import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Timer from 'react-compound-timer';
import swal from 'sweetalert';

class EnterOTP extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
         otp: "",
         show_timer: true,
         no_resend_option: false,
         otp_btn: 'formal_btn_unclick', //normal otp button class
         show_resend: true,
       };
       this.handleChange = this.handleChange.bind(this);
       this.otpSubmit = this.otpSubmit.bind(this);
       this.resendOTP = this.resendOTP.bind(this);
       this.showResend = this.showResend.bind(this);
    }

    handleChange(otp) {
        if(otp.length===4){
                this.setState({
                    otp_btn: 'formal_btn_click'
                });
        }else{
            this.setState({
                    otp_btn: 'formal_btn_unclick'
            });
        }
       this.setState({
         otp: otp
       });
    }

    otpSubmit() {
        var otp = this.state.otp;
        if(otp.length===4)
        {
            this.props.otp_handler(this.state.otp);
        }
        else
        {
            swal("Please enter 4 digit OTP");
        }
    }

    resendOTP() {
       this.props.mob_handler(this.props.phone, 1);
       this.setState({
            show_resend: false,
        });
       
    }

    showResend() {
        this.setState({
            show_timer: false,
        });
    }


    render() { 
			return(
                            <div>
                                <div className="col-xs-12">
                                    <div className="otp_text">An OTP was sent to</div>
                                    <div className="dark_text font_w_600">+91 - {this.props.phone}</div>
                                </div>
                                <div className="col-xs-12 each_input_label">
                                    <OtpInput value={this.state.otp} onChange={this.handleChange} numInputs={4} separator={<span>-</span>} isInputNum={true} containerStyle="otp-box" inputStyle="partitioned-input" />
                                    { this.state.no_resend_option ? (<div></div>) : this.state.show_timer ? (
                                    <div className="otp-timer-div">
                                        <Timer initialTime={60000} lastUnit="s" direction="backward" checkpoints={[{time: 0,callback: () => this.showResend()}]}>
                                        Resend OTP in <Timer.Seconds /> seconds.
                                        </Timer>
                                    </div>
                                    ):(
                                        <div>
                                        {this.state.show_resend ?
                                            <div className="resend-otp-text" onClick={this.resendOTP}>
                                                Resend OTP
                                            </div>
                                            :
                                            <div className="otp-sent-text" >
                                                OTP Sent !
                                            </div>
                                        }
                                        
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <button onClick={this.otpSubmit} className={this.state.otp_btn+ " btn col-xs-10 col-xs-offset-1 m_t_12"} type="button">Verify</button>
                                </div>
                            </div>
			);
    }
}

export default EnterOTP;