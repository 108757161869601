import React, { Component } from "react";
import Header from "../Common/Header";

class ContactUs extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            cart_count: 0,
            app_id: 0,
        };
    }
	componentDidMount()
    {
        const query = new URLSearchParams(this.props.location.search);
		const app_id = query.get('app_id');
		if(app_id == 1)
		{
			this.setState({app_id: app_id});
		}
        let prod_count = localStorage.getItem("prod_counts");
        if(prod_count)
        {
            var total_qty = 0;
            let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
            for(var i = 0; i<= product_id_array.length-1; i++)
            {
                if(product_id_array[i] !== 0)
                {
                    total_qty = total_qty + JSON.parse(localStorage.getItem("prod_"+product_id_array[i])).qty;
                }
            }
            this.setState({
                cart_count: total_qty
            });
        }
    }
	render() {
		return (
	    	<div>
	    		{this.state.app_id != 1 ?
	    			<Header color={"#160D08"} cart_count={this.state.cart_count}/>
	    			:
	    			null
	    		}
    			{/*<div className={this.state.app_id != 1 ? "row grey_background" : "row grey_background_no_header"}>
					<div className="col-xs-12">
						<center className="font_20 font_w_700 header_text">
							<p className="contact_us_header">We're here</p>
							<p className="contact_us_subheader">Waiting to hear from you</p>
						</center>
					</div>
				</div>
				<div className="row container-fluid no_margin m_t_5">
					<div className="col-sm-3"></div>
					<div className="col-xs-12 col-sm-6 contact_us_words">
						<p>Need us around? We are more than just eyes... we are ears too! <br /><br/> Drop us a mail at <b>contact@ojoswear.com</b> or send us a Whatsapp message at <b>+91-7669800711</b> if you have any unresolved questions, suggestions, or show us some love with feedback. <br/><br/> We promise to get back to you in 24 hours.</p>
						<p>Corporate Office: 6th Floor, Tower D, Vatika Mindscapes, 12/1, Mathura Rd, Sector 27D, Faridabad - 121003</p>
					</div>
					<div className="col-sm-3"></div>
				</div>*/}
				<div className="col-xs-12 col-sm-12 contact_us_first_section contact_us_first_section_web">
                    <h2 className="contact_us_first_text extra_bold_text contact_us_first_text_web">contact us</h2>
                    <h3 className="contact_us_first_subtext contact_us_first_text_web">We would love to hear from you. Here’s how you can reach us…</h3>
                    <div className="contact_us_action_button_section">
	                	<div className="col-sm-6 col-xs-6 padding_is_zero">
	                        <center><div className="contact_us_cta_button contact_us_cta_button_left_web"><a href="mailto:contact@ojoswear.com" target="_blank"><img src="/images/Header/purple_mail.svg"/><p className="contact_us_cta_subtext">Mail Us</p></a></div></center>
	                    </div>
	                    {/*<div className="col-xs-4">
	                        <a href="tel:+91-7669800711"><center className="contact_us_cta_button"><img src="/images/Header/purple_call.svg"/><p className="contact_us_cta_subtext">Call Us</p></center></a>
	                    </div>*/}
	                    <div className="col-sm-6 col-xs-6 padding_is_zero">
	                        <center><div className="contact_us_cta_button contact_us_cta_button_right_web"><a href="https://wa.me/+917669800711" target="_blank"><img src="/images/Header/purple_whatsapp.svg"/><p className="contact_us_cta_subtext">WhatsApp Us</p></a></div></center>
	                    </div>
	                </div>
                    <div className="clearfix"></div>
                    <div className="contact_us_action_button_section">
                    	{this.state.app_id != 1 ? <center><a href="/faq"><button className="contact_us_faq_button">READ FAQs</button></a></center> : null }
                    </div>
                </div>
			</div>
	    );
	}
}

export default ContactUs;

