import React, { Component } from "react";
import CheckoutHeader from './CheckoutHeader';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ReactPixel from 'react-facebook-pixel';
import Lottie from 'react-lottie';
import animationData from '../Lotties/success_payment_lottie.json';
import ClevertapReact from 'clevertap-react';

class PaymentSuccess extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	token: localStorage.getItem("token"),
	    	order_id: '',
	    	show_lenskart_return: false,
	    	show_lenskart_msg: '',
	    	redirect: false,
	    	redirectUrl: '/',
	    	stage: 2,
	    	width: window.innerWidth,
	    	cart_list: [],
	    }
	}
	componentWillMount() 
  	{
	  	window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount() {
	  	window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};
	componentDidMount()
	{
		if(this.state.token)
		{
			const order_id = this.props.match.params.order_id;
			const { width } = this.state;
	  		const isMobile = width <= 768;
	  		if(isMobile)
	  		{
	  			var device = "mobile";
	  		}
	  		else
	  		{
	  			var device = "desktop";
	  		}
			var myHeaders = new Headers();
		    myHeaders.append("Authorization", "Bearer "+ this.state.token);
		    myHeaders.append("User-Source", device);

			var data = '';
			let url = process.env.REACT_APP_API_URL+'/v1/user/order/'+order_id+'/lenskart';
			var requestOptions = {
	            method: 'POST',
	            headers: myHeaders,
	            body: data,
	            redirect: 'follow'
	        };

			fetch(url, requestOptions)
	        .then(response => response.json())
			.then(data => {
	            console.log(data)
	            if(data.success == true) 
	            {
	            	this.setState({show_lenskart_return: true, show_lenskart_msg: 'success'})
	            	var arr = []; // Array to hold the keys
					// Iterate over localStorage and insert the keys that meet the condition into arr
					for (var i = 0; i < localStorage.length; i++){
					    if (localStorage.key(i).substring(0,3) == 'pro') {
					        arr.push(localStorage.key(i));
					    }
					}
					// Iterate over arr and remove the items by key
					for (var i = 0; i < arr.length; i++) {
					    localStorage.removeItem(arr[i]);
					}

					var final_price = data.data.billing.final_price;
					var user_name = data.data.user_data.name;
					var user_email = data.data.user_data.email;
					var payment_mode = data.data.cart_list.payment_method;

					var cart_list = [];
					for(var i=0; i<data.data.cart_list.products.length; i++)
					{
						console.log(data.data.cart_list.products);
						if(data.data.cart_list.products[i].type != "subscription")
						{
							var product_id = data.data.cart_list.products[i].product_id;
							var product_name = data.data.cart_list.products[i].name;
							var power_type = data.data.cart_list.products[i].power_type;
							var product_qty = data.data.cart_list.products[i].qty;
							var product_category = data.data.cart_list.products[i].type;
							var product_data = {
								"category": product_category,
		                        "product_name": product_name,
		                        "power_type": power_type,
		                        "product_id": product_id,
		                        "Quantity": product_qty
							};
							cart_list.push(product_data);
						}
					}

					if(user_name != 'Test')
					{
						ReactPixel.track('Purchase',{currency: "INR", value: final_price});
					}
					var checkout_charged = 

					{
						
		                "Amount": final_price,
		                "Currency": "INR",
		                "Payment mode": payment_mode,
		                "Items": cart_list
			               
					}
					/*var checkout_charged = 
					{
			            
		                "Amount": 300,
		                "Currency": "Rs",
		                "Payment mode": "Credit Card",
		                "Items": [
		                    {
		                        "category": "test",
		                        "product_name": "p_name",
		                        "power_type": "single_vision",
		                        "product_id":101,
		                        "Quantity": 1
		                    }
		                ]
			            
			        }*/
					
					
					console.log(checkout_charged);
					ClevertapReact.event("Charged",checkout_charged);
				}
	            else 
	            {
	            	this.setState({show_lenskart_return: true, show_lenskart_msg: 'fail'})
	            } 
	        })
	        
			this.setState({order_id: order_id})
		}
		else
		{
			swal("Please login to Procced").then(value => {
				this.setState({
                	redirect: true,
                	redirectUrl:'/login',
            	})
			});
		}
	}
	CheckoutBack()
	{
		this.setState({ redirect: true, redirectUrl:'/'})
	}
	render() {
		const defaultOptions = {
	      loop: true,
	      autoplay: true,
	      animationData: animationData,
	      rendererSettings: {
	        preserveAspectRatio: "xMidYMid slice"
	      }
	    };
	    const styles = {
			checkAnimation: {
			    display: 'flex',
  				justifyContent: 'center',
  				alignItems: 'center',
  				textAlign: 'center',
  				minHeight: '40vh',
			},
		}
		const { width } = this.state;
  		const isMobile = width <= 768;
  		if(isMobile)
  		{
  			var device = "mob";
  		}
  		else
  		{
  			var device = "web";
  		}
		if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
		return(
			<div>
			<CheckoutHeader stage={this.state.stage} device={device}/>
			<div className="col-sm-3"></div>
				<div className="col-sm-6 padding_is_zero">
					<div className="checkout_details">
						<div class="payment_body_result">
							<div className="col-sm-12 padding_is_zero payment_section_text">
								{!this.state.show_lenskart_return ? 
									<center className="success_message_body success_plain_text_section">
										<div className="review_order_loader"></div>
										<p className="order_placed_text">Working to get you the best</p>
										<p className="order_placed_subtext">Almost there in a few seconds</p>
									</center>
									:
									<div>
										{this.state.show_lenskart_msg == 'success' ?
											<div>
												<center className="success_message_body">
													<Lottie options={defaultOptions} width={200} style={styles.checkAnimation}/>
													<p className="order_placed_text">Yoohoo! <br/> Your order has been confirmed</p>
													<p className="order_placed_subtext">Look out for our updates in your account section, while we process your order. Your order details are given below:</p>
													<p className="order_placed_subtext">Order ID: {this.state.order_id}</p>
													{/*<p className="order_placed_subtext">You can check your order details <a href="/order" className="link_css"><u>here</u></a>.</p>
													<p>We will send you a notification when your order is out to reach you</p>*/}
												</center>
												<div className="col-sm-6 col-offset-3 col-xs-12 padding_is_zero success_page_footer">
										    		<div className="col-xs-6 col-sm-6">
										    			<center><Link to="/order" className="link_css"><p><u>Skip For Now</u></p></Link></center>
										    		</div>
										    		<div className="col-xs-6 col-sm-6">
										    			<center><Link to="/order"><button className="btn success_enter_power_button">Enter My Power</button></Link></center>
										    		</div>
												</div>
									    	</div>
											:
											<center className="success_message_body success_plain_text_section">
												<p className="order_placed_text">Oops! Something went wrong while your order was getting confirmed!</p>
												<p className="order_placed_subtext">Order ID: {this.state.order_id}</p>
												<p className="order_placed_subtext">Don't worry, our customer support team will reach out to you within 24 hours.</p>
											</center>
										}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3"></div>
			</div>
		);
		
	}
}

export default PaymentSuccess;