import  React, {Component} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Lottie from 'react-lottie';
import animationData from '../Lotties/62542-cool-chick.json';
import LoginInputNew from './LoginInputNew';

class LoginCommonDiv extends Component 
{    
    constructor(props) {
    super(props);
    this.state = {
      step:"",
    };
    this.ChangeStep = this.ChangeStep.bind(this);
    }
    ChangeStep()
    {
        this.setState({step:2 });
    }
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const styles = {
        checkAnimation: {
            textAlign: 'center',
            height: 'auto',
            width: '50%',
        },
    } 
    return(
            <div>
                {/* ***************mob-view************************** */}
                <div className="col-xs-12 hidden-sm hidden-md hidden-lg hidden-xl ojos_log_in_page_div">
                    {this.state.step == 1 ?
                        <div>
                            {this.props.show_login_subtext ?
                                <div>
                                    <p className="ojos_login_headtext">Hello there…</p>
                                    <p className="ojos_login_subheadtext">Let’s get started with your mobile number</p>
                                </div>
                                :
                                <div >
                                    <p className="ojos_login_headtext">Verify your phone number</p>
                                    <p className="ojos_login_subheadtext">Please enter your mobile number to login & proceed for checkout</p>
                                </div>
                            }
                        </div>
                        :
                        <div>
                            <p className="ojos_login_headtext">Nice to meet you!</p>
                            <p className="ojos_login_subheadtext">Almost there, we are a verification away</p>
                        </div>
                    }
                    <LoginInputNew change_step_props={this.ChangeStep}/>
                    
                </div>

                {/* ************************tab & desktop -view*************************************** */}

                <div className="col-lg-12 col-md-12 col-sm-12 log_in_desktop_div hidden-xs">
                    <img src="/images/Header/OJPattern.png"/>
                    <div class=" sign_up_desktop_container">
                    <div className="col-lg-6  col-md-6 col-sm-6 sign_up_desktop_container_lottie">
                      <Lottie  options={defaultOptions} style={{position:"absolute", height: "auto", width:"50%"}}/>
                    </div>
                    <div className=" col-lg-6  col-md-6 col-sm-6 login_desktop_contents">
                        {this.props.step == 1 ?
                            <div>
                                {this.props.show_login_subtext ?
                                    <div>
                                        <p className="login_desktop_headtext">Hello there…</p>
                                        <p className="login_desktop_subheadtext">Let’s get started with your mobile number</p>
                                    </div>
                                    :
                                    <div >
                                        <p className="login_desktop_headtext">Verify your phone number</p>
                                        <p className="login_desktop_subheadtext">Please enter your mobile number to login & proceed for checkout</p>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <p className="login_desktop_headtext">Nice to meet you!</p>
                                <p className="login_desktop_subheadtext">Almost there, we are a verification away</p>
                            </div>
                        }
                        <LoginInputNew change_step_props={this.ChangeStep}/>
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default LoginCommonDiv;
