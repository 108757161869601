import React, { useState } from "react";

const StarRating = ({ totalStars, filledStars, onStarSelect }) => {
  const [selectedStars, setSelectedStars] = useState(0);

  const handleStarClick = (i) => {
    setSelectedStars(i);
    onStarSelect(i);
  };
  let starColor = "";
  let starText = "";
  const stars = [];
  switch (filledStars) {
    case 1:
      starColor = "#86ACC8";
      starText = "Could be better";
      break;
    case 2:
      starColor = "#00A2AC";
      starText = "It is fine";
      break;
    case 3:
      starColor = "#47B469";
      starText = "Satisfied!";
      break;
    case 4:
      starColor = "#EDC337";
      starText = "It is great!";
      break;
    case 5:
      starColor = "#F29B24";
      starText = "Phenomenal!!!";
      break;

    default:
      starColor = "#E6E6E6";
      starText = "";
      break;
  }
  for (let i = 1; i <= filledStars; i++) {
    const starClass = i <= selectedStars ? "selected" : "";

    stars.push(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.02"
        height="27.721"
        viewBox="0 0 29.02 27.721"
      >
        <path
          id="Icon_feather-star"
          data-name="Icon feather-star"
          d="M16.26,3l4.1,8.3L29.52,12.64,22.89,19.1l1.565,9.123-8.195-4.31-8.195,4.31L9.63,19.1,3,12.64,12.163,11.3Z"
          transform="translate(-1.75 -1.75)"
          fill={starColor}
          //   stroke="#f29b24"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
        />
      </svg>
    );
  }
  for (let i = 1; i <= totalStars - filledStars; i++) {
    const starClass = i <= selectedStars ? "selected" : "";

    stars.push(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.02"
        height="27.721"
        viewBox="0 0 29.02 27.721"
      >
        <path
          id="Icon_feather-star"
          data-name="Icon feather-star"
          d="M16.26,3l4.1,8.3L29.52,12.64,22.89,19.1l1.565,9.123-8.195-4.31-8.195,4.31L9.63,19.1,3,12.64,12.163,11.3Z"
          transform="translate(-1.75 -1.75)"
          fill="none"
          stroke="#E6E6E6"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
        />
      </svg>
    );
  }
  return (
    <div className="starRating">
      {stars.map((star, i) => {
        return <span onClick={() => handleStarClick(i + 1)}>{star}</span>;
      })}
      <div style={{ color: starColor }}>{starText}</div>
    </div>
  );
};

export default StarRating;
