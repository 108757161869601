import React, { Component } from "react";
import InstagramEmbed from 'react-instagram-embed';
import $ from 'jquery';

class ProductDetailsInstagramPost extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	 
	    } 
	}
	componentDidMount()
    {
	    var itemsMainDiv = ('.MultiCarousel');
	    var itemsDiv = ('.MultiCarousel-inner');
	    var itemWidth = "";

	    $('.leftLst, .rightLst').click(function () {
	    	console.log("clicked");
	        var condition = $(this).hasClass("leftLst");
	        if (condition)
	            click(0, this);
	        else
	            click(1, this)
	    });

	    ResCarouselSize();
		
		$(window).resize(function () {
	        ResCarouselSize();
	    });
	    $(window).scroll(function () {
	        ResCarouselSize();
	    });

	    //this function define the size of the items
	    function ResCarouselSize() {
	        var incno = 0;
	        var dataItems = ("data-items");
	        var itemClass = ('.item');
	        var id = 0;
	        var btnParentSb = '';
	        var itemsSplit = '';
	        var sampwidth = $(itemsMainDiv).width();
	        var bodyWidth = $('body').width();
	        $(itemsDiv).each(function () {
	            id = id + 1;
	            var itemNumbers = $(this).find(itemClass).length;
	            btnParentSb = $(this).parent().attr(dataItems);
	            itemsSplit = btnParentSb.split(',');
	            $(this).parent().attr("id", "MultiCarousel" + id);


	            if (bodyWidth >= 1200) {
	                incno = itemsSplit[3];
	            	itemWidth = sampwidth / incno;
	            }
	            else if (bodyWidth >= 992) {
	                incno = itemsSplit[2];
	                itemWidth = sampwidth / incno;
	            }
	            else if (bodyWidth >= 768) {
	                incno = itemsSplit[1];
	                itemWidth = sampwidth / incno;
	            }
	            else {
	                incno = itemsSplit[0];
	                itemWidth = sampwidth / incno;
	            }
	            
	            $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
	            $(this).find(itemClass).each(function () {
	                $(this).outerWidth(itemWidth);
	            });

	            $(".leftLst").addClass("over");
	            $(".rightLst").removeClass("over");

	        });
	    }


	    //this function used to move the items
	    function ResCarousel(e, el, s) {
	        var leftBtn = ('.leftLst');
	        var rightBtn = ('.rightLst');
	        var translateXval = '';
	        var divStyle = $(el + ' ' + itemsDiv).css('transform');
	        var values = divStyle.match(/-?[\d\.]+/g);
	        var xds = Math.abs(values[4]);
	        if (e == 0) {
	            translateXval = parseInt(xds) - parseInt(itemWidth * s);
	            $(el + ' ' + rightBtn).removeClass("over");

	            if (translateXval <= itemWidth / 2) {
	                translateXval = 0;
	                $(el + ' ' + leftBtn).addClass("over");
	            }
	        }
	        else if (e == 1) {
	            var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
	            translateXval = parseInt(xds) + parseInt(itemWidth * s);
	            $(el + ' ' + leftBtn).removeClass("over");

	            if (translateXval >= itemsCondition - itemWidth / 2) {
	                translateXval = itemsCondition;
	                $(el + ' ' + rightBtn).addClass("over");
	            }
	        }
	        $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');
	    }

	    //It is used to get some elements from btn
	    function click(ell, ee) {
	        var Parent = "#" + $(ee).parent().attr("id");
	        var slide = $(Parent).attr("data-slide");
	        ResCarousel(ell, Parent, slide);
	    }
	}
	render()
	{
		return(
				<div>
				<div className="clearfix"></div>

				{this.props.device == 'mob' && this.props.pdp_detail.length > 0 ?
					<div className="instagram_embed_pdp pdp_instagram_content hidden-sm hidden-lg hidden-md">
						<p className="trending_product_text_mob">spotted on our social</p>
						<section className="carousel corousel_instagram_post" aria-label="Gallery">
			                <ol className="carousel__viewport">
		                		{this.props.pdp_detail.map((item, index) => (
		                			<li className="carousel__slide__instagram carousel__slide">
			                            <div className="carousel__snapper_trending">
			                            	<div className="col-xs-12 col-sm-4 col-md-2">
								              	<div>
												    <InstagramEmbed
													  	url={item.url}
													  	clientAccessToken='1619016081795143|Uf6ojjPtQsnVjRU-TpxgzWLZuv8'
													  	maxWidth={320}
													  	hideCaption={true}
													  	containerTagName='div'
													  	protocol=''
													  	injectScript
													  	onLoading={() => {}}
													  	onSuccess={() => {}}
													  	onAfterRender={() => {}}
													  	onFailure={() => {}}
													/>
												</div>
							                </div>
										</div>
			                        </li>
								))}
			                </ol>
			            </section>
		            </div>
				:
					<div className="col-sm-12 pdp_instagram_content hidden-xs">
						{this.props.pdp_detail.length > 0 ?
							<div className="instagram_embed_pdp">
								<p className="trending_product_text">spotted on our social</p>
								<div className="col-sm-12 pdp_instagram_section_web">
				            		{this.props.pdp_detail.map((item, index) => (
				                       	<div className="col-sm-3">
				                       		{item.source_name == 'Instagram' && item.is_active == 1 ?
				                       			<div>
							                    	<div>
													    <InstagramEmbed
														  	url={item.url}
														  	clientAccessToken='1619016081795143|Uf6ojjPtQsnVjRU-TpxgzWLZuv8'
														  	maxWidth={320}
														  	hideCaption={true}
														  	containerTagName='div'
														  	protocol=''
														  	injectScript
														  	onLoading={() => {}}
														  	onSuccess={() => {}}
														  	onAfterRender={() => {}}
														  	onFailure={() => {}}
														/>
													</div> 
							                    </div>
							                    :
							                    null
				                       		}
						                </div>
					                ))}
					            </div>
				            	
					        </div>
					        :
					        null
				    	}

			        </div>
				}
			</div>
			);
	}
}
export default ProductDetailsInstagramPost;
