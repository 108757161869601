import React, { Component } from "react";

class ProductDetailsStaticImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            static_eyeglasses_web: [],
            show_static_eyeglasses_web: false,
            static_eyeglasses_mob: [],
            show_static_eyeglasses_mob: false,
            static_sunglasses_web: [],
            show_static_sunglasses_web: false,
            static_sunglasses_mob: [],
            show_static_sunglasses_mob: false,
            
        };
    }
    componentDidMount() 
    {
    
    }
    render() {
        return (
            <div>
                <div className="clearfix"></div>
                {this.props.product_details.type == "Sunglasses" ? (
                    <div>
                        {this.props.show_static_sunglasses_mob ?
                            <div className="col-xs-12 hidden-sm hidden-lg hidden-md padding_is_zero">
                                {this.props.static_sunglasses_mob.map((details, index) => (
                                    <div className="col-xs-12">

                                        <img src={details.banner_for_mobile_web} className="img-responsive" />
                                        <br />
                                        
                                    </div>
                                ))}
                            </div>
                            :
                            null
                        }
                        
                        {this.props.show_static_sunglasses_web ?
                            <div>
                                {this.props.static_sunglasses_web.map((details, index) => (
                                    <div className="col-sm-12 hidden-xs padding_is_zero">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-8">
                                            <img src={details.banner_for_desktop} className="img-responsive" />
                                            <br />
                                         </div>
                                        <div className="col-sm-2"></div>
                                    </div>
                                ))}
                            </div>
                            :
                            null
                        }
                    </div>
                ) : (
                    <div>
                        {this.props.show_static_eyeglasses_mob ?
                            <div className="col-xs-12 hidden-sm hidden-lg hidden-md padding_is_zero">
                                {this.props.static_eyeglasses_mob.map((details, index) => (
                                    <div  className="col-xs-12">
                                        <img
                                          src={details.banner_for_mobile_web}
                                          className="img-responsive"
                                        />
                                        <br />
                                    </div>
                                ))}
                            </div>
                            :
                            null
                        }
                        {this.props.show_static_eyeglasses_web ?
                            <div>
                                {this.props.static_eyeglasses_web.map((details, index) => (
                                    <div className="col-sm-12 hidden-xs padding_is_zero">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-8">
                                            <img
                                              src={details.banner_for_desktop}
                                              className="img-responsive"
                                            />
                                            <br />
                                        </div>
                                        <div className="col-sm-2"></div>
                                    </div>
                                ))}
                            </div>
                            :
                            null
                        }
                    </div>
                )}
            </div>
        );
    }
}
export default ProductDetailsStaticImages;
