import React from "react";
import FooterSearchBox from "./FooterSearchBox";
import { SayButton } from 'react-say';

const Footer = () => {
  return (
    <div>
    <div className="clearfix"></div>
    {/* <div className="col-sm-12 footer_link_in_shape_div">
      <img src="/images/Header/AppFooterNote 1.png" />
    </div> */}
    <div class="footer">
      {/* <div className="col-sm-12 col-xs-12 footer_link_div">
        <div className="col-sm-3 col-xs-12 footer_each_col">
          <p
            className="last_slide_contact_footer_subtext"
            style={{
              fontWeight: "600",
              fontSize: "15px",
              lineHeight: "0px",
            }}
          >
            Search for your favourite product
          </p>

          <FooterSearchBox></FooterSearchBox>
        </div>
        <div className="clearfix"></div>
      </div> */}
      <div className="col-sm-12 col-xs-12 footer_link_div ">
        <div className="row">
          <div
            className="col-sm-1 col-xs-3 footer_each_col"
            style={{  }}
          >
            <img
              src="/images/Header/brand_guidline_final.png"
              style={{
                paddingRight: "15px",
                // paddingLeft: "2em",
                borderRight: "0.5px solid #FAFAFA"
              }}
              className=""
            />
          </div>
          <div className="col-sm-8 col-xs-8 padding_is_zero" style={{ textAlign: "left" }}>
            <span
              className=""
              style={{
                // fontFamily: "Nunito Sans",
                fontWeight: "600",
                fontSize: "15px",
                lineHeight: "22px",
              }}
            >
              OJOS Wear | A Lenskart Alliance
              <br />
              <span className="footer_pronounce"
                style={{
                  fontSize: "13px",
                  lineHeight: "22px",
                  color: "#89949E",
                }}
              >
                Pronounced <span style={{ color: "white" }}>(oh-hos)</span> and
                sounds like
                <SayButton text="oh-hos"><i class="fa fa-volume-up"></i></SayButton>
              </span>
            </span>
          </div>
        </div>

        <div className="clearfix"></div>
      </div>
      {/* <div className="col-sm-12 col-xs-12 footer_link_div">
        <div className="col-sm-12 col-xs-12 footer_each_col">
          <p className="footer_header_text">Contact Us</p>
        </div>
        <div className="col-sm-1 col-xs-4 footer_each_col">
          <a href="mailto:contact@ojoswear.com" target="_blank">
            <center className="last_slide_contact_footer">
              <img src="/images/Header/Icon material-mail.svg" />
              <p className="last_slide_contact_footer_subtext">Mail Us</p>
            </center>
          </a>
        </div>
        <div className="col-sm-1 col-xs-4 footer_each_col">
          <a href="https://wa.me/+917669800711" target="_blank">
            <center className="last_slide_contact_footer">
              <img src="/images/Header/Icon ionic-logo-whatsapp.svg" />
              <p className="last_slide_contact_footer_subtext">WhatsApp Us</p>
            </center>
          </a>
        </div>
        <div className="clearfix"></div>
        
      </div> */}
      {/* <div className="col-sm-12 col-xs-12 footer_link_div">
        <div className="col-sm-4 col-xs-6 footer_each_col">
          <p className="footer_header_text">Download Our App</p>
        </div>
        <div className="col-sm-4 col-xs-6 footer_each_col align_right">
          <p className="footer_header_text">Let’s Connect On</p>
        </div>
        <div className="col-sm-4"></div>
        <div className="clearfix"></div>
        <div className="col-sm-4 col-xs-6 footer_each_col padding_left_0">
          <div className="col-sm-1 col-xs-4 footer_each_col footer_contact_col">
            <a href="https://bit.ly/3Ivkivt" target="_blank">
              <img
                src="/images/Header/icons8-google-play.svg"
                className="footer_icon"
              />
            </a>
          </div>
          <div className="col-sm-1 col-xs-4 footer_each_col footer_contact_col">
            <a href="https://apple.co/3vxJ4H6" target="_blank">
              <img
                src="/images/Header/icons8-app-store.svg"
                className="footer_icon"
              />
            </a>
          </div>
        </div>
        <div className="col-sm-4 col-xs-6 footer_each_col align_right">
          <a href="https://www.facebook.com/ojoswear" target="_blank">
            <img
              src="/images/Header/FacebookIconFooter.svg"
              className="footer_icon"
            />
          </a>
          <a href="https://www.instagram.com/ojoswear/" target="_blank">
            <img
              src="/images/Header/InstagramIconFooter.svg"
              className="footer_icon"
            />
          </a>
          <a href="https://twitter.com/OjosWear" target="_blank">
            <img
              src="/images/Header/TwitterIconFooter.svg"
              className="footer_icon"
            />
          </a>
        </div>
        <div className="col-sm-4"></div>
      </div> */}
      {/* <div className="col-sm-12 col-xs-12 footer_link_div">
        <div className="col-sm-4 col-xs-6 footer_each_col">
          <p className="footer_header_text">Explore OJOS Wear</p>
          <p>
            <a
              href="/products/eyeglasses/male?path_source=male_listing"
              target="_blank"
              className="footer_subtext"
            >
              Men's Eyeglasses
            </a>
          </p>
          <p>
            <a
              href="/products/eyeglasses/female?path_source=female_listing"
              target="_blank"
              className="footer_subtext"
            >
              Women's Eyeglasses
            </a>
          </p>
          <p>
            <a
              href="/products/sunglasses"
              target="_blank"
              className="footer_subtext"
            >
              Sunglasses
            </a>
          </p>
        </div>
        <div className="col-sm-4 col-xs-6 footer_each_col">
          <p className="footer_header_text">Our Services</p>
          <p>
            <a href="/account" target="_blank" className="footer_subtext">
              My Account
            </a>
          </p>
          <p>
            <a href="/order" target="_blank" className="footer_subtext">
              Orders & Shipping
            </a>
          </p>
          <p>
            <a href="/contact-us" target="_blank" className="footer_subtext">
              Help
            </a>
          </p>
          <p>
            <a href="/faq" target="_blank" className="footer_subtext">
              FAQs
            </a>
          </p>
        </div>
        <div className="col-sm-4 col-xs-12 footer_each_col">
          <p className="footer_header_text">About OJOS Wear</p>
          <p>
            <a
              href="https://forms.gle/XYbGDvGM2YBqyVFu6"
              target="_blank"
              className="footer_subtext"
            >
              We are hiring!{" "}
              <small className="apply_now_text"> Apply Now</small>
            </a>
          </p>
          <p>
            <a href="/about-us" target="_blank" className="footer_subtext">
              Get to know us
            </a>
          </p>
          <p>
            <a
              href="/terms-and-conditions"
              target="_blank"
              className="footer_subtext"
            >
              Terms & Conditions
            </a>
          </p>
          <p>
            <a
              href="/privacy-policy"
              target="_blank"
              className="footer_subtext"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div> */}
      <div className="col-sm-12 col-xs-12 footer_link_div">
        <center>
          <img src="/images/Header/OJ_footer.svg" />
        </center>
      </div>
    </div>
    </div>
  );
};

export default Footer;
