import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CartItems extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
        	access_token : localStorage.getItem("token"),
        	cart: this.props.cart,
        }
    }

    render() { 
    		return(
				<div>
					{this.props.cart.qty > 0 ?
						<div className="col-xs-12 each_cart_product">
	  						<div className="col-xs-4">
	  							<center><Link to={{pathname: "/product/"+this.props.cart.url+"/color/"+this.props.cart.product_id, state: { prevPath: window.location.pathname }}}><img src={this.props.cart.image} className={this.props.cart.sold_out == 1 ? "img-responsive cart_product_image each_item_section_sold_out" : "img-responsive cart_product_image"} alt="cart_product_image"/></Link></center>
	    						{this.props.cart.sold_out == 1 ? 
	    							<div className="product_qty_area">
		    							<p className="cart_sold_out_text">SOLD OUT</p>
		    						</div>
	    							:
	    							<div className="product_qty_area">
		    							<i className="fa fa-minus" aria-hidden="true" onClick={() => this.props.sub_qty(this.props.i)}></i>
		    							<input type="text" value={this.props.cart.qty} className="qty_input" disabled/>
		    							<i className="fa fa-plus" aria-hidden="true" onClick={() => this.props.add_qty(this.props.i)}></i>
		    						</div>
	    						}
	    					</div>
							<div className={this.props.cart.sold_out == 1 ? "col-xs-8 padding_is_zero each_item_section_sold_out" : "col-xs-8 padding_is_zero"}>
	    						{/*<div className="col-xs-12 product_compnay">
	    							{this.props.cart.brand_name}
		    					</div>*/}
		    					{this.props.cart.flag_show_ojos_price ?
		    						<div>
		    							<div className="col-xs-6">
		    								{this.props.cart.prescription_type == 'sunglasses' ?
		    									<p className="cart_prescription">Zero Power</p>
		    								:
				    							<p className="cart_prescription">{this.props.cart.prescription_type}</p>
				    						}
				    						{this.props.cart.prescription_type == 'Blu Cut Single Vision' || this.props.cart.prescription_type == 'Zero Power'?
                                                <img class="bluecut_icon_in_blue" src='/images/Header/BluCut_B_Icon.svg'></img>
                                                :
                                                null
                                            }
				    					</div>
				    					<div className="col-xs-6 cart_cost_price">
				    						Rs. {parseInt(this.props.cart.cost)}
				    					</div>
		    						</div>
		    						:
		    						<div className="col-xs-12 cart_prescription">
			    						{this.props.cart.prescription_type == 'sunglasses' ?
	    									<p className="cart_prescription">Zero Power</p>
	    								:
			    							<p className="cart_prescription">{this.props.cart.prescription_type}</p>
			    						}
			    						{this.props.cart.prescription_type == 'Blu Cut Single Vision' || this.props.cart.prescription_type == 'Zero Power'?
                                            <img class="bluecut_icon_in_blue" src='/images/Header/BluCut_B_Icon.svg'></img>
                                            :
                                            null
                                        }
			    					</div>
		    					}
		    					<div className="col-xs-12">
		    						<Link to={{pathname: "/product/"+this.props.cart.url+"/color/"+this.props.cart.product_id, state: { prevPath: window.location.pathname }}}><p className="extra_bold_text cart_product_name">{this.props.cart.name}</p></Link>
		    					</div>
		    					<div className="col-xs-6 cart_size_color_text">
		    						SIZE: <p className="size_text">{this.props.cart.size}</p>
		    					</div>
		    					<div className="col-xs-6 cart_size_color_text">
		    						COLOR: <span className="colour_dot" style={{backgroundColor: this.props.cart.color}}></span>
		    					</div>
		    					
		    					<div className="col-xs-12">
		    						<Link to={{pathname: "/product/"+this.props.cart.url+"/color/"+this.props.cart.product_id, state: { prevPath: window.location.pathname }}} className="cart_edit_text"><p >Edit ></p></Link>
		    						<p className="cart_edit_text" onClick={() => this.props.remove_item(this.props.i)}>Remove ></p>
		    					</div>
	    					</div>
						</div>
					:
					null
					}
            	</div>	
	                		
	        );
		
		
    }
}

export default CartItems;
/*

						*/