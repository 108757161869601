export const FETCH_PRODUCTS_BEGIN   = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const fetchProductsBegin = () => ({
    type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: { products }
});

export const fetchProductsFailure = error => ({
    type: FETCH_PRODUCTS_FAILURE,
    payload: { error }
});
export function FetchCartDetails(access_token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ access_token);
    let url = process.env.REACT_APP_API_URL+'/v1/cart';
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return dispatch => {
        dispatch(fetchProductsBegin());
        return fetch(url, requestOptions)
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            dispatch(fetchProductsSuccess(json));
            return json.products;
          })
        .catch(error => dispatch(fetchProductsFailure(error)));
    };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}