import React, { Component } from "react";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import Footer from '../Common/Footer';
import { Link } from 'react-router-dom';

class Products extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            show_footer: false,
        };
    }
	componentDidMount()
	{
		setTimeout(function(){
             this.setState({show_footer:true});
        }.bind(this),1000);
	}
	render() {
		return (
			<div>
				<Header color={"transparent"}/>
				<div className="col-sm-12 hidden-xs padding_is_zero">
					<img src ="/images/Header/ExploreDesktop-min.jpg" className="products_banner_img"/>
					<div className="explore_page_text_desktop">
						<p className="extra_bold_text products_text_line_value">WORK •<br/> CASUAL •<br/> FUN •</p>
						<p className="extra_bold_text products_text_line_value_next_line">Wear it on with OJOS</p>
						<div className="col-sm-12 padding_is_zero">
							<div className="col-sm-5 padding_is_zero">
								<Link to="/products/eyeglasses/male?path_source=male_listing"><button className="explore_page_button">Shop Men</button></Link> 
	                    	</div>
	                    	<div className="col-sm-2"></div>
							<div className="col-sm-5 padding_is_zero">
	                    		<Link to="/products/eyeglasses/female?path_source=female_listing"><button className="explore_page_button">Shop Women</button></Link>
	                    	</div>
	                    </div>
					</div>
				</div>
				<div className="col-xs-12 hidden-sm hidden-md hidden-lg products_page_section_mob padding_is_zero">
					<img src ="/images/Header/ExploreMobile.jpg" className="products_banner_img_mob img-responsive"/>
					<div className="explore_page_text_mobile">
	                	<p className="extra_bold_text products_text_line_value_mob">WORK •<br/> CASUAL •<br/> FUN •</p>
	                	<p className="extra_bold_text products_text_line_value_next_mob">Wear it on with OJOS</p>
	                	<div className="col-xs-12 padding_is_zero">
							<div className="col-xs-5 padding_is_zero">
								<Link to="/products/eyeglasses/male?path_source=male_listing"><button className="explore_page_button_mob">Shop Men</button></Link> 
	                    	</div>
	                    	<div className="col-xs-1"></div>
							<div className="col-xs-5 padding_is_zero">
	                    		<Link to="/products/eyeglasses/female?path_source=female_listing"><button className="explore_page_button_mob">Shop Women</button></Link>
	                    	</div>
	                    </div>
	                </div>
	            </div>
				{this.state.show_footer ?
					<Footer />
					:
					null
				}
				
			</div>
		);
	}
}

export default Products;
