import React, { Component } from "react";
import Header from "../Common/Header";
import { Link } from 'react-router-dom';

class Credit extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            credits: '0',
            name: localStorage.getItem("name"),
        };
    }
    componentDidMount()
    {
        if(this.state.token)
        {
           var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ this.state.token);
            var requestOptions = {
                headers: myHeaders,
            };
            fetch(process.env.REACT_APP_API_URL+'/v1/user', requestOptions)
            .then(response => response.json())
            .then(
            (res) => {
                console.log(res);
                this.setState({credits: res.result.credits});
            }); 
        }
    }
    render() {
        var credit_text = this.state.credits > 1 ? "Credits" : "Credit";
		return(
			<div>
                <Header color={"#160D08"}/>  
                <div className="credit_body">
                    <div className="col-xs-12 col-sm-12 credit_header_text">
                        <center>
                            <p className="my_wallet_text">My Wallet</p>
                            <p>Review OJOS wallet</p>
                        </center>
                    </div>
                    <div className="col-xs-12 col-sm-12 credit_body_text">
                        <center>
                            {this.state.name? 
                                <p className="credit_body_text_credit">Hi {this.state.name}, <br/> find your wallet credit details below</p>
                            :
                                <p className="credit_body_text_credit">Hi, <br/> find your wallet credit details below</p>
                            }
                        </center>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <center>
                            <div className="credit_value_box">
                                <center><img src="images/Header/Credits_Page_Card.svg" alt="credit_background" className="img-responsive"/><p className="credit_value">{this.state.credits} {credit_text}</p></center>
                            </div>
                        </center>
                    </div>
                    <div className="col-sm-4"></div>
                    <div className="col-xs-12 col-sm-4">
                        <p className="credit_value_desc">Your credit space shows the number of items you can redeem from the subscription offer at OJOS.</p>
                        <p className="credit_value_desc">Purchase eyeglasses anytime using OJOS credits Eg - 1 credit used = 1 eyeglass.</p>
                        <p className="credit_value_desc">Your credits are redeemable at all times, with 1 year validity.</p>
                        <p className="credit_value_desc">Need more help? <a href="/faq" className="credit_faq_link">Check our FAQs section</a></p>
                    </div>
                    <div className="col-sm-4"></div>
                </div>
            </div>
		);
		
	}
}

export default Credit;