import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import Header from "../Common/Header";
import Loading from "../Common/Loading";

class PayUWeb extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            response: '',
            hash: '',
            redirectUrl: '/',
            redirect: false,
        }
    }
    componentDidMount()
    {
        let access_token = this.props.match.params.access_token;
        let order_id =  this.props.match.params.order_id;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ access_token);
        myHeaders.append("User-Source", "android");
        
        var data = JSON.stringify({"order_id" : order_id, "product_name" : "John Jacob", "source" : "app"});
        let url = process.env.REACT_APP_API_URL+'/v1/payment';
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow',
            stage: 2,
        };

        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({hash:data.hash,response: data.form_data}));
    }
    componentDidUpdate() 
    {
        if(this.state.hash != '')
        {
            document.getElementById('payment_form').submit();
        }
    }
    render() {
            if(this.state.hash != '')
            {
                return(
                        <div>
                            <form  id="payment_form" method="POST" action={this.state.response.post_url}>
                            <input type="hidden" name="hash" value={this.state.hash}  />
                            <input type="hidden" name="key" value={this.state.response.key}  />
                            <input type="hidden" name="txnid" value={this.state.response.txnid}  />
                            <input type="hidden" name="amount" value={this.state.response.amount}  />
                            <input type="hidden" name="productinfo" value={this.state.response.productinfo}  />
                            <input type="hidden" name="firstname" value={this.state.response.firstname}  />
                            <input type="hidden" name="email" value={this.state.response.email}  />
                            <input type="hidden" name="udf1" value={this.state.response.udf1}  />
                            <input type="hidden" name="udf2" value={this.state.response.udf2}  />
                            <input type="hidden" name="udf3" value={this.state.response.udf3}  />
                            <input type="hidden" name="udf4" value={this.state.response.udf4}  />
                            <input type="hidden" name="udf5" value={this.state.response.udf5}  />
                            <input type="hidden" name="phone" value={this.state.response.phone}  />
                            <input type="hidden" name="surl" value={this.state.response.surl}  />
                            <input type="hidden" name="furl" value={this.state.response.furl}  />
                            <input type="hidden" name="post_url" value={this.state.response.post_url}  />
                            {/*<input type="hidden" name="service_provider" id="service_provider" value={this.state.response.service_provider}  />*/}
                            </form>
                        </div>
                );
            }
            else
            {
                return(
                    <div> 
                        <Loading />
                    </div>
                );
            }
    }
}


export default PayUWeb;