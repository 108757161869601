import React, { Component } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { Link } from 'react-router-dom';
import store from '../../Redux/store';
import ProductTextArea from './ProductTextArea';
import ProductTextAreaDesktop from './ProductTextAreaDesktop'
import PageFooter from './PageFooter';
import Footer from '../Common/Footer';
import DesktopHead from './DesktopHead';
import ImageView from './ImageView';
import ImageViewDesktop from './ImageViewDesktop';
import ProductListingLastSlide from './ProductListingLastSlide';


class ProductPage extends Component 
{
 	constructor(props) {
    	super(props);
    	this.state = {
    		primary_image: [],
    		secondary_image: [],
    		theme_color: [],
    		lenskart_product_id: [],
    		product_count: '',
    		product_details: [{model_images:[], glass_images:[]}],
    		color: '',
    		width: window.innerWidth,
    		show_desc: false,
    		path_source: '',
    		page_index: 0,
    		show_loading_lottie: 1,
    		loading_lottie_web: 1,
    		choosen_index: 0,
    		banners: [],
    		pl_coupon_banner_web: [],
    		show_pl_coupon_banner_web: false,
    		pl_coupon_banner_mob: [],
    		show_pl_coupon_banner_mob: false,
    		pl_header_men: [],
    		show_pl_header_men: false,
    		pl_header_women: [],
    		show_pl_header_women: false,
    		pl_header_collection: [],
    		show_pl_header_collection: false,
    		pl_header_sunglasses: [],
    		show_pl_header_sunglasses: false,
    	};
    	this.ChangePicture = this.ChangePicture.bind(this);
    	this.ChangeColour = this.ChangeColour.bind(this);
    	this.ChangeColor = this.ChangeColor.bind(this);
    	this.onLeave = this.onLeave.bind(this);
    	this.afterLoad = this.afterLoad.bind(this);
  	}
/*
  	componentWillMount() 
  	{
	  window.addEventListener('resize', this.handleWindowSizeChange);
	}
*/
	componentWillUnmount() {
	  window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
	
	  this.setState({ width: window.innerWidth });
	};
	onLeave(origin, destination, direction) {
	    //console.log("Leaving section " + origin.index);
	}
	afterLoad(origin, destination, direction) {
	    //console.log("After load: " + destination.index);
	    this.setState({page_index: destination.index})
	}
	ChangeColour(item_id,color_id,i)
	{
		/*let url = process.env.REACT_APP_API_URL+'/v1/products/'+item_id+'?color_id='+color_id;
		fetch(url)
        .then(response => response.json())
        .then(products => {
        	let product_details_new = this.state.product_details;
        	product_details_new[i].model_images = products.result.products[i].model_images;
  			product_details_new[i].glass_images = products.result.products[i].glass_images;
        	//this.setState({product_details: product_details_new})
		}); */
	}
  	ChangePicture(i)
  	{
  		let product_details_new = this.state.product_details;
  		let glass_images_array = this.state.product_details[i].glass_images;
  		let model_images_array = this.state.product_details[i].model_images;
  		product_details_new[i].model_images = glass_images_array;
  		product_details_new[i].glass_images = model_images_array;

  		this.setState({product_details: product_details_new, show_loading_lottie: 0})
  	}
  	componentDidMount()
  	{
  		window.addEventListener('resize', this.handleWindowSizeChange);

  		const state = store.getState();
  		let product_details = state.products.items.products;
  		let banners = state.products.items.banners;
  		this.setState({
  			product_count: state.products.items.product_count, 
  			product_details: product_details,
  			banners: banners
  			//primary_image: state.products.items.products[0].model_images, 
  			//secondary_image: state.products.items.products[0].glass_images
  		}, () => {
	    	this.SetThemeColor(state);
	    	this.SetProductId(state);
	    	this.FetchingBanner();
		});
		
		if(window.location.pathname == "/products/eyeglasses/female")
        {
            var gender = window.location.pathname.substring(21, 27)
            var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname +'?path_source='+gender+'_listing'+ window.location.hash;    
            //window.history.pushState({ path: refresh }, '', refresh);
            this.setState({ path_source: gender+'_listing'});
        }
        if(window.location.pathname == "/products/eyeglasses/male")
        {
            var gender = window.location.pathname.substring(21, 25)
            var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname +'?path_source='+gender+'_listing'+ window.location.hash;    
            //window.history.pushState({ path: refresh }, '', refresh);
            this.setState({ path_source: gender+'_listing'});
        }
  	}
  	SetThemeColor(state)
  	{
  		var theme_color = this.state.theme_color;
  		let product_details = state.products.items.products;
  		for(var i=0; i <= product_details.length-1; i++)
  		{
  			theme_color.push(product_details[i].theme_color);
  		}
  		this.setState({
  			theme_color: theme_color, loading_lottie_web: 0,
  		});
  	}
  	SetProductId(state)
  	{
  		var lenskart_product_id = this.state.lenskart_product_id;
  		let product_details = state.products.items.products;
    	for(var i=0; i <= product_details.length-1; i++)
  		{
  			lenskart_product_id.push(String(product_details[i].lenskart_prod_id));
  		}
  		this.setState({
  			lenskart_product_id: lenskart_product_id
  		});
  	}
  	FetchingBanner()
  	{
	    var pl_header_men = this.state.pl_header_men;
	    var show_pl_header_men = this.state.show_pl_header_men;
	    var pl_header_women = this.state.pl_header_women;
	    var show_pl_header_women = this.state.show_pl_header_women;
	    var pl_header_collection = this.state.pl_header_collection;
	    var show_pl_header_collection = this.state.show_pl_header_collection;
	    var pl_header_sunglasses = this.state.pl_header_sunglasses;
	    var show_pl_header_sunglasses = this.state.show_pl_header_sunglasses;
	    var pl_coupon_banner_web = this.state.pl_coupon_banner_web;
	    var show_pl_coupon_banner_web = this.state.show_pl_coupon_banner_web;
	    var pl_coupon_banner_mob = this.state.pl_coupon_banner_mob;
	    var show_pl_coupon_banner_mob = this.state.show_pl_coupon_banner_mob;
	    
	    for(var i = 0; i<this.state.banners.length; i++)
	    {
	        if(this.state.banners[i].banner_type == "Eyeglasses Listing page - header_men" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_header_men.push(this.state.banners[i]);
	            show_pl_header_men = true;
	        }
	        if(this.state.banners[i].banner_type == "Eyeglasses Listing page - header_female" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_header_women.push(this.state.banners[i]);
	            show_pl_header_women = true;
	        }
	        if(this.state.banners[i].banner_type == "Eyeglasses Listing Page - header_collection" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_header_collection.push(this.state.banners[i]);
	            show_pl_header_collection = true;
	        }
	        if(this.state.banners[i].banner_type == "Sunglasses Listing Page - header_collection" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_header_sunglasses.push(this.state.banners[i]);
	            show_pl_header_sunglasses = true;
	        }
	        if(this.state.banners[i].banner_type == "Coupon Banner - PL" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_coupon_banner_web.push(this.state.banners[i]);
	            show_pl_coupon_banner_web = true;
	        }
	        if(this.state.banners[i].banner_type == "Coupon Banner - PL" && this.state.banners[i].banner_for_mobile_web != "")
	        {
	            pl_coupon_banner_mob.push(this.state.banners[i]);
	            show_pl_coupon_banner_mob = true;
	        }
	    }
	    this.setState({ pl_header_men: pl_header_men, show_pl_header_men: show_pl_header_men, 
	    	pl_header_women: pl_header_women, show_pl_header_women: show_pl_header_women,
	    	pl_header_collection: pl_header_collection, show_pl_header_collection: show_pl_header_collection,
	    	pl_header_sunglasses: pl_header_sunglasses, show_pl_header_sunglasses: show_pl_header_sunglasses,
	    	pl_coupon_banner_web: pl_coupon_banner_web, show_pl_coupon_banner_web: show_pl_coupon_banner_web, 
	        pl_coupon_banner_mob: pl_coupon_banner_mob, show_pl_coupon_banner_mob: show_pl_coupon_banner_mob
	    })
    }
  	ChangeColor(item_url,color_id,i)
	{	
		const { width } = this.state;
  		const isMobile = width <= 768;
  		if (isMobile) 
 		{
			let product_details_new = this.state.product_details;
	        product_details_new[this.state.page_index].model_images = [];
	        product_details_new[this.state.page_index].glass_images = [];
			this.setState({product_details: product_details_new, show_loading_lottie: 1})
			if(this.state.path_source != null)
			{
				if(this.state.path_source=="male_listing")
				{
					var gender = this.state.path_source.substring(0, 4);
				}
				else
				{
					var gender = this.state.path_source.substring(0, 6);
				}
				var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id+"?gender="+gender;
			}
			else
			{
				var gender = "";
				var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id;
			}

			fetch(url)
	        .then(response => response.json())
	        .then(products => {
	        	//console.log(products);
	        	let product_details_new = this.state.product_details;
	        	product_details_new[this.state.page_index].model_images = products.result.products.model_images;
	  			product_details_new[this.state.page_index].glass_images = products.result.products.glass_images;
	  			product_details_new[this.state.page_index].specifications.color_prod_id = products.result.products.specifications.color_prod_id;
	  			product_details_new[this.state.page_index].sold_out = products.result.products.sold_out;
	        	this.setState({product_details: product_details_new,  color_border: 'colour_border'})
			});	 
    	}
    	else
    	{
    		
    		this.setState({ loading_lottie_web: 1, choosen_index: i});
    		let product_details_new = this.state.product_details;
    		//product_details_new[i].model_images = [];
	        //product_details_new[i].glass_images = [];
	        
			if(this.state.path_source != null)
			{
				if(this.state.path_source=="male_listing")
				{
					var gender = this.state.path_source.substring(0, 4);
				}
				else
				{
					var gender = this.state.path_source.substring(0, 6);
				}
				var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id+"?gender="+gender;
			}
			else
			{
				var gender = "";
				var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id;
			}

			fetch(url)
	        .then(response => response.json())
	        .then(products => {
	        	//console.log(products);
	        	let product_details_new = this.state.product_details;
	        	product_details_new[i].model_images = products.result.products.model_images;
	  			product_details_new[i].glass_images = products.result.products.glass_images;
	  			product_details_new[i].specifications.color_prod_id = products.result.products.specifications.color_prod_id;
	  			product_details_new[i].sold_out = products.result.products.sold_out;
	        	this.setState({product_details: product_details_new,  color_border: 'colour_border'},()=> this.setState({loading_lottie_web: 0}))
			});	 
    	}
	}
  	render() {
 		const { width } = this.state;
  		const isMobile = width <= 768;
  		const count = '';
  		const anchors = this.state.lenskart_product_id;
  		console.log(this.state.theme_color);
  		if(this.state.theme_color.length > 0)
 		{
 			if (isMobile) 
 			{
 				return (
			    	<ReactFullpage
			    	dragAndMove={true}
			    	dragAndMoveKey={process.env.REACT_APP_DRAG}
			    	autoScrolling={true}
					scrollOverflow={false}
			        scrollingSpeed={100}
			        licenseKey='E3F87307-C97946A6-934478F4-A0CBA372'
			        //onLeave={this.onLeave.bind(this)}
			        //afterLoad={this.afterLoad.bind(this)}
			        sectionsColor={this.state.theme_color}
			        onLeave={this.onLeave}
        			afterLoad={this.afterLoad}
        			anchors={anchors}
			        render={({ state, fullpageApi }) => {
				        var rows = [];
				        this.state.product_details.map((item, i) => (
					      	rows.push(
								<div className="section" key={i}>
									<ImageView item={item} change_picture={this.ChangePicture} show_loading_lottie={this.state.show_loading_lottie} i={i} />
				    				<ProductTextArea item={item} colors={this.state.product_details[i].colors} color_border={this.state.color_border} change_color={this.ChangeColor} path_source={this.state.path_source}/>
			    				</div>
			    			)
					    ));
					    rows.push(
								<div className="section react_fullpage_last_section">
									<ProductListingLastSlide />
			    				</div>
			    			)
			        	return (
			        		rows
				    	);
				    }}
			      	/>
	    		);
	    	}
	    	else
	    	{
	    		return (
		    		<div className="hidden-xs">
			    		<DesktopHead count={count} pl_coupon_banner_web={this.state.pl_coupon_banner_web} show_pl_coupon_banner_web={this.state.show_pl_coupon_banner_web} 
			    			pl_header_men={this.state.pl_header_men} show_pl_header_men={this.state.show_pl_header_men}
			    			pl_header_women={this.state.pl_header_women} show_pl_header_women={this.state.show_pl_header_women}
			    			pl_header_collection={this.state.pl_header_collection} show_pl_header_collection={this.state.show_pl_header_collection}
			    			pl_header_sunglasses={this.state.pl_header_sunglasses} show_pl_header_sunglasses={this.state.show_pl_header_sunglasses}/>
			    		<div className="col-sm-12 product_list_div">
			    				{this.state.product_details.map((item, i) => (
				    				<div className="col-sm-4" style = {{ marginBottom : "50px"}}>
				    					<div class="col-sm-1"></div>
				    					<div class="col-sm-10 padding_is_zero" style= {{marginTop: "15px", marginBottom: "15px", backgroundColor: item.theme_color}} >
				    						<ImageViewDesktop item={item} change_picture={this.ChangePicture} i={i} change_color={this.ChangeColour} path_source={this.state.path_source} loading_lottie_web={this.state.loading_lottie_web} choosen_index={this.state.choosen_index}/>
				    						<div className="product_textarea_div">
				    							{/*<Link to={"/product/"+item.prod_url+"/color/"+item.specifications.color_prod_id}>*/}
						    						<ProductTextAreaDesktop item={item} i={i} colors={this.state.product_details[i].colors} color_border={this.state.color_border} change_color={this.ChangeColor} path_source={this.state.path_source}/>
						    					{/*</Link>*/}
				    						</div>		
						    			</div>
						    			
						    			<div class="col-sm-1"></div>
				    				</div>
				    			))}
				    			
			    		</div>
			    		<PageFooter />
			    		<Footer />
		    		</div>
	    		);
	    	}	
 		}
 		return <div>Loading...</div>;
		
 		
 		
  	}
  	
}


export default ProductPage;
/*

<Link to={"/product/"+item.product_id}></Link>
<a href={"#carousel__slide"+(index-1)}><i class="fa fa-angle-left product_details_corousel_left_web"></i></a>
<a href={"#carousel__slide"+(index+1)}><i class="fa fa-angle-right product_details_corousel_right_web"></i></a>
*/
