import React, { Component } from "react";
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import ClevertapReact from 'clevertap-react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Lottie from 'react-lottie';
import animationData from '../Lotties/62542-cool-chick.json';

class Profile extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            gender: 'Female',
            phone: '',
            token: localStorage.getItem('token'),
            redirect: false,
            redirectUrl: '/',
        };
        this.handleChange  = this.handleChange.bind(this);
        this.ChooseGender  = this.ChooseGender.bind(this);
        this.SaveProfile  = this.SaveProfile.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
    }
    componentDidMount()
    {
        if(this.state.token)
        {
            const query = new URLSearchParams(window.location.search);
            const redirect_value = query.get('redirect_url');
            if(redirect_value)
            {
                if(redirect_value.includes("cart"))
                {
                    this.setState({redirectUrl: '/checkout'});
                }
            }

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ this.state.token);
            var requestOptions = {
                headers: myHeaders,
            };
            fetch(process.env.REACT_APP_API_URL+'/v1/user', requestOptions)
            .then(response => response.json())
            .then(
            (res) => {
                console.log(res);
                this.setState({name: res.result.name, email: res.result.email, gender: res.result.gender, phone: res.result.phone});
                localStorage.setItem('name', res.result.name);
            });
        }
        else
        {
            this.setState({redirect: true, redirectUrl: '/login'}); 
        }
    }
    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }
    ChooseGender(gender)
    {
        var selected_gender = gender;
        this.setState({gender: selected_gender});
    }
    handleKeypress(e){
        //it triggers by pressing the enter key
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            this.SaveProfile();
        }
    }
    SaveProfile()
    {
        var name_text = this.state.name.replace(/ /g, "");
        if(name_text.length > 2 && this.state.email != '')
        {
            if(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.state.email))
            {
                
                var payload = {
                'Site': {
                    'name':     this.state.name,
                    'email':    this.state.email,
                    'phone':    this.state.phone,
                    'gender':   this.state.gender
                    }
                }
                ClevertapReact.profile(payload);

                var data = JSON.stringify({basic : {name : this.state.name, email : this.state.email, gender : this.state.gender}});
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer "+ localStorage.getItem('token'));
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: data,
                    redirect: 'follow'
                };
                let url = process.env.REACT_APP_API_URL+'/v1/user/basic';
                fetch(url, {
                    method: 'POST',
                    headers: myHeaders,
                    body: data,
                    redirect: 'follow'
                })
                .then(response => response.json())
                .then(
                    (res) => {
                        console.log(res.result.user.data.gender);
                        localStorage.setItem('name', res.result.user.data.name);
                        this.setState({
                            redirect: true
                        });
                    });
            }
            else
            {
                swal("Incorrect Email ID entered");
            }
        }
        else
        {
            swal("All fields are required");
        }
        
    }
    render() {
        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        };
        const styles = {
            checkAnimation: {
                textAlign: 'center',
                height: 'auto',
                width: '50%',
            },
        } 
        if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
		return(
			<div className="">
                <div className="row">
                    <div className="col-xs-12 each_input_label padding_is_zero">
                        <Box component="form" sx={{'& > :not(style)': { m: 0, width: '90%' },}} noValidateautoComplete="off">
                          <TextField id="outlined-basic" className="login_input_new" label="Name" variant="outlined" onChange={this.handleChange} onKeyPress={(e) => {this.handleKeypress(e)}} type="text" name="name" id="name" value={this.state.name} />
                        </Box>
                    </div>
                    <br/>
                    <div className="col-xs-12 each_input_label padding_is_zero">
                        <Box component="form" sx={{'& > :not(style)': { m: 0, width: '90%' },}} noValidateautoComplete="off">
                          <TextField id="outlined-basic" className="login_input_new" label="Email" variant="outlined" onChange={this.handleChange} onKeyPress={(e) => {this.handleKeypress(e)}} type="text" name="email" id="email" value={this.state.email} />
                        </Box>
                    </div>
                    <form className="form_gender cf each_input_label">
                        <p className="gender_header_text">Gender</p>
                        <section className="plan cf">
                            <div className="click_gender" onClick={()=>this.ChooseGender("Male")}><input type="radio" name="radio_gender" id="male" value="Male" className="gender_radio" checked = {this.state.gender === 'Male'} /><label className="four col">Male</label></div>
                            <div className="click_gender" onClick={()=>this.ChooseGender("Female")}><input type="radio" name="radio_gender" id="female" value="Female" className="gender_radio" checked = {this.state.gender === 'Female'}/><label className="four col">Female</label></div>
                            <div className="click_gender" onClick={()=>this.ChooseGender("Others")}><input type="radio" name="radio_gender" id="others" value="Others" className="gender_radio" checked = {this.state.gender === 'Others'}/><label className="four col">Others</label></div>
                        </section>
                    </form>
                    <div className=" col-xs-12 login_mob_container_lottie hidden-sm hidden-lg hidden-md">
                        <center ><Lottie  options={defaultOptions} style={styles.checkAnimation}/></center>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-12 modal-footer-login-footer hidden-sm hidden-lg hidden-md">
                        <center><button className=" btn select_get_otp" type="button" onClick={this.SaveProfile} onKeyPress={(e) => {this.handleKeypress(e)}}>Proceed</button></center>
                    </div> 
                    <button className=" btn select_get_otp hidden-xs" type="button" onClick={this.SaveProfile} onKeyPress={(e) => {this.handleKeypress(e)}}>Proceed</button>
                    
                </div>
            </div>
		);
		
	}
}

export default Profile;