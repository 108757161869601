import React, { Component } from "react";
import { Link } from 'react-router-dom';

class CheckoutAddAddress extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	user_data: this.props.user_data,
	    	name: this.props.user_data.name,
	    	mob: this.props.user_data.phone,
	    	email: this.props.user_data.email,
	    	country: 'India',
	    	addr1: '',
	    	addr2: '',
	    	city: '',
	    	state: '',
	    	pin: '',
	    }
	    this.myChangeHandler = this.myChangeHandler.bind(this);
	    this.myChangeHandlerNew = this.myChangeHandlerNew.bind(this);
	    this.SelectSavedAddr = this.SelectSavedAddr.bind(this);
	}
	myChangeHandler = (event) => {
	    let nam = event.target.name;
	    let val = event.target.value;
	    this.setState({[nam]: val});
	}
	myChangeHandlerNew = (event) => {
	    let nam = event.target.name;
	    let val = event.target.value;
	    if(nam == "city" || nam == "country" || nam == "state")
	    {
	    	val = val.replace(/[^A-Za-z ]/ig, '');
	    }
	    if(nam == "mob")
	    {
	    	val = val.replace(/[^0-9]/ig, '');
	    }

	    this.setState({[nam]: val});
	}
	SelectSavedAddr()
	{
		this.props.show_saved_address();
	}
  	render() {
		
		return (
			<div>
				<div className="padding_is_zero">
					<input type="text" className="new_address_input" onChange={this.myChangeHandlerNew} name="name" placeholder="Name" value={this.state.name} maxLength="35" />
    				<input type="text" className="new_address_input" onChange={this.myChangeHandlerNew} name="mob" placeholder="Mobile" value={this.state.mob} />
    				<input type="text" className="new_address_input" onChange={this.myChangeHandlerNew} name="email" placeholder="Email" value={this.state.email} />
    					
    				<input type="text" className="new_address_input" onChange={this.myChangeHandlerNew} name="addr1" placeholder="Apartment/House Number" value={this.state.addr1} autoComplete="nope"/>
			    	<input type="text" className="new_address_input" onChange={this.myChangeHandlerNew} name="addr2" placeholder="Locality, Street Address" value={this.state.addr2} autoComplete="nope"/>
			    	<input type="text" className="new_address_input" onChange={this.myChangeHandlerNew} name="city" placeholder="City" value={this.state.city} autoComplete="nope"/>
			    	<input type="number" className="new_address_input" onChange={this.myChangeHandlerNew} name="pin" placeholder="ZIP Code" value={this.state.pin} autoComplete="nope"/>
			    	<input type="text" className="new_address_input" onChange={this.myChangeHandlerNew} name="state" placeholder="State" value={this.state.state} autoComplete="nope"/>
			    	<input type="text" className="new_address_input" name="country" placeholder="Country" value={this.state.country} readonly />
			    		
			    	{this.props.user_data.address.length > 0 ?
				    	<div className="col-sm-6 padding_left_zero">
	    					<center><button className="saved_address_button_web" onClick={this.props.close_address}><i class="fa fa-angle-left"></i>&nbsp; Saved addresses</button></center>
						</div>
						:
						null
					}
					<div className="col-sm-6 padding_right_zero">
    					<center><button className="save_address_button_web" onClick={() => this.props.on_confirm(this.state.name,this.state.mob,this.state.email,this.state.country,this.state.addr1,this.state.addr2,this.state.city,this.state.state,this.state.pin)}>Save This Address</button></center>
					</div>
		    	</div>	
			</div>
		);
		
		
	}
}

export default CheckoutAddAddress;
