import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomePageCarouselWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video_mute: true,
    };
    this.UnmuteVideo = this.UnmuteVideo.bind(this);
  }
  UnmuteVideo() {
    var video = document.getElementById("sunglasses_video_web");
    if (video.muted) {
      video.muted = false;
    } else {
      video.muted = true;
    }
    this.setState({ video_mute: !this.state.video_mute });
  }

  render() {
    return (
      
            <div>
                {this.props.show_carousel_list_web ? 
                    <div
                      id="demo_web"
                      class="carousel slide"
                      data-bs-ride="carousel"
                      data-bs-interval="7000"
                    >
                        <div class="carousel-inner">
							<div className="homepage_landing_section_carousel_web carousel-item active">
                                <div className="col-sm-12 homepage_banner_img_web padding_is_zero">
                                    <img
                                      src="https://static.ojos21.com/bannerimages/1684491884_1920x907%20copy.jpg"
                                      width="100%"
                                      height="100%"
                                    />
                                    <div className="homepage_banner_right_text_web">
                                      <h1 className="landing_bold_header">
									  	We are now available on <a href="https://www.lenskart.com/" className="landing_bold_header"><u>Lenskart.com</u></a>
                                      </h1>
                                      <p className="landing_subtext_header">
									  	To get a new pair of OJOS wear, head to the Lenskart Website/App
                                      </p>
                                    </div>
                                 </div>
                            </div>
                        {/* <div class="homepage_landing_section_carousel_web carousel-item active">
                          <div className="col-sm-12 padding_is_zero">
                            <video
                              id="sunglasses_video_web"
                              width="100%"
                              autoPlay
                              muted
                              loop
                            >
                              <source
                                src="/videos/DesktopLaunchReel.mp4"
                                type="video/mp4"
                              />
                            </video>
                            {this.state.video_mute ? (
                              <img
                                src="/images/Header/MuteIcon.svg"
                                className="volume_img_muted"
                                onClick={this.UnmuteVideo}
                              />
                            ) : (
                              <img
                                src="/images/Header/VolumeIcon1.svg"
                                className="volume_img_unmuted"
                                onClick={this.UnmuteVideo}
                              />
                            )}
                            <div className="homepage_banner_video_text">
                              <center>
                                <h1 className="landing_bold_header_video_text">
                                  BANGIN' • RUSTIC • EYERONIC
                                </h1>
                                <p className="landing_subtext_header">
                                  Introducing the Candy Rush collection of metallic
                                  frames that gives you the extra edge
                                </p>
                              </center>
                              <div className="col-sm-12 padding_is_zero">
                                <center>
                                  <a href="/products/eyeglasses/featured">
                                    <button className="homepage_video_button_notified">
                                      Shop Collection
                                    </button>
                                  </a>
                                </center>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* {this.props.homepage_carousel_web.map((details, index) => (
                            <div className={index == 0 ? "homepage_landing_section_carousel_web carousel-item active" : "homepage_landing_section_carousel_web carousel-item"} key={index}>
                                <div className="col-sm-12 homepage_banner_img_web padding_is_zero">
                                    <img
                                      src={details.banner_for_desktop}
                                      width="100%"
                                      height="100%"
                                    />
                                    <div className="homepage_banner_right_text_web">
                                      <h1 className="landing_bold_header">
                                        {details.banner_title}
                                      </h1>
                                      <p className="landing_subtext_header">
                                        {details.banner_desc}
                                      </p>
                                      <div className="col-sm-12 landing_button_section_web padding_is_zero">
                                        {details.has_male_button == 1 ?
                                            <div className="col-sm-5 padding_left_0">
                                                <Link to="/products/eyeglasses/male?path_source=male_listing">
                                                    <button className="landing_button">{details.male_button_text}</button>
                                                </Link>
                                            </div>
                                            :
                                            null
                                        }
                                        {details.has_female_button == 1 ?
                                            <div className="col-sm-5 padding_left_0">
                                                <Link to="/products/eyeglasses/female?path_source=female_listing">
                                                    <button className="landing_button">{details.female_button_text}</button>
                                                </Link>
                                            </div>
                                            :
                                            null
                                        }
                                        {details.has_feature_button == 1 ?
                                            <div className="col-sm-5 padding_is_zero">
                                                <a href={details.feature_id_url}>
                                                    <button className="landing_button">{details.feature_button_text}</button>
                                                </a>
                                            </div>
                                            :
                                            null
                                        }
                                      </div>
                                        
                                        
                                      </div>
                                      
                                    
                                </div>
                            </div>
                        ))} */}

                        
                        {/*<div class="homepage_landing_section_carousel_web carousel-item">
                              <div className="col-sm-12 homepage_banner_img_web padding_is_zero">
                                <img
                                  src="/images/Header/DesktopHomepageCarousel.jpg"
                                  width="100%"
                                  height="100%"
                                />
                                <div className="homepage_banner_right_text_web">
                                  <h1 className="landing_bold_header">
                                    We take your sweet dreams seriously
                                  </h1>
                                  <p className="landing_subtext_header">
                                    Introducing the Candy Rush collection to make your look
                                    marvelous, colorful and maginificent
                                  </p>
                                  <div className="col-sm-12 landing_button_section_web padding_is_zero">
                                    <div className="col-sm-5 padding_is_zero">
                                      <a href="/products/eyeglasses/featured">
                                        <button className="landing_button">
                                          Explore Collection
                                        </button>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                        </div>
                        <div class="homepage_landing_section_carousel_web carousel-item active">
                          <div className="col-sm-12 homepage_banner_img_web padding_is_zero">
                            <img
                              src="/images/Header/DesktopHomepageCarousel.jpg"
                              width="100%"
                              height="100%"
                            />
                            <div className="homepage_banner_right_text_web">
                              <h1 className="landing_bold_header">
                                We take your sweet dreams seriously
                              </h1>
                              <p className="landing_subtext_header">
                                Introducing the Candy Rush collection to make your look
                                marvelous, colorful and maginificent
                              </p>
                              <div className="col-sm-12 landing_button_section_web padding_is_zero">
                                <div className="col-sm-5 padding_is_zero">
                                  <a href="/products/eyeglasses/featured">
                                    <button className="landing_button">
                                      Explore Collection
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="homepage_landing_section_carousel_web carousel-item ">
                          <div className="col-sm-12 homepage_banner_img_web padding_is_zero">
                            <img
                              src="/images/Header/DesktopHome_SunCarousel.jpg"
                              width="100%"
                              height="100%"
                            />
                            <div className="homepage_banner_right_text_web">
                              <h1 className="landing_bold_header">
                                BUSSIN' • PEPPY • QUIRKY
                              </h1>
                              <p className="landing_subtext_header">
                                Introducing a range of colorful, vibrant, quirky and bussin'
                                OJ Sunglasses
                              </p>
                              <div className="col-sm-12 landing_button_section_web padding_is_zero">
                                <div className="col-sm-5 padding_is_zero">
                                  <a href="/products/sunglasses">
                                    <button className="landing_button">
                                      Shop Collection
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          
                        </div>
                        <div class="homepage_landing_section_carousel_web carousel-item">
                          <div className="col-sm-12 homepage_banner_img_web padding_is_zero">
                            <img
                              src="/images/Header/DesktopCarousel.jpg"
                              width="100%"
                              height="100%"
                            />
                            <div className="homepage_banner_right_text_web">
                              <h1 className="landing_bold_header">
                                Eyewear that makes you fun, young & bold!
                              </h1>
                              <p className="landing_subtext_header">
                                Get handpicked designer eyeglasses at a flat affordable
                                price, with a new collection every month.
                                <br />
                            </p>
                              <div className="col-sm-12 landing_button_section_web padding_is_zero">
                                <div className="col-sm-5 padding_left_0">
                                  <Link to="/products/eyeglasses/male?path_source=male_listing">
                                    <button className="landing_button">Shop Men’s</button>
                                  </Link>
                                </div>
                                <div className="col-sm-5 padding_left_0">
                                  <Link to="/products/eyeglasses/female?path_source=female_listing">
                                    <button className="landing_button">Shop Women's</button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>*/}
                        {/*<div class="homepage_landing_section_carousel_web carousel-item">
                                            <div className="col-sm-12 homepage_landing_section_web">
                                                <div className="col-sm-6">
                                                    <center><img src="/images/Header/HomeLandingDesktop-1-minAug.png" alt="banner2" className="img-responsive landing_page_img_web"/></center>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div className="homepage_landing_section_web_textarea">
                                                        <h1 className="landing_bold_header">Unleash your inner <br/> DRAMEBAAZ</h1>
                                                        <p className="landing_subtext_header">Get drama ready for a freaky Bollywood festival right here at OJOS Wear</p>
                                                        <div className="col-sm-12 landing_button_section_web padding_is_zero">
                                                            <div className="col-sm-4 padding_left_0">
                                                                <a href="/products/featured"><button className="landing_header_button_mob">Explore Collection</button></a> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div className="col-sm-1"></div>
                                            </div>
                                        </div>*/}
                        {/*<div class="homepage_landing_section_carousel_web carousel-item">
                                            <div className="col-sm-12 homepage_landing_section_web">
                                                <div className="col-sm-6">
                                                    <center><img src="/images/Header/Desktop_HomeLandingOJSuper.png" alt="banner3" className="img-responsive landing_page_img_web"/></center>
                                                </div>
                                                <div class="col-sm-5 homepage_landing_section_web_textarea">
                                                    <h1 className="landing_bold_header_intro">introducing</h1>
                                                    <h1 className="landing_bold_header_oj_super">OJ SUPER</h1>
                                                    <div className="landing_slide_subscription_section">
                                                        <span className="landing_subtext_header_second">Subscribe for 1 year & Get</span> 
                                                        <span className="landing_subtext_diff_header">3 Eyeglasses <br/>with Premium Lenses </span>
                                                        <span className="landing_subtext_header_second">at just </span>
                                                        <span className="landing_subtext_diff_header"> Rs. 700 each </span>
                                                    </div>
                                                    <div className="">
                                                        <div className="col-sm-4 padding_left_0">
                                                            <Link to="/subscription-plan"><button className="landing_header_button_mob">Know More</button></Link>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div className="col-sm-1"></div>
                                            </div>
                                        </div>*/}
                        </div>
                        {/* <button class="carousel-control-prev" type="button">
                            <span
                              class="carousel-control-prev-icon"
                              data-bs-target="#demo_web"
                              data-bs-slide="prev"
                            ></span>
                        </button>
                        <button class="carousel-control-next" type="button">
                            <span
                              class="carousel-control-next-icon"
                              data-bs-target="#demo_web"
                              data-bs-slide="next"
                            ></span>
                        </button> */}
                    </div>
                    :
                    null
                } 
            </div>
        );

    }
}
export default HomePageCarouselWeb;
