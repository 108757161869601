import React, { Component } from "react";
import { Link } from 'react-router-dom';

class CheckoutItemDetailsNew extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	 
	    } 
	}
	componentDidMount()
	{
		
  	}
  	render() {
  		var saved_credit_text = this.props.cart_details.billing.saved_credit/700 > 1 ? "Credits" : "Credit";
		var saved_credit_text_capital = this.props.cart_details.billing.saved_credit/700 > 1 ? "CREDITS" : "CREDIT"; 
  		return (
  			<div>

				<br/>
				{this.props.credits_in_wallet > 0 ?
					<div>
						<div className="col-xs-12 show_free_product_section"></div>
    					<div class="show_free_product">
    						{this.props.credit_product_array.map((each_item, index) => (
	    						<div>
	    						{each_item.sold_out == 1 ?
	    							<div className="each_section_sold_out each_item_section_sold_out" key={index}>
		    							<div className="col-xs-3">
				    						<center><a href={"/product/"+each_item.url+"/color/"+each_item.product_id} target="_blank"><img src={each_item.image} className="img-responsive cart_product_image each_item_section_sold_out"/></a></center>
				    						<p className="checkout_sold_out_text">SOLD OUT</p>
					    				</div>
					    				<div className="col-xs-5 padding_is_zero each_item_section_sold_out">
					    					<a href={"/product/"+each_item.url+"/color/"+each_item.product_id} target="_blank"><p className="checkout_brand_name_new">{each_item.name}</p></a>
					    					<p className="checkout_gender_size_text">{each_item.gender}  | {each_item.size}</p>
					    				</div>
					    				<div className="col-xs-4 checkout_each_amount each_item_section_sold_out">
					    					<span className="checkout_cost_display"><p className="product_striked_cost_new"><s>₹ {each_item.striked_cost}</s></p></span>
					    					<p className="checkout_use_credit_text p_margin_bottom_zero">{each_item.qty > 1 ? each_item.qty+" OJ SUPER Credits Used" : each_item.qty+" OJ SUPER Credit Used" }</p>
					    				</div>
					    				<div className="clearfix"></div>
					    				<div className="col-xs-3"><center><p className="checkout_plus_symbol p_margin_bottom_zero">+</p></center></div>
					    				<div className="col-xs-9"></div>
					    				<div className="clearfix"></div>
				    					<div className="col-xs-5">
				    						{each_item.prescription_type == 'sunglasses' ?
				    							<div className="checkout_pres_div_all">
				    								<div className="checkout_pres_div">
				    									<p className="checkout_pres_text_p checkout_prescription_text">ZP</p>
				    								</div>
				    								<p className="checkout_pres_text_right">Zero Power</p>
				    							</div>
				    							:
				    							<div>
				    							{each_item.prescription_type == "Single Vision" ?
				    								<div className="checkout_pres_div_all">
					    								<div className="checkout_pres_div">
					    									<p className="checkout_pres_text_p checkout_prescription_text">SV</p>
					    								</div>
					    								<p className="checkout_pres_text_right">Single Vision</p>
					    							</div>
													:
													<div>
													{each_item.prescription_type == "Blu Cut Single Vision" ?
														<div className="checkout_pres_div_all">
						    								<div className="checkout_pres_div">
						    									<p className="checkout_pres_text_blu checkout_prescription_text">B</p>
						    								</div>
						    								<p className="checkout_pres_text_right">Single Vision BLU Cut</p>
						    							</div>
														:
														<div className="checkout_pres_div_all">
						    								<div className="checkout_pres_div">
						    									<p className="checkout_pres_text_p checkout_prescription_text">ZP B</p>
						    								</div>
						    								<p className="checkout_pres_text_right">{each_item.prescription_type} BLU Cut</p>
						    							</div>
													}
													</div>
				    							}
												
												</div>
											}
										</div>
				    					<div className="col-xs-7 checkout_each_amount">
				    						<span className=""><p className="product_striked_cost_new"><s>₹ {each_item.lens_striked_cost}</s></p></span>
					    					<span className="">{each_item.lens_cost > 0 ? <p className="checkout_free_text_new">₹ {each_item.lens_cost}</p> : <p className="checkout_free_pres_type">FREE</p>}</span>
				    					</div>


					    				<div className="clearfix"></div>
					    				{this.props.device == "mob" ?
						    				<div className="col-xs-4">
					    						<div className="product_qty_area">
					    							<i className="fa fa-minus checkout_qty_icon_new" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
					    							<input type="text" value={each_item.qty} className="qty_input_new" readonly/>
					    							<i className="fa fa-plus checkout_qty_icon_new" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
					    						</div>
					    					</div>
					    					:
					    					<div className="col-xs-3">
					    						<div className="checkout_product_qty_area_new">
					    							<i className="fa fa-minus checkout_qty_add_sub_new" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
					    							<input type="text" value={each_item.qty} className="qty_input_new" readonly/>
					    							<i className="fa fa-plus checkout_qty_add_sub_new" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
					    						</div>
					    					</div>
				    					}
					    				<div className={this.props.device == "mob" ? "col-xs-8 checkout_each_amount" : "col-xs-9 checkout_each_amount"}>
											<p className="checkout_each_item_total">₹ {(each_item.qty*(parseFloat(each_item.lens_cost))).toFixed(2)}</p>
										</div>

				    					
					    				<div className="clearfix"></div>
		    						</div>
	    						:
		    						<div className="each_item_section" key={index}>
		    							<div className="col-xs-3">
		    								<div className="checkout_glass_img_div">
				    							<center><a href={"/product/"+each_item.url+"/color/"+each_item.product_id} target="_blank"><img src={each_item.image} className="img-responsive cart_product_image"/></a></center>
				    						</div>
					    				</div>
					    				<div className="col-xs-5 padding_is_zero">
					    					<a href={"/product/"+each_item.url+"/color/"+each_item.product_id} target="_blank"><p className="checkout_brand_name_new">{each_item.name}</p></a>
					    					<p className="checkout_gender_size_text">{each_item.gender}  | {each_item.size}</p>
					    				</div>
					    				<div className="col-xs-4 checkout_each_amount">
					    					<span className="checkout_cost_display"><p className="product_striked_cost_new"><s>₹ {each_item.striked_cost}</s></p></span>
					    					<p className="checkout_use_credit_text p_margin_bottom_zero">{each_item.qty > 1 ? each_item.qty+" OJ SUPER Credits Used" : each_item.qty+" OJ SUPER Credit Used" }</p>
					    				</div>
					    				<div className="clearfix"></div>
					    				<div className="col-xs-3"><center><p className="checkout_plus_symbol p_margin_bottom_zero">+</p></center></div>
					    				<div className="col-xs-9"></div>
					    				<div className="clearfix"></div>
				    					<div className="col-xs-5">
				    						{each_item.prescription_type == 'sunglasses' ?
				    							<div className="checkout_pres_div_all">
				    								<div className="checkout_pres_div">
				    									<p className="checkout_pres_text_p checkout_prescription_text">ZP</p>
				    								</div>
				    								<p className="checkout_pres_text_right">Zero Power</p>
				    							</div>
				    							:
				    							<div>
				    							{each_item.prescription_type == "Single Vision" ?
				    								<div className="checkout_pres_div_all">
					    								<div className="checkout_pres_div">
					    									<p className="checkout_pres_text_p checkout_prescription_text">SV</p>
					    								</div>
					    								<p className="checkout_pres_text_right">Single Vision</p>
					    							</div>
													:
													<div>
													{each_item.prescription_type == "Blu Cut Single Vision" ?
														<div className="checkout_pres_div_all">
						    								<div className="checkout_pres_div">
						    									<p className="checkout_pres_text_blu checkout_prescription_text">B</p>
						    								</div>
						    								<p className="checkout_pres_text_right">Single Vision BLU Cut</p>
						    							</div>
														:
														<div className="checkout_pres_div_all">
						    								<div className="checkout_pres_div">
						    									<p className="checkout_pres_text_p checkout_prescription_text">ZP B</p>
						    								</div>
						    								<p className="checkout_pres_text_right">{each_item.prescription_type} BLU Cut</p>
						    							</div>
													}
													</div>
				    							}
												
												</div>
											}
										</div>
				    					<div className="col-xs-7 checkout_each_amount">
				    						<span className=""><p className="product_striked_cost_new"><s>₹ {each_item.lens_striked_cost}</s></p></span>
					    					<span className="">{each_item.lens_cost > 0 ? <p className="checkout_free_text_new">₹ {each_item.lens_cost}</p> : <p className="checkout_free_pres_type">FREE</p>}</span>
				    					</div>
					    				<div className="clearfix"></div>
					    				{this.props.device == "mob" ?
						    				<div className="col-xs-4">
					    						<div className="product_qty_area">
					    							<i className="fa fa-minus checkout_qty_icon_new" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
					    							<input type="text" value={each_item.qty} className="qty_input_new" readonly/>
					    							<i className="fa fa-plus checkout_qty_icon_new" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
					    						</div>
					    					</div>
					    					:
					    					<div className="col-xs-3">
					    						<div className="checkout_product_qty_area_new">
					    							<i className="fa fa-minus checkout_qty_add_sub_new" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
					    							<input type="text" value={each_item.qty} className="qty_input_new" readonly/>
					    							<i className="fa fa-plus checkout_qty_add_sub_new" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
					    						</div>
					    					</div>
				    					}

					    				<div className={this.props.device == "mob" ? "col-xs-8 checkout_each_amount" : "col-xs-9 checkout_each_amount"}>
				    						<p className="checkout_each_item_total">₹ {(each_item.qty*(parseFloat(each_item.lens_cost))).toFixed(2)}</p>
				    					</div>
				    					
					    				<div className="clearfix"></div>
		    						</div>
	    						}
	    						</div>
	    					))}
    					</div>
    					<div className="clearfix"></div>
					</div>
					:
					null
				}
				{this.props.non_credit_product_array.length > 0 ?
					<div className="show_cost_product">
						
    					{this.props.non_credit_product_array.map((each_item, index) => (
    						<div>
    						{each_item.sold_out == 1 ?
    							<div className="each_section_sold_out each_item_section_sold_out" key={index}>
	    							<div className="col-xs-3">
			    						<center><a href={"/product/"+each_item.url+"/color/"+each_item.product_id} target="_blank"><img src={each_item.image} className="img-responsive cart_product_image each_item_section_sold_out"/></a></center>
			    						<p className="checkout_sold_out_text">SOLD OUT</p>
				    				</div>
				    				<div className="col-xs-5 padding_is_zero each_item_section_sold_out">
				    					<a href={"/product/"+each_item.url+"/color/"+each_item.product_id} target="_blank"><p className="checkout_brand_name_new">{each_item.name}</p></a>
				    					<p className="checkout_gender_size_text">{each_item.gender}  | {each_item.size}</p>
				    				</div>
				    				<div className="col-xs-4 checkout_each_amount each_item_section_sold_out">
				    					<span className="checkout_cost_display"><p className="product_striked_cost_new"><s>₹ {each_item.striked_cost}</s></p></span>
				    					<p className="checkout_each_product_amount">₹ {each_item.cost}</p>
				    					<br/>
				    					{this.props.cart_details.cart_list.map((each_item_sub, index) => (
				    						<div key={index}>
				    							{each_item_sub.type == "subscription" && each_item_sub.subscription_id == 2 ?
				    							<p class="checkout_use_credit_text p_margin_bottom_zero">{each_item.qty > 1 ? each_item.qty+" OJ SUPER Credits Used" : each_item.qty+" OJ SUPER Credit Used" }</p>
				    							:
				    							null
				    							}
				    						</div>
				    					))}
				    				</div>
				    				<div className="clearfix"></div>
				    				<div className="col-xs-3"><center><p className="checkout_plus_symbol p_margin_bottom_zero">+</p></center></div>
				    				<div className="col-xs-9"></div>
				    				<div className="clearfix"></div>
			    					<div className="col-xs-5">
			    						{each_item.prescription_type == 'sunglasses' ?
			    							<div className="checkout_pres_div_all">
			    								<div className="checkout_pres_div">
			    									<p className="checkout_pres_text_p checkout_prescription_text">ZP</p>
			    								</div>
			    								<p className="checkout_pres_text_right">Zero Power</p>
			    							</div>
			    							:
			    							<div>
			    							{each_item.prescription_type == "Single Vision" ?
			    								<div className="checkout_pres_div_all">
				    								<div className="checkout_pres_div">
				    									<p className="checkout_pres_text_p checkout_prescription_text">SV</p>
				    								</div>
				    								<p className="checkout_pres_text_right">Single Vision</p>
				    							</div>
												:
												<div>
												{each_item.prescription_type == "Blu Cut Single Vision" ?
													<div className="checkout_pres_div_all">
					    								<div className="checkout_pres_div">
					    									<p className="checkout_pres_text_blu checkout_prescription_text">B</p>
					    								</div>
					    								<p className="checkout_pres_text_right">Single Vision BLU Cut</p>
					    							</div>
													:
													<div className="checkout_pres_div_all">
					    								<div className="checkout_pres_div">
					    									<p className="checkout_pres_text_p checkout_prescription_text">ZP B</p>
					    								</div>
					    								<p className="checkout_pres_text_right">{each_item.prescription_type} BLU Cut</p>
					    							</div>
												}
												</div>
			    							}
											
											</div>
										}
									</div>
			    					<div className="col-xs-7 checkout_each_amount">
			    						<span className=""><p className="product_striked_cost_new"><s>₹ {each_item.lens_striked_cost}</s></p></span>
				    					<span className="">{each_item.lens_cost > 0 ? <p className="checkout_free_text_new">₹ {each_item.lens_cost}</p> : <p className="checkout_free_pres_type">FREE</p>}</span>
			    					</div>
				    				<div className="clearfix"></div>
				    				
				    				{this.props.device == "mob" ?
					    				<div className="col-xs-4">
				    						<div className="product_qty_area">
				    							<i className="fa fa-minus checkout_qty_icon_new" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
				    							<input type="text" value={each_item.qty} className="qty_input_new" readonly/>
				    							<i className="fa fa-plus checkout_qty_icon_new" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
				    						</div>
				    					</div>
				    					:
				    					<div className="col-xs-3">
				    						<div className="checkout_product_qty_area_new">
				    							<i className="fa fa-minus checkout_qty_add_sub_new" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
				    							<input type="text" value={each_item.qty} className="qty_input_new" readonly/>
				    							<i className="fa fa-plus checkout_qty_add_sub_new" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
				    						</div>
				    					</div>
			    					}
				    				<div className={this.props.device == "mob" ? "col-xs-8 checkout_each_amount" : "col-xs-9 checkout_each_amount"}>
				    					<p className="checkout_each_item_total">₹ {(each_item.qty*(parseFloat(each_item.cost) + parseFloat(each_item.lens_cost))).toFixed(2)}</p>
				    				</div>
				    				
				    				<div className="clearfix"></div>
				    			</div>
    							:
	    						<div className="each_item_section" key={index}>
	    							<div className="col-xs-3">
			    						<center><a href={"/product/"+each_item.url+"/color/"+each_item.product_id} target="_blank"><img src={each_item.image} className="img-responsive cart_product_image"/></a></center>
				    				</div>
				    				<div className="col-xs-5 padding_is_zero">
				    					<a href={"/product/"+each_item.url+"/color/"+each_item.product_id} target="_blank"><p className="checkout_brand_name_new">{each_item.name}</p></a>
				    					<p className="checkout_gender_size_text">{each_item.gender}  | {each_item.size}</p>
				    				</div>
				    				<div className="col-xs-4 checkout_each_amount">
				    					<span className="checkout_cost_display"><p className="product_striked_cost_new"><s>₹ {each_item.striked_cost}</s></p></span>
				    					<p className="checkout_each_product_amount">₹ {each_item.cost}</p>
				    					{this.props.cart_details.cart_list.map((each_item_sub, index) => (
				    						<div key={index}>
				    							{each_item_sub.type == "subscription" && each_item_sub.subscription_id == 2 ?
				    							<p class="checkout_use_credit_text p_margin_bottom_zero">{each_item.qty > 1 ? each_item.qty+" OJ SUPER Credits Used" : each_item.qty+" OJ SUPER Credit Used" }</p>
				    							:
				    							null
				    							}
				    						</div>
				    					))}
				    					
				    				</div>
				    				<div className="clearfix"></div>
				    				<div className="col-xs-3"><center><p className="checkout_plus_symbol p_margin_bottom_zero">+</p></center></div>
				    				<div className="col-xs-9"></div>
				    				<div className="clearfix"></div>
			    					<div className="col-xs-5">
			    						{each_item.prescription_type == 'sunglasses' ?
			    							<div className="checkout_pres_div_all">
			    								<div className="checkout_pres_div">
			    									<p className="checkout_pres_text_p checkout_prescription_text">ZP</p>
			    								</div>
			    								<p className="checkout_pres_text_right">Zero Power</p>
			    							</div>
			    							:
			    							<div>
			    							{each_item.prescription_type == "Single Vision" ?
			    								<div className="checkout_pres_div_all">
				    								<div className="checkout_pres_div">
				    									<p className="checkout_pres_text_p checkout_prescription_text">SV</p>
				    								</div>
				    								<p className="checkout_pres_text_right">Single Vision</p>
				    							</div>
												:
												<div>
												{each_item.prescription_type == "Blu Cut Single Vision" ?
													<div className="checkout_pres_div_all">
					    								<div className="checkout_pres_div">
					    									<p className="checkout_pres_text_blu checkout_prescription_text">B</p>
					    								</div>
					    								<p className="checkout_pres_text_right">Single Vision BLU Cut</p>
					    							</div>
													:
													<div className="checkout_pres_div_all">
					    								<div className="checkout_pres_div">
					    									<p className="checkout_pres_text_p checkout_prescription_text">ZP B</p>
					    								</div>
					    								<p className="checkout_pres_text_right">{each_item.prescription_type} BLU Cut</p>
					    							</div>
												}
												</div>
			    							}
											
											</div>
										}
									</div>
			    					<div className="col-xs-7 checkout_each_amount">
			    						<span className=""><p className="product_striked_cost_new"><s>₹ {each_item.lens_striked_cost}</s></p></span>
				    					<span className="">{each_item.lens_cost > 0 ? <p className="checkout_free_text_new">₹ {each_item.lens_cost}</p> : <p className="checkout_free_pres_type">FREE</p>}</span>
			    					</div>
			    					<div className="clearfix"></div>
				    				{this.props.device == "mob" ?
					    				<div className="col-xs-4">
				    						<div className="product_qty_area">
				    							<i className="fa fa-minus checkout_qty_icon_new" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
				    							<input type="text" value={each_item.qty} className="qty_input_new" readonly/>
				    							<i className="fa fa-plus checkout_qty_icon_new" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
				    						</div>
				    					</div>
				    					:
				    					<div className="col-xs-3">
				    						<div className="checkout_product_qty_area_new">
				    							<i className="fa fa-minus checkout_qty_add_sub_new" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
				    							<input type="text" value={each_item.qty} className="qty_input_new" readonly/>
				    							<i className="fa fa-plus checkout_qty_add_sub_new" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
				    						</div>
				    					</div>
			    					}
				    				
				    				<div className={this.props.device == "mob" ? "col-xs-8 checkout_each_amount" : "col-xs-9 checkout_each_amount"}>
				    					<p className="checkout_each_item_total">₹ {(each_item.qty*(parseFloat(each_item.cost) + parseFloat(each_item.lens_cost))).toFixed(2)}</p>
				    				</div>
				    				
				    				<div className="clearfix"></div>

				    			</div>
			    			}
			    			</div>
    					))}
					</div>
					:
					null
				}
				{/*{this.props.cart_details.cart_list.map((each_item, index) => (
					<div key={index}>
						{each_item.type == "subscription" && each_item.subscription_id == 2 && this.props.cart_details.billing.saved_credit > 0?
							<div className="each_item_section" key={index}>
	 							<div className="col-xs-3 checkout_vertical_line_section_super_ojos">
			 						<center><img src='/images/Header/OJ SUPER.png' className="img-responsive checkout_super_ojos_img"/></center>
				 				</div>
				 				<div className="col-xs-7 padding_is_zero">
				 					<p className="checkout_super_oj_credit_heading">x {this.props.cart_details.billing.saved_credit/700} Eyeglasses {saved_credit_text} saved for later</p>
				 					<div className="checkout_super_oj_credit_desc">
				 						{this.props.cart_details.billing.saved_credit/700} BUY LATER {saved_credit_text_capital} will be added to your account. Use these credits to shop eyeglasses anytime in next 1 year for FREE.
				 					</div>
				 				</div>
				 				<div className="col-xs-2 checkout_each_amount">
				 					<p className="checkout_reamining_credits_right">+ {this.props.cart_details.billing.saved_credit/700}</p>
				 					<span className="checkout_cost_display"><p className="checkout_buy_later_credits_text">Buy Later {saved_credit_text} </p></span>
				 				</div>
				 				<div className="clearfix"></div>
				 			</div>
							:
							null
						}
					</div>
				:
					null
				))}*/}
				<div className="col-xs-12 checkout_edit_button_div">
					{this.props.device == "web" ?
						<button className="btn checkout_add_address_continue" onClick={this.props.open_address_accordion}>Add Address To Continue</button>
						:
						null
					}
				</div>
				
			</div>
		);
  	}
}
export default CheckoutItemDetailsNew;
