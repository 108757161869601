import React from 'react'; 
  
const PageFooter=()=> 
{
return (
			<div className="col-sm-12 help_div">
	    			<center>
	    				<p className="trouble_text">We're here to help!</p>
	    				<p className="contact_text">You can <a href="/contact-us" className="contact_us_footer_text"><u>Contact Us</u></a> here!</p>
	    			</center>
	    	</div>
	    	
    );
}
 
  
export default PageFooter;