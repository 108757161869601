import React, { Component } from "react";
import AddNewAddress from "./AddNewAddress";
import $ from 'jquery';

class SelectAddress extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	user_data: this.props.user_data,
	    	save_add_address: this.props.save_add_address,
	    	select_address_id: this.props.user_data.address[0].id
	    }
	    this.myChangeHandler = this.myChangeHandler.bind(this);
	    this.HandleOnChange = this.HandleOnChange.bind(this);
	}
	myChangeHandler = (event) => {
	    let nam = event.target.name;
	    let val = event.target.value;
	    this.setState({[nam]: val});
	}
	HandleOnChange(id) {
    	var selected_option = id;
    	var address_id = selected_option.substring(8);
    	this.setState({ select_address_id: address_id});
    	$('input.quiz_checkbox').on('change', function() {
            $('input.quiz_checkbox').not(this).prop('checked', false);  
        });
  	}
	render() {
		console.log("Selected Address"+this.state.select_address_id);
		return (
					
					<div className="select_address">
						<div id="myModal" className="modal-edited modal" data-spy="scroll"  data-offset="20">
							<div className="modal-address modal-address-content modal-content-edited modal-content">
							<div className="col-sm-3"></div>
								<div className="col-sm-6 padding_is_zero">
									<div className="address-modal-header modal-header-edited modal-header">
							    		<div className="col-xs-12 add_address_header">
							    			<button type="button" className="address_close close" aria-label="Close" onClick={() => this.props.close_address()}>
									        	<span aria-hidden="true">&times;</span>
									        </button>
							    			<center><p className="header_address_text">SELECT ADDRESS</p></center>
							    		</div>
							    	</div>
							    	<div className="address-modal-body">
							    		<p className="select_address_text">SELECT DELIVERY ADDRESS</p>
							    		{this.state.user_data.address.map((item, i) => (
							    			<div className="col-xs-12 each_select_address_mob" key={i} onClick={()=>this.HandleOnChange("address_"+item.id)}>
												<div className="cart_quiz_card_area">
													<input className="quiz_checkbox" type="radio" name="address_selected" id={"address_"+item.id} checked = {this.state.select_address_id == item.id}/>
													<div className={this.state.select_address_id == item.id ? "single_quiz_card_cart_mob_selected":"single_quiz_card_cart_mob"}>
														<div className="quiz_card_content">
															<div className={this.state.select_address_id == item.id ? "quiz_card_title_cart_mob_selected" : "quiz_card_title_cart_mob "}>
																<p>{item.name}'s Address</p>
																<div className={this.state.select_address_id == item.id ? "cart_address_detail_mob_selected" : "cart_address_detail_mob"}>
																	<p>{item.phone} | {this.props.user_data.email}</p>
																	<p>{item.shipping_address+", "+item.ship_city+", "+item.ship_state+", "+item.ship_code}</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
							    		))}
							    		<div className="col-xs-12 select_add_address_div">
							    			<div className="col-xs-1"></div>
							    			<div className="col-xs-10">
				    							<a data-toggle="collapse" href="#collapse1" onClick={() => this.props.show_add_address()}><button className="add_new_address" >+ ADD NEW ADDRESS</button></a>
				    						</div>
				    						<div className="col-xs-1"></div>
				    					</div>
							    	</div>
							    	<div className="col-xs-12 col-sm-6 modal-footer-edited modal-footer">
							    		<center><button className="save_address_button" onClick={() => this.props.address_id(this.state.select_address_id)}>PROCEED WITH SELECTED ADDRESS</button></center>
				    				</div>
			    				</div>
			    				<div className="col-sm-3"></div>
							</div>
						</div>
					</div>
					
		    	);
		
	}
}


export default SelectAddress;
/*
<div className="col-xs-12 each_select_address" key={i} onClick={()=>this.HandleOnChange("address_"+item.id)}>
	<input type="radio" id={"address_"+item.id} name="active_combo" checked = {this.state.select_address_id == item.id}/>
	<label className="select_address_label">Deliver To: {item.name}'s Address</label>
	<br/>
	<p className="select_address_subtext">{item.phone} | {this.state.user_data.email}</p>
	<p className="select_address_subtext">{item.shipping_address+", "+item.ship_city+", "+item.ship_state+", "+item.ship_code}</p>
</div>

<div className="col-xs-12 each_select_address_mob" key={i} onClick={()=>this.HandleOnChange("address_"+item.id)}>
												<div  className="cart_quiz_card_area">
													<input className="quiz_checkbox" type="radio" name="address_selected" id={"address_"+item.id} checked = {this.state.select_address_id == item.id}/>
													<div className="single_quiz_card_cart">
														<div className="quiz_card_content">
															<div className="quiz_card_title_cart ">
																<p>{item.name}'s Address</p>
																<div className="cart_address_detail">
																	<p>{item.phone} | {this.props.user_data.email}</p>
																	<p>{item.shipping_address+", "+item.ship_city+", "+item.ship_state+", "+item.ship_code}</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
*/
/*
<a data-toggle="collapse" href="#collapse1" onClick={this.ShowAddressSave}><p className="new_address_accordion" >ADD NEW ADDRESS</p></a>
<div id="collapse1" className="collapse address_form">
    <AddressInputForm />
</div>
								    */

