import React, { Component } from "react";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import PageFooter from "../ProductListing/PageFooter";
import Footer from "../Common/Footer";
import store from "../../Redux/store";
import DeliveryCheck from "../Common/DeliveryCheck/DeliveryCheck";
import ProductDescription from "./ProductDescription";
import ProductCostSection from "./ProductCostSection";
import MeasurementSection from "./MeasurementSection";
import ProductStaticFeature from "./ProductStaticFeature";
import ProductLenseFeature from "./ProductLenseFeature";
import ProductDynamicFeature from "./ProductDynamicFeature";
import ProductDesktopImages from "./ProductDesktopImages";
import ProductImages from "./ProductImages";
import ProductPackage from "./ProductPackage";
import ProductPackageDesktop from "./ProductPackageDesktop";
import AlertBox from "../Common/AlertBox";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2-react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import ReactGA from "react-ga";

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      each_product: "",
      product_count: "",
      product_details: [{ glass_images: [], colors: [], specifications: "" }],
      add_bag: "add_to_bag_unclick",
      pres: "no",
      width: window.innerWidth,
      color_id: "",
      access_token: localStorage.getItem("token"),
      show_toast: "",
      alert_msg: "",
      show_product_image: false,
      cart_count: 0,
      color_border: "colour_border",
      size: "",
      gender: "male",
      path_source: "",
      display_frame: "",
      image_type: "frame",
      scrollTop: 0,
      display_hide: "",
      active_overview: "active_overview",
      add_to_cart: true,
      cyl_values1: "-",
      cyl_values2: "-",
      sph_values1: "-",
      sph_values2: "-",
      lense_background: "lense_background_purple",
      prevPath: "",
      redirect_page: false,
      lenskart_prod_id: "",
      sold_out: 0,
      show_lense_details: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.ChangeColor = this.ChangeColor.bind(this);
    this.ChangePrescription = this.ChangePrescription.bind(this);
    this.MoreImages = this.MoreImages.bind(this);
    this.MoreImagesModel = this.MoreImagesModel.bind(this);
    this.ClickTabOverView = this.ClickTabOverView.bind(this);
    this.ClickTabDetails = this.ClickTabDetails.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.RedirectBack = this.RedirectBack.bind(this);
    this.SelectPackage = this.SelectPackage.bind(this);
    this.SelectPrescription = this.SelectPrescription.bind(this);
    this.ChangePackage = this.ChangePackage.bind(this);
    this.ShowPowerRange = this.ShowPowerRange.bind(this);
    this.AvailablePower = this.AvailablePower.bind(this);
    this.myRef = React.createRef();
    this.myRef_overview = React.createRef();
    this.myRef_details = React.createRef();
  }
  componentDidMount() {
    const state = store.getState();
    let product_details = state.each_products.items.products;
    let prod_count = localStorage.getItem("prod_counts");
    if (prod_count) {
      var total_qty = 0;
      let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
      for (var i = 0; i <= product_id_array.length - 1; i++) {
        if (product_id_array[i] !== 0) {
          total_qty =
            total_qty +
            JSON.parse(localStorage.getItem("prod_" + product_id_array[i])).qty;
        }
      }
      this.setState({
        cart_count: total_qty,
      });
    }
    this.setState(
      {
        product_count: state.each_products.items.product_count,
        product_details: product_details,
      },
      () =>
        this.setState({
          color_id: this.state.product_details.specifications.color_prod_id,
          gender: this.state.product_details.gender,
          lenskart_prod_id: this.state.product_details.lenskart_prod_id,
          sold_out: this.state.product_details.sold_out,
        })
    );
    window.addEventListener("scroll", this.handleScroll, { passive: true });

    this.setState(
      { path_source: this.props.path_source, prevPath: this.props.prevPath },
      () => {
        //window.history.pushState({ path: this.state.prevPath.prevPath  }, '', this.state.prevPath.prevPath );
      }
    );
  }
  ClickTabOverView() {
    this.myRef_overview.current.scrollIntoView();
    this.setState({ display_frame: "scroll_frame_image_fade_in" });
    this.setState({ active_overview: "", scrollTop: 0 });
  }
  ClickTabDetails() {
    this.myRef_details.current.scrollIntoView();
    this.setState({ display_frame: "scroll_frame_image_fade_in" });
    this.setState({ active_overview: "", scrollTop: 0 });
  }

  handleScroll(e) {
    if (!this.state.show_product_image) {
      const { width } = this.state;
      const isMobile = width <= 768;
      if (isMobile) {
        /*scroll_frame_image_fade */
        const scrollY = window.scrollY;
        const scrollTop = this.myRef.current.scrollTop;
        /*this.setState({
			      scrollTop: scrollTop
			    })*/
        //console.log(scrollTop);
        //console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`)
        if (scrollTop < 100) {
          this.setState({ display_frame: "scroll_frame_image_fade_in" });
          $("#frame_images").css("opacity", 1);
        } else {
          //this.setState({ display_frame: 'scroll_frame_image_fade_out' });
          var opacity = 1 - scrollTop / 250;
          $("#frame_images").css("opacity", 1 - scrollTop / 250);
          if (opacity < 0) {
            this.setState({ display_frame: "scroll_frame_image_fade_out" });
          }
        }
      }
    }
  }
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  ChangeColor(item_url, color_id) {
    if (this.state.path_source != null) {
      if (this.state.path_source == "male_listing") {
        var gender = this.state.path_source.substring(0, 4);
      } else {
        var gender = this.state.path_source.substring(0, 6);
      }
      var url =
        process.env.REACT_APP_API_URL +
        "/v1/product/" +
        item_url +
        "/colorid/" +
        color_id +
        "?gender=" +
        gender;
    } else {
      var gender = "";
      var url =
        process.env.REACT_APP_API_URL +
        "/v1/product/" +
        item_url +
        "/colorid/" +
        color_id;
    }

    fetch(url)
      .then((response) => response.json())
      .then((products) => {
        let product_details_new = this.state.product_details;
        product_details_new.model_images =
          products.result.products.model_images;
        product_details_new.glass_images =
          products.result.products.glass_images;
        product_details_new.specifications =
          products.result.products.specifications;
        this.setState(
          {
            product_details: product_details_new,
            color_id: products.result.products.specifications.color_prod_id,
            color_border: "colour_border",
            show_lense_details: false,
            add_bag: "add_to_bag",
            add_to_cart: true,
            lenskart_prod_id: products.result.products.lenskart_prod_id,
            sold_out: products.result.products.sold_out,
          },
          () => {
            this.AvailablePower();
          }
        );
        this.setState({ display_frame: "scroll_frame_image_fade_in" });
      });
  }

  handleChange() {
    var cart_count = this.state.cart_count;
    if (this.state.pres == "no") {
      swal("Please select Prescription Type");
    } else {
      this.setState({ add_to_cart: false });
      //store a prod_id with product and increase each time
      // save products with diff key
      let prod_ids = [];
      let prod_data = {};
      let prod_id = this.state.product_details.specifications.color_prod_id;

      prod_data = {
        product_id: prod_id,
        qty: 1,
        prescription_type: this.state.pres,
      };

      //step 1
      prod_ids = JSON.parse(localStorage.getItem("prod_counts"));

      if (Array.isArray(prod_ids)) {
        prod_ids.indexOf(prod_id) === -1
          ? prod_ids.push(prod_id)
          : console.log("checking");
        this.CheckExistingItem(prod_id);
      } else {
        prod_ids = [];
        prod_ids.push(prod_id);
        this.setState({
          alert_msg: "Item Added to the Cart!",
          show_toast: "",
          add_to_cart: false,
        });
        setTimeout(() => {
          this.setState({ show_toast: "" });
        }, 1000);
        this.setState({
          cart_count: cart_count + 1,
        });
      }
      localStorage.setItem("prod_counts", JSON.stringify(prod_ids));

      // step 2
      localStorage.setItem("prod_" + prod_id, JSON.stringify(prod_data));

      // step 3
      if (this.state.access_token) {
        this.postCart();
      }
      /*ReactGA.plugin.require('ec');
			ReactGA.plugin.execute("ec", "add_to_cart", {
			    "id": this.state.product_details.specifications.color_prod_id,
			    "name": this.state.product_details.short_name,
			    "brand": this.state.product_details.brand_name,
			    "category": this.state.product_details.type,
			    "quantity": 1,
			    "price": this.state.product_details.ojos_price
			});

			ReactGA.plugin.execute("ec", "send");
			ReactGA.plugin.execute("ec", "clear");*/

      /*ReactGA.plugin.require('ecommerce');
		    ReactGA.plugin.execute(
		      'ecommerce',
		      'addItem',
		      {
		        id: this.state.product_details.specifications.color_prod_id,
		        name: this.state.product_details.short_name,
		        sku: this.state.product_details.prod_url,
		        price: this.state.product_details.ojos_price,
		        category: this.state.product_details.type,
		        quantity: '1',
		      }
		    );
		    ReactGA.plugin.execute('ecommerce', 'send');
		    ReactGA.plugin.execute('ecommerce', 'clear');*/

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          detail: {
            actionField: { list: "Shopping cart" },

            products: [
              {
                id: this.state.product_details.specifications.color_prod_id,
                name: this.state.product_details.short_name,
                brand: this.state.product_details.brand_name,
                category: this.state.product_details.type,
                quantity: 1,
                price: this.state.product_details.ojos_price,
              },
            ],
          },
        },
      });
    }
  }
  CheckExistingItem(prod_id) {
    /*Checking if item already exist in local storage when clicking add to bag*/
    var prod_ids = JSON.parse(localStorage.getItem("prod_counts"));
    var cart_count = this.state.cart_count;
    if (prod_ids.indexOf(prod_id) !== -1) {
      this.setState({
        alert_msg: "Item Updated with Prescription.",
        show_toast: "",
        add_to_cart: false,
      });
      setTimeout(() => {
        this.setState({ show_toast: "" });
      }, 1000);
    } else {
      this.setState({
        alert_msg: "Item Added to the Cart!",
        show_toast: "",
        add_to_cart: false,
      });
      setTimeout(() => {
        this.setState({ show_toast: "" });
      }, 1000);
      this.setState({
        cart_count: cart_count + 1,
      });
    }
  }
  postCart() {
    if (this.state.access_token) {
      let data = [];
      let key = "";
      let prod_data = [];
      let prod_ids = JSON.parse(localStorage.getItem("prod_counts"));

      prod_ids.forEach((item) => {
        key = "prod_" + item;

        prod_data = JSON.parse(localStorage.getItem(key));
        data.push(prod_data);
      });

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + this.state.access_token);

      // prod_ids = JSON.stringify({'product_ids': prod_ids});
      // let url = process.env.REACT_APP_API_URL+'/v1/cart/list';

      data = JSON.stringify({ cart_data: data });
      let url = process.env.REACT_APP_API_URL + "/v1/cart";

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      };

      fetch(url, requestOptions).then((response) => response.json());
    }
  }

  ChangePrescription(event) {
    /*if(event.target.value==='no'){
            this.setState({
                add_bag: 'add_to_bag_unclick',
                pres: event.target.value,
            });
        }
        else if(event.target.value==='Single Vision')
        {
        	
	        var span = document.createElement("span");
	        span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><center><div class='loader'></div></center></div>";
			swal({
			    content: span,
			    buttons: false
			});
			let url_power = process.env.REACT_APP_API_URL+'/v1/product/'+this.state.color_id+'/power';
			fetch(url_power)
	        .then(response => response.json())
	        
	        .then(data => {
	        	console.log(data);
	        	if(data.success == true)
	        	{
	        		var cyl_values1 = data.data.cyl_values[0];
	        		var cyl_values2 = data.data.cyl_values[data.data.cyl_values.length-1];
	        		var sph_values1 = data.data.sph_values[0];
	        		var sph_values2 = data.data.sph_values[data.data.sph_values.length-1];
	        		this.setState({cyl_values1: cyl_values1, cyl_values2: cyl_values2, sph_values1: sph_values1, sph_values2: sph_values2}, () => {
    					span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><b>This frame supports lenses of a specific power range.</b> <br/> Check your power availability below: <table style='margin-left: auto;margin-right: auto;margin-top: 20px;'><tr style='background-color: #EBEBEB;'><th style='padding: 10px 30px;'>SPH</th><th style='padding: 10px 30px;'>"+this.state.sph_values1+" to "+this.state.sph_values2+"</th></tr><tr style='background-color: #F6F6F6;'><th style='padding: 10px 30px;'>CYL</th><th style='padding: 10px 30px;'>"+this.state.cyl_values1+" to "+this.state.cyl_values2+"</th></tr></table><center><img src='/images/Header/LogoOnPower.svg' style='margin-top: 25px; width: 25px;' /></center></div>";
						swal({
						    content: span,
						});	
					});
	        	}
	        	else
	        	{
	        		//swal("Power details not fetched for this product")
	        	}
			});
			this.setState({
                add_bag: 'add_to_bag',
                pres: event.target.value,

            });
            this.setState({ lense_background: 'lense_background_purple' })
			setTimeout(
			  	() => this.setState({ lense_background: 'lense_background_white' }), 
			  	5000
		  	)
        }
        else{
            this.setState({
                add_bag: 'add_to_bag',
                pres: event.target.value
            });
            this.setState({ lense_background: 'lense_background_purple' })
            setTimeout(
			  	() => this.setState({ lense_background: 'lense_background_white' }), 
			  	5000
		  	)
        }*/
  }

  MoreImages() {
    this.setState({
      show_product_image: !this.state.show_product_image,
      image_type: "frame",
    });
  }
  MoreImagesModel() {
    this.setState({
      show_product_image: !this.state.show_product_image,
      image_type: "model",
    });
  }
  SelectPackage() {
    this.setState({
      show_lense_details: !this.state.show_lense_details,
    });
  }
  SelectPrescription(value) {
    this.setState({ show_lense_details: !this.state.show_lense_details });
    if (value == "Single Vision") {
      this.ShowPowerRange();
      this.setState({
        add_bag: "add_to_bag",
        pres: value,
      });
      this.setState({ lense_background: "lense_background_purple" });
      setTimeout(
        () => this.setState({ lense_background: "lense_background_white" }),
        5000
      );
    } else if (value == "Zero Power") {
      this.setState({
        add_bag: "add_to_bag",
        pres: value,
      });
      this.setState({ lense_background: "lense_background_purple" });
      setTimeout(
        () => this.setState({ lense_background: "lense_background_white" }),
        5000
      );
    } else {
      this.setState({
        add_bag: "add_to_bag_unclick",
        pres: "no",
      });
    }
  }
  AvailablePower() {
    if (this.state.pres == "Single Vision") {
      this.ShowPowerRange();
    }
  }
  ShowPowerRange() {
    var span = document.createElement("span");
    span.innerHTML =
      "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><center><div class='loader'></div></center></div>";
    swal({
      content: span,
      buttons: false,
    });
    let url_power =
      process.env.REACT_APP_API_URL +
      "/v1/product/" +
      this.state.color_id +
      "/power";
    fetch(url_power)
      .then((response) => response.json())

      .then((data) => {
        console.log(data);
        if (data.success == true) {
          var cyl_values1 = data.data.cyl_values[0];
          var cyl_values2 =
            data.data.cyl_values[data.data.cyl_values.length - 1];
          var sph_values1 = data.data.sph_values[0];
          var sph_values2 =
            data.data.sph_values[data.data.sph_values.length - 1];
          this.setState(
            {
              cyl_values1: cyl_values1,
              cyl_values2: cyl_values2,
              sph_values1: sph_values1,
              sph_values2: sph_values2,
            },
            () => {
              span.innerHTML =
                "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><b>This frame supports lenses of a specific power range.</b> <br/> Check your power availability below: <table style='margin-left: auto;margin-right: auto;margin-top: 20px;'><tr style='background-color: #EBEBEB;'><th style='padding: 10px 30px;'>SPH</th><th style='padding: 10px 30px;'>" +
                this.state.sph_values1 +
                " to " +
                this.state.sph_values2 +
                "</th></tr><tr style='background-color: #F6F6F6;'><th style='padding: 10px 30px;'>CYL</th><th style='padding: 10px 30px;'>" +
                this.state.cyl_values1 +
                " to " +
                this.state.cyl_values2 +
                "</th></tr></table><center><img src='/images/Header/LogoOnPower.svg' style='margin-top: 25px; width: 25px;' /></center></div>";
              swal({
                content: span,
              });
            }
          );
        } else {
          //swal("Power details not fetched for this product")
        }
      });
  }
  ChangePackage() {
    this.setState({
      show_lense_details: !this.state.show_lense_details,
      add_to_cart: true,
    });
  }
  RedirectBack() {
    /*window.history.back();*/
    //console.log(window.history.length);
    //var refresh = window.history.go(-1)
    //console.log(this.state.prevPath);
    //window.location.href = this.state.prevPath;
    //this.props.history.push(this.state.prevPath)
    this.setState({
      redirect_page: true,
    });
  }
  render() {
    const { width } = this.state;
    const isMobile = width <= 768;
    console.log(this.state.product_details);
    if (this.state.product_count === 1) {
      if (isMobile) {
        if (this.state.redirect_page) {
          if (
            this.state.prevPath.prevPath == "/products/eyeglasses/male" ||
            this.state.prevPath.prevPath == "/products/eyeglasses/female" ||
            this.state.prevPath.prevPath == "/products/sunglasses"
          ) {
            return (
              <Redirect
                to={
                  this.state.prevPath.prevPath +
                  "#" +
                  this.state.lenskart_prod_id
                }
              />
            );
          } else {
            return <Redirect to={this.state.prevPath.prevPath} />;
          }
        }
        return (
          <div className="product_details">
            {/*<Header color={"#160D08"} cart_count={this.state.cart_count}/>*/}
            {!this.state.show_product_image ? (
              <div
                id="myModal"
                className="modal"
                data-spy="scroll"
                data-offset="20"
              >
                <div className="product-details-modal-content modal-content">
                  <div className="product_details_modal_header modal-header">
                    <div className="col-xs-12 padding_is_zero product_details_mob_header">
                      <div className="col-xs-1">
                        <i
                          className="fa fa-angle-left product_details_close close"
                          aria-hidden="true"
                          onClick={this.RedirectBack}
                        ></i>
                      </div>
                      <div className="col-xs-7">
                        {/*<p className={"product_company_text " +this.state.display_frame}>{this.state.product_details.brand_name}</p>*/}
                        <p className="extra_bold_text product_name product_details_product_name">
                          {this.state.product_details.short_name}
                        </p>
                        {this.state.product_details.colors.map(
                          (item, index) => (
                            <div>
                              {this.state.color_id === item.id &&
                              item.urjency_text ? (
                                <p className="product_details_urgency_text">
                                  {item.urjency_text}
                                </p>
                              ) : null}
                            </div>
                          )
                        )}
                      </div>
                      <div className="col-xs-4 header_product_details_right_icon">
                        {this.state.access_token != "" ? (
                          <Link to="/account">
                            <img
                              src="/images/Header/Profile_blue.svg"
                              className="header_product_details_icon"
                              alt="profile"
                            />
                          </Link>
                        ) : (
                          <Link to="/login">
                            <img
                              src="/images/Header/Profile_blue.svg"
                              className="header_product_details_icon"
                              alt="profile"
                            />
                          </Link>
                        )}
                        <Link
                          to={"/cart?path_source=" + this.state.path_source}
                        >
                          <img
                            src="/images/Header/cart_blue.svg"
                            className="header_product_details_icon"
                            alt="cart"
                          />
                        </Link>

                        <div>
                          {this.state.cart_count > 0 ? (
                            <div>
                              {this.state.path_source == "" ? (
                                <Link to={"/cart"}>
                                  <p className="product_details_cart_product_count">
                                    {this.state.cart_count}
                                  </p>{" "}
                                </Link>
                              ) : (
                                <Link
                                  to={
                                    "/cart?path_source=" +
                                    this.state.path_source
                                  }
                                >
                                  <p className="product_details_cart_product_count">
                                    {this.state.cart_count}
                                  </p>{" "}
                                </Link>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <ProductImages
                      images={this.state.product_details.glass_images}
                      show_more_image={this.MoreImages}
                      display_frame={this.state.display_frame}
                      display_hide={this.state.display_hide}
                      image_clicks={this.state.image_clicks}
                    />
                    <div className="tab_menu tab_menu_mob">
                      <ul className="product_details_nav_mob nav">
                        <li className="tab_name">
                          <a
                            className={
                              this.state.active_overview + " tab_name_mob"
                            }
                            onClick={this.ClickTabOverView}
                          >
                            Overview
                          </a>
                        </li>
                        <li className="tab_name">
                          <a
                            onClick={this.ClickTabDetails}
                            className="tab_name_mob"
                          >
                            Details
                          </a>
                        </li>
                      </ul>
                      {this.state.product_details.model_images.length > 0 ? (
                        <Link
                          to={
                            "/product/" +
                            this.state.product_details.prod_url +
                            "/" +
                            this.state.lenskart_prod_id +
                            "/color/" +
                            this.state.product_details.specifications
                              .color_prod_id
                          }
                        >
                          <button className="product_details_try_on_button">
                            <img src="/images/Header/product_details_awesome-glasses.svg" />
                          </button>
                        </Link>
                      ) : null}
                    </div>
                  </div>

                  <div
                    className="modal-body product-details-modal"
                    ref={this.myRef}
                    onScroll={this.handleScroll}
                  >
                    <div id="overview" ref={this.myRef_overview}>
                      <ProductCostSection />
                      <ProductLenseFeature
                        lense_feature={this.state.pres}
                        lense_background={this.state.lense_background}
                        available_power={this.AvailablePower}
                      />
                      <div className="col-xs-12 product_company">Features</div>
                      <ProductDynamicFeature
                        features={this.state.product_details.features}
                      />
                      <div className="col-xs-12 product_company">
                        Deliver in 7 - 10 working days
                      </div>
                      <ProductStaticFeature />
                      <div className="clearfix"></div>
                    </div>
                    <hr />
                    <div className="delivery_section">
                      <DeliveryCheck
                        product_id={this.state.product_details.product_id}
                      />
                    </div>
                    <hr />
                    <div id="details" ref={this.myRef_details}>
                      <div className="col-xs-12 product_company">
                        Description
                      </div>
                      <ProductDescription
                        description={this.state.product_details.description}
                      />
                      <div className="col-xs-12 product_company">
                        Specifications
                      </div>
                      <MeasurementSection
                        specifications={
                          this.state.product_details.specifications
                        }
                      />
                    </div>
                    <hr />
                  </div>
                  <div className="modal-footer">
                    <div className="col-xs-12">
                      <div className="col-xs-6 padding_is_zero footer_size_color">
                        <p className="product_footer_text margin-top-5">
                          SIZE:{" "}
                        </p>
                        <p className="product_footer_text footer_size_box">
                          {this.state.product_details.specifications.frame_size.charAt(
                            0
                          )}
                        </p>
                        <Link to="/product-size">
                          <p className="product_footer_text footer_size_guide_text">
                            <u>SIZE GUIDE</u>
                          </p>
                        </Link>
                      </div>
                      <div className="col-xs-6 padding_is_zero footer_size_color footer_color_section">
                        <p className="product_footer_text margin-top-5">
                          COLOUR:{" "}
                        </p>
                        {this.state.product_details.colors.map(
                          (item, index) => (
                            <div>
                              {this.state.color_id === item.id ? (
                                <div>
                                  {item.sold_out == 1 ? (
                                    <div className="transparent_colour_border">
                                      <img
                                        src="/images/Header/red_striked_circle.svg"
                                        className="color_striked_img"
                                        onClick={() =>
                                          this.ChangeColor(
                                            this.state.product_details.prod_url,
                                            item.id
                                          )
                                        }
                                      />
                                      <span
                                        className="colour_dot_product_details"
                                        key={index}
                                        style={{
                                          backgroundColor: item.hex_code,
                                        }}
                                      ></span>
                                    </div>
                                  ) : (
                                    <div className={this.state.color_border}>
                                      <span
                                        className="colour_dot_product_details"
                                        key={index}
                                        style={{
                                          backgroundColor: item.hex_code,
                                        }}
                                        onClick={() =>
                                          this.ChangeColor(
                                            this.state.product_details.prod_url,
                                            item.id
                                          )
                                        }
                                      ></span>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {item.sold_out == 1 ? (
                                    <div className="transparent_colour_border">
                                      <img
                                        src="/images/Header/white_striked_circle.svg"
                                        className="color_striked_img"
                                        onClick={() =>
                                          this.ChangeColor(
                                            this.state.product_details.prod_url,
                                            item.id
                                          )
                                        }
                                      />
                                      <span
                                        className="colour_dot_product_details"
                                        key={index}
                                        style={{
                                          backgroundColor: item.hex_code,
                                        }}
                                      ></span>
                                    </div>
                                  ) : (
                                    <div className="transparent_colour_border">
                                      <span
                                        className="colour_dot_product_details"
                                        key={index}
                                        style={{
                                          backgroundColor: item.hex_code,
                                        }}
                                        onClick={() =>
                                          this.ChangeColor(
                                            this.state.product_details.prod_url,
                                            item.id
                                          )
                                        }
                                      ></span>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-xs-12">
                      {this.state.sold_out == 0 ? (
                        <div>
                          {this.state.product_details.active == 1 ||
                          this.state.product_details.live_status == 1 ? (
                            <div>
                              {!this.state.show_lense_details ? (
                                <div>
                                  {this.state.pres == "no" ? (
                                    <center>
                                      <button
                                        className="btn select_lense_button"
                                        onClick={this.SelectPackage}
                                      >
                                        SELECT LENSES
                                      </button>
                                    </center>
                                  ) : (
                                    <div>
                                      {this.state.add_to_cart == true ? (
                                        <div>
                                          <p className="product_footer_text footer_size_color">
                                            LENS PACKAGE:{" "}
                                            <b className="lens_package_text">
                                              {this.state.pres}
                                            </b>
                                            <u
                                              className="change_package_text"
                                              onClick={this.ChangePackage}
                                            >
                                              Change
                                            </u>
                                          </p>
                                          <center>
                                            <button
                                              className={
                                                "btn " + this.state.add_bag
                                              }
                                              onClick={this.handleChange}
                                            >
                                              ADD TO CART
                                            </button>
                                          </center>
                                        </div>
                                      ) : (
                                        <div>
                                          <p className="product_footer_text footer_size_color">
                                            LENS PACKAGE:{" "}
                                            <b className="lens_package_text">
                                              {this.state.pres}
                                            </b>
                                            <u
                                              className="change_package_text"
                                              onClick={this.ChangePackage}
                                            >
                                              Change
                                            </u>
                                          </p>
                                          {this.state.path_source == "" ||
                                          this.state.path_source == null ? (
                                            <Link to={"/cart"}>
                                              <center>
                                                <button className="btn go_to_bag">
                                                  GO TO CART
                                                </button>
                                              </center>
                                            </Link>
                                          ) : (
                                            <Link
                                              to={
                                                "/cart?path_source=" +
                                                this.state.path_source
                                              }
                                            >
                                              <center>
                                                <button className="btn go_to_bag">
                                                  GO TO CART
                                                </button>
                                              </center>
                                            </Link>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <ProductPackage
                                  select_package={this.SelectPackage}
                                  select_prescription={this.SelectPrescription}
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <center>
                          <button className="btn sold_out_button">
                            SOLD OUT
                          </button>
                        </center>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-sm-12">
                <div className="col-sm-12">
                  <button
                    type="button"
                    class="close more_image_close_button"
                    onClick={this.MoreImages}
                  >
                    &times;
                  </button>
                </div>
                <div className="col-sm-12 more_image_section">
                  <center>
                    <div>
                      {this.props.gender_url == "Unisex" ? (
                        <div>
                          {this.state.product_details.glass_images.map(
                            (image_item, index) => (
                              <img
                                src={image_item.url}
                                className="img-responsive show_more_image_img"
                                id="frame_img"
                                name="frame_img"
                                alt="frame_img"
                              />
                            )
                          )}
                          {this.state.product_details.model_images.map(
                            (image_item, index) => (
                              <img
                                src={image_item.url}
                                className="img-responsive show_more_image_img"
                                id="frame_img"
                                name="frame_img"
                                alt="frame_img"
                              />
                            )
                          )}
                        </div>
                      ) : (
                        <div>
                          {this.state.product_details.glass_images.map(
                            (image_item, index) => (
                              <img
                                src={image_item.url}
                                className="img-responsive show_more_image_img"
                                id="frame_img"
                                name="frame_img"
                                alt="frame_img"
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </center>
                </div>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div className="product_details">
            <Header color={"#160D08"} cart_count={this.state.cart_count} />
            {!this.state.show_product_image ? (
              <div className="col-sm-12 product_details_web_body padding_is_zero">
                <div className="col-sm-12 web_breadcrumb">
                  <Link to="/" className="web_breadcrumb_text">
                    <p>HOME</p>
                  </Link>
                  <p className="web_breadcrumb_text"> | EYEGLASSES - </p>
                  <p className="web_breadcrumb_text">{this.state.gender}</p>
                </div>
                <div className="col-sm-7 left_section_web">
                  <ProductDesktopImages
                    product_details={this.state.product_details}
                    gender_url={this.props.gender_url}
                    more_images={this.MoreImages}
                    more_model_images={this.MoreImagesModel}
                  />
                  <div className="col-sm-12 product_brand_section_web">
                    <div className="col-xs-12">
                      <p className="product_company_text">
                        {this.state.product_details.brand_name}
                      </p>
                    </div>
                    <div className="col-xs-12" ref={this.myRef_overview}>
                      <p className="product_name product_details_product_name">
                        {this.state.product_details.short_name}
                      </p>
                    </div>
                    <div className="col-xs-12">
                      {this.state.product_details.colors.map((item, index) => (
                        <div>
                          {this.state.color_id === item.id &&
                          item.urjency_text ? (
                            <p className="product_details_urgency_text">
                              {item.urjency_text}
                            </p>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-sm-12 product_tab_section_web">
                    <div className="tab_menu">
                      <ul className="nav">
                        <li className="tab_name">
                          <a
                            className={this.state.active_overview}
                            onClick={this.ClickTabOverView}
                          >
                            Overview
                          </a>
                        </li>
                        <li className="tab_name">
                          <a onClick={this.ClickTabDetails}>Details</a>
                        </li>
                      </ul>
                      {this.state.product_details.model_images.length > 0 ? (
                        <Link
                          to={
                            "/product/" +
                            this.state.product_details.prod_url +
                            "/" +
                            this.state.lenskart_prod_id +
                            "/color/" +
                            this.state.product_details.specifications
                              .color_prod_id
                          }
                        >
                          <button className="product_details_try_on_button_web">
                            <img src="/images/Header/product_details_awesome-glasses.svg" />
                          </button>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12 product_tab_section_web">
                    <div id="overview">
                      <div className="col-xs-12 product_company">Features</div>
                      <ProductDynamicFeature
                        features={this.state.product_details.features}
                      />
                      <div className="col-xs-12 product_company">
                        Deliver in 7 - 10 working days
                      </div>
                      <ProductStaticFeature />
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="col-sm-12 product_tab_section_web">
                    <div className="delivery_section" ref={this.myRef_details}>
                      <DeliveryCheck
                        product_id={this.state.product_details.product_id}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 product_tab_section_web">
                    <div id="details">
                      <div className="col-xs-12 product_company">
                        Description
                      </div>
                      <ProductDescription
                        description={this.state.product_details.description}
                      />
                      <div className="col-xs-12 product_company">
                        Specifications
                      </div>
                      <MeasurementSection
                        specifications={
                          this.state.product_details.specifications
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 right_section_web">
                  <div className="col-sm-12">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10 product_tab_left_add padding_is_zero">
                      <div className="col-sm-12">
                        <div className="col-sm-6 padding_is_zero footer_size_color">
                          <p className="product_footer_text">SIZE: </p>
                          <p className="product_footer_text footer_size_box">
                            {this.state.product_details.specifications.frame_size.charAt(
                              0
                            )}
                          </p>
                          <Link to="/product-size">
                            <p className="product_footer_text footer_size_guide_text">
                              <u>SIZE GUIDE</u>
                            </p>
                          </Link>
                        </div>
                        <div className="col-sm-6 padding_is_zero footer_size_color footer_color_section">
                          <p className="product_footer_text">COLOUR: </p>
                          {this.state.product_details.colors.map(
                            (item, index) => (
                              <div>
                                {this.state.color_id === item.id ? (
                                  <div>
                                    {item.sold_out == 1 ? (
                                      <div className="transparent_colour_border">
                                        <img
                                          src="/images/Header/red_striked_circle.svg"
                                          className="color_striked_img"
                                          onClick={() =>
                                            this.ChangeColor(
                                              this.state.product_details
                                                .prod_url,
                                              item.id
                                            )
                                          }
                                        />
                                        <span
                                          className="colour_dot_product_details"
                                          key={index}
                                          style={{
                                            backgroundColor: item.hex_code,
                                          }}
                                        ></span>
                                      </div>
                                    ) : (
                                      <div className={this.state.color_border}>
                                        <span
                                          className="colour_dot_product_details"
                                          key={index}
                                          style={{
                                            backgroundColor: item.hex_code,
                                          }}
                                          onClick={() =>
                                            this.ChangeColor(
                                              this.state.product_details
                                                .prod_url,
                                              item.id
                                            )
                                          }
                                        ></span>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {item.sold_out == 1 ? (
                                      <div className="transparent_colour_border">
                                        <img
                                          src="/images/Header/white_striked_circle.svg"
                                          className="color_striked_img"
                                          onClick={() =>
                                            this.ChangeColor(
                                              this.state.product_details
                                                .prod_url,
                                              item.id
                                            )
                                          }
                                        />
                                        <span
                                          className="colour_dot_product_details"
                                          key={index}
                                          style={{
                                            backgroundColor: item.hex_code,
                                          }}
                                        ></span>
                                      </div>
                                    ) : (
                                      <div className="transparent_colour_border">
                                        <span
                                          className="colour_dot_product_details"
                                          key={index}
                                          style={{
                                            backgroundColor: item.hex_code,
                                          }}
                                          onClick={() =>
                                            this.ChangeColor(
                                              this.state.product_details
                                                .prod_url,
                                              item.id
                                            )
                                          }
                                        ></span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      <div className="col-xs-12">
                        {this.state.sold_out == 0 ? (
                          <div>
                            {this.state.product_details.active == 1 ||
                            this.state.product_details.live_status == 1 ? (
                              <div>
                                {!this.state.show_lense_details ? (
                                  <div>
                                    {this.state.pres == "no" ? (
                                      <center>
                                        <button
                                          className="btn select_lense_button"
                                          onClick={this.SelectPackage}
                                        >
                                          SELECT LENSES
                                        </button>
                                      </center>
                                    ) : (
                                      <div>
                                        {this.state.add_to_cart == true ? (
                                          <div>
                                            <p className="product_footer_text footer_size_color">
                                              LENS PACKAGE:{" "}
                                              <b className="lens_package_text">
                                                {this.state.pres}
                                              </b>
                                              <u
                                                className="change_package_text"
                                                onClick={this.ChangePackage}
                                              >
                                                Change
                                              </u>
                                            </p>
                                            <ProductLenseFeature
                                              lense_feature={this.state.pres}
                                              lense_background={
                                                this.state.lense_background
                                              }
                                              available_power={
                                                this.AvailablePower
                                              }
                                            />
                                            <center>
                                              <button
                                                className={
                                                  "btn " + this.state.add_bag
                                                }
                                                onClick={this.handleChange}
                                              >
                                                ADD TO CART
                                              </button>
                                            </center>
                                          </div>
                                        ) : (
                                          <div>
                                            <p className="product_footer_text footer_size_color">
                                              LENS PACKAGE:{" "}
                                              <b className="lens_package_text">
                                                {this.state.pres}
                                              </b>
                                              <u
                                                className="change_package_text"
                                                onClick={this.ChangePackage}
                                              >
                                                Change
                                              </u>
                                            </p>
                                            <ProductLenseFeature
                                              lense_feature={this.state.pres}
                                              lense_background={
                                                this.state.lense_background
                                              }
                                              available_power={
                                                this.AvailablePower
                                              }
                                            />
                                            {this.state.path_source == "" ||
                                            this.state.path_source == null ? (
                                              <Link to={"/cart"}>
                                                <center>
                                                  <button className="btn go_to_bag">
                                                    GO TO CART
                                                  </button>
                                                </center>
                                              </Link>
                                            ) : (
                                              <Link
                                                to={
                                                  "/cart?path_source=" +
                                                  this.state.path_source
                                                }
                                              >
                                                <center>
                                                  <button className="btn go_to_bag">
                                                    GO TO CART
                                                  </button>
                                                </center>
                                              </Link>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <ProductPackageDesktop
                                    select_package={this.SelectPackage}
                                    select_prescription={
                                      this.SelectPrescription
                                    }
                                  />
                                )}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <center>
                            <button className="btn sold_out_button">
                              SOLD OUT
                            </button>
                          </center>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-1"></div>
                  </div>
                  <div className="col-sm-1"></div>
                  <div className="col-xs-10 padding_is_zero">
                    <ProductCostSection />
                  </div>
                  <div className="col-sm-1"></div>
                </div>
                <PageFooter />
                <Footer />
              </div>
            ) : (
              <div>
                {this.state.image_type == "model" ? (
                  <div>
                    <div className="col-sm-12">
                      <button
                        type="button"
                        class="close more_image_close_button"
                        onClick={this.MoreImagesModel}
                      >
                        &times;
                      </button>
                    </div>
                    <div className="col-sm-12 more_image_section">
                      <center>
                        {this.state.product_details.model_images.map(
                          (image_item, index) => (
                            <img
                              src={image_item.url}
                              className="img-responsive show_more_image_img"
                              id="frame_img"
                              name="frame_img"
                              alt="frame_img"
                            />
                          )
                        )}
                      </center>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="col-sm-12">
                      <button
                        type="button"
                        class="close more_image_close_button"
                        onClick={this.MoreImagesModel}
                      >
                        &times;
                      </button>
                    </div>
                    <div className="col-sm-12 more_image_section">
                      <center>
                        {this.state.product_details.glass_images.map(
                          (image_item, index) => (
                            <img
                              src={image_item.url}
                              className="img-responsive show_more_image_img"
                              id="frame_img"
                              name="frame_img"
                              alt="frame_img"
                            />
                          )
                        )}
                      </center>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      }
    }
    return (
      <div>
        <Header color={"#160D08"} />
        <Loading />
      </div>
    );
  }
}

export default ProductDetails;
/*
 */
