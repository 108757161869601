import  React, {Component} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import OtpInput from 'react-otp-input';
import Timer from 'react-compound-timer';
import Lottie from 'react-lottie';
import animationData from '../Lotties/62542-cool-chick.json';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import ClevertapReact from 'clevertap-react';

class LoginInputNew extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            mob_btn: 'select_get_otp_inactive' ,//normal otp button class
            showPhone: true,
            token: 0,
            redirect: false, //for redirecting to previous screen from where user came for Login,
            redirectUrl: "/", //setting this to home for now, will have to change, mainly using props here, of if no props then this value
            resend: 0, //0 means first time sending otp, 1 means resend
            products: '',
            alert_msg: '',
            name: localStorage.getItem('name'),
            show_login_subtext: true,
            track_source: Cookies.get('track_source'),
            change_step: 1,
            otp: "",
            show_timer: true,
            no_resend_option: false,
            otp_btn: 'select_verify_otp_inactive', //normal otp button class
            show_resend: true,
        };
        this.handleChange  = this.handleChange.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.triggerMobHandler  = this.triggerMobHandler.bind(this);
        this.mobHandler = this.mobHandler.bind(this);
        this.otpSubmit = this.otpSubmit.bind(this);
        this.resendOTP = this.resendOTP.bind(this);
        this.showResend = this.showResend.bind(this);
        this.handleChangeOTP = this.handleChangeOTP.bind(this);
        this.editMobile = this.editMobile.bind(this);
    }
    componentDidMount()
    {
        if(!this.state.name)
        {
            var redirectUrl = '/profile';
            const query = new URLSearchParams(window.location.search);
            const redirect_value = query.get('redirect_url');
            if(redirect_value)
            {
                if(redirect_value.includes("cart"))
                {
                    redirectUrl = '/profile?redirect_url=/cart';
                }
            }
             
            this.setState({
                redirectUrl: redirectUrl,
            });
        }
        /*if(this.props.checkout_redirect == true)
        {
            this.setState({
                redirectUrl: '/checkout',
            });
        }*/
    }
    handleChange(phone){
        let phone_number = phone.target.value;
        if(phone_number.length===10){
                this.setState({
                    mob_btn: 'select_get_otp'
                });
        }else{
            this.setState({
                    mob_btn: 'select_get_otp_inactive'
            });
        }
        this.setState({
            phone: phone_number
        })
    }

    handleKeypress(e){
        //it triggers by pressing the enter key
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            this.triggerMobHandler();
        }
    }

    triggerMobHandler(){
        var phone_number = this.state.phone;
        if(phone_number.length===10)
        {
            this.mobHandler(this.state.phone, 0);
        }
        else
        {
            swal("Please enter your 10 digit mobile no.");
        }
    }
    mobHandler(phone, resend) {
        var data = JSON.stringify({phone: phone});
        let header = new Headers({
            
        });
        let url = process.env.REACT_APP_API_URL+'/v1/user/session';
        fetch(url, {
            method: 'POST',
            headers: header,
            body: data
        })
        .then(response => response.json())
        .then(data => this.setState({
                showPhone: false,
                phone: phone,
                resend: resend,
                change_step: 2,
            }));
        this.props.change_step_props(2);
    }
    handleChangeOTP(otp) {
        if(otp.length===4){
                this.setState({
                    otp_btn: 'select_verify_otp'
                });
        }else{
            this.setState({
                    otp_btn: 'select_verify_otp_inactive'
            });
        }
       this.setState({
         otp: otp
       });
    }
    editMobile()
    {
        this.setState({ change_step: 1, phone: '', mob_btn: 'select_get_otp_inactive'});
    }
    otpSubmit(e) {
        e.preventDefault();
        var otp = this.state.otp;
        if(otp.length===4)
        {
            this.otpHandler(this.state.otp);
        }
        else
        {
            swal("Please enter 4 digit OTP");
        }
    }

    resendOTP() {
       this.mobHandler(this.state.phone, 1);
       this.setState({
            show_resend: false,
        });
       
    }

    showResend() {
        this.setState({
            show_timer: false,
        });
    }
    
    otpHandler(otp){
        const otp_data = JSON.stringify({phone: this.state.phone, 'otp': otp});
        var header = new Headers();

        let url = process.env.REACT_APP_API_URL+'/v1/user/session/verify';

            fetch(url, {
                method: 'POST',
                headers: header,
                body: otp_data
            })
            .then(response => response.json())
            .then(
                (data) => {
                    if(data.msg=='OTP Mismatch!')
                    {
                        swal("OTP entered is incorrect");
                    }
                    else
                    {
                        localStorage.setItem('token', data.token);
                        ClevertapReact.event("OtpVerified");
                        this.CartToBackend(data.token);
                        //this.FetchCartBackend(data.token);
                        this.setState({
                            token : data.token,
                            msg: data.msg,
                            redirect: true
                        });
                    }
                });
    }

    CartToBackend(token)
    {
        let data = [];
        let key = '';
        let prod_data = [];
        let prod_ids = JSON.parse(localStorage.getItem('prod_counts'));
        if(prod_ids)
        {
            prod_ids.forEach(item => {
            key = 'prod_'+item;

            prod_data = JSON.parse(localStorage.getItem(key));
            data.push(prod_data);
            });
            
            
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ token);

            data = JSON.stringify({'cart_data' : data, "track_source": this.state.track_source});
            let url = process.env.REACT_APP_API_URL+'/v1/cart';

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: data,
                redirect: 'follow'
            };

            fetch(url, requestOptions)
            .then(response => response.json())
            
            .then(data => 
                {
                    console.log(data);
                    this.SubToBackend(data,token);
                    
                }
            )  
        }
    }
    SubToBackend(cart_details,token)
    {
        let data = [];
        let sub_data = [];
        sub_data = JSON.parse(localStorage.getItem("sub_data")); //Passing Subscription details to backend
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ token);

        let url = process.env.REACT_APP_API_URL+'/v1/cart';

        var credits_in_wallet = cart_details.result.user_data.credits_in_wallet;
        var total_items = cart_details.result.billing.total_items;

        if(total_items > credits_in_wallet)
        {
            data.push(sub_data);
            data = JSON.stringify({'cart_data' : data, "track_source": this.state.track_source});
            let url = process.env.REACT_APP_API_URL+'/v1/cart';
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: data,
                redirect: 'follow'
            };
            fetch(url, requestOptions)
            .then(response => response.json())
            
            .then(data => 
                this.setState({
                    redirect: true,
                    redirectUrl:'/checkout',
                })
            )
        }
        else
        {
            this.setState({
                redirect: true,
                redirectUrl:'/checkout',
            })
        }
    }
    
    render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const styles = {
        checkAnimation: {
            textAlign: 'center',
            height: 'auto',
            width: '50%',
        },
    } 
    if(this.state.redirect){
        return <Redirect to={this.state.redirectUrl} />
    }
    return(
            <div>
            {this.state.change_step == 1 ?
                <div>
                    {/* ***************mob-view************************** */}
                    <div className="col-xs-12 hidden-sm hidden-md hidden-lg hidden-xl padding_is_zero">
                        <Box component="form" sx={{'& > :not(style)': { m: 0, width: '90%' },}} noValidateautoComplete="off">
                            <TextField 
                                id="outlined-basic" 
                                inputProps={{style: {fontSize: '12px'}}}
                                InputLabelProps={{style: {fontSize: '12px'}}}
                                label="Enter Mobile Number" variant="outlined" onChange={this.handleChange} onKeyPress={(e) => {this.handleKeypress(e)}} type="number" name="phone" id="phone" value={this.phone} minLength={10} maxLength={10} />
                        </Box>
                        <div className=" col-xs-12 login_mob_container_lottie">
                            <center ><Lottie  options={defaultOptions} style={styles.checkAnimation}/></center>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-xs-12 modal-footer-login-footer">
                            <center><button className={"btn "+this.state.mob_btn} onClick={this.triggerMobHandler}>GET OTP</button></center>
                        </div> 
                    </div>

                    {/* ************************tab & desktop-view*************************************** */}
                    <div className="col-xs-12 hidden-xs padding_is_zero">
                        <div className="login_desktop_textbox">
                            <Box component="form" sx={{'& > :not(style)': { m: 0, width: '90%' },}} noValidateautoComplete="off">
                              <TextField id="outlined-basic" 
                                inputProps={{style: {fontSize: '12px'}}}
                                InputLabelProps={{style: {fontSize: '12px'}}}
                                className="login_input_new" 
                                label="Enter Mobile Number" variant="outlined" onChange={this.handleChange} onKeyPress={(e) => {this.handleKeypress(e)}}  type="number" name="phone" id="phone" value={this.phone} minLength={10} maxLength={10} />
                            </Box>

                        </div>
                        
                        <button type="submit" className={"btn "+this.state.mob_btn} onClick={this.triggerMobHandler}>GET OTP</button> 
                    </div> 
                </div>
                :
                <div>
                    {/* ***************mob-view************************** */}
                    <div className="col-xs-12 hidden-sm hidden-md hidden-lg hidden-xl padding_is_zero">
                        
                        <div className="col-xs-12 padding_is_zero">
                            <p className="login_desktop_subheadtext">An OTP was sent to <u className="otp_mobile_no_text">+91 - {this.state.phone}</u> <img src="/images/Header/EditIconBlue.svg" className="otp_mobile_edit" onClick={this.editMobile}/></p>
                        </div>
                        <div className="col-xs-12 each_input_label padding_is_zero">
                            <form onSubmit={this.otpSubmit}>
                                <OtpInput value={this.state.otp} onChange={this.handleChangeOTP} numInputs={4} separator={<span>-</span>} isInputNum={true} containerStyle="otp-box" inputStyle="partitioned-input" />
                                { this.state.no_resend_option ? (<div></div>) : this.state.show_timer ? (
                                <div className="otp-timer-div">
                                    <Timer initialTime={60000} lastUnit="s" direction="backward" checkpoints={[{time: 0,callback: () => this.showResend()}]}>
                                    Resend OTP in <Timer.Seconds /> seconds.
                                    </Timer>
                                </div>
                                ):(
                                    <div>
                                    {this.state.show_resend ?
                                        <div className="resend-otp-text" onClick={this.resendOTP}>
                                            Resend OTP
                                        </div>
                                        :
                                        <div className="otp-sent-text" >
                                            OTP Sent !
                                        </div>
                                    }
                                    
                                    </div>
                                )}
                            
                                <div className=" col-xs-12 login_mob_container_lottie">
                                    <center ><Lottie  options={defaultOptions} style={styles.checkAnimation}/></center>
                                </div>
                                <div className="clearfix"></div>
                                <div className="col-xs-12 modal-footer-login-footer">
                                    <center><button className={this.state.otp_btn+ " btn"} type="submit">Verify</button></center>
                                </div> 

                            </form>
                        </div>
                    </div>

                    {/* ************************tab & desktop-view*************************************** */}
                    <div>
                        <div className="login_desktop_textbox hidden-xs">
                            <div className="col-xs-12 padding_is_zero">
                                <p className="login_desktop_subheadtext">An OTP was sent to <u className="otp_mobile_no_text">+91 - {this.state.phone}</u> <img src="/images/Header/EditIconBlue.svg" className="otp_mobile_edit" onClick={this.editMobile}/></p>
                            </div>
                            <div className="col-xs-12 each_input_label padding_is_zero">
                                <form onSubmit={this.otpSubmit}>
                                    <OtpInput value={this.state.otp} onChange={this.handleChangeOTP} numInputs={4} separator={<span>-</span>} isInputNum={true} containerStyle="otp-box" inputStyle="partitioned-input" />
                                    { this.state.no_resend_option ? (<div></div>) : this.state.show_timer ? (
                                    <div className="otp-timer-div">
                                        <Timer initialTime={60000} lastUnit="s" direction="backward" checkpoints={[{time: 0,callback: () => this.showResend()}]}>
                                        Resend OTP in <Timer.Seconds /> seconds.
                                        </Timer>
                                    </div>
                                    ):(
                                        <div>
                                        {this.state.show_resend ?
                                            <div className="resend-otp-text" onClick={this.resendOTP}>
                                                Resend OTP
                                            </div>
                                            :
                                            <div className="otp-sent-text" >
                                                OTP Sent !
                                            </div>
                                        }
                                        
                                        </div>
                                    )}
                                    <button className={this.state.otp_btn+ " btn"} type="submit">Verify</button>
                                </form>
                            </div>
                            
                           
                        </div>
                    </div> 
                </div>
            }
            </div>
            
        );
    }
}

export default LoginInputNew;
