import React, { Component } from "react";
import { Redirect } from 'react-router';
import CheckoutHeader from './CheckoutHeader';
import Loading from "../Common/Loading";
import Header from "../Common/Header";
import CheckoutItemDetails from "./CheckoutItemDetails";
import CheckoutItemDetailsNew from "./CheckoutItemDetailsNew";
import CheckoutSelectAddress from "./CheckoutSelectAddress";
import CheckoutAddAddress from "./CheckoutAddAddress"
import CheckoutFooter from './CheckoutFooter';
import CheckoutFooterConfirm from './CheckoutFooterConfirm';
import CheckoutCoupon from "./CheckoutCoupon";
import CheckoutBillDetails from "./CheckoutBillDetails";
import CheckoutCouponSection from "./CheckoutCouponSection"
import CheckoutStatic from "./CheckoutStatic";
import CheckoutEmpty from './CheckoutEmpty';
import SelectAddress from "../Common/AddAddress/SelectAddress";
import AddNewAddress from "../Common/AddAddress/AddNewAddress";
import CheckoutPay from "./CheckoutPay";
import store from '../../Redux/store';
import { connect } from "react-redux";
import { FetchCartDetails } from "../../Redux/Action/CartActions";
import swal from 'sweetalert';
import Countdown, { zeroPad } from 'react-countdown';
import {isMobile} from 'react-device-detect';
import ClevertapReact from 'clevertap-react';
import $ from 'jquery';

class Checkout extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	access_token: localStorage.getItem("token"),
	    	name: localStorage.getItem("name"),
	    	cart_details: [{cart_list: {}},{billing_details: {}},{user_data: {address:[]}}],
	    	address_id: '',
	    	address: [] ,
	    	showAddress: false,
	    	save_add_address: false,
	    	checkout_confirm: true,
	    	redirectTo: '/login',
	    	redirect: false,
	    	stage: 1,
	    	non_credit_product_array: [],
	    	credit_product_array: [],
	    	credits_in_wallet: 0,
	    	test: true,
	    	show_coupon_section: false,
	    	coupon_id: '',
	    	coupon_name: '',
	    	coupon_discount_upto: '',
	    	selected_subscription_id: '',
	    	selected_best_deal: '',
	    	checkout_accordion1: true,
	    	checkout_accordion2: false,
	    	checkout_accordion3: false,
	    	device: '',
	    	checkout_show_billing: true,
	    	width: window.innerWidth,
	    	qty_change_loader: false,
	    	clear_coupon_section: false,
	    	isBackButtonClicked: false,
	    }
	    this.AddAddress = this.AddAddress.bind(this);
	    this.CloseAddress = this.CloseAddress.bind(this);
	    this.onConfirm = this.onConfirm.bind(this);
	    this.ShowAddAddress = this.ShowAddAddress.bind(this);
	    this.UpdateAddressID = this.UpdateAddressID.bind(this);
	    this.EditAddress = this.EditAddress.bind(this);
	    this.CheckoutPay = this.CheckoutPay.bind(this);
	    this.CheckoutBack = this.CheckoutBack.bind(this);
	    this.clockRef = React.createRef();
	    this.handleStart = this.handleStart.bind(this);
	    this.ShowCouponSection = this.ShowCouponSection.bind(this);
	    this.ApplyCoupon = this.ApplyCoupon.bind(this);
	    this.DiscardCoupon = this.DiscardCoupon.bind(this);
	    this.OpenAddressAccord = this.OpenAddressAccord.bind(this);
	    this.CheckoutPayWeb = this.CheckoutPayWeb.bind(this);
	    this.AddQty  = this.AddQty.bind(this);
		this.SubQty  = this.SubQty.bind(this);
		this.RemoveItem  = this.RemoveItem.bind(this);
		this.GetCartDetails = this.GetCartDetails.bind(this);
		this.onBackButtonEvent = this.onBackButtonEvent.bind(this);
		this.handleTypeCheckbox = this.handleTypeCheckbox.bind(this);
		this.AddSubId = this.AddSubId.bind(this);
	}
	componentDidMount()
	{
		if(this.state.access_token)
		{
			if(this.state.name)
			{
				const state = store.getState();
				let cart_details = state.cart.items.data.cart_details;

				this.setState({ cart_details: cart_details}, 
					() => {
						this.AddSubId();
    					this.GetCartDetails();
    					this.CheckCredits();
    					this.AgainUpdateCheckout();
    					var selected_subscription_id = '';
    					for(var i = 0; i <= this.state.cart_details.cart_list.length-1 ; i++)
				  		{
				  			if(this.state.cart_details.cart_list[i].type == "subscription")
				  			{
				  				selected_subscription_id = this.state.cart_details.cart_list[i].subscription_id;
				  			}
				  		}
    					var checkout_clevertap = {
						  	"subscription_id": selected_subscription_id,
						  	"cart_value": this.state.cart_details.billing.payable_price
						}
			 
						ClevertapReact.event("Checkout", checkout_clevertap)
						/*window.addEventListener('popstate', (event) => {
							alert("clicked");
							if(this.state.stage == 0)
					      	{
					      		this.CheckoutBack();
					      	}
						});*/
						//window.history.pushState(null, null, window.location.pathname);
						//window.addEventListener("popstate", this.onBackButtonEvent)
						
							//window.history.pushState();
							//prevPath: this.props.location.state,
							window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
							window.addEventListener('popstate', (event) => {
							  	if(this.state.stage == 0)
							  	{
							  		this.CheckoutBack();
							  	}
							  	else
							  	{
							  		<Redirect to={this.props.prevPath.prevPath}/>
							  	}
							 });
						$("#title1").prop( "checked", true );
						
				});

			}
			else
			{
				this.setState({ redirectTo: '/profile', redirect: true})
			}
			
			
		}
		else
		{
			this.setState({ redirectTo: '/login?redirect_url=/cart', redirect: true})
		}
		
  	}
  	AddSubId()
  	{
		var sub_data_local = {
			"product_id": 0,
			"qty": 1,
			"subscription_id": 1
		};
		localStorage.setItem('sub_data', JSON.stringify(sub_data_local));

  		let data = [];
        let sub_data = [];
        sub_data = JSON.parse(localStorage.getItem("sub_data")); //Passing Subscription details to backend
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.state.access_token);

        let url = process.env.REACT_APP_API_URL+'/v1/cart';

        var credits_in_wallet = this.state.cart_details.user_data.credits_in_wallet;
        var total_items = this.state.cart_details.billing.total_items;

        if(total_items > credits_in_wallet)
        {
            data.push(sub_data);
            data = JSON.stringify({'cart_data' : data});
            let url = process.env.REACT_APP_API_URL+'/v1/cart';
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: data,
                redirect: 'follow'
            };
            fetch(url, requestOptions)
            .then(response => response.json())
        }
  	}
  	onBackButtonEvent(e)
  	{
	  	/*alert(this.state.stage);
    	if(this.state.stage == 0)
    	{
    		this.CheckoutBack();
    	}*/
    	/*e.preventDefault();

    	if (!this.isBackButtonClicked) 
    	{
	        if (this.state.stage == 0) 
	        {
	        	this.CheckoutBack();
	            this.isBackButtonClicked = true;
	        }
	        else 
        	{
            	this.isBackButtonClicked = false;
        	}
        }
        else 
        {
            this.isBackButtonClicked = false;
        }*/
    
	}
	componentWillUnmount()
	{
		//window.removeEventListener('popstate', this.onBackButtonEvent);
	}
  	GetCartDetails()
	{
		localStorage.removeItem("prod_counts");
		let arr = [] // Array to hold the keys
	  	// Iterate over localStorage and insert the keys that meet the condition into arr
	  	for (let i = 0; i < localStorage.length; i++) { 
		    if (localStorage.key(i).substring(0,5) == 'prod_') { 
		    	arr.push(localStorage.key(i)) 
		    }
	  	}
	  	// Iterate over arr and remove the items by key
	  	for (let i = 0; i < arr.length; i++) {
	    	localStorage.removeItem(arr[i]) 
	  	}
	  	
	    this.SetItemInLocal(this.state.cart_details);
	}
	SetItemInLocal(cart_details)
	{
		for(var i=0; i<=cart_details.cart_list.length - 1; i++)
		{
			if(cart_details.cart_list[i].type == 'product')
			{
				var product_id = cart_details.cart_list[i].product_id;
				var qty = cart_details.cart_list[i].qty;
				var prescription_type = cart_details.cart_list[i].prescription_type;
				var prod_data = {
					"product_id": product_id,
					"qty": qty,
					"prescription_type": prescription_type
				};
				localStorage.setItem('prod_'+product_id,JSON.stringify(prod_data)); /*Setting Product Details in local*/

				var prod_ids = JSON.parse(localStorage.getItem('prod_counts'));
				if (Array.isArray(prod_ids))
				{
					if(prod_ids.indexOf(product_id) === -1)
					{
						prod_ids.push(product_id) 
					}
					
				} 
				else 
				{
					prod_ids = [];
					prod_ids.push(product_id);
				}
				localStorage.setItem('prod_counts', JSON.stringify(prod_ids)); /*Setting Product Count in local*/
			}
		}

		let product_ids_array = JSON.parse(localStorage.getItem("prod_counts"));
	}
	AgainUpdateCheckout()
	{
		var myHeaders = new Headers();
	    myHeaders.append("Authorization", "Bearer "+ this.state.access_token);
	    let url = process.env.REACT_APP_API_URL+'/v1/cart';
	    var requestOptions = {
	        method: 'GET',
	        headers: myHeaders,
	        redirect: 'follow'
	    };
	    fetch(url, requestOptions)
        .then(response => response.json())
		.then(data => 
        {
        	this.setState({ cart_details: data.data.cart_details})
        })
	}
  	AddQty(product_id)
	{
		let product_details_new = this.state.cart_details.cart_list;
		for(var i = 0; i< product_details_new.length; i++)
  		{
  			if(product_details_new[i].product_id == product_id)
  			{
  				if(product_details_new[i].type === 'product') //Only for type product
				{
	  				let qty = product_details_new[i].qty+1;
	  				if(qty<11)
	  				{
					  	this.setState({ qty_change_loader: true})
					  	let product_item = JSON.parse(localStorage.getItem("prod_"+product_id));
					  	var prod_data = {
							"product_id": product_id,
							"qty": qty,
							"prescription_type": product_details_new[i].prescription_type
						};
						localStorage.setItem('prod_'+product_id,JSON.stringify(prod_data));
						this.CartToBackend();
					}else{
						swal("You cannot purchase more than 10 pairs of one type of eyeglasses or sunglasses at once.");
					}
				}
  			}
  		}		
	}
	SubQty(product_id)
	{
		let product_details_new = this.state.cart_details.cart_list;
  		for(var i = 0; i< product_details_new.length; i++)
  		{
  			if(product_details_new[i].product_id == product_id)
  			{
  				if(product_details_new[i].type === 'product') //Only for type product
				{
	  				let qty = product_details_new[i].qty-1;
	  				if(qty>=1)
	  				{
					  	this.setState({ qty_change_loader: true})
					  	let product_item = JSON.parse(localStorage.getItem("prod_"+product_id));
					  	var prod_data = {
							"product_id": product_id,
							"qty": qty,
							"prescription_type": product_details_new[i].prescription_type
						};
						localStorage.setItem('prod_'+product_id,JSON.stringify(prod_data));
						this.CartToBackend();
					}else{
						this.RemoveItem(product_id);
					}
				}
  			}
  		}
	}
	RemoveItem(product_id)
	{
		/*let product_details_new = this.state.cart_details.cart_list;
  		let product_item = JSON.parse(localStorage.getItem("prod_"+product_id));
  		var prod_data = {
				"product_id": product_item.product_id,
				"qty": 0,
				"prescription_type": product_item.prescription_type
			};
		localStorage.setItem('prod_'+product_id,JSON.stringify(prod_data));
		
		var result = this.CartToBackend()
		localStorage.removeItem("prod_"+product_id);
  		let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
  		const index = product_id_array.indexOf(product_id);
		if (index > -1) {
		  product_id_array.splice(index, 1);
		}
		localStorage.setItem('prod_counts', JSON.stringify(product_id_array)); 
		let product_ids_array = JSON.parse(localStorage.getItem("prod_counts"));
		if(product_ids_array.length > 0)
		{
        	//this.GetProductList(product_ids_array);    
		}
		else
		{
			localStorage.removeItem("prod_counts");
			this.setState({
				products : '',
				show_product: true,
        	});
		}*/
		let product_details_new = this.state.cart_details.cart_list;
  		for(var i = 0; i< product_details_new.length; i++)
  		{
  			if(product_details_new[i].product_id == product_id)
  			{
  				if(product_details_new[i].type === 'product') //Only for type product
				{
					this.setState({ qty_change_loader: true})
					var prod_data = {
						"product_id": product_id,
						"qty": 0,
						"prescription_type": product_details_new[i].prescription_type
					};
					localStorage.setItem('prod_'+product_id,JSON.stringify(prod_data));
					var result = this.CartToBackend()
				  	localStorage.removeItem("prod_"+product_id);
			  		let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
			  		const index = product_id_array.indexOf(product_id);
					if (index > -1) {
					  product_id_array.splice(index, 1);
					}
					localStorage.setItem('prod_counts', JSON.stringify(product_id_array)); 
				}
			}
		}
		
	}
	CartToBackend()
	{
		let data = [];
		let key = '';
		let prod_data = [];
		let prod_ids = JSON.parse(localStorage.getItem('prod_counts'));

		if(prod_ids)
		{
			prod_ids.forEach(item => {
			key = 'prod_'+item;

			prod_data = JSON.parse(localStorage.getItem(key));
			data.push(prod_data);
			});
			const { width } = this.state;
	  		const isMobile = width <= 768;
	  		if(isMobile)
	  		{
	  			var device = "mobile";
	  		}
	  		else
	  		{
	  			var device = "desktop";
	  		}
			var myHeaders = new Headers();
		    myHeaders.append("Authorization", "Bearer "+ this.state.access_token);
		    myHeaders.append("User-Source", device);

			data = JSON.stringify({'cart_data' : data});
			let url = process.env.REACT_APP_API_URL+'/v1/cart';

			var requestOptions = {
	            method: 'POST',
	            headers: myHeaders,
	            body: data,
	            redirect: 'follow'
	        };

			fetch(url, requestOptions)
	        .then(response => response.json())
			
	        .then(data => 
	        {
	        	console.log(data);
	        	this.setState({ cart_details: data.result, qty_change_loader: false, show_product: true, clear_coupon_section: true, coupon_id: ''},
	        		()=>{
	        			this.AgainUpdateCheckout();
	        			this.CheckCredits();
	        		})
	        }
	        )
		}
		else
		{
			this.setState({
				products : '',
				show_product: true,
				qty_change_loader: false,
        	});
		}

	}
	CheckCredits()
  	{
  		let credit_product_array = [];
		let non_credit_product_array = [];
		let credits_in_wallet = this.state.cart_details.user_data.credits_in_wallet;
		let cart_list_temp = this.state.cart_details.cart_list;
		let total_utilized_credit = 0;
		for(var i = 0; i< cart_list_temp.length; i++)
		{
			if(cart_list_temp[i].type === 'product') //Only for type product
			{
				if(credits_in_wallet > 0)  //Checking if user has any credit value
				{
					if(cart_list_temp[i].qty > 1)  //if the product qty is greater than 1
					{
						if(total_utilized_credit != credits_in_wallet) //checking if all credits are fullfilled
						{
							var remaining_credits = credits_in_wallet-total_utilized_credit;
							if(remaining_credits == cart_list_temp[i].qty)
							{
								credit_product_array.push(cart_list_temp[i]);
								var credit_qty = cart_list_temp[i].qty;
							}
							else
							{
								if(remaining_credits > cart_list_temp[i].qty)
								{
									credit_product_array.push(cart_list_temp[i]);
									var credit_qty = cart_list_temp[i].qty;
								}
								else
								{
									var cart_qty = cart_list_temp[i].qty;
									var non_credit_qty = cart_qty - remaining_credits;
									var non_credit_product = cart_list_temp[i];
									non_credit_product.qty = non_credit_qty;
									non_credit_product_array.push(non_credit_product);

									var cart_credit = Object.assign({}, cart_list_temp[i]); // creating new duplicate object
									cart_credit.qty = remaining_credits;
									credit_product_array.push(cart_credit);
									var credit_qty = remaining_credits;
									
								}
							}
						}
						else
						{
							non_credit_product_array.push(cart_list_temp[i]);
							var credit_qty = cart_list_temp[i].qty;
						}
						total_utilized_credit = total_utilized_credit+credit_qty;
						
					}
					else // if product quantity = 1, then directly putting it into credit array
					{
						if(total_utilized_credit < credits_in_wallet) //checking if all credits are fullfilled
						{
							credit_product_array.push(cart_list_temp[i]);
							total_utilized_credit = total_utilized_credit+1
						}
						else
						{
							non_credit_product_array.push(cart_list_temp[i]);
							total_utilized_credit = total_utilized_credit+1
						}
					}
				}
				else
				{
					non_credit_product_array.push(cart_list_temp[i]);
				}
			}
		}
		this.setState({ non_credit_product_array: non_credit_product_array , credit_product_array: credit_product_array, credits_in_wallet: credits_in_wallet})
  	}
	UpdateAddressID(id)
	{
		this.setState({ address_id: id})
		$('#title1').not(this).prop('checked', false);
		$('#title2').not(this).prop('checked', false); 
		$('#title3').not(this).prop('checked', false); 
	}
	AddAddress() {
	    this.setState({
	    	showAddress: true,
	    });
	}
	CloseAddress(){
		this.setState({
	      showAddress: false,
	      save_add_address: false
	    });
	}
	ShowAddAddress()
	{
		this.setState({ save_add_address: true })
	}
	EditAddress()
	{
		this.setState({ address_id: '', showAddress: true})
	}
	CheckoutPay(device)
	{
		this.setState({ checkout_confirm: false, device: device, stage: 0})
	}
	CheckoutPayWeb(device)
	{
		this.setState({ checkout_show_billing: false, device: device, checkout_confirm: false, stage: 0})
	}
	CheckoutBack()
	{
		this.setState({ checkout_confirm: true, stage: 1})
	}
	onConfirm(name,mob,email,country,addr1,addr2,city,state,pin)
	{
		if(country.length < 2)
		{
			swal("Enter a valid Country");
		}
		else if(mob.toString().length != 10 )
		{
			swal("Enter a valid Mobile Number");
		}
		else if(addr1.length < 1)
		{
			swal("Enter a Apartment/ House Number");
		}
		else if(addr2.length < 3)
		{
			swal("Enter a valid Locality, Street Address");
		}
		else if(city.length < 2)
		{
			swal("Enter a valid City");
		}
		else if(state.length < 2)
		{
			swal("Enter a valid State");
		}
		else if(pin.length != 6)
		{
			swal("Enter a valid Pincode");
		}
		else if(name.length < 2)
		{
			swal("Enter your Name");
		}
		else if(email.length < 2)
		{
			swal("Enter a valid Email ID");
		}
		else
		{
			var data = JSON.stringify({address : {type : 'shipping', shipping_address : addr1+", "+addr2, ship_code : pin,
	        ship_city : city,
	        ship_state : state,
	        phone : mob,
	    	name: name}});
			console.log(data);
			var myHeaders = new Headers();
	        myHeaders.append("Authorization", "Bearer "+ localStorage.getItem('token'));
			var requestOptions = {
	            method: 'POST',
	            headers: myHeaders,
	            body: data,
	            redirect: 'follow'
	        };
			let url = process.env.REACT_APP_API_URL+'/v1/user/basic';
			fetch(url, {
	                method: 'POST',
	                headers: myHeaders,
	                body: data,
	                redirect: 'follow'
	            })
	            .then(response => response.json())
	            .then(
	                (res) => {
	                	console.log(res)
	                	if(res.success)
	                	{
	                		this.state.cart_details.user_data.address.push(res.result.address.data)
	                		this.setState({ address_id: res.result.address.data.id, showAddress: false, save_add_address: false, checkout_accordion2: false})
	                		$('#title2').not(this).prop('checked', false); 
	                	}
	                	else
	                	{
	                		swal(res.result);
	                	}
	                });
		}
		

	}
	handleStart()
	{
		this.clockRef.current.start();
	}
	ShowCouponSection()
	{
		var selected_subscription_id = '';
		var selected_best_deal = '';
		for(var i = 0; i <= this.state.cart_details.cart_list.length-1 ; i++)
  		{
  			if(this.state.cart_details.cart_list[i].type == "subscription")
  			{
  				selected_subscription_id = this.state.cart_details.cart_list[i].subscription_id;
  			}
  		}
  		for(var i = 0; i <= this.state.cart_details.subscriptions.length-1 ; i++)
  		{
  			if(this.state.cart_details.subscriptions[i].id == selected_subscription_id)
  			{
  				selected_best_deal = this.state.cart_details.subscriptions[i].best_deal;
  			}
  		}
		this.setState({
			selected_subscription_id: selected_subscription_id,
			selected_best_deal: selected_best_deal,
	      	show_coupon_section: !this.state.show_coupon_section,
	    });
	}
	ApplyCoupon(id,input_coupon_name)
	{
		this.ApplyCouponIDWithName(id,input_coupon_name);
	}
	ApplyCouponIDWithName(id, name)
	{
		if(isMobile)
  		{
  			var device = "mobile";
  		}
  		else
  		{
  			var device = "desktop";
  		}
  		var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.state.access_token);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("User-Source", device);
  		var subscription_id = '';
  		var discount_upto = '';
  		for(var i = 0; i <= this.state.cart_details.cart_list.length-1 ; i++)
  		{
  			if(this.state.cart_details.cart_list[i].type == "subscription")
  			{
  				subscription_id = this.state.cart_details.cart_list[i].subscription_id;
  			}
  		}
  		var data = JSON.stringify({subscription_id : subscription_id});
		let url = process.env.REACT_APP_API_URL+'/v1/cart/coupon/'+name;
		fetch(url, {
                method: 'POST',
                headers: myHeaders,
                body: data,
                redirect: 'follow'
            })
        .then(response => response.json())
        .then(
            (res) => {
            	console.log(res);
            	if(res.success)
            	{
            		let cart_details_new = this.state.cart_details;
    				cart_details_new.billing = res.data.cart_details.billing;
    				var span = document.createElement("span");
			        span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><center><img src='/images/Header/discount-coupon-icon-green.svg'/><p style='color: #2D4B65;font-size: 28px;margin-top: 10px;font-family: 'NunitoSans-ExtraBold';'>Rs. "+cart_details_new.billing.coupon_price+"</p><p style='margin-top: 15px;color: #2D4B65;font-size: 12px;'>saved on this purchase using the code</p><p style='color: #2D4B65;text-transform: uppercase;font-weight: 600;font-family: 'NunitoSans-ExtraBold';'>"+name+"</p></center></div>";
					swal({
					    content: span,
					    showConfirmButton: true
					});
					this.setState({ cart_details: cart_details_new, coupon_id: cart_details_new.billing.coupon_id, coupon_name: name, coupon_discount_upto: cart_details_new.billing.coupon_price, show_coupon_section: false, clear_coupon_section: false })
            	}
            	else
            	{
            		this.setState({ clear_coupon_section: false })
            		swal(res.message);
            	}
            });
	}
	DiscardCoupon()
	{
		var myHeaders = new Headers();
	    myHeaders.append("Authorization", "Bearer "+ this.state.access_token);
	    let url = process.env.REACT_APP_API_URL+'/v1/cart';
	    this.setState({ coupon_id: '' })
		fetch(url, {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            })
        .then(response => response.json())
        .then(
            (res) => {
            	if(res.success)
            	{
            		let cart_details_new = this.state.cart_details;
    				cart_details_new.billing = res.data.cart_details.billing;
    				this.setState({ cart_details: cart_details_new, coupon_id: '' });	
            	}
            	else
            	{
            		swal(res.message);
            	}
            });
	}
	handleTypeCheckbox()
	{
		$('input.faq_input').on('change', function() {
            $('input.faq_input').not(this).prop('checked', false);  
        });
	}
	OpenAddressAccord()
	{
		//this.setState({ checkout_accordion2: true,  checkout_accordion1: false})
		$('#title2').not(this).prop('checked', true);
		$('#title1').not(this).prop('checked', false);   
	}
	render() {
		console.log(this.state.cart_details);
		const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
		    // Render a completed state
		    return "";
		} else {
		    // Render a countdown
		    return 	<center>
    					<p className="checkout_time_left_text">Time left:</p><span className="checkout_timer">{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    				</center>;
		  	}
		};

		if(this.state.redirect){
            return <Redirect to={this.state.redirectTo} />
        }
		if(this.state.cart_details.hasOwnProperty('cart_list'))
	    {
			
			if(this.state.cart_details.billing.total_items > 0)
			{
				var saved_credit_text = this.state.cart_details.billing.saved_credit/700 > 1 ? "Credits" : "Credit";
				var saved_credit_text_capital = this.state.cart_details.billing.saved_credit/700 > 1 ? "CREDITS" : "CREDIT"; 
				
				return (
					this.state.checkout_confirm ?
					<div className="col-sm-12 padding_is_zero">
						<div className="col-sm-12 padding_is_zero">
							<div className="checkout_details hidden-sm hidden-lg hidden-md">
								<CheckoutHeader stage={this.state.stage} device="mob"/>
								<div className="col-xs-12 checkout_body padding_is_zero">
					    			<div className="col-xs-12 checkout_product_list padding_is_zero">
					    				<Countdown date={Date.now() + 600000} renderer={renderer}/>
					    				{this.state.cart_details.billing.oj_referral_discount > 0 ?
					    					<div className="col-sm-12 checkout_top_message_div">
					    						<p className="checkout_top_message_text extra_bold_text">Rs. {this.state.cart_details.billing.oj_referral_discount} OJ Cash applied! </p>
												<p className="cart_urgency_message_text">This deal is ensuring free delivery and a bundled discount on your cart!</p>
												<img src="/images/Header/confetti-green.png" className="checkout_confetti_img"/>
					    					</div>
											:
					    					null
					    				}
					    				{!this.state.qty_change_loader ?
											<CheckoutItemDetailsNew add_qty={this.AddQty} sub_qty={this.SubQty} remove_item={this.RemoveItem} credits_in_wallet={this.state.credits_in_wallet} credit_product_array={this.state.credit_product_array} non_credit_product_array={this.state.non_credit_product_array} cart_details={this.state.cart_details} device="mob"/>
											:
											<Loading />
										}
					    				{/*{this.state.cart_details.cart_list.map((each_item, index) => (
					    				<div className="each_item_section" key={index}>
					    					{ each_item.type == "product" ?
					    						<div>
						    						<div className="col-xs-3">
							    						<center><img src={each_item.image} className="img-responsive cart_product_image"/></center>
								    				</div>
								    				<div className="col-xs-5 padding_is_zero">
								    					<p className="checkout_brand_name">{each_item.brand_name}</p>
								    					<div className="checkout_color_size_text">
								    						<span className="colour_dot" style={{backgroundColor: each_item.color}}></span>
															
								    						<p>({each_item.size.charAt(0)}) {each_item.prescription_type} * {each_item.qty}</p>
								    					</div>
								    				</div>
								    				<div className="col-xs-4 checkout_each_amount">
								    					<p className="checkout_delete_bin"><a href="/cart"><img src="./images/Header/Pencil_grey.svg" alt="bin_icon" className="bin_icon" /></a></p>
								    					<span className="checkout_cost_display"><p className="product_striked_cost"><s>Rs. {each_item.striked_cost}</s></p><p className="checkout_each_product_amount">Rs. {each_item.cost}</p></span>
								    				</div>
								    				<div className="clearfix"></div>
							    				</div>
							    				:
							    				null
					    					}
						    			</div>
										))}*/}
				    				</div>
				    				<div className="col-xs-12 cart_prescription_text">
			    						Enter power for eyeglasses after placing your order
			    					</div>
			    					
		    							{this.state.cart_details.cart_list.map((each_item, index) => (
			    						<div className="" key={index}>
				    						{ each_item.type == "subscription" && each_item.subscription_id == 2 ?
				    							
						    					<div className="checkout_subscription_plan col-xs-12">
						    						<div className="col-xs-2 checkout_coupon_icon">
					    								<img src="./images/Header/CouponCodeIcon_CheckoutPage1.svg" alt="coupon_icon" />
					    							</div>
					    							
					    							<div className="col-xs-7 padding_is_zero">
					    								<p className="checkout_congrats_text">Congratulations!</p>
														<p className="checkout_sub_plan_text">You have a new OJ SUPER deal</p>
														<p className="checkout_new_plan_subtext">You are getting 3 eyeglasses with premium lens at just Rs. <s>4500</s> 2100 (700 each). No additional or hidden charges. Pay now to get 3 credits and choose from a curated collection of eyeglasses, revamped every month. Your OJ credits are valid for 1 year. <a href="/subscription-plan" target="_blank"><u className="checkout_subscription_details_text">Details</u></a></p>
													</div>
													<div className="col-xs-3 padding_is_zero"><a href="/cart"><button className="checkout_change_deal_button">Change Deal</button></a></div>
					    						</div>
						    				:
				    						null
				    						}
				    						{/*{ each_item.type == "subscription" && each_item.subscription_id == 1 ?
				    							
						    					<div className="checkout_subscription_plan col-xs-12">
						    						<div className="col-xs-2 checkout_coupon_icon">
					    								<img src="./images/Header/CouponCodeIcon_CheckoutPage1.svg" alt="coupon_icon" />
					    							</div>
					    							
					    							<div className="col-xs-7 padding_is_zero">
					    								<p className="checkout_sub_plan_text">Get OJ SUPER to Save More!</p>
														<p className="checkout_new_plan_subtext">Apply OJ SUPER deal to get 3 eyeglasses with premium lens at just Rs. <s>4500</s> 2100 (700 each) with no additional or hidden charges. Subscribe now to get 3 credits and choose from a curated collection of eyeglasses, revamped every month. Your OJ credits are valid for 1 year. <a href="/subscription-plan" target="_blank"><u className="checkout_subscription_details_text">Details</u></a></p>
													</div>
													<div className="col-xs-3 padding_is_zero"><a href="/cart"><button className="checkout_get_super_button">GET SUPER</button></a></div>
					    						</div>
						    				:
				    						null
				    						}*/}
				    						{this.state.cart_details.billing.total_items > 0 ?
				    							<div>
				    								{this.state.credits_in_wallet >= this.state.cart_details.billing.total_items  && index==0?
						    							<div className="checkout_subscription_plan col-xs-12">
								    						<div className="col-xs-2">
							    								<img src="./images/Header/CouponCodeIcon_CheckoutPage1.svg" alt="coupon_icon" />
							    							</div>
							    							
							    							<div className="col-xs-9 padding_is_zero">
							    								{this.state.credits_in_wallet == this.state.cart_details.billing.total_items ?
							    									<p className="checkout_congrats_text">Congrats! You have paid using OJ Credits</p>
							    									:
							    									<div>
							    										<p className="checkout_congrats_text">Congrats! You have paid using OJ Credits</p>
							    										<p className="checkout_new_plan_subtext">{this.state.credits_in_wallet - this.state.cart_details.billing.total_items > 1 ?  this.state.credits_in_wallet - this.state.cart_details.billing.total_items+ " credits left in your OJ Account" : this.state.credits_in_wallet - this.state.cart_details.billing.total_items+ " credit left in your OJ Account"}</p>
							    									</div>
							    								}
							    							</div>
															<div className="col-xs-1 padding_is_zero"></div>
							    						</div>
								    				:
						    						null
						    						}
				    							</div>
				    							:
				    							null
				    						}
				    					</div>
		    							))}
									{this.state.cart_details.billing.payable_price > 0 ?
										<div>
											<CheckoutCoupon show_coupon_section={this.ShowCouponSection} coupon_id={this.state.coupon_id} coupon_name={this.state.coupon_name} coupon_discount_upto={this.state.coupon_discount_upto} coupon_details={this.state.cart_details.coupons} discard_coupon_section={this.DiscardCoupon} clear_coupon_section={this.state.clear_coupon_section}/>
											{this.state.show_coupon_section ?
												<CheckoutCouponSection coupon_details={this.state.cart_details.coupons} coupon_id={this.ApplyCoupon} show_coupon_section={this.ShowCouponSection} selected_best_deal={this.state.selected_best_deal} selected_subscription_id={this.state.selected_subscription_id}  clear_coupon_section={this.state.clear_coupon_section} device="mob"/>
												:
												null
											}
										</div>
										:
										null
									}	
			    					{this.state.cart_details.billing.total_items > 0 ?
				    					<CheckoutBillDetails cart_details={this.state.cart_details} credits_in_wallet={this.state.credits_in_wallet} device="mob"/>
				    					:
				    					null
				    				}
				    				<CheckoutStatic />
				    			</div>

				    			{this.state.address_id == '' ? 
				    				<div>
										<div className="col-sm-6 col-xs-12 checkout_footer">
											{this.state.cart_details.billing.total_items > 0 ?
								    			<center><button className="add_address_button" onClick={this.AddAddress}>Add Address To Continue</button></center>
								    			:
								    			<center><button className="add_address_button_disable">Add Address To Continue</button></center>
								    		}
								    	</div>
								    	{this.state.showAddress ?
								    		<div className="address_section">
								    		{this.state.cart_details.user_data.address.length > 0 ?
								    			<div>
								    				{!this.state.save_add_address ?
										    		<SelectAddress close_address={this.CloseAddress} user_data={this.state.cart_details.user_data}  show_add_address={this.ShowAddAddress} save_add_address={this.state.save_add_address} address_id={this.UpdateAddressID}/> 
								    				:
								    				<AddNewAddress close_address={this.CloseAddress} on_confirm={this.onConfirm} user_data={this.state.cart_details.user_data}/>
								    				}
								    			</div>
								    			:
								    			<AddNewAddress close_address={this.CloseAddress} on_confirm={this.onConfirm} user_data={this.state.cart_details.user_data}/>
								    		}
								    			
							    			</div>
						    				:
						    				null
								    	}
								    </div> 
				    			: 
				    				<CheckoutFooterConfirm cart_details={this.state.cart_details} address_id={this.state.address_id} back_to_checkout={this.EditAddress} checkout_pay={this.CheckoutPay}/>
				    			}
			    			</div>
			    			<div className="checkout_details hidden-xs">
			    				<CheckoutHeader stage={this.state.stage} device="web"/>
			    				<div className="col-sm-12 checkout_section_web">
			    					<div className="col-sm-12 checkout_timer_web">
				    					<div className="col-sm-6"></div>
				    					<div className="col-sm-6">
					    					<div className="col-sm-1"></div>
					    					<div className="col-sm-10">
					    						<Countdown date={Date.now() + 600000} renderer={renderer}/>
							    			</div>
					    					<div className="col-sm-1"></div>
				    					</div>
			    					</div>
				    				<div className="col-sm-6">
					    				<div className=" container-fluid no_margin">
						    				<div className="col-sm-1"></div>
											<div className="col-sm-10 padding_is_zero">
												<div className="checkout_accordion" >
													<input type="checkbox" id="title1" className="faq_input faq-drawer__trigger" onChange={this.handleTypeCheckbox}/>
													<label for="title1" className="faq_question faq-drawer__title checkout_points">
														<p className="checkout_points_text">1</p><p className="checkout_accordion_text"> Review Product Details <small className="checkout_cart_total_items">({this.state.cart_details.billing.total_items} items)</small></p>
													</label>

													<div className="checkout_content content">
														{!this.state.qty_change_loader ?
															<CheckoutItemDetailsNew add_qty={this.AddQty} sub_qty={this.SubQty} remove_item={this.RemoveItem} credits_in_wallet={this.state.credits_in_wallet} credit_product_array={this.state.credit_product_array} non_credit_product_array={this.state.non_credit_product_array} cart_details={this.state.cart_details} open_address_accordion={this.OpenAddressAccord} device="web"/>
															:
															<Loading />
														}
													</div>
												</div>
												<div className="checkout_accordion">
													<input type="checkbox" id="title2" className="faq_input faq-drawer__trigger" onChange={this.handleTypeCheckbox}/>
													<label for="title2" className="faq_question faq-drawer__title checkout_points">
														<div>
															<div className="checkout_add_address_point_web"><p className="checkout_points_text">2</p><p className="checkout_accordion_text">Add Delivery Address</p></div>
															{this.state.address_id == '' ? 
																null
											    				:
											    				<div className="address_section_web">
												    				{this.state.cart_details.user_data.address.map((item, i) => (
												    					<div key={i}>
												    					{item.id == this.state.address_id ?
												    						<div>
													    						<p className="delivery_address_text">{item.phone} | {this.state.cart_details.user_data.email}</p>
												    							<p className="delivery_address_text">{item.shipping_address+", "+item.ship_city+", "+item.ship_state+", "+item.ship_code}</p>
												    						</div>
												    						:
												    						null
												    					}
												    					</div>
												    				))}
											    				</div>
											    			}
										    			</div>
													</label>

													<div className="checkout_content content">
														{this.state.cart_details.user_data.address.length > 0 ?
															<div>
										    				{!this.state.save_add_address ?
													    		<CheckoutSelectAddress user_data={this.state.cart_details.user_data}  address_id={this.UpdateAddressID} save_add_address={this.state.save_add_address} show_add_address={this.ShowAddAddress} /> 
											    				:
											    				<CheckoutAddAddress close_address={this.CloseAddress} on_confirm={this.onConfirm} user_data={this.state.cart_details.user_data}/>
											    				}
											    			</div>
											    			:
											    			<CheckoutAddAddress close_address={this.CloseAddress} on_confirm={this.onConfirm} user_data={this.state.cart_details.user_data}/>
														}
													</div>
												</div>
												{this.state.cart_details.billing.payable_price > 0 ?
													<div className="checkout_accordion">
														<input type="checkbox" id="title3" className="faq_input faq-drawer__trigger" onChange={this.handleTypeCheckbox}/>
														<label for="title3" className="faq_question faq-drawer__title checkout_points">
															<img src="/images/Header/checkout_apply coupon_web.svg" className="apply_coupon_web_img" /><p className="checkout_accordion_text">Apply Coupon</p>
														</label>

														<div className="checkout_content content">
															<CheckoutCouponSection coupon_details={this.state.cart_details.coupons} coupon_id={this.ApplyCoupon} show_coupon_section={this.ShowCouponSection} selected_best_deal={this.state.selected_best_deal} selected_subscription_id={this.state.selected_subscription_id} clear_coupon_section={this.state.clear_coupon_section} device="web"/>
								    					</div>
													</div>
													:
													null
												}
											</div>
											<div className="col-sm-1"></div>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="col-sm-1"></div>
										<div className="col-sm-10">
											{this.state.cart_details.billing.oj_referral_discount > 0 ?
						    					<div className="col-sm-12 checkout_top_message_div">
						    						<p className="checkout_top_message_text extra_bold_text">Rs. {this.state.cart_details.billing.oj_referral_discount} OJ Cash applied! </p>
													<p className="cart_urgency_message_text">This deal is ensuring free delivery and a bundled discount on your cart!</p>
													<img src="/images/Header/confetti-green.png" className="checkout_confetti_img"/>
												</div>
												:
						    					null
						    				}
											{this.state.cart_details.billing.total_items > 0 ?
												<div>
													{/*{this.state.checkout_show_billing ? 
						    							<CheckoutBillDetails cart_details={this.state.cart_details} credits_in_wallet={this.state.credits_in_wallet} device="web"/>
														:
														<CheckoutPay device={this.state.device} cart_details={this.state.cart_details} address_id={this.state.address_id} checkout_back={this.CheckoutBack} coupon_id={this.state.coupon_id}/>
													}*/}
													<CheckoutBillDetails cart_details={this.state.cart_details} credits_in_wallet={this.state.credits_in_wallet} device="web"/>
												</div>
						    					:
						    					null
						    				}

						    				{this.state.address_id != '' ? 
						    					<center><button className="cart_checkout_confirm_button" onClick={() => this.CheckoutPayWeb("web")} >Confirm</button></center>
						    					:
						    					null
						    				}
					    				</div>
					    				<div className="col-sm-1"></div>
					    			</div>
				    			</div>
			    			</div>
		    			</div>
	    			</div>
	    			:
	    			<CheckoutPay device={this.state.device} cart_details={this.state.cart_details} address_id={this.state.address_id} checkout_back={this.CheckoutBack} coupon_id={this.state.coupon_id} stage={this.state.stage}/>
				);
			}
			else
			{
				return (
					<div>
		    			<Header color={"#160D08"}/> 
		    			<CheckoutEmpty />
		    		</div>
	    		);
			}
			
		}
		return (
				<div className="checkout_details"> 
	      			<Header color={"#160D08"}/> 
	      			<Loading />
	      		</div>
	      	);
	}
}


export default Checkout;

/*{this.state.address_id == '' ? 
	<CheckoutFooter user_data={this.state.cart_details.user_data} update_address_id={this.UpdateAddressID}/> 
: 
	<CheckoutFooterConfirm cart_details={this.state.cart_details}/>
}*/
