import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from '../Common/Footer';
import OrderTracker from './OrderTracker';
import { Link } from 'react-router-dom';
import Loading from "../Common/Loading";

class OrderTrackerWebApp extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            token : '',
            order_id: '',
            product_id: '',
            product_details: {delivery_address:{},products:{}, power: {}},
        };
    }
    componentDidMount()
    {
        let token = this.props.match.params.access_token;
        let order_id =  this.props.match.params.order_id;
        let product_id = this.props.match.params.product_id;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ token);

        let url = process.env.REACT_APP_API_URL+'/v1/orders/'+order_id+'/product/'+product_id;

        var requestOptions = {
            headers: myHeaders,
        };

        fetch(url, requestOptions)
        .then(response => response.json())
        
        .then(data => {
            this.setState({order_id: order_id, product_id: product_id, product_details: data.data, products: data.data.products, token: token}) 
        })
    }
    render() {
        console.log(this.state.product_details)
        
		return(
            <div>
                {this.state.product_details.id != null ?
                    <div className="each_order_section_body_app">
                        <div className="col-sm-3"></div>
            			<div className="col-xs-12 col-sm-6 each_order_id_section">
                            <p className="each_product_order_id">Order ID</p>
                            <p>{this.state.product_details.hashed_id}</p>
                        </div>
                        <div className="col-sm-3"></div>

                        <div className="clearfix"></div>

                        <div className="col-sm-3"></div>
                        <div className="col-xs-12 col-sm-6  padding_is_zero">
                            <center>
                                <Link to={{pathname: "/product/"+this.state.product_details.products.product_url+"/color/"+this.state.product_details.products.color_id, state: { prevPath: window.location.pathname }}}><img src={this.state.product_details.products.image} className="order_product_image img-responsive" /></Link>
                            </center>
                        </div>
                        <div className="col-sm-3"></div>

                        <div className="col-xs-12 order_product_details">
                            <center>
                                <p className="order_product_brand_text">{this.state.product_details.products.brand_name}</p>
                                <p className="order_product_full_name">{this.state.product_details.products.name}</p>
                                <p className="order_product_size">{this.state.product_details.products.size} - {this.state.product_details.products.gender}<span className="colour_dot order_product_color" style={{backgroundColor: this.state.product_details.products.color}} ></span></p>
                                <p className="order_product_qty">X {this.state.product_details.products.qty}</p>
                            </center>
                        </div>
                        {this.state.product_details.tracker ?
                            <div>
                                {this.state.product_details.tracker.length > 0 ?
                                    <OrderTracker tracker={this.state.product_details.tracker}/>
                                    : 
                                    null
                                }
                            </div>
                            :
                            null
                        }
                        
                        <div className="col-sm-3"></div>
                        <div className="clearfix"></div>
                        {this.state.product_details.power.left ?
                            <div>
                            <div className="col-sm-3"></div>
                            <div className="col-xs-12 col-sm-6 order_product_power padding_is_zero">
                                <p className="order_delivery_text">Power Details</p>
                                <div className="col-sm-6 col-xs-6">
                                    <p className="order_delivery_subtext">
                                        <u>Left</u><br/> Spherical: {this.state.product_details.power.left.sph}<br/> Cylinderical: {this.state.product_details.power.left.cyl}<br/> PD:  {this.state.product_details.power.left.pd}<br/> Axis: {this.state.product_details.power.left.axis}
                                    </p>
                                </div>
                                <div className="col-sm-6 col-xs-6">
                                    <p className="order_delivery_subtext">
                                        <u>Right</u><br/> Spherical: {this.state.product_details.power.right.sph}<br/> Cylinderical: {this.state.product_details.power.right.cyl}<br/> PD:  {this.state.product_details.power.right.pd}<br/> Axis: {this.state.product_details.power.right.axis} 
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-3"></div>
                            </div>
                            :
                            null
                        }
                        <div className="clearfix"></div>
                        {this.state.product_details.delivery_address.shipping_address ?
                            <div className="col-sm-12 padding_is_zero">
                            <div className="col-sm-3"></div>
                            <div className="col-xs-12 col-sm-6 order_product_address padding_is_zero">
                                <p className="order_delivery_text">Delivery Address</p>
                                <p className="order_delivery_subtext">{this.state.product_details.delivery_address.name} | {this.state.product_details.delivery_address.phone}</p>
                                <p className="order_delivery_subtext">{this.state.product_details.delivery_address.shipping_address}, {this.state.product_details.delivery_address.ship_city}, {this.state.product_details.delivery_address.ship_state}, {this.state.product_details.delivery_address.ship_code}</p>
                            </div>
                            <div className="col-sm-3"></div>
                            </div>
                            :
                            null
                        }
                        <div className="clearfix"></div>

                        {this.state.product_details.total_cost ?
                            <div className="col-sm-12 padding_is_zero">
                                <div className="col-sm-3"></div>
                                <div className="col-xs-12 col-sm-6 order_product_price padding_is_zero">
                                    <p className="order_delivery_text">Amount Paid</p><p></p>
                                    <p className="order_delivery_subtext">Rs. {this.state.product_details.total_cost}</p>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                            :
                            null
                        }
                        <div className="clearfix"></div>
                    </div>
                    :
                    <Loading />
                }
            </div>
        );
		
	}
}

export default OrderTrackerWebApp;