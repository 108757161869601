import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from '../Common/Footer';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

class SubscriptionPlan extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            cart_count: 0,
            redirect: false,
            redirectUrl: '/explore-ojos',
            app_id: 0,
        };
        this.SaveNow = this.SaveNow.bind(this);
    }
    componentDidMount()
    {
        const query = new URLSearchParams(this.props.location.search);
        const app_id = query.get('app_id');
        if(app_id == 1)
        {
            this.setState({app_id: app_id});
        }
        let prod_count = localStorage.getItem("prod_counts");
        if(prod_count)
        {
            var total_qty = 0;
            let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
            for(var i = 0; i<= product_id_array.length-1; i++)
            {
                if(product_id_array[i] !== 0)
                {
                    total_qty = total_qty + JSON.parse(localStorage.getItem("prod_"+product_id_array[i])).qty;
                }
            }
            this.setState({
                cart_count: total_qty
            });
        }
    }
    SaveNow()
    {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        if(String(params).indexOf("cart") > -1)
        {
            this.setState({'redirect': true, 'redirectUrl': '/cart'});
        }
        else
        {
            this.setState({'redirect': true, 'redirectUrl': '/explore-ojos'});
        }
    }
    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
        return(
			<div>
                {this.state.app_id != 1 ?
                    <Header color={"#160D08"} cart_count={this.state.cart_count} show_launch_timer={1}/>
                    :
                    null
                }
                <div className="subscription_plan_page">
                    <div className="subscription_plan_section_1 hidden-sm hidden-lg hidden-md">
                        <div className="col-xs-12 col-sm-12 subscription_first_header">
                            <h2 className="subscribe_introducing_text extra_bold_text">introducing</h2>
                            <h1 className="subscription_header_text subscribe_super_main_text extra_bold_text">OJ SUPER</h1>
                            <h3 className="subscription_header_subtext">Get 3 eyeglasses for flat Rs.2100 only</h3>
                        </div>
                        <div className="col-sm-2"></div>
                        <div className="col-xs-12 col-sm-4">
                            <center><img src="/images/Header/OJSuperHero.png" className="img-responsive subscription_first_img" /></center>
                        </div>
                        <div className="col-xs-12 col-sm-4 subscription_first_section_text">
                            <h1 className="subscription_header_text">Subscribe and save more than</h1>
                            <h3 className="subscription_header_subtext"><b className="subscription_53_text extra_bold_text">53%</b><b className="subscription_53_subtext"> on purchase of<br/> each eyewear</b></h3>
                        </div>
                        <div className="col-sm-2"></div>
                    </div>

                    <div className="subscription_plan_section_1 hidden-xs">
                        <div className="col-xs-12 col-sm-12 subscription_first_header padding_is_zero">
                            <h2 className="subscribe_introducing_text subscribe_introducing_text_web extra_bold_text">introducing</h2>
                            <h1 className="subscription_header_text subscribe_introducing_text_web subscribe_super_main_text extra_bold_text">OJ SUPER</h1>
                            <h3 className="subscription_header_subtext subscribe_introducing_text_web">Get 3 eyeglasses for flat Rs.2100 only</h3>
                        </div>
                        <div className="col-sm-2"></div>
                        <div className="col-xs-12 col-sm-4">
                            <center><img src="/images/Header/OJSuperHero.png" className="img-responsive subscription_first_img" /></center>
                        </div>
                        <div className="col-xs-12 col-sm-5 subscription_first_section_text">
                            <h1 className="subscription_header_text">OJ SUPER Subscribe & Save</h1>
                            <h3 className="subscription_header_text">Subscribe and save more than</h3>
                            <h5 className="subscription_header_subtext"><b className="subscription_53_text extra_bold_text">53%</b><b className="subscription_53_subtext"> on purchase of<br/> each eyewear</b></h5>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-sm-12 hidden-xs padding_is_zero">
                        <div className="col-sm-12 subscription_plan_section_2 subscription_plan_section_2_web">
                            <div className="col-sm-12 subscription_plan_section_2_header">
                                <center>
                                    <p className="subscription_header_blue_box">Start your savings spree in</p>
                                    <p className="subscription_header_blue_box"><b className="subscription_numbers_points extra_bold_text">3</b> <b className="subscription_easy_text">easy steps</b></p>
                                </center>
                            </div>
                            <div className="col-sm-12">
                                <div className="col-sm-4">
                                    <div className=" subscription_first_section_img subscription_white_boxes">
                                        <b className="subscription_numbers_points extra_bold_text">1</b>
                                        <p className="">Pick your favourite pairs from our range of <b className="subscription_color_text"> stylish and exclusively curated OJ Eyewear.</b> Add them to your cart.</p>
                                    </div>
                                </div>
                                <div className="col-sm-4 subscription_middle_phone_web">
                                    <center><img src="/images/Header/DetailsPageMockup.png" className="img-responsive subscription_middle_box_text_img_phone"/></center>
                                </div>
                                <div className="col-sm-4"></div>
                                {/*<img src="/images/Header/DetailsPageMockup.png" className="img-responsive subscription_first_box_text_img_phone"/>
                                <img src="/images/Header/Group 1083.png" className="img-responsive subscription_first_box_text_img_arrow"/>*/}
                            </div>
                            <div className="col-sm-12">
                                <div className="col-sm-4">
                                    <img src="/images/Header/Group 1081.png" className="img-responsive subscription_first_box_text_img_arrow_web"/>
                                </div>
                                <div className="col-sm-4 subscription_middle_phone_web">
                                    <center><img src="/images/Header/CartPageMockup.png" className="img-responsive subscription_middle_box_text_img_phone"/></center>
                                </div>
                                <div className="col-sm-4">
                                    <div className="subscription_first_section_img subscription_white_boxes subscription_white_boxes_right">
                                        <b className="subscription_numbers_points extra_bold_text">2</b>
                                        <p className="">On your cart page, <br/> select the <b className="subscription_color_text">OJ Super</b> deal</p>
                                    </div>
                                </div>
                                {/*<img src="/images/Header/CartPageMockup.png" className="img-responsive subscription_second_box_text_img_phone"/>
                                <img src="/images/Header/Group 1084.png" className="img-responsive subscription_second_box_text_img_arrow"/>*/}
                            </div>
                            <div className="col-sm-12">
                                <div className="col-sm-4">
                                    <div className="subscription_first_section_img subscription_white_boxes">
                                        <b className="subscription_numbers_points extra_bold_text">3</b>
                                        <p className="">Voila! Once you select the deal you get 3 credits to complete your purchase.<br/> <b className="subscription_color_text">1 credit = 1 eyewear.</b><br/> The unused credits will be stored as your OJ Credits in your account, with a validity of 1 year. So come back anytime to pick more!</p>
                                    </div>
                                </div>
                                <div className="col-sm-4 subscription_middle_phone_web">
                                    <center><img src="/images/Header/CreditsMockup.png" className="img-responsive subscription_middle_box_text_img_phone"/></center>
                                </div>
                                <div className="col-sm-4"><img src="/images/Header/Group 1082.png" className="img-responsive subscription_second_box_text_img_arrow_web"/></div>
                                {/*<img src="/images/Header/CreditsMockup.png" className="img-responsive subscription_first_box_text_img_phone"/>*/}
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 padding_is_zero hidden-sm hidden-lg hidden-md">
                        <div className="col-xs-12 subscription_plan_section_2">
                            <div className="col-xs-12 subscription_plan_section_2_header">
                                <center>
                                    <p className="subscription_header_blue_box">Start your savings spree in</p>
                                    <p className="subscription_header_blue_box"><b className="subscription_numbers_points extra_bold_text">3</b> <b className="subscription_easy_text">easy steps</b></p>
                                </center>
                            </div>
                            <div className="col-xs-12 subscription_first_section_img">
                                <b className="subscription_numbers_points extra_bold_text">1</b>
                                <p className="subscription_first_box_text">Pick your favourite pairs from our range of <b className="subscription_color_text"> stylish and exclusively curated OJ Eyewear.</b> Add them to your cart.</p>
                                <img src="/images/Header/DetailsPageMockup.png" className="img-responsive subscription_first_box_text_img_phone"/>
                                <img src="/images/Header/Group 1083.png" className="img-responsive subscription_first_box_text_img_arrow"/>
                            </div>
                            <div className="col-xs-12 subscription_first_section_img">
                                <b className="subscription_second_box_text subscription_numbers_points extra_bold_text">2</b>
                                <p className="subscription_second_box_text subscription_second_box_maintext">On your cart page, <br/> select the <b className="subscription_color_text">OJ Super</b> deal</p>
                                <img src="/images/Header/CartPageMockup.png" className="img-responsive subscription_second_box_text_img_phone"/>
                                <img src="/images/Header/Group 1084.png" className="img-responsive subscription_second_box_text_img_arrow"/>
                            </div>
                            <div className="col-xs-12 subscription_first_section_img">
                                <b className="subscription_numbers_points extra_bold_text">3</b>
                                <p className="subscription_first_box_text">Voila! Once you select the deal you get 3 credits to complete your purchase.<br/> <b className="subscription_color_text">1 credit = 1 eyewear.</b><br/> The unused credits will be stored as your OJ Credits in your account, with a validity of 1 year. So come back anytime to pick more!</p>
                                <img src="/images/Header/CreditsMockup.png" className="img-responsive subscription_first_box_text_img_phone"/>
                            </div>
                            {/*<div className="col-xs-12 subscription_second_section_text padding_is_zero">
                                <center>
                                    <h4 className="subscription_header_text"><b>Choose the “Get 3 for Rs.2100” deal during checkout</b></h4>
                                    <p className="subscription_second_section_subtext">3 Credits will be added to your OJOS Wallet</p>
                                    <p className="subscription_second_section_subtext">These credits will be the default payment for your purchase of 3 subsequent eyeglasses</p>
                                    <p className="subscription_second_section_subtext">The eyeglasses can be purchased together now or anytime in the next 1 year</p>
                                </center>
                            </div>
                            
                            <div className="col-xs-12 subscription_first_section_img">
                                <center><img src="https://s3.ap-southeast-1.amazonaws.com/flash-dashboard-image/icons/SubscriptionIllustration3.png" className="img-responsive" /></center>
                            </div>
                            <div className="col-xs-12 subscription_first_section_text padding_is_zero">
                                <h4 className="subscription_header_text"><b>Choose from 21 trending styles, refreshed every 21 days</b></h4>
                                <p className="subscription_second_section_subtext">Purchase eyeglasses anytime using OJOS credits Eg - 1 credit used = 1 eyeglass.</p>
                                <p className="subscription_second_section_subtext">Unused credits will be transferred to your OJOS Wallet, stay assured!</p>
                                <p className="subscription_second_section_subtext">Come back to pick your pair at your convenience, no limits!</p>
                                <p className="subscription_second_section_subtext">You can add different powers for different eyeglasses.</p>
                            </div>*/}
                            
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-sm-4"></div>
                    <div className="col-xs-12 col-sm-4">
                        {this.state.app_id != 1 ?<center><button className="subscription_save_now_button" onClick={this.SaveNow}>Yes, Save Now!</button></center> : null }
                    </div>
                    <div className="col-sm-4"></div>
                </div>
                {this.state.app_id != 1 ?
                    <Footer />
                    :
                    null
                }
            </div>
		);
		
	}
}

export default SubscriptionPlan;