import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from '../Common/Footer';
import { Redirect } from 'react-router';
import Lottie from 'react-lottie';
import animationData from '../Lotties/account_page_lottie.json';

class Account extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            gender: 'Female',
            token: localStorage.getItem('token'),
            profile_image: '/images/Header/Female_Avatar.svg',
            redirect: false,
            redirectUrl: '/',
            width: window.innerWidth,
            logout: 'LOGOUT',
        };
        this.ClickLogout = this.ClickLogout.bind(this);
    }
    componentDidMount()
    {
        if(this.state.token)
        {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ this.state.token);
            var requestOptions = {
                headers: myHeaders,
            };
            fetch(process.env.REACT_APP_API_URL+'/v1/user', requestOptions)
            .then(response => response.json())
            .then(
            (res) => {
                console.log(res);
                if(res.result.gender == "Female")
                {
                    var profile_image = "/images/Header/Female_Avatar.svg"
                }
                else
                {
                    var profile_image = "/images/Header/Male_Avatar.svg"
                }
                this.setState({name: res.result.name, email: res.result.email, phone: res.result.phone, gender: res.result.gender, profile_image: profile_image});
                localStorage.setItem('name', res.result.name);
            });
        }
        else
        {
            this.setState({redirect: true, redirectUrl: '/login'}); 
        }
    }
    componentWillMount() 
    {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    ClickLogout()
    {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        this.setState({
            logout: 'Logging Off...'
        });
        setTimeout(() => {
            this.setState({ token : '', logout: 'LOGOUT', redirect: true, redirectUrl: '/' });
        }, 2000);

    }
    render() {

        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        };
        const styles = {
            checkAnimation: {
                
            },
        }

        const { width } = this.state;
        const isMobile = width <= 768;
        if (isMobile) 
        {
            if(this.state.redirect){
                return <Redirect to={this.state.redirectUrl} />
            }
            return (
                <div>
                    <Header color={"#160D08"}/>
                    <div className="dark_background">
                        <div className="account_profile_section">
                            <center>
                                <img src={this.state.profile_image} className="male_avatar"/>
                                <p className="account_profile_header_text_bold">{this.state.name}</p>
                                <p className="account_profile_header_text">{this.state.phone}</p>
                                <p className="account_profile_header_text">{this.state.email}</p>
                                <p><a href="/edit-profile" className="account_edit_profile"><i class="fa fa-pencil" aria-hidden="true"></i> Edit Profile Details</a></p>
                            </center>
                        </div>
                        <div className="curved_div_account">
                            {/*<a href="/address" target="_blank">
                                <div className="col-xs-12 padding_is_zero each_account_section">
                                    <div className="col-xs-2">
                                        <img src="/images/Header/house-svgrepo-com.svg" className="account_page_icon"/>
                                    </div>
                                    <div className="col-xs-10">
                                        <p className="account_each_section_text">My Address</p>
                                        
                                    </div>
                                    <div className="col-xs-2">
                                        
                                    </div>
                                </div>
                            </a>*/}
                            {/*<a href="/prescription" target="_blank">
                                <div className="col-xs-12 padding_is_zero each_account_section">
                                    <div className="col-xs-2">
                                        <img src="/images/Header/prescription-svgrepo-com.svg" className="account_page_icon"/>
                                    </div>
                                    <div className="col-xs-10">
                                        <p className="account_each_section_text">My Prescription</p>
                                        
                                    </div>
                                    <div className="col-xs-2">
                                        
                                    </div>
                                </div>
                            </a>*/}
                            <a href="/order" target="_blank">
                                <div className="col-xs-12 padding_is_zero each_account_section">
                                    <div className="col-xs-2">
                                        <img src="/images/Header/package-svgrepo-com.svg" className="account_page_icon"/>
                                    </div>
                                    <div className="col-xs-10">
                                        <p className="account_each_section_text">My Orders</p>
                                        {/*<p className="account_each_section_subtext">Review your orders</p>*/}
                                    </div>
                                    <div className="col-xs-2">
                                        {/*<img src="/images/Header/Chevron_Brown.svg" className="account_page_icon"/>*/}
                                    </div>
                                </div>
                            </a>
                            <a href="/credit" target="_blank">
                                <div className="col-xs-12 padding_is_zero each_account_section">
                                    <div className="col-xs-2">
                                        <img src="/images/Header/coins-money-svgrepo-com.svg" className="account_page_icon"/>
                                    </div>
                                    <div className="col-xs-10">
                                        <p className="account_each_section_text">My Credits</p>
                                        
                                    </div>
                                    <div className="col-xs-2">
                                        {/*<img src="/images/Header/Chevron_Brown.svg" className="account_page_icon"/>*/}
                                    </div>
                                </div>
                            </a>
                            <a href="/referral" target="_blank">
                                <div className="col-xs-12 padding_is_zero each_account_section">
                                    <div className="col-xs-2">
                                        <img src="/images/Header/ReferralIcon.png" className="account_page_icon"/>
                                    </div>
                                    <div className="col-xs-10">
                                        <p className="account_each_section_text">My Referral</p>
                                        
                                    </div>
                                    <div className="col-xs-2">
                                        {/*<img src="/images/Header/Chevron_Brown.svg" className="account_page_icon"/>*/}
                                    </div>
                                </div>
                            </a>
                            
                        </div>
                        <div className="clearfix"></div>
                        <div className="account_logout_div">
                            <p className="account_logout" onClick={this.ClickLogout}>{this.state.logout}</p>
                        </div>
                        
                    <Footer />
                    </div>
                    
                </div>
            );
        }
        else
        {
            if(this.state.redirect){
                return <Redirect to={this.state.redirectUrl} />
            }
            return (
                <div>
                    <Header color={"#160D08"}/>
                    <div className="col-sm-12 account_web_div">
                        <p className="account_web_heading">MY ACCOUNT</p>
                        {/*<p className="account_web_subheading">Review your account</p>*/}
                        <div className="col-sm-3  padding_is_zero">
                            <div className="col-sm-12 account_web_dark_div padding_is_zero">
                                <div className="col-sm-5">
                                    <center>
                                        <img src={this.state.profile_image} className="male_avatar"/>
                                    </center>
                                </div>
                                <div className="col-sm-7">
                                    <p className="account_profile_header_text_bold">{this.state.name}</p>
                                    <p className="account_profile_header_text">{this.state.phone}</p>
                                    <p className="account_profile_header_text">{this.state.email}</p>
                                    <p><a href="/edit-profile" className="account_edit_profile"><i class="fa fa-pencil" aria-hidden="true"></i> Edit Profile Details</a></p>
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className="col-sm-9">
                            <a href="/credit" target="_blank">
                                <div className="col-sm-3 account_web_right_each_section">
                                    <center>
                                        <img src="/images/Header/coins-money-svgrepo-com.svg" className="account_web_page_icon_right"/>
                                        <p className="account_each_section_text">My Wallet</p>
                                    </center>
                                </div>
                            </a>

                            {/*<a href="/order" target="_blank">
                                <div className="col-sm-3 account_web_right_each_section">
                                    <center>
                                        <img src="/images/Header/Prescription_Brown.svg" className="account_web_page_icon_right"/>
                                        <p className="account_each_section_text">My Prescriptions<br /><p className="account_each_section_subtext">Add new prescriptions</p></p>
                                    </center>
                                </div>
                            </a>*/}
                            {/*<div className="col-sm-3 account_web_right_each_section">
                                <center>
                                    <img src="/images/Header/SuperOjos_Brown.svg" className="account_web_page_icon_right"/>
                                    <p className="account_each_section_text">My SuperOjos <br /><p className="account_each_section_subtext">Review your SuperOjos benefits</p></p>
                                </center>
                            </div>*/}
                            <a href="/order" target="_blank">
                                <div className="col-sm-3 account_web_right_each_section">
                                    <center>
                                        <img src="/images/Header/package-svgrepo-com.svg" className="account_web_page_icon_right"/>
                                        <p className="account_each_section_text">My Orders</p>
                                    </center>
                                </div>
                            </a>

                            <a href="/referral" target="_blank">
                                <div className="col-sm-3 account_web_right_each_section">
                                    <center>
                                        <img src="/images/Header/ReferralIcon.png" className="account_web_page_icon_right"/>
                                        <p className="account_each_section_text">My Referral</p>
                                    </center>
                                </div>
                            </a>
                        </div>
                        <div className="clearfix"></div>

                        <div className="col-sm-3">
                            <Lottie options={defaultOptions} width={300} style={styles.checkAnimation}/>
                        </div>

                        <div className="clearfix"></div>

                        <div className="account_logout_div">
                            <p className="account_logout" onClick={this.ClickLogout}>{this.state.logout}</p>
                        </div>
                    </div>
                    <Footer />
                </div>
            );
        }
    }
}

export default Account;