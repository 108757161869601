import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomePageCollectionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_image: '',
            rest_image: [],
        };
    }
    componentDidMount()
    {
        this.props.collection_list.forEach((element,index) => {
            if(index == 0)
            {
                this.setState({ first_image: element});
            }
        });
        var arr = [];
        this.props.collection_list.forEach((element,index) => {
            
            if(index > 0 && index < 5)
            {
                arr.push(element);
            }
            
        });
        this.setState({ rest_image: arr });
    }
    render() {
        return (
            <div>
            {this.props.source == "mob" ?
                <div className="col-xs-12 padding_is_zero">
                    <span className="trending_product_text_mob">
                      shop by collections
                    </span>
                    <a href="/products/eyeglasses/collection">
                      <span className="trending_product_view_mob">
                        <u>show all</u>
                      </span>
                    </a>
                    <div className="col-xs-12">
                        <a href={"/products/eyeglasses/collection/"+this.state.first_image.name}><img src={this.state.first_image.collection_banner_mob} className="img-responsive collection_product_model_img homepage_first_img_coll_mob"/></a>
                    </div>
                    <div className="clearfix"></div>
                    <section className="carousel corousel_collection_product" aria-label="Gallery">
                        <ol className="carousel__viewport__collection">
                            {this.state.rest_image.map((item, product_index) => ( 
                            <li className="carousel__slide__trending collection_product_div carousel__slide_collection carousel__slide">
                              
                                <div className="col-xs-12 col-sm-4 col-md-2 collection_carousel_mob">
                                    <a href={"/products/eyeglasses/collection/"+item.name}><img src={item.collection_banner_mob} className="img-responsive  collection_product_model_img gallery-image center-block"/></a>
                                </div>
                                    
                            </li>
                        ))}
                        </ol>
                    </section>
                </div>
                :
                <div className="col-xs-12 padding_is_zero">
                    <span className="trending_product_text">
                      shop by collections
                    </span>
                    <a href="/products/eyeglasses/collection">
                      <span className="trending_product_view">
                        <u>show all</u>
                      </span>
                    </a>
                    
                    <div className="col-xs-12 homepage_collection_section padding_is_zero ">
                        <div className="images--flex">
                            <a href={"/products/eyeglasses/collection/"+this.state.first_image.name}><img src={this.state.first_image.collection_banner_web} className="collection_product_model_img"/></a>
                            <div class="images--flex__inner">
                                {this.state.rest_image.map((item, product_index) => ( 
                                    <a href={"/products/eyeglasses/collection/"+item.name}><img src={item.collection_banner_web} className="collection_product_model_img" /></a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
            </div>
        );
    }
}
export default HomePageCollectionList;
