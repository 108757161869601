import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HomePageOjosFeatureSection extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
    render() {
        return (
            <div>
                <div className="col-xs-12 homepage_download_app_section">
                    <div className="hidden-sm hidden-lg hidden-md">
                        <div className="col-sm-12 padding_is_zero">
                            <h1 className="homepage_download_app_header extra_bold_text">download our</h1>
                            <h1 className="homepage_download_app_subtext">MOBILE APP</h1>
                            <ul className="homepage_download_app_ul">
                                <li>Stay updated with trends and offers</li>
                                <li>Friendly UI</li>
                                <li>Faster and better in all aspects</li>
                                <li>Access to exclusive app-only offers on your favourite eyewear</li>
                                <li>Early access to new collection every month</li>
                                <li>Enhanced OJ Experience</li>
                                <li>Fastest customer care experience</li>
                            </ul>
                            <center>
                                <a href="https://bit.ly/3Ivkivt" target="_blank"><img src="/images/Header/AppScreens.png" className="img-responsive"/></a>
                            </center>
                            <div className="col-xs-6">
                                <a href="https://bit.ly/3Ivkivt" target="_blank"><img src="/images/Header/playstore_app_download.png" className="img-responsive"/></a>
                            </div>
                            <div className="col-xs-6">
                               <a href="https://apple.co/3vxJ4H6" target="_blank"><img src="/images/Header/ios_app_download.png" className="img-responsive"/></a>
                            </div>
                            <div className="clearfix"></div>
                            <p className="homepage_app_beta_text">PS: Perfection takes time. Our app is in the beta phase so it might lack some features. We would appreciate your feedback in making it better 💜</p>
                        </div>
                    </div>
                    <div className="hidden-xs">
                        <div className="col-sm-6">
                            <h1 className="homepage_download_app_header extra_bold_text">download our</h1>
                            <h1 className="homepage_download_app_subtext">MOBILE APP</h1>
                            <ul className="homepage_download_app_ul_web">
                                <li>Stay updated with trends and offers</li>
                                <li>Friendly UI</li>
                                <li>Faster and better in all aspects</li>
                                <li>Access to exclusive app-only offers on your favourite eyewear</li>
                                <li>Early access to new collection every month</li>
                                <li>Enhanced OJ Experience</li>
                                <li>Fastest customer care experience</li>
                            </ul>
                            <div className="col-sm-3">
                                <a href="https://bit.ly/3Ivkivt" target="_blank"><img src="/images/Header/playstore_app_download.png" className="img-responsive homepage_playstore_image_web"/></a>
                            </div>
                            <div className="col-sm-3">
                                <a href="https://apple.co/3vxJ4H6" target="_blank"><img src="/images/Header/ios_app_download.png" className="img-responsive homepage_playstore_image_web"/></a>
                            </div>
                        </div> 
                        <div className="col-sm-6">
                            <center>
                                <a href="https://bit.ly/3Ivkivt" target="_blank"><img src="/images/Header/AppScreens.png" className="img-responsive homepage_app_img_web"/></a>
                            </center>
                        </div> 
                        <div className="clearfix"></div>
                        <p className="homepage_app_beta_text_web">PS: Perfection takes time. Our app is in the beta phase so it might lack some features. We would appreciate your feedback in making it better 💜</p>
                    </div>
                </div>
                
            </div>
            );
    }
}
export default HomePageOjosFeatureSection;