import React, { Component } from "react";
import Header from "../Common/Header";

class OjosHiring extends Component 
{
	render() {
		return(
			
			<div>
				<Header color={"#160D08"}/> 
				<iframe src="https://docs.google.com/forms/u/2/d/e/1FAIpQLSdWrQ1ilWDORRkKq3D8i-Om54QNPqS9lCqcr_e3C53XcBI5bQ/viewform?embedded=true" className="ar_iframe"></iframe>
		    </div>
			
		);
		
	}
}

export default OjosHiring;