import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { RWebShare } from "react-web-share";
import Lottie from 'react-lottie';
import animationData from '../Lotties/ojos_loading.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class ImageView extends Component 
{    
    constructor(props) {
        super(props);
    }

    
    render() {
        
      const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          };
        const styles = {
            checkAnimation: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              minHeight: '50vh',
            },
        }
 
      return(
                        <div className="image_section image_section_mob">
                            <div className="large_image_slider">
                              
                                    <Carousel
                                          swipeable={true}
                                          draggable={false}
                                          showDots={false}
                                          responsive={responsive}
                                          //ssr={true} // means to render carousel on server-side.
                                          infinite={false}
                                          //autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                          //autoPlaySpeed={1000}
                                          keyBoardControl={true}
                                          //customTransition="all .5"
                                          //transitionDuration={500}
                                          containerClass="carousel-container"
                                          removeArrowOnDeviceType={["tablet", "mobile"]}
                                          deviceType={this.props.deviceType}
                                          dotListClass="custom-dot-list-style"
                                          itemClass="carousel-item-padding-40-px"
                                        >
                                                  
                                            {this.props.item.glass_images.map((image_item, index) => (
                                                <div>
                                                  <LazyLoadImage src={image_item.url} effect="blur" height="100%" width="100%" className="lazy_loading_img" />
                                                  {/*<img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.state.loading ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/>*/}
                                                </div>
                                            ))}
                                    </Carousel>
                                    {/*{this.props.show_loading_lottie===1 ?
                                      <div className="loading-product-listing">
                                        <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} />
                                      </div>
                                      :
                                      <div></div>
                                    }*/}
                                
                              
                            </div>
                            {this.props.item.model_images.length > 0 ?
                              <div className="img_thumbnail_mob">
                                  <img className="expand_icon_web" src="/images/Header/Expand_Thumbnail_icon_ListingPage.svg" alt="expand_icon" />
                                  <img className="img-rounded product_thumbnail_web" src={this.props.item.model_images[0].url} onClick={() => this.props.change_picture(this.props.i)} alt="frame_img"/> 
                              </div> 
                              :
                              null
                            }
                        </div> 
      );
    }
}

export default ImageView;
