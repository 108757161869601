import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from '../Common/Footer';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

class SubscriptionPlan extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            cart_count: 0,
            redirect: false,
            redirectUrl: '/explore-ojos',
            app_id: 0,
        };
        this.ProductSizeExplore = this.ProductSizeExplore.bind(this);
    }
    componentDidMount()
    {
        const query = new URLSearchParams(this.props.location.search);
        const app_id = query.get('app_id');
        if(app_id == 1)
        {
            this.setState({app_id: app_id});
        }
        let prod_count = localStorage.getItem("prod_counts");
        if(prod_count)
        {
            var total_qty = 0;
            let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
            for(var i = 0; i<= product_id_array.length-1; i++)
            {
                if(product_id_array[i] !== 0)
                {
                    total_qty = total_qty + JSON.parse(localStorage.getItem("prod_"+product_id_array[i])).qty;
                }
            }
            this.setState({
                cart_count: total_qty
            });
        }
    }
    ProductSizeExplore()
    {
        window.history.back();
    }
    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
        return(
			<div>
                {this.state.app_id != 1 ?
                    <Header color={"#160D08"} cart_count={this.state.cart_count}/>
                    :
                    null
                }
                <div className="subscription_plan_page">
                    <div className="subscription_plan_section_1">
                        <center>
                            <div className="col-xs-12 col-sm-12">
                                <h1 className="subscription_header_text"><b>Confused about frame sizes?</b></h1>
                                <h3 className="subscription_header_subtext">Measure your face size using any of <br/> the given methods</h3>
                            </div>
                        </center>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-sm-12 hidden-xs">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10 subscription_plan_section_2">
                            <div className="col-sm-12 subscription_plan_section_2_header">
                                <center><h2 className="subscription_header_text">Credit Card Method</h2></center>
                            </div>
                            <div className="col-sm-5 subscription_second_section_text">
                                <h4 className="subscription_header_text"><b>1. Take a Debit/Credit Card</b></h4>
                                <p>Hold the card vertically</p>
                                <p>Stand in front of a mirror</p>
                                <p>Place one tip of the card on centre of your nose <br/> and touch the other towards the edge of eye</p>
                            </div>
                            <div className="col-sm-2"></div>
                            <div className="col-sm-4 subscription_first_section_img">
                                <center><img src="images/Header/Card.jpg" className="img-responsive" /></center>
                            </div>
                            <div className="col-sm-1"></div>

                            <div className="clearfix"></div>

                            
                            <div className="col-sm-12 subscription_second_section_text">
                                <h4 className="subscription_header_text"><b>2. Check the position of the card</b></h4>
                            </div>
                            <div className="col-sm-4 subscription_first_section_img">
                                <center>
                                    <img src="images/Header/Narrow Size.png" className="img-responsive product_size_model_img subscription_first_section_img" />
                                    <p className="subscription_second_section_subtext">If card extends beyond corner of the eye</p>
                                    <p className="subscription_second_section_subtext product_size_bold_text">SIZE IS NARROW</p>
                                </center>
                            </div>
                            <div className="col-sm-4 subscription_first_section_img">
                                <center>
                                    <img src="images/Header/Medium Size.png" className="img-responsive product_size_model_img subscription_first_section_img" />
                                    <p className="subscription_second_section_subtext">If card roughly touches the corner of the eye</p>
                                    <p className="subscription_second_section_subtext product_size_bold_text">SIZE IS MEDIUM</p>
                                </center>
                            </div>
                            <div className="col-sm-4 subscription_first_section_img">
                                <center>
                                    <img src="images/Header/Wide Size.png" className="img-responsive product_size_model_img subscription_first_section_img" />
                                    <p className="subscription_second_section_subtext">If card doesn’t reach corner of the eye</p>
                                    <p className="subscription_second_section_subtext product_size_bold_text">SIZE IS WIDE</p>
                                </center>
                            </div>

                            <div className="clearfix"></div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>

                    <div className="col-sm-12 hidden-xs">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10 subscription_plan_section_2">
                            <div className="col-sm-12 subscription_plan_section_2_header">
                                <center><h1 className="subscription_header_text">Using Existing Eyeframe</h1></center>
                            </div>
                            <div className="col-sm-12 subscription_first_section_img">
                                <center>
                                    <p className="product_size_step_text"><b>Look on the inside of your eyeglass temples (arm pieces)</b></p>
                                    <img src="images/Header/SizeGuidelines.jpg" className="img-responsive product_size_model_img" />
                                </center>
                            </div>
                            <div className="col-sm-12  padding_is_zero">
                                <center>
                                    <p className="subscription_second_section_subtext">Lens width and frame sizes</p>
                                    <table className="product_size_table">
                                        <tr>
                                            <td className="product_size_subtext product_size_table_left">Less than 47 mm</td>
                                            <td className="product_size_bold_text product_size_table_right">EXTRA NARROW</td>
                                        </tr>
                                        <tr>
                                            <td className="product_size_subtext product_size_table_left">48 mm - 50 mm</td>
                                            <td className="product_size_bold_text product_size_table_right">NARROW</td>
                                        </tr>
                                        <tr>
                                            <td className="product_size_subtext product_size_table_left">51 mm - 53 mm</td>
                                            <td className="product_size_bold_text product_size_table_right">MEDIUM</td>
                                        </tr>
                                        <tr>
                                            <td className="product_size_subtext product_size_table_left">More than 53 mm</td>
                                            <td className="product_size_bold_text product_size_table_right">WIDE</td>
                                        </tr>
                                    </table>
                                </center>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 hidden-xs">
                        <center><button className="product_size_explore_ojos" onClick={this.ProductSizeExplore}>EXPLORE OJOS</button></center>
                    </div>
                    <div className="col-sm-4"></div>

                    <div className="col-xs-12 hidden-sm hidden-lg hidden-md">
                        <div className="col-xs-12 subscription_plan_section_2">
                            <div className="col-xs-12 subscription_plan_section_2_header">
                                <center><h1 className="subscription_header_text">Credit Card Method</h1></center>
                            </div>

                            <div className="col-xs-12 subscription_first_section_img">
                                <center>
                                    <p className="product_size_step_text"><b>1. Take a Debit/Credit Card</b></p>
                                    <img src="images/Header/Card.jpg" className="img-responsive" />
                                </center>
                            </div>
                            <div className="col-xs-12 subscription_second_section_text padding_is_zero">
                                <center>
                                    <p className="subscription_second_section_subtext">Hold the card vertically</p>
                                    <p className="subscription_second_section_subtext">Stand in front of a mirror</p>
                                    <p className="subscription_second_section_subtext">Place one tip of the card on centre of your nose and touch the other towards the edge of eye</p>
                                </center>
                            </div>
                            
                            <div className="col-xs-12 subscription_first_section_img">
                                <p className="product_size_step_text"><b>2. Check the position of the card</b></p>
                                <center><img src="images/Header/Narrow Size.png" className="img-responsive product_size_model_img" /></center>
                            </div>
                            <div className="col-xs-12 subscription_first_section_text padding_is_zero">
                                <p className="subscription_second_section_subtext">If card extends beyond corner of the eye</p>
                                <p className="subscription_second_section_subtext product_size_bold_text">SIZE IS NARROW</p>
                            </div>

                            <div className="col-xs-12 subscription_first_section_img">
                                <center><img src="images/Header/Medium Size.png" className="img-responsive product_size_model_img" /></center>
                            </div>
                            <div className="col-xs-12 subscription_first_section_text padding_is_zero">
                                <p className="subscription_second_section_subtext">If card roughly touches the corner of the eye</p>
                                <p className="subscription_second_section_subtext product_size_bold_text">SIZE IS MEDIUM</p>
                            </div>

                            <div className="col-xs-12 subscription_first_section_img">
                                <center><img src="images/Header/Wide Size.png" className="img-responsive product_size_model_img" /></center>
                            </div>
                            <div className="col-xs-12 subscription_first_section_text padding_is_zero">
                                <p className="subscription_second_section_subtext">If card doesn’t reach corner of the eye</p>
                                <p className="subscription_second_section_subtext product_size_bold_text">SIZE IS WIDE</p>
                            </div>
                        </div>
                    </div>

                    <div className="clearfix"></div>

                    <div className="col-xs-12 hidden-sm hidden-lg hidden-md">
                        <div className="col-xs-12 subscription_plan_section_2">
                            <div className="col-xs-12 subscription_plan_section_2_header">
                                <center><h1 className="subscription_header_text">Using Existing Eyeframe</h1></center>
                            </div>
                            <div className="col-xs-12 subscription_first_section_img">
                                <center>
                                    <p className="product_size_step_text"><b>Look on the inside of your eyeglass temples (arm pieces)</b></p>
                                    <img src="images/Header/SizeGuidelines.jpg" className="img-responsive" />
                                </center>
                            </div>
                            <div className="col-xs-12 subscription_first_section_text padding_is_zero">
                                <center>
                                    <p className="subscription_second_section_subtext">Lens width and frame sizes</p>
                                    <table className="product_size_table">
                                        <tr>
                                            <td className="product_size_subtext product_size_table_left">Less than 47 mm</td>
                                            <td className="product_size_bold_text product_size_table_right">EXTRA NARROW</td>
                                        </tr>
                                        <tr>
                                            <td className="product_size_subtext product_size_table_left">48 mm - 50 mm</td>
                                            <td className="product_size_bold_text product_size_table_right">NARROW</td>
                                        </tr>
                                        <tr>
                                            <td className="product_size_subtext product_size_table_left">51 mm - 53 mm</td>
                                            <td className="product_size_bold_text product_size_table_right">MEDIUM</td>
                                        </tr>
                                        <tr>
                                            <td className="product_size_subtext product_size_table_left">More than 53 mm</td>
                                            <td className="product_size_bold_text product_size_table_right">WIDE</td>
                                        </tr>
                                    </table>
                                </center>
                            </div>
                        </div>
                        <center><button className="product_size_explore_ojos" onClick={this.ProductSizeExplore}>EXPLORE OJOS</button></center>
                    </div>
                </div>
                {this.state.app_id != 1 ?
                    <Footer />
                    :
                    null
                }
            </div>
		);
		
	}
}

export default SubscriptionPlan;