import React, { useEffect, useState } from "react";
import Header from "../../Common/Header";
import { Link, useParams } from 'react-router-dom';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import { Modal, Button, Form } from "react-bootstrap";
import Lottie from 'react-lottie';
import animationData from '../../Lotties/account_page_lottie.json';


const Address = () => {
    const [token, setToken] = useState('');
    const [credits, setCredits] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [profile_image, setProfileImage] = useState('');
    const [redirectTo, setRedirectTo] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [address_array, setAddressArray] = useState([]);
    const [logout, setLogout] = useState('LOGOUT');
    const [addAddress, setAddAddress] = useState(false);
    const [nameAddr, setNameAddr] = useState('');
    const [emailAddr, setEmailAddr] = useState('');
    const [mobAddr, setMobAddr] = useState('');
    const [addr1, setAddr1] = useState('');
    const [addr2, setAddr2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
   	const [pin, setPin] = useState('');
   	const [country, setCountry] = useState('India');
    
	useEffect(() => {
        const token = localStorage.getItem('token');
        const name = localStorage.getItem('name');
        if (token) 
        {
			var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ token);
            var requestOptions = {
                headers: myHeaders,
            };
            fetch(process.env.REACT_APP_API_URL+'/v1/user', requestOptions)
            .then(response => response.json())
            .then(
            (res) => {
                console.log(res);
                if(res.result.gender == "Female")
                {
                    var profile_image = "/images/Header/Female_Avatar.svg";
                }
                else
                {
                    var profile_image = "/images/Header/Male_Avatar.svg"
                }
                setToken(token);
            	setName(res.result.name);
            	setEmail(res.result.email);
            	setPhone(res.result.phone);
            	setProfileImage(profile_image);
            	setAddressArray(res.result.address);
            	setNameAddr(res.result.name);
            	setEmailAddr(res.result.email);
            	setMobAddr(res.result.phone);
            	localStorage.setItem('name', res.result.name);
            });
            
        }
        else
        {
            setRedirectTo('/login');
            setRedirect(true);
        }
        window.addEventListener('resize', handleWindowSizeChange);
    }, []);
    
    const handleWindowSizeChange = () => {
    	setWidth(window.innerWidth);
  	};
  	function AddAdrressOption()
	{
		setAddAddress(!addAddress);
	}
	function SaveAddress()
	{
		if(country.length < 2)
		{
			swal("Enter a valid Country");
		}
		else if(mobAddr.toString().length != 10 )
		{
			swal("Enter a valid Mobile Number");
		}
		else if(addr1.length < 1)
		{
			swal("Enter a Apartment/ House Number");
		}
		else if(addr2.length < 3)
		{
			swal("Enter a valid Locality, Street Address");
		}
		else if(city.length < 2)
		{
			swal("Enter a valid City");
		}
		else if(state.length < 2)
		{
			swal("Enter a valid State");
		}
		else if(pin.length != 6)
		{
			swal("Enter a valid Pincode");
		}
		else if(nameAddr.length < 2)
		{
			swal("Enter your Name");
		}
		else if(emailAddr.length < 2)
		{
			swal("Enter a valid Email ID");
		}
		else
		{
			var data = JSON.stringify({address : {type : 'shipping', shipping_address : addr1+", "+addr2, ship_code : pin,
	        ship_city : city,
	        ship_state : state,
	        phone : mobAddr,
	    	name: nameAddr,
	    	email: emailAddr}});
			var myHeaders = new Headers();
	        myHeaders.append("Authorization", "Bearer "+ token);
			var requestOptions = {
	            method: 'POST',
	            headers: myHeaders,
	            body: data,
	            redirect: 'follow'
	        };
			let url = process.env.REACT_APP_API_URL+'/v1/user/basic';
			fetch(url, {
	                method: 'POST',
	                headers: myHeaders,
	                body: data,
	                redirect: 'follow'
	            })
	            .then(response => response.json())
	            .then(
	                (res) => {
	                	if(res.success)
	                	{
	                		swal(
		                        "Address Saved"
		                    ).then(function() {
		                        setAddAddress(!addAddress);
		                    });
	                	}
	                	else
	                	{
	                		swal(res.result);
	                	}
	                });
		}
	}
  	function ClickLogout()
  	{
  		localStorage.removeItem('token');
        localStorage.removeItem('name');
        setLogout('Logging Off...')
        setTimeout(() => {
        	setToken('');
        	setLogout('LOGOUT');
        	setRedirectTo('/');
            setRedirect(true);
        }, 2000);
  	}
  	const myChangeHandler = (event) => {
	    let nam = event.target.name;
	    let val = event.target.value;
	    
	}
    if(redirect)
    {
            return <Redirect to={redirectTo} />
    }
    const isMobile = width <= 768;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const styles = {
        checkAnimation: {
            
        },
    }
    if (isMobile) 
    {
    	return(
    			<div>
	        		<Header color={"#160D08"}/> 
	        		<div className="select_address">
						<div id="myModal" className="modal-edited modal" data-spy="scroll"  data-offset="20">
							{addAddress ?
								<div className="my_address_modal modal-address modal-address-content modal-content-edited modal-content">
									<div className="col-sm-3"></div>
									<div className="col-sm-6 padding_is_zero">
										<div className="address-modal-header modal-header-edited modal-header">
								    		<div className="col-xs-12 add_address_header">
								    			<button type="button" className="address_close close" aria-label="Close" onClick={AddAdrressOption}>
										        	<span aria-hidden="true">&times;</span>
										        </button>
								    			<center><p className="header_address_text">Add Address</p></center>
								    		</div>
								    	</div>
								    	<div className="address-modal-body">
								    		<div className="col-xs-12">
					    						<input type="text" className="address_input add_address_input" onChange={(e) => setNameAddr(e.target.value)} name="name" placeholder="Name" value={nameAddr} maxLength="35" />
					    						<input type="text" className="address_input add_address_input" onChange={(e) => setMobAddr(e.target.value)} name="mob" placeholder="Mobile" value={mobAddr} />
					    						<input type="text" className="address_input add_address_input" onChange={(e) => setEmailAddr(e.target.value)} name="email" placeholder="Email" value={emailAddr} />
					    					</div>
					    					<div className="clearfix"></div>	
					    					<div className="col-xs-12">
					    						<input type="text" className="address_input add_address_input" onChange={(e) => setAddr1(e.target.value)} name="addr1" placeholder="Apartment/House Number" value={addr1} autoComplete="nope"/>
								    			<input type="text" className="address_input add_address_input" onChange={(e) => setAddr2(e.target.value)} name="addr2" placeholder="Locality, Street Address" value={addr2} autoComplete="nope"/>
								    			<input type="text" className="address_input add_address_input" onChange={(e) => setCity(e.target.value)} name="city" placeholder="City" value={city} autoComplete="nope"/>
								    			<input type="number" className="address_input add_address_input" onChange={(e) => setPin(e.target.value)} name="pin" placeholder="ZIP Code" value={pin} autoComplete="nope"/>
								    			<input type="text" className="address_input add_address_input" onChange={(e) => setState(e.target.value)} name="state" placeholder="State" value={state} autoComplete="nope"/>
								    			<input type="text" className="address_input add_address_input" name="country" placeholder="Country" value={country} readonly />
								    		</div>
								    		<div className="clearfix"></div>
								    		<div className="col-xs-12 col-sm-6 modal-footer-edited modal-footer">
						    					<center><button className="save_address_button" onClick={SaveAddress}>Save This Address</button></center>
											</div>
						    			</div>
								    </div>
				    				<div className="col-sm-3"></div>
								</div>
								:
								<div className="my_address_modal modal-address modal-address-content modal-content-edited modal-content">
									<div className="col-sm-3"></div>
									<div className="col-sm-6 padding_is_zero">
										<div className="address-modal-header modal-header-edited modal-header">
								    		<div className="col-xs-12 add_address_header">
								    			<a href="/account"><button type="button" className="address_close close" aria-label="Close" ><span aria-hidden="true">&times;</span></button></a>
								    			<center><p className="header_address_text">MY ADDRESS</p></center>
								    		</div>
								    	</div>
								    	<div className="address-modal-body">
								    		{address_array.map((item, i) => (
								    			<div className="col-xs-12 each_select_address_mob" key={i} >
													<div className="cart_quiz_card_area address_box_shadow">
														<input className="quiz_checkbox" type="radio" name="address_selected" id={"address_"+item.id} checked/>
														<div className="">
															<div className="quiz_card_content">
																<div className="quiz_card_title_cart_mob">
																	<p className="address_user_name">{item.name}'s Address</p>
																	<div className="address_text_section">
																		<p>{item.phone} | {email}</p>
																		<p>{item.shipping_address+", "+item.ship_city+", "+item.ship_state+", "+item.ship_code}</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
								    		))}
								    		<div className="col-xs-12 select_add_address_div">
								    			<div className="col-xs-1"></div>
								    			<div className="col-xs-10">
					    							<a data-toggle="collapse" href="#collapse1"><button className="add_new_address" onClick={AddAdrressOption}>+ ADD NEW ADDRESS</button></a>
					    						</div>
					    						<div className="col-xs-1"></div>
					    					</div>
								    	</div>
								    	
				    				</div>
				    				<div className="col-sm-3"></div>
								</div>
							}
						</div>
					</div>
				</div>
	    );
    }
    else
    {
    	return(
    			<div>
    				<Header color={"#160D08"}/>
    				<div className="col-sm-12 account_web_div">
                        <p className="account_web_heading">MY ADDRESS</p>
                        <div className="col-sm-3  padding_is_zero">
                            <div className="col-sm-12 account_web_dark_div padding_is_zero">
                                <div className="col-sm-5">
                                    <center>
                                        <img src={profile_image} className="male_avatar"/>
                                    </center>
                                </div>
                                <div className="col-sm-7">
                                    <p className="account_profile_header_text_bold">{name}</p>
                                    <p className="account_profile_header_text">{phone}</p>
                                    <p className="account_profile_header_text">{email}</p>
                                    <p><a href="/edit-profile" className="account_edit_profile"><i class="fa fa-pencil" aria-hidden="true"></i> Edit Profile Details</a></p>
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className="col-sm-9">
                        	{addAddress ?
                        		<div>
                        			<div className="col-sm-9"></div>
			                        	<div className="col-sm-3">
			                        		<button className='btn saved_address_button_web address_right_button' onClick={AddAdrressOption}><i className="fa fa-angle-left"></i>&#160; &#160; Back to Saved Address&#160;&#160;</button>
			                        		<br/>
			                        	</div>
			                        	<div className="clearfix"></div>
			                        	<div className="col-xs-6">
				    						<input type="text" className="address_input add_address_input" onChange={(e) => setNameAddr(e.target.value)} name="name" placeholder="Name" value={nameAddr} maxLength="35" />
				    						<input type="text" className="address_input add_address_input" onChange={(e) => setMobAddr(e.target.value)} name="mob" placeholder="Mobile" value={mobAddr} />
				    						<input type="text" className="address_input add_address_input" onChange={(e) => setEmailAddr(e.target.value)} name="email" placeholder="Email" value={emailAddr} />
				    					</div>
				    					<div className="clearfix"></div>	
				    					<div className="col-xs-6">
				    						<input type="text" className="address_input add_address_input" onChange={(e) => setAddr1(e.target.value)} name="addr1" placeholder="Apartment/House Number" value={addr1} autoComplete="nope"/>
							    			<input type="text" className="address_input add_address_input" onChange={(e) => setAddr2(e.target.value)} name="addr2" placeholder="Locality, Street Address" value={addr2} autoComplete="nope"/>
							    			<input type="text" className="address_input add_address_input" onChange={(e) => setCity(e.target.value)} name="city" placeholder="City" value={city} autoComplete="nope"/>
							    			<input type="number" className="address_input add_address_input" onChange={(e) => setPin(e.target.value)} name="pin" placeholder="ZIP Code" value={pin} autoComplete="nope"/>
							    			<input type="text" className="address_input add_address_input" onChange={(e) => setState(e.target.value)} name="state" placeholder="State" value={state} autoComplete="nope"/>
							    			<input type="text" className="address_input add_address_input" name="country" placeholder="Country" value={country} readonly />
							    		</div>
							    		<div className="clearfix"></div>
					    				<div className="col-xs-6 col-sm-6">
					    					<center><button className="save_address_button" onClick={SaveAddress}>Save This Address</button></center>
										</div>
                        		</div>
                        		:
                        		<div>
                        			<div className="col-sm-9"></div>
			                        	<div className="col-sm-3">
			                        		<button className='btn address_right_button add_new_address' onClick={AddAdrressOption}>&#160; &#160;<i className="fa fa-plus" aria-hidden="true"> </i>&#160; Add New Address&#160;&#160;</button>
			                        		<br/>
			                        	</div>
			                        	<div className="clearfix"></div>
			                        	{address_array.map((item, i) => (
							    			<div className="col-xs-4 each_select_address_mob" key={i} >
												<div className="cart_quiz_card_area address_box_shadow">
													<input className="quiz_checkbox" type="radio" name="address_selected" id={"address_"+item.id} checked/>
													<div className="">
														<div className="quiz_card_content">
															<div className="quiz_card_title_cart_mob">
																<p className="address_user_name">{item.name}'s Address</p>
																<div className="address_text_section">
																	<p>{item.phone} | {email}</p>
																	<p>{item.shipping_address+", "+item.ship_city+", "+item.ship_state+", "+item.ship_code}</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
							    		))}
                        		</div>
                        	}
                        	
                        </div>

                        <div className="clearfix"></div>

                        <div className="col-sm-3">
                            <Lottie options={defaultOptions} width={300} style={styles.checkAnimation}/>
                        </div>

                        <div className="clearfix"></div>

                        <div className="account_logout_div">
                            <p className="account_logout" onClick={ClickLogout}>{logout}</p>
                        </div>
                    </div>
    			</div>
    	);
    }
};
export default Address;


