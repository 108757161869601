import React, { Component } from "react";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import Footer from '../Common/Footer';
import swal from 'sweetalert';
import { Redirect } from 'react-router';
import $ from 'jquery';
import ExchangeHeader from "./ExchangeHeader";
import {isMobile} from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';

class Exchange extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	token: localStorage.getItem("token"),
	    	product_id: '',
	    	order_id: '',
	    	hashed_id: '',
	    	product_details: {delivery_address:{},products:{}, power: {}},
	    	reasons: [],
	    	show_exchange_page: false,
	    	exchange_proceed_button: 'exchange_proceed_button_disable',
	    	show_exchange_product_list: false,
	    	exchange_product_list: [],
	    	exchange_product_count: '',
	    	color_border_trending: 'color_border_trending',
	    	exchange_power_enabled: 'exchange_power_enabled',
	    	exchange_power_disabled: 'exchange_power_disabled',
	    	primary_reason_id: '',
	    	secondary_reason_id: '',
	    	choosen_power_type: 'Single Vision',
	    	ex_product_id: '',
	    	confirm_exchange_product: false,
	    	exchange_product_details: '',
	    	width: window.innerWidth,
	    	redirectTo: '/login',
	    	redirect: false,
	    	cyl_values1: '-',
			cyl_values2: '-',
			sph_values1: '-',
			sph_values2: '-',
			show_product_image: false,
			current_page: '', 
			total_product: '', 
			per_page: '', 
  			last_page_url: '',
  			next_page_url: '',
  			last_page: '',
	    }
	    this.handleCheckBoxChange  = this.handleCheckBoxChange.bind(this);
	    this.handleSubCheckBoxChange  = this.handleSubCheckBoxChange.bind(this);
	    this.handleProceedExchange = this.handleProceedExchange.bind(this);
	    this.handleProductCheckbox = this.handleProductCheckbox.bind(this);
	    this.handleSelectPower = this.handleSelectPower.bind(this);
	    this.exchangeProduct = this.exchangeProduct.bind(this);
	    this.handleConfirmExchange = this.handleConfirmExchange.bind(this);
	    this.changeExchangeProduct = this.changeExchangeProduct.bind(this);
	    this.availablePower = this.availablePower.bind(this);
	    this.moreImages = this.moreImages.bind(this);
	    this.closeMoreImages = this.closeMoreImages.bind(this);
	    this.handlePrimaryInput = this.handlePrimaryInput.bind(this);
	}
	componentDidMount()
	{
		if(this.state.token)
		{
			const product_id = this.props.match.params.product_id;
			const order_id = this.props.match.params.order_id;
			
			this.GetOrderDetails(order_id,product_id);
			this.GetExchangeReason();
			this.GetProductDetails();
		}
		else
		{
			this.setState({ redirectTo: '/login', redirect: true})
		}
    }
    GetOrderDetails(order_id,product_id)
    {
    	var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.state.token);

        let url = process.env.REACT_APP_API_URL+'/v1/orders/'+order_id+'/product/'+product_id;

        var requestOptions = {
            headers: myHeaders,
        };

        fetch(url, requestOptions)
        .then(response => response.json())
        
        .then(data => {
            this.setState({order_id: order_id, product_id: product_id, product_details: data.data, products: data.data.products, hashed_id: data.data.hashed_id, show_exchange_page: true}) 
        })
    }
    GetExchangeReason()
    {
		var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.state.token);
        var requestOptions = {
            headers: myHeaders,
        };

    	let url_reason = process.env.REACT_APP_API_URL+'/v1_1/return/reasons';

        fetch(url_reason, requestOptions)
        .then(response => response.json())
        
        .then(data => {
        	if(data.result)
        	{	
        		this.setState({reasons: data.result},
            	() => 
  				{ 
	  				//this.CheckBoxChange();	
  				});
        	}
            
        })
    }
    GetProductDetails()
    {
    	let url_product = process.env.REACT_APP_API_URL+'/v2/products?type=old-listing&per_page=10';
        fetch(url_product)
            .then(response => response.json())
            .then(data => {
                if(data.result)
                {
                    this.setState({ exchange_product_list: data.result.products , 
                    				exchange_product_count: data.result.product_count,
                    				current_page: data.result.current_page, 
                    				total_product: data.result.total, 
                    				per_page: data.result.per_page, 
						  			last_page_url: data.result.last_page_url,
						  			next_page_url: data.result.next_page_url,
                    			},
                    				() => {
								    	this.SetLastPage(data.result)
									}
                    			);
                }
        	})
    }
    SetLastPage(items)
  	{
  		console.log(items);
		let urlString = items.last_page_url;
        let paramString = urlString.split('?')[1];
        let params_arr = paramString.split('&');
        var last_page = '';
        for(let i = 0; i < params_arr.length; i++) {
            let pair = params_arr[i].split('=');
            if(pair[0] == 'page')
            {
            	last_page = pair[1];
            }
        }
        this.setState({
  			last_page: last_page
  		});
  	}
	CheckBoxChange()
	{
		
		$('.fes-category-checklist > li > input[name^="download_category"]').change(function() {

			$(this)
		    .closest('ul')
		    .find('input[name^="download_category"]')
		    .not(this)
		    .prop('checked', false);
		});
		$("#slider_bottom_body").each(function() {
		  	this.scrollTop = this.scrollHeight;

			if ($(this).scrollTop() > 0) {
			    $('#slide_header_fixed').addClass("nav-hide");
			} else {
			    $('#slide_header_fixed').addClass("nav-normal");
			}
		});

	}
	handleCheckBoxChange()
	{
		$('.fes-category-checklist > li > input[name^="download_category"]').change(function() {
			$(this)
		    .closest('ul')
		    .find('input[name^="download_category"]')
		    .not(this)
		    .prop('checked', false);
		});
	}
	handlePrimaryInput()
	{
		this.setState({exchange_proceed_button: 'exchange_proceed_button_disable'});
	}
	handleSubCheckBoxChange()
	{
		var primary_value = $('.exchange_checkbox:checked').val();
		var secondary_value = $('.exchange_subcheckbox:checked').val();
		this.setState({primary_reason_id: primary_value, secondary_reason_id: secondary_value});
		this.setState({exchange_proceed_button: 'exchange_proceed_button'});
	}
	handleProceedExchange()
	{
		if(this.state.exchange_proceed_button == 'exchange_proceed_button_disable')
		{
			swal("Select Reason before proceeding");
		}
		else
		{
			this.setState({show_exchange_product_list: true});
		}
	}
	handleProductCheckbox()
	{
		$('input.exchange_input').on('change', function() {
    		$('input.exchange_input').not(this).prop('checked', false);  
		});
	}
	handleSelectPower(power_type)
	{
		if(this.state.exchange_power_enabled == 'exchange_power_enabled')
		{
			this.setState({exchange_power_enabled: 'exchange_power_disabled', exchange_power_disabled: 'exchange_power_enabled'});
		}
		else
		{
			this.setState({exchange_power_enabled: 'exchange_power_enabled', exchange_power_disabled: 'exchange_power_disabled'});
		}
		this.setState({choosen_power_type: power_type });
	}
	ChangeColor(item_url,color_id,i)
    {   
        var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id;
        let product_details_new = this.state.exchange_product_list;
        fetch(url)
        .then(response => response.json())
        .then(products => {
            console.log(products);
            let product_details_new = this.state.exchange_product_list;
            product_details_new[i].model_images = products.result.products.model_images;
            product_details_new[i].glass_images = products.result.products.glass_images;
            product_details_new[i].specifications.color_prod_id = products.result.products.specifications.color_prod_id;
            product_details_new[i].sold_out = products.result.products.sold_out;
            this.setState({exchange_product_list: product_details_new})
        });  
    }
    exchangeProduct(item_url,color_id)
    {
    	this.setState({ex_product_id: color_id, confirm_exchange_product: true, show_exchange_product_list: false},
    		() => {
    			var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id;
		        fetch(url)
		        .then(response => response.json())
		        .then(products => {
		        	console.log(products);
		            this.setState({exchange_product_details: products.result.products, ex_product_id: color_id})
		        });  
    		})
    	
    }
    changeExchangeProduct()
    {
    	this.setState({show_exchange_product_list: true, confirm_exchange_product: false, ex_product_id: ''})
    }
    moreImages(item_url,color_id)
    {
    	var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id;
        fetch(url)
        .then(response => response.json())
        .then(products => {
            this.setState({exchange_product_details: products.result.products}, () => {
            	this.setState({
		            show_product_image: !this.state.show_product_image,
		            image_type: 'frame',
		        });
            })
        }); 
		
    }
    closeMoreImages()
    {
    	this.setState({
            show_product_image: !this.state.show_product_image,
            image_type: 'frame',
        });
    }
    handleConfirmExchange()
    {
    	if(isMobile)
  		{
  			var device = "mobile";
  		}
  		else
  		{
  			var device = "desktop";
  		}
    	const product_id = this.props.match.params.product_id;
		const order_id = this.props.match.params.order_id;
		const hashed_id = this.state.hashed_id;
		var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.state.token);
        myHeaders.append("User-Source", device);
        myHeaders.append("Content-Type", "application/json");

        var data = JSON.stringify({primary_reason_id: this.state.primary_reason_id, secondary_reason_id: this.state.secondary_reason_id, ex_item_id: this.state.ex_product_id, power_type: this.state.choosen_power_type});
                
		var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: data,
                redirect: 'follow'
            };
        let url = process.env.REACT_APP_API_URL+'/v1_1/return/order/'+hashed_id+'/product/'+product_id;
        fetch(url, {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(
                (res) => {
                	if(res.success)
                	{
                		swal("Exchange Order Placed. Order ID: "+res.data.order_id);
                	}
                	else
                	{
                		swal(res.error.message);
                	}
                });
    }
    availablePower(color_id)
	{
		var span = document.createElement("span");
        span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><center><div class='loader'></div></center></div>";
		swal({
		    content: span,
		    buttons: false
		});
		let url_power = process.env.REACT_APP_API_URL+'/v1/product/'+color_id+'/power';
		fetch(url_power)
        .then(response => response.json())
        
        .then(data => {
        	console.log(data);
        	if(data.success == true)
        	{
        		var cyl_values1 = data.data.cyl_values[0];
        		var cyl_values2 = data.data.cyl_values[data.data.cyl_values.length-1];
        		var sph_values1 = data.data.sph_values[0];
        		var sph_values2 = data.data.sph_values[data.data.sph_values.length-1];
        		this.setState({cyl_values1: cyl_values1, cyl_values2: cyl_values2, sph_values1: sph_values1, sph_values2: sph_values2}, () => {
					span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><b>This frame supports lenses of a specific power range.</b> <br/> Check your power availability below: <table style='margin-left: auto;margin-right: auto;margin-top: 20px;'><tr style='background-color: #EBEBEB;'><th style='padding: 10px 30px;'>SPH</th><th style='padding: 10px 30px;'>"+this.state.sph_values1+" to "+this.state.sph_values2+"</th></tr><tr style='background-color: #F6F6F6;'><th style='padding: 10px 30px;'>CYL</th><th style='padding: 10px 30px;'>"+this.state.cyl_values1+" to "+this.state.cyl_values2+"</th></tr></table><center><img src='/images/Header/LogoOnPower.svg' style='margin-top: 25px; width: 25px;' /></center></div>";
					swal({
					    content: span,
					});	
				});
        	}
        	else
        	{
        		//swal("Power details not fetched for this product")
        	}
		});
	}
    componentWillMount() 
  	{
	  	window.addEventListener('resize', this.handleWindowSizeChange); 
	}
	componentWillUnmount() 
	{
	  	window.removeEventListener('resize', this.handleWindowSizeChange);
	}
    handleWindowSizeChange = () => {
	
	  this.setState({ width: window.innerWidth });
	};
	fetchMoreData = () => {
		var current_page = this.state.current_page;
		var total_product = this.state.total_product;
		var total_pages = this.state.total_pages;
		var per_page = this.state.per_page;
		var next_page = current_page+1;
		var last_page = this.state.last_page
		console.log("LAST PAGE "+last_page);
		if(next_page <= last_page)
		{
			var url = process.env.REACT_APP_API_URL+"/v2/products?type=old-listing&page="+next_page+"&per_page="+per_page; 
			fetch(url)
	        .then(response => response.json())
	        .then(products => {
	        	console.log(products);
	        	this.setState({ exchange_product_list: this.state.exchange_product_list.concat(products.result.products), current_page: products.result.current_page });
	        });
		}
		
    
  	};
    render() {
		console.log(this.state.reasons);
		console.log(this.state.exchange_product_list);
		const { width } = this.state;
  		const isMobile = width <= 768;
  		if(this.state.redirect){
            return <Redirect to={this.state.redirectTo} />
        }
  		if (isMobile) 
 		{
			return (
				<div>
					{this.state.show_exchange_page ?
						<div>
							<ExchangeHeader order_id={this.state.order_id} product_id={this.state.product_id}/>
							{!this.state.show_exchange_product_list ?
								<div className="show_reason_section">
									<div id="slide_header_fixed"></div>
									<header id="slider_top_body">
										<div className="exchange_top_section">
											<div className="col-xs-4">
												<p className="exchange_header_order_details">Order ID</p>
												<p>{this.state.product_details.hashed_id}</p>
											</div>
											<div className="col-xs-4">
												<p className="exchange_header_order_details">Purchased Via</p>
												<p>{this.state.product_details.payment_method}</p>
											</div>
											<div className="col-xs-4">
												<p className="exchange_header_order_details">Amount Paid</p>
												<p>{this.state.product_details.total_cost}</p>
											</div>
											<div className="col-xs-12">
												<div className="col-xs-12 exchange_product_details_section">
													<div className="col-xs-5">
														<img src={this.state.product_details.products.image} className="img-responsive"/>
													</div>
													<div className="col-xs-7 exchange_product_name_section">
														<p className="product_details_full_name_new">{this.state.product_details.products.name}</p>
														<p className="product_details_new_size">{this.state.product_details.products.gender} | {this.state.product_details.products.size}</p>
														{this.state.product_details.products.power_type == 'single_vision' ?
															<p className="product_details_new_size">SINGLE VISION</p>
															:
															<p className="product_details_new_size">ZERO POWER</p>
														}
													</div>
												</div>
											</div>
										</div>
									</header>
									<section id="slider_bottom_body">
										{!this.state.confirm_exchange_product ?
											<div className="exchange_body_section">
												<div className="col-xs-12 product_details_horizontal_line_up"><center><img src="/images/Header/SlideUpLine.png" /></center></div>
												<div className="col-xs-12"><p className="exchange_reason_text">Why do you want to return the order?</p></div>
												{this.state.reasons.reasons ?
													<div className="col-xs-12">
														<ul class="fes-category-checklist" onClick={this.handleCheckBoxChange}>
													  		{this.state.reasons.reasons.map((item, index) => (
																<li id={"download_category-"+item.id}>
																    <input value={item.id} type="checkbox" name="download_category[]" id={"in-download_category-"+item.id} className="exchange_checkbox" onChange={this.handlePrimaryInput}/>
																	    <label for={"in-download_category-"+item.id} class="selectit_primary"> {item.reason}</label>

																    <ul class="children" onClick={this.handleSubCheckBoxChange}>
																      	{item.secondaryReasons.map((second_item, second_index) => (
																    		<li id={"download_category-"+second_item.id}><input value={second_item.id} type="radio" name="download_category[]" id={"in-download_category-"+second_item.id} className="exchange_subcheckbox"/><label class="selectit_secondary"> {second_item.reason}</label></li>
																		))}
																    </ul>
															  	</li>
															))}
													  	</ul>
													</div>
													:
													null
												}
											</div>
											:
											<div className="exchange_body_section">
												<div className="col-xs-12 product_details_horizontal_line_up"><center><img src="/images/Header/SlideUpLine.png" /></center></div>
												<div className="col-xs-12"><p className="exchange_reason_text">Confirm your exchange product</p></div>
												<div className="col-xs-12">
													<div className="col-xs-12 exchange_product_details_section">
														{this.state.exchange_product_details ?
															<center>
																<img src={this.state.exchange_product_details.glass_images[0].url} className="exchange_product_img img-responsive"/>
																<p className="product_details_full_name_new">{this.state.exchange_product_details.short_name}</p>
																<p className="product_details_new_size exchange_product_gender">{this.state.exchange_product_details.gender} | {this.state.exchange_product_details.specifications.frame_size}</p>
																<span className="colour_dot exchange_color_dot" style={{backgroundColor: this.state.exchange_product_details.colors[0].hex_code}}></span>
																<p className="product_details_new_size">{this.state.choosen_power_type}</p>
															</center>
															:
															null
														}
													</div>
													<div className="col-xs-12 padding_is_zero">
														<p className="change_exchange_product_text" onClick={this.changeExchangeProduct}>CHANGE</p>
														<center><p className="exchange_pres_text">You can enter prescription after purchasing this glass</p></center>
													</div>
												</div>
											</div>
										}
										
									</section>
									{!this.state.confirm_exchange_product ?
										<div className="modal-footer-product-details-new modal_exchange_footer">
											<div className="col-xs-12 padding_is_zero"></div>
											<div className="col-xs-6">
											</div>
									    	<div className="col-xs-6">
									    		<button className={this.state.exchange_proceed_button} onClick={this.handleProceedExchange}>Procced</button>
									    	</div>
									    </div>
									    :
									    <div className="modal-footer-product-details-new modal_exchange_confirm_footer">
											<div className="col-xs-12 padding_is_zero"></div>
											<div className="col-xs-4">
												<img src="/images/Header/BackArrowPurple.svg" className="img-responsive exchange_back_button_footer" onClick={this.changeExchangeProduct}/>
											</div>
									    	<div className="col-xs-8">
									    		<button className="confirm_exchange_button" onClick={this.handleConfirmExchange}>CONFIRM EXCHANGE</button>
									    	</div>
									    </div>
									}
							    </div>
							    :
							    <div>
							    {!this.state.show_product_image ?
							    	<div className="exchange_product_list_section">
								    	<div className="col-xs-12">
							    			<p className="exchange_reason_text">Select the eyewear you want in exchange</p>
							    		</div>
							    		<InfiniteScroll
								          	dataLength={this.state.current_page * this.state.per_page}
								          	next={this.fetchMoreData}
								          	hasMore={true}
								        >
							    		{this.state.exchange_product_count > 0 ?
							    			<div>
							    				{this.state.exchange_product_list.map((item, i) => (
										    		<div className=" container-fluid no_margin exchange_content">
														<div className="col-xs-12 each_exchange_product_section padding_is_zero" onChange={this.handleProductCheckbox}>
															<div className="">
																<input type="checkbox" id={"title"+i} className="exchange_input"/>
																<label for={"title"+i} className="exchange_product_details">
																	<center>
																		<img src={item.glass_images[0].url} className="exchange_product_img img-responsive"/>
																		<img className="exchange_expand_icon" src="/images/Header/Expand_Thumbnail_icon_ListingPage.svg" alt="expand_icon_web" onClick={() => this.moreImages(item.prod_url,item.specifications.color_prod_id)} /><p className="exchange_expand_text" onClick={() => this.moreImages(item.prod_url,item.specifications.color_prod_id)}>Tap to expand</p>
																		<p className="product_details_full_name_new">{item.short_name}</p>
																		<p className="product_details_new_size">{item.gender} | {item.specifications.frame_size}</p>
																	</center>
																</label>

																<div className="exchange_content_options">
																	<center>
																		<div className="col-xs-12">
																			<img src="/images/Header/PowersIcon.svg" className="exchange_rx_img" onClick={() => this.availablePower(item.specifications.color_prod_id)}/>
																			<p className="exchange_rx_text" onClick={() => this.availablePower(item.specifications.color_prod_id)}>Available Powers</p>
																		</div>
																		<div className="col-xs-12 exchange_color_section">
																			<center><p className="exchange_reason_text">Select the eyewear colour</p></center>
																			<div className="colour_section_trending">
																				{item.colors.map((color, index) => (
													                                <div>
													                                    {item.specifications.color_prod_id === color.id?
													                                    <div>
													                                        {color.sold_out == 1 ?
													                                            <div className="transparent_colour_border_trending"><img src="/images/Header/red_striked_circle.svg" className="color_striked_img_exchange" onClick={() => this.ChangeColor(item.prod_url,color.id, i)}/><span className="colour_dot_product_details" id={color.basic_prod_id+"_"+color.id} key={index} style={{backgroundColor: color.hex_code}}></span></div>
													                                            :
													                                            <div className={this.state.color_border_trending}><span className="colour_dot_trending_homepage" id={color.basic_prod_id+"_"+color.id} key={index} style={{backgroundColor: color.hex_code}} onClick={() => this.ChangeColor(item.prod_url,color.id, i)}></span></div>
													                                        }
													                                    </div>
													                                    :
													                                    <div>
													                                        {color.sold_out == 1 ?
													                                            <div className="transparent_colour_border_trending"><img src="/images/Header/white_striked_circle.svg" className="color_striked_img_exchange" onClick={() => this.ChangeColor(item.prod_url,color.id, i)}/><span className="colour_dot_product_details" key={index} style={{backgroundColor: color.hex_code}}></span></div>
													                                            :
													                                            <div className="transparent_colour_border_trending"><span className="colour_dot_trending_homepage" key={index} style={{backgroundColor: color.hex_code}} onClick={() => this.ChangeColor(item.prod_url,color.id, i)}></span></div>
													                                        }
													                                    </div>
													                                    }
													                                </div>
													                            ))}
												                            </div>
											                            </div>

											                            <br className="clear_both"/>

											                            <div className="col-xs-12 padding_is_zero">
											                            	<center><p className="exchange_reason_text">Select the lens package</p></center>
																			<div className="col-xs-6">
																				<button className={this.state.exchange_power_enabled} onClick={() => this.handleSelectPower("Single Vision")}>SINGLE VISION</button>
																			</div>
																			<div className="col-xs-6">
																				<button className={this.state.exchange_power_disabled} onClick={() => this.handleSelectPower("Zero Power")}>ZERO POWER</button>
																			</div>
																		</div>

																		<br className="clear_both"/>

																		<div className="col-xs-12 exchange_button_section">
																			
																			<div className="col-xs-3"></div>
																			<div className="col-xs-6">
																				{item.sold_out == 0?
																					<button className="exchange_button" onClick={() => this.exchangeProduct(item.prod_url,item.specifications.color_prod_id)}>EXCHANGE</button>
																					:
																					<button className="exchange_sold_button">SOLD OUT</button>
																				}
																			</div>
																			<div className="col-xs-3"></div>
																		</div>

																		<br className="clear_both"/>

																	</center>
																</div>
															</div>
															
														</div>
													</div>
												))}
									    	</div>
									    	:
									    	null
									    }
								    	</InfiniteScroll>
								    </div>
							    :
							    	<div className="col-sm-12 exchange_product_list_section">
										<div className="col-sm-12">
											<button type="button" class="close exchange_close_button" onClick={this.closeMoreImages}>&times;</button>
										</div>
										<div className="col-sm-12 more_image_section">
											<center>
												<div>
													{this.state.exchange_product_details.glass_images.map((image_item, index) => (
														<img src={image_item.url} className="img-responsive show_more_image_img" id="frame_img" name="frame_img" alt="frame_img"/>
													))}
													{this.state.exchange_product_details.model_images.map((image_item, index) => (
														<img src={image_item.url} className="img-responsive show_more_image_img" id="frame_img" name="frame_img" alt="frame_img"/>
													))}
													
												</div>
											</center>
										</div>
									</div>
							    }
							    
							    </div>
							    }
							</div>
						:
						<div>
							<Header color={"#160D08"} />
							<Loading />
							<Footer />
						</div>
					}
				</div>
			);
		}
		else
		{
			return (
				<div>
					{this.state.show_exchange_page ?
						<div>
							<Header color={"#160D08"}/> 
							<div className="col-xs-12 exchange_web">
								<div className="col-sm-12 exchange_header_web">
									<img src="/images/Header/ExchangeOrderIcon.svg" /><p className="exchange_header_text_web">Exchange Order</p>
								</div>
								<div className="col-sm-12">
									<div className="col-sm-4">
										<div className="">
											<div className="col-xs-4">
												<p className="exchange_header_order_details">Order ID</p>
												<p>{this.state.product_details.hashed_id}</p>
											</div>
											<div className="col-xs-4">
												<p className="exchange_header_order_details">Purchased Via</p>
												<p>{this.state.product_details.payment_method}</p>
											</div>
											<div className="col-xs-4">
												<p className="exchange_header_order_details">Amount Paid</p>
												<p>{this.state.product_details.total_cost}</p>
											</div>
											<div className="col-xs-12">
												<div className="col-xs-12 exchange_product_details_section">
													<div className="col-xs-5">
														<img src={this.state.product_details.products.image} className="img-responsive"/>
													</div>
													<div className="col-xs-7 exchange_product_name_section">
														<p className="product_details_full_name_new">{this.state.product_details.products.name}</p>
														<p className="product_details_new_size">{this.state.product_details.products.gender} | {this.state.product_details.products.size}</p>
														{this.state.product_details.products.power_type == 'single_vision' ?
															<p className="product_details_new_size">SINGLE VISION</p>
															:
															<p className="product_details_new_size">ZERO POWER</p>
														}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-8 exchange_section_right">
										{!this.state.show_exchange_product_list ?
											<div>
												<div>
													{!this.state.confirm_exchange_product ?
														<div className="exchange_body_section">
															<div className="col-xs-12"><p className="exchange_reason_text">Why do you want to return the order?</p></div>
															{this.state.reasons.reasons ?
																<div className="col-xs-12">
																	<ul class="fes-category-checklist" onClick={this.handleCheckBoxChange}>
																  		{this.state.reasons.reasons.map((item, index) => (
																			<li id={"download_category-"+item.id}>
																			    <input value={item.id} type="checkbox" name="download_category[]" id={"in-download_category-"+item.id} className="exchange_checkbox" onChange={this.handlePrimaryInput}/>
																				    <label for={"in-download_category-"+item.id} class="selectit_primary"> {item.reason}</label>

																			    <ul class="children" onClick={this.handleSubCheckBoxChange}>
																			      	{item.secondaryReasons.map((second_item, second_index) => (
																			    		<li id={"download_category-"+second_item.id}><input value={second_item.id} type="radio" name="download_category[]" id={"in-download_category-"+second_item.id} className="exchange_subcheckbox"/><label class="selectit_secondary"> {second_item.reason}</label></li>
																					))}
																			    </ul>
																		  	</li>
																		))}
																  	</ul>
																</div>
																:
																null
															}
														</div>
														:
														<div className="exchange_body_section">
															<div className="col-xs-12"><p className="exchange_reason_text">Confirm your exchange product</p></div>
															<div className="col-xs-12">
																<div className="col-xs-6 exchange_product_details_section">
																	{this.state.exchange_product_details ?
																		<center>
																			<img src={this.state.exchange_product_details.glass_images[0].url} className="exchange_product_img img-responsive"/>
																			<p className="product_details_full_name_new">{this.state.exchange_product_details.short_name}</p>
																			<p className="product_details_new_size exchange_product_gender">{this.state.exchange_product_details.gender} | {this.state.exchange_product_details.specifications.frame_size}</p>
																			<span className="colour_dot exchange_color_dot" style={{backgroundColor: this.state.exchange_product_details.colors[0].hex_code}}></span>
																			<p className="product_details_new_size">{this.state.choosen_power_type}</p>
																		</center>
																		:
																		null
																	}
																</div>
																<div className="col-xs-6"></div>
																<div className="clearfix"></div>
																<div className="col-xs-6 padding_is_zero">
																	<p className="change_exchange_product_text" onClick={this.changeExchangeProduct}>CHANGE</p>
																	<center><p className="exchange_pres_text">You can enter prescription after purchasing this glass</p></center>
																</div>
																<div className="col-xs-6"></div>
															</div>
														</div>
													}
												</div>
												<div>
													{!this.state.confirm_exchange_product ?
														<div className="modal_exchange_footer">
															<div className="col-xs-12 padding_is_zero"></div>
															<div className="col-xs-9"></div>
															<div className="col-xs-3">
													    		<button className={this.state.exchange_proceed_button} onClick={this.handleProceedExchange}>Procced</button>
													    	</div>
													    </div>
													    :
													    <div className="modal_exchange_confirm_footer">
															<div className="col-xs-12 padding_is_zero"></div>
															<div className="col-xs-4">
																<img src="/images/Header/BackArrowPurple.svg" className="img-responsive exchange_back_button_footer" onClick={this.changeExchangeProduct}/>
															</div>
															<div className="col-xs-5"></div>
													    	<div className="col-xs-3">
													    		<button className="confirm_exchange_button" onClick={this.handleConfirmExchange}>CONFIRM EXCHANGE</button>
													    	</div>
													    </div>
													}
												</div>
											</div>
											:
											<div>
										    	<div className="col-sm-12">
									    			<p className="exchange_reason_text">Select the eyewear you want in exchange</p>
									    		</div>
									    		<InfiniteScroll
										          	dataLength={this.state.current_page * this.state.per_page}
										          	next={this.fetchMoreData}
										          	hasMore={true}
										        >
										    		{this.state.exchange_product_count > 0 ?
										    			<div>
										    				{this.state.exchange_product_list.map((item, i) => (
													    		<div className=" container-fluid no_margin exchange_content">
													    			<div className="col-sm-6 each_exchange_product_section padding_is_zero" onChange={this.handleProductCheckbox}>
																		<div className="">
																			<input type="checkbox" id={"title"+i} className="exchange_input"/>
																			<label for={"title"+i} className="exchange_product_details">
																				<center>
																					<img src={item.glass_images[0].url} className="exchange_product_img img-responsive"/>
																					<p className="product_details_full_name_new">{item.short_name}</p>
																					<p className="product_details_new_size">{item.gender} | {item.specifications.frame_size}</p>
																				</center>
																			</label>

																			<div className="exchange_content_options">
																				<center>
																					<div className="col-xs-12">
																						<img src="/images/Header/PowersIcon.svg" className="exchange_rx_img" onClick={() => this.availablePower(item.specifications.color_prod_id)}/>
																						<p className="exchange_rx_text" onClick={() => this.availablePower(item.specifications.color_prod_id)}>Available Powers</p>
																					</div>
																					<div className="col-xs-12 exchange_color_section">
																						<center><p className="exchange_reason_text">Select the eyewear colour</p></center>
																						<div className="colour_section_trending">
																							{item.colors.map((color, index) => (
																                                <div>
																                                    {item.specifications.color_prod_id === color.id?
																                                    <div>
																                                        {color.sold_out == 1 ?
																                                            <div className="transparent_colour_border_trending"><img src="/images/Header/red_striked_circle.svg" className="color_striked_img_exchange" onClick={() => this.ChangeColor(item.prod_url,color.id, i)}/><span className="colour_dot_product_details" id={color.basic_prod_id+"_"+color.id} key={index} style={{backgroundColor: color.hex_code}}></span></div>
																                                            :
																                                            <div className={this.state.color_border_trending}><span className="colour_dot_trending_homepage" id={color.basic_prod_id+"_"+color.id} key={index} style={{backgroundColor: color.hex_code}} onClick={() => this.ChangeColor(item.prod_url,color.id, i)}></span></div>
																                                        }
																                                    </div>
																                                    :
																                                    <div>
																                                        {color.sold_out == 1 ?
																                                            <div className="transparent_colour_border_trending"><img src="/images/Header/white_striked_circle.svg" className="color_striked_img_exchange" onClick={() => this.ChangeColor(item.prod_url,color.id, i)}/><span className="colour_dot_product_details" key={index} style={{backgroundColor: color.hex_code}}></span></div>
																                                            :
																                                            <div className="transparent_colour_border_trending"><span className="colour_dot_trending_homepage" key={index} style={{backgroundColor: color.hex_code}} onClick={() => this.ChangeColor(item.prod_url,color.id, i)}></span></div>
																                                        }
																                                    </div>
																                                    }
																                                </div>
																                            ))}
															                            </div>
														                            </div>

														                            <br className="clear_both"/>

														                            <div className="col-xs-12 padding_is_zero">
														                            	<center><p className="exchange_reason_text">Select the lens package</p></center>
																						<div className="col-xs-6">
																							<button className={this.state.exchange_power_enabled} onClick={() => this.handleSelectPower("Single Vision")}>SINGLE VISION</button>
																						</div>
																						<div className="col-xs-6">
																							<button className={this.state.exchange_power_disabled} onClick={() => this.handleSelectPower("Zero Power")}>ZERO POWER</button>
																						</div>
																					</div>

																					<br className="clear_both"/>

																					<div className="col-xs-12 exchange_button_section">
																						
																						<div className="col-xs-3"></div>
																						<div className="col-xs-6">
																							{item.sold_out == 0?
																								<button className="exchange_button" onClick={() => this.exchangeProduct(item.prod_url,item.specifications.color_prod_id)}>EXCHANGE</button>
																								:
																								<button className="exchange_sold_button">SOLD OUT</button>
																							}
																						</div>
																						<div className="col-xs-3"></div>
																					</div>

																					<br className="clear_both"/>

																				</center>
																			</div>
																		</div>
																	</div>
																	<div className="col-sm-6"></div>
																</div>
															))}
												    	</div>
												    	:
												    	null
												    }
											    </InfiniteScroll>
									    	</div>
										}
									</div>
								</div>
							</div>
						</div>
						:
						<div>
							<Header color={"#160D08"} />
							<Loading />
							<Footer />
						</div>
					}
				</div>
			);
		}
	}
}


export default Exchange;
