import React, { Component } from "react";
import { Link } from 'react-router-dom';

class CheckoutItemDetails extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	 
	    } 
	}
	componentDidMount()
	{
		
  	}
  	render() {
  		var saved_credit_text = this.props.cart_details.billing.saved_credit/700 > 1 ? "Credits" : "Credit";
		var saved_credit_text_capital = this.props.cart_details.billing.saved_credit/700 > 1 ? "CREDITS" : "CREDIT"; 
  		return (
  			<div>

				<br/>
				{this.props.credits_in_wallet > 0 ?
					<div>
						<div className="col-xs-12 show_free_product_section"></div>
    					<div class="show_free_product">
    						<div className="col-xs-12 checkout_text_vertical_line_section">
    							<p className="checkout_credit_main_text">Your saved credits from previous <b>“Get 3 Eyewear for Rs. 700 each”</b> deal</p>
    							{/*<p className="checkout_premium_lense_text">OJ Premium Lenses FREE with all products!</p>*/}
    						</div>
	    					{this.props.credit_product_array.map((each_item, index) => (
	    						<div>
	    						{each_item.sold_out == 1 ?
	    							<div className="each_section_sold_out" key={index}>
		    							<div className="col-xs-3 checkout_vertical_line_section">
				    						<center><img src={each_item.image} className="img-responsive cart_product_image each_item_section_sold_out"/></center>
				    						<p className="checkout_sold_out_text">SOLD OUT</p>
					    				</div>
					    				<div className="col-xs-5 padding_is_zero each_item_section_sold_out">
					    					<p className="checkout_brand_name">{each_item.name}</p>
					    					<div className="checkout_color_size_text">
					    						<span className="colour_dot" style={{backgroundColor: each_item.color}}></span>
					    						{each_item.prescription_type == 'sunglasses' ?
					    							<p className="checkout_prescription_text"> Zero Power</p>
					    							:
													<p className="checkout_prescription_text"> {each_item.prescription_type}</p>
												}
												{each_item.prescription_type == 'Blu Cut Single Vision' || each_item.prescription_type == 'Zero Power'?
	                                                <img class="bluecut_icon_in_blue" src='/images/Header/BluCut_B_Icon.svg'></img>
	                                                :
	                                                null
	                                            }
	                                            <br/>
	                                        </div>
											<p  className="checkout_qty_text"> X {each_item.qty}</p>
					    				</div>
					    				<div className="col-xs-4 checkout_each_amount each_item_section_sold_out">
					    					<span className="checkout_cost_display"><p className="product_striked_cost"><s>₹ {each_item.striked_cost}</s></p><p className="checkout_free_text">₹ 0</p></span>
					    					<br/>
					    					<span className="checkout_cost_display"><p className="checkout_free_text">₹ {each_item.lens_cost}</p></span>
					    					<p className="checkout_use_credit_text">{each_item.qty > 1 ? each_item.qty+" OJ SUPER Credits Used" : each_item.qty+" OJ SUPER Credit Used" }</p>
					    					{this.props.device == "mob" ?
					    						<div className="product_qty_area">
					    							<i className="fa fa-minus checkout_qty_icon" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
					    							<input type="text" value={each_item.qty} className="qty_input" disabled/>
					    							<i className="fa fa-plus checkout_qty_icon" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
					    						</div>
					    						:
					    						<div className="checkout_product_qty_area">
					    							<i className="fa fa-minus checkout_qty_add_sub" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
					    							<input type="text" value={each_item.qty} className="qty_input" disabled/>
					    							<i className="fa fa-plus checkout_qty_add_sub" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
					    						</div>
					    					}
					    				</div>
					    				<div className="clearfix"></div>
		    						</div>
	    						:
		    						<div className="each_item_section" key={index}>
		    							<div className="col-xs-3 checkout_vertical_line_section">
				    						<center><img src={each_item.image} className="img-responsive cart_product_image"/></center>
					    				</div>
					    				<div className="col-xs-5 padding_is_zero">
					    					<p className="checkout_brand_name">{each_item.name}</p>
					    					<div className="checkout_color_size_text">
					    						<span className="colour_dot" style={{backgroundColor: each_item.color}}></span>
												{each_item.prescription_type == 'sunglasses' ?
					    							<p className="checkout_prescription_text"> Zero Power</p>
					    							:
													<p className="checkout_prescription_text"> {each_item.prescription_type}</p>
												}
												{each_item.prescription_type == 'Blu Cut Single Vision' || each_item.prescription_type == 'Zero Power'?
	                                                <img class="bluecut_icon_in_blue" src='/images/Header/BluCut_B_Icon.svg'></img>
	                                                :
	                                                null
	                                            }
	                                            <br/>
	                                        </div>
	                                        <p className="checkout_qty_text"> X {each_item.qty}</p>
					    				</div>
					    				<div className="col-xs-4 checkout_each_amount">
					    					<span className="checkout_cost_display"><p className="product_striked_cost"><s>₹ {each_item.striked_cost}</s></p><p className="checkout_free_text">₹ 0</p></span>
					    					<br/>
					    					<span className="checkout_cost_display"><p className="checkout_free_text">₹ {each_item.lens_cost}</p></span>
					    					<p className="checkout_use_credit_text">{each_item.qty > 1 ? each_item.qty+" OJ SUPER Credits Used" : each_item.qty+" OJ SUPER Credit Used" }</p>
					    					{this.props.device == "mob" ?
					    						<div className="product_qty_area">
					    							<i className="fa fa-minus checkout_qty_icon" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
					    							<input type="text" value={each_item.qty} className="qty_input" disabled/>
					    							<i className="fa fa-plus checkout_qty_icon" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
					    						</div>
					    						:
					    						<div className="checkout_product_qty_area">
					    							<i className="fa fa-minus checkout_qty_add_sub" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
					    							<input type="text" value={each_item.qty} className="qty_input" disabled/>
					    							<i className="fa fa-plus checkout_qty_add_sub" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
					    						</div>
					    					}
					    				</div>
					    				<div className="clearfix"></div>
		    						</div>
	    						}
	    						</div>
	    					))}
    					</div>
    					<div className="clearfix"></div>
					</div>
					:
					null
				}
				{this.props.non_credit_product_array.length > 0 ?
					<div className="show_cost_product">
						<div className="col-xs-12 checkout_text_vertical_line_section">
							{this.props.cart_details.cart_list.map((each_item, index) => (
								<div className="" key={index}>
									{ each_item.type == "subscription" && each_item.subscription_id == 2?
										<div>
											<p className="checkout_credit_main_text">Your new "<b className="checkout_oj_saver_text">OJ SUPER</b> - <b dangerouslySetInnerHTML={{__html: each_item.name }}></b>" Deal </p>
											{/*<p className="checkout_premium_lense_text">OJ Premium Lenses FREE with all products!</p>*/}
										</div>
										:
										null
 									}
 									{ each_item.type == "subscription" && each_item.subscription_id == 1?
											<div>
 											<p className="checkout_credit_main_text">Your new "<b dangerouslySetInnerHTML={{__html: each_item.name }}></b>" Deal </p>
 											{/*<p className="checkout_premium_lense_text">FREE OJ Premium Lenses with all products!</p>*/}
 										</div>
 										:
 										null
 									}
								</div>
							))}
						</div>
    					{this.props.non_credit_product_array.map((each_item, index) => (
    						<div>
    						{each_item.sold_out == 1 ?
    							<div className="each_section_sold_out" key={index}>
	    							<div className="col-xs-3 checkout_vertical_line_section">
			    						<center><img src={each_item.image} className="img-responsive cart_product_image each_item_section_sold_out"/></center>
			    						<p className="checkout_sold_out_text">SOLD OUT</p>
				    				</div>
				    				<div className="col-xs-5 padding_is_zero each_item_section_sold_out">
				    					<p className="checkout_brand_name">{each_item.name}</p>
				    					<div className="checkout_color_size_text">
				    						<span className="colour_dot" style={{backgroundColor: each_item.color}}></span>
												{each_item.prescription_type == 'sunglasses' ?
					    							<p className="checkout_prescription_text"> Zero Power</p>
					    							:
													<p className="checkout_prescription_text"> {each_item.prescription_type}</p>
												}
												{each_item.prescription_type == 'Blu Cut Single Vision' || each_item.prescription_type == 'Zero Power'?
	                                                <img class="bluecut_icon_in_blue" src='/images/Header/BluCut_B_Icon.svg'></img>
	                                                :
	                                                null
	                                            }
	                                            <br/>
	     								</div>
	     								<p className="checkout_qty_text"> X {each_item.qty}</p>
				    				</div>
				    				<div className="col-xs-4 checkout_each_amount each_item_section_sold_out">
				    					<span className="checkout_cost_display"><p className="product_striked_cost"><s>₹ {each_item.striked_cost}</s></p><p className="checkout_each_product_amount">₹ {each_item.cost}</p></span>
				    					<br/>
				    					<span className="checkout_cost_display"><p className="checkout_free_text">₹ {each_item.lens_cost}</p></span>
				    					{this.props.cart_details.cart_list.map((each_item_sub, index) => (
				    						<div key={index}>
				    							{each_item_sub.type == "subscription" && each_item_sub.subscription_id == 2 ?
				    							<p class="checkout_use_credit_text">{each_item.qty > 1 ? each_item.qty+" OJ SUPER Credits Used" : each_item.qty+" OJ SUPER Credit Used" }</p>
				    							:
				    							null
				    							}
				    						</div>
				    					))}
				    					{this.props.device == "mob" ?
				    						<div className="product_qty_area">
				    							<i className="fa fa-minus checkout_qty_icon" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
				    							<input type="text" value={each_item.qty} className="qty_input" disabled/>
				    							<i className="fa fa-plus checkout_qty_icon" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
				    						</div>
				    						:
				    						<div className="checkout_product_qty_area">
				    							<i className="fa fa-minus checkout_qty_add_sub" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
				    							<input type="text" value={each_item.qty} className="qty_input" disabled/>
				    							<i className="fa fa-plus checkout_qty_add_sub" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
				    						</div>
				    					}
				    				</div>
				    				<div className="clearfix"></div>
				    			</div>
    							:
	    						<div className="each_item_section" key={index}>
	    							<div className="col-xs-3 checkout_vertical_line_section">
			    						<center><img src={each_item.image} className="img-responsive cart_product_image"/></center>
				    				</div>
				    				<div className="col-xs-5 padding_is_zero">
				    					<p className="checkout_brand_name">{each_item.name}</p>
				    					<div className="checkout_color_size_text">
				    						<span className="colour_dot" style={{backgroundColor: each_item.color}}></span>
												{each_item.prescription_type == 'sunglasses' ?
					    							<p className="checkout_prescription_text"> Zero Power</p>
					    							:
													<p className="checkout_prescription_text"> {each_item.prescription_type}</p>
												}
												{each_item.prescription_type == 'Blu Cut Single Vision' || each_item.prescription_type == 'Zero Power'?
	                                                <img class="bluecut_icon_in_blue" src='/images/Header/BluCut_B_Icon.svg'></img>
	                                                :
	                                                null
	                                            }
	                                            <br/>
	                                            
											
				    					</div>
				    					<p className="checkout_qty_text"> X {each_item.qty}</p>
				    				</div>
				    				<div className="col-xs-4 checkout_each_amount">
				    					<span className="checkout_cost_display"><p className="product_striked_cost"><s>₹ {each_item.striked_cost}</s></p><p className="checkout_each_product_amount">₹ {each_item.cost}</p></span>
				    					<br/>
				    					<span className="checkout_cost_display"><p className="checkout_free_text">₹ {each_item.lens_cost}</p></span>
				    					{this.props.cart_details.cart_list.map((each_item_sub, index) => (
				    						<div key={index}>
				    							{each_item_sub.type == "subscription" && each_item_sub.subscription_id == 2 ?
				    							<p class="checkout_use_credit_text">{each_item.qty > 1 ? each_item.qty+" OJ SUPER Credits Used" : each_item.qty+" OJ SUPER Credit Used" }</p>
				    							:
				    							null
				    							}
				    						</div>
				    					))}
				    					{this.props.device == "mob" ?
				    						<div className="product_qty_area">
				    							<i className="fa fa-minus checkout_qty_icon" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
				    							<input type="text" value={each_item.qty} className="qty_input" disabled/>
				    							<i className="fa fa-plus checkout_qty_icon" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
				    						</div>
				    						:
				    						<div className="checkout_product_qty_area">
				    							<i className="fa fa-minus checkout_qty_add_sub" aria-hidden="true" onClick={() => this.props.sub_qty(each_item.product_id)}></i>
				    							<input type="text" value={each_item.qty} className="qty_input" disabled/>
				    							<i className="fa fa-plus checkout_qty_add_sub" aria-hidden="true" onClick={() => this.props.add_qty(each_item.product_id)}></i>
				    						</div>
				    					}
				    				</div>
				    				<div className="clearfix"></div>
				    			</div>
			    			}
			    			</div>
    					))}
					</div>
					:
					null
				}
				{this.props.cart_details.cart_list.map((each_item, index) => (
					<div key={index}>
						{each_item.type == "subscription" && each_item.subscription_id == 2 && this.props.cart_details.billing.saved_credit > 0?
							<div className="each_item_section" key={index}>
	 							<div className="col-xs-3 checkout_vertical_line_section_super_ojos">
			 						<center><img src='/images/Header/OJ SUPER.png' className="img-responsive checkout_super_ojos_img"/></center>
				 				</div>
				 				<div className="col-xs-7 padding_is_zero">
				 					<p className="checkout_super_oj_credit_heading">x {this.props.cart_details.billing.saved_credit/700} Eyeglasses {saved_credit_text} saved for later</p>
				 					<div className="checkout_super_oj_credit_desc">
				 						{this.props.cart_details.billing.saved_credit/700} BUY LATER {saved_credit_text_capital} will be added to your account. Use these credits to shop eyeglasses anytime in next 1 year for FREE.
				 					</div>
				 				</div>
				 				<div className="col-xs-2 checkout_each_amount">
				 					<p className="checkout_reamining_credits_right">+ {this.props.cart_details.billing.saved_credit/700}</p>
				 					<span className="checkout_cost_display"><p className="checkout_buy_later_credits_text">Buy Later {saved_credit_text} </p></span>
				 				</div>
				 				<div className="clearfix"></div>
				 			</div>
							:
							null
						}
					</div>
				:
					null
				))}
				<div className="col-xs-12 checkout_edit_button_div">
					{this.props.device == "web" ?
						<button className="btn checkout_add_address_continue" onClick={this.props.open_address_accordion}>Add Address To Continue</button>
						:
						null
					}
				</div>
				
			</div>
		);
  	}
}
export default CheckoutItemDetails;
