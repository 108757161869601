import React, { Component } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Redirect } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_visible: false,
      background_color: this.props.color,
      token: "",
      name: "",
      show_logout_text: true,
      cart_count: 0,
      logout: "Logout",
      path_source: "",
      track_source: Cookies.get("track_source"),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      show_launch_timer: 1,
      seasonal_header: "seasonal_header_text",
      header_div_seasonal: "",
      dropdown_visible: "dropdown-container-nav",
      dropdown_visible_ar: "dropdown-container-nav",
      device: "",
    };
    this.ClickNav = this.ClickNav.bind(this);
    this.ClickLogout = this.ClickLogout.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.ShowNavDropdown = this.ShowNavDropdown.bind(this);
    this.ShowNavDropdownAR = this.ShowNavDropdownAR.bind(this);
  }

  ClickNav() {
    this.setState({ menu_visible: !this.state.menu_visible });
  }

  ClickLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    this.setState({
      logout: "Logging Off...",
    });
    setTimeout(() => {
      this.setState({ token: "", logout: "Logout", name: "" });
    }, 2000);
  }
  componentDidMount() {
    let token = localStorage.getItem("token");
    if (token) {
      this.setState(
        {
          token: token,
        },
        () => {
          this.UpdateToken(this.state.token);
        }
      );
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    this.CartCount();
    this.SetUtm();
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    let today = new Date(),
      dd = String(today.getDate()).padStart(2, "0"),
      mm = String(today.getMonth() + 1).padStart(2, "0"),
      yyyy = today.getFullYear(),
      dayMonth = "08/12/",
      launch = dayMonth + yyyy + " 17:00:00";

    today = mm + "/" + dd + "/" + yyyy;
    if (today > launch) {
      launch = dayMonth + yyyy + " 17:00:00";
    }

    const countDown = new Date(launch).getTime();
    setInterval(() => {
      const now = new Date().getTime(),
        distance = countDown - now;
      this.setState({
        days: Math.floor(distance / day),
        hours: Math.floor((distance % day) / hour),
        minutes: Math.floor((distance % hour) / minute),
        seconds: Math.floor((distance % minute) / second),
      });
    }, 0);
    if (
      window.location.pathname == "/explore-ojos" ||
      window.location.pathname == "/products/eyeglasses/fifa" ||
      window.location.pathname == "/products/eyeglasses/featured" ||
      window.location.pathname == "/cart" ||
      window.location.pathname == "/products/eyeglasses/trending" ||
      window.location.pathname == "/products/sunglasses" ||
      window.location.pathname == "/ar/products/eyeglasses/male" ||
      window.location.pathname == "/ar/products/eyeglasses/female" || 
      window.location.pathname.indexOf("collection") > -1
    ) {
      this.setState({ show_launch_timer: 0 });
    }
    if (window.location.pathname == "/products/eyeglasses/featured") {
      this.setState({
        seasonal_header: "seasonal_header_highlighted",
        header_div_seasonal: "header_div_seasonal",
      });
    }

    /*Mobile dropdown Header */
    /*var dropdown = document.getElementsByClassName("dropdown-btn-nav");
        var i;

        for (i = 0; i < dropdown.length; i++) {
          dropdown[i].addEventListener("click", function() {
            console.log("clicked");
            this.classList.toggle("active");
            var dropdownContent = this.nextElementSibling;
            if (dropdownContent.style.display === "block") {
              dropdownContent.style.display = "none";
            } else {
              dropdownContent.style.display = "block";
            }
          });
        }*/

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        this.setState({ device : "Windows Phone"});
    }

    if (/android/i.test(userAgent)) {
        this.setState({ device : "Android"});
        
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.setState({ device : "iOS"});
    }

    return "unknown";
  }
  ShowNavDropdown() {
    if (this.state.dropdown_visible == "dropdown-container-nav-show") {
      this.setState({ dropdown_visible: "dropdown-container-nav" });
    } else {
      this.setState({ dropdown_visible: "dropdown-container-nav-show" });
    }
  }
  ShowNavDropdownAR() {
    if (this.state.dropdown_visible_ar == "dropdown-container-nav-show") {
      this.setState({ dropdown_visible_ar: "dropdown-container-nav" });
    } else {
      this.setState({ dropdown_visible_ar: "dropdown-container-nav-show" });
    }
  }

  CartCount() {
    let prod_count = localStorage.getItem("prod_counts");
    if (prod_count) {
      var total_qty = 0;
      let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
      for (var i = 0; i <= product_id_array.length - 1; i++) {
        total_qty =
          total_qty +
          JSON.parse(localStorage.getItem("prod_" + product_id_array[i])).qty;
      }
      this.setState({
        cart_count: total_qty,
      });
    }
  }
  SetUtm() {
    if (window.location.pathname == "/products/eyeglasses/female") {
      var gender = window.location.pathname.substring(21, 27);
      this.setState({ path_source: gender + "_listing" });
    }
    if (window.location.pathname === "/products/eyeglasses/male") {
      var gender = window.location.pathname.substring(21, 25);
      this.setState({ path_source: gender + "_listing" });
    }
    if (
      window.location.href.indexOf("path_source") > -1 &&
      window.location.pathname != "/cart"
    ) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == "path_source") {
          this.setState({ path_source: pair[1] });
        }
      }
    }
    if (window.location.href.indexOf("utm_source") > -1) {
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var track_source = urlParams.get("utm_source");
      if (
        this.state.track_source != null &&
        track_source != this.state.track_source
      ) {
        //localStorage.setItem('track_source', track_source);
        Cookies.set("track_source", track_source, { expires: 2 });
      } else {
        //localStorage.setItem('track_source', track_source);
        Cookies.set("track_source", track_source, { expires: 2 });
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    /*Hiding the nav menu, if clicked outside */
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ menu_visible: false });
    }
  }
  UpdateToken(token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.state.token);
    var requestOptions = {
      headers: myHeaders,
    };
    fetch(process.env.REACT_APP_API_URL + "/v1/user", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.msg == "Token mismatch") {
          this.setState({ name: "User" });
        } else {
          this.setState({ name: data.result.name });
        }
      });
  }
  render() {
    var name = this.state.name != null ? this.state.name : "User";
    return (
      <div className="header_div">
        
        <div className={this.state.show_launch_timer ? "header_div_web hidden-xs" : "header_div_web1 hidden-xs"}>
          <div
            className="header_section_web hidden-xs"
            style={{ backgroundColor: this.props.color }}
          >
            <nav className="navbar">
              <div className="">
                <div className="navbar-header ojos_title_web">
                  <a className="navbar-brand ojos_header" href="/">
                    <img
                      src="/images/Header/NewHeaderLogo.png"
                      className="img-responsive"
                    />
                  </a>
                </div>
                
                {/* <ul className="header_left_section">
                  <div class="dropdown header_left_section_text header-dropdown">
                  <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Eyeglasses <img src="/images/Header/Dropdownarrow.svg" />
                    </a>
                  
                  <div class="dropdown-menu header_dropdown_div" aria-labelledby="dropdownMenuButton">
                  <div className="header_dropdown_menu_div">
                    <a class="dropdown-item desktop_nav_icon_sub" href="/products/eyeglasses/male?path_source=male_listing">Shop Men’s</a>
                  </div>
                  <div className="header_dropdown_menu_div">
                    <a class="dropdown-item desktop_nav_icon_sub" href="/products/eyeglasses/female?path_source=female_listing">Shop Women's</a>
                  </div>
                  
                  
                  </div>
                </div>
                  
                  <li className="header_left_section_text header-dropdown dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      AR Try On <img src="/images/Header/Dropdownarrow.svg" />
                    </a>
                    <div
                      className="header_dropdown_div dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <div className="header_dropdown_menu_div">
                        <a
                          href="/ar/products/eyeglasses/male?path_source=male_listing"
                          className="dropdown-item desktop_nav_icon_sub"
                        >
                          Shop Men’s
                        </a>
                      </div>
                      <div className="header_dropdown_menu_div">
                        <a
                          href="/ar/products/eyeglasses/female?path_source=female_listing"
                          className="dropdown-item desktop_nav_icon_sub"
                        >
                          Shop Women's
                        </a>
                      </div>

                    </div>
                  </li>
                  <li className="header_left_section_text">
                    <a href="/products/sunglasses">Sunglasses</a>
                  </li>
                  <li className="header_left_section_text">
                    <a
                      href="/products/eyeglasses/featured"
                      className={
                        this.state.seasonal_header + " desktop_nav_icon"
                      }
                    >
                      <img
                        src="/images/Header/NewTag.svg"
                        className="header_new_tag_img"
                      />
                      New Arrivals
                    </a>
                  </li>
                </ul> */}
                <ul className="navbar-right header_right_section">
                  {/* <li className="header_web_icon">
                    <a href="/order" className="desktop_nav_icon">
                      <center>
                        <img
                          src="/images/Header/Icon awesome-box.svg"
                          className=""
                          alt="order"
                        />
                        <p>Orders</p>
                      </center>
                    </a>
                  </li> */}
                  {this.state.path_source == "" ? (
                    <li className="header_web_icon">
                      <Link
                        to={{
                          pathname: "/checkout",
                          state: { prevPath: window.location.pathname },
                        }}
                        className="desktop_nav_icon"
                      >
                        {/* <center>
                          <img
                            src="/images/Header/CartIconWhite.svg"
                            className=""
                            alt="cart"
                          />
                          <p>Cart</p>
                        </center> */}
                      </Link>
                      {/* {this.props.cart_count == undefined ? (
                        <div>
                          {this.state.cart_count > 0 ? (
                            <p className="cart_product_count_desktop">
                              {this.state.cart_count}
                            </p>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          {this.props.cart_count > 0 ? (
                            <p className="cart_product_count_desktop">
                              {this.props.cart_count}
                            </p>
                          ) : null}
                        </div>
                      )} */}
                    </li>
                  ) : (
                    <li className="header_web_icon">
                      <Link
                        to={{
                          pathname: "/checkout",
                          search: "?path_source=" + this.state.path_source,
                          state: { prevPath: window.location.pathname },
                        }}
                        className="desktop_nav_icon"
                      >
                        {/* <center>
                          <img
                            src="/images/Header/CartIconWhite.svg"
                            className=""
                            alt="cart"
                          />
                          <p>Cart</p>
                        </center> */}
                      </Link>
                      {this.props.cart_count == undefined ? (
                        <div>
                          {this.state.cart_count > 0 ? (
                            <div>
                              <Link
                                to={{
                                  pathname: "/checkout",
                                  search:
                                    "?path_source=" + this.state.path_source,
                                  state: { prevPath: window.location.pathname },
                                }}
                              >
                                {/* <p className="cart_product_count_desktop">
                                  {this.state.cart_count}
                                </p> */}
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          {this.props.cart_count > 0 ? (
                            <div>
                              <Link
                                to={{
                                  pathname: "/checkout",
                                  search:
                                    "?path_source=" + this.state.path_source,
                                  state: { prevPath: window.location.pathname },
                                }}
                              >
                                {/* <p className="cart_product_count_desktop">
                                  {this.props.cart_count}
                                </p> */}
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </li>
                  )}
                  {this.state.token != "" ? (
                    <li className="header_web_icon">
                      <a
                        href="/account"
                        className="desktop_nav_icon"
                        onClick={this.ClickNav}
                      >
                        <center>
                          {/* <img
                            src="/images/Header/MyProfileIcon.svg"
                            className=""
                            alt="profile"
                          />
                          <p>Profile</p> */}
                        </center>
                      </a>
                    </li>
                  ) : (
                    <li className="header_web_icon">
                      <a
                        href="/login"
                        className="desktop_nav_icon"
                        onClick={this.ClickNav}
                      >
                        <center>
                          {/* <img
                            src="/images/Header/MyProfileIcon.svg"
                            className=""
                            alt="profile"
                          />
                          <p>Sign In</p> */}
                        </center>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </nav>
          </div>
          
          
        </div>
        
        <div
          className={
            this.state.show_launch_timer
              ? "header_div_mob hidden-sm hidden-lg hidden-md"
              : "hidden-sm hidden-lg hidden-md"
          }
          ref={this.wrapperRef}
        >
            
          <div
            className={this.state.show_launch_timer ? "header_section_with_top_section" : "header_section"}
            style={{ backgroundColor: this.props.color }}
          >
            <div className="col-sm-6 col-xs-7">
              {/* <span className="hamburger" onClick={this.ClickNav}>
                <img src="/images/Header/MenuIcon.png" />
              </span> */}
              <span className="ojos_title">
                <a href="/">
                  <img
                    src="/images/Header/NewHeaderLogo.png"
                    className="img-responsive"
                  />
                </a>
              </span>
            </div>
            {/* <div className="col-sm-6 col-xs-5 header_right_icon">
              {this.state.token != "" ? (
                <Link to="/account">
                  <img
                    src="/images/Header/MyProfileIcon.svg"
                    className="header_icon"
                    alt="profile"
                  />
                </Link>
              ) : (
                <Link to="/login">
                  <img
                    src="/images/Header/MyProfileIcon.svg"
                    className="header_icon"
                    alt="profile"
                  />
                </Link>
              )}
              {this.state.path_source == "" ? (
                <Link
                  to={{
                    pathname: "/checkout",
                    state: { prevPath: window.location.pathname },
                  }}
                >
                  <img
                    src="/images/Header/CartIconWhite.svg"
                    className="header_icon"
                    alt="cart"
                  />
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: "/checkout",
                    search: "?path_source=" + this.state.path_source,
                    state: { prevPath: window.location.pathname },
                  }}
                >
                  <img
                    src="/images/Header/CartIconWhite.svg"
                    className="header_icon"
                    alt="cart"
                  />
                </Link>
              )}
              {this.props.cart_count == undefined ? (
                <div>
                  {this.state.cart_count > 0 ? (
                    <div>
                      {this.state.path_source == "" ? (
                        <Link
                          to={{
                            pathname: "/checkout",
                            state: { prevPath: window.location.pathname },
                          }}
                        >
                          <p className="cart_product_count">
                            {this.state.cart_count}
                          </p>
                        </Link>
                      ) : (
                        <Link
                          to={{
                            pathname: "/checkout",
                            search: "?path_source=" + this.state.path_source,
                            state: { prevPath: window.location.pathname },
                          }}
                        >
                          <p className="cart_product_count">
                            {this.state.cart_count}
                          </p>
                        </Link>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div>
                  {this.props.cart_count > 0 ? (
                    <div>
                      {this.state.path_source == "" ? (
                        <Link
                          to={{
                            pathname: "/checkout",
                            state: { prevPath: window.location.pathname },
                          }}
                        >
                          <p className="cart_product_count">
                            {this.state.cart_count}
                          </p>
                        </Link>
                      ) : (
                        <Link
                          to={{
                            pathname: "/checkout",
                            search: "?path_source=" + this.state.path_source,
                            state: { prevPath: window.location.pathname },
                          }}
                        >
                          <p className="cart_product_count">
                            {this.state.cart_count}
                          </p>
                        </Link>
                      )}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div className="clearfix"></div> */}
          </div>
          {this.state.show_launch_timer
            ? null
            : 
              null}
          
          <div id="mySidenav" className={"sidenav" + this.state.menu_visible}>
            <a className="close_nav" onClick={() => this.ClickNav()}>
              <img
                src="/images/Header/Arrow.svg"
                className="nav_icon arrow_reverse"
                alt="arrow"
              />
            </a>
            {/* <p>Explore OJOS Wear</p>

            <a class="dropdown-btn-nav" onClick={() => this.ShowNavDropdown()}>
              Eyeglasses
              <img
                src="/images/Header/dropdown_arrow.svg"
                className="dropdown_arrow_mob"
              />
            </a>
            <div className={this.state.dropdown_visible}>
              <a href="/products/eyeglasses/male?path_source=male_listing">
                Shop Men's
              </a>
              <a href="/products/eyeglasses/female?path_source=female_listing">
                Shop Women's
              </a>
            </div>
            {this.state.device != "iOS" ?
              <div>
                <a class="dropdown-btn-nav" onClick={() => this.ShowNavDropdownAR()}>
                  AR Try On
                  <img
                    src="/images/Header/dropdown_arrow.svg"
                    className="dropdown_arrow_mob"
                  />
                </a>
                <div className={this.state.dropdown_visible_ar}>
                  <a href="/ar/products/eyeglasses/male?path_source=male_listing">
                    Shop Men's
                  </a>
                  <a href="/ar/products/eyeglasses/female?path_source=female_listing">
                    Shop Women's
                  </a>
                </div>
              </div>
              :
              null
            }
            

            <a href="/products/sunglasses">Sunglasses </a>
            <a
              href="/products/eyeglasses/featured"
              className={this.state.seasonal_header}
            >
              New Arrivals
              <img
                src="/images/Header/NewTag.svg"
                className="header_new_tag_img_mob"
              />
            </a>

            <br />
            <p>Hi, {name}</p>
            {this.state.token != "" ? (
              <a href="/account" onClick={this.ClickNav}>
                <img
                  src="/images/Header/Account.svg"
                  className="nav_icon"
                  alt="account"
                />
                Account
              </a>
            ) : (
              <a href="/login" onClick={this.ClickNav}>
                <img
                  src="/images/Header/Account.svg"
                  className="nav_icon"
                  alt="account"
                />
                Sign In
              </a>
            )}
            {this.state.path_source == "" ? (
              <a href="/checkout">
                <img
                  src="/images/Header/Cart.svg"
                  className="nav_icon"
                  alt="cart"
                />
                Cart
              </a>
            ) : (
              <a href={"/checkout?path_source=" + this.state.path_source}>
                <img
                  src="/images/Header/Cart.svg"
                  className="nav_icon"
                  alt="cart"
                />
                Cart
              </a>
            )}
            <a href="/order" onClick={this.ClickNav}>
              <img
                src="/images/Header/Orders.svg"
                className="nav_icon"
                alt="order"
              />
              Orders
            </a>
            <a href="/credit" onClick={this.ClickNav}>
              <img
                src="/images/Header/Fav.svg"
                className="nav_icon"
                alt="wishlist"
              />
              Credit
            </a>
            
            <a href="/about-us" onClick={this.ClickNav}>
              <img
                src="/images/Header/AboutUs.svg"
                className="nav_icon"
                alt="About us"
              />
              About Us
            </a>
            <a href="/privacy-policy" onClick={this.ClickNav}>
              <img
                src="/images/Header/PrivacyPolicy.svg"
                className="nav_icon"
                alt="Privacy Policy"
              />
              Privacy Policy
            </a>
            <a href="/faq" onClick={this.ClickNav}>
              <img
                src="/images/Header/faq.png"
                className="nav_icon"
                alt="FAQ"
              />
              FAQ
            </a>
            <a href="/contact-us" onClick={this.ClickNav}>
              <img
                src="/images/Header/Contact_Us.svg"
                className="nav_icon"
                alt="help"
              />
              Contact Us
            </a>
            <a href="/terms-and-conditions" onClick={this.ClickNav}>
              <img
                src="/images/Header/Terms&Conditions.svg"
                className="nav_icon"
                alt="nav_icon"
              />
              Terms & Conditions
            </a>
            {this.state.token != "" ? (
              <a className="logout_nav" onClick={this.ClickLogout}>
                <img
                  src="/images/Header/Logout.svg"
                  className="nav_icon"
                  alt="logout"
                />
                <p className="logout_nav_text">{this.state.logout}</p>
              </a>
            ) : null} */}
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
