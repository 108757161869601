import React, { Component } from "react";

class CheckoutCoupon extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	cart_details: this.props.cart_details,
	    	show_tax: false,
	    	show_tax_chevron: 'fa-chevron-down',
	    	credits_in_wallet: this.props.credits_in_wallet,
	    }
	    this.ShowCoupon = this.ShowCoupon.bind(this);
	    this.DiscardCoupon = this.DiscardCoupon.bind(this);
	}
	ShowCoupon()
	{
		this.props.show_coupon_section();
	}
	DiscardCoupon()
	{
		this.props.discard_coupon_section();
	}
	render() {
		return (
			<div>
				<div className="col-xs-12 bill_details padding_is_zero">
					{this.props.coupon_id == '' ?
						<div className="col-xs-12 coupon_heading" onClick={this.ShowCoupon}>
							<img src="/images/Header/discount-coupon-icon.svg" />
							<div>
								<p className="apply_coupon_text">APPLY COUPON</p>
								{/*<p className="checkout_buy_more_text">“Buy More, Save More” coupon available</p>*/}
							</div>
							<i className="fa fa-angle-right apply-coupon-arrow" aria-hidden="true"></i>

						</div>
						:
						<div className="col-xs-12 coupon_heading_choosen" >
							<img src="/images/Header/discount-coupon-icon.svg" />
							<div><p className="apply_coupon_text">CODE APPLIED</p>
							{/*{this.props.coupon_details.map((item, i) => (
								<div>
									{this.props.coupon_id == item.id ?
										<div className="show_applied_coupon_details">
											<p className="applied_coupon_code_text">{item.title}</p>
												<div>
													<p className="applied_coupon_dis_text">Rs. {item.discount_upto} off</p>
												</div>
											<p className="applied_coupon_text" onClick={this.ShowCoupon}>CHANGE</p>
										</div>
										:
										null
									}

								</div>
							))}*/}
							<div className="show_applied_coupon_details">
								<p className="applied_coupon_code_text">{this.props.coupon_name}</p>
									<div>
										<p className="applied_coupon_dis_text">Rs. {this.props.coupon_discount_upto} off</p>
									</div>
								<p className="applied_coupon_text" onClick={this.ShowCoupon}>CHANGE</p>
							</div>
							</div>
							<img src="/images/Header/CrossIcon.svg" className="applied_coupon_crossed" onClick={this.DiscardCoupon}/>
						</div>
					}
					
				</div>
			</div>
    	);
		
		
	}
}

export default CheckoutCoupon;