import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

class InstagramFeed extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            instagram_feed: [],
        };
    }
	componentDidMount()
    {
        
    }
	render() {
		return (
			<div>
		    {this.props.source == "mob" ?
		    	<div className="col-sm-12 padding_is_zero">
                    <div className="col-xs-8 padding_is_zero">
                        <h1 className="homepage_follow_us_header">follow us on</h1>
                        <h1 className="homepage_follow_instagram">INSTAGRAM</h1>
                    </div>
                    <div className="col-xs-4 padding_is_zero">
                        <img src="/images/Header/Icon awesome-instagram.svg" className="homepage_insta_icon"/>
                    </div>
                    <div className="clearfix"></div>
                    <ul className="homepage_insta_feed_ul">
                        <li>Be a part of the OJOS Community</li>
                        <li>Stay updated on latest offers and new collections</li>
                        <li>Take part in contests and win OJOS freebies</li>
                        <li>Essential eyecare tips</li>
                    </ul>
			    	<section className="carousel corousel_about_us" aria-label="Gallery">
		                <ol className="carousel__viewport">
		                    {this.props.instagram_feed.map((item, index) => (
		                        <li className={index == 0 ? "carousel__slide__homepage carousel__slide corousel_homepage_insta_half" : "carousel__slide__homepage carousel__slide corousel_homepage_insta_half"}>
		                            <div className="carousel__snapper about_us_carousel_snapper">
		                                {item.media_type == "VIDEO" ?
		                                    <a href={item.permalink} target="_blank">
		                                    <video width='100%' height='auto' poster={item.thumbnail_url} controls playsInline><source src={item.media_url} type="video/mp4" /></video>
		                                    </a>
		                                    :
		                                    <a href={item.permalink} target="_blank"><img src={item.media_url} width='100%' height='auto'/></a>
		                                }
		                            </div>
		                        </li>
		                    ))}
		                </ol>
		            </section>
		            <center><a href="https://www.instagram.com/ojoswear/" target="_blank"><button className="homepage_insta_check_button_mob">Check It Out</button></a></center>
	            </div>
		    :
		    	<div className="col-sm-12 ">
                    <div className="col-xs-8 ">
                        <h1 className="homepage_follow_us_header extra_bold_text">follow us on</h1>
                        <h1 className="homepage_download_app_subtext">INSTAGRAM</h1>
                    </div>
                    <div className="col-xs-4 ">
                        <img src="/images/Header/Icon awesome-instagram.svg" className="homepage_insta_icon_web"/>
                    </div>
                    <div className="clearfix"></div>
                    <ul className="homepage_insta_feed_ul_web">
                        <li>Be a part of the OJOS Community</li>
                        <li>Stay updated on latest offers and new collections</li>
                    </ul>
                    <ul className="homepage_insta_feed_ul_web">
                    	<li>Take part in contests and win OJOS freebies</li>
                        <li>Essential eyecare tips</li>
                    </ul>
                    <div className="clearfix"></div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8">
                        <div className="gallery">
							{this.props.instagram_feed.map((item, index) => (
								<div className="gallery-item" tabindex="0">
									{item.media_type == "VIDEO" ?
										<a href={item.permalink} target="_blank">
										<video width='100%' height='auto' poster={item.thumbnail_url} controls playsInline><source src={item.media_url} type="video/mp4" /></video>
										</a>
									:
										<a href={item.permalink} target="_blank"><img src={item.media_url} className="gallery-image" alt="instagram-feed" /></a>
									}
								</div>
							))}
						</div>
                    </div>
                    <div className="col-sm-2"></div>
                    <div className="clearfix"></div>
                    <center><a href="https://www.instagram.com/ojoswear/" target="_blank"><button className="homepage_insta_check_button_mob">Check It Out</button></a></center>
                </div>
			}
		    </div>
		);
	}
}

export default InstagramFeed;