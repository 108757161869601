import React, { Component } from "react";

class MeasurementSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="col-xs-12">
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Frame Size
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.frame_size}
            </p>
          </div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Frame Shape
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.frame_shape}
            </p>
          </div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Frame Type
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.frame_type}
            </p>
          </div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Frame Colour
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.frame_colour}
            </p>
          </div>
          <div className="clearfix"></div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Dimensions
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.frame_dimensions}
            </p>
          </div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Material
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.frame_material}
            </p>
          </div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Frame Style
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.frame_style}
            </p>
          </div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Frame Width
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.frame_width}
            </p>
          </div>
          <div className="clearfix"></div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Height
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.height}
            </p>
          </div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Temple Colour
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.temple_colour}
            </p>
          </div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Temple Material
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.temple_material}
            </p>
          </div>
          <div className="col-xs-3 details_section">
            <p
              className="details_header"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              Weight
            </p>
            <p
              className="details_small_text"
              style={this.props.source === "web" ? { fontSize: "18px" } : null}
            >
              {this.props.specifications.weight}
            </p>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default MeasurementSection;
