import React, { Component } from "react";
import SelectAddress from "../Common/AddAddress/SelectAddress";
import AddNewAddress from "../Common/AddAddress/AddNewAddress";

class CheckoutFooter extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	showAddress: false,
	    	user_data: this.props.user_data,
	    	save_add_address: false,
	    	address_id: '2',

	    }
	    this.AddAddress = this.AddAddress.bind(this);
	    this.CloseAddress = this.CloseAddress.bind(this);
	    this.onConfirm = this.onConfirm.bind(this);
	    this.ShowAddAddress = this.ShowAddAddress.bind(this);
	}
	componentDidMount()
	{
		
	}
	componentWillUnmount() {
    	
	}
	CloseAddress(){
		this.setState({
	      showAddress: false,
	    });
	}
	onConfirm(country,addr1,addr2,city,state,pin){
		var header = new Headers();
		var data = JSON.stringify({address : {type : 'shipping', shipping_address : addr1+","+addr2, ship_code : pin,
        ship_city : city,
        ship_state : state,
        phone : ""}});
		console.log(data);
		let url = process.env.REACT_APP_API_URL+'/v1/user/basic';
		console.log(url);
	    fetch(url, {
                method: 'POST',
                headers: header,
                body: data
            })
            .then(response => response.json())
            .then(
                (data) => {
                    this.setState({ address_id: 1 })
                    this.props.update_address_id(this.state.address_id);
                });

	}
	AddAddress() {
	    this.setState({
	      showAddress: true,
	      save_add_address: false,
	    });
	}
	ShowAddAddress()
	{
		this.setState({ save_add_address: true })
	}
	render() {
		return (
					<div>
						<div className="col-xs-12 checkout_footer">
				    		<center><button className="add_address_button" onClick={this.AddAddress}>Add Address To Continue</button></center>
				    	</div>
				    	{this.state.showAddress ?
				    		<div className="address_section">
					    		<SelectAddress close_address={this.CloseAddress} user_data={this.state.user_data} show_add_address={this.ShowAddAddress} save_add_address={this.state.save_add_address} on_confirm={this.onConfirm}/> 
			    			</div>
		    				:
		    				null
				    	}
				    </div>
		    	
	    );
		
		
	}
}

export default CheckoutFooter;
