import React, { Component } from 'react';

class ProductDescription extends Component 
{    
    constructor(props) {
        super(props);
    }


    render() { 
			return(
                    <div>
                        <div className="col-xs-12">
                        <p>{this.props.description}</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
			);
    }
}

export default ProductDescription;