import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProductDynamicFeature from "./ProductDynamicFeature";
import ProductStaticFeature from "./ProductStaticFeature";
import DeliveryCheck from "../Common/DeliveryCheck/DeliveryCheck";
import MeasurementSection from "./MeasurementSection";
import ProductLenseFeature from "./ProductLenseFeature";
import Rating from "../RatingReview/Rating";
const oval = {
  width: "6px",
  height: "6px",
  display: "inline-block",
  marginLeft: "10px",
  marginRight: "10px",
  background: " #777777",
  borderRadius: "100%",
};
const frameFeature = {
  color: "#8A949E",
  fontSize: "17px",
  fontWeight: "bold",
};
const bottomLine = {
  background: "#eee",
  height: "2px",
  marginBottom: "20px",
  marginTop: "20px",
};

class ProductDetailsLeftSectionWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color_border: "colour_border",
    };
    this.ChangeColor = this.ChangeColor.bind(this);
  }
  componentDidMount() {}
  ChangeColor(item_url, color_id) {
    this.props.change_color(item_url, color_id);
  }

  render() {
    return (
      <div className="col-xs-12">
        <p className="product_details_full_name_new">
          {this.props.product_details.short_name}
          <Rating
            reviewCount={this.props.review.total_reviews}
            ratingCount={this.props.review.rating}
          ></Rating>
        </p>

        <div>
          <div className="clearfix"></div>
          <p className="product_details_new_size">
            {this.props.product_details.specifications.frame_colour}
            <div style={oval}></div> {this.props.product_details.gender}{" "}
            <div style={oval}></div>
            {"  "}
            {this.props.product_details.specifications.frame_size}
            <Link
              className=""
              style={{ marginLeft: "10px" }}
              to="/product-size"
            >
              (<u>SIZE GUIDE</u>)
            </Link>
          </p>
          <br />

          <p>{this.props.product_details.short_description}</p>

          <div style={bottomLine}></div>
          <p className="" style={frameFeature}>
            <b>frame features</b>
          </p>
          <br></br>
          <ProductDynamicFeature
            source="web"
            features={this.props.product_details.features}
          />
        </div>

        {this.props.pres != "no" ? (
          <div className="col-sm-12 product_details_new_feature_div_web">
            <ProductLenseFeature
              lense_feature={this.props.pres}
              available_power={this.props.available_power}
            />
          </div>
        ) : null}

        <div style={bottomLine}></div>
        <div className="row" style={frameFeature}>
          deliver in 7 - 10 working days
        </div>
        <div className="row">
          <br></br>
          <ProductStaticFeature source="web" />
        </div>

        <div style={bottomLine}></div>
        <div className="row" style={frameFeature}>
          delivery time
        </div>

        <div className="">
          <br></br>
          <DeliveryCheck
            source="web"
            product_id={this.props.product_details.product_id}
          />
        </div>
        <div style={bottomLine}></div>
        <div className="row" style={frameFeature}>
          product details
        </div>
        <br></br>
        <MeasurementSection
          source="web"
          specifications={this.props.product_details.specifications}
        />
        
        <div className="clearfix"></div>
        <div style={bottomLine}></div>
        <div className="clearfix"></div>
        <div className="col-xs-10 pdp_referral_div hidden-xs">
            <div className="col-xs-1" style={{paddingLeft: "5px", paddingRight: "5px"}}><img src="https://static.ojos21.com/icons/icons8-affiliate-96.png" className="img-responsive"/></div>
            <div className="col-xs-11 product_details_cost_text_section">
                <p className="product_details_cost_header_desktop">Refer a Friend & Get FREE OjosWear + Cashback!</p>
                <p className="product_details_cost_subtext_desktop">Invite your friends to try our trendy eyewear using your unique referral link, and you'll both get rewarded with instant cashback & FREE eyewear. Don't miss out on this exclusive offer - <a href="/referral" target="_blank">Start Referring Now!</a></p>
            </div>
        </div>
      </div>
    );
  }
}
export default ProductDetailsLeftSectionWeb;
