import React, { Component } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

class ProductDetailsHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      track_source: Cookies.get("track_source"),
    };
  }
  componentDidMount() {
    if (window.location.href.indexOf("utm_source") > -1) {
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var track_source = urlParams.get("utm_source");
      if (
        this.state.track_source != null &&
        track_source != this.state.track_source
      ) {
        //localStorage.setItem('track_source', track_source);
        Cookies.set("track_source", track_source, { expires: 2 });
      } else {
        //localStorage.setItem('track_source', track_source);
        Cookies.set("track_source", track_source, { expires: 2 });
      }
    }
  }
  RedirectBack() {
    window.history.back();
  }
  render() {
    return (
      <div className="product_details_new_modal_header modal-header">
        <div className="col-xs-12 padding_is_zero product_details_mob_header">
          <div className="col-xs-1">
            <i
              className="fa fa-angle-left product_details_close close s"
              aria-hidden="true"
              onClick={this.RedirectBack}
            ></i>
          </div>
          <div className="col-xs-7"></div>
          <div className="col-xs-4 header_product_details_right_icon">
            {this.props.access_token != "" ? (
              <Link to="/account">
                <img
                  src="/images/Header/MyProfile_Icon_ListingPage.svg"
                  className="header_product_details_icon"
                  alt="profile"
                />
              </Link>
            ) : (
              <Link to="/login">
                <img
                  src="/images/Header/MyProfile_Icon_ListingPage.svg"
                  className="header_product_details_icon"
                  alt="profile"
                />
              </Link>
            )}
            <Link to={"/checkout?path_source=" + this.props.path_source}>
              <img
                src="/images/Header/Cart_Icon_ProdDetails.svg"
                className="header_product_details_icon"
                alt="cart"
              />
            </Link>

            <div>
              {this.props.cart_count > 0 ? (
                <div>
                  {this.props.path_source == "" ? (
                    <Link to={"/checkout"}>
                      <p className="product_details_cart_product_count">
                        {this.props.cart_count}
                      </p>{" "}
                    </Link>
                  ) : (
                    <Link
                      to={"/checkout?path_source=" + this.props.path_source}
                    >
                      <p className="product_details_cart_product_count">
                        {this.props.cart_count}
                      </p>{" "}
                    </Link>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProductDetailsHeader;
