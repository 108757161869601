import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class ProductPackage extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            lense_feature: true,
        };
        this.LenseFeatureButton = this.LenseFeatureButton.bind(this);
    }
    LenseFeatureButton()
    {
        this.setState({
            lense_feature: !this.state.lense_feature,
        });
    }
    handleTypeCheckbox()
    {
        $('input.blu_cut_toggle').on('change', function() {
            $('input.blu_cut_toggle').not(this).prop('checked', false);  
        });
    }
    render() { 
            return(
                <div className="select-package-dialog-background modal-backdrop">
                    <div class="select-package-dialog modal-dialog">
                        <img src="/images/Header/Minimise_ImageView.svg" className="select_package_cross_button" onClick={this.props.select_package}/>
                        <div class="modal-edited modal" id="lab-slide-bottom-popup" data-keyboard="false" data-backdrop="false">
                            <div class="lab-modal-body">
                                <div clasName="blue_cut_lenses">
                                    <div class="col-md-12 first_heading_blu_cut" >
                                        <h3>OJ PREMIUM LENSES</h3>
                                    </div>
                                    <div class="col-md-12">
                                        <p className="package_selection_points_desktop lense_selection_subheader">OJ Premium Lenses are exclusively created to deliver the highest level of clarity, with the best in quality and comfort.</p>
                                        {/*<p className="know_more_blu_cut">Know More</p>*/}
                                        <p className="package_selection_points_desktop lense_selection_subheader">Select the lens package you require:</p>
                                    </div>
                                    {this.props.packages.map((item, index) => (
                                        <div className="col-md-12 accordion first_accordion" onChange={this.handleTypeCheckbox}>
                                            <div className="accordion-tab first_accordion_tab">
                                                <input type="checkbox" id={"toggle"+index} className="accordion-toggle blu_cut_toggle" name ="toggle"></input>
                                                <label for={"toggle"+index} className="blu_cut_label">
                                                    <div className="six blue_cut_tab">
                                                    <img className= 'select_lense_blu_img' src={item.lens_banner_url}/>
                                                        <div className= 'six_one_one blue_cut_tab_description'>
                                                            <div className= 'six_two'>{item.title}
                                                                {item.is_bluecut ?
                                                                    <img class="bluecut_icon_in_blue" src='/images/Header/BluCut_B_Icon.svg'></img>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            <div className= 'six_three'>
                                                                <s class ="not_price_bluecut">₹ {item.strike_price}</s> 
                                                                {item.final_price == '0.00' ?
                                                                    <b class="free_bluecut"> FREE</b>
                                                                    :
                                                                    <b class="price_bluecut">₹ {item.final_price}</b>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>

                                                <div className="accordion-content first_accordion_content">
                                                    <div className="col-xs-12 package_lense_div_modal">
                                                        <div className="col-xs-12 padding_is_zero"><p className="lense_selection_subheader">{item.lens_desc}</p></div>
                                                        <section className="carousel corousel_package_mob" aria-label="Gallery">
                                                            <ol className="carousel__viewport carousel_package_viewport">
                                                                {item.is_bluecut ? 
                                                                    <li className="carousel__slide corousel_package_slide">
                                                                        <div className="carousel__snapper package_carousel_snapper">
                                                                            <img src="/images/Header/BluCutLenses-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                                                                            <p className="lense_package_subtext_section"><img src="/images/Header/feature_icons_V3_BLU-PRO COATING.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">BLU-PRO COATING</p></p>
                                                                            <ul className="package_selection_points_text">
                                                                                <li className="package_selection_points">Ideal for long screen hours</li>
                                                                                <li className="package_selection_points">Limits exposure to blue light</li>
                                                                                <li className="package_selection_points">Reduces chances of Age-related Macular Degeneration (AMD)</li>
                                                                            </ul>
                                                                        </div>
                                                                    </li> 
                                                                    :
                                                                    null
                                                                }
                                                                {item.is_antiglare ?
                                                                    <li className="carousel__slide corousel_package_slide">
                                                                        <div className="carousel__snapper package_carousel_snapper">
                                                                            <img src="/images/Header/AntiGlare-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                                                                            <p className="lense_package_subtext_section"><img src="/images/Header/feature_icons_V3_ANTI-GLARE COATING.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">ANTI-GLARE COATING</p></p>
                                                                            <ul className="package_selection_points_text">
                                                                                <li className="package_selection_points">Ideal for night vision</li>
                                                                                <li className="package_selection_points">Best for long screen hours</li>
                                                                                <li className="package_selection_points">Reduces eye strain</li>
                                                                                <li className="package_selection_points">Prevents dry eye and headache</li>
                                                                                <li className="package_selection_points">Eliminates stray light</li>
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                    :
                                                                    null
                                                                }
                                                                {item.is_max_clarity ?
                                                                    <li className="carousel__slide corousel_package_slide">
                                                                        <div className="carousel__snapper package_carousel_snapper">
                                                                            <img src="/images/Header/MaximumClarity-min.png" alt="Work" className="lense_carousel_img img-responsive"/>
                                                                            <p className="lense_package_subtext_section"><img src="/images/Header/feature_icons_V3_MAXIMUM CLARITY.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">MAXIMUM CLARITY</p></p>
                                                                            <ul className="package_selection_points_text">
                                                                                <li className="package_selection_points">1.56 - 1.67 Indexes</li>
                                                                                <li className="package_selection_points">Relieve eye discomfort</li>
                                                                                <li className="package_selection_points">Smudge & Scratch resistant</li>
                                                                                <li className="package_selection_points">Sleek, stylish and minimum thickness</li>
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                    :
                                                                    null
                                                                }
                                                                {item.is_hydrophobic ?
                                                                    <li className="carousel__slide corousel_package_slide">
                                                                        <div className="carousel__snapper package_carousel_snapper">
                                                                            <img src="/images/Header/Hydrophobic-min.png" alt="Tasks" className="lense_carousel_img img-responsive"/>
                                                                            <p className="lense_package_subtext_section"><img src="/images/Header/feature_icons_V3_HYDROPHOBIC COATING.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">HYDROPHOBIC COATING</p></p>
                                                                            <ul className="package_selection_points_text">
                                                                                <li className="package_selection_points">Rain-wear friendly</li>
                                                                                <li className="package_selection_points">Improves lens lifetime</li>
                                                                                <li className="package_selection_points">Reduces vision distortion</li>
                                                                                <li className="package_selection_points">Smooth surface</li>
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                    :
                                                                    null
                                                                }
                                                                {item.is_ds_resistance ?
                                                                    <li className="carousel__slide corousel_package_slide">
                                                                        <div className="carousel__snapper package_carousel_snapper">
                                                                            <img src="/images/Header/DustResistant-min.png" alt="Development" className="lense_carousel_img img-responsive"/>
                                                                            <p className="lense_package_subtext_section"><img src="/images/Header/feature_icons_V3_DIRT & SCRATCH RESISTANT.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">DIRT & SCRATCH RESISTANT</p></p>
                                                                            <ul className="package_selection_points_text">
                                                                                <li className="package_selection_points">Gives maximum clarity</li>
                                                                                <li className="package_selection_points">Hassle-free handling</li>
                                                                                <li className="package_selection_points">Long-lasting lenses</li>
                                                                                <li className="package_selection_points">Easy to clean</li>
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                    :
                                                                    null
                                                                }
                                                            </ol>
                                                        </section>
                                                    </div>
                                                    <center><button className="btn select_single_zero_button_bluecut btn_bluecut" onClick={() => this.props.select_prescription(item.title)}> {item.final_price == '0.00' ? <p className="lense_package_button_text">ADD LENSES FOR FREE</p> : <p className="lense_package_button_text"> ADD LENSES FOR Rs. {Math.trunc(item.final_price)}</p> }</button></center>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/*<div className="col-md-12 accordion first_accordion" onChange={this.handleTypeCheckbox}>
                                        <div className="accordion-tab first_accordion_tab">
                                            <input type="checkbox" id="toggle1" className="accordion-toggle blu_cut_toggle" name ="toggle"></input>
                                            <label for="toggle1" className="blu_cut_label">
                                                <div className="six blue_cut_tab">
                                                <img className= 'select_lense_blu_img' src='/images/Header/SingleBluImage.png'/>
                                                    <div className= 'six_one_one blue_cut_tab_description'>
                                                        <div className= 'six_two'> BLU CUT SINGLE VISION LENSES <img class="bluecut_icon_in_blue" src='/images/Header/BluCut_B_Icon.svg'></img></div>
                                                        <div className= 'six_three'><b class="bluecut_rs_sym">₹ </b> <s class ="not_price_bluecut">1500</s> <b class="price_bluecut"> ₹ 199</b></div>
                                                    </div>
                                                </div>
                                            </label>

                                            <div className="accordion-content first_accordion_content">
                                                <div className="col-xs-12 package_lense_div_modal">
                                                    <div className="col-xs-12 padding_is_zero"><p className="lense_selection_subheader">Premium powered lenses with anti-glare, hydrophobic dust resistant and blu cut coating to give clear vision and protect eyes from harmful blue rays</p></div>
                                                    <section className="carousel corousel_package_mob" aria-label="Gallery">
                                                        <ol className="carousel__viewport carousel_package_viewport">
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/BluCutLenses-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/BluCutIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">BLU-PRO COATING</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Ideal for long screen hours</li>
                                                                        <li className="package_selection_points">Limits exposure to blue light</li>
                                                                        <li className="package_selection_points">Reduces chances of Age-related Macular Degeneration (AMD)</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/AntiGlare-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/Anti_Glare.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">ANTI-GLARE COATING</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Ideal for night vision</li>
                                                                        <li className="package_selection_points">Best for long screen hours</li>
                                                                        <li className="package_selection_points">Reduces eye strain</li>
                                                                        <li className="package_selection_points">Prevents dry eye and headache</li>
                                                                        <li className="package_selection_points">Eliminates stray light</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/MaximumClarity-min.png" alt="Work" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/MaxClarityIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">MAXIMUM CLARITY</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">1.56 - 1.67 Indexes</li>
                                                                        <li className="package_selection_points">Relieve eye discomfort</li>
                                                                        <li className="package_selection_points">Smudge & Scratch resistant</li>
                                                                        <li className="package_selection_points">Sleek, stylish and minimum thickness</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/Hydrophobic-min.png" alt="Tasks" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/HydrophobicIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">HYDROPHOBIC COATING</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Rain-wear friendly</li>
                                                                        <li className="package_selection_points">Improves lens lifetime</li>
                                                                        <li className="package_selection_points">Reduces vision distortion</li>
                                                                        <li className="package_selection_points">Smooth surface</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/DustResistant-min.png" alt="Development" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/DirtResistantIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">DIRT & SCRATCH RESISTANT</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Gives maximum clarity</li>
                                                                        <li className="package_selection_points">Hassle-free handling</li>
                                                                        <li className="package_selection_points">Long-lasting lenses</li>
                                                                        <li className="package_selection_points">Easy to clean</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </ol>
                                                    </section>
                                                </div>
                                                <center><button className="btn select_single_zero_button_bluecut btn_bluecut" onClick={() => this.props.select_prescription("Blu Cut Single Vision")}>ADD LENSES FOR RS. 199</button></center>
                                            </div>
                                        </div>

                                        <div className="accordion-tab first_accordion_tab" onChange={this.handleTypeCheckbox}>
                                            <input type ="checkbox" id = "toggle2" className= "accordion-toggle blu_cut_toggle" name ="toggle"></input>
                                            <label for = "toggle2" className="blu_cut_label">  
                                                <div className= 'seven blue_cut_tab'>
                                                    <img className='select_lense_blu_img' src= '/images/Header/SingleImage.png'/>
                                                    <div className='seven_one_one blue_cut_tab_description'>
                                                        <div className= 'seven_two'>  SINGLE VISION LENSES</div>
                                                        <div className= 'seven_three'><b class="bluecut_rs_sym">₹ </b> <s class ="not_price_bluecut">1500</s> <b class="free_bluecut"> FREE</b> </div>
                                                    </div>
                                                </div>
                                            </label>
                                            <div className= "accordion-content first_accordion_content">
                                                <div className="col-xs-12 package_lense_div_modal_desktop">
                                                    <div className="col-xs-12 padding_is_zero"><p className="lense_selection_subheader">Premium powered lenses with anti-glare, hydrophobic dust resistant and blu cut coating to give clear vision and protect eyes from harmful blue rays</p></div>
                                                    <section className="carousel corousel_package_mob" aria-label="Gallery">
                                                        <ol className="carousel__viewport carousel_package_viewport">
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/AntiGlare-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/Anti_Glare.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">ANTI-GLARE COATING</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Ideal for night vision</li>
                                                                        <li className="package_selection_points">Best for long screen hours</li>
                                                                        <li className="package_selection_points">Reduces eye strain</li>
                                                                        <li className="package_selection_points">Prevents dry eye and headache</li>
                                                                        <li className="package_selection_points">Eliminates stray light</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/MaximumClarity-min.png" alt="Work" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/MaxClarityIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">MAXIMUM CLARITY</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">1.56 - 1.67 Indexes</li>
                                                                        <li className="package_selection_points">Relieve eye discomfort</li>
                                                                        <li className="package_selection_points">Smudge & Scratch resistant</li>
                                                                        <li className="package_selection_points">Sleek, stylish and minimum thickness</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/Hydrophobic-min.png" alt="Tasks" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/HydrophobicIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">HYDROPHOBIC COATING</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Rain-wear friendly</li>
                                                                        <li className="package_selection_points">Improves lens lifetime</li>
                                                                        <li className="package_selection_points">Reduces vision distortion</li>
                                                                        <li className="package_selection_points">Smooth surface</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/DustResistant-min.png" alt="Development" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/DirtResistantIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">DIRT & SCRATCH RESISTANT</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Gives maximum clarity</li>
                                                                        <li className="package_selection_points">Hassle-free handling</li>
                                                                        <li className="package_selection_points">Long-lasting lenses</li>
                                                                        <li className="package_selection_points">Easy to clean</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </ol>
                                                    </section>
                                                </div>
                                                <center><button className="btn select_single_zero_button_bluecut btn_bluecut" onClick={() => this.props.select_prescription("Single Vision")}>ADD LENSES FOR FREE</button></center>
                                            </div>
                                        </div>

                                        <div className = "accordion-tab first_accordion_tab" onChange={this.handleTypeCheckbox}>
                                            <input type ="checkbox" id = "toggle3" className= "accordion-toggle blu_cut_toggle" name ="toggle"></input>
                                            <label for="toggle3" className="blu_cut_label">
                                                <div className= 'eight blue_cut_tab'>
                                                    <img className='select_lense_blu_img' src= '/images/Header/ZeroBluImage.png'/>
                                                    <div className= 'eight_one_one blue_cut_tab_description'>
                                                        <div className= 'eight_two'> ZERO POWER LENSES <img class="bluecut_icon_in_blue" src='/images/Header/BluCut_B_Icon.svg'></img></div>
                                                        <div className= 'eight_three'><b class="bluecut_rs_sym">₹ </b> <s class ="not_price_bluecut">1500</s>   <b class="free_bluecut">FREE</b></div>
                                                    </div>
                                                </div>
                                            </label>
                              
                                            <div className = "accordion-content first_accordion_content">
                                                <div className="col-xs-12 package_lense_div_modal_desktop">
                                                    <div className="col-xs-12 padding_is_zero"><p className="lense_selection_subheader">Premium powered lenses with anti-glare, hydrophobic dust resistant and blu cut coating to give clear vision and protect eyes from harmful blue rays</p></div>
                                                    <section className="carousel corousel_package_mob" aria-label="Gallery">
                                                        <ol className="carousel__viewport carousel_package_viewport">
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/BluCutLenses-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/BluCutIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">BLU-PRO COATING</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Ideal for long screen hours</li>
                                                                        <li className="package_selection_points">Limits exposure to blue light</li>
                                                                        <li className="package_selection_points">Reduces chances of Age-related Macular Degeneration (AMD)</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/AntiGlare-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/Anti_Glare.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">ANTI-GLARE COATING</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Ideal for night vision</li>
                                                                        <li className="package_selection_points">Best for long screen hours</li>
                                                                        <li className="package_selection_points">Reduces eye strain</li>
                                                                        <li className="package_selection_points">Prevents dry eye and headache</li>
                                                                        <li className="package_selection_points">Eliminates stray light</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/MaximumClarity-min.png" alt="Work" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/MaxClarityIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">MAXIMUM CLARITY</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">1.56 - 1.67 Indexes</li>
                                                                        <li className="package_selection_points">Relieve eye discomfort</li>
                                                                        <li className="package_selection_points">Smudge & Scratch resistant</li>
                                                                        <li className="package_selection_points">Sleek, stylish and minimum thickness</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/Hydrophobic-min.png" alt="Tasks" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/HydrophobicIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">HYDROPHOBIC COATING</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Rain-wear friendly</li>
                                                                        <li className="package_selection_points">Improves lens lifetime</li>
                                                                        <li className="package_selection_points">Reduces vision distortion</li>
                                                                        <li className="package_selection_points">Smooth surface</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="carousel__slide corousel_package_slide">
                                                                <div className="carousel__snapper package_carousel_snapper">
                                                                    <img src="/images/Header/DustResistant-min.png" alt="Development" className="lense_carousel_img img-responsive"/>
                                                                    <p className="lense_package_subtext_section"><img src="/images/Header/DirtResistantIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">DIRT & SCRATCH RESISTANT</p></p>
                                                                    <ul className="package_selection_points_text">
                                                                        <li className="package_selection_points">Gives maximum clarity</li>
                                                                        <li className="package_selection_points">Hassle-free handling</li>
                                                                        <li className="package_selection_points">Long-lasting lenses</li>
                                                                        <li className="package_selection_points">Easy to clean</li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </ol>
                                                    </section>
                                                </div>
                                                <center><button className="btn select_single_zero_button_bluecut btn_bluecut" onClick={() => this.props.select_prescription("Zero Power")}>ADD LENSES FOR FREE</button></center>
                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
			);
    }
}

export default ProductPackage;

/*
<h4 className="lense_selection_header">Select OJ Premium Lenses</h4>
<p className="lense_selection_subheader">OJ Premium Lenses are exclusively created to deliver the highest level of clarity, with the best in quality and comfort.</p>
<div className="col-xs-6 package_selection_button">
    <button className={this.state.lense_feature ? "package_lense_button_single_active" : "package_lense_button_single_inactive"} onClick={this.LenseFeatureButton}>SINGLE VISION</button>
</div>
<div className="col-xs-6 package_selection_button">
    <button className={!this.state.lense_feature ? "package_lense_button_zero_active" : "package_lense_button_zero_inactive"} onClick={this.LenseFeatureButton}>ZERO POWER</button>
</div>

{this.state.lense_feature ?
    <div className="col-xs-12 package_lense_div_modal">
        <div className="col-xs-12 padding_is_zero"><div className="col-xs-10 padding_is_zero"><p className="lense_selection_main_text">PREMIUM POWERED LENSES</p></div><div className="col-xs-2 padding_is_zero package_selection_striked"><s>₹ 1500</s></div></div>
        <div className="col-xs-12 padding_is_zero"><div className="col-xs-10 padding_is_zero"><p className="lense_selection_subheader">Get the best in superior quality, cut and clarity</p></div><div className="col-xs-2 padding_is_zero package_selection_free">FREE</div></div>
        <section className="carousel corousel_package" aria-label="Gallery">
            <ol className="carousel__viewport carousel_package_viewport">
                <li className="carousel__slide corousel_package_slide">
                    <div className="carousel__snapper package_carousel_snapper">
                        <img src="/images/Header/AntiGlare-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                        <p className="lense_package_subtext_section"><img src="/images/Header/Anti_Glare.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">ANTI-GLARE COATING</p></p>
                        <ul className="package_selection_points_text">
                            <li className="package_selection_points">Ideal for night vision</li>
                            <li className="package_selection_points">Best for long screen hours</li>
                            <li className="package_selection_points">Reduces eye strain</li>
                            <li className="package_selection_points">Prevents dry eye and headache</li>
                            <li className="package_selection_points">Eliminates stray light</li>
                        </ul>
                    </div>
                </li>
                <li className="carousel__slide corousel_package_slide">
                    <div className="carousel__snapper package_carousel_snapper">
                        <img src="/images/Header/MaximumClarity-min.png" alt="Work" className="lense_carousel_img img-responsive"/>
                        <p className="lense_package_subtext_section"><img src="/images/Header/MaxClarityIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">MAXIMUM CLARITY</p></p>
                        <ul className="package_selection_points_text">
                            <li className="package_selection_points">1.56 - 1.67 Indexes</li>
                            <li className="package_selection_points">Relieve eye discomfort</li>
                            <li className="package_selection_points">Smudge & Scratch resistant</li>
                            <li className="package_selection_points">Sleek, stylish and minimum thickness</li>
                        </ul>
                    </div>
                </li>
                <li className="carousel__slide corousel_package_slide">
                    <div className="carousel__snapper package_carousel_snapper">
                        <img src="/images/Header/Hydrophobic-min.png" alt="Tasks" className="lense_carousel_img img-responsive"/>
                        <p className="lense_package_subtext_section"><img src="/images/Header/HydrophobicIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">HYDROPHOBIC COATING</p></p>
                        <ul className="package_selection_points_text">
                            <li className="package_selection_points">Rain-wear friendly</li>
                            <li className="package_selection_points">Improves lens lifetime</li>
                            <li className="package_selection_points">Reduces vision distortion</li>
                            <li className="package_selection_points">Smooth surface</li>
                        </ul>
                    </div>
                </li>
                <li className="carousel__slide corousel_package_slide">
                    <div className="carousel__snapper package_carousel_snapper">
                        <img src="/images/Header/DustResistant-min.png" alt="Development" className="lense_carousel_img img-responsive"/>
                        <p className="lense_package_subtext_section"><img src="/images/Header/DirtResistantIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">DIRT & SCRATCH RESISTANT</p></p>
                        <ul className="package_selection_points_text">
                            <li className="package_selection_points">Gives maximum clarity</li>
                            <li className="package_selection_points">Hassle-free handling</li>
                            <li className="package_selection_points">Long-lasting lenses</li>
                            <li className="package_selection_points">Easy to clean</li>
                        </ul>
                    </div>
                </li>
            </ol>
        </section>
        <center><button className="btn select_single_zero_button" onClick={() => this.props.select_prescription("Single Vision")}>SELECT SINGLE VISION</button></center>
    </div>
    :
    <div className="col-xs-12 package_lense_div_modal">
        <div className="col-xs-12 padding_is_zero"><div className="col-xs-10 padding_is_zero"><p className="lense_selection_main_text">ZERO POWER BLU LENSES</p></div><div className="col-xs-2 padding_is_zero package_selection_striked"><s>₹ 1500</s></div></div>
        <div className="col-xs-12 padding_is_zero"><div className="col-xs-10 padding_is_zero"><p className="lense_selection_subheader">Designed for best eye protection from harmful rays</p></div><div className="col-xs-2 padding_is_zero package_selection_free">FREE</div></div>
        <section className="carousel corousel_package" aria-label="Gallery">
            <ol className="carousel__viewport carousel_package_viewport">
                <li className="carousel__slide corousel_package_slide">
                    <div className="carousel__snapper package_carousel_snapper">
                        <img src="/images/Header/BluCutLenses-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                        <p className="lense_package_subtext_section"><img src="/images/Header/BluCutIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">BLU-PRO COATING</p></p>
                        <ul className="package_selection_points_text">
                            <li className="package_selection_points">Ideal for long screen hours</li>
                            <li className="package_selection_points">Limits exposure to blue light</li>
                            <li className="package_selection_points">Reduces chances of Age-related Macular Degeneration (AMD)</li>
                        </ul>
                    </div>
                </li>
                <li className="carousel__slide corousel_package_slide">
                    <div className="carousel__snapper package_carousel_snapper">
                        <img src="/images/Header/AntiGlare-min.png" alt="Meeting" className="lense_carousel_img img-responsive"/>
                        <p className="lense_package_subtext_section"><img src="/images/Header/Anti_Glare.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">ANTI-GLARE COATING</p></p>
                        <ul className="package_selection_points_text">
                            <li className="package_selection_points">Ideal for night vision</li>
                            <li className="package_selection_points">Best for long screen hours</li>
                            <li className="package_selection_points">Reduces eye strain</li>
                            <li className="package_selection_points">Prevents dry eye and headache</li>
                            <li className="package_selection_points">Eliminates stray light</li>
                        </ul>
                    </div>
                </li>
                <li className="carousel__slide corousel_package_slide">
                    <div className="carousel__snapper package_carousel_snapper">
                        <img src="/images/Header/MaximumClarity-min.png" alt="Work" className="lense_carousel_img img-responsive"/>
                        <p className="lense_package_subtext_section"><img src="/images/Header/MaxClarityIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">MAXIMUM CLARITY</p></p>
                        <ul className="package_selection_points_text">
                            <li className="package_selection_points">1.56 - 1.67 Indexes</li>
                            <li className="package_selection_points">Relieve eye discomfort</li>
                            <li className="package_selection_points">Smudge & Scratch resistant</li>
                            <li className="package_selection_points">Sleek, stylish and minimum thickness</li>
                        </ul>
                    </div>
                </li>
                <li className="carousel__slide corousel_package_slide">
                    <div className="carousel__snapper package_carousel_snapper">
                        <img src="/images/Header/Hydrophobic-min.png" alt="Tasks" className="lense_carousel_img img-responsive"/>
                        <p className="lense_package_subtext_section"><img src="/images/Header/HydrophobicIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">HYDROPHOBIC COATING</p></p>
                        <ul className="package_selection_points_text">
                            <li className="package_selection_points">Rain-wear friendly</li>
                            <li className="package_selection_points">Improves lens lifetime</li>
                            <li className="package_selection_points">Reduces vision distortion</li>
                            <li className="package_selection_points">Smooth surface</li>
                        </ul>
                    </div>
                </li>
                <li className="carousel__slide corousel_package_slide">
                    <div className="carousel__snapper package_carousel_snapper">
                        <img src="/images/Header/DustResistant-min.png" alt="Development" className="lense_carousel_img img-responsive"/>
                        <p className="lense_package_subtext_section"><img src="/images/Header/DirtResistantIcon – 1.svg" className="lense_selection_icon"/><p className="lense_package_subtext_header">DIRT & SCRATCH RESISTANT</p></p>
                        <ul className="package_selection_points_text">
                            <li className="package_selection_points">Gives maximum clarity</li>
                            <li className="package_selection_points">Hassle-free handling</li>
                            <li className="package_selection_points">Long-lasting lenses</li>
                            <li className="package_selection_points">Easy to clean</li>
                        </ul>
                    </div>
                </li>
            </ol>
        </section>
        <center><button className="btn select_single_zero_button" onClick={() => this.props.select_prescription("Zero Power")}>SELECT ZERO POWER</button></center>
    </div>
}
*/