import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import $ from "jquery";
import { Link } from "react-router-dom";

class HomePageTrendingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product_details: [],
      show_product: false,
      color_border_trending: "color_border_trending",
    };
    this.ChangeColor = this.ChangeColor.bind(this);
  }
  componentDidMount() {
    var itemsMainDiv = ".MultiCarousel";
    var itemsDiv = ".MultiCarousel-inner";
    var itemWidth = "";

    $(".leftLst, .rightLst").click(function () {
      console.log("clicked");
      var condition = $(this).hasClass("leftLst");
      if (condition) click(0, this);
      else click(1, this);
    });

    ResCarouselSize();

    $(window).resize(function () {
      ResCarouselSize();
    });
    $(window).scroll(function () {
      ResCarouselSize();
    });

    //this function define the size of the items
    function ResCarouselSize() {
      var incno = 0;
      var dataItems = "data-items";
      var itemClass = ".item";
      var id = 0;
      var btnParentSb = "";
      var itemsSplit = "";
      var sampwidth = $(itemsMainDiv).width();
      var bodyWidth = $("body").width();
      $(itemsDiv).each(function () {
        id = id + 1;
        var itemNumbers = $(this).find(itemClass).length;
        btnParentSb = $(this).parent().attr(dataItems);
        itemsSplit = btnParentSb.split(",");
        $(this)
          .parent()
          .attr("id", "MultiCarousel" + id);

        if (bodyWidth >= 1200) {
          incno = itemsSplit[3];
          itemWidth = sampwidth / incno;
        } else if (bodyWidth >= 992) {
          incno = itemsSplit[2];
          itemWidth = sampwidth / incno;
        } else if (bodyWidth >= 768) {
          incno = itemsSplit[1];
          itemWidth = sampwidth / incno;
        } else {
          incno = itemsSplit[0];
          itemWidth = sampwidth / incno;
        }
        $(this).css({
          transform: "translateX(0px)",
          width: itemWidth * itemNumbers,
        });
        $(this)
          .find(itemClass)
          .each(function () {
            $(this).outerWidth(itemWidth);
          });

        $(".leftLst").addClass("over");
        $(".rightLst").removeClass("over");
      });
    }

    //this function used to move the items
    function ResCarousel(e, el, s) {
      var leftBtn = ".leftLst";
      var rightBtn = ".rightLst";
      var translateXval = "";
      var divStyle = $(el + " " + itemsDiv).css("transform");
      var values = divStyle.match(/-?[\d\.]+/g);
      var xds = Math.abs(values[4]);
      if (e == 0) {
        translateXval = parseInt(xds) - parseInt(itemWidth * s);
        $(el + " " + rightBtn).removeClass("over");

        if (translateXval <= itemWidth / 2) {
          translateXval = 0;
          $(el + " " + leftBtn).addClass("over");
        }
      } else if (e == 1) {
        var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
        translateXval = parseInt(xds) + parseInt(itemWidth * s);
        $(el + " " + leftBtn).removeClass("over");

        if (translateXval >= itemsCondition - itemWidth / 2) {
          translateXval = itemsCondition;
          $(el + " " + rightBtn).addClass("over");
        }
      }
      $(el + " " + itemsDiv).css(
        "transform",
        "translateX(" + -translateXval + "px)"
      );
    }

    //It is used to get some elements from btn
    function click(ell, ee) {
      var Parent = "#" + $(ee).parent().attr("id");
      var slide = $(Parent).attr("data-slide");
      ResCarousel(ell, Parent, slide);
    }
  }
  ChangeColor(item_url, color_id, i) {
    this.props.change_color(item_url, color_id, i);
  }
  render() {
    return (
      <div>
        <div className="trending_product_div"></div>
        {this.props.trending_product_details ? (
          <div
            className="col-xs-12 padding_is_zero trending_product_div"
            
          >
            {this.props.source == "mob" ? (
              <div className="col-xs-12 padding_is_zero">
                <span className="trending_product_text_mob">
                  trending products
                </span>
                <a href="/products/eyeglasses/trending">
                  <span className="trending_product_view_mob">
                    <u>view all</u>
                  </span>
                </a>
                <section
                  className="carousel corousel_trending_product"
                  aria-label="Gallery"
                >
                  <ol className="carousel__viewport">
                    {this.props.trending_product_details.map(
                      (item, product_index) => (
                        <li
                          className={
                            item.model_images.length
                              ? "carousel__slide__trending trending_product_div carousel__slide_trending carousel__slide"
                              : ""
                          }
                        >
                          {item.model_images.length > 0 ? (
                            <div className="carousel__snapper_trending">
                              <div className="col-xs-12 col-sm-4 col-md-2">
                                <a
                                  href={
                                    "/product/" +
                                    item.prod_url +
                                    "/color/" +
                                    item.specifications.color_prod_id
                                  }
                                >
                                  <img
                                    src={item.model_images[0].url}
                                    className="img-responsive trending_product_model_img gallery-image center-block"
                                  />
                                </a>
                                <div className="col-xs-12 homepage_trending_textarea_mob">
                                  <a
                                    href={
                                      "/product/" +
                                      item.prod_url +
                                      "/color/" +
                                      item.specifications.color_prod_id
                                    }
                                  >
                                    <div className="col-xs-8 padding_is_zero">
                                      {item.short_name &&
                                      item.short_name.length > 15 ? (
                                        <p className="homepage_trending_product_name_mob">
                                          {item.short_name.substr(0, 12) +
                                            "..."}
                                        </p>
                                      ) : (
                                        <p className="homepage_trending_product_name_mob">
                                          {item.short_name}
                                        </p>
                                      )}
                                    </div>
                                  </a>
                                  <div className="col-xs-4 colour_section_trending">
                                    {item.colors.map((color, index) => (
                                      <div>
                                        {item.specifications.color_prod_id ===
                                        color.id ? (
                                          <div>
                                            {color.sold_out == 1 ? (
                                              <div className="transparent_colour_border_trending">
                                                <img
                                                  src="/images/Header/red_striked_circle.svg"
                                                  className="color_striked_img"
                                                  onClick={() =>
                                                    this.ChangeColor(
                                                      item.prod_url,
                                                      color.id,
                                                      product_index
                                                    )
                                                  }
                                                />
                                                <span
                                                  className="colour_dot_product_details"
                                                  id={
                                                    color.basic_prod_id +
                                                    "_" +
                                                    color.id
                                                  }
                                                  key={index}
                                                  style={{
                                                    backgroundColor:
                                                      color.hex_code,
                                                  }}
                                                ></span>
                                              </div>
                                            ) : (
                                              <div
                                                className={
                                                  this.state
                                                    .color_border_trending
                                                }
                                              >
                                                <span
                                                  className="colour_dot_trending_homepage"
                                                  id={
                                                    color.basic_prod_id +
                                                    "_" +
                                                    color.id
                                                  }
                                                  key={index}
                                                  style={{
                                                    backgroundColor:
                                                      color.hex_code,
                                                  }}
                                                  onClick={() =>
                                                    this.ChangeColor(
                                                      item.prod_url,
                                                      color.id,
                                                      product_index
                                                    )
                                                  }
                                                ></span>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div>
                                            {color.sold_out == 1 ? (
                                              <div className="transparent_colour_border_trending">
                                                <img
                                                  src="/images/Header/white_striked_circle.svg"
                                                  className="color_striked_img"
                                                  onClick={() =>
                                                    this.ChangeColor(
                                                      item.prod_url,
                                                      color.id,
                                                      product_index
                                                    )
                                                  }
                                                />
                                                <span
                                                  className="colour_dot_product_details"
                                                  key={index}
                                                  style={{
                                                    backgroundColor:
                                                      color.hex_code,
                                                  }}
                                                ></span>
                                              </div>
                                            ) : (
                                              <div className="transparent_colour_border_trending">
                                                <span
                                                  className="colour_dot_trending_homepage"
                                                  key={index}
                                                  style={{
                                                    backgroundColor:
                                                      color.hex_code,
                                                  }}
                                                  onClick={() =>
                                                    this.ChangeColor(
                                                      item.prod_url,
                                                      color.id,
                                                      product_index
                                                    )
                                                  }
                                                ></span>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                  <div className="clearfix"></div>
                                  <a
                                    href={
                                      "/product/" +
                                      item.prod_url +
                                      "/color/" +
                                      item.specifications.color_prod_id
                                    }
                                  >
                                    <p className="homepage_trending_product_size_mob">
                                      {item.specifications.frame_size} -{" "}
                                      {item.gender}
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </li>
                      )
                    )}
                  </ol>
                </section>
              </div>
            ) : (
              <div className="col-sm-12">
                <div className="col-xs-12">
                  <span className="trending_product_text">
                    {this.props.pageSource === "pdp"
                      ? "similar products"
                      : "trending products"}
                  </span>
                  <a href="/products/eyeglasses/trending">
                    <span className="trending_product_view">
                      <u>view all</u>
                    </span>
                  </a>
                </div>
                <div className="col-sm-12">
                  <div
                    className="MultiCarousel trending_products_carousel"
                    data-items="1,3,6,4"
                    data-slide="1"
                    id="MultiCarousel"
                    data-interval="1000"
                  >
                    <div className="MultiCarousel-inner">
                      {this.props.trending_product_details.map(
                        (item, product_index) => (
                          <div>
                            {item.model_images.length > 0 ? (
                              <div className="item padding_is_zero">
                                <div className="pad15">
                                  <a
                                    href={
                                      "/product/" +
                                      item.prod_url +
                                      "/color/" +
                                      item.specifications.color_prod_id
                                    }
                                  >
                                    <img
                                      src={item.model_images[0].url}
                                      className="trending_product_model_img gallery-image center-block"
                                    />
                                  </a>
                                  <div className="col-sm-12 homepage_trending_textarea padding_is_zero">
                                    <a
                                      href={
                                        "/product/" +
                                        item.prod_url +
                                        "/color/" +
                                        item.specifications.color_prod_id
                                      }
                                    >
                                      <div className="col-sm-8 padding_is_zero">
                                        {item.short_name &&
                                        item.short_name.length > 15 ? (
                                          <p className="homepage_trending_product_name">
                                            {item.short_name.substr(0, 12) +
                                              "..."}
                                          </p>
                                        ) : (
                                          <p className="homepage_trending_product_name">
                                            {item.short_name}
                                          </p>
                                        )}
                                      </div>
                                    </a>
                                    <div className="col-sm-4 colour_section_trending">
                                      {item.colors.map((color, index) => (
                                        <div>
                                          {item.specifications.color_prod_id ===
                                          color.id ? (
                                            <div>
                                              {color.sold_out == 1 ? (
                                                <div className="transparent_colour_border_trending">
                                                  <img
                                                    src="/images/Header/red_striked_circle.svg"
                                                    className="color_striked_img"
                                                    onClick={() =>
                                                      this.ChangeColor(
                                                        item.prod_url,
                                                        color.id,
                                                        product_index
                                                      )
                                                    }
                                                  />
                                                  <span
                                                    className="colour_dot_product_details"
                                                    id={
                                                      color.basic_prod_id +
                                                      "_" +
                                                      color.id
                                                    }
                                                    key={index}
                                                    style={{
                                                      backgroundColor:
                                                        color.hex_code,
                                                    }}
                                                  ></span>
                                                </div>
                                              ) : (
                                                <div
                                                  className={
                                                    this.state
                                                      .color_border_trending
                                                  }
                                                >
                                                  <span
                                                    className="colour_dot_trending_homepage"
                                                    id={
                                                      color.basic_prod_id +
                                                      "_" +
                                                      color.id
                                                    }
                                                    key={index}
                                                    style={{
                                                      backgroundColor:
                                                        color.hex_code,
                                                    }}
                                                    onClick={() =>
                                                      this.ChangeColor(
                                                        item.prod_url,
                                                        color.id,
                                                        product_index
                                                      )
                                                    }
                                                  ></span>
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div>
                                              {color.sold_out == 1 ? (
                                                <div className="transparent_colour_border_trending">
                                                  <img
                                                    src="/images/Header/white_striked_circle.svg"
                                                    className="color_striked_img"
                                                    onClick={() =>
                                                      this.ChangeColor(
                                                        item.prod_url,
                                                        color.id,
                                                        product_index
                                                      )
                                                    }
                                                  />
                                                  <span
                                                    className="colour_dot_product_details"
                                                    key={index}
                                                    style={{
                                                      backgroundColor:
                                                        color.hex_code,
                                                    }}
                                                  ></span>
                                                </div>
                                              ) : (
                                                <div className="transparent_colour_border_trending">
                                                  <span
                                                    className="colour_dot_trending_homepage"
                                                    key={index}
                                                    style={{
                                                      backgroundColor:
                                                        color.hex_code,
                                                    }}
                                                    onClick={() =>
                                                      this.ChangeColor(
                                                        item.prod_url,
                                                        color.id,
                                                        product_index
                                                      )
                                                    }
                                                  ></span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="clearfix"></div>
                                    <a
                                      href={
                                        "/product/" +
                                        item.prod_url +
                                        "/color/" +
                                        item.specifications.color_prod_id
                                      }
                                    >
                                      <p className="homepage_trending_product_size">
                                        {item.specifications.frame_size} -{" "}
                                        {item.gender}
                                      </p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        )
                      )}
                    </div>
                    {/*<img src="/images/Header/Icon ionic-ios-arrow-dropright-circle.svg" className="leftLst"/>
						            <img src="/images/Header/Icon ionic-ios-arrow-dropright-circle.svg" className="rightLst"/>*/}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default HomePageTrendingSection;
