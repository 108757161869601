import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import ProductPage from "./ProductPage";
import ProductSeasonalPage from "./ProductSeasonalPage";
import AllProductPage from "./AllProductPage";
import CollectionPageList from "./CollectionPageList"
import { fetchProductsType } from "../../Redux/Action/ProductActions";
import { fetchProductsTypeCollection } from "../../Redux/Action/ProductActions";
import store from '../../Redux/store';

class ProductType extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
			width: window.innerWidth,
		}
	}
	componentDidMount()
	{
		const product_type = this.props.match.params.type;
		const frame_type = this.props.match.params.frame_type;
		
		this.props.dispatch(fetchProductsType(product_type,frame_type));

		const state = store.getState();
		window.addEventListener('resize', this.handleWindowSizeChange);
		
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
	
	  this.setState({ width: window.innerWidth });
	};
	render() {
		const product_type = this.props.match.params.type;
		const frame_type = this.props.match.params.frame_type;
		const { error, loading, products } = this.props;
		const { width } = this.state;
		const isMobile = width <= 768;
		console.log(this.props.products);
		if (error) {
	      return <div>Error! {error.message}</div>;
	    }

	    if (loading) {
	      return (
	      		<div> 
	      			<Header color={"#160D08"} /> 
	      			<Loading />
	      		</div>
	      	);
	    }
		if (isMobile) 
 		{
			if(product_type == 'fifa')
			{
				return (
					<div>
						<Header color={"transparent"} />
						<ProductSeasonalPage />
					</div>
				);
			}
			else if(product_type == 'trending')
			{
				return (
					<div>
						<Header color={"transparent"} />
						<ProductPage />
					</div>
				);
			}
			else if(product_type == 'featured')
			{
				return (
					<div>
						<Header color={"transparent"} />
						<ProductPage />
					</div>
				);
			}
			else if(frame_type == 'sunglasses')
			{
				return (
					<div>
						<Header color={"transparent"} />
						<ProductPage />
					</div>
				);
			}
			else if(product_type == 'collection')
			{
				return (
					<div>
						<Header color={"#160D08"} />
						<CollectionPageList products={products} device="mob"/>
					</div>
				);
			}
			return (
				<div>
					<Header color={"#160D08"} />
					{/*<ProductPage />*/}
					<AllProductPage />
				</div>
			);
		}
		else
		{
			if(product_type == 'fifa')
			{
				return (
					<div>
						<Header color={"#160D08"} />
						<ProductSeasonalPage />
					</div>
				);
			}
			else if(product_type == 'trending')
			{
				return (
					<div>
						<Header color={"#160D08"} />
						<ProductPage />
					</div>
				);
			}
			else if(product_type == 'featured')
			{
				return (
					<div>
						<Header color={"#160D08"} />
						<ProductPage />
					</div>
				);
			}
			else if(frame_type == 'sunglasses')
			{
				return (
					<div>
						<Header color={"#160D08"} />
						<ProductPage />
					</div>
				);
			}
			else if(product_type == 'collection')
			{
				return (
					<div>
						<Header color={"#160D08"} />
						<CollectionPageList products={products} device="web"/>
					</div>
				);
			}
			return (
				<div>
					<Header color={"#160D08"} />
					{/*<ProductPage />*/}
					<AllProductPage />
				</div>
			);
		}
	}
}
const mapStateToProps = state => ({
  	products: state.products.items,
  	loading: state.products.loading,
  	error: state.products.error
});

export default connect(mapStateToProps)(ProductType);
