import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoginTextBox from './LoginTextBox';
import MobileNumberBox from './MobileNumberBox';
import LoginCommonDiv from './LoginCommonDiv';

class Login extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            show_login_subtext: true,
            checkout_redirect: false,
            redirectUrl: '/'
        };
        
    }
    componentDidMount()
    {
        const query = new URLSearchParams(window.location.search);
        const redirect_value = query.get('redirect_url');
        if(redirect_value)
        {
            if(redirect_value.includes("cart"))
            {
                this.setState({
                    show_login_subtext: false,
                    checkout_redirect: true,
                    redirectUrl: redirect_value,
                });
            }
        }
    }

    render() { 
			return(
                <div>
                    <LoginCommonDiv show_login_subtext={this.state.show_login_subtext}/>
                </div>
			);
    }
}

export default Login;

{/*<div>
    <div className="dark_background row no_margin">
        <div className="hidden-sm hidden-md hidden-lg col-xs-12">
        {this.state.show_login_subtext? 
            <Link to="/">
                <span className="close_modal_btn col-xs-2" id="close_modal">Close</span>
            </Link>
            :
            <Link to={this.state.redirectUrl}>
                <span className="close_modal_btn col-xs-2" id="close_modal">Close</span>
            </Link>
        }
            
        </div>
        <LoginTextBox step={this.state.step} show_login_subtext={this.state.show_login_subtext}/>
        <MobileNumberBox change_step={this.ChangeStep} checkout_redirect={this.state.checkout_redirect}/>
    </div>
</div>*/}
