import React, { Component } from "react";
import { Redirect } from 'react-router';
import CheckoutHeader from './CheckoutHeader';
import Lottie from 'react-lottie';
import animationData from '../Lotties/fail_payment_lottie.json';
import ClevertapReact from 'clevertap-react';

class PaymentFail extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	token: localStorage.getItem("token"),
	    	redirect: false,
	    	order_id: '',
	    	redirectUrl: '/',
	    	stage: 2,
	    	width: window.innerWidth,
	    }
	}
	componentDidMount()
	{
		const order_id = this.props.match.params.order_id;
		this.setState({order_id: order_id})
		var myHeaders = new Headers();
	    myHeaders.append("Authorization", "Bearer "+ this.state.token);
	    let url = process.env.REACT_APP_API_URL+'/v1/orders/hashedid/'+order_id;
	    var data = '';
		var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

		fetch(url, requestOptions)
        .then(response => response.json())
		.then(data => {
            console.log(data)
            var subscription_id = 1;
            var order_value = data.result.total_cost;
            var order_id = data.result.hashed_id;
            var payment_method = data.result.payment_method;
            var checkout_failed = 
			{
				"subscription_id": subscription_id,
                "order_value": order_value,
                "order_id": order_id,
                "payment_method": payment_method
	        }
	        ClevertapReact.event("PaymentFailed",checkout_failed);
        })
	}
	componentWillMount() 
  	{
	  window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	
	handleWindowSizeChange = () => {
	
	  this.setState({ width: window.innerWidth });
	};
	
	CheckoutBack()
	{
		this.setState({ redirect: true, redirectUrl:'/'})
	}
	render() {
		const defaultOptions = {
	      loop: true,
	      autoplay: true,
	      animationData: animationData,
	      rendererSettings: {
	        preserveAspectRatio: "xMidYMid slice"
	      }
	    };
	    const styles = {
			checkAnimation: {
			    display: 'flex',
  				justifyContent: 'center',
  				alignItems: 'center',
  				textAlign: 'center',
			},
		}
		const { width } = this.state;
  		const isMobile = width <= 768;
  		if(isMobile)
  		{
  			var device = "mob";
  		}
  		else
  		{
  			var device = "web";
  		}
		if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
        return(
			<div>
				<CheckoutHeader  stage={this.state.stage} device={device}/>
				<div className="col-sm-3"></div>
				<div className="col-sm-6 padding_is_zero">
					<div className="checkout_details">
						<div class="payment_body">
							<div className="col-sm-12 payment_section_text_fail">
								<center>
									<Lottie options={defaultOptions} width={300} style={styles.checkAnimation}/>
									<p className="order_placed_text">Oh No! <br/> Sorry to keep you hanging</p>
									<p className="order_fail_text">Unfortunately, we were unable to process your order (details given below). Our team will get in touch with you, soon!</p>
									<p className="order_placed_subtext">Order ID: {this.state.order_id}</p>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3"></div>
			</div>
		);
		
	}
}

export default PaymentFail;