import React, { Component } from 'react';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import Lottie from 'react-lottie';
import animationData from '../Lotties/2100_package_confetti.json';

class CartSubscription extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            redirectTo: '/subscription-plan?path_source=cart',
            redirect: false,
        }
        this.show_subscription_text = this.show_subscription_text.bind(this);
    }
    show_subscription_text()
    {
        this.setState({ redirect: true})
    }
    render() { 
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                }
            };
            const styles = {
                checkAnimation: {
                    position: 'absolute',
                    textAlign: 'center',
                    zIndex: 1,
                    top: '0px',
                    left: '150px',
                },
            }
            if(this.state.redirect){
                return <Redirect to={this.state.redirectTo} />
            }
            return(
                    <div className="col-xs-12 combo_desc_area">
                        {this.props.subscription.length > 1 ?
                        <div>
                            {/*<div className="col-xs-12 subscribe_area">
                                {this.props.selected_sub == 1 ? 
                                    <img src="../images/Header/SubscriptionIllustration1.svg" className="subscribe_icon" alt="icon"/>
                                    :
                                    <img src="../images/Header/SubscriptionIllustration1.svg" className="subscribe_icon" alt="icon"/>
                                }
                            </div>*/}
                            <div className="col-xs-12 cart_save_text">
                                <img src="/images/Header/gift-svgrepo-com.svg" />Get Free Premium Lens & Free Delivery. Buy Today!
                            </div>
                            <div className="clearfix"></div>
                            <p className="choose_subscription_text">Choose your deal:</p>
                            
                            {/*<div>
                                {this.props.subscription.slice(0).reverse().map((item, i) => (
                                    <div>
                                        <div className={"col-xs-12 " + (this.props.selected_sub==item.id  ? 'active_combo_area' : 'cart_combo_area')} onClick={()=>this.props.change_subscription(item.id)}>
                                            {item.id == 2 ?
                                                <div>
                                                    {this.props.selected_sub==item.id ?
                                                        <p className="cart_most_popular">Most Popular</p>
                                                        :
                                                        <p className="cart_most_popular_disabled">Most Popular</p>
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                            <input type="radio" name="active_combo" className="cart_subscription_radio" checked={this.props.selected_sub === item.id}  onClick={()=>this.props.change_subscription(item.id)} />
                                            <label className={this.props.selected_sub==item.id  ? "combo_label" : "combo_label_disabled"}  onClick={()=>this.props.change_subscription(1)}>
                                                {item.id == 2 ?
                                                    <p className={this.props.selected_sub==item.id  ? 'extra_bold_text cart_oj_super_text' : 'extra_bold_text cart_oj_super_text_disabled'}>OJ SUPER</p>
                                                    :
                                                    <p className={this.props.selected_sub==item.id  ? 'extra_bold_text cart_oj_standard_text' :'extra_bold_text cart_oj_standard_text_disabled'}>Standard Deal</p>
                                                }
                                                <div dangerouslySetInnerHTML={{__html: item.name }}></div>
                                            </label>
                                            <img src="/images/Header/Purple_Tick.svg" className={this.props.selected_sub==item.id  ? 'orange_deal_tag' : 'orange_deal_tag_none'}/>
                                            <br/>
                                            <p className="cart_combo_subtext"  onClick={()=>this.props.change_subscription(item.id)}><p dangerouslySetInnerHTML={{__html: item.description }}></p> {item.id == 2 ? <p className="cart_subscription_learn_more" onClick={this.show_subscription_text}><u> Details </u></p>: null }</p>
                                            {item.id == 2 ?
                                                <div>
                                                    {this.props.selected_sub==item.id  ?
                                                        <img src="/images/Header/Blue_OJSUPERLogo.svg" className="super_ojos_tilted"/>
                                                        :
                                                        <img src="/images/Header/Grey_OJSUPERLogo.svg" className="super_ojos_tilted"/>
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>*/}
                            <div>
                                {this.props.subscription.slice(0).reverse().map((item, i) => (
                                    <div>
                                        <div className={"col-xs-12 padding_is_zero " + (this.props.selected_sub==item.id  ? 'active_sub_plan' : 'inactive_sub_plan')} onClick={()=>this.props.change_subscription(item.id)}>
                                            {item.id == 2 ?
                                                <div>
                                                    {this.props.selected_sub==item.id ?
                                                        <p className="cart_most_popular">Save 30% more with Subscription Plan</p>
                                                        :
                                                        <p className="cart_most_popular_disabled">Save 30% more with Subscription Plan</p>
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                            <div className={"col-xs-3 padding_is_zero " + (this.props.selected_sub==item.id  ? 'cart_sub_left_section_active' : 'cart_sub_left_section_inactive')}>
                                                {item.id == 2 ?
                                                    <center>
                                                        <div className="cart_sub_left_section_textarea">
                                                        <p className={this.props.selected_sub==item.id  ? 'cart_oj_super_text_new' : 'cart_oj_super_text_new_inactive'}>OJ SUPER</p>
                                                        <p><s className="cart_subscription_striked_cost">₹ 5999</s></p>
                                                        <p className="cart_oj_super_subtext_new">₹ {item.price}</p>
                                                        {this.props.selected_sub==item.id  ?
                                                            <img src="/images/Header/Blue_OJSUPERLogo.svg" className="super_ojos_tilted_active"/>
                                                            :
                                                            <img src="/images/Header/Grey_OJSUPERLogo.svg" className="super_ojos_tilted_inactive"/>
                                                        }
                                                        </div>
                                                        <div className="cart_sub_most_popular_div"><p className={this.props.selected_sub==item.id  ? "cart_sub_most_popular_textarea_active" : "cart_sub_most_popular_textarea_inactive"}><img src="/images/Header/Star.svg" /> Most Popular</p></div>
                                                        {this.props.show_lottie ?
                                                            <Lottie 
                                                                options={defaultOptions}
                                                                style={styles.checkAnimation}
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </center>
                                                    :
                                                    <center>
                                                        <div className="cart_sub_left_standard_section_textarea">
                                                            <p className={this.props.selected_sub==item.id  ? 'cart_oj_super_text_new' : 'cart_oj_super_text_new_inactive'}>Standard Deal</p>
                                                            <p><s  className="cart_subscription_striked_cost">₹ 1999</s></p>
                                                            <p className="cart_oj_super_subtext_new">₹ {item.price}</p>
                                                        </div>
                                                    </center>
                                                }
                                            </div>
                                            <div className="col-xs-9 padding_is_zero">
                                                {item.id == 2 ?
                                                    <div className="cart_sub_desc_div">
                                                        <div className="col-xs-4 cart_sub_right_section">
                                                            <p className="cart_sub_right_header">₹ 700</p>
                                                            <p className="cart_sub_right_subtext">effective price per eyeglasses</p>
                                                        </div>
                                                        <div className="col-xs-4 cart_sub_right_section">
                                                            <p className="cart_sub_right_header">3 credits</p>
                                                            <p className="cart_sub_right_subtext">1 credit = 1 eyeglasses</p>
                                                        </div>
                                                        <div className="col-xs-4 cart_sub_right_section">
                                                            <p className="cart_sub_right_header">1 year</p>
                                                            <p className="cart_sub_right_subtext">validity</p>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                        <div className="col-xs-12 cart_sub_desc_div">
                                                            <span className="cart_combo_subtext"><span dangerouslySetInnerHTML={{__html: item.description }}></span> {item.id == 2 ? <span className="cart_subscription_learn_more" onClick={this.show_subscription_text}><u> Details </u></span>: null }</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="cart_sub_desc_div">
                                                        <div className="col-xs-4 cart_sub_desc_div">
                                                            <p className="cart_sub_right_header">₹ {item.price}</p>
                                                            <p className="cart_sub_right_subtext">per eyewear</p>
                                                        </div>
                                                        <div className="col-xs-8 cart_sub_desc_div">
                                                            <span className="cart_combo_subtext"><span dangerouslySetInnerHTML={{__html: item.description }}></span></span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {/*<input type="radio" name="active_combo" className="cart_subscription_radio" checked={this.props.selected_sub === item.id}  onClick={()=>this.props.change_subscription(item.id)} />
                                            <label className={this.props.selected_sub==item.id  ? "combo_label" : "combo_label_disabled"}  onClick={()=>this.props.change_subscription(1)}>
                                                {item.id == 2 ?
                                                    <p className={this.props.selected_sub==item.id  ? 'extra_bold_text cart_oj_super_text' : 'extra_bold_text cart_oj_super_text_disabled'}>OJ SUPER</p>
                                                    :
                                                    <p className={this.props.selected_sub==item.id  ? 'extra_bold_text cart_oj_standard_text' :'extra_bold_text cart_oj_standard_text_disabled'}>Standard Deal</p>
                                                }
                                                <div dangerouslySetInnerHTML={{__html: item.name }}></div>
                                            </label>
                                            <img src="/images/Header/Purple_Tick.svg" className={this.props.selected_sub==item.id  ? 'orange_deal_tag' : 'orange_deal_tag_none'}/>
                                            <br/>
                                            <p className="cart_combo_subtext"  onClick={()=>this.props.change_subscription(item.id)}><p dangerouslySetInnerHTML={{__html: item.description }}></p> {item.id == 2 ? <p className="cart_subscription_learn_more" onClick={this.show_subscription_text}><u> Details </u></p>: null }</p>
                                            */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        null
                        }
                        
                        <div className="clearfix"></div>
                        {/*<div className="col-xs-12 padding_is_zero pdp_buy_more_div">
                            <p className="pdp_buy_more_text">Buy More, Save More</p>
                            <center>
                            <div className="col-xs-4 padding_is_zero">
                                <img src="/images/Header/BuyMoreSaveMoreIcon3.png" className="img-responsive product_details_buy_more_icon"/>
                                <p className="product_details_buy_more_text1">Buy 1 OJ Wear</p>
                                <p className="product_details_buy_more_text2">Get 40% off</p>
                            </div>
                            <div className="col-xs-4 padding_is_zero">
                                <img src="/images/Header/BuyMoreSaveMoreIcon1.png" className="img-responsive product_details_buy_more_icon"/>
                                <p className="product_details_buy_more_text1">Buy 2 OJ Wear</p>
                                <p className="product_details_buy_more_text2">Get 50% off</p>
                            </div>
                            <div className="col-xs-4 padding_is_zero">
                                <img src="/images/Header/BuyMoreSaveMoreIcon2.png" className="img-responsive product_details_buy_more_icon"/>
                                <p className="product_details_buy_more_text1">Buy 3 OJ Wear</p>
                                <p className="product_details_buy_more_text2">Get 55% off</p>
                            </div>
                            </center>
                        </div>
                        <div className="col-xs-12 padding_is_zero">
                            <p className="pdp_coupon_checkout_text">Coupon code available at checkout</p>
                        </div>*/}
                        <div className="clearfix"></div>
                        <div className="col-xs-12 cart_whatsapp_contact">
                        <center>
                            <p>Have a question? <br /> We are here to help</p>
                            <a href="https://wa.me/+917669800711" target="_blank"><button className="cart_whatsapp_button"><img src="/images/Header/icons8-whatsapp-50.png" className="cart_whatsapp_icon"/>WhatsApp Us</button></a>
                        </center>
                        </div>
                    </div>
			);
    }
}

export default CartSubscription;
/*active_combo_area cart_combo_area*/