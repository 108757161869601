import React, { Component } from "react";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import swal from 'sweetalert';
import { Redirect } from 'react-router';

class PowerWebApp extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	token: '',
	    	product_id: '',
	    	order_id: '',
	    	show_cylinder: false,
	    	sph_right: '',
	    	sph_left: '',
	    	cyl_right: '',
	    	cyl_left: '',
	    	axis_right: '',
	    	axis_left: '',
	    	pd_right: '',
	    	pd_left: '',
	    	loading: true,
	    	new_power: true,
	    	select_power: [],
	    	select_power_id: '',
	    	redirect: false,
	    	redirectUrl: '',
	    	cyl_values: [],
	    	pd_values: [],
	    	sph_values: [],
	    	show_power_confirmation: false,
	    	order_prod_id: '',
	    }
	    this.SelectPower = this.SelectPower.bind(this);
	    this.CheckCylinder = this.CheckCylinder.bind(this);
	    this.SubmitPower = this.SubmitPower.bind(this);
	    this.ClosePower = this.ClosePower.bind(this);
	    this.SelectSubmitPower = this.SelectSubmitPower.bind(this);
	    this.AddNewPower = this.AddNewPower.bind(this);
	    this.SubmitPowerConfirmation = this.SubmitPowerConfirmation.bind(this);
	    this.PowerFinalSubmit = this.PowerFinalSubmit.bind(this);
	    this.PowerConfirmation = this.PowerConfirmation.bind(this);
	}
	componentDidMount()
	{
		const access_token = this.props.match.params.access_token;
		const product_id = this.props.match.params.product_id;
		const order_id = this.props.match.params.order_id;
		const order_prod_id = this.props.match.params.order_prod_id;
		var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ access_token);
        var requestOptions = {
            headers: myHeaders,
        };
        fetch(process.env.REACT_APP_API_URL+'/v1/user', requestOptions)
        .then(response => response.json())
        .then(
        (res) => {
            console.log(res);
            this.setState({user_id: res.result.id, product_id: product_id, order_id:order_id, order_prod_id: order_prod_id});
            fetch(process.env.REACT_APP_API_URL+'/v1/user/powers', requestOptions)
	        .then(response => response.json())
	        .then(
	        (data) => {
	            console.log(data);
	            if(data.result.data.length > 0)
	            {
	            	this.setState({new_power: false, select_power: data.result.data, select_power_id: data.result.data[0].id, loading: false});
	            }
	            else
	            {
	            	this.setState({new_power: true, loading: false});
	            }
	        })
        });

        let url1 = process.env.REACT_APP_API_URL+'/v1/product/'+product_id+'/power';
		fetch(url1)
        .then(response => response.json())
        
        .then(data => {
        	console.log(data);
        	if(data.success == true)
        	{
        		this.setState({cyl_values: data.data.cyl_values, pd_values: data.data.pd_values, sph_values: data.data.sph_values}, () => {
					this.setState({new_power: !this.state.new_power, loading: false});
				});
        	}
        	else
        	{
        		swal("Power details not fetched for this product")
        		this.setState({loading: false});
        	}
        })
        this.setState({token: access_token});
	}
	CheckCylinder()
	{
		this.setState({show_cylinder: !this.state.show_cylinder});
	}
	SelectPower = (event) => {
	    let nam = event.target.name;
	    let val = event.target.value;
	    this.setState({[nam]: val});
	}
	ClosePower()
	{
		this.setState({'redirect': true, 'redirectUrl': '/order'});
	}
	AddNewPower()
	{
		this.setState({new_power: !this.state.new_power});
		this.setState({loading: true});
		const product_id = this.props.match.params.product_id;
		const order_id = this.props.match.params.order_id;
		let url = process.env.REACT_APP_API_URL+'/v1/product/'+product_id+'/power';
		fetch(url)
        .then(response => response.json())
        
        .then(data => {
        	console.log(data);
        	if(data.success == true)
        	{
        		this.setState({cyl_values: data.data.cyl_values, pd_values: data.data.pd_values, sph_values: data.data.sph_values}, () => {
					this.setState({new_power: true, loading: false});
				});
        	}
        	else
        	{
        		swal("Power details not fetched for this product")
        		this.setState({loading: false});
        	}
        })
		
	}
	SelectSubmitPower()
	{
		var power_id = this.state.select_power_id;
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer "+ this.state.token);
		var data = JSON.stringify({"power_type" : "single_vision","id" : power_id, "odr_prd_detail_id" : this.state.order_prod_id});
		let url = process.env.REACT_APP_API_URL+'/v2/user/'+this.state.user_id+'/order/'+this.state.order_id+'/product/'+this.state.product_id+'/power';
		var requestOptions = {
			method: 'PUT',
			headers: myHeaders,
			body: data,
			redirect: 'follow'
			    };
		fetch(url, requestOptions)
        .then(response => response.json())
		.then((data) => {
				console.log(data);
				if(data.success == true)
				{
					swal("Eye Power Details Updated.").then(value => {
					console.log(value);
					if(value== true)
					{
						var redirectUrl = '/order-tracker-web/'+this.state.token+'/order_id/'+this.state.order_id+'/product_id/'+this.state.product_id;
						this.setState({'redirect': true, 'redirectUrl': redirectUrl});
					}
				    });
				}
				else
				{
					swal("Sorry couldn't update eye power")
				}
			}
		)
	}
	SubmitPower()
	{
		if((this.state.axis_left  >= 0 && this.state.axis_left <= 180) && (this.state.axis_right  >= 0 && this.state.axis_right <= 180))
		{
			if(this.state.sph_left !='' && this.state.sph_right !='' )
			{
				if((this.state.pd_left.length == 0) && (this.state.pd_right.length == 0) && (this.state.cyl_left.length == 0) && (this.state.cyl_right.length == 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
				{
					this.PowerConfirmation();
				}
				else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length == 0) && (this.state.cyl_right.length == 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
				{
					this.PowerConfirmation();
				}
				else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
				{
					this.PowerConfirmation();
				}
				else if((this.state.pd_left.length == 0) && (this.state.pd_right.length == 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
				{
					this.PowerConfirmation();
				}
				else if((this.state.pd_left.length == 0) && (this.state.pd_right.length == 0) && (this.state.cyl_left.length == 0) && (this.state.cyl_right.length == 0) && (this.state.axis_left.length != 0) && (this.state.axis_right.length != 0))
				{
					this.PowerConfirmation();
				}
				else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length == 0) && (this.state.cyl_right.length == 0) && (this.state.axis_left.length != 0) && (this.state.axis_right.length != 0))
				{
					this.PowerConfirmation();
				}
				else if((this.state.pd_left.length == 0) && (this.state.pd_right.length == 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length != 0) && (this.state.axis_right.length != 0))
				{
					this.PowerConfirmation();
				}
				else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
				{
					this.PowerConfirmation();
				}
				else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length != 0) && (this.state.axis_right.length != 0))
				{
					this.PowerConfirmation();
				}
				else
				{
					swal("Some fields are missing");
				}
		    }
			else
			{
				swal("Select SPH value");
			}
		}
		else
		{
			swal("Axis can be from 0 to 180");
		}
		
	}
	PowerConfirmation()
	{
		this.setState({ show_power_confirmation: !this.state.show_power_confirmation});
	}
	SubmitPowerConfirmation()
	{
		if((this.state.pd_left.length == 0) && (this.state.pd_right.length == 0) && (this.state.cyl_left.length == 0) && (this.state.cyl_right.length == 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
		{
			var data = JSON.stringify({"power_type" : "single_vision","data" : {
	        "left": {
	        		"sph": this.state.sph_left,
		            },
	        "right":{
	        		"sph": this.state.sph_right,
		            }		
        	},
        	"odr_prd_detail_id": this.state.order_prod_id,
        	});
        	this.PowerFinalSubmit(data);
        }
		else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length == 0) && (this.state.cyl_right.length == 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
		{
			var data = JSON.stringify({"power_type" : "single_vision","data" : {
	        "left": {
	        		"sph": this.state.sph_left,
		            "pd": this.state.pd_left
	        		},
	        "right":{
	        		"sph": this.state.sph_right,
		            "pd": this.state.pd_right
	        		}		
        	},
        	"odr_prd_detail_id": this.state.order_prod_id,
        	});
        	this.PowerFinalSubmit(data);
        }
		else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
		{
			var data = JSON.stringify({"power_type" : "single_vision","data" : {
	        "left": {
	        		"sph": this.state.sph_left,
	        		"cyl": this.state.cyl_left,
		            "pd": this.state.pd_left
	        		},
	        "right":{
	        		"sph": this.state.sph_right,
	        		"cyl": this.state.cyl_right,
		            "pd": this.state.pd_right
	        		}		
        	},
        	"odr_prd_detail_id": this.state.order_prod_id,
        	});
        	this.PowerFinalSubmit(data);
        }
		else if((this.state.pd_left.length == 0) && (this.state.pd_right.length == 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
		{
			var data = JSON.stringify({"power_type" : "single_vision","data" : {
	        "left": {
	        		"sph": this.state.sph_left,
	        		"cyl": this.state.cyl_left,
		       		},
	        "right":{
	        		"sph": this.state.sph_right,
	        		"cyl": this.state.cyl_right,
		            }		
        	},
        	"odr_prd_detail_id": this.state.order_prod_id,
        	});
        	this.PowerFinalSubmit(data);
        }
		else if((this.state.pd_left.length == 0) && (this.state.pd_right.length == 0) && (this.state.cyl_left.length == 0) && (this.state.cyl_right.length == 0) && (this.state.axis_left.length != 0) && (this.state.axis_right.length != 0))
		{
			var data = JSON.stringify({"power_type" : "single_vision","data" : {
	        "left": {
	        		"axis": this.state.axis_left,
	        		"sph": this.state.sph_left,
	        		},
	        "right":{
	        		"axis": this.state.axis_right,
	        		"sph": this.state.sph_right,
	        		}		
        	},
        	"odr_prd_detail_id": this.state.order_prod_id,
        	});
        	this.PowerFinalSubmit(data);
        }
		else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length == 0) && (this.state.cyl_right.length == 0) && (this.state.axis_left.length != 0) && (this.state.axis_right.length != 0))
		{
			var data = JSON.stringify({"power_type" : "single_vision","data" : {
	        "left": {
	        		"axis": this.state.axis_left,
	        		"sph": this.state.sph_left,
	        		"pd": this.state.pd_left
	        		},
	        "right":{
	        		"axis": this.state.axis_right,
	        		"sph": this.state.sph_right,
	        		"pd": this.state.pd_right
	        		}		
        	},
        	"odr_prd_detail_id": this.state.order_prod_id,
        	});
        	this.PowerFinalSubmit(data);
        }
		else if((this.state.pd_left.length == 0) && (this.state.pd_right.length == 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length != 0) && (this.state.axis_right.length != 0))
		{
			var data = JSON.stringify({"power_type" : "single_vision","data" : {
	        "left": {
	        		"axis": this.state.axis_left,
	        		"sph": this.state.sph_left,
	        		"cyl": this.state.cyl_left
	        		},
	        "right":{
	        		"axis": this.state.axis_right,
	        		"sph": this.state.sph_right,
	        		"cyl": this.state.cyl_right
	        		}		
        	},
        	"odr_prd_detail_id": this.state.order_prod_id,
        	});
        	this.PowerFinalSubmit(data);
        }
		else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length == 0) && (this.state.axis_right.length == 0))
		{
			var data = JSON.stringify({"power_type" : "single_vision","data" : {
	        "left": {
	        		"sph": this.state.sph_left,
	        		"cyl": this.state.cyl_left,
	        		"pd": this.state.pd_left
	        		},
	        "right":{
	        		"sph": this.state.sph_right,
	        		"cyl": this.state.cyl_right,
	        		"pd": this.state.pd_right
	        		}		
        	},
        	"odr_prd_detail_id": this.state.order_prod_id,
        	});
        	this.PowerFinalSubmit(data);
        }
		else if((this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) && (this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) && (this.state.axis_left.length != 0) && (this.state.axis_right.length != 0))
		{
			var data = JSON.stringify({"power_type" : "single_vision","data" : {
	        "left": {
	        		"axis": this.state.axis_left,
	        		"sph": this.state.sph_left,
	        		"cyl": this.state.cyl_left,
	        		"pd": this.state.pd_left
	        		},
	        "right":{
	        		"axis": this.state.axis_right,
	        		"sph": this.state.sph_right,
	        		"cyl": this.state.cyl_right,
	        		"pd": this.state.pd_right
	        		}		
        	},
        	"odr_prd_detail_id": this.state.order_prod_id,
        	});
        	this.PowerFinalSubmit(data);
        }
		else
		{
			swal("Some fields are missing");
		}
	}
	PowerFinalSubmit(data)
	{
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer "+ this.state.token);
		console.log(data);
		let url = process.env.REACT_APP_API_URL+'/v2/user/'+this.state.user_id+'/order/'+this.state.order_id+'/product/'+this.state.product_id+'/power';
		var requestOptions = {
			method: 'PUT',
			headers: myHeaders,
			body: data,
			redirect: 'follow'
			    };
		fetch(url, requestOptions)
        .then(response => response.json())
		.then((data) => {
			console.log(data);
			if(data.success == true)
			{
				var redirectUrl = '/order-tracker-web/'+this.state.token+'/order_id/'+this.state.order_id+'/product_id/'+this.state.product_id;
				this.setState({'redirect': true, 'redirectUrl': redirectUrl});
				swal("Eye Power Details Updated.")	
			}
			else
			{
				swal("Sorry couldn't update eye power")
			}
		})
	}
	HandleOnChange(id) {
    	var selected_option = id;
    	var power_id = selected_option.substring(6);
    	this.setState({ select_power_id: power_id});
  	}
	render() {
		if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
		return (

			<div>
			{!this.state.loading ?
				<div>
					{this.state.new_power ? 
						<div className="">
							<div id="myModal" className="modal-edited modal" data-spy="scroll"  data-offset="20">
								<div className="modal-content-app modal-address modal-content-edited modal-content">
									<div className="col-sm-3"></div>
									<div className="col-sm-6 padding_is_zero">
										<div className="address-modal-header modal-header-edited modal-header">
									    	<div className="col-xs-12 add_address_header">
									    		<center><p className="header_address_text">Enter Eye Power Details</p></center>
								    		</div>
					    				</div>
					    				<div className="power_modal_body modal-body-edited modal-body">
					    					<div className="add_address_body">
								    			{/*<p className="col-xs-12 power_text">Enter your eye power details</p>*/}
								    			<div className="col-xs-12 power_option_section">
									    			<p className="power_subtext">POWER (SPH)</p>
									    			<div className="col-xs-6">
									    				<p className="power_left_right">LEFT</p>
									    				<select id="sph_left" name="sph_left" className="power_box" onChange={this.SelectPower}>
											    			<option value="0">Select</option>
											    			{this.state.sph_values.map((each_item, index) => (
											    				<option value={each_item}>{each_item}</option>
											    			))}
											    			
														</select>
									    			</div>
									    			<div className="col-xs-6">
									    				<p className="power_left_right">RIGHT</p>
									    				<select id="sph_right" name="sph_right" className="power_box" onChange={this.SelectPower}>
											    			<option value="0">Select</option>
											    			{this.state.sph_values.map((each_item, index) => (
											    				<option value={each_item}>{each_item}</option>
											    			))}
														</select>
									    			</div>
								    			</div>
								    			<div className="col-xs-12 power_option_section">
									    			<p className="power_subtext_pd">PD </p>
									    			<p className="power_optional_text">(optional)</p>
									    			<div className="col-xs-6">
									    				<p className="power_left_right">LEFT</p>
									    				{/*<input type="text" name="pd_left" className="power_box" onChange={this.SelectPower}/>*/}
									    				<select  name="pd_left" className="power_box" onChange={this.SelectPower}>
											    			<option value="0">Select</option>
											    			{this.state.pd_values.map((each_item, index) => (
											    				<option value={each_item}>{each_item}</option>
											    			))}
														</select>
									    			</div>
									    			<div className="col-xs-6">
									    				<p className="power_left_right">RIGHT</p>
									    				{/*<input type="text" name="pd_right" className="power_box" onChange={this.SelectPower}/>*/}
									    				<select  name="pd_right" className="power_box" onChange={this.SelectPower}>
											    			<option value="0">Select</option>
											    			{this.state.pd_values.map((each_item, index) => (
											    				<option value={each_item}>{each_item}</option>
											    			))}
														</select>
									    			</div>
								    			</div>
								    			{this.state.show_cylinder ?
								    				<div>
									    				<div className="col-xs-12 power_option_section">
											    			<p className="power_subtext">CYLINDER</p>
											    			<div className="col-xs-6">
											    				<p className="power_left_right">LEFT</p>
											    				<select id="cyl_left" name="cyl_left" className="power_box" onChange={this.SelectPower}>
													    			<option value="0">Select</option>
																  	{this.state.cyl_values.map((each_item, index) => (
													    				<option value={each_item}>{each_item}</option>
													    			))}
																</select>
											    			</div>
											    			<div className="col-xs-6">
											    				<p className="power_left_right">RIGHT</p>
											    				<select id="cyl_right" name="cyl_right" className="power_box" onChange={this.SelectPower}>
													    			<option value="0">Select</option>
																  	{this.state.cyl_values.map((each_item, index) => (
													    				<option value={each_item}>{each_item}</option>
													    			))}
																</select>
											    			</div>
										    			</div>
										    			<div className="col-xs-12 power_option_section">
											    			<p className="power_subtext">AXIS <small>(value ranging from 0 to 180 degrees)</small></p>
											    			<div className="col-xs-6">
											    				<p className="power_left_right">LEFT</p>
											    				<input type="text" name="axis_left" className="power_box" onChange={this.SelectPower}/>
											    			</div>
											    			<div className="col-xs-6">
											    				<p className="power_left_right">RIGHT</p>
											    				<input type="text" name="axis_right" className="power_box" onChange={this.SelectPower}/>
											    			</div>
										    			</div>
									    			</div>
									    			:
									    			null
								    			}
								    			<div className="col-xs-12">
								    				<input type="checkbox" id="cylinder" name="cylinder" value="cylinder" className="cylinder_checkbox" onClick={this.CheckCylinder}/>
													<label for="cylinder" className="cylinder_checkbox_text"> I have cylinder power too</label>
								    			</div>
								    		</div>
										</div>
					    				<div className="col-xs-12 col-sm-6 modal-footer-edited modal-footer">
					    					<center><button className="save_address_button" onClick={this.SubmitPower}>Submit Eye Power</button></center>
								    	</div>
							    	</div>
							    	<div className="col-sm-3"></div>
			    				</div>
			    			</div>
			    			{this.state.show_power_confirmation ?
			    				<div className="select-package-dialog-background modal-backdrop">
				                    <div class="select-package-dialog modal-dialog">
				                        <div className="modal-edited modal" id="lab-slide-bottom-popup-power" data-keyboard="false" data-backdrop="false">
				                            <div class="lab-modal-body">
				                            	<div className="col-sm-3"></div>
				                            	<div className="col-xs-12 col-sm-6">
												<p className="lense_selection_header">Are you sure you want to submit your power details?</p>
												<div className="col-xs-12 show_power_confirmed_div">
													<center>
													<table>
														<tr>
															<th className="power_confirmation_header"></th>
														   	<th className="power_confirmation_header">SPH</th>
														   	{(this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) ?
														   		<th className="power_confirmation_header">PD</th>
														   		:
														   		null
														   	}
														   	{(this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) ?
														   		<th className="power_confirmation_header">CYL</th>
														   		:
														   		null
														   	}
														   	{(this.state.axis_left.length != 0) && (this.state.axis_right.length != 0) ?
														   		<th className="power_confirmation_header">AXIS</th>
														   		:
														   		null
														   	}
														</tr>
														<tr>
														   	<th className="power_confirmation_header_col">L</th>
														   	<td className="power_confirmed_text">{this.state.sph_left}</td>
														   	{(this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) ?
														   		<td className="power_confirmed_text">{this.state.pd_left}</td>
														   		:
														   		null
														   	}
														   	{(this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) ?
														   		<td className="power_confirmed_text">{this.state.cyl_left}</td>
														   		:
														   		null
														   	}
														   	{(this.state.axis_left.length != 0) && (this.state.axis_right.length != 0) ?
														   		<td className="power_confirmed_text">{this.state.axis_left}</td>
														   		:
														   		null
														   	}
														</tr>
														<tr>
														   	<th className="power_confirmation_header_col">R</th>
														   	<td className="power_confirmed_text">{this.state.sph_right}</td>
														   	{(this.state.pd_left.length != 0) && (this.state.pd_right.length != 0) ?
														   		<td className="power_confirmed_text">{this.state.pd_right}</td>
														   		:
														   		null
														   	}
														   	{(this.state.cyl_left.length != 0) && (this.state.cyl_right.length != 0) ?
														   		<td className="power_confirmed_text">{this.state.cyl_right}</td>
														   		:
														   		null
														   	}
														   	{(this.state.axis_left.length != 0) && (this.state.axis_right.length != 0) ?
														   		<td className="power_confirmed_text">{this.state.axis_right}</td>
														   		:
														   		null
														   	}
														</tr>
													</table>
													</center>
												</div>
												<div className="clearfix"></div>
												<center><p className="power_final_edit_text">You cannot edit the details once submitted</p></center>
												<div className="col-xs-6">
													<button className="power_edit_button_confirmation" onClick={this.PowerConfirmation}>EDIT</button>
												</div>
												<div className="col-xs-6">
													<button className="power_submit_button_confirmation" onClick={this.SubmitPowerConfirmation}>SUBMIT</button>
												</div>
												<div className="clearfix"></div>
												</div>
												
												<div className="col-sm-3"></div>
											</div>
										</div>
									</div>
								</div>
								:
								null
			    			}
			    		</div>
			    		: 
			    		<div className="select_address">
			    			<div id="myModal" className="modal-edited modal" data-spy="scroll"  data-offset="20">
								<div className="modal-content-app modal-address modal-content-edited modal-content">
								<div className="col-sm-3"></div>
									<div className="col-sm-6 padding_is_zero">
										<div className="address-modal-header modal-header-edited modal-header">
								    		<div className="col-xs-12 add_address_header">
								    			<center><p className="header_address_text">Select Eye Power</p></center>
								    		</div>
								    	</div>
								    	<div className="power-modal-body modal-body-edited modal-body">
								    		{/*<p className="select_address_text">SELECT YOUR POWER</p>*/}
								    		{this.state.select_power.map((item, i) => (
								    			<div className="col-xs-12 each_select_address" key={i} onClick={()=>this.HandleOnChange("power_"+item.id)}>
						    						<input type="radio"  name="active_combo" checked = {this.state.select_power_id == item.id}/>
													<label className="select_address_label">SINGLE VISION</label>
													<br/>
													<div className="col-sm-6 col-xs-6">
														<p className="select_power_subtext"><u>Left</u><br/> Spherical: {item.left.sph}<br/> Cylinderical: {item.left.cyl}<br/> PD: {item.left.pd} <br/> Axis: {item.left.axis}</p>
													</div>
													<div className="col-sm-6 col-xs-6">
														<p className="select_power_subtext"><u>Right</u><br/> Spherical: {item.right.sph}<br/> Cylinderical: {item.right.cyl}<br/> PD: {item.right.pd} <br/> Axis: {item.right.axis}</p>
													</div>
												</div>
								    		))}
								    		<div className="col-xs-12 select_add_address_div">
					    						<a data-toggle="collapse" href="#collapse1" ><p className="new_address_accordion" onClick={this.AddNewPower}>ADD NEW EYE POWER</p></a>
					    					</div>
								    	</div>
								    	<div className="col-xs-12 col-sm-6 modal-footer-edited modal-footer">
								    		<center><button className="save_address_button" onClick={this.SelectSubmitPower}>Select This Eye Power</button></center>
					    				</div>
				    				</div>
				    				<div className="col-sm-3"></div>
								</div>
							</div>
						</div>
					}
				</div>
				:
				<div> 
	      			<Loading />
	      		</div>
			}
			</div>
		);
	}
}


export default PowerWebApp;
