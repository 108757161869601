import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import StarRating from "./StarRating";

export function SubmitRating({ productDetails, orderDetails }) {
  const backdropStyle = { opacity: 0.5 };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [filledStars, setFilledStars] = useState("");
  const handleFilledStars = (data) => {
    setFilledStars(data);
  };
  const [showModal, setShowModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true);
    setFilledStars(0);
    reset();
    setValidationError(null);
    setFiles([]);
    setIsSubmitted(false);
  };
  const [files, setFiles] = useState([]);
  const [validationError, setValidationError] = useState(null);
  const onChange = (e) => {
    const files = e.target.files;
    const filesArr = Array.prototype.slice.call(files);

    // Check that each file has a valid extension
    const allowedExtensions = [".jpg", ".jpeg", ".png"];
    const invalidFiles = filesArr.filter(
      (file) => !allowedExtensions.includes(file.name.slice(-4).toLowerCase())
    );
    if (invalidFiles.length > 0) {
      const invalidFileNames = invalidFiles.map((file) => file.name).join(", ");
      setValidationError(`Invalid files: ${invalidFileNames}`);
      return;
    } else {
      setValidationError(null);
    }

    // Check that the total file size does not exceed 10MB
    const totalSize = filesArr.reduce((acc, file) => acc + file.size, 0);
    const maxSize = 10 * 1024 * 1024; // 10MB
    if (totalSize > maxSize) {
      setValidationError("Total file size exceeds 1MB");
      return;
    } else {
      setValidationError(null);
    }
    setFiles([...filesArr]);
  };
  const removeFile = (f) => {
    setFiles(files.filter((x) => x !== f));
  };
  useEffect(() => {
    console.log(productDetails);
  })
  const onSubmit = (data) => {
    const formData = new FormData();
    // let filesArr = [];
    let filesArr = Array.prototype.slice.call(files);

    for (let i = 0; i < filesArr.length; i++) {
      formData.append("files[]", filesArr[i]);
    }
    if (filledStars == 0) {
      setValidationError("Please give rating to select stars");
      return;
    }
    formData.append("title", data.reviewTitle);
    formData.append("prod_color_id", data.prodColorId);
    formData.append("description", data.reviewDesc);
    formData.append("no_of_stars", filledStars);

    const access_token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);
    let url = process.env.REACT_APP_API_URL + "/v3/saveReview";
    console.log(formData, "this is");
    fetch(url, {
      method: "POST",
      body: formData,
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        console.log("Files uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
    setIsSubmitted(true);
    console.log(data);
  };

  return (
    <>
      {" "}
      <button className="prescription_button" onClick={handleShow} style={{color: "#fff"}}> WRITE REVIEW</button>
      
      
      <Modal
        size="md"
        show={showModal}
        onHide={handleClose}
        style={{
          opacity: "1",
          marginTop: "100px",
          ModalBackdrop: backdropStyle,
          width: "100%",
        }}
        className="modal-review fade-in"
      >
        <Modal.Header>
          <Modal.Title>
            <center>ORDER REVIEW</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
          
          <div className="">
            <div className="col-xs-4">
              <center>
                <p className="exchange_header_order_details">Order ID</p>
                <p>{orderDetails.hashed_id}</p>
              </center>
            </div>
            <div className="col-xs-4">
              <center>
                <p className="exchange_header_order_details">Purchased Via</p>
                <p>{orderDetails.payment_method}</p>
              </center>
            </div>
            <div className="col-xs-4">
              <center>
                <p className="exchange_header_order_details">Amount Paid</p>
                <p>{orderDetails.total_cost}</p>
              </center>
              
            </div>
            <div className="col-xs-12">
              <div className="col-xs-12 review_product_details_section">
                <img style={{width: "50%"}} src={productDetails ? productDetails.image : ""} className="img-responsive"/>
                
                <div className="exchange_product_name_section">
                  <p className="product_details_full_name_new">{productDetails ? productDetails.name : ""}</p>
                  <p className="product_details_new_size">{productDetails.gender} | {productDetails.size}</p>
                  {productDetails.power_type == 'single_vision' ?
                    <p className="product_details_new_size">SINGLE VISION</p>
                    :
                    <div>
                      {productDetails.power_type == 'single_vision_bluecut' ?
                        <p className="product_details_new_size">SINGLE VISION BLU CUT</p>
                        :
                        <p className="product_details_new_size">ZERO POWER</p>
                      }
                      </div>
                  }
                </div>
                
              </div>
            </div>
          </div>
          {isSubmitted ? (
            <div style={{ textAlign: "center", marginTop: "100px" }}>
              <h4>Thank you for your valuable feedback</h4>
              <p>Your review has been submitted</p>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="review_rating_form">
              <StarRating
                totalStars={5}
                filledStars={filledStars}
                onStarSelect={handleFilledStars}
              ></StarRating>

              <input
                {...register("reviewTitle", {
                  required: true,
                  maxLength: 100,
                })}
                placeholder="Sum up in one word"
                style={{ marginTop: "10px" }}
                className="review_rating_form_input"
                maxLength={"35"}
              />
              {errors?.reviewTitle?.type === "required" && (
                <p className="error-message">This field is required</p>
              )}
              {errors?.reviewTitle?.type === "maxLength" && (
                <p className="error-message">
                  Title cannot exceed 100 characters
                </p>
              )}

              <input
                {...register("prodColorId")}
                type="hidden"
                value={
                  productDetails
                    ? productDetails.color_id
                    : ""
                }
                className="review_rating_form_input"
              />
              <textarea
                {...register("reviewDesc")}
                placeholder="write review"
                className="review_rating_form_input"
              />
              <label className="custom-file-upload">
                <input
                  {...register("file")}
                  type="file"
                  placeholder="Sum up in one word"
                  onChange={onChange}
                  multiple
                  // onChange={onChange}
                  className="review_rating_form_input"
                />
                <i className="fa fa-cloud-upload" /> Attach Image
              </label>
              {validationError && (
                <div className="error-message">{validationError}</div>
              )}
              {files.map((x) => (
                <div className="file-preview" onClick={() => removeFile(x)}>
                  {x.name}
                </div>
              ))}
              {/* <p>{data}</p> */}
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  SUBMIT REVIEW
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
