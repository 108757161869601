import React, { Component } from 'react';

class EmptyCart extends Component 
{    
    constructor(props) {
        super(props);
    }


    render() { 
            return(
                    <div className="col-xs-12 empty_cart_div">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                        <center><img src="/images/Header/Lonely_Cat.svg" /></center>
                        <center><p className="no_product_text">Your cart seems blurry! <br />Add products and come back here, to make them yours! </p></center>
                        {/*<CartSubscription change_subscription={this.ChangeSubscription} selected_sub={this.state.selected_sub} subscription={this.state.subscription}/>*/}
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
			);
    }
}

export default EmptyCart;
