import React, { Component } from 'react';
import { RWebShare } from "react-web-share";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Lottie from 'react-lottie';
import animationData from '../Lotties/ojos_loading.json';

class ImageViewDesktop extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
          loading: true,
          loading_lottie_web: this.props.loading_lottie_web,
        }
        this.ImageLoaded = this.ImageLoaded.bind(this);
    }
    ImageLoaded()
    {
        //this.setState({loading: false});
    }
    componentDidMount()
    {
        
    }
    render() { 
        console.log(this.props.choosen_index);
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
              };
            const styles = {
              checkAnimation: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  minHeight: '50vh',
              },
            }
			return(
                        <div className="image_section">
                            <div className="img_model">
                                <div className="carousel_product_listing_web carousel" aria-label="Gallery" data-interval="false">
                                    
                                        <ol className="carousel__viewport">
                                            {this.props.item.glass_images.map((image_item, index) => (
                                                <li id={"carousel__slide"+index}
                                                tabIndex={index} key={index}
                                                className="carousel__slide">
                                                    <div className="carousel__snapper">
                                                        <RWebShare
                                                            data={{
                                                              text: this.props.item.brand_name,
                                                              url: "https://ojoswear.com/product/"+ this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id,
                                                              title: this.props.item.brand_name,
                                                            }}
                                                            onClick={() => console.log("shared successfully!")}
                                                        >
                                                        <img src="/images/Header/Share_Icon_ListingPage.svg" className="share_icon_web" alt="share_icon" />
                                                        </RWebShare>
                                                        <div>
                                                            {this.props.path_source == ''?
                                                                <div>
                                                                    {this.props.i == this.props.choosen_index ?
                                                                        <div>
                                                                            <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                            <Link to={"/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <Link to={"/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id}><img src={image_item.url} onLoad={this.ImageLoaded} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                        </div>
                                                                    }
                                                                    
                                                                </div>
                                                                :
                                                                <div>
                                                                    {this.props.i == this.props.choosen_index ?
                                                                        <div>
                                                                            <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                            <Link to={"/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?path_source="+this.props.path_source}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <Link to={"/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?path_source="+this.props.path_source}><img src={image_item.url} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ol>
                                    
                                </div>

                            </div>
                            {this.props.item.model_images.length > 0 ?
                                <div className="img_thumbnail">
                                    <img className="expand_icon_web" src="/images/Header/Expand_Thumbnail_icon_ListingPage.svg" alt="expand_icon_web" />
                                    <img className="img-rounded product_thumbnail_web" src={this.props.item.model_images[0].url} onClick={() => this.props.change_picture(this.props.i)} alt="frame_img_web"/> 
                                </div> 
                                :
                                null
                            }
                        </div> 
			);
    }
}

export default ImageViewDesktop;

/*
<div className="carousel" aria-label="Gallery" data-interval="false">
                                        <ol className="carousel__viewport">
                                            {this.props.item.model_images.map((image_item, index) => (
                                                <li id={"carousel__slide"+index}
                                                tabIndex={index} key={index}
                                                className="carousel__slide">
                                                    <div className="carousel__snapper">
                                                        <RWebShare
                                                            data={{
                                                              text: this.props.item.brand_name,
                                                              url: "https://ojos21.com/product/"+ this.props.item.product_id,
                                                              title: this.props.item.brand_name,
                                                            }}
                                                            onClick={() => console.log("shared successfully!")}
                                                        >
                                                            <img src="../images/Header/Share_Icon_ListingPage.svg" className="share_icon" alt="share_icon" />
                                                        </RWebShare>
                                                        <img src={image_item.url} className="img-responsive" id="frame_img" name="frame_img" alt="frame_img"/>
                                                    </div>
                                                </li>
                                            ))}
                                        </ol>
                                </div>
                                */
/*
<div id="myCarousel" class="carousel slide" data-ride="carousel">
    
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>

    
    <div class="carousel-inner">
      <div class="item active">
        <img src="https://static1.lenskart.com/media/catalog/product/cache/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/e/lenskart-air-new-la-e13284-c1-eyeglasses_19_may_male_shoot-841.jpg" alt="Los Angeles" />
      </div>

      <div class="item">
        <img src="https://static1.lenskart.com/media/catalog/product/cache/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/e/lenskart-air-new-la-e13284-c1-eyeglasses_19_may_male_shoot-841.jpgg" alt="Chicago" />
      </div>
    
      <div class="item">
        <img src="https://static1.lenskart.com/media/catalog/product/cache/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/e/lenskart-air-new-la-e13284-c1-eyeglasses_19_may_male_shoot-841.jpg" alt="New york" />
      </div>
    </div>

    
    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>*/