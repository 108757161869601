export const FETCH_PRODUCTS_BEGIN   = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const fetchProductsBegin = () => ({
    type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: { products }
});

export const fetchProductsFailure = error => ({
    type: FETCH_PRODUCTS_FAILURE,
    payload: { error }
});
export function FetchEachProductDetails(product_id,color_id,gender,path_source) {
    if(gender == "Unisex") 
    {
        if(path_source == "female_listing")
        {
            var gender_value = path_source.substring(0, 6);
            var url = process.env.REACT_APP_API_URL+"/v1/product/"+product_id+"/colorid/"+color_id+"?gender="+gender_value;
        }
        else if(path_source == "male_listing")
        {
            var gender_value = path_source.substring(0, 4);
            var url = process.env.REACT_APP_API_URL+"/v1/product/"+product_id+"/colorid/"+color_id+"?gender="+gender_value;
        }
        else
        {
            var url = process.env.REACT_APP_API_URL+"/v1/product/"+product_id+"/colorid/"+color_id;
        }
        
    }
    else
    {
        var url = process.env.REACT_APP_API_URL+"/v1/product/"+product_id+"/colorid/"+color_id;
    }
    return dispatch => {
        dispatch(fetchProductsBegin());
        return fetch(url)
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            console.log(json.result);
            dispatch(fetchProductsSuccess(json.result));
            return json.products;
          })
        .catch(error => dispatch(fetchProductsFailure(error)));
    };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}