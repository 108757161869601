import React, { Component } from "react";
import { Link } from 'react-router-dom';

class OrderFilter extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
    render() {
		return(
            <div>
                <div className="col-sm-3"></div>
    			<div className="col-xs-12 col-sm-6 padding_is_zero order_filter_div">
                    <div className="col-xs-6 col-sm-6">
                        <p className="filter_text">Showing <b>All Orders</b></p>
                    </div>
                    <div className="col-xs-6 col-sm-6 dropdown">
                        {/*<img src="../images/Header/Filter_Icon.svg" className="order_filter_icon dropbtn"/>
                        <div class="dropdown-content">
                            <a href="#">Link 1</a>
                            <a href="#">Link 2</a>
                            <a href="#">Link 3</a>
                          </div>*/}
                    </div>
                </div>
                <div className="col-sm-3"></div>
            </div>
        );
		
	}
}

export default OrderFilter;