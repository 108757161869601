import React, { Component } from "react";

class ProductCostSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.props.pdp_coupon_mob)
    return (
      <div className="col-xs-12 product_details_horizontal_line_up product_details_border_bottom">
        {/*<div className="col-xs-12 product_details_lenskart_section hidden-sm hidden-lg hidden-md">
                            <div className="col-xs-2"><img src="/images/Header/LK_Monogram.svg" className="product_details_tag_img_lenskart"/></div>
                            <div className="col-xs-10 product_details_cost_text_section">
                                <p className="product_details_lenskart_header">OJOS Wear - A Lenskart Alliance</p>
                                <p className="product_details_lenskart_subtext">OJOS Wear is a new and vibrant collection brand by Lenskart. Shop from the range of stylish and quirky eyewear, curated for the GenZ, who love luxe styles at an affordable price!</p>
                            </div>
                            
                        </div>
                        <div className="col-xs-12 product_details_lenskart_section hidden-xs">
                            <div className="col-xs-2"><img src="/images/Header/LK_Monogram.svg" className="product_details_tag_img_desktop"/></div>
                            <div className="col-xs-10 product_details_cost_text_section">
                                <p className="product_details_lenskart_header_desktop">OJOS Wear - A Lenskart Alliance</p>
                                <p className="product_details_lenskart_subtext_desktop">OJOS Wear is a new and vibrant collection brand by Lenskart. Shop from the range of stylish and quirky eyewear, curated for the GenZ, who love luxe styles at an affordable price!</p>
                            </div>
                            
                        </div>*/}
        {/*<div className="col-xs-12 product_details_cost_section hidden-sm hidden-lg hidden-md">
                            <div className="col-xs-2"><img src="/images/Header/price_tag.svg" className="product_details_tag_img"/></div>
                            <div className="col-xs-10 product_details_cost_text_section">
                                <p className="product_details_cost_header">Pick the best price at the next step!</p>
                                <p className="product_details_cost_subtext">Guess what, all the products have the same price. No ups and downs. Pick your favourite frames fitted with free premium lenses at no additional cost!</p>
                            </div>
                            
                        </div>
                        <div className="col-xs-12 product_details_cost_section hidden-xs">
                            <div className="col-xs-2"><img src="/images/Header/price_tag.svg" className="product_details_tag_img_desktop"/></div>
                            <div className="col-xs-10 product_details_cost_text_section">
                                <p className="product_details_cost_header_desktop">Pick the best price at the next step!</p>
                                <p className="product_details_cost_subtext_desktop">Guess what, all the products have the same price. No ups and downs. Pick your favourite frames fitted with free premium lenses at no additional cost!</p>
                            </div>
                        </div>*/}
        {/*<div className="col-xs-12 banner_discount_coupon_div">
                            <div className="col-xs-12 banner_discount_coupon_new padding_is_zero">
                                <img src="/images/Header/discount-svgrepo-com-green.svg" className=""/>
                                <p className="banner_discount_coupon_text"><b className="banner_discount_bold_text">Flat 50% off</b> <br /> Use code <b className="banner_discount_bold_text">OJOS50</b> | Valid on all products | Discount ends tonight!</p>
                            </div>
                        </div>*/}

        {this.props.show_pdp_coupon_web ?
            <div>
               {this.props.pdp_coupon_web.map((details, index) => (
                    <div className="col-xs-12 hidden-xs">
                        <img src={details.banner_for_desktop} className="product_coupon_banner img-responsive"width="100%"/> 
                    </div>
                ))}
            </div>
            :
            null
        }
        {this.props.show_pdp_coupon_mob ?
            <div>
               {this.props.pdp_coupon_mob.map((details, index) => (
                    <div className="col-xs-12 hidden-sm hidden-lg hidden-md ">
                        <img src={details.banner_for_mobile_web} className="product_coupon_banner img-responsive"width="100%"/> 
                    </div>
                ))}
            </div>
            :
            null
        }
        

        {/*<div className="col-xs-12 padding_is_zero pdp_buy_more_div">
                            <p className="pdp_buy_more_text">Buy More, Save More</p>
                            <center>
                            <div className="col-xs-4 padding_is_zero">
                                <img src="/images/Header/BuyMoreSaveMoreIcon3.png" className="img-responsive product_details_buy_more_icon"/>
                                <p className="product_details_buy_more_text1">Buy 1 OJ Wear</p>
                                <p className="product_details_buy_more_text2">Get 40% off</p>
                            </div>
                            <div className="col-xs-4 padding_is_zero">
                                <img src="/images/Header/BuyMoreSaveMoreIcon1.png" className="img-responsive product_details_buy_more_icon"/>
                                <p className="product_details_buy_more_text1">Buy 2 OJ Wear</p>
                                <p className="product_details_buy_more_text2">Get 50% off</p>
                            </div>
                            <div className="col-xs-4 padding_is_zero">
                                <img src="/images/Header/BuyMoreSaveMoreIcon2.png" className="img-responsive product_details_buy_more_icon"/>
                                <p className="product_details_buy_more_text1">Buy 3 OJ Wear</p>
                                <p className="product_details_buy_more_text2">Get 55% off</p>
                            </div>
                            </center>
                        </div>
                        <div className="col-xs-12 padding_is_zero">
                            <p className="pdp_coupon_checkout_text">Coupon code available at checkout</p>
                        </div>*/}
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default ProductCostSection;
