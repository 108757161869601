import React, { Component } from "react";
import ProductShowCaseImage from "./ProductShowCaseImage";
import FsLightbox from "fslightbox-react";
import Lightbox from "react-image-lightbox";

const boxcontainer = {
  display: "flex",
  flexDirection: "row",
  width: "100% ",
  height: "600px",
  marginBottom: "10px",
  padding: "0px",
};
const leftboxcontainer = {
  height: "100% ",
  width: "40%",
  display: "flex",
  justifyContent: "center",
  align: "center",
  padding: "0px",
  marginRight: "10px",
};
const rightboxcontainer = {
  display: "flex",
  flexDirection: "row ",
  height: "100% ",
  width: "60% ",
};
const rightboxleft = {
  display: "flex",
  flexDirection: "column ",
  height: "100% ",
  width: "35% ",
};
const rightboxcenter = {
  display: "flex",
  flexDirection: "column ",
  height: "100% ",
  width: "30% ",
  marginLeft: "10px",
  marginRight: "10px",
};
const lightboxbutton = {
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  border: "2px solid #40566C",
  borderRadius: "10px",
  position: "absolute",
  right: "30px",
  bottom: "30px",
  width: "173px",
  height: "46px",
};

export default class ProductImagesShowCase extends Component {
  constructor() {
    super();
    this.state = {
      firstImg: "",
      lightboxImg: [],
      glassImg: [],
      colorId: "",
      toggler: false,
      photoIndex: 0,
      isOpen: false,
    };
  }
  componentDidMount() {
    this.renderImages(this.props.product_details);
  }
  componentDidUpdate(prevProps, preState) {
    if (
      this.state.colorId !=
      this.props.product_details.specifications.color_prod_id
    ) {
      this.renderImages(this.props.product_details);
    }
  }
  render() {
    return (
      <div style={boxcontainer}>
        {/* <FsLightbox
          toggler={this.state.toggler}
          sources={this.state.lightboxImg}
          type="image"
        /> */}
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.state.lightboxImg[this.state.photoIndex]}
            nextSrc={
              this.state.lightboxImg[
                (this.state.photoIndex + 1) % this.state.lightboxImg.length
              ]
            }
            prevSrc={
              this.state.lightboxImg[
                (this.state.photoIndex + this.state.lightboxImg.length - 1) %
                  this.state.lightboxImg.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + this.state.lightboxImg.length - 1) %
                  this.state.lightboxImg.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + 1) % this.state.lightboxImg.length,
              })
            }
          />
        )}
        <div style={leftboxcontainer}>
          <ProductShowCaseImage
            product_details={this.props.product_details}
            boxHeight="98.4%"
            url={this.state.firstImg}
            showWebShare={true}
            showRx={true}
            index={0}
            clickfun={(indexx) => {
              this.setState({
                isOpen: true,
                photoIndex: indexx,
              });
            }}
          />
        </div>
        <div style={rightboxcontainer}>
          <div style={rightboxleft}>
            {this.state.glassImg.map((element, index) => {
              if (index > 1) return null;
              else
                return (
                  <ProductShowCaseImage
                    product_details={this.props.product_details}
                    boxHeight="49%"
                    url={element.url}
                    index={index}
                    clickfun={(indexx) => {
                      this.setState({
                        isOpen: true,
                        photoIndex: indexx,
                      });
                    }}
                  />
                );
            })}
          </div>
          <div style={rightboxcenter}>
            {this.state.glassImg.map((element, index) => {
              if (index < 4 || index >= 7) return null;
              else
                return (
                  <ProductShowCaseImage
                    product_details={this.props.product_details}
                    boxHeight="33%"
                    url={element.url}
                    index={index}
                    clickfun={(indexx) => {
                      this.setState({
                        isOpen: true,
                        photoIndex: indexx,
                      });
                    }}
                  />
                );
            })}
          </div>
          <div style={rightboxleft}>
            {this.state.glassImg.map((element, index) => {
              if (index <= 1 || index >= 4) return null;
              else
                return (
                  <>
                    <ProductShowCaseImage
                      product_details={this.props.product_details}
                      boxHeight="49%"
                      url={element.url}
                      rightRound="true"
                      index={index}
                      clickfun={(indexx) => {
                        this.setState({
                          isOpen: true,
                          photoIndex: indexx,
                        });
                      }}
                    />
                  </>
                );
            })}
          </div>
        </div>
        <button
          className=""
          style={lightboxbutton}
          // onClick={() => this.setState({ toggler: !this.state.toggler })}
          onClick={() => this.setState({ isOpen: true })}
        >
          <img
            src="/images/Header/Images_Icon.svg"
            style={{ marginRight: "5px" }}
          />{" "}
          Show all photos
        </button>
      </div>
    );
  }
  renderImages(product_details) {
    product_details.glass_images.forEach((element) => {
      if (element.thumbnail === 1) {
        this.setState({ firstImg: element.url });
        return;
      }
    });

    let model_image = product_details.glass_images;
    model_image = model_image.concat(product_details.model_images);
    model_image.sort(() => Math.random() - 0.5);
    model_image.sort(() => Math.random() - 0.5);
    model_image = model_image.filter((item) => {
      return item.thumbnail !== 1;
    });
    this.setState({ glassImg: model_image }, () => {
      let l_model_image = [];
      this.state.glassImg.forEach((element) => {
        l_model_image.push(element.url);
      });
      this.setState({ lightboxImg: l_model_image, photoIndex: 0 });
    });
    this.setState({ colorId: this.props.color_id });
  }
}
