import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

class EnterMobile extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            mob_btn: 'formal_btn_unclick' //normal otp button class
        };
        this.handleChange  = this.handleChange.bind(this);
        this.triggerMobHandler  = this.triggerMobHandler.bind(this);
    }

    handleChange(phone){
        let phone_number = phone.target.value;
        if(phone_number.length===10){
                this.setState({
                    mob_btn: 'formal_btn_click'
                });
        }else{
            this.setState({
                    mob_btn: 'formal_btn_unclick'
            });
        }
        this.setState({
            phone: phone_number
        })
    }

    triggerMobHandler(){
        var phone_number = this.state.phone;
        if(phone_number.length===10)
        {
            this.props.mob_handler(this.state.phone, 0);
        }
        else
        {
            swal("Please enter your 10 digit mobile no.");
        }
    }

    render() { 
			return(
                    <div className="row">
                        <h3 className="hidden-xs enter_mob_web">Enter your mobile number</h3>
                        <div className="col-xs-12 each_input_label">
                            <div className="magic_label col-xs-6">Mobile Number</div>
                            <input onChange={this.handleChange} className="phone_input col-xs-12" type="number" name="phone" id="phone" value={this.phone} minLength={10} maxLength={10} placeholder="Your 10 digit number" />
                        </div>
                        <div className="row">
                            <button onClick={this.triggerMobHandler} className={this.state.mob_btn + " btn  col-xs-10 col-xs-offset-1  m_t_12"} type="button">Get OTP</button>
                        </div>
                    </div>
			);
    }
}

export default EnterMobile;