export const FETCH_PRODUCTS_BEGIN   = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const fetchProductsBegin = () => ({
    type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: { products }
});

export const fetchProductsFailure = error => ({
    type: FETCH_PRODUCTS_FAILURE,
    payload: { error }
});
export function fetchProducts() {
    return dispatch => {
        dispatch(fetchProductsBegin());
        let url = process.env.REACT_APP_API_URL+"/v1/products";
        console.log(url);
        return fetch(url)
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            dispatch(fetchProductsSuccess(json.result));
            return json.products;
          })
        .catch(error => dispatch(fetchProductsFailure(error)));
    };
}
export function fetchProductsCollectionType(type,collection_name) 
{
    var url = process.env.REACT_APP_API_URL+"/v2/products?type=collection&collection_name="+collection_name+"&per_page=60"; 
    return dispatch => {
        dispatch(fetchProductsBegin());
        console.log(url);
        return fetch(url)
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            dispatch(fetchProductsSuccess(json.result));
            return json.products;
          })
        .catch(error => dispatch(fetchProductsFailure(error)));
    };
}

export function fetchProductsType(type,frame_type) {

    if(frame_type == 'sunglasses')
    {
        var url = process.env.REACT_APP_API_URL+"/v3/products/filter?glassType=Sunglasses&per_page=200"; 
        return dispatch => {
            dispatch(fetchProductsBegin());
            console.log(url);
            return fetch(url)
              .then(handleErrors)
              .then(res => res.json())
              .then(json => {
                dispatch(fetchProductsSuccess(json.data));
                return json.products;
              })
            .catch(error => dispatch(fetchProductsFailure(error)));
        };
    }
    else
    {
        if(type=='fifa')
        {
            var url = process.env.REACT_APP_API_URL+"/v2/products?type=featured"; 
        }
        else if(type=='featured')
        {
            var url = process.env.REACT_APP_API_URL+"/v2/products?type=latest"; 
        }
        else if(type=='trending')
        {
            var url = process.env.REACT_APP_API_URL+"/v2/products?type=trending"; 
        }
        else if(type=='collection')
        {
            var url = process.env.REACT_APP_API_URL+"/v1/getCollectionList";
            return dispatch => {
                dispatch(fetchProductsBegin());
                return fetch(url)
                  .then(handleErrors)
                  .then(res => res.json())
                  .then(json => {
                    console.log(url);
                    dispatch(fetchProductsSuccess(json.result));
                    return json.products;
                  })
                .catch(error => dispatch(fetchProductsFailure(error)));
            }; 
        }
        else if(window.location.href.indexOf('frame_type') > -1 || window.location.href.indexOf('frame_shape') > -1 || window.location.href.indexOf('frame_size') > -1 || window.location.href.indexOf('frame_colour') > -1 || window.location.href.indexOf('frame_material') > -1)
        {
            var url = process.env.REACT_APP_API_URL+"/v3/products/filter"+window.location.search+"&per_page=20&gender="+type;
            return dispatch => {
                dispatch(fetchProductsBegin());
                return fetch(url)
                  .then(handleErrors)
                  .then(res => res.json())
                  .then(json => {
                    console.log(json);
                    dispatch(fetchProductsSuccess(json.data));
                    return json.products;
                  })
                .catch(error => dispatch(fetchProductsFailure(error)));
            };
        }
        else
        {
            //var url = process.env.REACT_APP_API_URL+"/v2/products?type="+type+"&gender="+type; 
            var url = process.env.REACT_APP_API_URL+"/v2/products?type=old-listing&gender="+type+"&per_page=20"; 
        }
    }
    
    return dispatch => {
        dispatch(fetchProductsBegin());
        return fetch(url)
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            console.log(json);
            dispatch(fetchProductsSuccess(json.result));
            return json.products;
          })
        .catch(error => dispatch(fetchProductsFailure(error)));
    };
}
// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}