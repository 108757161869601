import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import Header from "../Common/Header";
import Loading from "../Common/Loading";

class ProductsRedirect extends Component 
{
 	constructor(props) {
    	super(props);
    	this.state = {
    		redirect: false,
    		product_type: '',
    		redirectTo: '',
    	};
    	
  	}
  	componentDidMount()
	{
		var product_type = this.props.match.params.type;
		this.setState({redirect: true,  product_type: product_type});
	}
  	render() {
 		if(this.state.redirect)
 		{
 			if(this.state.product_type == "sunglasses")
 			{
 				return <Redirect to={'/products/'+this.state.product_type} />
 			}
 			return <Redirect to={'/products/eyeglasses/'+this.state.product_type} />
 		}
 		else
 		{
 			return (
	      		<div> 
	      			<Header color={"#160D08"} /> 
	      			<Loading />
	      		</div>
	      	);
 		}
 		
	}
  	
}


export default ProductsRedirect;

