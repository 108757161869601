import React, { Component } from "react";
import { Link } from "react-router-dom";
import store from '../../Redux/store';
import Footer from '../Common/Footer';

class CollectionPageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collection_list: [],
            first_image: '',
            rest_image: [],
        };
    }
    componentDidMount()
    {
        const state = store.getState();

        var collection_list = state.products.items.collection_details;
        console.log(collection_list);
        this.setState({
            collection_list: collection_list },
            ()=>{
                collection_list.forEach((element,index) => {
                    if(index == 0)
                    {
                        this.setState({ first_image: element});
                    }
                });
                var arr = [];
                collection_list.forEach((element,index) => {
                    
                    if(index > 0)
                    {
                        arr.push(element);
                    }
                    
                });
                this.setState({ rest_image: arr });
            }
        );

        
    }
    render() {
        return (
            <div>
                {this.props.device == "mob" ?
                    <div className="collection_listing_section">
                        <div className="col-xs-12">
                            <p className="collection_listing_top_text">Choose from a range of freaky, stylish and quirky collections, curated just for you!</p>
                            <p className="collection_list_header_text_web">Hot on OJ! 🔥</p>
                        </div>
                        <div className="col-xs-12">
                            <a href={"/products/eyeglasses/collection/"+this.state.first_image.name}><img src={this.state.first_image.collection_banner_mob} className="homepage_first_img_coll_mob img-responsive collection_product_model_img"/></a>
                        </div>
                        <div className="col-xs-12">
                            <p className="collection_list_header_text_web">Previous Collection</p>
                        </div>
                        {this.state.rest_image.map((item, i) => (
                            <div className="col-xs-12">
                                <a href={"/products/eyeglasses/collection/"+item.name}>
                                    <div className="col-xs-12 collection_list_each_card padding_is_zero">
                                        <div className="col-xs-6 padding_is_zero">
                                            <img src={item.collection_banner_mob} key={i} className="img-responsive collection_product_model_img"/>
                                        </div>

                                        <div className="col-xs-6 collection_list_right_section">
                                            <p className="collection_list_title">{item.name}</p>
                                            <p className="collection_list_desc">{item.description}</p>
                                            <p className="collection_list_explore_text">Explore Collection <img src="/images/Header/explore_arrow.png" /></p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                        <Footer />
                    </div>
                    :
                    <div>
                        <div className="collection_list_web">
                            <div className="col-xs-12">
                                <h3 className="collection_list_header_text_web">OJ Collection</h3>
                                <p className="collection_listing_top_text">Choose from a range of freaky, stylish and quirky collections, curated just for you!</p>
                            </div>

                            <div className="col-xs-12 collection_list_first_section_web">
                                <div className="col-xs-3 padding_is_zero">
                                    <a href={"/products/eyeglasses/collection/"+this.state.first_image.name}><img src={this.state.first_image.collection_banner_mob} width="100%" className="img-responsive collection_product_model_img"/></a>
                                </div>
                                <div className="col-xs-5">
                                    <p className="collection_list_title">{this.state.first_image.name}</p>
                                    <p className="collection_list_desc">{this.state.first_image.description}</p>
                                    <a href={"/products/eyeglasses/collection/"+this.state.first_image.name}><p className="collection_list_explore_text">Explore Collection</p></a>
                                </div>
                                <div className="col-xs-3"></div>
                            </div>
                            <div className="col-xs-12">
                                <p className="collection_list_header_text_web">Previous Collection</p>
                            </div>

                            {this.state.rest_image.map((item, i) => (
                                <div className="col-xs-4">
                                    <a href={"/products/eyeglasses/collection/"+item.name}>
                                        <div className="col-xs-12 collection_list_each_card padding_is_zero">
                                            <div className="col-xs-6 padding_is_zero">
                                                <img src={item.collection_banner_web} key={i} className="img-responsive collection_product_model_img"/>
                                            </div>

                                            <div className="col-xs-6 collection_list_right_section">
                                                <p className="collection_list_title">{item.name}</p>
                                                <p className="collection_list_desc">{item.description}</p>
                                                <p className="collection_list_explore_text">Explore Collection <img src="/images/Header/explore_arrow.png" /></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}

                        </div>
                        <Footer />
                    </div>
                }
            </div>
        );
    }
}
export default CollectionPageList;
