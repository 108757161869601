import React, { Component } from "react";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
class ProductAR extends Component
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	pid: '',
	    	height: '100%',
	    	width: '100%',
	    }
	}
	componentDidMount()
	{
		const lenskart_id = this.props.match.params.pid;
		this.setState({pid: lenskart_id});
	}
	render() {
		return(
			<div>
				{this.state.pid !== '' ?
					<div>
						<Header color={"#160D08"}/> 
						<iframe src={"https://bridge.lenskart.com/vto/launch/"+this.state.pid} className="ar_iframe" allow='camera'></iframe>
					</div>
					:
					<div>
						<Header color={"#160D08"}/> 
	      				<Loading />
	      			</div>
				}
			</div>
		);
	}	
}
export default ProductAR;