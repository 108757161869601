import React, { Component }  from 'react'; 
  
class ProductLenseFeature extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
	    	
	    }
    }
    componentDidMount()
    {
    	
		
    }
	render() { 
		return(          
                
                <div>
					{this.props.lense_feature == "Single Vision" ? 
						<div className="product_details_lense_feature">
							<div className={"col-xs-12 padding_is_zero product_details_premium_lense_div "+this.props.lense_background}>
								<p className="product_details_feature_header product_details_product_name">premium lenses</p><p className="product_details_lense_striked">(for <s className="product_details_striked_cost">Rs 1500</s> <p className="bill_details_delivery_charge">FREE</p>)</p>
							</div>
							<div className={"col-xs-12 product_lense_extra "+this.props.lense_background}>
								<div className="col-xs-3 each_product_extra">
									<img src="/images/Header/feature_icons_V3_MAXIMUM CLARITY.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Maximum Clarity</p>
								</div>
								<div className="col-xs-3 each_product_extra">
									<img src="/images/Header/feature_icons_V3_ANTI-GLARE COATING.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Anti-Glare Coating</p>
								</div>
								<div className="col-xs-3 each_product_extra">
									<img src="/images/Header/feature_icons_V3_HYDROPHOBIC COATING.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Hydrophobic Lenses</p>
								</div>
								<div className="col-xs-3 each_product_extra">
									<img src="/images/Header/feature_icons_V3_DIRT & SCRATCH RESISTANT.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Dirt Resistant</p>
								</div>
							</div>
							<div className={"col-xs-12"}>
								<p className="available_power_subtext"><img src="/images/Header/exclamation-circle.svg" className="available_exclamation_img"/>Power availability for each frame might vary.</p>
								<p className="available_power_text" onClick={this.props.available_power}><u>Please check the available powers here.</u></p>
							</div>
						</div>
						:
						[
						    (this.props.lense_feature == "Zero Power" ? 
						    	<div className="product_details_lense_feature">
							    	<div className={"col-xs-12 padding_is_zero product_details_premium_lense_div "+this.props.lense_background}>
										<p className="product_details_feature_header product_details_product_name">premium lenses</p><p className="product_details_lense_striked">(for <s className="product_details_striked_cost">Rs 1500</s> <p className="bill_details_delivery_charge">FREE</p>)</p>
									</div>
							        <div className={"col-xs-12 product_lense_extra "+this.props.lense_background}>
										<div className="col-xs-3 each_product_extra">
											<img src="/images/Header/feature_icons_V3_BLU-PRO COATING.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">BLU CUT Coating</p>
										</div>
										<div className="col-xs-3 each_product_extra">
											<img src="/images/Header/feature_icons_V3_ANTI-GLARE COATING.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Anti-Glare Coating</p>
										</div>
										<div className="col-xs-3 each_product_extra">
											<img src="/images/Header/feature_icons_V3_HYDROPHOBIC COATING.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Hydrophobic Lenses</p>
										</div>
										<div className="col-xs-3 each_product_extra">
											<img src="/images/Header/feature_icons_V3_DIRT & SCRATCH RESISTANT.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Dirt Resistant</p>
										</div>
									</div>
								</div>
						        : 
						        [
						        	(this.props.lense_feature == "Blu Cut Single Vision" ?
						        		<div className="product_details_lense_feature">
									    	<div className={"col-xs-12 padding_is_zero product_details_premium_lense_div "+this.props.lense_background}>
												<p className="product_details_feature_header product_details_product_name">premium lenses</p><p className="product_details_lense_striked">(for <s className="product_details_striked_cost">Rs 1400</s> <p className="bill_details_delivery_charge">Rs 890</p>)</p>
											</div>
									        <div className={"col-xs-12 product_lense_extra "+this.props.lense_background}>
												<div className="col-xs-3 each_product_extra">
													<img src="/images/Header/feature_icons_V3_BLU-PRO COATING.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">BLU CUT Coating</p>
												</div>
												<div className="col-xs-3 each_product_extra">
													<img src="/images/Header/feature_icons_V3_ANTI-GLARE COATING.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Anti-Glare Coating</p>
												</div>
												<div className="col-xs-3 each_product_extra">
													<img src="/images/Header/feature_icons_V3_HYDROPHOBIC COATING.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Hydrophobic Lenses</p>
												</div>
												<div className="col-xs-3 each_product_extra">
													<img src="/images/Header/feature_icons_V3_DIRT & SCRATCH RESISTANT.svg" className="product_feature_icons" alt="icons"/><p className="redo_text">Dirt Resistant</p>
												</div>
											</div>
										</div>
										:
										null
						        	)
						        ]
						        
						    )
						]
						
					}
				</div>
                    
		);
    }
}
 
  
export default ProductLenseFeature;