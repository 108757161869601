import React, { Component } from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";

class ProductARDetailsBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color_border: "colour_border",
    };
    this.ChangeColor = this.ChangeColor.bind(this);
  }
  componentDidMount() {}
  ChangeColor(item_url, color_id, device) {
    this.props.change_color(item_url, color_id, device);
  }
  render() {
    return (
      <div>
        <div className="col-xs-6 extra_bold_text product_full_name">
          {this.props.item.short_name}
        </div>
        <div className="col-xs-6 colour_section">
          {this.props.colors.map((color, index) => (
            <div>
              {this.props.item.specifications.color_prod_id === color.id ? (
                <div>
                  {color.sold_out == 1 ? (
                    <div className="transparent_colour_border">
                      <img
                        src="/images/Header/red_striked_circle.svg"
                        className="color_striked_img"
                        onClick={() =>
                          this.ChangeColor(
                            this.props.item.prod_url,
                            color.id,
                            this.props.device
                          )
                        }
                      />
                      <span
                        className="colour_dot_product_details"
                        id={color.basic_prod_id + "_" + color.id}
                        key={index}
                        style={{ backgroundColor: color.hex_code }}
                      ></span>
                    </div>
                  ) : (
                    <div className={this.state.color_border}>
                      <span
                        className="colour_dot_product_details"
                        id={color.basic_prod_id + "_" + color.id}
                        key={index}
                        style={{ backgroundColor: color.hex_code }}
                        onClick={() =>
                          this.ChangeColor(
                            this.props.item.prod_url,
                            color.id,
                            this.props.device
                          )
                        }
                      ></span>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {color.sold_out == 1 ? (
                    <div className="transparent_colour_border">
                      <img
                        src="/images/Header/white_striked_circle.svg"
                        className="color_striked_img"
                        onClick={() =>
                          this.ChangeColor(
                            this.props.item.prod_url,
                            color.id,
                            this.props.device
                          )
                        }
                      />
                      <span
                        className="colour_dot_product_details"
                        key={index}
                        style={{ backgroundColor: color.hex_code }}
                      ></span>
                    </div>
                  ) : (
                    <div className="transparent_colour_border">
                      <span
                        className="colour_dot_product_details"
                        key={index}
                        style={{ backgroundColor: color.hex_code }}
                        onClick={() =>
                          this.ChangeColor(
                            this.props.item.prod_url,
                            color.id,
                            this.props.device
                          )
                        }
                      ></span>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="clearfix"></div>
        <div className="col-xs-5">
          {this.props.item.specifications.frame_size} - {this.props.item.gender}
        </div>
        <div className="col-xs-7"></div>
        <div className="clearfix"></div>
        <div className="col-xs-12">
          <div
            className={
              this.props.item.sold_out == 0
                ? "col-xs-12 product_listing_left_button"
                : "col-xs-6 product_listing_left_button"
            }
          >
            {this.props.item.gender == "Unisex" ? (
              <div>
                {this.props.path_source == "" ? (
                  <center>
                    <Link
                      to={{
                        pathname:
                          "/product/" +
                          this.props.item.prod_url +
                          "/color/" +
                          this.props.item.specifications.color_prod_id,
                        state: { prevPath: window.location.href },
                      }}
                    >
                      <button className="get_details_button btn btn-dark">
                        Get Details
                      </button>
                    </Link>
                  </center>
                ) : (
                  <center>
                    <Link
                      to={{
                        pathname:
                          "/product/" +
                          this.props.item.prod_url +
                          "/color/" +
                          this.props.item.specifications.color_prod_id +
                          "?gender=" +
                          this.props.item.gender +
                          "&path_source=" +
                          this.props.path_source,
                        state: { prevPath: window.location.href },
                      }}
                    >
                      <button className="get_details_button btn btn-dark">
                        Get Details
                      </button>
                    </Link>
                  </center>
                )}
              </div>
            ) : (
              <div>
                {this.props.path_source == "" ? (
                  <center>
                    <Link
                      to={{
                        pathname:
                          "/product/" +
                          this.props.item.prod_url +
                          "/color/" +
                          this.props.item.specifications.color_prod_id,
                        state: { prevPath: window.location.href },
                      }}
                    >
                      <button className="get_details_button btn btn-dark">
                        Get Details
                      </button>
                    </Link>
                  </center>
                ) : (
                  <center>
                    <Link
                      to={{
                        pathname:
                          "/product/" +
                          this.props.item.prod_url +
                          "/color/" +
                          this.props.item.specifications.color_prod_id +
                          "?path_source=" +
                          this.props.path_source,
                        state: { prevPath: window.location.href },
                      }}
                    >
                      <button className="get_details_button btn btn-dark">
                        Get Details
                      </button>
                    </Link>
                  </center>
                )}
              </div>
            )}
          </div>
          {this.props.item.sold_out == 1 ? (
            <div className="col-xs-6 product_listing_right_button">
              <center>
                <button className="btn btn-dark extra_bold_text new_sold_out_button_product_listing">
                  SOLD OUT
                </button>
              </center>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default ProductARDetailsBody;
