import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import AllProductPageAR from "./AllProductPageAR";
import { fetchProductsARType } from "../../Redux/Action/ProductARAction";
import store from "../../Redux/store";
import Lottie from "react-lottie";
import animationData from "../Lotties/ar_try_on_loading.json";
import AllProductPageARHR from "./AllProductPageARHR";

class ProductTypeAR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }
  componentDidMount() {
    const product_type = this.props.match.params.type;
    const frame_type = this.props.match.params.frame_type;
    this.props.dispatch(fetchProductsARType(product_type, frame_type));
    const state = store.getState();
    window.addEventListener("resize", this.handleWindowSizeChange);
    // this.initializeMedia();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  initializeMedia = async () => {
    if (!("mediaDevices" in navigator)) {
      navigator.mediaDevices = {};
    }

    if (!("getUserMedia" in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(new Error("getUserMedia Not Implemented"));
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }

    //Get the details of video inputs of the device
    const videoInputs = await this.getListOfVideoInputs();

    //The device has a camera
    if (videoInputs.length) {
      navigator.mediaDevices
        .getUserMedia({
          video: {},
        })
        .then((stream) => {
          this.player.srcObject = stream;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("The device does not have a camera");
    }
  };
  getListOfVideoInputs = async () => {
    // Get the details of audio and video output of the device
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

    //Filter video outputs (for devices with multiple cameras)
    return enumerateDevices.filter((device) => device.kind === "videoinput");
  };
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const styles = {
      checkAnimation: {
        height: "200px",
        width: "200px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    };
    const product_type = this.props.match.params.type;
    const frame_type = this.props.match.params.frame_type;
    const { error, loading, products } = this.props;
    const { width } = this.state;
    const isMobile = width <= 768;
    console.log(products);
    if (error) {
      return <div>Error! {error.message}</div>;
    }

    if (loading) {
      return (
        <div>
          <Header color={"#160D08"} />
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <Header color={"#160D08"} />
        <AllProductPageARHR />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.products.items,
  loading: state.products.loading,
  error: state.products.error,
});

export default connect(mapStateToProps)(ProductTypeAR);

/*
<video ref={(refrence) => { this.player = refrence;}} autoPlay className={" front_camera_ar_background"}></video>
<Lottie options={defaultOptions} style={styles.checkAnimation} className={this.state.show_iframe}/>
*/
