import React, { Component } from "react";

class ProductDynamicFeature extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.source === "web" ? (
      <div>
        <div className="row">
          {this.props.features.map((item, index) => (
            <div className="col-sm-4 mt-3">
              <img
                src={item.image}
                className=""
                style={{ height: "50px", width: "50px", marginRight: "10px" }}
                alt="icons"
              />
              <span className="redo_text" style={{ fontSize: "16px" }}>
                {item.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className="col-xs-12 product_extra">
        {this.props.features.map((item, index) => (
          <div className="col-xs-3 each_product_extra">
            <img
              src={item.image}
              className="product_feature_icons"
              alt="icons"
            />
            <p className="redo_text">{item.name}</p>
          </div>
        ))}
      </div>
    );
  }
}

export default ProductDynamicFeature;
