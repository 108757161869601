import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

class EmptyCart extends Component 
{    
    constructor(props) {
        super(props);
    }


    render() { 
            return(
                    <div className="col-xs-12 empty_cart_div">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                        <center>
                            <img src="/images/Header/Lonely_Cat.svg" />
                            <p className="no_product_text">Your cart seems blurry! <br />Add products and come back here, to make them yours! </p>
                            <Link to="/"><p className="no_product_text">Continue Shopping</p></Link>
                        </center>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
			);
    }
}

export default EmptyCart;
