import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomePageCouponSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <div>
            {this.props.source == "mob" ?
                <div>
                    {this.props.show_coupon_banner_mob ?
                        <div className="col-xs-12 padding_is_zero">
                            <img src={this.props.coupon_banner_mob} className="img-responsive" width="100%"/>
                        </div>
                        :
                        null
                    }
                </div>
                :
                <div>
                    {this.props.show_coupon_banner_web ?
                        <div className="col-xs-12 padding_is_zero">
                            <img src={this.props.coupon_banner_web} className="img-responsive" width="100%" />
                        </div>
                        :
                        null
                    }
                </div>
            }
        </div>
    );
  }
}
export default HomePageCouponSection;
