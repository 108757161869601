import React, { Component } from "react";  
  
export default class ProductImages extends Component
{
	
	constructor(props) {  
		super(props);
	    this.state = {
	    	image_clicks: this.props.image_clicks,
	    	index: 0,
	    }
	    //this.Prev  = this.Prev.bind(this);
	    //this.Next  = this.Next.bind(this);
	}
	Prev(event, index)
	{
		event.preventDefault();
		var next_target = "#carousel__slide"+(index-1);
		window.location.href = next_target;
		this.setState({index: parseFloat(this.state.index-1)});
        //window.history.pushState('', document.title, window.location.pathname);
	}
	Next(event, index)
	{
		event.preventDefault();
		var next_target = "#carousel__slide"+(index+1);
		window.location.href = next_target;
		this.setState({index: parseFloat(this.state.index+1)});
        //window.history.pushState('', document.title, window.location.pathname);
    }
	render() {  
		return (
				<div id="frame_images" className={""+this.props.display_frame+" "+this.props.display_hide}>
					<section className="carousel_product_details carousel" aria-label="Gallery">
						<ol className="carousel__viewport">
						  	{this.props.images.map((item, index) => (
						  		<li id={"carousel__slide"+index}
						        tabIndex={index} key={index}
						        className="carousel__slide" data-target="#myCarousel">
						      		<div className="carousel__snapper">
						        		<img src={item.url} className="img-responsive glass_img_product_details" alt="glass_img" onClick={this.props.show_more_image}/>
						      		</div>
						      		
						    	</li>
							))}
						</ol>
						<img className="product_details_expand_icon" src="/images/Header/Expand_Thumbnail_icon_ListingPage.svg" alt="expand_icon_web" onClick={this.props.show_more_image}/><p className="product_details_expand_text" onClick={this.props.show_more_image}>Tap to expand</p>
						
						<a class="left carousel-control" href="javascript:void()"  role="button" data-slide="prev" onClick={(event) => {this.Prev(event,this.state.index);}}>
					      {/*<span class="icon-prev" aria-hidden="true"></span>
					      <span class="sr-only">Previous</span>*/}
					      <img src="/images/Header/carousel-arrow-right.svg" className="product_details_left_arrow"/>
					    </a>
					    <a class="right carousel-control" href="javascript:void()"  role="button" data-slide="next" onClick={(event) => {this.Next(event,this.state.index);}}>
					      {/*<span class="icon-next" aria-hidden="true"></span>
					      <span class="sr-only">Next</span>*/}
					      <img src="/images/Header/carousel-arrow-right.svg" className="product_details_right_arrow"/>
					    </a>
					    <div className="clearfix"></div>
					</section>
				</div>
	    	);
	}
}
/*
<i class="fa fa-angle-left product_details_corousel_left"></i>
<i class="fa fa-angle-right product_details_corousel_right"></i>*/
/*
<a href={"#carousel__slide"+(index-1)} ><i class="fa fa-angle-left product_details_corousel_left"></i></a>
<a href={"#carousel__slide"+(index+1)} ><i class="fa fa-angle-right product_details_corousel_right"></i></a>
*/