import React, { Component } from "react";
import Lottie from 'react-lottie';
import animationData from '../Lotties/62542-cool-chick.json';
import { Link } from 'react-router-dom';
import BasicDetails from './BasicDetails';

class ProfileNew extends Component 
{
	render() {
		const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        const styles = {
            checkAnimation: {
                position: 'absolute',
                textAlign: 'center',
                height: 'auto',
                width: '50%',
                left: '20%',
            },
        } 
		return(
			<div>
                {/* ***************mob-view************************** */}
                <div className="col-xs-12 hidden-sm hidden-md hidden-lg hidden-xl ojos_log_in_page_div">
                    <p className="ojos_login_headtext">Let’s get to know you</p>
                    <p className="ojos_login_subheadtext">Fresh frames coming up…</p>
                    <BasicDetails />
                </div>

                {/* ************************tab & desktop -view*************************************** */}

                <div className="col-lg-12 col-md-12 col-sm-12 log_in_desktop_div hidden-xs">
                    <img src="/images/Header/OJPattern.png"/>
                    <div class=" sign_up_desktop_container">
                    <div className="col-lg-6  col-md-6 col-sm-6 sign_up_desktop_container_lottie">
                      <Lottie  options={defaultOptions} style={{position:"absolute", height: "auto", width:"50%"}}/>
                    </div>
                    <div className=" col-lg-6  col-md-6 col-sm-6 login_desktop_contents">
                        <p className="login_desktop_headtext">Let’s get to know you</p>
                        <p className="login_desktop_subheadtext">Fresh frames coming up…</p>
                        <BasicDetails />
                    </div>
                  </div>
                </div>
            </div>
		);
		
	}
}

export default ProfileNew;