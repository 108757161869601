import './App.css';
import { BrowserRouter as Router, Route, Navigate} from 'react-router-dom';
import Homepage from './Components/Home/Index'
import ProfileNew from './Components/Auth/ProfileNew'
import Account from './Components/Auth/Account'
import EditProfile from './Components/Auth/EditProfile'
import ProductsRedirect from './Components/ProductListing/ProductsRedirect'
import Products from './Components/ProductListing/Products'
import ProductType from './Components/ProductListing/ProductType'
import ProductCollectionType from './Components/ProductListing/ProductCollectionType'
import ProductTypeAR from './Components/ProductListingAR/ProductTypeAR'
import ProductSunglasses from './Components/ProductListing/ProductSunglasses'
import ProductDetails from './Components/ProductPage/Index'
import ProductAR from './Components/ProductAR/Index'
import CartDetails from './Components/Cart/CartDetails'
import CheckoutOverview from './Components/Checkout/Index'
import CheckoutData from './Components/CheckoutData'
import Order from './Components/Order/OrderDetails'
import OrderProductDetails from './Components/Order/OrderProductDetails'
import OrderReturn from './Components/Order/OrderReturn'
import Power from './Components/Power/PowerPage'
import Exchange from './Components/Exchange/ExchangePage'
import PowerNew from './Components/Power/PowerNew'
import PaymentSuccess from './Components/Checkout/PaymentSuccess'
import PaymentFail from './Components/Checkout/PaymentFail'
import Login from './Components/Auth/Login'
import Logout from './Components/Auth/Logout'
import Help from './Components/Static/Help'
import AboutUs from './Components/Static/AboutUs'
import PrivacyPolicy from './Components/Static/PrivacyPolicy'
import Terms from './Components/Static/TermsConditions'
import ContactUs from './Components/Static/ContactUs'
import FAQ from './Components/Static/FAQ'
import Credit from './Components/Common/Credit'
import SubscriptionPlan from './Components/Common/SubscriptionPlan'
import ProductSize from './Components/Common/ProductSize'
import PayUWeb from './Components/Checkout/PayUWeb'
import PowerWebApp from './Components/Power/PowerWebApp'
import OrderTrackerWeb from './Components/Order/OrderTrackerWebApp'
import LaunchRegistration from './Components/Common/LaunchRegistration'
import WhyOjoswear from './Components/Common/WhyOjoswear'
import OjosFeedback from './Components/Common/OjosFeedback'
import OjosHiring from './Components/Common/OjosHiring'
import Referral from './Components/Common/Referral'
import UserAddress from './Components/Common/AddAddress/Address'
/*Facebook Pixel*/
import ReactPixel from 'react-facebook-pixel';
/*Google Analytics*/
import ReactGA from 'react-ga';
import { createBrowserHistory } from "history";
import ClevertapReact from 'clevertap-react';
 
ClevertapReact.initialize("R57-664-Z56Z");


const history = createBrowserHistory();
ReactGA.initialize('UA-201898065-1');
ReactGA.pageview(window.location.pathname);
console.log(window.location.pathname);
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname);
});

/*Facebook Pixel */

//const advancedMatching = { em: '' }; 
const options = {
  autoConfig: true, // set pixel's autoConfig. 
  debug: false, // enable logs
};
ReactPixel.init('1008827613060985', options);
ReactPixel.pageView(); // For tracking page view



function App() {
    return (
        <Router>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/profile" component={ProfileNew} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/edit-profile" component={EditProfile} />
            <Route exact path="/explore-ojos" component={Products} />
            <Route exact path="/products/:type" component={ProductsRedirect}/>
            <Route exact path="/products/:frame_type/:type" component={ProductType} />
            <Route exact path="/ar/products/:frame_type/:type" component={ProductTypeAR} />
            <Route exact path="/products/:frame_type/collection/:collection_name" component={ProductCollectionType} />
            <Route exact path="/products/sunglasses" component={ProductSunglasses} />
            <Route exact path="/product/:id/color/:color_id" component={ProductDetails} />
            <Route exact path="/product/:id/:pid/color/:color_id" component={ProductAR} />
            <Route exact path="/cart" component={CartDetails} />
            <Route exact path="/checkout" component={CheckoutOverview} />
            <Route exact path="/checkout_data" component={CheckoutData} />
            <Route exact path="/order" component={Order} />
            <Route exact path="/order/:order_id/product/:product_id" component={OrderProductDetails} />
            <Route exact path="/power/order/:order_id/product/:product_id" component={Power} />
            <Route exact path="/exchange/order/:order_id/product/:product_id" component={Exchange} />
            <Route exact path="/power_new/order/:order_id/product/:product_id/order_prod_id/:order_prod_id" component={PowerNew} />
            <Route exact path="/return/order/:order_id/product/:product_id" component={OrderReturn} />
            <Route exact path="/success/:order_id" component={PaymentSuccess} />
            <Route exact path="/fail/:order_id" component={PaymentFail} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/help" component={Help} />
            <Route exact path="/terms-and-conditions" component={Terms} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/credit" component={Credit} />
            <Route exact path="/subscription-plan" component={SubscriptionPlan} />
            <Route exact path="/product-size" component={ProductSize} />
            <Route exact path="/payu-web/:access_token/:order_id" component={PayUWeb} />
            <Route exact path="/power-web/:access_token/order_id/:order_id/product_id/:product_id/order_prod_id/:order_prod_id" component={PowerWebApp} />
            <Route exact path="/order-tracker-web/:access_token/order_id/:order_id/product_id/:product_id" component={OrderTrackerWeb} />
            <Route exact path="/sale-registration" component={LaunchRegistration} />
            <Route exact path="/why-ojoswear" component={WhyOjoswear} />
            <Route exact path="/feedback" component={OjosFeedback} />
            <Route exact path="/hiring" component={OjosHiring} />
            <Route exact path="/referral" component={Referral} />
            <Route exact path="/address" component={UserAddress} />
        </Router>
    );
}

export default App;
