import React, { Component } from "react";
import { Redirect } from 'react-router';
import swal from 'sweetalert';

class ExchangeHeader extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            
        };
        
    }
    componentDidMount()
    {
        
    }
    
    render() {
        return(
			<div className="product_details_new_modal_header modal-header">
                <div className="col-xs-12 padding_is_zero product_details_mob_header">
                    <div className="col-xs-1">
                        <a href={"/order/"+this.props.order_id+"/product/"+this.props.product_id}><i className="fa fa-angle-left product_details_close close" aria-hidden="true"></i></a>
                    </div>
                    <div className="col-xs-11 padding_is_zero">
                        <center><p className="header_exchange_text">EXCHANGE ORDER</p></center>
                    </div>
                </div>
            </div>
		);
		
	}
}

export default ExchangeHeader;