import React, { useEffect } from "react";
import Rating from "./Rating";
import { useState } from "react";
import styles from "./RatingReview.css";
import { SubmitRating } from "./SubmitRating";
import Lightbox from "react-image-lightbox";

const ReviewList = ({ reviewData, productDetails }) => {

	const [isOpen, setisOpen] = useState(false);
	const [imageIndex, setImageIndex] = useState(0);
	const [imageValue, setImageValue] = useState('');
	const [lightboxImg, setReviewImageArray] = useState([]);
	const [isOpenEach, setisOpenEach] = useState(false);
	const [eachPhotoUrl, setEachPhotoUrl] = useState('');
	
	
	
	let imgData = [];
	const [photoIndex, setPhotoIndex] = useState(0);

  	console.log("trst", reviewData);
	const img_array = [];
	useEffect(() => {
		if(reviewData)
		{
			imgData=[];
			console.log("inside review");
		
			reviewData &&
			reviewData.reviews.map((review) => {
				{
				return (
					review.media_url &&
					review.media_type == "image" &&
					review.media_url.map((element,index) => {
					return (
						imgData.push(element)
					);
					})
				);
				}
			})

			setReviewImageArray(imgData)
		}
	},[reviewData,isOpen]);

	const ojFam = {
		/* UI Properties */
		textAlign: "left",
		font: "normal normal bold 17px NunitoSans-ExtraBold",
		letterSpacing: "0px",
		color: "#8a949e",
		opacity: "1",
	};
	const userImg = {
		background: "#EDEDED",
		borderRadius: "50%",
		padding: "15px",
		// width: "72px",
		fontSize: "30px",
		textAlign: "center",
	};
	

  
	return (
		<div>
		
		<div className="clearfix"></div>
		<div
			className="col-md-12"
			// style={{ height: "400px", overflow: "auto", position: "relative" }}
		>
			{isOpen && (
			
				<Lightbox
					mainSrc={lightboxImg[photoIndex]}
					nextSrc={lightboxImg[(photoIndex + 1)]}
					prevSrc={lightboxImg[(photoIndex - 1)]}
					onCloseRequest={() => setisOpen(false)}
					onMovePrevRequest={() => setPhotoIndex(photoIndex-1)}
					onMoveNextRequest={() => setPhotoIndex(photoIndex+1)}
			  	/>

			)}

			{isOpenEach && (
				
				<Lightbox
					mainSrc={eachPhotoUrl}
					onCloseRequest={() => setisOpenEach(false)}
					
				/>

			)}
			
			{reviewData && reviewData.reviews.length ? (
			<>
				{" "}
				<h5 style={ojFam}>
					the #OJFam says{" "}
				</h5>
				<h5
				id="reviewsection"
				style={{
					color: "black",
					fontWeight: "bold",
				}}
				>
				OJ Clicks
				</h5>
			</>
			) : (
			""
			)}

			{lightboxImg.map((element,index) => {
				return <img
							width={75}
							style={{ marginRight: "10px", borderRadius: "5px", cursor: "pointer" }}
							src={element}
							index={index}
							onClick={() =>{setisOpen(true) ;setPhotoIndex(index)}}
							
						/>
			})}

			<div style={{ marginTop: "10px" }}>
			{reviewData &&
				reviewData.reviews.map((element) => {
				return (
					<div>
					<div className="row" style={{ marginLeft: "-15px" }}>
						<hr
						style={{
							borderTop: "3px solid #707070",
							opacity: "0.05",
						}}
						></hr>
						<div className="row">
						<div className="col-md-1 col-xs-2">
							<img src="/images/Header/review_user_icon.png"/>

							{/* <img
								src="/images/Header/MyProfileIcon.svg"
								style={userImg}
								alt="profile"
							/> */}
						</div>
						<div className="col-md-10 col-xs-10">
							<div className="col-xs-4" style={{ marginTop: "5px" }}>
							{" "}
							<span>
								<b>{element.title}</b>
								<br></br>
								by {element.user.name}
							</span>
							</div>
							<div className="col-xs-8" style={{textAlign: "right"}}>
							{" "}
							<Rating
								showStar={"all"}
								ratingCount={element.no_of_stars}
							></Rating>
							</div>
						</div>
						</div>

						<div className="col-md-12" style={{ marginTop: "5px" }}>
						{element.description ? <>"{element.description}"</> : ""}
						</div>
						<div className="col-md-12" style={{ marginTop: "5px" }}>
						{element.media_url &&
							element.media_type == "image" &&
							element.media_url.map((item, index) => {
							return (
								<>
								<img
									width={75}
									style={{
									marginRight: "10px",
									borderRadius: "5px",
									cursor: "pointer",
									}}
									src={item}
									onClick={() =>{setisOpenEach(true) ; setEachPhotoUrl(item)}}
								/>
								</>
							);
							})}
						</div>
					</div>
					</div>
				);
				})}
			</div>
		</div>
		</div>
	);
	};
	export default ReviewList;
