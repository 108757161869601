import React, { Component } from "react";

class ProductDetailsCollectionBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <div>
        {this.props.product_details.latest == 1 ? (
          <div>
            {this.props.device == "mob" ? (
              <div className="col-xs-12 hidden-sm hidden-lg hidden-md padding_is_zero pdp_a_content_div">
                {this.props.show_collection_banner_mob ?
                  <div className="col-xs-12">
                    <br />
                    <p className="pdp_a_content_p1">
                      Get edgy, get extra, get bold
                    </p>
                    {/*<p className="pdp_a_content_p2">DRAMEBAAZ</p>*/}
                    <p className="pdp_a_content_p3">with our new collection</p>
                    <p className="pdp_a_content_p4">Eyeconic Cocktails</p>
                    <p className="pdp_a_content_p5">
                      <i>Jihne mera eyes luteya, OJOS!</i>
                    </p>
                    {this.props.collection_banner_mob.map((details, index) => (
                      <img
                      src={details.banner_for_mobile_web}
                      className="img-responsive"
                    />
                    ))}
                    <br />
                  </div>
                  :
                  null
                }
              </div>
            ) : (
              <div>
              {this.props.show_collection_banner_web ?
                <div
                  className="col-sm-12 hidden-xs padding_is_zero pdp_a_content_div"
                  style={{ zIndex: "-1" }}
                >
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <br />
                    <p className="pdp_a_content_p1">
                      Get edgy, get extra, get bold
                    </p>
                    {/*<p className="pdp_a_content_p2">DRAMEBAAZ</p>*/}
                    <p className="pdp_a_content_p3">with our new collection</p>
                    <p className="pdp_a_content_p4">Eyeconic Cocktails</p>
                    <p className="pdp_a_content_p5">
                      <i>Jihne mera eyes luteya, OJOS!</i>
                    </p>
                    {this.props.collection_banner_web.map((details, index) => (
                      <img
                        src={details.banner_for_desktop}
                        className="pdp_collection_banner_img img-responsive"
                      />
                    ))}
                    <br />
                  </div>
                  <div className="col-sm-2"></div>
                </div>
                :
                null
              }
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}
export default ProductDetailsCollectionBanner;
