import React, { Component } from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";

class ProductARFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iframeSrc: "",
      element: "",
    };
  }
  componentDidMount() {
    this.setState({ iframeSrc: "" });
    this.setState({
      element:
        "https://bridge.lenskart.com/vto/launch/" + this.props.lenskart_prod_id,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.lenskart_prod_id != prevProps.lenskart_prod_id) {
      this.setState({
        iframeSrc:
          "https://bridge.lenskart.com/vto/launch/" +
          this.props.lenskart_prod_id,
      });
    }
  }
  render() {
    return this.props.index === 0 ? (
      <div
        style={{ height: "100%" }}
        dangerouslySetInnerHTML={{
          __html:
            "<iframe src='https://bridge.lenskart.com/vto/launch/" +
            this.props.lenskart_prod_id +
            "' onLoad='" +
            this.HideCameraBackground +
            "' className='product_listing_iframe_ar' width='100%' allow='camera' frameBorder='0' height='100%' data-id='" +
            this.props.lenskart_prod_id +
            "' id='" +
            this.props.lenskart_prod_id +
            "test'} />",
        }}
      />
    ) : (
      <div
        style={{ height: "100%" }}
        dangerouslySetInnerHTML={{
          __html:
            "<iframe src='" +
            this.state.iframeSrc +
            "' data-src='https://bridge.lenskart.com/vto/launch/" +
            this.props.lenskart_prod_id +
            "' onLoad='" +
            this.HideCameraBackground +
            "' className='product_listing_iframe_ar' width='100%' allow='camera' frameBorder='0' height='100%' data-id='" +
            this.props.lenskart_prod_id +
            "' id='" +
            this.props.lenskart_prod_id +
            "test'} />",
        }}
      />
    );
  }
}
export default ProductARFrame;
{
  /**/
}

{
  /**/
}
