import React, { Component } from "react";
import Checkout from './CheckoutDetails';
import CheckoutHeader from './CheckoutHeader';
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import CheckoutEmpty from './CheckoutEmpty';
import { Redirect } from 'react-router';
import { connect } from "react-redux";
import { FetchCartDetails } from "../../Redux/Action/CartActions";

class CheckoutOverView extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	add_address_option: true,
	    	address: 'xyz',
	    	access_token : localStorage.getItem("token"),
	    	name: localStorage.getItem("name"),
	    	redirect: false,
	    	redirectTo: '/',
	    	prevPath: this.props.location.state,
	    }
	}
	componentDidMount()
	{
		if(this.state.access_token)
		{
			this.props.dispatch(FetchCartDetails(this.state.access_token));
		}
		else
		{
			this.setState({ redirectTo: '/login?redirect_url=/cart', redirect: true})
		}
		
	}
	componentWillUnmount() {
    	
	}
	render() {
		if(this.state.redirect){
            return <Redirect to={this.state.redirectTo} />
        }

		const { error, loading, products } = this.props;
		console.log(products);
		if (error) {
	      return <div>Error! {error.message}</div>;
	    }
		if (loading) {
	      return(<div class="checkout_details"> 
	      			<Header color={"#160D08"}/> 
	      			<Loading />
	      		</div>);
	    }
	    return(
	    	<div>
	    	{this.props.products.success  ?
	    		<Checkout prevPath={this.state.prevPath} />
	    		:
	    		<div>
	    			<Header color={"#160D08"}/> 
	    			<CheckoutEmpty />
	    		</div>
	    	}
	    	</div>
	    );
		
	}
}

const mapStateToProps = state => ({
  	products: state.products.items,
  	loading: state.products.loading,
  	error: state.products.error
});

export default connect(mapStateToProps)(CheckoutOverView);
