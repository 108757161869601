import React, { Component } from "react";
import { Redirect } from 'react-router';
import Header from "../Common/Header";
import swal from 'sweetalert';

class EditProfile extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            gender: 'Female',
            phone: '',
            profile_image: '/images/Header/Female_Avatar.svg',
            token: localStorage.getItem('token'),
            redirect: false,
            redirectUrl: '/edit-profile',
        };
        this.handleChange  = this.handleChange.bind(this);
        this.ChooseGender  = this.ChooseGender.bind(this);
        this.SaveProfile  = this.SaveProfile.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
    }
    componentDidMount()
    {
        if(this.state.token)
        {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ this.state.token);
            var requestOptions = {
                headers: myHeaders,
            };
            fetch(process.env.REACT_APP_API_URL+'/v1/user', requestOptions)
            .then(response => response.json())
            .then(
            (res) => {
                console.log(res);
                if(res.result.gender == "Female")
                {
                    var profile_image = "/images/Header/Female_Avatar.svg"
                }
                else
                {
                    var profile_image = "/images/Header/Male_Avatar.svg"
                }
                this.setState({name: res.result.name, email: res.result.email, gender: res.result.gender, profile_image: profile_image, phone: res.result.phone});
                localStorage.setItem('name', res.result.name);
            });
        }
        else
        {
            this.setState({redirect: true, redirectUrl: '/login'}); 
        }
    }
    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }
    ChooseGender(gender)
    {
        var selected_gender = gender;
        this.setState({gender: selected_gender});
    }
    handleKeypress(e){
        //it triggers by pressing the enter key
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            this.SaveProfile();
        }
    }
    SaveProfile()
    {
        var name_text = this.state.name.replace(/ /g, "");
        if(name_text.length > 2  && this.state.email != '')
        {
            if(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.state.email))
            {
                var data = JSON.stringify({basic : {name : this.state.name, email : this.state.email, gender : this.state.gender}});
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer "+ localStorage.getItem('token'));
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: data,
                    redirect: 'follow'
                };
                let url = process.env.REACT_APP_API_URL+'/v1/user/basic';
                fetch(url, {
                    method: 'POST',
                    headers: myHeaders,
                    body: data,
                    redirect: 'follow'
                })
                .then(response => response.json())
                .then(
                    (res) => {
                        console.log(res.result.user.data.gender);
                        localStorage.setItem('name', res.result.user.data.name);
                        swal("Details Updated!");
                    });  
            }
            else
            {
                swal("Incorrect Email ID entered");
            }
        }
        else
        {
            swal("All fields are required");
        }
        
    }
    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
		return(
            <div>
                <Header  color={"#160D08"}/>
                <div className="col-sm-3"></div>
    			<div className="profile_edit_div col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <p className="edit_profile_header_name">Hi {this.state.name}</p>
                        <p className="edit_profile_edit_text">Edit your details</p>
                        <center>
                                <img src={this.state.profile_image} className="male_avatar"/>
                        </center>
                        <h3 className="hidden-xs enter_mob_web"></h3>
                        <div className="col-xs-12 each_input_label">
                            <div className="magic_label col-xs-2">Name</div>
                            <input onChange={this.handleChange} onKeyPress={(e) => {this.handleKeypress(e)}} className="phone_input col-xs-12" type="text" name="name" id="name" placeholder="Your Full Name" value={this.state.name}/>
                        </div>
                        <br/>
                        <div className="col-xs-12 each_input_label">
                            <div className="magic_label col-xs-2">Email</div>
                            <input onChange={this.handleChange} onKeyPress={(e) => {this.handleKeypress(e)}} className="phone_input col-xs-12" type="text" name="email" id="email" placeholder="Your Email Address" value={this.state.email}/>
                        </div>
                        <div className="col-xs-12 each_input_label">
                            <div className="magic_label col-xs-2">Mobile</div>
                            <input className="phone_input col-xs-12" type="text" name="phone" id="phone" placeholder="Your Mobile Number" value={this.state.phone} disabled/>
                        </div>
                        <form className="form_gender cf each_input_label">
                            <p className="gender_header_text">Gender</p>
                            <section className="plan cf">
                                <div className="click_gender" onClick={()=>this.ChooseGender("Male")}><input type="radio" name="radio_gender" id="male" value="Male" className="gender_radio" checked = {this.state.gender === 'Male'} /><label className="four col">Male</label></div>
                                <div className="click_gender" onClick={()=>this.ChooseGender("Female")}><input type="radio" name="radio_gender" id="female" value="Female" className="gender_radio" checked = {this.state.gender === 'Female'}/><label className="four col">Female</label></div>
                                <div className="click_gender" onClick={()=>this.ChooseGender("Others")}><input type="radio" name="radio_gender" id="others" value="Others" className="gender_radio" checked = {this.state.gender === 'Others'}/><label className="four col">Others</label></div>
                            </section>
                        </form>
                        <div className="col-xs-12 col-sm-12">
                            <button className=" btn formal_btn m_t_12" type="button" onClick={this.SaveProfile} onKeyPress={(e) => {this.handleKeypress(e)}}>Save Changes</button>
                        </div>
                </div>
                <div className="col-sm-3"></div>
            </div>
		);
		
	}
}

export default EditProfile;