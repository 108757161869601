import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../Lotties/62542-cool-chick.json';


class LoginTextBox extends Component 
{    
    constructor(props) {
        super(props);
    }
    
    render() {
            //console.log(this.props.show_login_subtext);
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                }
            };
            const styles = {
                checkAnimation: {
                    position: 'absolute',
                    textAlign: 'center',
                    height: 'auto',
                    width: '50%',
                    left: '20%',
                },
            } 
			return(
                        <div>
                            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                                {this.props.step == 1 ?
                                    <div className="float_text">
                                        {this.props.show_login_subtext ?
                                            <div className="white_text font_24">
                                                Hello there!
                                            </div>
                                            :
                                            <div className="white_text font_24">
                                                Verify your phone number
                                            </div>
                                        }
                                        {this.props.show_login_subtext ?
                                            <div className="grey_text font_15">
                                                Let’s get started with your mobile number
                                            </div>
                                            :
                                            <div className="grey_text font_15">
                                                Please enter your mobile number to login & proceed for checkout
                                            </div>
                                        }
                                        
                                    </div> 
                                    :
                                    <div className="float_text">
                                        <div className="white_text font_24">
                                            Nice to meet you!
                                        </div>
                                        <div className="grey_text font_15">
                                            Almost there, we are a verification away
                                        </div>
                                    </div> 
                                }
                                <center className="hidden-xs">
                                <Lottie 
                                    options={defaultOptions}
                                    style={styles.checkAnimation}
                                />
                                </center>
                            </div>
                        </div>
			);
    }
}

export default LoginTextBox;