import React, { Component } from "react";


class CheckoutCouponSection extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	select_coupon_id: '',
	    	coupon_name: '',
	    }
	    this.HandleOnChange = this.HandleOnChange.bind(this);
	    this.myChangeHandler = this.myChangeHandler.bind(this);
	    this.ClearCoupon = this.ClearCoupon.bind(this);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.clear_coupon_section != this.props.clear_coupon_section) {
    		this.ClearCoupon();
  		}
	}
	HandleOnChange(id, coupon_name) {
    	var selected_option = id;
    	var selected_coupon_name = coupon_name;
    	var coupon_id = selected_option.substring(7);
    	var coupon_name = selected_coupon_name.substring(7);
    	this.setState({ select_coupon_id: coupon_id, coupon_name: coupon_name});
    	document.getElementById("coupon_name_input").disabled = true;
  	}
  	myChangeHandler = (event) => {
  		let nam = event.target.name;
	    let val = event.target.value;
	    this.setState({[nam]: val});
	}
	ClearCoupon()
	{
		this.setState({ select_coupon_id: '', coupon_name: ''});
		document.getElementById("coupon_name_input").disabled = false;
	}
	render() {
		return (
				<div>
					{this.props.device == 'mob' ?
						<div className="select_address">
							<div id="myModal" className="modal-edited modal" data-spy="scroll"  data-offset="20">
								<div className="modal-coupon modal-content-edited modal-content">
								<div className="col-sm-3"></div>
									<div className="col-sm-6 padding_is_zero">
										<div className="address-modal-header modal-header-edited modal-header">
								    		<div className="col-xs-12 add_address_header">
								    			<button type="button" className="address_close close" aria-label="Close" onClick={() => this.props.show_coupon_section()}>
										        	<span aria-hidden="true">&times;</span>
										        </button>
								    			<center><p className="header_address_text">DISCOUNT COUPONS</p></center>
								    		</div>
								    	</div>
								    	{this.props.selected_best_deal ?
								    		<div className="show_best_deal_div">
								    			<div className="address-modal-body">
										    		<div className="col-xs-12 checkout_best_deal_div">
										    			<center>
										    				<img src="/images/Header/DiscountIllustration.svg" className="img-responsive"/>
										    				<p className="checkout_best_deal_text">COUPONS ARE NOT APPLICABLE <br/> ON THE OJ SUPER DEAL</p>
										    				<p className="checkout_best_deal_subtext">You are already getting the best deal and <br/> saving more than 65% on your purchase</p>
										    			</center>
										    		</div>
										    	</div>
								    		</div>
								    		:
								    		<div className="show_coupon_div">
										    	<div className="address-modal-body">
										    		<div className="col-xs-12">
										    			<input type="text" placeholder="Enter Coupon Code" id="coupon_name_input" className="coupon_code_input" name="coupon_name" value={this.state.coupon_name} onChange={this.myChangeHandler} autoComplete="nope"/>
										    		</div>
										    		<div className="col-xs-12 clear_coupon_div">
										    			<p className="applied_coupon_text" onClick={this.ClearCoupon}>Clear</p>
										    		</div>
										    		<div className="clearfix"></div>
										    		{this.props.coupon_details.length > 0 ?
										    			<div>
												    		<div className="col-xs-12">
												    			<p className="select_available_power_text">Available Coupons</p>
												    		</div>
												    		{this.props.coupon_details.map((item, i) => (
												    			<div className="col-xs-12 each_select_address" key={i} onClick={()=>this.HandleOnChange("coupon_"+item.id, "coupon_"+item.title)}>
										    						<input type="radio" id={"coupon_"+item.id} name="active_combo" checked = {this.state.select_coupon_id == item.id}/>
										    						<label className={this.state.select_coupon_id == item.id ? "select_coupon_label_checked" : "select_coupon_label"}>{item.title}</label>
										    						<br/>
																	<p className={this.state.select_coupon_id == item.id ? "select_coupon_subtext_checked" : "select_coupon_subtext"}>{item.description}</p>
																</div>
												    		))}
												    	</div>
												    	:
												    	null
												    }
										    	</div>
										    	<div className="col-xs-12 col-sm-6 modal-footer-edited modal-footer">
										    		<center><button className="apply_coupon_button"  onClick={() => this.props.coupon_id(this.state.select_coupon_id, this.state.coupon_name)}>APPLY COUPON</button></center>
										    	</div>
									    	</div>
								    	}
								    </div>
									<div className="col-sm-3"></div>
								</div>
							</div>
						</div>
					: 
						<div>
							{this.props.selected_best_deal ?
					    		<div className="show_best_deal_div">
					    			<div className="address-modal-body">
							    		<div className="col-xs-12 checkout_best_deal_div">
							    			<center>
							    				<img src="/images/Header/DiscountIllustration.svg" className="img-responsive"/>
							    				<p className="checkout_best_deal_text">COUPONS ARE NOT APPLICABLE <br/> ON THE OJ SUPER DEAL</p>
							    				<p className="checkout_best_deal_subtext">You are already getting the best deal and <br/> saving more than 65% on your purchase</p>
							    			</center>
							    		</div>
							    	</div>
					    		</div>
					    		:
					    		<div className="show_coupon_div">
							    	<div className="address-modal-body">
							    		<div className="col-xs-12">
							    			<input type="text" placeholder="Enter Coupon Code" id="coupon_name_input" className="coupon_code_input" name="coupon_name" value={this.state.coupon_name} onChange={this.myChangeHandler} autoComplete="nope"/>
							    		</div>
							    		<div className="col-xs-12 clear_coupon_div">
							    			<p className="applied_coupon_text" onClick={this.ClearCoupon}>Clear</p>
							    		</div>
							    		<div className="clearfix"></div>
							    		{this.props.coupon_details.length > 0 ?
							    			<div>
									    		<div className="col-xs-12">
									    			<p className="select_available_power_text">Available Coupons</p>
									    		</div>
									    		{this.props.coupon_details.map((item, i) => (
									    			<div className="col-xs-12 each_select_address" key={i} onClick={()=>this.HandleOnChange("coupon_"+item.id, "coupon_"+item.title)}>
							    						<input type="radio" id={"coupon_"+item.id} name="active_combo" checked = {this.state.select_coupon_id == item.id}/>
							    						<label className={this.state.select_coupon_id == item.id ? "select_coupon_label_checked" : "select_coupon_label"}>{item.title}</label>
							    						<br/>
														<p className={this.state.select_coupon_id == item.id ? "select_coupon_subtext_checked" : "select_coupon_subtext"}>{item.description}</p>
													</div>
									    		))}
									    	</div>
									    	:
									    	null
									    }
							    	</div>
							    	<div className="col-sm-12">
							    		<div className="col-sm-6"></div>
							    		<div className="col-sm-6 padding_is_zero">
							    			<center><button className="apply_coupon_button"  onClick={() => this.props.coupon_id(this.state.select_coupon_id, this.state.coupon_name)}>APPLY COUPON</button></center>
							    		</div>
							    	</div>
						    	</div>
					    	}
				    	</div>
			    	}
		    	</div>
			);
		
	}
}


export default CheckoutCouponSection;

/*
<div className="select_address">
	<div id="myModal" className="modal" data-spy="scroll"  data-offset="20">
		<div className="modal-coupon modal-content">
		<div className="col-sm-3"></div>
			<div className="col-sm-6 padding_is_zero">
				<div className="address-modal-header modal-header">
		    		<div className="col-xs-12 add_address_header">
		    			<button type="button" className="address_close close" aria-label="Close" onClick={() => this.props.show_coupon_section()}>
				        	<span aria-hidden="true">&times;</span>
				        </button>
		    			<center><p className="header_address_text">DISCOUNT COUPONS</p></center>
		    		</div>
		    	</div>
		    	{this.props.selected_best_deal ?
		    		<div className="show_best_deal_div">
		    			<div className="address-modal-body">
				    		<div className="col-xs-12 checkout_best_deal_div">
				    			<center>
				    				<img src="/images/Header/DiscountIllustration.svg" className="img-responsive"/>
				    				<p className="checkout_best_deal_text">COUPONS ARE NOT APPLICABLE <br/> ON THE OJ SUPER DEAL</p>
				    				<p className="checkout_best_deal_subtext">You are already getting the best deal and <br/> saving more than 65% on your purchase</p>
				    			</center>
				    		</div>
				    	</div>
		    		</div>
		    		:
		    		<div className="show_coupon_div">
				    	<div className="address-modal-body">
				    		<div className="col-xs-12">
				    			<input type="text" placeholder="Enter Coupon Code" id="coupon_name_input" className="coupon_code_input" name="coupon_name" value={this.state.coupon_name} onChange={this.myChangeHandler} autoComplete="nope"/>
				    		</div>
				    		<div className="col-xs-12 clear_coupon_div">
				    			<p className="applied_coupon_text" onClick={this.ClearCoupon}>Clear</p>
				    		</div>
				    		<div className="clearfix"></div>
				    		{this.props.coupon_details.length > 0 ?
				    			<div>
						    		<div className="col-xs-12">
						    			<p className="select_available_power_text">Available Coupons</p>
						    		</div>
						    		{this.props.coupon_details.map((item, i) => (
						    			<div className="col-xs-12 each_select_address" key={i} onClick={()=>this.HandleOnChange("coupon_"+item.id, "coupon_"+item.title)}>
				    						<input type="radio" id={"coupon_"+item.id} name="active_combo" checked = {this.state.select_coupon_id == item.id}/>
				    						<label className={this.state.select_coupon_id == item.id ? "select_coupon_label_checked" : "select_coupon_label"}>{item.title}</label>
				    						<br/>
											<p className={this.state.select_coupon_id == item.id ? "select_coupon_subtext_checked" : "select_coupon_subtext"}>{item.description}</p>
										</div>
						    		))}
						    	</div>
						    	:
						    	null
						    }
				    	</div>
				    	<div className="col-xs-12 col-sm-6 modal-footer">
				    		<center><button className="apply_coupon_button"  onClick={() => this.props.coupon_id(this.state.select_coupon_id, this.state.coupon_name)}>APPLY COUPON</button></center>
				    	</div>
			    	</div>
		    	}
		    </div>
			<div className="col-sm-3"></div>
		</div>
	</div>
</div>*/