import React, { Component } from "react";
import CheckoutHeader from './CheckoutHeader';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import Lottie from 'react-lottie';
import animationData from '../Lotties/New_LoadingAnimation.json';
import Cookies from 'js-cookie';

class CheckoutPay extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	cart_details: this.props.cart_details,
	    	address_id: this.props.address_id,
	    	coupon_id: this.props.coupon_id,
	    	access_token: localStorage.getItem("token"),
	    	track_source: Cookies.get('track_source'),
	    	response: '',
	    	hash: '',
	    	show_pay_button: true,
	    	redirectUrl: '/',
	    	redirect: false,
			access_code:'',
			show_redirecting: false,
			width: window.innerWidth,
	    }
	    this.PlaceOrder = this.PlaceOrder.bind(this);
	}
	componentWillMount() 
  	{
	  	window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount() {
	  	window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};
	PlaceOrder()
	{
		var address_id = this.state.address_id;
		var coupon_id = this.props.coupon_id;
		var payment_method = 2;
		const { width } = this.state;
  		const isMobile = width <= 768;
  		if(isMobile)
  		{
  			var device = "mobile";
  		}
  		else
  		{
  			var device = "desktop";
  		}
		var myHeaders = new Headers();
	    myHeaders.append("Authorization", "Bearer "+ this.state.access_token);
	    myHeaders.append("User-Source", device);
	    if(coupon_id == "")
	    {
	    	var data = JSON.stringify({"address_id" : address_id, "payment_method" : payment_method, "track_source": this.state.track_source});
	    }
	    else
	    {
	    	var data = JSON.stringify({"address_id" : address_id, "payment_method" : payment_method, "track_source": this.state.track_source, "coupon_id": coupon_id});
	    }
	    let url = process.env.REACT_APP_API_URL+'/v1/order';
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };

		fetch(url, requestOptions)
        .then(response => response.json())
		.then(data => {
            console.log("Result from order API"+data)
            if(data.result) 
            {
            	if(data.result.data.payment == true)
            	{
					this.PaymentPage(data.result.data.order_id)
            	}
            	else
            	{
            		this.setState({redirect: true, redirectUrl: '/success/'+data.result.data.order_id})
            	}
            }
            else
            {
            	this.setState({redirect: true, redirectUrl: '/fail/'+data.error.order_id})
            } 
        })
		this.setState({show_pay_button:false, show_redirecting: true})
	}
	PaymentPage(order_id)
	{
		const { width } = this.state;
  		const isMobile = width <= 768;
  		if(isMobile)
  		{
  			var device = "mobile";
  		}
  		else
  		{
  			var device = "desktop";
  		}
		var myHeaders = new Headers();
	    myHeaders.append("Authorization", "Bearer "+ this.state.access_token);
	    myHeaders.append("User-Source", device);

		//PAYU
		var data = JSON.stringify({"order_id" : order_id, "product_name" : "John Jacob"});
      	let url = process.env.REACT_APP_API_URL+'/v1/payment';
      	console.log(data);
		var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: data,
        redirect: 'follow',
        stage: 2,
        };

		fetch(url, requestOptions)
        .then(response => response.json())
		.then(data => this.setState({hash:data.hash,response: data.form_data}));

		//CCAvenue
		/*var data = JSON.stringify({"order_id" : order_id});
      	let url = process.env.REACT_APP_API_URL+'/v1/payment/ccavenue';
      
		var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow',
            stage: 2,
        };

		fetch(url, requestOptions)
        .then(response => response.json())
		.then(data => this.setState({access_code:data.form_data.access_code,response: data.form_data}));*/
	}
	componentDidUpdate() 
	{
		//PAYU
		if(this.state.hash != '')
		{
		   	const service_provider = document.getElementById("service_provider");
		 	document.getElementById('payment_form').submit();
		}

		//CCAvenue
		/*console.log('access_code=',this.state.access_code)
		console.log('post_url=',this.state.response.post_url)

		if(this.state.access_code != '')
		{
		    document.getElementById('ccavenue_form').submit();
		}*/
	}
	render() {
		const defaultOptions = {
	      loop: true,
	      autoplay: true,
	      animationData: animationData,
	      rendererSettings: {
	        preserveAspectRatio: "xMidYMid slice"
	      }
	    };
	    const styles = {
			checkAnimation: {
			    display: 'flex',
  				justifyContent: 'center',
  				alignItems: 'center',
  				textAlign: 'center',
  				minHeight: '60vh',
  				paddingTop: '65px',
			},
		}
		if(this.state.hash === '')
		{
			if(this.state.redirect == true) 
			{
				return <Redirect to={this.state.redirectUrl} />
			}
			else{
				var saved_credit_text = this.state.cart_details.billing.saved_credit/700 > 1 ? "Credits" : "Credit"; 
				return (
					<div className="col-sm-12 padding_is_zero">
						<div className="col-sm-12 padding_is_zero">
							<div className="checkout_details checkout_pay_details">
									
									<CheckoutHeader device={this.props.device} stage={this.props.stage} checkout_back={this.props.checkout_back}/>
										
									{!this.state.show_redirecting ?
									<div>
										<div className="col-sm-3"></div>
										<div className="col-xs-12 col-sm-6 checkout_body checkout_pay_list padding_is_zero">
											<div className="col-xs-12 each_checkout_pay">
												<div className="col-xs-2"><img src="./images/Header/Store_icon_paymentspage.svg" alt="checkout_icon" className="checkout_pay_icon"/></div>
												<div className="col-xs-10 checkout_pay_desc_text">
													{this.state.cart_details.billing.total_items == 1 ?
														<div>
														{this.state.cart_details.cart_list.map((each_item, index) => (
					    									<div className="" key={index}>
					    									{each_item.type == "product" ?
																<p className="checkout_pay_text">{this.state.cart_details.cart_list[index].name}</p>
																:
																null
															}
															</div>
														))}
														</div>
														:
														<div>
															{this.state.cart_details.cart_list.map((each_item, index) => (
						    									<div className="" key={index}>
						    									{each_item.type == "product" && index==1 ?
																	<p className="checkout_pay_text">{this.state.cart_details.cart_list[index].name} and {this.state.cart_details.billing.total_items-1} others</p>
																	:
																	null
																}
																</div>
															))}
														
														</div>
													}
													<div className="checkout_pay_subtext"><p>{this.state.cart_details.billing.total_items} items</p></div>
												</div>
											</div>
											<div className="col-xs-12 checkout_pay_vertical_line1">
											</div>
											<div className="col-xs-12 each_checkout_pay">
												<div className="col-xs-2"><img src="./images/Header/StandardDeliveryIcon.svg" alt="checkout_icon" className="checkout_pay_small_icon"/></div>
												<div className="col-xs-1 checkout_pay_horizontal_line"></div>
												<div className="col-xs-9 checkout_pay_desc_text"><p className="checkout_pay_subtext">Standard Delivery</p></div>
											</div>
											<div className="col-xs-12 checkout_pay_vertical_line2">
											</div>
											<div className="col-xs-12 each_checkout_pay">
												<div className="col-xs-2"><img src="./images/Header/HomeAddressIcon.svg" alt="checkout_icon" className="checkout_pay_icon"/></div>
												<div className="col-xs-10 checkout_pay_desc_text">
													
													{this.state.cart_details.user_data.address.map((item, i) => (
								    					<div key={i}>
								    					{item.id == this.state.address_id ?
								    						<div>
								    							<p className="checkout_pay_text">{item.name}'s  Address</p>
								    							<p className="checkout_pay_subtext_bold checkout_pay_subtext">{item.phone} | {this.state.cart_details.user_data.email}</p>
								    							<p className="checkout_pay_subtext">{item.shipping_address+", "+item.ship_city+", "+item.ship_state+", "+item.ship_code}</p>
								    						</div>
								    						:
								    						null
								    					}
								    					</div>
								    				))}
								    				
												</div>
											</div>
											<div className="col-xs-12 cart_prescription_text">
												Enter power for eyeglasses after placing your order
					    					</div>
					    					<div className="col-xs-12 checkout_bill_pay padding_is_zero">
												<div className="col-xs-12  checkout_bill_pay_div">
													<div className="bill_heading col-xs-9">
														<p className="bill_details_text">Total Bill Details</p><p className="bill_details_text_sub">(Total Including GST)</p>
													</div>
													<div className="bill_heading col-xs-3 bill_heading">
														<p className="checkout_each_product_amount">Rs. {this.state.cart_details.billing.payable_price}</p>
													</div>
												</div>
											</div>
											{this.state.cart_details.cart_list.map((each_item, index) => (
					    						<div className="" key={index}>
						    						{ each_item.type == "subscription" && each_item.subscription_id == 2 ?
						    							
								    					<div className="checkout_subscription_plan col-xs-12">
								    						<div className="col-xs-2 checkout_coupon_icon">
							    								<img src="./images/Header/CouponCodeIcon_CheckoutPage1.svg" alt="coupon_icon" />
							    							</div>
							    							
							    							<div className="col-xs-7 padding_is_zero">
							    								<p className="checkout_congrats_text">Congratulations!</p>
																<p className="checkout_sub_plan_text">You have a new OJ SUPER deal</p>
																<p className="checkout_new_plan_subtext">You are getting 3 eyeglasses with premium lens at just Rs. <s>4500</s> 2100 (700 each). No additional or hidden charges. Pay now to get 3 credits and choose from a curated collection of eyeglasses, revamped every month. Your OJ credits are valid for 1 year. <a href="/subscription-plan" target="_blank"><u className="checkout_subscription_details_text">Details</u></a></p>
															</div>
														</div>
								    				:
						    						null
						    						}
						    						{this.state.credits_in_wallet >= this.state.cart_details.billing.total_items  && index==0?
						    							<div className="checkout_subscription_plan col-xs-12">
								    						<div className="col-xs-2">
							    								<img src="./images/Header/CouponCodeIcon_CheckoutPage1.svg" alt="coupon_icon" />
							    							</div>
							    							
							    							<div className="col-xs-8 padding_is_zero">
							    								{this.state.credits_in_wallet == this.state.cart_details.billing.total_items ?
							    									<p className="checkout_congrats_text">Congrats! You have paid using OJ Credits</p>
							    									:
							    									<div>
							    										<p className="checkout_congrats_text">Congrats! You have paid using OJ Credits</p>
							    										<p className="checkout_new_plan_subtext">{this.state.credits_in_wallet - this.state.cart_details.billing.total_items > 1 ?  this.state.credits_in_wallet - this.state.cart_details.billing.total_items+ " credits left in your OJ Account" : this.state.credits_in_wallet - this.state.cart_details.billing.total_items+ " credit left in your OJ Account"}</p>
							    									</div>
							    								}
							    							</div>
															<div className="col-xs-2 padding_is_zero"></div>
							    						</div>
								    				:
						    						null
						    						}
					    						</div>
			    							))}
											<div className="col-xs-12 ">
												{this.state.show_pay_button ?
									    		<center><button className="btn add_address_button" onClick={this.PlaceOrder}>Proceed To Pay</button></center>
									    		:
									    		<center><button className="btn add_address_button">Redirecting...</button></center>
												}
									    	</div>
										</div>
										<div className="col-sm-3"></div>
									</div>
									:
									<div>
										<Lottie options={defaultOptions} width={200} style={styles.checkAnimation} />
										<center>
											<p className="checkout_redirect_text">Hang in there!</p>
											<p className="checkout_redirect_subtext">You are being redirected to another page, it might take upto 30 seconds.</p>
											<p className="checkout_redirect_subtext_small">Please do not go back or press the home button</p>
										</center>
									</div>
								}

									
							</div>
						</div>
						
					</div>
							
				);
			}
		}
		else
		{
			return(
				<div>
				 	{/* PAYU */}
				 	<form onSubmit={this.handleSubmit} id="payment_form" method="POST" action={this.state.response.post_url}>
				 		<input type="hidden" name="hash" value={this.state.hash}  />
				        <input type="hidden" name="key" value={this.state.response.key}  />
				        <input type="hidden" name="txnid" value={this.state.response.txnid}  />
				        <input type="hidden" name="amount" value={this.state.response.amount}  />
				        <input type="hidden" name="productinfo" value={this.state.response.productinfo}  />
				        <input type="hidden" name="firstname" value={this.state.response.firstname}  />
				        <input type="hidden" name="email" value={this.state.response.email}  />
				        <input type="hidden" name="udf1" value={this.state.response.udf1}  />
				 		<input type="hidden" name="udf2" value={this.state.response.udf2}  />
						<input type="hidden" name="udf3" value={this.state.response.udf3}  />
				 		<input type="hidden" name="udf4" value={this.state.response.udf4}  />
						<input type="hidden" name="udf5" value={this.state.response.udf5}  />
				        <input type="hidden" name="phone" value={this.state.response.phone}  />
				        <input type="hidden" name="surl" value={this.state.response.surl}  />
				        <input type="hidden" name="furl" value={this.state.response.furl}  />
				        <input type="hidden" name="post_url" value={this.state.response.post_url}  />
				    </form>

					{/*CCAVenue
				    <form id="ccavenue_form" method="POST" action={this.state.response.post_url}>
						<input type="hidden" name="encRequest" value={ this.state.response.encrypted_data } />
						<input type="hidden" name="access_code" value={ this.state.access_code } />
					</form>*/}
				 </div>

				
				
			);
		}
	}
}


export default CheckoutPay;