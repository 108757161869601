import React, { Component } from "react";
import Header from "../Common/Header";
import PageContent from "./PageContent";
import HomePage from "./HomePage";
import Footer from '../Common/Footer';
import store from '../../Redux/store';

class Homepage extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	
	    }
	}
	render() {
		const { error, loading, products } = this.props;
		console.log(products);
		if (error) {
	      return <div>Error! {error.message}</div>;
	    }

	    if (loading) {
	      return <div>Loading...</div>;
	    }
		return (
			<div>
				<Header color={"#160D08"}/>
				<HomePage />
				<Footer />
			</div>
		);
	}
}

export default Homepage;