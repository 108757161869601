import React, { Component } from "react";
import Header from "../Common/Header";

class LaunchRegistration extends Component 
{
	render() {
		return(
			
			<div>
				<Header color={"#160D08"}/> 
				<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeVsR5lLOH9fm3GvbIC2x2pebcA1xeowpTurA4AHSyz96OcfQ/viewform" className="ar_iframe"></iframe>
		    </div>
			
		);
		
	}
}

export default LaunchRegistration;