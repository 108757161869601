import React, { Component } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { Link } from "react-router-dom";
import { create } from "@lottiefiles/lottie-interactivity";
import { Waypoint } from "react-waypoint";
import Lottie from "react-lottie";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
// import ScrollAnimation from 'react-animate-on-scroll';
import scrollDownAnimation from "../Lotties/scroll_down.json";

class WhyOjoswear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      set_animation: true,
      isStopped: true,
      currentScrollHeight: 0,
      width: window.innerWidth,
      firstLottie: "firstLottie_show",
      secondLottie: "secondLottie_show",
      explore_button: "ultra_light_button_mob_none",
    };
    this.myRef = React.createRef();
    this.myRef2 = React.createRef();
    this.handleScrollMob1 = this.handleScrollMob1.bind(this);
  }
  componentDidMount() {
    this.myRef2.current.addEventListener("load", function (e) {
      create({
        mode: "scroll",
        player: "#secondLottie",
        container: "#container_mob",
        actions: [
          {
            visibility: [0.1],
            type: "stop",
            frames: [0],
          },
          {
            visibility: [0.5, 5],
            type: "seek",
            frames: [0, 1896],
          },
        ],
      });
    });

    this.myRef.current.addEventListener("load", function (e) {
      create({
        mode: "scroll",
        player: "#firstLottie",
        container: "#container",
        actions: [
          {
            visibility: [0, 0.1],
            type: "stop",
            frames: [0],
          },
          {
            visibility: [0.7, 5],
            type: "seek",
            frames: [0, 1896],
          },
        ],
      });
    });

    window.addEventListener("scroll", this.handleScrollMob1, { passive: true });
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollMob1);
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  handleScrollMob1(e) {
    const scrollY = window.scrollY;
    //const scrollTop = this.myRefScroll.current.scrollTop
    //console.log(scrollY);
    const { width } = this.state;
    const isMobile = width <= 768;
    if (isMobile) {
      if (scrollY > 1000 && scrollY < 6500) {
        this.setState({ explore_button: "ultra_light_button_mob" });
      } else {
        this.setState({ explore_button: "ultra_light_button_mob_none" });
      }
    }
  }
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: scrollDownAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const styles = {
      scrollArrowAnimation: {
        margin: "0px",
      },
    };
    return (
      <div className="homepage_body">
        <Header color={"#160D08"} />
        {/* Mobile */}
        <div
          id="container_mob"
          className="container_web hidden-sm hidden-lg hidden-md"
        >
          <div id="content_mob" className="content_mob">
            <div className="col-xs-12" className={this.state.secondLottie}>
              <lottie-player
                ref={this.myRef2}
                id="secondLottie"
                src="/images/Header/why-ojoswear-mob.json"
              ></lottie-player>
            </div>
            <div
              className={
                "col-xs-12 hidden-sm hidden-md hidden-lg ojos_unique_mob padding_is_zero"
              }
            >
              <a href="/explore-ojos">
                <button
                  className={this.state.explore_button}
                  id="explore_button"
                >
                  Explore OJOS
                </button>
              </a>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        {/* Desktop */}
        <div id="container" class="container hidden-xs padding_is_zero">
          <div id="content" class="content_web hidden-xs padding_is_zero">
            <div
              className={
                "col-sm-12 hidden-xs animation_area " + this.state.firstLottie
              }
            >
              <lottie-player
                ref={this.myRef}
                id="firstLottie"
                src="/images/Header/why-ojoswear-web.json"
              ></lottie-player>
            </div>
          </div>
        </div>

        <div className="clearfix"></div>

        <div class="footer_homepage">
          <div className="col-sm-12 col-xs-12 footer_link_div">
            <div className="col-sm-12 col-xs-12 footer_each_col">
              <p className="footer_header_text">Contact Us</p>
            </div>
            <div className="col-sm-1 col-xs-4 footer_each_col">
              <a href="mailto:contact@ojoswear.com" target="_blank">
                <center className="last_slide_contact_footer">
                  <img src="/images/Header/Icon material-mail.svg" />
                  <p className="last_slide_contact_footer_subtext">Mail Us</p>
                </center>
              </a>
            </div>
            <div className="col-sm-1 col-xs-4 footer_each_col">
              <a href="https://wa.me/+917669800711" target="_blank">
                <center className="last_slide_contact_footer">
                  <img src="/images/Header/Icon ionic-logo-whatsapp.svg" />
                  <p className="last_slide_contact_footer_subtext">
                    WhatsApp Us
                  </p>
                </center>
              </a>
            </div>
            <div className="clearfix"></div>
            {/*<div className="col-sm-3 col-xs-6 footer_each_col footer_contact_col">
                            <img src="/images/Header/Call_Filled.svg" className="footer_icon"/><p className="footer_subtext">+91-9876543210</p>
                        </div>*/}
          </div>
          <div className="col-sm-12 col-xs-12 footer_link_div">
            <div className="col-sm-4 col-xs-6 footer_each_col">
              <p className="footer_header_text">Download Our App</p>
            </div>
            <div className="col-sm-4 col-xs-6 footer_each_col align_right">
              <p className="footer_header_text">Let’s Connect On</p>
            </div>
            <div className="col-sm-4"></div>
            <div className="clearfix"></div>
            <div className="col-sm-4 col-xs-6 footer_each_col padding_left_0">
              <div className="col-sm-1 col-xs-4 footer_each_col footer_contact_col">
                <a href="https://bit.ly/3Ivkivt" target="_blank">
                  <img
                    src="/images/Header/icons8-google-play.svg"
                    className="footer_icon"
                  />
                </a>
              </div>
              <div className="col-sm-1 col-xs-4 footer_each_col footer_contact_col">
                <a href="https://apple.co/3vxJ4H6" target="_blank">
                  <img
                    src="/images/Header/icons8-app-store.svg"
                    className="footer_icon"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-4 col-xs-6 footer_each_col align_right">
              <a href="https://www.facebook.com/ojoswear" target="_blank">
                <img
                  src="/images/Header/FacebookIconFooter.svg"
                  className="footer_icon"
                />
              </a>
              <a href="https://www.instagram.com/ojoswear/" target="_blank">
                <img
                  src="/images/Header/InstagramIconFooter.svg"
                  className="footer_icon"
                />
              </a>
              <a href="https://twitter.com/OjosWear" target="_blank">
                <img
                  src="/images/Header/TwitterIconFooter.svg"
                  className="footer_icon"
                />
              </a>
            </div>
            <div className="col-sm-4"></div>
          </div>
          <div className="col-sm-12 col-xs-12 footer_link_div">
            <div className="col-sm-4 col-xs-6 footer_each_col">
              <p className="footer_header_text">Explore OJOS Wear</p>
              <p>
                <a
                  href="/products/eyeglasses/male?path_source=male_listing"
                  target="_blank"
                  className="footer_subtext"
                >
                  Men's Eyeglasses
                </a>
              </p>
              <p>
                <a
                  href="/products/eyeglasses/female?path_source=female_listing"
                  target="_blank"
                  className="footer_subtext"
                >
                  Women's Eyeglasses
                </a>
              </p>
              <p>
                <a
                  href="/products/sunglasses"
                  target="_blank"
                  className="footer_subtext"
                >
                  Sunglasses
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-xs-6 footer_each_col">
              <p className="footer_header_text">Our Services</p>
              <p>
                <a href="/account" target="_blank" className="footer_subtext">
                  My Account
                </a>
              </p>
              <p>
                <a href="/order" target="_blank" className="footer_subtext">
                  Orders & Shipping
                </a>
              </p>
              <p>
                <a
                  href="/contact-us"
                  target="_blank"
                  className="footer_subtext"
                >
                  Help
                </a>
              </p>
              <p>
                <a href="/faq" target="_blank" className="footer_subtext">
                  FAQs
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-xs-12 footer_each_col">
              <p className="footer_header_text">About OJOS Wear</p>
              <p>
                <a
                  href="https://forms.gle/XYbGDvGM2YBqyVFu6"
                  target="_blank"
                  className="footer_subtext"
                >
                  We are hiring!{" "}
                  <small className="apply_now_text"> Apply Now</small>
                </a>
              </p>
              <p>
                <a href="/about-us" target="_blank" className="footer_subtext">
                  Get to know us
                </a>
              </p>
              <p>
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  className="footer_subtext"
                >
                  Terms & Conditions
                </a>
              </p>
              <p>
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className="footer_subtext"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-xs-12 footer_link_div">
            <center>
              <img src="/images/Header/OJ_footer.svg" />
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default WhyOjoswear;
