import React, { Component } from "react";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import swal from 'sweetalert';
import { Redirect } from 'react-router';

class PowerPage extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	token: localStorage.getItem("token"),
	    	user_id: '',
	    	product_id: '',
	    	order_id: '',
	    	show_cylinder: false,
	    	sph_right: '0.00',
	    	sph_left: '0.00',
	    	cyl_right: '0.00',
	    	cyl_left: '0.00',
	    	axis_right: '0',
	    	axis_left: '0',
	    	pd_right: '0',
	    	pd_left: '0',
	    	loading: true,
	    	new_power: true,
	    	select_power: [],
	    	select_power_id: '',
	    	redirect: false,
	    	redirectUrl: '',
	    	cyl_values: [],
	    	pd_values: [],
	    	sph_values: [],
	    }
	    this.SelectPower = this.SelectPower.bind(this);
	    this.CheckCylinder = this.CheckCylinder.bind(this);
	    this.SubmitPower = this.SubmitPower.bind(this);
	    this.ClosePower = this.ClosePower.bind(this);
	    this.SelectSubmitPower = this.SelectSubmitPower.bind(this);
	    this.AddNewPower = this.AddNewPower.bind(this);
	}
	componentDidMount()
	{
		const product_id = this.props.match.params.product_id;
		const order_id = this.props.match.params.order_id;
		var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.state.token);
        var requestOptions = {
            headers: myHeaders,
        };
        fetch(process.env.REACT_APP_API_URL+'/v1/user', requestOptions)
        .then(response => response.json())
        .then(
        (res) => {
            console.log(res);
            this.setState({user_id: res.result.id, product_id: product_id, order_id:order_id});
            fetch(process.env.REACT_APP_API_URL+'/v1/user/powers', requestOptions)
	        .then(response => response.json())
	        .then(
	        (data) => {
	            console.log(data);
	            if(data.result.data.length > 0)
	            {
	            	this.setState({new_power: false, select_power: data.result.data, select_power_id: data.result.data[0].id, loading: false});
	            }
	            else
	            {
	            	this.setState({new_power: true, loading: false});
	            }
	        })
        });

        let url1 = process.env.REACT_APP_API_URL+'/v1/product/'+product_id+'/power';
		fetch(url1)
        .then(response => response.json())
        
        .then(data => {
        	console.log(data);
        	if(data.success == true)
        	{
        		this.setState({cyl_values: data.data.cyl_values, pd_values: data.data.pd_values, sph_values: data.data.sph_values}, () => {
					this.setState({new_power: !this.state.new_power, loading: false});
				});
        	}
        	else
        	{
        		swal("Power details not fetched for this product")
        		this.setState({loading: false});
        	}
        })
	}
	CheckCylinder()
	{
		this.setState({show_cylinder: !this.state.show_cylinder});
	}
	SelectPower = (event) => {
	    let nam = event.target.name;
	    let val = event.target.value;
	    this.setState({[nam]: val});
	}
	ClosePower()
	{
		this.setState({'redirect': true, 'redirectUrl': '/order'});
	}
	AddNewPower()
	{
		this.setState({new_power: !this.state.new_power});
		/*this.setState({loading: true});
		const product_id = this.props.match.params.product_id;
		const order_id = this.props.match.params.order_id;
		let url = process.env.REACT_APP_API_URL+'/v1/product/'+product_id+'/power';
		fetch(url)
        .then(response => response.json())
        
        .then(data => {
        	console.log(data);
        	if(data.success == true)
        	{
        		this.setState({cyl_values: data.data.cyl_values, pd_values: data.data.pd_values, sph_values: data.data.sph_values}, () => {
					this.setState({new_power: !this.state.new_power, loading: false});
				});
        	}
        	else
        	{
        		swal("Power details not fetched for this product")
        		this.setState({loading: false});
        	}
        })*/
		
	}
	SelectSubmitPower()
	{
		var power_id = this.state.select_power_id;
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer "+ this.state.token);
		let url = process.env.REACT_APP_API_URL+'/v1/user/'+this.state.user_id+'/order/'+this.state.order_id+'/product/'+this.state.product_id+'/power/'+power_id;
		var requestOptions = {
			method: 'PUT',
			headers: myHeaders,
			redirect: 'follow'
			    };
		fetch(url, requestOptions)
        .then(response => response.json())
		.then((data) => {
				console.log(data);
				if(data.success == true)
				{
					swal("Eye Power Details Updated.").then(value => {
					console.log(value);
					if(value== true)
					{
						var redirectUrl = '/order/'+this.state.order_id+'/product/'+this.state.product_id;
						this.setState({'redirect': true, 'redirectUrl': redirectUrl});
					}
				    });
				}
				else
				{
					swal("Sorry couldn't update eye power")
				}
			}
		)
	}
	SubmitPower()
	{
		if((this.state.axis_left  >= 0 && this.state.axis_left <= 180) && (this.state.axis_right  >= 0 && this.state.axis_right <= 180))
		{
			if(this.state.pd_left !=0 && this.state.pd_right !=0 )
			{
				var myHeaders = new Headers();
				myHeaders.append("Authorization", "Bearer "+ this.state.token);
				
				var data = JSON.stringify({"power_type" : "single_vision","data" : {
		        "left": {
		        		"axis": this.state.axis_left,
			            "sph": this.state.sph_left,
			            "cyl": this.state.cyl_left,
			            "pd": this.state.pd_left
		        		},
		        "right":{
		        		"axis": this.state.axis_right,
			            "sph": this.state.sph_right,
			            "cyl": this.state.cyl_right,
			            "pd": this.state.pd_right
		        		}		
		        }});
		        console.log(data);
				let url = process.env.REACT_APP_API_URL+'/v1/user/power';

				var requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: data,
					redirect: 'follow'
					    };
				fetch(url, requestOptions)
		        .then(response => response.json())
				.then((data) => {
					if(data.status == true)
					{
						let url = process.env.REACT_APP_API_URL+'/v1/user/'+this.state.user_id+'/order/'+this.state.order_id+'/product/'+this.state.product_id+'/power/'+data.power_id;
						var requestOptions = {
							method: 'PUT',
							headers: myHeaders,
							redirect: 'follow'
							    };
						fetch(url, requestOptions)
				        .then(response => response.json())
						.then((data) => {
								console.log(data);
								if(data.success == true)
								{
									swal("Eye Power Details Updated.").then(value => {
										console.log(value);
										if(value== true)
										{
											var redirectUrl = '/order/'+this.state.order_id+'/product/'+this.state.product_id;
											this.setState({'redirect': true, 'redirectUrl': redirectUrl});
										}
									    
									});
								}
								else
								{
									swal("Sorry couldn't update eye power")
								}
							}
						)
					}
					else
					{
						swal(data.msg);
					}
				})
			}
			else
			{
				swal("Select PD value");
			}
			
		}
		else
		{
			swal("Axis can be from 0 to 180");
		}
		
	}
	HandleOnChange(id) {
    	var selected_option = id;
    	var power_id = selected_option.substring(6);
    	this.setState({ select_power_id: power_id});
  	}
	render() {
		if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
		return (

			<div>
			{!this.state.loading ?
				<div>
					{this.state.new_power ? 
						<div className="add_new_address">
							<Header color={"#160D08"}/> 
							<div id="myModal" className="modal" data-spy="scroll"  data-offset="20">
								<div className="modal-address modal-content">
									<div className="col-sm-3"></div>
									<div className="col-sm-6 padding_is_zero">
										<div className="address-modal-header modal-header">
									    	<div className="col-xs-12 add_address_header">
									    		<button type="button" className="address_close close" aria-label="Close" onClick={this.ClosePower}>
											       	<span aria-hidden="true">&times;</span>
											    </button>
												<center><p className="header_address_text">Enter Eye Power Details</p></center>
								    		</div>
					    				</div>
					    				<div className="power_modal_body modal-body">
					    					<div className="add_address_body">
								    			{/*<p className="col-xs-12 power_text">Enter your eye power details</p>*/}
								    			<div className="col-xs-12 power_option_section">
									    			<p className="power_subtext">POWER (SPH)</p>
									    			<div className="col-xs-6">
									    				<p className="power_left_right">LEFT</p>
									    				<select id="sph_left" name="sph_left" className="power_box" onChange={this.SelectPower}>
											    			<option>Select</option>
											    			{this.state.sph_values.map((each_item, index) => (
											    				<option value={each_item}>{each_item}</option>
											    			))}
											    			
														</select>
									    			</div>
									    			<div className="col-xs-6">
									    				<p className="power_left_right">RIGHT</p>
									    				<select id="sph_right" name="sph_right" className="power_box" onChange={this.SelectPower}>
											    			<option>Select</option>
											    			{this.state.sph_values.map((each_item, index) => (
											    				<option value={each_item}>{each_item}</option>
											    			))}
														</select>
									    			</div>
								    			</div>
								    			<div className="col-xs-12 power_option_section">
									    			<p className="power_subtext">PD </p>
									    			<div className="col-xs-6">
									    				<p className="power_left_right">LEFT</p>
									    				{/*<input type="text" name="pd_left" className="power_box" onChange={this.SelectPower}/>*/}
									    				<select  name="pd_left" className="power_box" onChange={this.SelectPower}>
											    			<option>Select</option>
											    			{this.state.pd_values.map((each_item, index) => (
											    				<option value={each_item}>{each_item}</option>
											    			))}
														</select>
									    			</div>
									    			<div className="col-xs-6">
									    				<p className="power_left_right">RIGHT</p>
									    				{/*<input type="text" name="pd_right" className="power_box" onChange={this.SelectPower}/>*/}
									    				<select  name="pd_right" className="power_box" onChange={this.SelectPower}>
											    			<option>Select</option>
											    			{this.state.pd_values.map((each_item, index) => (
											    				<option value={each_item}>{each_item}</option>
											    			))}
														</select>
									    			</div>
								    			</div>
								    			{this.state.show_cylinder ?
								    				<div>
									    				<div className="col-xs-12 power_option_section">
											    			<p className="power_subtext">CYLINDER</p>
											    			<div className="col-xs-6">
											    				<p className="power_left_right">LEFT</p>
											    				<select id="cyl_left" name="cyl_left" className="power_box" onChange={this.SelectPower}>
													    			<option>Select</option>
																  	{this.state.cyl_values.map((each_item, index) => (
													    				<option value={each_item}>{each_item}</option>
													    			))}
																</select>
											    			</div>
											    			<div className="col-xs-6">
											    				<p className="power_left_right">RIGHT</p>
											    				<select id="cyl_right" name="cyl_right" className="power_box" onChange={this.SelectPower}>
													    			<option>Select</option>
																  	{this.state.cyl_values.map((each_item, index) => (
													    				<option value={each_item}>{each_item}</option>
													    			))}
																</select>
											    			</div>
										    			</div>
										    			<div className="col-xs-12 power_option_section">
											    			<p className="power_subtext">AXIS <small>(value ranging from 0 to 180 degrees)</small></p>
											    			<div className="col-xs-6">
											    				<p className="power_left_right">LEFT</p>
											    				<input type="text" name="axis_left" className="power_box" onChange={this.SelectPower}/>
											    			</div>
											    			<div className="col-xs-6">
											    				<p className="power_left_right">RIGHT</p>
											    				<input type="text" name="axis_right" className="power_box" onChange={this.SelectPower}/>
											    			</div>
										    			</div>
									    			</div>
									    			:
									    			null
								    			}
								    			<div className="col-xs-12">
								    				<input type="checkbox" id="cylinder" name="cylinder" value="cylinder" className="cylinder_checkbox" onClick={this.CheckCylinder}/>
													<label for="cylinder" className="cylinder_checkbox_text"> I have cylinder power too</label>
								    			</div>
								    		</div>
										</div>
					    				<div className="col-xs-12 col-sm-6 modal-footer">
					    					<center><button className="save_address_button" onClick={this.SubmitPower}>Submit Eye Power</button></center>
								    	</div>
							    	</div>
							    	<div className="col-sm-3"></div>
			    				</div>
			    			</div>
			    		</div>
			    		: 
			    		<div className="select_address">
			    			<Header color={"#160D08"}/> 
							<div id="myModal" className="modal" data-spy="scroll"  data-offset="20">
								<div className="modal-address modal-content">
								<div className="col-sm-3"></div>
									<div className="col-sm-6 padding_is_zero">
										<div className="address-modal-header modal-header">
								    		<div className="col-xs-12 add_address_header">
								    			<button type="button" className="address_close close" aria-label="Close" onClick={this.ClosePower}>
										        	<span aria-hidden="true">&times;</span>
										        </button>
								    			<center><p className="header_address_text">Select Eye Power</p></center>
								    		</div>
								    	</div>
								    	<div className="power-modal-body modal-body">
								    		{/*<p className="select_address_text">SELECT YOUR POWER</p>*/}
								    		{this.state.select_power.map((item, i) => (
								    			<div className="col-xs-12 each_select_address" key={i} onClick={()=>this.HandleOnChange("power_"+item.id)}>
						    						<input type="radio"  name="active_combo" checked = {this.state.select_power_id == item.id}/>
													<label className="select_address_label">SINGLE VISION</label>
													<br/>
													<div className="col-sm-6 col-xs-6">
														<p className="select_power_subtext"><u>Left</u><br/> Spherical: {item.left.sph}<br/> Cylinderical: {item.left.cyl}<br/> PD: {item.left.pd} <br/> Axis: {item.left.axis}</p>
													</div>
													<div className="col-sm-6 col-xs-6">
														<p className="select_power_subtext"><u>Right</u><br/> Spherical: {item.right.sph}<br/> Cylinderical: {item.right.cyl}<br/> PD: {item.right.pd} <br/> Axis: {item.right.axis}</p>
													</div>
												</div>
								    		))}
								    		<div className="col-xs-12 select_add_address_div">
					    						<a data-toggle="collapse" href="#collapse1" ><p className="new_address_accordion" onClick={this.AddNewPower}>ADD NEW EYE POWER</p></a>
					    					</div>
								    	</div>
								    	<div className="col-xs-12 col-sm-6 modal-footer">
								    		<center><button className="save_address_button" onClick={this.SelectSubmitPower}>Select This Eye Power</button></center>
					    				</div>
				    				</div>
				    				<div className="col-sm-3"></div>
								</div>
							</div>
						</div>
					}
				</div>
				:
				<div> 
	      			<Header color={"#160D08"}/> 
	      			<Loading />
	      		</div>
			}
			</div>
		);
	}
}


export default PowerPage;