import React, { Component }from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { Link } from 'react-router-dom';
import store from '../../Redux/store';
import Footer from '../Common/Footer';
import swal from 'sweetalert';
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import Lottie from 'react-lottie';
import animationData from '../Lotties/ar_try_on_loading.json';
import ProductARDetailsBody from './ProductARDetailsBody';
import ProductARFrame from "./ProductARFrame"
import $ from "jquery";

var arArray=[];
	var cursor=0;

class ProductPage extends Component 
{
	
 	constructor(props) {
    	super(props);
    	this.state = {
    		primary_image: [],
    		secondary_image: [],
    		theme_color: [],
    		lenskart_product_id: [],
    		product_count: '',
    		product_details: [{model_images:[], glass_images:[]}],
    		color: '',
    		width: window.innerWidth,
    		show_desc: false,
    		path_source: '',
    		page_index: 0,
    		show_loading_lottie: 1,
    		loading_lottie_web: 1,
    		items: Array.from({ length: 20 }),
    		current_page: '',
    		total_product: '',
    		total_pages: '',
    		per_page: '',
    		gender: '',
    		next_page_url: '',
    		last_page_url: '',
    		last_page: 1,
    		show_filters: false,
    		filters: [],
    		show_products: false,
    		filter_last_page: '',
    		show_loading_filter: false,
    		show_applied_filters: false,
    		show_no_product: false,
    		imageDataURL: null,
    		cameraNumber: 0,
    		show_iframe: 'hide_iframe_ar',
    		product_increment: 2,
    		product_details_array: [{model_images:[], glass_images:[]}],
    		first_pointer: 0,
    		second_pointer: 2,
    		stateArArray:[],
    		frame_reload: 0,
    		slide_index: 0,
    		
    	};
    	this.ChangePicture = this.ChangePicture.bind(this);
    	this.ChangeColour = this.ChangeColour.bind(this);
    	this.ChangeColor = this.ChangeColor.bind(this);
    	this.onLeave = this.onLeave.bind(this);
    	this.afterLoad = this.afterLoad.bind(this);
    	this.afterSlideLoad = this.afterSlideLoad.bind(this);
    	this.AvailablePower = this.AvailablePower.bind(this);
    	this.HideCameraBackground = this.HideCameraBackground.bind(this);
  	}

/*
  	componentWillMount() 
  	{
	  window.addEventListener('resize', this.handleWindowSizeChange);
	}
*/
  	componentDidMount()
  	{
  		window.addEventListener('resize', this.handleWindowSizeChange);

  		if(window.location.pathname == "/products/eyeglasses/female")
        {
            var gender = window.location.pathname.substring(21, 27)
            var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname +'?path_source='+gender+'_listing'+ window.location.hash;    
            //window.history.pushState({ path: refresh }, '', refresh);
            this.setState({ path_source: gender+'_listing', gender: gender});
        }
        if(window.location.pathname == "/products/eyeglasses/male")
        {
            var gender = window.location.pathname.substring(21, 25)
            var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname +'?path_source='+gender+'_listing'+ window.location.hash;    
            //window.history.pushState({ path: refresh }, '', refresh);
            this.setState({ path_source: gender+'_listing', gender: gender});
        }

  		if (window.location.href.indexOf('frame_type') > -1 || window.location.href.indexOf('frame_shape') > -1 || window.location.href.indexOf('frame_size') > -1 || window.location.href.indexOf('frame_colour') > -1 || window.location.href.indexOf('frame_material') > -1)
		{ 
			//this.CallFiltersLoadMore(window.location.search);
			const state = store.getState();
			console.log(state);
			let product_details = state.products.items.products;
		  	let total_pages = Math.ceil(state.products.items.total_product_count/state.products.items.per_page);

	  		this.setState({
	  			product_count: state.products.items.total_product_count, 
	  			product_details: product_details,
	  			current_page: state.products.items.current_page, 
	  			total_product: state.products.items.total_product_count, 
	  			per_page: state.products.items.per_page, 
	  			total_pages: total_pages,
	  			show_products: true,
	  			show_applied_filters: true,
	  		});
		}
		else
		{
			const state = store.getState();
	  		let product_details = state.products.items.products;
	  		let total_pages = Math.ceil(state.products.items.total/state.products.items.per_page);

	  		this.setState({
	  			product_count: state.products.items.total_product_count, 
	  			product_details: product_details,
	  			current_page: state.products.items.current_page, 
	  			total_product: state.products.items.total, 
	  			per_page: state.products.items.per_page, 
	  			total_pages: total_pages,
	  			last_page_url: state.products.items.last_page_url,
	  			next_page_url: state.products.items.next_page_url,
	  			show_products: true,
	  			//primary_image: state.products.items.products[0].model_images, 
	  			//secondary_image: state.products.items.products[0].glass_images
	  		}, () => {
		    	this.SetThemeColor(state);
		    	this.SetProductId(state);
		    	//this.SetLastPage(state);
		    });
		}
		this.initializeMedia();

	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};
	onLeave(origin, destination, direction) {
	    console.log("Leaving section " + origin.index);
	}
	afterLoad(origin, destination, direction) {
	    console.log("After load: " + destination.index);
	    console.log("Direction "+direction);
	    
	    this.setState({page_index: destination.index, show_iframe: 'hide_iframe_ar'})
	    //if(destination.index == this.state.product_increment)
	    //{
	    	//this.SetProductArray(direction);	
	    	//this.TestAR(direction);	
	   	//}
	    this.initializeMedia();
	    
	}
	afterSlideLoad(section, origin, destination, direction, trigger)
	{
		this.setState({slide_index: destination.index})
	}
	SetProductArray(direction)
	{
		var product_details_array = [];
		if(direction == "up")
		{
			var first_pointer = this.state.first_pointer-1;
			var second_pointer = this.state.second_pointer-1;
			var product_increment = this.state.product_increment-2;
		}
		else if(direction == 0)
		{
			var first_pointer = this.state.first_pointer;
			var second_pointer = this.state.second_pointer;
			var product_increment = this.state.product_increment;
		}
		else
		{
			var first_pointer = this.state.first_pointer+1;
			var second_pointer = this.state.second_pointer+1;
			var product_increment = this.state.product_increment+2;
		}
		for(var i = first_pointer; i<=second_pointer; i++)
		{
			console.log(this.state.product_details[i].short_name+" "+this.state.product_details[i].product_id);
			product_details_array.push(this.state.product_details[i]);
		}
		
		this.setState({product_details_array: product_details_array, first_pointer: first_pointer, second_pointer: second_pointer, product_increment: product_increment})
	}
	TestAR(direction){


		console.log(arArray);
		if(direction == "down")
		{
			
			
			
			arArray.push("");
			cursor+=1;
			if(arArray.length-1!=cursor){
				if(arArray[cursor+1]==""){
					arArray[cursor-2]="";
					arArray[cursor+1]=this.state.product_details[cursor+1];

					this.setState({stateArArray: arArray})
				}
			}
		}
		else if(direction == "up"){
			cursor-=1;
			if(cursor!=0){
				if(arArray[cursor-1]==""){
					arArray[cursor+2]="";
					arArray[cursor-1]=this.state.product_details[cursor-1];
				}
			}

		}
console.log("cursor",cursor);
console.log("stateArArray",this.state.stateArArray);


	}
	ChangeColour(item_id,color_id,i)
	{
		/*let url = process.env.REACT_APP_API_URL+'/v1/products/'+item_id+'?color_id='+color_id;
		fetch(url)
        .then(response => response.json())
        .then(products => {
        	let product_details_new = this.state.product_details;
        	product_details_new[i].model_images = products.result.products[i].model_images;
  			product_details_new[i].glass_images = products.result.products[i].glass_images;
        	//this.setState({product_details: product_details_new})
		}); */
	}
  	ChangePicture(i)
  	{
  		let product_details_new = this.state.product_details;
  		let glass_images_array = this.state.product_details[i].glass_images;
  		let model_images_array = this.state.product_details[i].model_images;
  		product_details_new[i].model_images = glass_images_array;
  		product_details_new[i].glass_images = model_images_array;

  		this.setState({product_details: product_details_new, show_loading_lottie: 0})
  	}
  	
	initializeMedia = async () => {
    	if (!("mediaDevices" in navigator)) {
	      navigator.mediaDevices = {};
	    }

	    if (!("getUserMedia" in navigator.mediaDevices)) {
	      navigator.mediaDevices.getUserMedia = function (constraints) {
	        var getUserMedia =
	          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

	        if (!getUserMedia) {
	          return Promise.reject(new Error("getUserMedia Not Implemented"));
	        }

	        return new Promise((resolve, reject) => {
	          getUserMedia.call(navigator, constraints, resolve, reject);
	        });
	      };
	    }

	    //Get the details of video inputs of the device
	    const videoInputs = await this.getListOfVideoInputs();

	    //The device has a camera
	    if (videoInputs.length) {
	      navigator.mediaDevices
	        .getUserMedia({
	          video: {
	            
	          },
	        })
	        .then((stream) => {
	          this.player.srcObject = stream;
	        })
	        .catch((error) => {
	           console.error(error);
	        });
	    } else {
	      	console.log("The device does not have a camera");
	      	this.setState({show_iframe: 'show_iframe_ar' });
	    }
	};
	getListOfVideoInputs = async () => {
	    // Get the details of audio and video output of the device
	    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

	    //Filter video outputs (for devices with multiple cameras)
	    return enumerateDevices.filter((device) => device.kind === "videoinput");
	};
  	SetThemeColor(state)
  	{
  		var theme_color = this.state.theme_color;
  		let product_details = this.state.product_details;
    	for(var i=0; i <= this.state.product_count-1; i++)
  		{
  			theme_color.push(product_details[i].theme_color);
  		}
  		this.setState({
  			theme_color: theme_color, loading_lottie_web: 0
  		});
  	}
  	SetProductId(state)
  	{
  		var lenskart_product_id = this.state.lenskart_product_id;
  		let product_details = this.state.product_details;
    	for(var i=0; i <= this.state.product_count-1; i++)
  		{
  			lenskart_product_id.push(String(product_details[i].lenskart_prod_id));
  		}
  		this.setState({
  			lenskart_product_id: lenskart_product_id
  		});
  	}
  	SetLastPage(state)
  	{

		let urlString = state.products.items.last_page_url;
        let paramString = urlString.split('?')[1];
        let params_arr = paramString.split('&');
        var last_page = '';
        for(let i = 0; i < params_arr.length; i++) {
            let pair = params_arr[i].split('=');
            if(pair[0] == 'page')
            {
            	last_page = pair[1];
            }
        }
        this.setState({
  			last_page: last_page
  		});
  	}
  	ChangeColor(item_url,color_id,device)
	{	
		this.setState({ loading_lottie_web: 1});
		let product_details_new = this.state.product_details;
		//product_details_new[i].model_images = [];
        //product_details_new[i].glass_images = [];
        
		if(this.state.path_source != null)
		{
			if(this.state.path_source=="male_listing")
			{
				var gender = this.state.path_source.substring(0, 4);
			}
			else
			{
				var gender = this.state.path_source.substring(0, 6);
			}
			var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id+"?gender="+gender;
		}
		else
		{
			var gender = "";
			var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id;
		}

		fetch(url)
        .then(response => response.json())
        .then(products => {
        	let product_details_new = this.state.product_details;
        	if(device=="web")
        	{
        		var index = this.state.slide_index;
        	}
        	else
        	{
        		var index = this.state.page_index;
        	}
        	product_details_new[index].lenskart_prod_id = products.result.products.lenskart_prod_id;
        	product_details_new[index].specifications.color_prod_id = products.result.products.specifications.color_prod_id;
        	product_details_new[index].sold_out = products.result.products.sold_out;
  			this.setState({product_details: product_details_new,  color_border: 'colour_border'},()=> this.setState({loading_lottie_web: 0, frame_reload: this.state.frame_reload+1}), ()=> this.fullpageApi.reBuild())

		});	 
    }
    
	AvailablePower(color_id)
	{
		var span = document.createElement("span");
        span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><center><div class='loader'></div></center></div>";
		swal({
		    content: span,
		    buttons: false
		});
		let url_power = process.env.REACT_APP_API_URL+'/v1/product/'+color_id+'/power';
		fetch(url_power)
        .then(response => response.json())
        
        .then(data => {
        	console.log(data);
        	if(data.success == true)
        	{
        		var cyl_values1 = data.data.cyl_values[0];
        		var cyl_values2 = data.data.cyl_values[data.data.cyl_values.length-1];
        		var sph_values1 = data.data.sph_values[0];
        		var sph_values2 = data.data.sph_values[data.data.sph_values.length-1];
        		this.setState({cyl_values1: cyl_values1, cyl_values2: cyl_values2, sph_values1: sph_values1, sph_values2: sph_values2}, () => {
					span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><b>This frame supports lenses of a specific power range.</b> <br/> Check your power availability below: <table style='margin-left: auto;margin-right: auto;margin-top: 20px;'><tr style='background-color: #EBEBEB;'><th style='padding: 10px 30px;'>SPH</th><th style='padding: 10px 30px;'>"+this.state.sph_values1+" to "+this.state.sph_values2+"</th></tr><tr style='background-color: #F6F6F6;'><th style='padding: 10px 30px;'>CYL</th><th style='padding: 10px 30px;'>"+this.state.cyl_values1+" to "+this.state.cyl_values2+"</th></tr></table><center><img src='/images/Header/LogoOnPower.svg' style='margin-top: 25px; width: 25px;' /></center></div>";
					swal({
					    content: span,
					});	
				});
        	}
        	else
        	{
        		//swal("Power details not fetched for this product")
        	}
		});
  	}
  	HideCameraBackground()
  	{
  		this.setState({show_iframe: 'show_iframe_ar' });
  	}
  	render() {
 		const { width } = this.state;
		const isMobile = width <= 768;
		const count = this.state.product_count;
		const anchors = this.state.lenskart_product_id;
		console.log(this.state.theme_color);
		console.log(this.state.product_details);
		const anchors_web = ['1'];

		const defaultOptions = {
	      loop: true,
	      autoplay: true,
	      animationData: animationData,
	      rendererSettings: {
	        preserveAspectRatio: "xMidYMid slice"
	      }
	    };
	    const styles = {
			checkAnimation: {
			    height: '200px',
  				width: '200px',
  				position: 'absolute',
    			top: '50%',
    			left: '50%',
    			transform: 'translate(-50%, -50%)',
			},
		}

		if(this.state.theme_color.length > 0)
 		{
 			if (isMobile) 
 			{
	 			return (
 					<ReactFullpage
						dragAndMove={true}
						dragAndMoveKey={process.env.REACT_APP_DRAG}
						autoScrolling={true}
						scrollOverflow={false}
					    scrollingSpeed={100}
					    licenseKey='E3F87307-C97946A6-934478F4-A0CBA372'
					    //onLeave={this.onLeave.bind(this)}
					    afterLoad={this.afterLoad}
					    onLeave={this.onLeave}
					    anchors={anchors}
					    render={({ state, fullpageApi }) => {
					        var rows = [];
					        this.state.product_details.map((item, i) => (
					        	rows.push(
					        		<div className="section" key={i}>
					        			<div>
											<div className="product_listing_ar_div">
												<ProductARFrame lenskart_prod_id={item.lenskart_prod_id} frame_reload={this.state.frame_reload} index={i}/>
												<div className="iframe_overlay_ar">
													<div className="ar_product_details_div">
														<div className="col-sm-4"></div>
														<div className="col-sm-4 col-xs-12 ar_product_details_section">
															<ProductARDetailsBody item={item} colors={this.state.product_details[i].colors} color_border={this.state.color_border} change_color={this.ChangeColor} path_source={this.state.path_source} device={"mob"}/>
										    			</div>
										    			<div className="col-sm-4"></div>
									    			</div>
									    		</div>
											</div>
										</div>
					    			</div>
						    	)
						    ));
						    
						    return (
					    		rows
					    	);
					    }}
					/>
		    	);
 			}
 			else
 			{
 				return (
 					<ReactFullpage
						dragAndMove={true}
						dragAndMoveKey={process.env.REACT_APP_DRAG}
						autoScrolling={true}
						scrollOverflow={false}
					    scrollingSpeed={100}
					    licenseKey='E3F87307-C97946A6-934478F4-A0CBA372'
					    //onLeave={this.onLeave.bind(this)}
					    afterSlideLoad={this.afterSlideLoad}
					    onLeave={this.onLeave}
					    anchors={anchors_web}
					    render={({ state, fullpageApi }) => {
					        var rows = [];
					        	rows.push(
					        		<div className="section">
					        			{this.state.product_details.map((item, i) => (
									    	<div className="slide" data-anchor={item.lenskart_prod_id}>
									    		<div className="product_listing_ar_div">
										    		<ProductARFrame lenskart_prod_id={item.lenskart_prod_id} frame_reload={this.state.frame_reload} index={i}/>
													<div className="iframe_overlay_ar">
														<div className="ar_product_details_div">
															<div className="col-sm-4"></div>
															<div className="col-sm-4 col-xs-12 ar_product_details_section">
																<ProductARDetailsBody item={item} colors={this.state.product_details[i].colors} color_border={this.state.color_border} change_color={this.ChangeColor} path_source={this.state.path_source} device={"web"}/>
											    			</div>
											    			<div className="col-sm-4"></div>
										    			</div>
										    		</div>
									    		</div>
											</div>
									    ))};
									</div>
								)
						    return (
					    		rows
					    	);
					    }}
					/>
		    	);
	    	}
 		}
 		return <div>Loading...</div>;
 	}
  	
}


export default ProductPage;

/*
<video ref={(refrence) => { this.player = refrence;}} autoPlay className={this.state.show_iframe +" front_camera_ar_background"}></video>

{this.state.show_iframe != "show_iframe_ar" ?
	<Lottie options={defaultOptions} style={styles.checkAnimation} className={this.state.show_iframe}/>
	:
	null
}
*/
/*

<div className="all_products_ar_div">
		{this.state.product_details.map((item, i) => (
		<div className="col-sm-12 all_products_ar_section_each_card padding_is_zero">
    		<div className="ar_each_section section" key={i}>
    			<iframe src={"https://bridge.lenskart.com/vto/launch/"+item.lenskart_prod_id} allow='camera' frameBorder="0" height="100%" width="100%" className="product_listing_iframe_ar" onLoad={this.HideCameraBackground}></iframe>
    			<div className="iframe_overlay_ar">
    				<div className="col-xs-12 ar_product_details_section">
	    				<div className="col-xs-8 extra_bold_text product_full_name">
                            {item.short_name}
                        </div>
                        <div className="col-xs-4">
                            
                        </div>
                        <div className="col-xs-12 product_size">
                            {item.specifications.frame_size} - {item.gender}
                        </div>
                    </div>
    			</div>
    		</div>
    	</div>
    ))}
</div>
<ReactFullpage
	dragAndMove={true}
	dragAndMoveKey={process.env.REACT_APP_DRAG}
	autoScrolling={true}
	scrollOverflow={false}
    scrollingSpeed={100}
    licenseKey='E3F87307-C97946A6-934478F4-A0CBA372'
    //onLeave={this.onLeave.bind(this)}
    afterLoad={this.afterLoad}
    onLeave={this.onLeave}
    render={({ state, fullpageApi }) => {
        var rows = [];
        this.state.product_details.map((item, i) => (
        	i < this.state.product_increment ?
        	rows.push(
				<div className="section" key={i}>
					<div>
					{this.state.page_index == i ?
						<div className="product_listing_ar_div">
							<video ref={(refrence) => { this.player = refrence;}} autoPlay className={this.state.show_iframe +" front_camera_ar_background"}></video>
							{this.state.show_iframe != "show_iframe_ar" ?
								<Lottie options={defaultOptions} style={styles.checkAnimation} className={this.state.show_iframe}/>
								:
								null
							}
							<iframe src={"https://bridge.lenskart.com/vto/launch/"+item.lenskart_prod_id} allow='camera' frameBorder="0" height="100%" width="100%" className="product_listing_iframe_ar" onLoad={this.HideCameraBackground}></iframe>
							<div className="iframe_overlay_ar"></div>
						</div>
						:
    					null
					}
					</div>
    			</div>
			)
	      	:
	      	null
	    ));
	    return (
    		rows
    	);
    }}
/>
<iframe src={"https://bridge.lenskart.com/vto/launch/"+item.lenskart_prod_id} allow='camera' frameBorder="0" height="100%" width="100%" onLoad={this.HideCameraBackground}></iframe>
													<div className="iframe_overlay_ar"></div>
<Link to={"/product/"+item.product_id}></Link>
<a href={"#carousel__slide"+(index-1)}><i class="fa fa-angle-left product_details_corousel_left_web"></i></a>
<a href={"#carousel__slide"+(index+1)}><i class="fa fa-angle-right product_details_corousel_right_web"></i></a>
*/
