import React, { Component } from "react";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import Footer from '../Common/Footer';
import CartItems from "./CartItems";
import CartSubscription from "./CartSubscription";
import EmptyCart from "./EmptyCart";
import CartFooter from "./CartFooter";
//import store from '../../Redux/store';
import { connect } from "react-redux";
//import { FetchCartDetails } from "../../Redux/Action/CartActions";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import Lottie from 'react-lottie';
import animationData from '../Lotties/55156-confetti.json';


class CartDetails extends Component 
{
	constructor(props) {
		//const user_token = JSON.parse(localStorage.getItem('token'));
				
	    super(props);
	    this.state = {
	      //token: user_token,
	      access_token : localStorage.getItem("token"),
	      combo_active:1,
	      combo: '',
	      products: '',
	      basic_products: '',
	      show_product: false,
	      cart_count: 0,
	      width: window.innerWidth,
	      selected_sub: 2,
	      subscription: '',
	      credits: 0,
	      path_source: '',
	      show_lottie: false,
	      redirectValue: true,
	    };
		
		this.ChangeSubscription  = this.ChangeSubscription.bind(this);
		this.AddQty  = this.AddQty.bind(this);
		this.SubQty  = this.SubQty.bind(this);
		this.RemoveItem  = this.RemoveItem.bind(this);
	}
	
	componentDidMount()
	{
		this.setState({redirectValue: true});
		/*if (this.state.access_token)  
		{
			this.GetCartDetails(); 
			
		}
		else 
		{
			var prod_count_array = JSON.parse(localStorage.getItem("prod_counts"));
			if(prod_count_array)
			{
				let product_ids_array = JSON.parse(localStorage.getItem("prod_counts"));
				this.GetProductList(product_ids_array);

			}
			else
			{
				this.setState({
					products: '',
					show_product : true,
	            })
			}
			
		}*/
		//Commenting this as for now there is only 990 package
		/*if(localStorage.getItem("sub_data"))
		{
			let sub_id = JSON.parse(localStorage.getItem("sub_data")).subscription_id;
			this.setState({
  				selected_sub: sub_id,
  			});
		}
		else
		{
			var sub_data = {
			"product_id": 0,
			"qty": 1,
			"subscription_id": this.state.selected_sub
			};
			localStorage.setItem('sub_data', JSON.stringify(sub_data));
		}*/

		//Hard coding to set sub_data with sub_id 1
		/*var sub_data = {
			"product_id": 0,
			"qty": 1,
			"subscription_id": 1
			};
		localStorage.setItem('sub_data', JSON.stringify(sub_data));
		this.setState({
  			selected_sub: 1,
  		});

		if (window.location.href.indexOf("path_source") > -1) {
            var gender =  window.location.search.substring(13,);
            //var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + '?path_source='+gender;    
            //window.history.pushState({ path: refresh }, '', refresh);
            this.setState({path_source: gender });
        }*/
	}
	SetItemInLocal(cart_details)
	{
		for(var i=0; i<=cart_details.cart_list.length - 1; i++)
		{
			if(cart_details.cart_list[i].type == 'product')
			{
				var product_id = cart_details.cart_list[i].product_id;
				var qty = cart_details.cart_list[i].qty;
				var prescription_type = cart_details.cart_list[i].prescription_type;
				var prod_data = {
					"product_id": product_id,
					"qty": qty,
					"prescription_type": prescription_type
				};
				localStorage.setItem('prod_'+product_id,JSON.stringify(prod_data)); /*Setting Product Details in local*/

				var prod_ids = JSON.parse(localStorage.getItem('prod_counts'));
				if (Array.isArray(prod_ids))
				{
					if(prod_ids.indexOf(product_id) === -1)
					{
						prod_ids.push(product_id) 
					}
					
				} 
				else 
				{
					prod_ids = [];
					prod_ids.push(product_id);
				}
				localStorage.setItem('prod_counts', JSON.stringify(prod_ids)); /*Setting Product Count in local*/
			}
		}

		let product_ids_array = JSON.parse(localStorage.getItem("prod_counts"));
		this.GetProductList(product_ids_array);
	}
	UpdateQty()
	{
		let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
		let product_details_new = this.state.basic_products;
		if(product_details_new.cart_list.length > 0)
		{
			for(var i = 0; i< product_id_array.length; i++)
			{
				let local_product_id = product_id_array[i];
				const found = product_details_new.cart_list.some(el => el.product_id === local_product_id);
				if(found)
				{
					let this_cart_array = product_details_new.cart_list[i];
					let product_id = this_cart_array.product_id;
					let product_item = JSON.parse(localStorage.getItem("prod_"+product_id));
					if(product_item.product_id == product_id)
					{
						this_cart_array.qty = product_item.qty;
						this_cart_array.prescription_type = product_item.prescription_type;
					}
				}
				else
				{
					localStorage.removeItem("prod_"+local_product_id);
			  		let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
			  		const index = product_id_array.indexOf(local_product_id);
					if (index > -1) {
					  product_id_array.splice(index, 1);
					}
					localStorage.setItem('prod_counts', JSON.stringify(product_id_array)); 
				}
			}
		}
		else
		{
			localStorage.removeItem("prod_counts");
			let arr = [] // Array to hold the keys
		  	// Iterate over localStorage and insert the keys that meet the condition into arr
		  	for (let i = 0; i < localStorage.length; i++) { 
			    if (localStorage.key(i).substring(0,5) == 'prod_') { 
			    	arr.push(localStorage.key(i)) 
			    }
		  	}
		  	// Iterate over arr and remove the items by key
		  	for (let i = 0; i < arr.length; i++) {
		    	localStorage.removeItem(arr[i]) 
		  	}
		}
		this.setState({
			products : product_details_new,
			show_product: true,
    	})
    	let prod_count = localStorage.getItem("prod_counts");
  		if(prod_count)
  		{
  			var total_qty = 0;
  			let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
  			for(var i = 0; i< product_id_array.length; i++)
			{
				total_qty = total_qty + JSON.parse(localStorage.getItem("prod_"+product_id_array[i])).qty;
			}
  			this.setState({
  				cart_count: total_qty,
  			});
  		}
	}
	AddQty(i)
	{
		var cart_count = this.state.cart_count;
		let product_details_new = this.state.products;
  		let this_cart_array = this.state.products.cart_list[i];
  		let product_id = this_cart_array.product_id;
  		let qty = this_cart_array.qty+1;

	  	if(qty<11){
		  	this_cart_array.qty = qty;
		  	let product_item = JSON.parse(localStorage.getItem("prod_"+product_id));
		  	var prod_data = {
				"product_id": product_id,
				"qty": qty,
				"prescription_type": product_item.prescription_type
			};
			localStorage.setItem('prod_'+product_id,JSON.stringify(prod_data));
			if (this.state.access_token) 
			{
				this.CartToBackend()
			}
		  	this.setState({
		        cart_count: cart_count+1,
		    });
		}else{
			swal("You cannot purchase more than 10 pairs of one type of eyeglasses at once.");
		}			
	}
	SubQty(i)
	{
		var cart_count = this.state.cart_count;
		let product_details_new = this.state.products;
  		let this_cart_array = this.state.products.cart_list[i];
  		let product_id = this_cart_array.product_id;
  		let qty = this_cart_array.qty-1;
  		if(qty>=1){
	  		this_cart_array.qty = qty;
	  		console.log(this_cart_array);
	  		let product_item = JSON.parse(localStorage.getItem("prod_"+product_id));
	  		var prod_data = {
				"product_id": product_item.product_id,
				"qty": qty,
				"prescription_type": product_item.prescription_type
			};
			localStorage.setItem('prod_'+product_id,JSON.stringify(prod_data));
			if (this.state.access_token) 
			{
				this.CartToBackend()
			}
	  		this.setState({
	            cart_count: cart_count-1,
	        });
  		}
  		else
  		{
  			this.RemoveItem(i);
  		}
	}
	RemoveItem(i)
	{
		var cart_count = this.state.cart_count;
		let this_cart_array = this.state.products.cart_list[i];
  		let product_id = this_cart_array.product_id;
  		let product_item = JSON.parse(localStorage.getItem("prod_"+product_id));
  		let qty = this_cart_array.qty
  		var prod_data = {
				"product_id": product_item.product_id,
				"qty": 0,
				"prescription_type": product_item.prescription_type
			};
		localStorage.setItem('prod_'+product_id,JSON.stringify(prod_data));
		if (this.state.access_token) 
		{
			var result = this.CartToBackend()
			localStorage.removeItem("prod_"+product_id);
	  		let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
	  		const index = product_id_array.indexOf(product_id);
			if (index > -1) {
			  product_id_array.splice(index, 1);
			}
			localStorage.setItem('prod_counts', JSON.stringify(product_id_array)); 
			let product_ids_array = JSON.parse(localStorage.getItem("prod_counts"));
			if(product_ids_array.length > 0)
			{
	        	this.GetProductList(product_ids_array);    
			}
			else
			{
				localStorage.removeItem("prod_counts");
				this.setState({
					products : '',
					show_product: true,
	        	});
			}
		}
		else
		{
			localStorage.removeItem("prod_"+product_id);
	  		let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
	  		const index = product_id_array.indexOf(product_id);
			if (index > -1) {
			  product_id_array.splice(index, 1);
			}
			localStorage.setItem('prod_counts', JSON.stringify(product_id_array)); 
			let product_ids_array = JSON.parse(localStorage.getItem("prod_counts"));
			if(product_ids_array.length > 0)
			{
				this.GetProductList(product_ids_array);
			}
			else
			{
				localStorage.removeItem("prod_counts");
				this.setState({
					products : '',
					show_product: true,
	        	});
			}
		}
		this.setState({
            cart_count: cart_count-qty,
        });
  		/*localStorage.removeItem("prod_"+product_id);
  		let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
  		const index = product_id_array.indexOf(product_id);
		if (index > -1) {
		  product_id_array.splice(index, 1);
		}
		localStorage.setItem('prod_counts', JSON.stringify(product_id_array));
  		*/
	}
	GetProductList(product_ids_array)
	{
		if(product_ids_array)
		{
			var product_data = JSON.stringify({'product_ids' : product_ids_array});
			let url = process.env.REACT_APP_API_URL+'/v1/cart/list';

			var requestOptions = {
	            method: 'POST',
	            body: product_data,
	        };
			fetch(url, requestOptions)
	        .then(response => response.json())
			.then((data) => 
				this.setState({
				basic_products : data.result.cart_details,
				subscription: data.result.cart_details.subscriptions,
				show_product: true,
			}, () => {
				this.UpdateQty();
			}))
		}
	}
	CartToBackend()
	{
		let data = [];
		let key = '';
		let prod_data = [];
		let prod_ids = JSON.parse(localStorage.getItem('prod_counts'));

		if(prod_ids)
		{
			prod_ids.forEach(item => {
			key = 'prod_'+item;

			prod_data = JSON.parse(localStorage.getItem(key));
			data.push(prod_data);
			});
			
			const { width } = this.state;
	  		const isMobile = width <= 768;
	  		if(isMobile)
	  		{
	  			var device = "mobile";
	  		}
	  		else
	  		{
	  			var device = "desktop";
	  		}
			var myHeaders = new Headers();
		    myHeaders.append("Authorization", "Bearer "+ this.state.access_token);
		    myHeaders.append("User-Source", device);

			data = JSON.stringify({'cart_data' : data});
	        let url = process.env.REACT_APP_API_URL+'/v1/cart';

			var requestOptions = {
	            method: 'POST',
	            headers: myHeaders,
	            body: data,
	            redirect: 'follow'
	        };

			fetch(url, requestOptions)
	        .then(response => response.json())
			
	        .then(data => 
	        {
	        	console.log(data);
	        }
	        )
		}
		else
		{
			this.setState({
				products : '',
				show_product: true,
        	});
		}
	}
	GetCartDetails()
	{
		localStorage.removeItem("prod_counts");
		let arr = [] // Array to hold the keys
	  	// Iterate over localStorage and insert the keys that meet the condition into arr
	  	for (let i = 0; i < localStorage.length; i++) { 
		    if (localStorage.key(i).substring(0,5) == 'prod_') { 
		    	arr.push(localStorage.key(i)) 
		    }
	  	}
	  	// Iterate over arr and remove the items by key
	  	for (let i = 0; i < arr.length; i++) {
	    	localStorage.removeItem(arr[i]) 
	  	}
	  	const { width } = this.state;
  		const isMobile = width <= 768;
  		if(isMobile)
  		{
  			var device = "mobile";
  		}
  		else
  		{
  			var device = "desktop";
  		}
		var myHeaders = new Headers();
	    myHeaders.append("Authorization", "Bearer "+ this.state.access_token);
	    myHeaders.append("User-Source", device);
	    let url = process.env.REACT_APP_API_URL+'/v1/cart';
	    var requestOptions = {
	        method: 'GET',
	        headers: myHeaders,
	        redirect: 'follow'
	    };
	    fetch(url, requestOptions)
        .then(response => response.json())
		.then(data =>{
			console.log(data);
			if(data.success==true)
			{
				this.SetItemInLocal(data.data.cart_details);
				this.setState({
					subscription : data.data.cart_details.subscriptions,
					credits: data.data.cart_details.user_data.credits_in_wallet,
	            }, () => {
					setTimeout(() => {  this.CartToBackend(); }, 2000);
				})
	        }
			else
			{
				this.setState({
					products: '',
					show_product : true,
	            })
			}
		})
	}
	componentWillMount() 
  	{
	  window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	handleWindowSizeChange = () => {
	
	  this.setState({ width: window.innerWidth });
	};
	ChangeSubscription(id){
		var selected_sub = id;
		var sub_data = {
			"product_id": 0,
			"qty": 1,
			"subscription_id": parseInt(selected_sub)
		};
		localStorage.setItem('sub_data', JSON.stringify(sub_data));
		this.setState({
            combo: 'active_combo_area',
            selected_sub: parseInt(selected_sub)
        })
        if(selected_sub == 2)
        {
        	this.setState({'show_lottie': true})
        	setTimeout(() => {
			        this.setState({ show_lottie: false });
			}, 3000);
        }
	}
	RedirectBack()
	{
		window.history.back();
	}
	render() {
		const { width } = this.state;
  		const isMobile = width <= 768;
  		console.log(this.state.products);
  		var credit_text = this.state.credits > 1 ? "credits" : "credit";
  		var cart_count_text = this.state.cart_count > 1 ? "credits" : "credit"; 

  		if(this.state.redirectValue)
  		{
  			return <Redirect to={{pathname:"/checkout", state: { prevPath: window.location.pathname }}}></Redirect>
  		}
		if(this.state.show_product)
	    {
	    	if (isMobile) 
 			{
		    	return (
					<div className="cart_details">
			    		<Header color={"#160D08"} cart_count={this.state.cart_count} show_launch_timer={0}/>
			    		<div id="myModal_cart" className="modal" data-spy="scroll"  data-offset="20">
							<div className="modal-content">
								<div className="cart-modal-header modal-header">
							    	<div className="col-xs-12">
							    		{/*<i className="fa fa-angle-left close" aria-hidden="true" onClick={this.RedirectBack}></i>*/}
							    	</div>
								</div>
								<div className="cart-modal-body modal-body">
									
									{this.state.products != '' ?
										<div>
											<h1 className="mycart_text">MY CART</h1>
											<div className="col-sm-12 cart_urgency_div">
												<p className="cart_urgency_message_header extra_bold_text">HURRY, GRAB THEM BEFORE THEY’RE GONE!</p>
												<p className="cart_urgency_message_text">These are limited handpicked collection, available for limited period. Get your favourite now, before it gets sold out!</p>
												<img src="/images/Header/lightning-thunder.svg" className="cart_thunder_img"/>
											</div>
											{/*<div className="col-sm-12 cart_urgency_div">
												<p className="cart_urgency_message_header extra_bold_text">COLLECTION GOING OUT OF STOCK ON FRIDAY. BUY YOUR FAVOURITE NOW!</p>
												<img src="/images/Header/UrgencySandClockTimerCart.svg" className="cart_thunder_img"/>
											</div>*/}
											<div className="clearfix"></div>
											<div>
												{this.state.credits > 0 ?
													<div className="col-sm-12 cart_credit_message_div"><p className="cart_subscription_message">You have <b>{this.state.credits} {credit_text}</b> in your wallet.</p></div>
													:
													null
												}
											</div>
											<div>
												{this.state.credits > 0  && this.state.selected_sub == 2 ?
													[ this.state.cart_count < this.state.credits ?
														<div className="col-sm-12 cart_subscription_message_div"><p className="cart_subscription_message"><b>{this.state.cart_count} out of {this.state.credits}</b> items added to your cart!<br/> You can add {this.state.credits-this.state.cart_count} more item, at any time!</p></div>
														:
														[ this.state.cart_count == this.state.credits ?
																<div className="col-sm-12 cart_subscription_message_div"><p className="cart_subscription_message">Hurrah! You have added <b>{this.state.cart_count} out of {this.state.credits}</b> items to your cart.</p></div>
																:
																<div className="col-sm-12 cart_subscription_message_div"><p className="cart_subscription_message">New subscription plan added for additional item(s). Unused credits (if any) will be added back to your wallet.</p></div>
														]

													]
													: 
													null
												}
											</div>
											<div>
												{/*{this.state.selected_sub == 1 ?
													<div className="col-sm-12 cart_subscription_message_div"><p className="cart_subscription_message">Get 3 eyeglasses for just Rs.700 each with a subscription plan! </p></div>
													:
													null
												}*/}
											</div>
											<div>
			                					{this.state.products.cart_list.map((item, i) => (
			                						<div>
			                						{item.type === "product" ?
			                							<div>
															<CartItems cart={item} i={i} add_qty={this.AddQty} sub_qty={this.SubQty} remove_item={this.RemoveItem}/>
														</div>
													:
													null
													}
													</div>
												))}
											</div>
											<div className="col-xs-12 cart_prescription_text">
		    									Enter power for eyeglasses after placing your order
		    								</div>
		    								{this.state.credits > 0 ?
		    									<div>
													<div className="clearfix"></div>
													<div className="cart_subscription_choosen">
														<p className="cart_subscription_choosen_text"><img src="/images/Header/Tick_icon.svg" alt="tick_credit_icon" className="tick_credit_icon"/>
														
														{this.state.cart_count > this.state.credits ?
															<label for="choosen_subscription" className="choosen_subscription">
															{this.state.credits} {cart_count_text} will be deducted from your wallet. 
															</label>

															:
															<label for="choosen_subscription" className="choosen_subscription">
															{this.state.cart_count} {cart_count_text} will be deducted from your wallet. 
															</label>
														}
														</p>
													</div>
												</div>
												:
												null
		    								}
		    								{this.state.cart_count > this.state.credits ?
		    									<CartSubscription change_subscription={this.ChangeSubscription} selected_sub={this.state.selected_sub} subscription={this.state.subscription} show_lottie={this.state.show_lottie}/>
											:
												null
											}
										</div>
									:
										<div>
											<EmptyCart />
										</div>
									}
								</div>
								<div className="cart-footer modal-footer">
									<CartFooter products={this.state.products} cart_count={this.state.cart_count} credits={this.state.credits} path_source={this.state.path_source} />
								</div>
							</div>
						</div>
				    </div>
				);
	    	}
	    	else
			{
				return(
					<div className="cart_details">
						<Header color={"#160D08"} cart_count={this.state.cart_count} show_launch_timer={0}/>
							{this.state.products != '' ?
								<div>
									<div className="col-sm-3"></div>
									<div className="cart_details_web col-sm-6">
										<h1 className="mycart_text_web">MY CART</h1>
										<div className="col-sm-12 cart_urgency_div_web">
											<p className="cart_urgency_message_header extra_bold_text">HURRY, GRAB THEM BEFORE THEY’RE GONE!</p>
											<p className="cart_urgency_message_text">These are limited handpicked collection, available for limited period. Get your favourite now, before it gets sold out!</p>
											<img src="/images/Header/lightning-thunder.svg" className="cart_thunder_img_web"/>
										</div>
										{/*<div className="col-sm-12 cart_urgency_div_web">
											<p className="cart_urgency_message_header extra_bold_text">COLLECTION GOING OUT OF STOCK ON FRIDAY. BUY YOUR FAVOURITE NOW!</p>
											<img src="/images/Header/UrgencySandClockTimerCart.svg" className="cart_thunder_img_web"/>
										</div>*/}
										<div>
											{this.state.credits > 0 ?
												<div className="col-sm-12 cart_credit_message_div_web"><p className="cart_subscription_message">You have <b>{this.state.credits} {credit_text}</b> in your wallet.</p></div>
												:
												null
											}
										</div>
										<div>
											{this.state.credits > 0 && this.state.selected_sub == 2 ?
												[ this.state.cart_count < this.state.credits ?
													<div className="col-sm-12 cart_subscription_message_div_web"><p className="cart_subscription_message"><b>{this.state.cart_count} out of {this.state.credits}</b> items added to your cart!<br/> You can add {this.state.credits-this.state.cart_count} more item, at any time! </p></div>
													:
													[ this.state.cart_count == this.state.credits ?
															<div className="col-sm-12 cart_subscription_message_div_web"><p className="cart_subscription_message">Hurrah! You have added <b>{this.state.cart_count} out of {this.state.credits}</b> items to your cart.</p></div>
															:
															<div className="col-sm-12 cart_subscription_message_div_web"><p className="cart_subscription_message">New subscription plan added for additional item(s). Unused credits (if any) will be added back to your wallet.</p></div>
													]

												]
												: 
												null
											}
										</div>
										{/*<div>
											{this.state.selected_sub == 1 ?
												<div className="col-sm-12 cart_subscription_message_div_web"><p className="cart_subscription_message">Get 3 eyeglasses for just Rs.700 each with a subcription plan! </p></div>
												:
												null
											}
										</div>*/}
				        					{this.state.products.cart_list.map((item, i) => (
				        						<div>
				        						{item.type === "product" ?
				        							<div>
														<CartItems cart={item} i={i} add_qty={this.AddQty} sub_qty={this.SubQty} remove_item={this.RemoveItem}/>
													</div>
												:
												null
												}
												</div>
											))}
											<div className="col-xs-12 cart_prescription_text">
		    									Enter power for eyeglasses after placing your order
		    								</div>
		    								{this.state.credits > 0 ?
		    									<div>
													<div className="clearfix"></div>
													<div className="cart_subscription_choosen">
														<p className="cart_subscription_choosen_text"><img src="/images/Header/Tick_icon.svg" alt="tick_credit_icon" className="tick_credit_icon"/>
														
														{this.state.cart_count > this.state.credits ?
															<label for="choosen_subscription" className="choosen_subscription">
															{this.state.credits} {cart_count_text} will be deducted from your wallet. 
															</label>

															:
															<label for="choosen_subscription" className="choosen_subscription">
															{this.state.cart_count} {cart_count_text} will be deducted from your wallet. 
															</label>
														}
														</p>
													</div>
												</div>
												:
												null
		    								}

											{this.state.cart_count > this.state.credits ?
		    									<div>
			    									<CartSubscription change_subscription={this.ChangeSubscription} selected_sub={this.state.selected_sub} subscription={this.state.subscription} show_lottie={this.state.show_lottie}/>
			    								</div>
											:
												null
											}
									</div>
									<div className="col-sm-3"></div>
								</div>
							:
								<div className="cart_details_web col-sm-12">
									<EmptyCart />
								</div>
							}
						<div className="clearfix"></div>
						<div className="col-sm-12 cart_web_margin"></div>
						<CartFooter products={this.state.products} cart_count={this.state.cart_count} credits={this.state.credits} path_source={this.state.path_source} />
						<Footer />
					</div>
				);
			}
		}
		
    	/*return 	(<div> 
	      			<Header color={"#160D08"}/> 
	      			<Loading />
	      		</div>);*/
	}
}
export default CartDetails;

		