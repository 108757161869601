import React, { Component } from "react";


class AddAddress extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	name: this.props.user_data.name,
	    	mob: this.props.user_data.phone,
	    	email: this.props.user_data.email,
	    	country: 'India',
	    	addr1: '',
	    	addr2: '',
	    	city: '',
	    	state: '',
	    	pin: '',
	    }
	    this.myChangeHandler = this.myChangeHandler.bind(this);
	}
	myChangeHandler = (event) => {
	    let nam = event.target.name;
	    let val = event.target.value;
	    if(nam == "city" || nam == "country" || nam == "state")
	    {
	    	val = val.replace(/[^A-Za-z ]/ig, '');
	    }
	    if(nam == "mob")
	    {
	    	val = val.replace(/[^0-9]/ig, '');
	    }

	    this.setState({[nam]: val});
	}
	render() {
		return (
			<div className="add_new_address_modal">
				<div id="myModal" className="modal-edited modal" data-spy="scroll"  data-offset="20">
					<div className="modal-address modal-address-content modal-content-edited modal-content">
						<div className="col-sm-3"></div>
						<div className="col-sm-6 padding_is_zero">
							<div className="address-modal-header modal-header-edited modal-header">
						    	<div className="col-xs-12 add_address_header">
					    			<button type="button" className="address_close close" aria-label="Close" onClick={() => this.props.close_address()}>
							        	<span aria-hidden="true">&times;</span>
							        </button>
					    			<center><p className="header_address_text">ADD NEW ADDRESS</p></center>
					    		</div>
		    				</div>
		    				<div className="address-modal-body">
		    					<div className="col-xs-12 add_address_body add_address_user_details_section">
		    						<input type="text" className="address_input" onChange={this.myChangeHandler} name="name" placeholder="Name" value={this.state.name} maxLength="35" />
		    						<input type="text" className="address_input" onChange={this.myChangeHandler} name="mob" placeholder="Mobile" value={this.state.mob} />
		    						<input type="text" className="address_input" onChange={this.myChangeHandler} name="email" placeholder="Email" value={this.state.email} />
		    					</div>
		    						<hr/>
		    					<div className="col-xs-12 add_address_body">
		    						<input type="text" className="address_input" onChange={this.myChangeHandler} name="addr1" placeholder="Apartment/House Number" value={this.state.addr1} autoComplete="nope"/>
					    			<input type="text" className="address_input" onChange={this.myChangeHandler} name="addr2" placeholder="Locality, Street Address" value={this.state.addr2} autoComplete="nope"/>
					    			<input type="text" className="address_input" onChange={this.myChangeHandler} name="city" placeholder="City" value={this.state.city} autoComplete="nope"/>
					    			<input type="number" className="address_input" onChange={this.myChangeHandler} name="pin" placeholder="ZIP Code" value={this.state.pin} autoComplete="nope"/>
					    			<input type="text" className="address_input" onChange={this.myChangeHandler} name="state" placeholder="State" value={this.state.state} autoComplete="nope"/>
					    			<input type="text" className="address_input" name="country" placeholder="Country" value={this.state.country} readonly />
					    			</div>
					    		</div>
		    				<div className="col-xs-12 col-sm-6 modal-footer-edited modal-footer">
		    					<center><button className="save_address_button" onClick={() => this.props.on_confirm(this.state.name,this.state.mob,this.state.email,this.state.country,this.state.addr1,this.state.addr2,this.state.city,this.state.state,this.state.pin)}>Save This Address</button></center>
							</div>
				    	</div>
				    	<div className="col-sm-3"></div>
    				</div>
    			</div>
    		</div>
		);
	}
}


export default AddAddress;


