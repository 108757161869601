import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

class HomePageCarouselMob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video_mute: true,
      autoplay: true,
    };
    this.UnmuteVideo = this.UnmuteVideo.bind(this);
  }
  /*componentDidMount()
    {
        var media = document.getElementById('sunglasses_video');
        media.addEventListener('playing', this.AutoPlay);

    }
    AutoPlay()
    {
        console.log("changed");
        this.setState({autoplay: "null"});
        
        //$('.carousel').carousel('pause');
    }*/
  UnmuteVideo() {
    var video = document.getElementById("sunglasses_video");
    if (video.muted) {
      video.muted = false;
    } else {
      video.muted = true;
    }
    this.setState({ video_mute: !this.state.video_mute });
  }
  
  render() {
    return (
        <div>
            {this.props.show_carousel_list_mob ? 
                <div
                  id="demo"
                  className="carousel slide"
                  data-bs-interval="7000"
                  data-bs-ride="carousel"
                >
                  <div class="homepage_carousel_section carousel-inner">
                    <div class="carousel-item active">
                            <img
                                src="https://static.ojos21.com/bannerimages/1684491884_512x921(mob)%20copy.jpg"
                                alt="banner1"
                                className="d-block"
                                width="100%"
                            />
                            <div className="col-xs-12 landing_mob_textarea">
                                <h1 className="landing_bold_header">
                                    We are now available on <a href="https://www.lenskart.com/" className="landing_bold_header"><u>Lenskart.com</u></a>
                                </h1>
                                <p className="landing_subtext_header">
                                    To get a new pair of OJOS wear, head to the Lenskart Website/App
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* <div class="carousel-item active">
                            <video id="sunglasses_video" width="100%" height="100%" autoPlay playsInline muted loop>
                                <source src="/videos/MelangeMetalsMobile_1.mp4" type="video/mp4" />
                            </video>
                            {this.state.video_mute ?
                                <img src="/images/Header/MuteIcon.svg" className="volume_img_mob" onClick={this.UnmuteVideo} />
                                :
                                <img src="/images/Header/VolumeIcon1.svg" className="volume_img_mob" onClick={this.UnmuteVideo} />
                            }
                            <div class="col-xs-12 landing_mob_textarea">
                                <h1 className="landing_bold_header">BANGIN' • RUSTIC • EYERONIC</h1>
                                <p className="landing_subtext_header">Introducing the Candy Rush collection of metallic frames that gives you the extra edge</p>
                                <div className="">
                                    <div className="col-xs-12 padding_left_0">
                                        <a href="/products/eyeglasses/featured"><button className="landing_header_button_mob">Shop Collection</button></a>
                                    </div>
                                    
                                </div>
                            </div> 
                        </div> */}
                        {/* {this.props.homepage_carousel_mob.map((details, index) => (
                            <div className={index == 0 ? "carousel-item active" : "carousel-item"} key={index}>
                                <img
                                    src={details.banner_for_mobile_web}
                                    alt="banner1"
                                    className="d-block"
                                    width="100%"
                                />
                                <div className="col-xs-12 landing_mob_textarea">
                                    <h1 className="landing_bold_header">
                                        {details.banner_title}
                                    </h1>
                                    <p className="landing_subtext_header">
                                      {details.banner_desc}
                                    </p>
                                    {details.has_male_button == 1 ?
                                        <div className="col-xs-6 padding_left_0">
                                            <Link to="/products/eyeglasses/male?path_source=male_listing">
                                                <button className="landing_header_button_mob">
                                                    {details.male_button_text}
                                                </button>
                                            </Link>
                                        </div>
                                        :
                                        null
                                    }
                                    {details.has_female_button == 1 ?
                                        <div className="col-xs-6 padding_left_0">
                                            <Link to="/products/eyeglasses/female?path_source=female_listing">
                                                <button className="landing_header_button_mob">
                                                    {details.female_button_text}
                                                </button>
                                            </Link>
                                        </div>
                                        :
                                        null
                                    }
                                    {details.has_feature_button == 1 ?
                                        <div className="col-xs-12 padding_left_0">
                                            <Link to={details.feature_id_url}>
                                                <button className="landing_header_button_mob">
                                                    {details.feature_button_text}
                                                </button>
                                            </Link>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    <button class="carousel-control-prev" type="button">
                        <span
                          class="carousel-control-prev-icon"
                          data-bs-target="#demo"
                          data-bs-slide="prev"
                        ></span>
                    </button>
                    <button class="carousel-control-next" type="button">
                        <span
                          class="carousel-control-next-icon"
                          data-bs-target="#demo"
                          data-bs-slide="next"
                        ></span>
                    </button> */}
                </div>
                :
                null
            }
        </div>
    );
  }
}
export default HomePageCarouselMob;
