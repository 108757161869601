import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HomePageSunglassesBanner extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
    render() {
        return (
            <div>
                <div className="col-xs-12 padding_is_zero hidden-sm hidden-lg hidden-md">
                    <a href="/products/sunglasses"><img src="/images/Header/mob_banner_sun.jpg" className="img-responsive" width="100%"/></a>
                    {/*<div className="homepage_banner_right_text_mob">
                        <h1 className="landing_bold_header_white_mob">We are upto something shady this month…</h1>
                        <div className="col-sm-12 landing_button_section_web padding_is_zero">
                            <div className="">
                                <center>
                                    <a href="/sale-registration"><button className="landing_header_button_mob">Get Notified</button></a> 
                                </center>
                            </div>
                        </div>
                    </div>*/}
                </div>
                <div className="col-xs-12 padding_is_zero hidden-xs">
                    <a href="/products/sunglasses"><img src="/images/Header/web_banner_copy.jpg" className="img-responsive" width="100%"/></a>
                    {/*<div className="homepage_banner_right_text_web">
                        <h1 className="landing_bold_header_white">We are upto something shady this month…</h1>
                        <p className="landing_subtext_header">Introducing the range of vibrant, quirky and colourful OJ Sunglasses collection</p>
                        <div className="col-sm-12 landing_button_section_web padding_is_zero">
                            <div className="col-sm-5 padding_left_0">
                                <a href="/sale-registration"><button className="landing_button">Get Notified</button></a> 
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
            );
    }
}
export default HomePageSunglassesBanner;