import React, { useState, Component } from "react";
import { Redirect, Route } from "react-router-dom";

{/*export default function FooterSearchBox() {
  const [val, setVal] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [redirectUrl, setRedirctUrl] = useState("/");
  const [noResult, setNoResult] = useState("");
  const [prodUrl, setProdUrl] = useState(null);
  const [prodId, setProdId] = useState(null);
  const handleKeyPress = (e) => {
    if (val.length) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const checkProductId = () => {
    if (val) {
      let url =
        process.env.REACT_APP_API_URL + "/v1/product/lenskartprodid/" + val;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200 && data.result === "No Products Found") {
            console.log(data.result);
            setNoResult("No result found!");
          } else if (data.status == 200 && data.result.product_count) {
            let item = data.result.products;
            console.log(item);
            let redirectUrl1 =
              "/product/" +
              item.prod_url +
              "/color/" +
              item.specifications.color_prod_id;
            console.log(redirectUrl1);
            setIsRedirect(true);
            setProdUrl(item.prod_url);
            setProdId(item.specifications.color_prod_id);
            setRedirctUrl(redirectUrl1);
            return true;
          } else {
            setNoResult("No result found log!");
          }
        });
    }
  };
  return isRedirect ? (
    <Redirect to={"/product/"+prodUrl+"/color/"+prodId} />
  ) : (
    <div>
      <div>
        <span
          class="fa fa-search"
          style={{
            paddingLeft: "0px",
            position: "relative",
            top: "28px",
            left: "10px",
            color: "#89949E",
            fontSize: "16px",
          }}
        ></span>
        <input
          type="number"
          className="form-control"
          style={{
            background: "#FAFAFA",
            borderRadius: "6px",
            paddingLeft: "50px",
          }}
          placeholder="Enter product ID here"
          value={val}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          onKeyUp={handleKeyPress}
        ></input>
        {isVisible ? (
          <button
            style={{
              background: "#6155FF",
              borderRadius: "0px 6px 6px 0px",
              fontSize: "12px",
              padding: "7px",
              width: "100px",
              position: "absolute",
              right: "15px",
              bottom: "0px",
              fontWeight: "900",
              lineHeight: "20px",
              border: "none",
            }}
            onClick={checkProductId}
          >
            Search
          </button>
        ) : null}
      </div>
      <div className="clearfix"></div>
      <div style={{ position: "absolute" }}>
        {noResult.length ? noResult : null}
      </div>
    </div>
  );
}*/}

class FooterSearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            val: "",
            isVisible: false,
            isRedirect: false,
            redirectURL: "/",
            noResult: "",
            prodUrl: "",
            prodId: "",
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.myChangeHandler = this.myChangeHandler.bind(this);
    }
    componentDidMount() {}
    handleKeyPress = (e) => {
        if (this.state.val.length) 
        {
            this.setState({isVisible: true });
        } else {
            this.setState({isVisible: false });
        }
    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({val: val});
        if (val.length > 2) 
        {
            this.setState({isVisible: true });
        } else {
            this.setState({isVisible: false });
        }
    }
     checkProductId = () => {
    if (this.state.val) {
      let url =
        process.env.REACT_APP_API_URL + "/v1/product/lenskartprodid/" + this.state.val;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200 && data.result === "No Products Found") {
            console.log(data.result);
            this.setState({noResult: "No result found!"})
            //setNoResult("No result found!");
          } else if (data.status == 200 && data.result.product_count) {
            let item = data.result.products;
            console.log(item);
            let redirectUrl1 =
              "/product/" +
              item.prod_url +
              "/color/" +
              item.specifications.color_prod_id;
            console.log(redirectUrl1);
            this.setState({redirectUrl: redirectUrl1, isRedirect: false, prodUrl:  item.prod_url, prodId: item.specifications.color_prod_id});
            window.location.href = redirectUrl1;
            return true;
          } else {
            
            this.setState({noResult: "No result found log!"})
            //setNoResult();
          }
        });
    }
  };
    render() 
    {
        if (this.state.isRedirect) {
            return (
              <Redirect
                to={{pathname: this.state.redirectUrl, state: { prevPath: window.location.pathname }}}
              />
            );
        }
        return (
            <div>
      <div>
        <span
          class="fa fa-search"
          style={{
            paddingLeft: "0px",
            position: "relative",
            top: "28px",
            left: "10px",
            color: "#89949E",
            fontSize: "16px",
          }}
        ></span>
        <input
          type="number"
          name="product_id"
          className="form-control"
          style={{
            background: "#FAFAFA",
            borderRadius: "6px",
            paddingLeft: "50px",
          }}
          placeholder="Enter product ID here"
          value={this.state.val}
          onChange={this.myChangeHandler}
          
        ></input>
        {this.state.isVisible ? (
          <button
            style={{
              background: "#6155FF",
              borderRadius: "0px 6px 6px 0px",
              fontSize: "12px",
              padding: "7px",
              width: "100px",
              position: "absolute",
              right: "0px",
              bottom: "0px",
              fontWeight: "900",
              lineHeight: "20px",
              border: "none",
            }}
            onClick={this.checkProductId}
          >
            Search
          </button>
        ) : null}
      </div>
      <div className="clearfix"></div>
      <div style={{ position: "absolute" }}>
        {this.state.noResult.length ? this.state.noResult : null}
      </div>
    </div>
        );
    }
}
export default FooterSearchBox;

