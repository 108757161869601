import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import valentineBird from '../Lotties/valentine_bird.json';
import valentineHeart from '../Lotties/valentine_heart.json';

class DesktopHead extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
          gender: 'MALE',
        };
    }

    componentDidMount()
    {
        var url_path = window.location.pathname;
        var gender = url_path.substring(10);
        this.setState({
            gender: gender,
        })
    }
    render() { 
            const defaultOptions = {
              loop: true,
              autoplay: true,
              animationData: valentineBird,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            };
            const defaultOptions1 = {
              loop: true,
              autoplay: true,
              animationData: valentineHeart,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            };
            const styles = {
                birdAnimation: {
                    margin: '0px',
                },
            }
			return(
                    <div>
                        <div className="col-xs-12 padding_is_zero">
                           <img src="/images/Header/DesktopListingHeader and HomepageBannerClickable.jpg" className="img-responsive"/> 
                        </div>
                        <div className="col-sm-12 seasonal_product_header_text">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8">

                                <center><p className="seasonal_product_page_head">Watch the FIFA fever Messi vs Mbappe only with OJOS wear glasses.</p></center>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
			);
    }
}

export default DesktopHead;