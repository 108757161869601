import React, { Component } from "react";  
  
export default class ProductImages extends Component
{
	
	constructor(props) {  
		super(props);
	    this.state = {
	    	image_clicks: this.props.image_clicks,
	    	selected_index: 0,
	    }
	    //this.Prev  = this.Prev.bind(this);
	    //this.Next  = this.Next.bind(this);
	}
	render() {  
		return (
				<div id="product_details_frame_images">
					<section className="carousel_product_details_new carousel" aria-label="Gallery">
						<ol className="carousel__viewport">
						  	{this.props.images.map((item, index) => (
						  		<li id={"carousel__slide"+index}
						        tabIndex={index} key={index}
						        className="carousel__slide" data-target="#myCarousel">
						      		<div className="carousel__snapper">
						        		<img src={item.url} className="img-responsive glass_img_product_details_new" alt="glass_img" onClick={this.props.more_images}/>
						      		</div>
						      		
						    	</li>
							))}
						</ol>
						{/*<img className="product_details_expand_icon" src="/images/Header/Expand_Thumbnail_icon_ListingPage.svg" alt="expand_icon_web" onClick={this.props.more_images}/><p className="product_details_expand_text" onClick={this.props.more_images}>Tap to expand</p>*/}
					    <div className="clearfix"></div>
					</section>
				</div>
			);
	}
}
