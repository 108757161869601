import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProductListingLastSlide extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    render() { 
            return(
                    <div className="col-xs-12 last_slide_section">
                        <center><img src="/images/Header/logo_InlineVertical_black.svg" className="ojos_logo_last_slide" /></center>
                        <p className="last_slide_looking_more">Get yourself new look today!</p>
                        <p className="last_slide_subtext1_grey">Pick your favourite OJOS designs <br/> before they are soldout. </p>
                        <p className="last_slide_subtext2_grey">It’s now or never!</p>
                        <p className="last_slide_facing_issue">Facing any issue?</p>
                        <div className="col-xs-2"></div>
                        <div className="col-xs-4 padding_is_zero">
                            <a href="mailto:contact@ojoswear.com" target="_blank"><center className="last_slide_contact_footer"><img src="/images/Header/Icon material-mail.svg"/><p className="last_slide_contact_footer_subtext">Mail Us</p></center></a>
                        </div>
                        {/*<div className="col-xs-4">
                            <a href="tel:+91-7669800711"><center className="last_slide_contact_footer"><img src="/images/Header/Icon ionic-ios-call.svg"/><p className="last_slide_contact_footer_subtext">Call Us</p></center></a>
                        </div>*/}
                        <div className="col-xs-4 padding_is_zero">
                            <a href="https://wa.me/+917669800711" target="_blank"><center className="last_slide_contact_footer"><img src="/images/Header/Icon ionic-logo-whatsapp.svg"/><p className="last_slide_contact_footer_subtext">WhatsApp Us</p></center></a>
                        </div>
                        <div className="col-xs-2"></div>
                    </div>
			);
    }
}

export default ProductListingLastSlide;