import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

class ProductDetailsBreadCrumb extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	 		
	    } 
	}
	componentDidMount()
	{
		
  	}
  	render() {
  		return (
  			<div>
	  			<div className="col-sm-12 web_breadcrumb_pdp hidden-xs">
	                <Link to="/"><p className="web_breadcrumb_pdp_text">HOME</p></Link>
	                {this.props.path_source != null ?
	                	<div>
		                	{this.props.path_source == "male_listing" ?
		                		<Link to={"/products/eyeglasses/male?path_source=male_listing"}><p className="web_breadcrumb_pdp_text"> | MEN'S EYEGLASSES </p></Link>
		                		:
		                		<Link to={"/products/eyeglasses/female?path_source=female_listing"}><p className="web_breadcrumb_pdp_text"> | WOMEN'S EYEGLASSES </p></Link>
		                	}
	                	</div>
	                	:
	                	null
	                }
	                <Link to={{pathname: "/product/"+this.props.product_details.prod_url+"/color/"+this.props.product_details.specifications.color_prod_id}}><p className="web_breadcrumb_pdp_text"> |  {this.props.product_details.short_name} - {this.props.product_details.lenskart_prod_id} </p></Link>
	            </div>
            </div>
  		);
  	}
}
export default ProductDetailsBreadCrumb;
