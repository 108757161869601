import React, { Component } from 'react';

class ProductDescription extends Component 
{    
    constructor(props) {
        super(props);
    }

    render() { 
            return( <div>
                        {this.props.gender_url == "Unisex" ?
                            <div className="col-sm-12 product_image_section padding_is_zero">
                                {this.props.product_details.model_images.length > 0 ?
                                    <div>
                                        <div className="col-sm-6">
                                            <img src={this.props.product_details.model_images[0].url} alt="frame_img" className="img-responsive frame_iamge_web"/>
                                            <button className="show_more_image" onClick={this.props.more_model_images}>Show More</button>
                                        </div>
                                        <div className="col-sm-6 product_image_glass_image">
                                            <img src={this.props.product_details.glass_images[0].url} alt="frame_img" className="img-responsive frame_iamge_web"/>
                                            <button className="show_more_image" onClick={this.props.more_images}>Show More</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="col-sm-12">
                                        <center>
                                            <img src={this.props.product_details.glass_images[0].url} alt="frame_img" className="frame_image_web_center"/>
                                            <button className="show_more_image_single" onClick={this.props.more_images}>Show More</button>
                                        </center>
                                    </div>
                                }
                            </div>
                            :
                            <div className="col-sm-12">
                                <center>
                                    <img src={this.props.product_details.glass_images[0].url} alt="frame_img" className="frame_image_web_center"/>
                                    <button className="show_more_image_single" onClick={this.props.more_images}>Show More</button>
                                </center>
                            </div>
                        }
                    </div>
            );
    }
}

export default ProductDescription;