import { useState } from "react";
import { RWebShare } from "react-web-share";
import swal from "sweetalert";

const ProductShowCaseImage = (props) => {
  console.log(props.product_details.type);
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const boxStyle = {
    transform: isHover ? "scale(1.2)" : "",
    transition: " all .3s",
    cursor: "pointer",
  };
  const commanbox = {
    position: "relative",
    background: "white",
    border: "2px solid #ededed",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    overflow: "hidden",
  };

  const webshare = {
    position: "absolute",
    right: "0px",
    top: "10px",
    height: "10%",
    display: "flex",
    alignItems: "left",
    width: "70px",
  };
  const rxshare = {
    position: "absolute",
    right: "0px",
    bottom: "10px",
    height: "10%",
    display: "flex",
    alignItems: "left",
    width: "70px",
  };
  const product_details_share_icon_web = {
    cursor: "pointer",
    background: "#F6F6F4 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: "0.5",
    borderRadius: "100%",
  };
  const rx_web_share = {
    cursor: "pointer",
    background: "#F6F6F4 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: "0.5",
    borderRadius: "100%",
  };

  return (
    <div
      style={{
        ...commanbox,
        ...{ height: props.boxHeight },
        ...{ borderRadius: "8px" },
        // ...(props.showRx ? { borderRadius: "16px 2px 2px 16px" } : null),
        // ...(props.rightRound ? { borderRadius: "2px 2px 16px 2px" } : null),
      }}
    >
      {props.showWebShare ? (
        <div style={webshare}>
          <RWebShare
            data={{
              text: props.product_details.brand_name,
              url:
                "https://ojoswear.com/product/" +
                props.product_details.prod_url +
                "/color/" +
                props.product_details.specifications.color_prod_id,
              title: props.product_details.brand_name,
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <img
              src="/images/Header/Share_Icon_ListingPage.svg"
              style={product_details_share_icon_web}
              alt="share_icon"
            />
          </RWebShare>
        </div>
      ) : null}
      {props.showRx ? (
        <div style={rxshare}>
          {props.product_details.type === "Eyeglasses" ? (
            <div className="">
              {props.pres === "Single Vision" ? (
                <img
                  alt=""
                  src="/images/Header/PowersIconGreenDot.svg"
                  style={rx_web_share}
                  // onClick={}
                />
              ) : (
                <img
                  alt=""
                  src="/images/Header/PowersIcon.svg"
                  style={rx_web_share}
                  onClick={() =>
                    ShowPowerRange(
                      props.product_details.specifications.color_prod_id
                    )
                  }
                />
              )}
              {/* <p
              className="product_details_check_power_text_web"
              onClick={props.available_power}
            >
              Check Available Powers
            </p> */}
            </div>
          ) : null}
        </div>
      ) : null}
      <img
        alt="img"
        className="img-responsive"
        style={boxStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        src={props.url}
        onClick={(event) => props.clickfun(props.index)}
      ></img>
    </div>
  );
};
function ShowPowerRange(color_id) {
  var span = document.createElement("span");
  span.innerHTML =
    "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><center><div class='loader'></div></center></div>";
  swal({
    content: span,
    buttons: false,
  });
  let url_power =
    process.env.REACT_APP_API_URL + "/v1/product/" + color_id + "/power";
  fetch(url_power)
    .then((response) => response.json())
    .then((data) => {
      if (data.success === true) {
        var cyl_values1 = data.data.cyl_values[0];
        var cyl_values2 = data.data.cyl_values[data.data.cyl_values.length - 1];
        var sph_values1 = data.data.sph_values[0];
        var sph_values2 = data.data.sph_values[data.data.sph_values.length - 1];
        var span = document.createElement("span");
        span.innerHTML =
          "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><b>This frame supports lenses of a specific power range.</b> <br/> Check your power availability below: <table style='margin-left: auto;margin-right: auto;margin-top: 20px;'><tr style='background-color: #EBEBEB;'><th style='padding: 10px 30px;'>SPH</th><th style='padding: 10px 30px;'>" +
          sph_values1 +
          " to " +
          sph_values2 +
          "</th></tr><tr style='background-color: #F6F6F6;'><th style='padding: 10px 30px;'>CYL</th><th style='padding: 10px 30px;'>" +
          cyl_values1 +
          " to " +
          cyl_values2 +
          "</th></tr></table><center><img src='/images/Header/LogoOnPower.svg' style='margin-top: 25px; width: 25px;' /></center></div>";
        swal({
          content: span,
        });
      }
    });
}
export default ProductShowCaseImage;
