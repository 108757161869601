import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from '../Common/Footer';
import Loading from "../Common/Loading";
import OrderFilter from "./OrderFilter";
import { SubmitRating } from "../RatingReview/SubmitRating";
import { Link } from 'react-router-dom';

class OrderDetails extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            token : localStorage.getItem("token"),
            order_details: [{products:[]}],
            show_product: false,
        };
    }
    componentDidMount()
    {
        if(this.state.token)
        {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ this.state.token);

            let url = process.env.REACT_APP_API_URL+'/v1/orders';

            var requestOptions = {
                headers: myHeaders,
            };

            fetch(url, requestOptions)
            .then(response => response.json())
            
            .then(data => {
                if(data.result)
                {
                   this.setState({order_details: data.result, show_product: true}) 
                }
                else
                {
                    this.setState({order_details: '', show_product: true}) 
                }
            })
        }
        else
        {
           this.setState({order_details: '', show_product: true}) 
        }
        
    }
    render() {
        console.log(this.state.order_details);
        return(
            <div>
                <Header color={"#160D08"}/>
                {this.state.show_product ?
                    <div>
                        {this.state.order_details.length > 0 ?
                        <div className="order_details_body col-xs-12 padding_is_zero">
                            <div>
                                <OrderFilter />
                                {this.state.order_details.map((item, index) => (
                                    <div className="col-sm-12">
                                        <div className="col-sm-3"></div>
                                        <div className="col-xs-12 col-sm-6 order_section">
                                            <div className="col-xs-12 order_section_header padding_is_zero">
                                                <div className="col-xs-12 padding_is_zero">
                                                    <p className="order_text_details">Order ID: {item.hashed_id}</p>
                                                </div>
                                                <div className="col-xs-2 order_status_icon_section">
                                                    <img src="../images/Header/Order_In_Transit.svg" className="img-responsive"/>
                                                </div>
                                                <div className="col-xs-6">
                                                    <p className="order_status">{item.status}</p>
                                                    <p className="order_subtext">Ordered On {item.ordered_on}</p>
                                                </div>
                                                <div className="col-xs-4">
                                                    <p className="order_total_cost">Rs. {item.total_cost}</p>
                                                    <p className="order_subtext">{item.payment_method}</p>
                                                </div>
                                            </div>
                                            
                                            {item.products.map((data, index) => (
                                            <div className="col-xs-12 each_order_product_section padding_is_zero">
                                                <div className="col-xs-4">
                                                    <center><Link to={{pathname: "/product/"+data.product_url+"/color/"+data.color_id, state: { prevPath: window.location.pathname }}}><img src={data.image} className="order_details_product_image img-responsive"/></Link></center>
                                                </div>
                                                <div className="col-xs-6">
                                                    <p className="order_product_brand_text">{data.brand_name}</p>
                                                    <p className="order_product_full_name">{data.name}</p>
                                                    <p className="order_product_size">{data.size} - {data.gender}<span className="colour_dot order_product_color" style={{backgroundColor: data.color}}></span></p>
                                                    <p className="order_product_qty">X {data.qty}</p>
                                                </div>
                                                <div className="col-xs-2">
                                                    <Link to={"/order/"+item.id+"/product/"+data.color_id}><img src="../images/Header/Chevron_Brown.svg" className="img-responsive order_product_arrow"/></Link>
                                                </div>
                                                <div className="clearfix"></div>
                                                {data.power_type == "single_vision" || data.power_type == "single_vision_bluecut" ?
                                                    <div>
                                                        {data.power_id == 0 ?
                                                            <div>
                                                                <div className="col-xs-12"><Link to={"/power_new/order/"+item.id+"/product/"+data.color_id+"/order_prod_id/"+data.odr_prd_detail_id}><button className="prescription_button">ENTER EYE POWER</button></Link></div>
                                                            </div>
                                                        :
                                                            <div>
                                                                <center>
                                                                    <div className="col-xs-6"><Link to={"/order/"+item.id+"/product/"+data.color_id}><button className="track_button">TRACK ORDER</button></Link></div>
                                                                    <div className="col-xs-6">
                                                                        <SubmitRating productDetails={data} orderDetails={item}></SubmitRating>
                                                                    </div>
                                                                </center>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                        <center>
                                                            <div className="col-xs-6"><Link to={"/order/"+item.id+"/product/"+data.color_id}><button className="track_button">TRACK ORDER</button></Link></div>
                                                            <div className="col-xs-6">
                                                                <SubmitRating productDetails={data} orderDetails={item}></SubmitRating>
                                                            </div>
                                                        </center>
                                                    </div>
                                                }
                                                
                                                <div className="col-xs-12 padding_is_zero">
                                                    {<center><p className="order_cancel_text"></p></center>}
                                                </div>
                                            </div>
                                            ))}
                                            <div className="clearfix"></div>
                                            <div className="col-sm-12">
                                                <p className="exchange_text">To Cancel/ Return/ Exchange your order email us at contact@ojoswear.com </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-3"></div>
                                    </div>
                                ))}
                            </div>
                            <Footer />
                        </div> 
                        :
                        <div className="col-xs-12 no_order_section">
                            <center>
                                <p className="no_order_text">Uh oh!</p>
                                <p className="no_order_text">Looks like you have not places any order with us :(</p>
                                <img src="../images/Header/NoOrders.svg" className="no_order_img"/>
                                <br />
                                <Link to="/"><button className="order_explore_ojos_button">Explore OJOS</button></Link>
                            </center>
                        </div>
                        }
                    </div>
                    :
                    <div> 
                        <Loading />
                    </div>
                    
                }
            </div>
        );
        
    }
}

export default OrderDetails;
