import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HomePageOjosFeatureSection extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
    render() {
        return (
            <div className="col-xs-12 homepage_ojos_feature_section">
                {/*<div className="hidden-sm hidden-md hidden-lg">
                    <div className="col-xs-12 homepage_banner_section padding_is_zero">
                        <center><img src="/images/Header/Mobile_HomeOJSuperCard.png" className="homepage_oj_super_img img-responsive"/></center>
                    </div>
                    <div className="col-xs-12 homepage_banner_section homepage_banner_launch_text_div padding_is_zero">
                        <div className="homepage_oj_super_desc_subtext">
                            <p className="homepage_ojsuper_card_text">Introducing OJ SUPER</p>
                            <p className="homepage_ojsuper_card_subtext">First ever eyewear subscription plan</p>
                            <p className="homepage_ojsuper_card_subtext">Buy 3 glasses for just Rs. 700 each</p>
                            <p className="homepage_ojsuper_card_subtext">Save credits for buying later</p>
                            <p className="homepage_ojsuper_card_subtext">More than 53% off on each purchase</p>
                            <a href="/subscription-plan"><button className="homepage_oj_super_learn_more_button">Learn More</button></a>
                            <img src="/images/Header/Blue_OJSUPERLogo.svg" className="homepage_oj_super_icon_right"/>
                        </div>
                    </div>
                </div>*/}
                {/*<div className="hidden-xs">
                    <div className="col-xs-4"></div>
                    <div className="col-xs-4  padding_is_zero">
                        <center><img src="/images/Header/Desktop_OJSuperCard.png" className="homepage_oj_super_img img-responsive"/></center>
                    </div>
                    <div className="col-xs-4"></div>
                    <div className="clearfix"></div>
                    <div className="col-xs-4"></div>
                    <div className="col-xs-4  homepage_banner_launch_text_div padding_is_zero">
                        <div className="homepage_oj_super_desc_subtext">
                            <p className="homepage_ojsuper_card_text">Introducing OJ SUPER</p>
                            <p className="homepage_ojsuper_card_subtext">First ever eyewear subscription plan</p>
                            <p className="homepage_ojsuper_card_subtext">Buy 3 glasses for just Rs. 700 each</p>
                            <p className="homepage_ojsuper_card_subtext">Save credits for buying later</p>
                            <p className="homepage_ojsuper_card_subtext">More than 53% off on each purchase</p>
                            <a href="/subscription-plan"><button className="homepage_oj_super_learn_more_button">Learn More</button></a>
                            <img src="/images/Header/Blue_OJSUPERLogo.svg" className="homepage_oj_super_icon_right"/>
                        </div>
                    </div>
                    <div className="col-xs-4"></div>
                </div>*/}
            </div>
            );
    }
}
export default HomePageOjosFeatureSection;