import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomePageLaunchEdition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <div>
            {this.props.source == "mob" ?
                <div>
                    {this.props.show_collection_banner_mob ?
                        <div className="homepage_launch_banner_section hidden-sm hidden-lg hidden-md">
                            <a href="/products/eyeglasses/featured">
                                <img src={this.props.collection_banner_mob} className="img-responsive" width="100%"/>
                            </a>
                        </div>
                        :
                        null
                    }
                    
                </div>
                :
                <div>
                    {this.props.show_collection_banner_web ?
                        <div className="homepage_launch_banner_section hidden-xs">
                          <a href="/products/eyeglasses/featured">
                            <img src={this.props.collection_banner_web} className="img-responsive" width="100%"/>
                          </a>
                        </div>
                        :
                        null
                    }
                </div>
            }
        </div>
    );
  }
}
export default HomePageLaunchEdition;
