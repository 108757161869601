import React, { Component } from "react";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import ProductDetails from "./ProductDetails";
import ProductDetailsNew from "./ProductDetailsNew";
import ProductDetailsLatest from "./ProductDetailsLatest";
import { connect } from "react-redux";
import { FetchEachProductDetails } from "../../Redux/Action/EachProductActions";

class ProductOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path_source: "",
      gender_url: "",
      prevPath: this.props.location.state,
      prev_path: this.props.location,
    };
  }
  componentDidMount() {
    const product_id = this.props.match.params.id;
    const color_id = this.props.match.params.color_id.split("?")[0];
    const query = new URLSearchParams(window.location.search);
    const gender = query.get("gender");
    const path_source = query.get("path_source");
    this.setState({ path_source: path_source, gender_url: gender });
    this.props.dispatch(
      FetchEachProductDetails(product_id, color_id, gender, path_source)
    );
    if (this.state.prevPath == undefined) {
      this.setState({ prevPath: "/" });
    }
  }
  render() {
    const { error, loading, products } = this.props;
    if (error) {
      return <div>Error! {error.message}</div>;
    }

    if (loading) {
      return (
        <div>
          <Header color={"#160D08"} />
          <Loading />
        </div>
      );
    }
    return (
      <ProductDetailsLatest
        path_source={this.state.path_source}
        prevPath={this.state.prevPath}
        prev_path={this.state.prev_path}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.products.items,
  loading: state.products.loading,
  error: state.products.error,
});

/*<ProductDetails path_source={this.state.path_source} gender_url={this.state.gender_url} prevPath={this.state.prevPath}/>*/
export default connect(mapStateToProps)(ProductOverview);
