import React, { Component } from "react";
import $ from 'jquery';

class CheckoutData extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	response: '',
	    	hash:'',
	    }
	}
	componentDidMount()
	{
		var final_detail_data = JSON.stringify({order_id: 'OJ19EhW','product_name':'xyz'});
		let header = new Headers({
		 	'Content-Type': 'application/json',
		 	'Access-Control-Allow-Origin': '*',
		 	'Accept': 'application/json',
		 	'token': '1fPDVZKLg9hURa66Ct7CDNy3MWWCpr5f',
		});
		fetch('/v1/payment', {
			method: 'POST',
			headers: header,
			body: final_detail_data
		})
		.then(response => response.json())
		.then(data => this.setState({hash:data.hash,response: data.form_data}));
	}
	componentDidUpdate() {

	    const service_provider = document.getElementById("service_provider");
	    console.log(this.state.response);
	    if (service_provider) {
	        document.getElementById('payment_form').submit();
	    }
	}
	
		render() 
		{
			return(
			<div>
				<h1>Response : {this.state.response.key}!!</h1>
				<form onSubmit={this.handleSubmit} id="payment_form" method="POST" action={this.state.response.post_url}>
					<input type="text" name="hash" value={this.state.hash}  />
			        <input type="text" name="key" value={this.state.response.key}  />
			        <input type="text" name="txnid" value={this.state.response.txnid}  />
			        <input type="text" name="amount" value={this.state.response.amount}  />
			        <input type="text" name="productinfo" value={this.state.response.productinfo}  />
			        <input type="text" name="firstname" value={this.state.response.firstname}  />
			        <input type="text" name="email" value={this.state.response.email}  />
			        <input type="text" name="udf1" value={this.state.response.udf1}  />
			        <input type="text" name="phone" value={this.state.response.phone}  />
			        <input type="text" name="surl" value={this.state.response.surl}  />
			        <input type="text" name="furl" value={this.state.response.furl}  />
			        <input type="text" name="post_url" value={this.state.response.post_url}  />
			        <input type="text" name="service_provider" id="service_provider" value={this.state.response.service_provider}  />
			        <button type="submit" value="submit"></button>
			      </form>
			</div>
			);
		}
}
export default CheckoutData;	