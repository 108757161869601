import React, { Component } from "react";
import Lottie from 'react-lottie';
import animationData from '../Lotties/New_LoadingAnimation.json';

class Loading extends Component 
{
	render() {
		const defaultOptions = {
	      loop: true,
	      autoplay: true,
	      animationData: animationData,
	      rendererSettings: {
	        preserveAspectRatio: "xMidYMid slice"
	      }
	    };
	    const styles = {
			checkAnimation: {
			    display: 'flex',
  				justifyContent: 'center',
  				alignItems: 'center',
  				textAlign: 'center',
  				minHeight: '80vh',
			},
		}
		return(
			
			<div>
				<Lottie 
				    options={defaultOptions}
			        width={300}
			        style={styles.checkAnimation}
			    />
		    </div>
			
		);
		
	}
}

export default Loading;