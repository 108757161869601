import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import valentineBird from '../Lotties/valentine_bird.json';
import valentineHeart from '../Lotties/valentine_heart.json';
import scrollArrow from '../Lotties/single_scroll_arrow.json';

class ProductListingSeasonalSlide extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    render() { 
            const defaultOptions = {
              loop: true,
              autoplay: true,
              animationData: valentineBird,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            };
            const defaultOptions1 = {
              loop: true,
              autoplay: true,
              animationData: valentineHeart,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            };
            const defaultOptions2 = {
              loop: true,
              autoplay: true,
              animationData: scrollArrow,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            };
            const styles = {
                birdAnimation: {
                    margin: '0px',
                }
            }
            return(
                    <div >
                        <div className="col-xs-12 padding_is_zero">
                            <div className="image_section image_section_mob">
                                <div className="large_image_slider">
                                    <center><img src="/images/Header/MobileListingHeaderNonClickable.jpg" className="img-responsive seasonal_first_slide_banner" /></center>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-xs-12 padding_is_zero seasonal_mob_first_slide_subtext_section">
                            <center><p className="seasonal_swipe_up_text">Swipe up to explore!</p></center>
                            <center><img src="/images/Header/product_seasonal_swipe_up.png" className="product_seasonal_arrow_img"/></center>
                        </div>*/}
                        {/*<div className="col-xs-12 seasonal_mob_first_slide_lottie padding_is_zero">
                            <div className="col-xs-3 padding_is_zero">
                                <center><Lottie options={defaultOptions1} width={75} style={styles.birdAnimation}/></center>
                            </div>
                            <div className="col-xs-6 padding_is_zero">
                                <center><Lottie options={defaultOptions} width={150} style={styles.birdAnimation}/></center>
                            </div>
                            <div className="col-xs-3 padding_is_zero">
                                <center><Lottie options={defaultOptions1} width={75} style={styles.birdAnimation}/></center>
                            </div>
                            <div className="col-xs-12 padding_is_zero seasonal_mob_first_slide_subtext_section">
                                <center><p className="seasonal_mob_first_slide_text extra_bold_text">BECAUSE GOOD THINGS ALWAYS COME IN A PAIR!!!</p></center>
                                <center><p className="seasonal_swipe_up_text">Swipe up to twin!</p></center>
                                <center><Lottie options={defaultOptions2} width={25} style={styles.scrollArrowAnimation}/></center>
                            </div>
                        </div>*/}
                        
                    </div>
			);
    }
}

export default ProductListingSeasonalSlide;