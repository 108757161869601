import React, { Component } from 'react';
import { Redirect } from 'react-router';
import EnterMobile from './EnterMobile';
import EnterOTP from './EnterOTP';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import ClevertapReact from 'clevertap-react';

class MobileNumberBox extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            status : '',
            showPhone: true,
            token: 0,
            redirect: false, //for redirecting to previous screen from where user came for Login,
            redirectUrl: "/", //setting this to home for now, will have to change, mainly using props here, of if no props then this value
            resend: 0, //0 means first time sending otp, 1 means resend
            products: '',
            alert_msg: '',
            name: localStorage.getItem('name'),
            show_login_subtext: true,
            track_source: Cookies.get('track_source'),
        };
        this.mobHandler  = this.mobHandler.bind(this);
        this.otpHandler  = this.otpHandler.bind(this);
    }
    componentDidMount()
    {
        if(!this.state.name)
        {
            var redirectUrl = '/profile';
            const query = new URLSearchParams(window.location.search);
            const redirect_value = query.get('redirect_url');
            if(redirect_value)
            {
                if(redirect_value.includes("cart"))
                {
                    redirectUrl = '/profile?redirect_url=/cart';
                }
            }
             
            this.setState({
                redirectUrl: redirectUrl,
            });
        }
        if(this.props.checkout_redirect == true)
        {
            this.setState({
                redirectUrl: '/checkout',
            });
        }
    }
    mobHandler(phone, resend) {
        var data = JSON.stringify({phone: phone});
        let header = new Headers({
            
        });
        let url = process.env.REACT_APP_API_URL+'/v1/user/session';
        fetch(url, {
            method: 'POST',
            headers: header,
            body: data
        })
        .then(response => response.json())
        .then(data => this.setState({
                showPhone: false,
                phone: phone,
                resend: resend
            }));
        this.props.change_step(2);
    }

    otpHandler(otp){
        const otp_data = JSON.stringify({phone: this.state.phone, 'otp': otp});
        var header = new Headers();

        let url = process.env.REACT_APP_API_URL+'/v1/user/session/verify';

            fetch(url, {
                method: 'POST',
                headers: header,
                body: otp_data
            })
            .then(response => response.json())
            .then(
                (data) => {
                    if(data.msg=='OTP Mismatch!')
                    {
                        swal("OTP entered is incorrect");
                    }
                    else
                    {
                        localStorage.setItem('token', data.token);
                        ClevertapReact.event("OtpVerified");
                        this.CartToBackend(data.token);
                        //this.FetchCartBackend(data.token);
                        this.setState({
                            token : data.token,
                            msg: data.msg,
                            redirect: true
                        });
                    }
                });
    }
    CartToBackend(token)
    {
        let data = [];
        let key = '';
        let prod_data = [];
        let prod_ids = JSON.parse(localStorage.getItem('prod_counts'));
        if(prod_ids)
        {
            prod_ids.forEach(item => {
            key = 'prod_'+item;

            prod_data = JSON.parse(localStorage.getItem(key));
            data.push(prod_data);
            });
            
            
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ token);

            data = JSON.stringify({'cart_data' : data, "track_source": this.state.track_source});
            let url = process.env.REACT_APP_API_URL+'/v1/cart';

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: data,
                redirect: 'follow'
            };

            fetch(url, requestOptions)
            .then(response => response.json())
            
            .then(data => 
                {
                    console.log(data);
                    this.SubToBackend(data,token);
                    
                }
            )  
        }
    }
    SubToBackend(cart_details,token)
    {
        let data = [];
        let sub_data = [];
        sub_data = JSON.parse(localStorage.getItem("sub_data")); //Passing Subscription details to backend
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ token);

        let url = process.env.REACT_APP_API_URL+'/v1/cart';

        var credits_in_wallet = cart_details.result.user_data.credits_in_wallet;
        var total_items = cart_details.result.billing.total_items;

        if(total_items > credits_in_wallet)
        {
            data.push(sub_data);
            data = JSON.stringify({'cart_data' : data, "track_source": this.state.track_source});
            let url = process.env.REACT_APP_API_URL+'/v1/cart';
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: data,
                redirect: 'follow'
            };
            fetch(url, requestOptions)
            .then(response => response.json())
            
            .then(data => 
                this.setState({
                    redirect: true,
                    redirectUrl:'/checkout',
                })
            )
        }
        else
        {
            this.setState({
                redirect: true,
                redirectUrl:'/checkout',
            })
        }
    }
    render() { 
        if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
		return(
            <div className="curved_div col-xs-12 col-sm-6 col-md-6 col-lg-6">
                {this.state.showPhone ? <EnterMobile mob_handler={this.mobHandler} /> : <EnterOTP otp_handler={this.otpHandler} phone={this.state.phone} mob_handler={this.mobHandler}/> }
            </div>
		);
    }
}

export default MobileNumberBox;