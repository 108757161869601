import React, { Component } from "react";  
  
export default class Sweetalertdemo extends Component {  
  
  constructor(props) {  
    super(props);  
    this.state = {
      alert_box: 'btn btn-success',
      alert_msg: this.props.alert_msg,
      show_toast: this.props.show_toast,
    };

  }  
  render() {  

    return (
      <div>
      {this.props.alert_msg != '' ?
          <div id="snackbar" className={this.props.show_toast}>  
            <p>{this.props.alert_msg}</p>
          </div>
        :
        null
      }
      </div>
    );  
  }  
} 
/*<div className="col-xs-12">    
          {this.props.alert_msg != '' ? 
          <p>{this.props.alert_msg}</p>
          :
          null }
        </div>  */