/*import React, { Component } from "react";
import Header from "../Common/Header";
import { Link } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class Referral extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            credits: 0,
            name: localStorage.getItem("name"),
            oj_referral_cash : 0,
            oj_referral_credit : 0,
            show_submit_referral : 0,
            show_referral : 0,
            phone : '',
            transactions : [],
            copySuccess : '',
        };
        this.ApplyReferral = this.ApplyReferral.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }
    componentDidMount()
    {
        if(this.state.token)
        {
           var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ this.state.token);
            var requestOptions = {
                headers: myHeaders,
            };
            fetch(process.env.REACT_APP_API_URL+'/v1/user', requestOptions)
            .then(response => response.json())
            .then(
            (res) => {
                console.log(res);
                this.setState({credits: res.result.credits, oj_referral_cash: res.result.oj_referral_cash, oj_referral_credit: res.result.oj_referral_credit, phone: res.result.phone});
            }); 

            fetch(process.env.REACT_APP_API_URL+'/v3/showReferral', requestOptions)
            .then(response => response.json())
            .then(
            (res) => {
                console.log(res);
                this.setState({show_submit_referral: res.data.show_submit_referral, show_referral: res.data.show_referral, transactions: res.data.transactions});
            }); 
        }
    }
    ApplyReferral()
    {
        alert("ok");
    }
    copyToClipboard()
    {
        this.setState({copySuccess: 'Copied'})
    }
    render() {
        var credit_text = this.state.oj_referral_credit > 1 ? "Credits" : "Credit";
        return(
			<div>
                <Header color={"#160D08"}/>  
                <div className="col-sm-12 padding_is_zero">
                    <img src="/images/Header/referral_header_img.png" className="img-responsive" width="100%"/>
                    <div className="col-sm-12 referral_section">
                        <div className="referral_section_one">
                            <p className="referral_section_header_p">YOUR Wallet balance</p>
                            <p className="referral_cash_value">Rs {this.state.oj_referral_cash}    +    {this.state.oj_referral_credit} {credit_text}</p>
                            <span className="referral_ques_text"><p><i class="fa fa-exclamation-circle" aria-hidden="true"></i> How to use OJ Cash?</p> <p><i class="fa fa-exclamation-circle" aria-hidden="true"></i> How to use OJ Credit?</p></span>
                            <p className="referral_start_text">Start referring your friends to earn instant OJ Cash.</p>
                        </div>
                        {this.state.show_submit_referral ?
                            <div className="referral_submit_section">
                                <p className="referral_start_text">Enter your friend’s referral code</p>
                                <input type="text" className="referral_submit_code_text"/><button className="referral_submit_button">Submit</button>
                            </div>
                            :
                            null
                        }
                        
                        <p className="referral_earn_text_1">Earn instant OJ Cash and Credits with</p>
                        <p className="referral_earn_text_2">Refer and Earn</p>
                        <center><img src="/images/Header/referral_img1.png" /></center>
                        {this.state.show_referral ?
                            <div className="referral_show_section">
                                <p className="referral_show_text">Your referral code is just your registered mobile number</p>
                                <input type="text" value={this.state.phone} className="referral_code_input" /> 
                                <CopyToClipboard text={this.state.phone} >
                                    <span onClick={this.copyToClipboard}><i class="fa fa-copy"></i></span>
                                </CopyToClipboard>
                                <p>{this.state.copySuccess}</p>
                            </div>
                            :
                            null
                        }
                        <p>Transaction History</p>
                        {this.state.transactions.map((details, index) => (
                            <p>hello</p>
                        ))}
                    </div>
                </div>
                <div className="modal-footer-product-details-new">
                    <button onClick={this.ApplyReferral}>REFER NOW</button>
                </div>
            </div>
		);
		
	}
}

export default Referral;*/
import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import { Link, useParams } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RWebShare } from "react-web-share";
import swal from 'sweetalert';
import { Redirect } from 'react-router';
import animationData from '../Lotties/New_LoadingAnimation.json';
import Lottie from 'react-lottie';
import { Modal, Button, Form } from "react-bootstrap";


const Referral = () => {
    const [token, setToken] = useState('');
    const [credits, setCredits] = useState(0);
    const [name, setName] = useState('');
    const [oj_referral_cash, setOJCash] = useState(0);
    const [oj_referral_credit, setOJCredit] = useState(0);
    const [show_submit_referral, setShowSubmitReferral] = useState(0);
    const [show_referral, setShowReferral] = useState(0);
    const [phone, setPhone] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [copySuccess, setCopySuccess] = useState('');
    const [referralInput, setReferralInput] = useState('');
    const [redirectTo, setRedirectTo] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [app_id, setAppId] = useState(0);
            
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        let app_id = params.get('app_id');
        if(app_id != 1)
        {
            const token = localStorage.getItem('token');
            const name = localStorage.getItem('name');
            if (token) {
                setToken(token);
                setName(name);
                ReferralApiCall(token);
            }
            else
            {
                setRedirectTo('/login');
                setRedirect(true);
            }
        }
        else
        {
            let token = params.get('token');
            if(token)
            {
                setAppId(1);
                setToken(token);
                ReferralApiCall(token);
            }
            else
            {
                setRedirectTo('/login');
                setRedirect(true);
            }
            
        }
    }, []);
    
    function ReferralApiCall(token)
    {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ token);
        var requestOptions = {
            headers: myHeaders,
        };
        fetch(process.env.REACT_APP_API_URL+'/v1/user', requestOptions)
        .then(response => response.json())
        .then(
        (res) => {
            console.log(res);
            setCredits(res.result.credits);
            setOJCash(res.result.oj_referral_cash);
            setOJCredit(res.result.oj_referral_credit);
            setPhone(res.result.phone);
        }); 

        fetch(process.env.REACT_APP_API_URL+'/v3/showReferral', requestOptions)
        .then(response => response.json())
        .then(
        (res) => {
            console.log(res);
            setShowSubmitReferral(res.data.show_submit_referral);
            setShowReferral(res.data.show_referral);
            setTransactions(res.data.transactions);
        }); 
    }
    const handleChange = (event) => {
        setReferralInput(event.target.value);
    }
    const ApplyReferral = (event) => {
    
        var data = JSON.stringify({referral_id : referralInput});
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ token);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };
        let url = process.env.REACT_APP_API_URL+'/v3/saveReferralId';
        fetch(url, {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        })
        .then(response => response.json())
        .then(
            (res) => {
                console.log(res);
                if(res.success)
                {
                    var span = document.createElement("span");
                    var defaultOptions = {
                      loop: true,
                      autoplay: true,
                      animationData: animationData,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice"
                      }
                    };
                    var styles = {
                        checkAnimation: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            minHeight: '80vh',
                        },
                    };
                    span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><center><p style='color: #2F2679;margin-top: 10px;font-family: futuraExtraBlack;font-size: 2.5rem;'>REFERRAL APPLIED SUCCESSFULLY!!!</p><Lottie options= "+defaultOptions+" width="+300+" style= "+styles.checkAnimation+"/><p style='color: #89949E;font-family: NunitoSans-Black;font-size: 12px;'>You have received <small style='color: #3FA5B2;font-family: NunitoSans-Black;font-size: 12px;'>150 OJ Cash </small>as a referral gift.</p></center></div>";
                    swal({
                        content: span,
                        showConfirmButton: false
                    }).then(function() {
                        if(app_id == 1)
                        {
                            window.location = "/referral?app_id=1&token="+token;
                        }
                        else
                        {
                            window.location = "/referral";
                        }
                        
                    });
                }
                else
                {
                    swal(res.message)
                }
            });
    }

    function CopyText()
    {
        setCopySuccess('Copied !');
    }

    var credit_text = oj_referral_credit > 1 ? "Credits" : "Credit";
    if(redirect)
    {
            return <Redirect to={redirectTo} />
    }
    return (
        //
        <div>
            {app_id != 1 ?
                <Header color={"#160D08"}/> 
                :
                null
            }
            
            <div className="col-sm-4"></div>
            <div className="col-xs-12 col-sm-4 padding_is_zero">
                <div>
                    <img src="/images/Header/referral_header_img.png" className="img-responsive" width="100%"/>
                    <div className="col-sm-12 referral_section">
                        <div className="referral_section_one">
                            <p className="referral_section_header_p">YOUR Wallet balance</p>
                            <p className="referral_cash_value">Rs {oj_referral_cash}    +    {oj_referral_credit} {credit_text}</p>
                            <span className="referral_ques_text"><p data-toggle="modal" data-target="#oj_cash_modal"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> How to use OJ Cash?</p> <p data-toggle="modal" data-target="#oj_credit_modal"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> How to use OJ Credit?</p></span>
                            <p className="referral_start_text">Start referring your friends to earn instant OJ Cash.</p>
                        </div>
                        {show_submit_referral ?
                            <div className="referral_submit_section">
                                <p className="referral_start_text">Enter your friend’s referral code</p>
                                <input type="text" className="referral_submit_code_text" name="referral_no" onChange={handleChange} maxLength="10"/><button className="referral_submit_button" onClick={ApplyReferral}>Submit</button>
                            </div>
                            :
                            null
                        }
                        
                        <p className="referral_earn_text_1">Earn instant OJ Cash and Credits with</p>
                        <p className="referral_earn_text_2">Refer and Earn</p>
                        <center><img src="/images/Header/referral_img1.png" /></center>
                        {show_referral ?
                            <div>
                                <p className="referral_tran_text"><img src="/images/Header/referral_works.png" />How referral works?</p>
                                <ol className="referral_steps">
                                    <li>Share your referral code with your friend and ask them to register using it.</li>
                                    <li>You get instant OJ Cash of <small className="referral_step_green_text">Rs. 100</small> and your friend gets OJ Cash of <small className="referral_step_green_text"> Rs. 150</small>.</li>
                                    <li>You also get <small className="referral_step_green_text"> 1 OJ Credit </small> once 2 of your referred friends make successful purchases. <br /> The Credit is added to your account after their return period of 14 days is over</li>
                                </ol>
                                <div className="referral_show_section">
                                    <center>
                                        <p className="referral_show_text">Your referral code is just your registered mobile number</p>
                                        <input type="text" value={phone} className="referral_code_input" readOnly/> 
                                        <span className="referral_share_copy_icon">
                                            <CopyToClipboard text={phone} >
                                                <span><i class="fa fa-copy" onClick={CopyText}></i></span>
                                            </CopyToClipboard>
                                            {app_id != 1 ?
                                                <RWebShare
                                                    data={{
                                                        text: "Hooray! You've been referred to Ojoswear by your bestie "+name+". Join now and get a cash credit of 150 in your account, which you can use towards your Ojoswear purchase. Use Referral Code "+phone+" and elevate your style with our trendy and affordable eyewear!",
                                                        url: "Shop now @ bit.ly/referral_claim",
                                                        title: "Referral Code",
                                                    }}
                                                    onClick={() => console.log("shared successfully!")}
                                                >
                                                <i class="fa fa-share-alt"></i>
                                                </RWebShare>
                                                :
                                                null
                                            }
                                            
                                        </span>
                                        <p className="referral_copied_text">{copySuccess}</p>
                                    </center>
                                </div>
                            </div>
                            :
                            <div>
                                <p className="referral_tran_text"><img src="/images/Header/referral_works.png" />How referral works?</p>
                                <ol className="referral_steps">
                                    <li>Share your referral code with your friend and ask them to register using it.</li>
                                    <li>You get instant OJ Cash of <small className="referral_step_green_text">Rs. 100</small> and your friend gets OJ Cash of <small className="referral_step_green_text"> Rs. 150</small>.</li>
                                    <li>You also get <small className="referral_step_green_text"> 1 OJ Credit </small> once 2 of your referred friends make successful purchases. <br /> The Credit is added to your account after their return period of 21 days is over</li>
                                </ol>
                                <div className="referral_show_section">
                                    <center>
                                        <p className="referral_show_text">Unlock your referral code and earn more free credits by placing your first order</p>
                                        <input type="text" value="XXXXXXXXXX" className="referral_code_input" readOnly/> 
                                        <span className="referral_share_copy_icon">
                                            <span><i class="fa fa-copy"></i></span>
                                            <i class="fa fa-share-alt"></i>
                                        </span>
                                    </center>
                                </div>
                            </div>
                        }
                        {transactions.length > 0 ?
                            <div>
                                <p className="referral_tran_text"><img src="/images/Header/transaction_referral.png" />Transaction History</p>
                                {transactions.map((details, index) => (
                                    <div key={index}>
                                        {details.order_id != null ?
                                            <p className="referral_history_list_sec">
                                                Order ID: {details.order.hashed_id} 
                                                {details.transaction_type == "credit" ? 
                                                    <p className="referral_history_cost_value"> 
                                                        + 
                                                        {details.referral_val_type == "oj_cash" ? 
                                                            <p className="p_margin_bottom_zero">Rs.{details.value}</p> 
                                                            : 
                                                            <p className="p_margin_bottom_zero">{details.value} credit </p>
                                                        }
                                                    </p>
                                                    : 
                                                    <p className="referral_history_cost_value"> 
                                                        -
                                                        {details.referral_val_type == "oj_cash" ? 
                                                            <p className="p_margin_bottom_zero">Rs.{details.value}</p> 
                                                            : 
                                                            <p className="p_margin_bottom_zero">{details.value} credit</p>
                                                        }
                                                    </p> 
                                                } 
                                            </p>
                                            :
                                            <p className="referral_history_list_sec">
                                                Referred 
                                                {details.referral_val_type == "oj_cash" ? 
                                                    <p className="p_margin_bottom_zero"> to </p> 
                                                    : 
                                                    <p className="p_margin_bottom_zero"> from </p> 
                                                } 
                                                {details.refree.name}  
                                                {details.transaction_type == "credit" ? 
                                                    <p className="referral_history_cost_value"> 
                                                        + 
                                                        {details.referral_val_type == "oj_cash" ? 
                                                            <p className="p_margin_bottom_zero">Rs.{details.value}</p> 
                                                            : 
                                                            <p className="p_margin_bottom_zero">{details.value} credit </p>
                                                        }
                                                    </p>
                                                    : 
                                                    <p> 
                                                        - 
                                                        {details.referral_val_type == "oj_cash" ? 
                                                            <p>Rs.{details.value}</p> 
                                                            : 
                                                            <p>{details.value} credit</p>
                                                        }
                                                    </p> 
                                                } 
                                            </p>
                                        }
                                    </div>
                                ))}
                            </div>
                            :
                            null
                        }
                        
                    </div>
                    <div class="modal fade" id="oj_cash_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title referral_modal_header" id="exampleModalLabel">HOW TO USE OJ REFERRAL CASH?</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p className="referral_modal_text">Your OJ Referral Cash can be used to pay for your orders along with any other payment modes. 
                                    <br />
                                    <br/>
                                    OJ Cash can be redeemed only for a maximum of 20% of total value of your order in one transaction.
                                    <br />
                                    <br />
                                    There is no limit to collect the OJ Cash. Keep referring as many friends as you want.
                                    </p>
                                </div>
                                {/*<div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary">Save changes</button>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="oj_credit_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title referral_modal_header" id="exampleModalLabel">HOW TO USE OJ REFERRAL CREDIT?</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p className="referral_modal_text">Purchase eyeglasses anytime using your OJ credits 1 credit = 1 eyeglass<br />
                                    <br/>
                                    The OJ Credit is applicable on all sunglasses and eyeglasses with Single Vision and Zero Power Lens packages only.
                                    <br />
                                    <br />
                                    The OJ Credit will be applicable only on the eyeglass frame when purchased with Single Vision Blu Cut Lens package. Additional cost of Rs. 890 has to be paid separately for the lens package.
                                    </p>
                                </div>
                                {/*<div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary">Save changes</button>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                
                <div className="clearfix"></div>
                <div>
                    {app_id != 1 ?
                        <div>
                            {show_referral ?
                                <div className="modal-footer-referral-system">
                                    <RWebShare
                                        data={{
                                            text: "Hooray! You've been referred to Ojoswear by your bestie "+name+". Join now and get a cash credit of 150 in your account, which you can use towards your Ojoswear purchase. Use Referral Code "+phone+" and elevate your style with our trendy and affordable eyewear!",
                                            url: "Shop now @ bit.ly/referral_claim",
                                            title: "Referral Code",
                                        }}
                                        onClick={() => console.log("shared successfully!")}
                                    >
                                    <button className="btn select_lense_button">REFER NOW</button>
                                    </RWebShare>
                                    
                                </div>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                    
                </div>
            </div>
            <div className="col-sm-4"></div>
            


        </div>
    );
};
export default Referral;