import React, { Component } from "react";
import { Link } from 'react-router-dom';

class CheckoutSelectAddress extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	user_data: this.props.user_data,
	    	save_add_address: this.props.save_add_address,
	    	select_address_id: this.props.user_data.address[0].id,
	    	show_select_address: true,
	    	name: this.props.user_data.name,
	    	mob: this.props.user_data.phone,
	    	country: 'India',
	    	addr1: '',
	    	addr2: '',
	    	city: '',
	    	state: '',
	    	pin: '',
	    }
	    this.myChangeHandler = this.myChangeHandler.bind(this);
	    this.HandleOnChange = this.HandleOnChange.bind(this);
	    this.myChangeHandlerNew = this.myChangeHandlerNew.bind(this);
	    this.addAddress = this.addAddress.bind(this);
	}
	myChangeHandler = (event) => {
	    let nam = event.target.name;
	    let val = event.target.value;
	    this.setState({[nam]: val});
	}
	HandleOnChange(id) {
    	var selected_option = id;
    	var address_id = selected_option.substring(8);
    	this.setState({ select_address_id: address_id});
  	}
  	myChangeHandlerNew = (event) => {
	    let nam = event.target.name;
	    let val = event.target.value;
	    if(nam == "city" || nam == "country" || nam == "state")
	    {
	    	val = val.replace(/[^A-Za-z ]/ig, '');
	    }
	    if(nam == "mob")
	    {
	    	val = val.replace(/[^0-9]/ig, '');
	    }

	    this.setState({[nam]: val});
	}
  	addAddress()
  	{
  		this.setState({ show_select_address: !this.state.show_select_address});
  	}
	render() {
		
		return (
			<div>
				<div>
						<div>
							{this.props.user_data.address.map((item, i) => (
								<div className="col-sm-6 padding_left_zero">
									<div className="cart_address_card" key={i} onClick={()=>this.HandleOnChange("address_"+item.id)}>
										<div  className="cart_quiz_card_area">
											<input className="quiz_checkbox" type="radio" name="address_selected" id={"address_"+item.id} checked = {this.state.select_address_id == item.id}/>
											<div className="single_quiz_card_cart">
												<div className="quiz_card_content">
													<div className="quiz_card_title_cart ">
														<p>{item.name}'s Address</p>
														<div className="cart_address_detail">
															<p>{item.phone} | {this.props.user_data.email}</p>
															<p>{item.shipping_address+", "+item.ship_city+", "+item.ship_state+", "+item.ship_code}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
						<div className="clearfix"></div>
						<div className="col-sm-6 padding_left_zero">
	    					<center><button onClick={this.props.show_add_address}className='btn add_new_address'>&#160; &#160;<i className="fa fa-plus" aria-hidden="true"> </i>&#160; Add New Address&#160;&#160;</button></center>
						</div>
						<div className="col-sm-6 padding_right_zero">
	    					<center><button className="save_address_button_web" onClick={() => this.props.address_id(this.state.select_address_id)}>Proceed with this address</button></center>
						</div>
					</div>
					
			</div>
		);
		
		
	}
}

export default CheckoutSelectAddress;
