export const FETCH_PRODUCTS_BEGIN   = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const fetchProductsBegin = () => ({
    type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: { products }
});

export const fetchProductsFailure = error => ({
    type: FETCH_PRODUCTS_FAILURE,
    payload: { error }
});
export function fetchProductsARType(type,frame_type) {

    var url = process.env.REACT_APP_API_URL+"/v3/products/filter?ar_status=1&per_page=500&gender="+type; 
     
    return dispatch => {
        dispatch(fetchProductsBegin());
        console.log(url);
        return fetch(url)
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            dispatch(fetchProductsSuccess(json.data));
            return json.products;
          })
        .catch(error => dispatch(fetchProductsFailure(error)));
    };
}
// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}