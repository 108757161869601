import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from '../Common/Footer';
import OrderTracker from './OrderTracker';
import { Link } from 'react-router-dom';
import Loading from "../Common/Loading";
import swal from 'sweetalert';
import { Redirect } from 'react-router';

class OrderProductFilter extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            token : localStorage.getItem("token"),
            order_id: '',
            product_id: '',
            product_details: {delivery_address:{},products:{}, power: {}},
            redirect: false,
            redirectUrl: '/',
            show_exchange_button : false,
        };
        this.CheckEligibility  = this.CheckEligibility.bind(this);
    }
    componentDidMount()
    {
        let order_id =  this.props.match.params.order_id;
        let product_id = this.props.match.params.product_id;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.state.token);

        let url = process.env.REACT_APP_API_URL+'/v1/orders/'+order_id+'/product/'+product_id;

        var requestOptions = {
            headers: myHeaders,
        };

        fetch(url, requestOptions)
        .then(response => response.json())
        
        .then(data => {
            this.setState({order_id: order_id, product_id: product_id, product_details: data.data, products: data.data.products},
                ()=> {
                    if(this.state.product_details.status == 'Delivered'  && (this.state.product_details.delivery_address.phone == '7702550478' || this.state.product_details.delivery_address.phone == '8105387416' || this.state.product_details.delivery_address.phone == '8447705584' || this.state.product_details.delivery_address.phone == '7004699245' || this.state.product_details.delivery_address.phone == '8197137097' || this.state.product_details.delivery_address.phone == '9731063350'))
                    {
                        var delivered_date = new Date(this.state.product_details.delivered_on);
                        var today_date = new Date();
                        var diff_in_time = today_date.getTime() - delivered_date.getTime();
                        var diff_in_day = diff_in_time / (1000 * 3600 * 24);
                        if(diff_in_day < 21)
                        {
                            this.setState({show_exchange_button: true})
                        }
                    }
                }) 
        })

    }
    CheckEligibility()
    {
        var data = JSON.stringify({"updated_source":"user"});
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.state.token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };
        let url = process.env.REACT_APP_API_URL+'/v1_1/return/checkEligibility/'+this.state.product_details.hashed_id;
        fetch(url, {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        })
        .then(response => response.json())
        .then(
            (res) => {
                if(res.success)
                {
                    this.setState({redirect: true, redirectUrl: '/exchange/order/'+this.state.order_id+'/product/'+this.state.product_id}) 
                }
                else
                {
                    swal(res.message);
                }
            });
    }
    render() {
        console.log(this.state.product_details)
        if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        }
        return(
            <div>
                <Header color={"#160D08"} />
                {this.state.product_details.id != null ?
                    <div className="each_order_section_body">
                        <div className="col-sm-3"></div>
                        <div className="col-xs-12 col-sm-6 each_order_id_section">
                            <p className="each_product_order_id">Order ID</p>
                            <p>{this.state.product_details.hashed_id}</p>
                        </div>
                        <div className="col-sm-3"></div>

                        <div className="clearfix"></div>

                        <div className="col-sm-3"></div>
                        <div className="col-xs-12 col-sm-6  padding_is_zero">
                            <center>
                                <Link to={{pathname: "/product/"+this.state.product_details.products.product_url+"/color/"+this.state.product_details.products.color_id, state: { prevPath: window.location.pathname }}}><img src={this.state.product_details.products.image} className="order_product_image img-responsive" /></Link>
                            </center>
                        </div>
                        <div className="col-sm-3"></div>

                        <div className="col-xs-12 order_product_details">
                            <center>
                                <p className="order_product_brand_text">{this.state.product_details.products.brand_name}</p>
                                <p className="order_product_full_name">{this.state.product_details.products.name}</p>
                                <p className="order_product_size">{this.state.product_details.products.size} - {this.state.product_details.products.gender}<span className="colour_dot order_product_color" style={{backgroundColor: this.state.product_details.products.color}} ></span></p>
                                <p className="order_product_qty">X {this.state.product_details.products.qty}</p>
                            </center>
                        </div>
                        <div className="col-xs-12 order_product_details_power_option">
                        {(this.state.product_details.products.power_type == "single_vision" || this.state.product_details.products.power_type == "single_vision_bluecut") && this.state.product_details.power.length == 0 ?
                            <a href={"/power_new/order/"+this.state.product_details.id+"/product/"+this.state.product_details.products.color_id+"/order_prod_id/"+this.state.product_details.products.odr_prd_detail_id}><p className="order_product_eye_power">Enter Eye Power</p></a>
                            :
                            null
                        }
                        </div>
                        {this.state.product_details.tracker ?
                            <div>
                                {this.state.product_details.tracker.length > 0 ?
                                    <OrderTracker tracker={this.state.product_details.tracker}/>
                                    : 
                                    null
                                }
                            </div>
                            :
                            null
                        }
                        
                        <div className="col-sm-3"></div>
                        <div className="clearfix"></div>

                        {this.state.show_exchange_button ?
                            <div>
                                <div className="col-sm-3"></div>
                                <div className="col-xs-12 col-sm-6 order_product_exchange_div padding_is_zero">
                                    <center><button className="order_product_exchange_button" onClick={this.CheckEligibility}>EXCHANGE ORDER</button></center>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                            :
                            null
                        }
                        <div className="clearfix"></div>
                        
                        {this.state.product_details.power.left ?
                            <div>
                                <div className="col-sm-3"></div>
                                <div className="col-xs-12 col-sm-6 order_product_power padding_is_zero">
                                    <p className="order_delivery_text">Power Details</p>
                                    <div className="col-sm-6 col-xs-6">
                                        <p className="order_delivery_subtext">
                                            <u>Left</u><br/> Spherical: {this.state.product_details.power.left.sph}<br/> Cylinderical: {this.state.product_details.power.left.cyl}<br/> PD:  {this.state.product_details.power.left.pd}<br/> Axis: {this.state.product_details.power.left.axis}
                                        </p>
                                    </div>
                                    <div className="col-sm-6 col-xs-6">
                                        <p className="order_delivery_subtext">
                                            <u>Right</u><br/> Spherical: {this.state.product_details.power.right.sph}<br/> Cylinderical: {this.state.product_details.power.right.cyl}<br/> PD:  {this.state.product_details.power.right.pd}<br/> Axis: {this.state.product_details.power.right.axis} 
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                            :
                            null
                        }
                        <div className="clearfix"></div>
                        {this.state.product_details.delivery_address.shipping_address ?
                            <div className="col-sm-12 padding_is_zero">
                            <div className="col-sm-3"></div>
                            <div className="col-xs-12 col-sm-6 order_product_address padding_is_zero">
                                <p className="order_delivery_text">Delivery Address</p>
                                <p className="order_delivery_subtext">{this.state.product_details.delivery_address.name} | {this.state.product_details.delivery_address.phone}</p>
                                <p className="order_delivery_subtext">{this.state.product_details.delivery_address.shipping_address}, {this.state.product_details.delivery_address.ship_city}, {this.state.product_details.delivery_address.ship_state}, {this.state.product_details.delivery_address.ship_code}</p>
                            </div>
                            <div className="col-sm-3"></div>
                            </div>
                            :
                            null
                        }
                        <div className="clearfix"></div>

                        {this.state.product_details.total_cost ?
                            <div className="col-sm-12 padding_is_zero">
                                <div className="col-sm-3"></div>
                                <div className="col-xs-12 col-sm-6 order_product_price padding_is_zero">
                                    <p className="order_delivery_text">Amount Paid</p><p></p>
                                    <p className="order_delivery_subtext">Rs. {this.state.product_details.total_cost}</p>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                            :
                            null
                        }
                        <div className="clearfix"></div>
                    </div>
                :
                    <Loading />
                }
                <Footer />
            </div>
        );
        
    }
}

export default OrderProductFilter;
