import React from "react";

const ProductStaticFeature = (props) => {
  return props.source === "web" ? (
    <div className="col-xs-12 product_extra">
      <div className="col-xs-3 each_product_extra">
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/flash-dashboard-image/icons/icons8_return.svg"
          className="product_feature_icons"
          alt="icons"
        />
        <p className="redo_text" style={{ fontSize: "16px" }}>
          14 Days Return
        </p>
      </div>
      <div className="col-xs-3 each_product_extra">
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/flash-dashboard-image/icons/icons8_guarantee.svg"
          className="product_feature_icons"
          alt="icons"
        />
        <p className="redo_text" style={{ fontSize: "16px" }}>
          1 Year Warranty
        </p>
      </div>
      <div className="col-xs-3 each_product_extra">
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/flash-dashboard-image/icons/icons8_free_shipping.svg"
          className="product_feature_icons"
          alt="icons"
        />
        <p className="redo_text" style={{ fontSize: "16px" }}>
          Free Delivery
        </p>
      </div>
      <div className="col-xs-3 each_product_extra">
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/flash-dashboard-image/icons/FastShippingIcon.svg"
          className="product_feature_icons"
          alt="icons"
        />
        <p className="redo_text" style={{ fontSize: "16px" }}>
          Fast Shipping
        </p>
      </div>
    </div>
  ) : (
    <div className="col-xs-12 product_extra">
      <div className="col-xs-3 each_product_extra">
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/flash-dashboard-image/icons/icons8_return.svg"
          className="product_feature_icons"
          alt="icons"
        />
        <p className="redo_text">14 Days Return</p>
      </div>
      <div className="col-xs-3 each_product_extra">
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/flash-dashboard-image/icons/icons8_guarantee.svg"
          className="product_feature_icons"
          alt="icons"
        />
        <p className="redo_text">1 Year Warranty</p>
      </div>
      <div className="col-xs-3 each_product_extra">
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/flash-dashboard-image/icons/icons8_free_shipping.svg"
          className="product_feature_icons"
          alt="icons"
        />
        <p className="redo_text">Free Delivery</p>
      </div>
      <div className="col-xs-3 each_product_extra">
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/flash-dashboard-image/icons/FastShippingIcon.svg"
          className="product_feature_icons"
          alt="icons"
        />
        <p className="redo_text">Fast Shipping</p>
      </div>
    </div>
  );
};

export default ProductStaticFeature;
