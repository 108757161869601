import React, { Component } from "react";
import Header from "../Common/Header";

class OjosFeedback extends Component 
{
	render() {
		return(
			
			<div>
				<Header color={"#160D08"}/> 
				<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScw4q7ZGjdU38g8ud2p-FyqnbGeocFxqdSoVT8jnig0e_QHRQ/viewform?usp=pp_url" className="ar_iframe"></iframe>
		    </div>
			
		);
		
	}
}

export default OjosFeedback;