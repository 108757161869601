import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { RWebShare } from "react-web-share";
import Lottie from 'react-lottie';
import animationData from '../Lotties/ojos_loading.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

class ImageViewNew extends Component 
{    
    constructor(props) {
        super(props);
    }

    
    render() {
        
      const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          };
        const styles = {
            checkAnimation: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              minHeight: '50vh',
            },
        }
        let dragging = false;
        const settings = {
            draggable: true,
            dots: true,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: true,
            accessibility: true,
            swipeable: true,
            beforeChange: () => dragging = true,
            afterChange: () => dragging = false,
            appendDots: (dots) => {
                return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />
            }
        };

      return(
            <div className="all_image_section">
                <div className="img_model">
                    <div className="carousel_product_all_listing carousel" aria-label="Gallery" data-interval="false">
                        
                        <ol className="carousel__viewport carousel__viewport_new_item carousel__viewport_all_products_section">
                            <Slider {...settings}>
                            {this.props.item.glass_images.map((image_item, index) => (
                                
                                    <div>
                                        <div>
                                            {this.props.item.gender == "Unisex" ?
                                                <div>
                                                    {this.props.path_source == ''?
                                                        <div>
                                                            {this.props.i == this.props.choosen_index ?
                                                                <div>
                                                                    <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                    <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id, state: { prevPath: window.location.pathname+window.location.search }}}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id, state: { prevPath: window.location.pathname+window.location.search }}}><img src={image_item.url} onLoad={this.ImageLoaded} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                </div>
                                                            }
                                                            
                                                        </div>
                                                        :
                                                        <div>
                                                            {this.props.i == this.props.choosen_index ?
                                                                <div>
                                                                    <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                    <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?gender="+this.props.item.gender+"&path_source="+this.props.path_source, state: { prevPath: window.location.pathname+window.location.search }}}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?gender="+this.props.item.gender+"&path_source="+this.props.path_source, state: { prevPath: window.location.pathname+window.location.search }}}><img src={image_item.url} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div>
                                                    {this.props.path_source == ''?
                                                        <div>
                                                            {this.props.i == this.props.choosen_index ?
                                                                <div>
                                                                    <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                    <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id, state: { prevPath: window.location.pathname+window.location.search }}}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id, state: { prevPath: window.location.pathname+window.location.search }}}><img src={image_item.url} onLoad={this.ImageLoaded} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                </div>
                                                            }
                                                            
                                                        </div>
                                                        :
                                                        <div>
                                                            {this.props.i == this.props.choosen_index ?
                                                                <div>
                                                                    <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                    <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?path_source="+this.props.path_source, state: { prevPath: window.location.pathname+window.location.search }}}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?path_source="+this.props.path_source, state: { prevPath: window.location.pathname+window.location.search }}}><img src={image_item.url} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                
                            ))}
                            
                            </Slider>
                            <RWebShare
                                data={{
                                  text: this.props.item.brand_name,
                                  url: "https://ojoswear.com/product/"+ this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id,
                                  title: this.props.item.brand_name,
                                }}
                                onClick={() => console.log("shared successfully!")}
                            >
                            <img src="/images/Header/Share_Icon_ListingPage.svg" className="share_icon_web_new" alt="share_icon" />
                            </RWebShare>
                            <img src="/images/Header/PowersIconNewColor.svg" className="product_listing_rx_img" onClick={() => this.props.available_power(this.props.item.specifications.color_prod_id)}/>
                            <p className="product_listing_check_power_text" onClick={() => this.props.available_power(this.props.item.specifications.color_prod_id)}>Check Available<br/> Powers</p>
                        </ol>

                    </div>
 

                </div>
                {this.props.item.model_images.length > 0 ?
                    <div className="all_product_img_thumbnail">
                        <img className="expand_icon_web" src="/images/Header/Expand_Thumbnail_icon_ListingPage.svg" onClick={() => this.props.change_picture(this.props.i)} alt="expand_icon_web" />
                        <img className="img-rounded product_thumbnail_web" src={this.props.item.model_images[0].url} onClick={() => this.props.change_picture(this.props.i)} alt="frame_img_web"/> 
                    </div> 
                    :
                    null
                }
                <div className="clearfix"></div>
            </div> 

        );
    }
}

export default ImageViewNew;
/*
<div className="carousel_product_all_listing carousel" aria-label="Gallery" data-interval="false">
                        <div className="carousel__viewport_new_item carousel__viewport_all_products_section">
                            <Slider {...settings}>
                                {this.props.item.glass_images.map((image_item, index) => (
                                        <div className="test-sample">
                                            <RWebShare
                                                data={{
                                                  text: this.props.item.brand_name,
                                                  url: "https://ojoswear.com/product/"+ this.props.item.prod_url,
                                                  title: this.props.item.brand_name,
                                                }}
                                                onClick={() => console.log("shared successfully!")}
                                            >
                                            <img src="../images/Header/Share_Icon_ListingPage.svg" className="share_icon_web_new" alt="share_icon" />
                                            </RWebShare>
                                            <img src="/images/Header/PowersIconNewColor.svg" className="product_listing_rx_img" onClick={() => this.props.available_power(this.props.item.specifications.color_prod_id)}/>
                                            <p className="product_listing_check_power_text" onClick={() => this.props.available_power(this.props.item.specifications.color_prod_id)}>Check Available<br/> Powers</p>
                                            <div className="test-sample1">
                                                {this.props.item.gender == "Unisex" ?
                                                    <div>
                                                        {this.props.path_source == ''?
                                                            <div>
                                                                {this.props.i == this.props.choosen_index ?
                                                                    <div>
                                                                        <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                        <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id, state: { prevPath: window.location.pathname }}}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id, state: { prevPath: window.location.pathname }}}><img src={image_item.url} onLoad={this.ImageLoaded} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                    </div>
                                                                }
                                                                
                                                            </div>
                                                            :
                                                            <div>
                                                                {this.props.i == this.props.choosen_index ?
                                                                    <div>
                                                                        <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                        <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?gender="+this.props.item.gender+"&path_source="+this.props.path_source, state: { prevPath: window.location.pathname }}}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?gender="+this.props.item.gender+"&path_source="+this.props.path_source, state: { prevPath: window.location.pathname }}}><img src={image_item.url} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                        {this.props.path_source == ''?
                                                            <div>
                                                                {this.props.i == this.props.choosen_index ?
                                                                    <div>
                                                                        <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                        <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id, state: { prevPath: window.location.pathname }}}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id, state: { prevPath: window.location.pathname }}}><img src={image_item.url} onLoad={this.ImageLoaded} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                    </div>
                                                                }
                                                                
                                                            </div>
                                                            :
                                                            <div>
                                                                {this.props.i == this.props.choosen_index ?
                                                                    <div>
                                                                        <Lottie options={defaultOptions} width={200} style={styles.checkAnimation} style={{display: this.props.loading_lottie_web ? "block" : "none"}}/>
                                                                        <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?path_source="+this.props.path_source, state: { prevPath: window.location.pathname }}}><img src={image_item.url} onLoad={this.ImageLoaded} style={{display: this.props.loading_lottie_web ? "none" : "block"}} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <Link to={{pathname: "/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?path_source="+this.props.path_source, state: { prevPath: window.location.pathname }}}><img src={image_item.url} className="img-responsive frame_img" alt="frame_img"/></Link>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    
                                ))}
                            </Slider>
                        </div>
                    </div>
*/
