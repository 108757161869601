import React, { Component } from 'react';
import ReactFullpage from "@fullpage/react-fullpage";
import { Link } from 'react-router-dom';
import HomePageCarouselMob from "./HomePageCarouselMob";
import HomePageCarouselWeb from "./HomePageCarouselWeb";
import HomePageCouponSection from "./HomePageCouponSection"
import HomePageWhyOjosWear from "./HomePageWhyOjosWear";
import HomePageLaunchEdition from "./HomePageLaunchEdition";
import HomePageCollectionList from "./HomePageCollectionList";
import HomePageWomenMenSection from "./HomePageWomenMenSection";
import HomePageLaunchExtraSection from "./HomePageLaunchExtraSection";
import HomePageTrendingSection from "./HomePageTrendingSection";
import HomePageSunglassesBanner from "./HomePageSunglassesBanner"
import HomePageOjosFeatureSection from "./HomePageOjosFeatureSection";
import HomePageDownloadApp from "./HomePageDownloadApp"
import InstagramFeed from "./InstagramFeed";
import HomePageDeliverySection from "./HomePageDeliverySection";

class HomePage extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            trending_product_details: [],
            show_trending_product: false,
            instagram_feed: [],
            show_instagram_feed: false,
            collection_list: [],
            show_collection_list: false,
            homepage_carousel_web: [],
            show_carousel_list_web: false,
            homepage_carousel_mob: [],
            show_carousel_list_mob: false,
            coupon_banner_web : '',
            show_coupon_banner_web: false,
            coupon_banner_mob : '',
            show_coupon_banner_mob: false,
            collection_banner_web: '',
            show_collection_banner_web: false,
            collection_banner_mob: '',
            show_collection_banner_mob: false,
            shop_men_banner_web: '',
            show_shop_men_banner_web: false,
            shop_women_banner_web: '',
            show_shop_women_banner_web: false,
            shop_men_banner_mob: '',
            show_shop_men_banner_mob: false,
            shop_women_banner_mob: '',
            show_shop_women_banner_mob: false,
        };
        //this.ChangeColor = this.ChangeColor.bind(this);
    }
    // componentDidMount()
    // {
    //     let url = process.env.REACT_APP_API_URL+'/v2/products?type=trending';
    //     fetch(url)
    //         .then(response => response.json())
    //         .then(data => {
    //             if(data.result)
    //             {
    //                 this.setState({ trending_product_details: data.result.products, show_trending_product: true });
    //             }
    //             else
    //             {
    //                 this.setState({ show_trending_product: false });
    //             }
                
    //     })

    //     let url_insta = process.env.REACT_APP_API_URL+'/v1/getInstaFeed';
    //     fetch(url_insta)
    //         .then(response => response.json())
    //         .then(data => {
    //             if(data.data)
    //             {
    //                 this.setState({ instagram_feed: data.data, show_instagram_feed: true});
    //             }
    //             else
    //             {
    //                 this.setState({ show_instagram_feed: false});
    //             }
    //     })

    //     let url_collection = process.env.REACT_APP_API_URL+'/v1/getCollectionList';
    //     fetch(url_collection)
    //         .then(response => response.json())
    //         .then(data => {
    //             if(data.result.collection_details.length > 0)
    //             {
    //                 this.setState({ collection_list: data.result.collection_details, show_collection_list: true});
    //             }
    //     })

    //     let url_banner = process.env.REACT_APP_API_URL+'/v3/banners';
    //     fetch(url_banner)
    //         .then(response => response.json())
    //         .then(data => {
    //             if(data.success)
    //             {
    //                 var carousel_array_web = [];
    //                 var carousel_array_mob = [];
    //                 var show_carousel_list_web = this.state.show_carousel_list_web;
    //                 var show_carousel_list_mob = this.state.show_carousel_list_mob;

    //                 var coupon_banner_web = this.state.coupon_banner_web;
    //                 var show_coupon_banner_web = this.state.show_coupon_banner_web;
    //                 var coupon_banner_mob = this.state.coupon_banner_mob;
    //                 var show_coupon_banner_mob = this.state.show_coupon_banner_mob;

    //                 var collection_banner_web = this.state.collection_banner_web;
    //                 var show_collection_banner_web = this.state.show_collection_banner_web;
    //                 var collection_banner_mob = this.state.collection_banner_mob;
    //                 var show_collection_banner_mob = this.state.show_collection_banner_mob;

    //                 var shop_men_banner_web = this.state.shop_men_banner_web;
    //                 var show_shop_men_banner_web = this.state.show_shop_men_banner_web;
    //                 var shop_women_banner_web = this.state.shop_women_banner_web;
    //                 var show_shop_women_banner_web = this.state.show_shop_women_banner_web;

    //                 var shop_men_banner_mob = this.state.shop_men_banner_mob;
    //                 var show_shop_men_banner_mob = this.state.show_shop_men_banner_mob;
    //                 var shop_women_banner_mob = this.state.shop_women_banner_mob;
    //                 var show_shop_women_banner_mob = this.state.show_shop_women_banner_mob;


    //                 for(var i = 0; i<data.data.length; i++)
    //                 {
    //                     if(data.data[i].banner_type == "Homepage - carousel" && data.data[i].banner_for_desktop != "")
    //                     {
    //                         carousel_array_web.push(data.data[i]);
    //                         show_carousel_list_web = true;
    //                     }
    //                     if(data.data[i].banner_type == "Homepage - carousel" && data.data[i].banner_for_mobile_web != "")
    //                     {
    //                         carousel_array_mob.push(data.data[i]);
    //                         show_carousel_list_mob = true;
    //                     }
    //                     if(data.data[i].banner_type == "Coupon"  && data.data[i].banner_for_desktop != "")
    //                     {
    //                         coupon_banner_web = data.data[i]['banner_for_desktop'];
    //                         show_coupon_banner_web = true;
    //                     }
    //                     if(data.data[i].banner_type == "Coupon"  && data.data[i].banner_for_mobile_web != "")
    //                     {
    //                         coupon_banner_mob = data.data[i]['banner_for_mobile_web'];
    //                         show_coupon_banner_mob = true;
    //                     }
    //                     if(data.data[i].banner_type == "Eyeglasses - collection banner"  && data.data[i].banner_for_desktop != "")
    //                     {
    //                         collection_banner_web = data.data[i]['banner_for_desktop'];
    //                         show_collection_banner_web = true;
    //                     }
    //                     if(data.data[i].banner_type == "Eyeglasses - collection banner"  && data.data[i].banner_for_mobile_web != "")
    //                     {
    //                         collection_banner_mob = data.data[i]['banner_for_mobile_web'];
    //                         show_collection_banner_mob = true;
    //                     }
    //                     if(data.data[i].banner_type == "Eyeglasses - men banner"  && data.data[i].banner_for_desktop != "")
    //                     {
    //                         shop_men_banner_web = data.data[i]['banner_for_desktop'];
    //                         show_shop_men_banner_web = true;
    //                     }
    //                     if(data.data[i].banner_type == "Eyeglasses - men banner"  && data.data[i].banner_for_mobile_web != "")
    //                     {
    //                         shop_men_banner_mob = data.data[i]['banner_for_mobile_web'];
    //                         show_shop_men_banner_mob = true;
    //                     }
    //                     if(data.data[i].banner_type == "eyeglasses - female banner"  && data.data[i].banner_for_desktop != "")
    //                     {
    //                         shop_women_banner_web = data.data[i]['banner_for_desktop'];
    //                         show_shop_women_banner_web = true;
    //                     }
    //                     if(data.data[i].banner_type == "eyeglasses - female banner"  && data.data[i].banner_for_mobile_web != "")
    //                     {
    //                         shop_women_banner_mob = data.data[i]['banner_for_mobile_web'];
    //                         show_shop_women_banner_mob = true;
    //                     }
    //                 }
    //                 this.setState({ homepage_carousel_web: carousel_array_web, show_carousel_list_web: show_carousel_list_web, homepage_carousel_mob: carousel_array_mob, 
    //                     show_carousel_list_mob: show_carousel_list_mob, coupon_banner_web: coupon_banner_web, 
    //                     show_coupon_banner_web: show_coupon_banner_web, coupon_banner_mob: coupon_banner_mob, 
    //                     show_coupon_banner_mob: show_coupon_banner_mob, collection_banner_web: collection_banner_web,
    //                     show_collection_banner_web : show_collection_banner_web, collection_banner_mob: collection_banner_mob,
    //                     show_collection_banner_mob: show_collection_banner_mob, shop_men_banner_web: shop_men_banner_web,
    //                     show_shop_men_banner_web: show_shop_men_banner_web, shop_men_banner_mob: shop_men_banner_mob,
    //                     show_shop_men_banner_mob: show_shop_men_banner_mob, shop_women_banner_web: shop_women_banner_web,
    //                     show_shop_women_banner_web: show_shop_women_banner_web, shop_women_banner_mob: shop_women_banner_mob, 
    //                     show_shop_women_banner_mob: show_shop_women_banner_mob
    //                 });
    //             }
    //     })
    // }
    // ChangeColor(item_url,color_id,i)
    // {   
        
    //         var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id;
    //         let product_details_new = this.state.trending_product_details;
    //         fetch(url)
    //         .then(response => response.json())
    //         .then(products => {
    //             //console.log(products);
    //             let product_details_new = this.state.trending_product_details;
    //             product_details_new[i].model_images = products.result.products.model_images;
    //             product_details_new[i].glass_images = products.result.products.glass_images;
    //             product_details_new[i].specifications.color_prod_id = products.result.products.specifications.color_prod_id;
    //             product_details_new[i].sold_out = products.result.products.sold_out;
    //             this.setState({trending_product_details: product_details_new})
    //         });  
        
    // }
    render() {
        console.log(this.state.homepage_carousel_mob);
        return (<div>
                    <div className="mobile_homepage hidden-sm hidden-lg hidden-md">
                        <HomePageCarouselMob show_carousel_list_mob="true"/>
                        {/* <HomePageCarouselMob homepage_carousel_mob={this.state.homepage_carousel_mob} show_carousel_list_mob={this.state.show_carousel_list_mob}/>
                        <HomePageCouponSection source="mob" coupon_banner_mob={this.state.coupon_banner_mob} show_coupon_banner_mob={this.state.show_coupon_banner_mob} />
                        <HomePageWhyOjosWear />
                        <div className="clearfix"></div>
                        <HomePageLaunchEdition source="mob" collection_banner_mob={this.state.collection_banner_mob} show_collection_banner_mob={this.state.show_collection_banner_mob}/>
                        <HomePageWomenMenSection source="mob" shop_men_banner_web={this.state.shop_men_banner_web} show_shop_men_banner_web={this.state.shop_men_banner_web} shop_men_banner_mob={this.state.shop_men_banner_mob}  show_shop_men_banner_mob={this.state.show_shop_men_banner_mob} shop_women_banner_web={this.state.shop_women_banner_web} show_shop_women_banner_web={this.state.show_shop_women_banner_web} shop_women_banner_mob={this.state.shop_women_banner_mob} show_shop_women_banner_mob={this.state.show_shop_women_banner_mob}/>
                        {this.state.show_collection_list ?
                            <div className="col-xs-12 homepage_collection_list_section padding_is_zero">
                                <HomePageCollectionList source="mob" collection_list={this.state.collection_list}/>
                            </div>
                            :
                            null
                        }
                        {this.state.show_trending_product ?
                            <HomePageTrendingSection source="mob" trending_product_details={this.state.trending_product_details} change_color={this.ChangeColor}/>
                            :
                            null
                        }
                        <HomePageSunglassesBanner />
                        <HomePageLaunchExtraSection />
                        <HomePageOjosFeatureSection />
                        {this.state.show_instagram_feed ?
                            <div className="col-xs-12 homepage_instagram_feed_section">
                                <InstagramFeed source="mob" instagram_feed={this.state.instagram_feed}/>
                            </div>
                            :
                            null
                        }
                        <HomePageDownloadApp />
                        <HomePageDeliverySection /> */}
                    </div>
                    <div className="mobile_homepage hidden-xs">
                    <HomePageCarouselWeb show_carousel_list_web="true"/>
                        {/* <HomePageCarouselWeb homepage_carousel_web={this.state.homepage_carousel_web} show_carousel_list_web={this.state.show_carousel_list_web}/>
                        <HomePageCouponSection source="web" coupon_banner_web={this.state.coupon_banner_web} show_coupon_banner_web={this.state.show_coupon_banner_web}/>
                        <HomePageWhyOjosWear />
                        <div className="clearfix"></div>
                        <HomePageLaunchEdition source="web" collection_banner_web={this.state.collection_banner_web} show_collection_banner_web={this.state.show_collection_banner_web}/>
                        <HomePageWomenMenSection source="web" shop_men_banner_web={this.state.shop_men_banner_web} show_shop_men_banner_web={this.state.shop_men_banner_web} shop_men_banner_mob={this.state.shop_men_banner_mob}  show_shop_men_banner_mob={this.state.show_shop_men_banner_mob} shop_women_banner_web={this.state.shop_women_banner_web} show_shop_women_banner_web={this.state.show_shop_women_banner_web} shop_women_banner_mob={this.state.shop_women_banner_mob} show_shop_women_banner_mob={this.state.show_shop_women_banner_mob}/>
                        {this.state.show_collection_list ?
                            <div className="col-xs-12 homepage_collection_list_section padding_is_zero">
                                <HomePageCollectionList source="web" collection_list={this.state.collection_list}/>
                            </div>
                            :
                            null
                        }
                        {this.state.show_trending_product ?
                            <HomePageTrendingSection source="web" trending_product_details={this.state.trending_product_details} change_color={this.ChangeColor}/>
                            :
                            null
                        }
                        <HomePageSunglassesBanner />
                        <HomePageLaunchExtraSection />
                        <HomePageOjosFeatureSection />
                        {this.state.show_instagram_feed ?
                            <div className="col-xs-12 homepage_instagram_feed_section">
                                <InstagramFeed source="web" instagram_feed={this.state.instagram_feed}/>
                            </div>
                            :
                            null
                        }
                        <HomePageDownloadApp />
                        <HomePageDeliverySection /> */}
                    </div>
                </div>
        );
    }
}
export default HomePage;