import React, { Component } from "react";

class CheckoutFooterConfirm extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	cart_details: this.props.cart_details,
	    	address_id: this.props.address_id
	    }
	}
	componentDidMount()
	{
		
	}
	render() {
		
		return (
					<div className="col-sm-6 col-xs-12 padding_is_zero checkout_pay_footer">
			    		<div className="address_section">
			    			<div className="col-xs-12">
				    			<div className="col-xs-2 padding_is_zero"><img src="../images/Header/Purple_Address_Pin.svg" alt="address_icon" className="deliver_icon"/></div>
				    			<div className="col-xs-8 padding_is_zero">
				    				{this.state.cart_details.user_data.address.map((item, i) => (
				    					<div key={i}>
				    					{item.id == this.state.address_id ?
				    						<div>
					    						{item.name == "" ?
					    							<p className="delivery_address_text"><b>Deliver To: {this.state.cart_details.user_data.name}'s Address</b></p>
					    							:
					    							<p className="delivery_address_text"><b>Deliver To: {item.name}'s Address</b></p>
				    							}
				    							<p className="delivery_address_text">{item.phone} | {this.state.cart_details.user_data.email}</p>
				    							<p className="delivery_address_text">{item.shipping_address+", "+item.ship_city+", "+item.ship_state+", "+item.ship_code}</p>
				    						</div>
				    						:
				    						null
				    					}
				    					</div>
				    				))}
				    			</div>
				    			<div className="col-xs-2 checkout_edit_div"><img src="../images/Header/Pencil_grey.svg" alt="edit_icon" className="edit_address_icon" onClick={() => this.props.back_to_checkout()}/></div>
			    			</div>
			    		</div>
			    		<div className="total_bill_footer">
			    			<div className="col-xs-12 bill_footer">
				    			<div className="col-xs-5 padding_is_zero">
				    				<p className="delivery_total_bill">Total Bill</p>
				    				<p className="delivery_total_cost"><b>Rs. {this.state.cart_details.billing.payable_price}</b></p>
				    			</div>
				    			<div className="col-xs-7">
				    				<center><button className="checkout_confirm_button" onClick={() => this.props.checkout_pay("mob")}>Confirm</button></center>
				    			</div>
				    		</div>
			    		</div>
			    	</div>
		    	
	    );
		
		
	}
}

export default CheckoutFooterConfirm;