import React, { Component } from "react";

class CheckoutStatic extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	
	    }
	}
	componentDidMount()
	{
		
	}
	componentWillUnmount() {
    	
	}
	render() {
		
		return (
					
					<div>
						<div className="col-xs-12 bill_details_static padding_is_zero">
    						<div className="col-xs-12 bill_heading">
    							<p className="buying_details_text">Redefine your Eyewear Shopping at OJOS</p>
    						</div>
    						<div className="col-xs-12 item_total_section1 padding_is_zero">
    							<div className="col-xs-12 item_total_text_area checkout_points"><img src="../images/Header/Glasses.svg" alt="checkout_icon" className="checkout_bottom_icon"/><p className="checkout_point_text">New and Stylish frames for you, revamped every month!</p></div>
    						</div>
    						{/*<div className="col-xs-12 item_total_section1 padding_is_zero">
    							<div className="col-xs-12 item_total_text_area checkout_points"><img src="../images/Header/Piggy_Bank_Checkout.svg" alt="checkout_icon" className="checkout_bottom_icon"/><p className="checkout_point_text">Subscription offers to help you save more</p></div>
    						</div>*/}
    						<div className="col-xs-12 item_total_section1 padding_is_zero">
    							<div className="col-xs-12 item_total_text_area checkout_points"><img src="../images/Header/Delivery.svg" alt="checkout_icon" className="checkout_bottom_icon"/><p className="checkout_point_text">Order deliveries at its fastest!</p></div>
    						</div>
    						<div className="col-xs-12 item_total_section padding_is_zero">
    							<div className="col-xs-12 item_total_text_area checkout_points"><img src="../images/Header/Return_Checkout.svg" alt="checkout_icon" className="checkout_bottom_icon"/><p className="checkout_point_text">Easy Return/Refund options in case you are not satisfied</p></div>
    						</div>
    					</div>

						<div className="col-xs-12 checkout_trouble">
							<center>
								<p className="trouble_text">Facing an issue?</p>
								<p className="contact_text"><a href="/contact-us" className="contact_us_footer_text"><u>Contact Us</u></a></p>
							</center>
						</div>
					</div>
    				
		    	
	    );
		
		
	}
}

export default CheckoutStatic;