import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomePageWomenMenSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <div className="col-xs-12 homepage_men_women_section">
            {this.props.source == "mob" ?
                <div>
                    {this.props.shop_men_banner_mob ?
                        <div className="col-xs-12 padding_is_zero">
                            <center>
                              <a
                                href="/products/eyeglasses/male?path_source=male_listing"
                                className="homepage_banner_section_a"
                              >
                                <img
                                  src={this.props.shop_men_banner_mob}
                                  className="img-responsive homepage_shop_glasses_mob"
                                />
                              </a>
                            </center>
                        </div>
                        :
                        null
                    }
                    
                    {this.props.shop_women_banner_mob ?
                        <div className="col-xs-12 homepage_shop_women_banner_img padding_is_zero">
                            <center>
                              <a
                                href="/products/eyeglasses/female?path_source=female_listing"
                                className="homepage_banner_section_a"
                              >
                                <img
                                  src={this.props.shop_women_banner_mob}
                                  className="img-responsive homepage_shop_glasses_mob"
                                />
                              </a>
                            </center>
                        </div>
                        :
                        null
                    }
                </div>
                :
                <div>
                    <div className="col-sm-2"></div>
                    {this.props.shop_men_banner_web ?
                        <div className="col-sm-4">
                            <center>
                              <a
                                href="/products/eyeglasses/male?path_source=male_listing"
                                className="homepage_banner_section_a"
                              >
                                <img
                                  src={this.props.shop_men_banner_web}
                                  className="img-responsive homepage_shop_glasses_mob"
                                />
                              </a>
                            </center>
                        </div>
                        :
                        null
                    }
                    {this.props.shop_women_banner_web ?
                        <div className="col-sm-4">
                                <center>
                                  <a
                                    href="/products/eyeglasses/female?path_source=female_listing"
                                    className="homepage_banner_section_a"
                                  >
                                    <img
                                      src={this.props.shop_women_banner_web}
                                      className="img-responsive homepage_shop_glasses_mob"
                                    />
                                  </a>
                                </center>
                        </div>
                        :
                        null
                    }
                    <div className="col-sm-2"></div>
                    <div className="clearfix"></div>
                </div>
            }
        </div>
    );
  }
}
export default HomePageWomenMenSection;
