import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import ARAnimation from '../Lotties/ar_try_on.json';
import { RWebShare } from "react-web-share";

class ProductDetailsShareOptions extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	 
	    } 
	}
	componentDidMount()
	{
		
  	}
  	render() {
  		const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: ARAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        };
        const styles = {
            scrollArrowAnimation: {
                margin: '0px',
            },
        }
  		return (
  			<div>
	  			{this.props.source == 'mob' ?
	  				<div>
		  				{this.props.product_details.colors.map((item, index) => (
		    				<div className="product_details_new_urgency_div">
		    				{this.props.color_id === item.id && item.urjency_text ?
								<p class="ribbon">
		   							<strong class="ribbon-content">{item.urjency_text}</strong>
								</p>
			    				:
			    				null
			    			}
			    			</div>
			    		))}
		  				<RWebShare
		                    data={{
		                      text: this.props.product_details.brand_name,
		                      url: "https://ojoswear.com/product/"+ this.props.product_details.prod_url+"/color/"+this.props.product_details.specifications.color_prod_id,
		                      title: this.props.product_details.brand_name,
		                    }}
		                    onClick={() => console.log("shared successfully!")}>
		                	<img src="/images/Header/Share_Icon_ListingPage.svg" className="product_details_share_icon" alt="share_icon" />
		                </RWebShare>
			  			
			  			{this.props.product_details.colors.map((item, index) => (
			  				<div>
				  				{this.props.color_id === item.id && item.ar_status ?
									<Link to={"/product/"+this.props.product_details.prod_url+"/"+this.props.lenskart_prod_id+"/color/"+this.props.product_details.specifications.color_prod_id}>
										<div className="product_details_ar_div">
											<Lottie options={defaultOptions} width={50} style={styles.scrollArrowAnimation}/>
											<p className="product_details_try_on_text">Try It On</p>
										</div>
									</Link>
									:
									null
								}
							</div>
						))}	
						{this.props.product_details.type == "Eyeglasses" ?
							<div  className="product_details_power_div">
								{this.props.pres == 'Single Vision' ?
									<img src="/images/Header/PowersIconGreenDot.svg" className="product_details_rx_img" onClick={this.props.available_power}/>
									:
									<img src="/images/Header/PowersIcon.svg" className="product_details_rx_img" onClick={this.props.available_power}/>
								}
								<p className="product_details_check_power_text" onClick={this.props.available_power}>Check Available<br/> Powers</p>
							</div>
							:
							null
						}
					</div>
					:
					<div>
						<RWebShare
		                    data={{
		                      text: this.props.product_details.brand_name,
		                      url: "https://ojoswear.com/product/"+ this.props.product_details.prod_url+"/color/"+this.props.product_details.specifications.color_prod_id,
		                      title: this.props.product_details.brand_name,
		                    }}
		                    onClick={() => console.log("shared successfully!")}>
		                	<img src="/images/Header/Share_Icon_ListingPage.svg" className="product_details_share_icon_web" alt="share_icon" />
		                </RWebShare>
		                {this.props.product_details.type == "Eyeglasses" ?
		                	<div  className="product_details_power_div_web">
			                	{this.props.pres == 'Single Vision' ?
									<img src="/images/Header/PowersIconGreenDot.svg" className="product_details_rx_img_web" onClick={this.props.available_power}/>
									:
									<img src="/images/Header/PowersIcon.svg" className="product_details_rx_img_web" onClick={this.props.available_power}/>
								}
								<p className="product_details_check_power_text_web" onClick={this.props.available_power}>Check Available Powers</p>
							</div>
							:
							null
		                }
		                
					</div>
	  			}
	  		</div>
		);
  	}
}
export default ProductDetailsShareOptions;
