import React, { Component } from "react";
import { Link } from 'react-router-dom';

class CheckoutHeader extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	
	    }
	}
	componentDidMount()
	{
		
	}
	componentWillUnmount() {
    	
	}
	render() {
		console.log(this.props.stage);	
		console.log(this.props.device);	
		return (
			<div>	
				{this.props.stage==1 ?
						<div className="col-sm-12 col-xs-12 checkout_header">
						{this.props.device == "mob" ?
							<div className="col-xs-2 col-sm-4 checkout_header_chevron_div padding_is_zero"><Link to="/"><i className="fa fa-angle-left checkout_back_button close" aria-hidden="true"></i></Link></div>
							:
							<div className="col-xs-2 col-sm-4 cart_checkout_header_section checkout_header_chevron_div  padding_is_zero"><Link to="/"><img src="/images/Header/Logo_Checkout.svg" className="cart_checkout_logo_icon"/></Link></div>
		    			}
		    			
		    			<div className="col-xs-2 col-sm-1 padding_is_zero progress_icon"><img src="/images/Header/Checkout_Stage1_Active.svg" alt="checkout" className="checkout_header_icon"/></div>
			    		<div className="col-xs-2 col-sm-1 padding_is_zero checkout_progress_line"></div>
			    		<div className="col-xs-2 col-sm-1 padding_is_zero progress_icon"><img src="/images/Header/Checkout_Stage2_Inactive.svg" alt="checkout" className="checkout_header_icon"/></div>
						<div className="col-xs-2 col-sm-1 padding_is_zero checkout_progress_line"></div>
						<div className="col-xs-2 col-sm-4 padding_is_zero progress_icon"><img src="/images/Header/Checkout_Stage3_Inactive.svg" alt="checkout" className="checkout_header_icon"/></div>
	    				</div>
	    			:
	    			[(this.props.stage==2 ?
	    				<div className="col-sm-12 col-xs-12 checkout_header">
	    				{this.props.device == "mob" ?
							<div className="col-xs-2 col-sm-4 checkout_header_chevron_div padding_is_zero"><Link to="/"><i className="fa fa-angle-left checkout_back_button close" aria-hidden="true"></i></Link></div>
							:
							<div className="col-xs-2 col-sm-4 cart_checkout_header_section checkout_header_chevron_div  padding_is_zero"><Link to="/"><img src="/images/Header/Logo_Checkout.svg" className="cart_checkout_logo_icon"/></Link></div>
		    			}
		    			<div className="col-xs-2 col-sm-1 padding_is_zero progress_icon"><img src="/images/Header/Checkout_Stage1_Complete.svg" alt="checkout" className="checkout_header_icon"/></div>
			    		<div className="col-xs-2 col-sm-1 padding_is_zero checkout_progress_line"></div>
			    		<div className="col-xs-2 col-sm-1 padding_is_zero progress_icon"><img src="/images/Header/Checkout_Stage2_Complete.svg" alt="checkout" className="checkout_header_icon"/></div>
						<div className="col-xs-2 col-sm-1 padding_is_zero checkout_progress_line"></div>
						<div className="col-xs-2 col-sm-4 padding_is_zero progress_icon"><img src="/images/Header/Checkout_Stage3_Complete.svg" alt="checkout" className="checkout_header_icon"/></div>
	    				</div>
	    				:
	    				<div className="col-sm-12 col-xs-12 checkout_header">
	    				{this.props.device == "mob" ?
							<div className="col-xs-2 col-sm-4 checkout_header_chevron_div padding_is_zero"><i className="fa fa-angle-left checkout_back_button close" aria-hidden="true" onClick={this.props.checkout_back}></i></div>
							:
							<div className="col-xs-2 col-sm-4 cart_checkout_header_section checkout_header_chevron_div  padding_is_zero"><img src="/images/Header/Logo_Checkout.svg" className="cart_checkout_logo_icon"/></div>
		    			}
		    			<div className="col-xs-2 col-sm-1 padding_is_zero progress_icon"><img src="/images/Header/Checkout_Stage1_Complete.svg" alt="checkout" className="checkout_header_icon"/></div>
			    		<div className="col-xs-2 col-sm-1 padding_is_zero checkout_progress_line"></div>
			    		<div className="col-xs-2 col-sm-1 padding_is_zero progress_icon"><img src="/images/Header/Checkout_Stage2_Complete.svg" alt="checkout" className="checkout_header_icon"/></div>
						<div className="col-xs-2 col-sm-1 padding_is_zero checkout_progress_line"></div>
						<div className="col-xs-2 col-sm-4 padding_is_zero progress_icon"><img src="/images/Header/Checkout_Stage3_Inactive.svg" alt="checkout" className="checkout_header_icon"/></div>
	    				</div>
	    			)]
	    		}
			</div>
		);
		
		
	}
}

export default CheckoutHeader;
/*
<ul id="progressbar">
			    				<center>
				                <li><img src="../images/Header/Review_Incomplete.svg" alt="checkout" className="checkout_header_icon"/></li>
				                <li><img src="../images/Header/Payment_Incomplete.svg" alt="checkout" className="checkout_header_icon"/></li>
				                <li><img src="../images/Header/OrderPurchased_Incomplete.svg" alt="checkout" className="checkout_header_icon"/></li>
				                </center>
				            </ul>*/