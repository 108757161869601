import React, { Component } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { Link } from "react-router-dom";
import { create } from "@lottiefiles/lottie-interactivity";
import { Waypoint } from "react-waypoint";
import Lottie from "react-lottie";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Footer from "../Common/Footer";
// import ScrollAnimation from 'react-animate-on-scroll';
import scrollDownAnimation from "../Lotties/scroll_down.json";
import InstagramFeed from "./InstagramFeed";

class PageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      set_animation: true,
      isStopped: true,
      currentScrollHeight: 0,
      width: window.innerWidth,
      homepage_page1: "homepage_page_default_1",
      homepage_page2: "homepage_page_default_2",
      homepage_page3: "homepage_page_default_3",
      homepage_page4: "homepage_page_default_4",
      homepage_page5: "homepage_page_default_5",
      homepage_page6: "homepage_page_default_6",
      secondLottie: "secondLottie",
      firstLottie: "firstLottie",
      explore_button: "ultra_light_button_mob_none",
    };
    this.myRef = React.createRef();
    this.myRef2 = React.createRef();
    this.myRefScrollMob1 = React.createRef();
    this.handleScrollMob1 = this.handleScrollMob1.bind(this);
  }
  componentDidMount() {
    this.myRef2.current.addEventListener("load", function (e) {
      create({
        mode: "scroll",
        player: "#secondLottie",
        container: "#container_mob",
        actions: [
          {
            visibility: [0, 0.1],
            type: "stop",
            frames: [0],
          },
          {
            visibility: [0.5, 5],
            type: "seek",
            frames: [0, 1896],
          },
        ],
      });
    });

    this.myRef.current.addEventListener("load", function (e) {
      create({
        mode: "scroll",
        player: "#firstLottie",
        container: "#container",
        actions: [
          {
            visibility: [0, 0.1],
            type: "stop",
            frames: [0],
          },
          {
            visibility: [0.7, 5],
            type: "seek",
            frames: [0, 1896],
          },
        ],
      });
    });

    window.addEventListener("scroll", this.handleScrollMob1, { passive: true });
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollMob1);
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  handleScrollMob1(e) {
    const scrollY = window.scrollY;
    //const scrollTop = this.myRefScroll.current.scrollTop
    //console.log(scrollY);
    const { width } = this.state;
    const isMobile = width <= 768;
    if (isMobile) {
      if (scrollY > 1000 && scrollY < 6500) {
        this.setState({ explore_button: "ultra_light_button_mob" });
      } else {
        this.setState({ explore_button: "ultra_light_button_mob_none" });
      }
      if (scrollY > 200) {
        this.setState({ secondLottie: "secondLottie_show" });
      } else {
        this.setState({ secondLottie: "secondLottie" });
      }
    } else {
      if (scrollY > 200) {
        this.setState({ firstLottie: "firstLottie_show" });
      } else {
        this.setState({ firstLottie: "firstLottie" });
      }
    }
  }
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: scrollDownAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const styles = {
      scrollArrowAnimation: {
        margin: "0px",
      },
    };
    return (
      <div className="homepage_body">
        <div className="">
          <div
            id="container_mob"
            className="container_web hidden-sm hidden-lg hidden-md"
          >
            <div id="content_mob" className="content_mob">
              <div
                className="homepage_section1"
                ref={this.myRefScrollMob1}
                onScroll={this.handleScrollMob1}
              >
                <img
                  src="/images/Header/NewHomeLandingMobileSplit.jpg"
                  className="img-responsive"
                />
                {/*<div className={"col-xs-12 landing_page_main_text_mob hidden-sm hidden-md hidden-lg "}>
                                    <center>
                                        <h1 className="landing_bold_text">21 New Trending Eyewear<br/> Designs Every Month</h1>
                                    </center>
                                </div>
                                <div className="col-xs-12">
                                    <center>
                                        <img src="/images/Header/HeroLadki2.png" className="img-responsive homepage_ladhki"/>
                                    </center>
                                </div>*/}
                <div
                  className={
                    "col-xs-12 hidden-sm hidden-md hidden-lg landing_right_text_mob "
                  }
                >
                  <h1 className="landing_bold_text">
                    Go Eyeliciously Colorful <br /> this month!
                  </h1>
                  <p className="landing_right_subtext_mob">
                    The new collection is here. Get your favourite now before it
                    gets sold out!
                  </p>
                  <div className="landing_button_section">
                    <div className="col-xs-6 padding_left_0">
                      <Link to="/products/male?path_source=male_listing">
                        <button className="landing_button">Shop Men</button>
                      </Link>
                    </div>
                    <div className="col-xs-6 padding_left_0">
                      <Link to="/products/female?path_source=female_listing">
                        <button className="landing_button">Shop Women</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clearfix"></div>
              <div className="col-xs-12" className={this.state.secondLottie}>
                <lottie-player
                  ref={this.myRef2}
                  id="secondLottie"
                  src="/images/Header/Animation_Mobile.json"
                ></lottie-player>
              </div>
              <div
                className={
                  "col-xs-12 hidden-sm hidden-md hidden-lg ojos_unique_mob padding_is_zero"
                }
              >
                <a href="/explore-ojos">
                  <button
                    className={this.state.explore_button}
                    id="explore_button"
                  >
                    Explore OJOS
                  </button>
                </a>
              </div>

              <div className="clearfix"></div>
              <div
                className="col-xs-12 homepage_banner_space_mob hidden-sm hidden-md hidden-lg"
                onScroll={this.handleScrollMob7}
              >
                {/*<div className="col-xs-12 padding_is_zero">
                                    <center><h1 className="homepage_banner_space_text_mob">Browse through our range of exclusive eyeglasses</h1></center>
                                </div>*/}
                <div className="col-sm-12 padding_is_zero">
                  <h1 className="homepage_banner_new_text_header extra_bold_text">
                    eyeglasses
                  </h1>
                  <h1 className="homepage_banner_new_subtext_header">
                    FUN • BOLD • SLEEK
                  </h1>
                </div>
                <div className="col-xs-12 padding_is_zero">
                  <center>
                    <a
                      href="/products/male?path_source=male_listing"
                      className="homepage_banner_section_a"
                    >
                      <img
                        src="/images/Header/NewShopMenBannerMobileNew.png"
                        className="img-responsive homepage_shop_glasses_mob"
                      />
                      <p className="homepage_banner_text_img">
                        Shop Men <i class="fa fa-chevron-right"></i>
                      </p>
                    </a>
                  </center>
                </div>
                <div className="col-xs-12 padding_is_zero">
                  <center>
                    <a
                      href="/products/female?path_source=female_listing"
                      className="homepage_banner_section_a"
                    >
                      <img
                        src="/images/Header/ShopWomenBannerMobileNew.png"
                        className="img-responsive homepage_shop_glasses_mob"
                      />
                      <p className="homepage_banner_text_img">
                        Shop Women <i class="fa fa-chevron-right"></i>
                      </p>
                    </a>
                  </center>
                </div>
                {/* Valentine Banner*/}
                {/*<div className="col-xs-12 padding_is_zero">
                                    <center><a href="/products/valentine?param=valentine"><img src="/images/Header/Mobile_ValentinesHomePageBanner.png" className="homepage_buy3_banner_mob"/></a></center>
                                </div>*/}
                {/* Valentine Banner Ends*/}
                <div className="col-xs-12 homepage_banner_section padding_is_zero">
                  <center>
                    <img
                      src="/images/Header/MobileNewCollectionRegistrationBanner.png"
                      className="homepage_shop_glasses_launch_banner_mob img-responsive"
                    />
                  </center>
                </div>
                <div className="col-xs-12 homepage_banner_section homepage_banner_launch_text_div padding_is_zero">
                  <div className="homepage_banner_collection_launch_text_mob">
                    <p className="homepage_banner_collection_launch_text_header">
                      OJOS New Collection Launching Soon!!!
                    </p>
                    <p className="homepage_banner_collection_launch_subtext_header">
                      Register Now and Win a Free Eyewear!
                    </p>
                    <p className="homepage_banner_collection_launch_subtext_header">
                      Get early access to the collection and grab your favourite
                      pair before it gets sold-out. <br />
                      <small>
                        *Winner selected at random. Will be notified through
                        email{" "}
                      </small>
                    </p>
                    <a href="/sale-registration">
                      <button className="homepage_banner_collection_launch_register_button">
                        Register Now and Win
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>

              <div className="col-xs-12 homepage_3banner_space_mob ">
                <img
                  src="/images/Header/Mobile_Buy3.jpg"
                  className="homepage_buy3_banner_mob"
                />
                <p className="homepage_3banner_text_shop_mob1">Shop more</p>
                <p className="homepage_3banner_text_shop_mob2">Save more !!!</p>
                <p className="homepage_3banner_text_buy_mob1">
                  Get 3 eyeglasses
                </p>
                <p className="homepage_3banner_text_buy_mob2">
                  for just Rs. 2100
                </p>
                <p className="homepage_3banner_text_button_mob">
                  <a href="/subscription-plan">
                    <button className="homepage_learn_more_button_mob">
                      Learn More
                    </button>
                  </a>
                  <a href="/explore-ojos">
                    <button className="homepage_buy_now_button_mob">
                      Buy Now
                    </button>
                  </a>
                </p>
              </div>
              <div className="col-xs-12 homepage_banner_instagram_feed">
                <InstagramFeed source="mob" />
              </div>
              <div className="col-xs-12 homepage_download_app">
                <div className="col-sm-12 padding_is_zero">
                  <h1 className="homepage_download_app_header extra_bold_text">
                    download our
                  </h1>
                  <h1 className="homepage_download_app_subtext">MOBILE APP</h1>
                  <ul className="homepage_download_app_ul">
                    <li>Stay updated with trends and offers</li>
                    <li>Friendly UI</li>
                    <li>Faster and better in all aspects</li>
                    <li>
                      Access to exclusive app-only offers on your favourite
                      eyewear
                    </li>
                    <li>Early access to new collection every month</li>
                    <li>Enhanced OJ Experience</li>
                    <li>Fastest customer care experience</li>
                  </ul>
                  <center>
                    <a href="https://bit.ly/3Ivkivt" target="_blank">
                      <img
                        src="/images/Header/AppMockups.png"
                        className="img-responsive"
                      />
                    </a>
                    <a href="https://bit.ly/3Ivkivt" target="_blank">
                      <img
                        src="/images/Header/get-it-on-google-play-vector.png"
                        className="img-responsive homepage_playstore_image"
                      />
                    </a>
                  </center>
                </div>
              </div>
              <div className="col-xs-12 homepage_uncertain_div_mob hidden-sm hidden-md hidden-lg ">
                <center>
                  <p className="homepage_uncertain_text_mob extra_bold_text">
                    can’t wait for your new look?
                  </p>
                  <p className="homepage_uncertain_subtext_mob">
                    get your superpower delivered to your doorstep at a
                    lightning speed
                  </p>
                </center>
                <div className="col-xs-12 padding_is_zero">
                  <div className="col-xs-3 padding_is_zero">
                    <center>
                      <img
                        src="/images/Header/14DaysReturn.svg"
                        className="homepage_delivery_icons_mob"
                      />
                      <p className="homepage_delivery_icons_mob_text">
                        14 days easy return
                      </p>
                    </center>
                  </div>
                  <div className="col-xs-3 padding_is_zero">
                    <center>
                      <img
                        src="/images/Header/FreeDelivery.svg"
                        className="homepage_delivery_icons_mob"
                      />
                      <p className="homepage_delivery_icons_mob_text">
                        Free delivery
                      </p>
                    </center>
                  </div>
                  <div className="col-xs-3 padding_is_zero">
                    <center>
                      <img
                        src="/images/Header/fastShipping.svg"
                        className="homepage_delivery_icons_mob"
                      />
                      <p className="homepage_delivery_icons_mob_text">
                        Fast Shipping
                      </p>
                    </center>
                  </div>
                  <div className="col-xs-3 padding_is_zero">
                    <center>
                      <img
                        src="/images/Header/warranty.svg"
                        className="homepage_delivery_icons_mob"
                      />
                      <p className="homepage_delivery_icons_mob_text">
                        1 Year warranty
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>

          {/* Desktop */}
          <div id="container" class="container hidden-xs padding_is_zero">
            <div id="content" class="content_web hidden-xs padding_is_zero">
              <div
                className={
                  "col-sm-12 padding_is_zero hidden-xs " +
                  this.state.homepage_page1
                }
                onScroll={this.handleScrollMob1}
              >
                <div className="col-sm-6 padding_is_zero">
                  <img
                    src="/images/Header/NewHomeLandingDesktopNew.jpg"
                    className="img-responsive"
                  />
                </div>
                <div className="col-sm-6 homepage_page_section_right">
                  <h1 className="landing_bold_text">
                    Go Eyeliciously Colorful <br /> this month!
                  </h1>
                  <p className="landing_right_subtext_mob landing_right_subtext_mob_display">
                    The new collection is here. Get your favourite now before it
                    gets sold out!
                  </p>
                  <div className="col-sm-12 landing_button_section_web padding_is_zero">
                    <div className="col-sm-4 padding_left_0">
                      <Link to="/products/male?path_source=male_listing">
                        <button className="landing_button">Shop Men</button>
                      </Link>
                    </div>
                    <div className="col-sm-4 padding_left_0">
                      <Link to="/products/female?path_source=female_listing">
                        <button className="landing_button">Shop Women</button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-12 landing_scroll_section padding_is_zero">
                    <Lottie
                      options={defaultOptions}
                      width={50}
                      style={styles.scrollArrowAnimation}
                    />
                    <p className="landing_scroll_text">
                      Scroll for an amazing experience
                    </p>
                  </div>
                </div>
                {/*<div className="col-sm-1"></div>
                                <div className="col-sm-5">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-10">
                                        <center>
                                            <img src="/images/Header/HeroLadki2.png" className="img-responsive homepage_ladhki_web"/>
                                        </center>
                                    </div>
                                    <div className="col-sm-1"></div>
                                </div>
                                <div className="col-sm-5">
                                    <center>
                                        <h1 className="landing_bold_text">21 New Trending Eyewear<br/> Designs Every Month</h1>
                                        <p className="landing_right_subtext_mob landing_right_subtext_mob_display">Subscribe for 1 year & Get</p> <p className="landing_right_sub_subtext_mob landing_right_subtext_mob landing_right_subtext_mob_display"> 3 Eyeglasses </p><br/><p className="landing_right_sub_subtext_mob landing_right_subtext_mob landing_right_subtext_mob_display">with Premium Lenses </p> <p className="landing_right_subtext_mob landing_right_subtext_mob_display"> at just </p><p className="landing_right_sub_subtext_mob landing_right_subtext_mob landing_right_subtext_mob_display landing_right_sub_subtext_mob1"> Rs. 700</p><p className="landing_right_subtext_mob landing_right_subtext_mob_display"> each</p>
                                        <p className="landing_right_subtext_web"><a href="/subscription-plan" className="homepage_subscription_plan_link">Know More</a></p>
                                        <div className="col-sm-12 landing_button_section">
                                            <div className="col-sm-6">
                                                <Link to="/products/male?path_source=male_listing"><button className="landing_button">Shop Men</button></Link> 
                                            </div>
                                            <div className="col-sm-6">
                                                <Link to="/products/female?path_source=female_listing"><button className="landing_button">Shop Women</button></Link>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                                <div className="col-sm-1"></div>*/}
              </div>
              <div className="clearfix"></div>
              <div
                className={
                  "col-sm-12 hidden-xs animation_area " + this.state.firstLottie
                }
              >
                <lottie-player
                  ref={this.myRef}
                  id="firstLottie"
                  src="/images/Header/Animation_Desktop.json"
                ></lottie-player>
              </div>
              {/*<div className={"col-sm-12 hidden-xs ojos_unique landing_right_text " +this.state.homepage_page2} onScroll={this.handleScrollMob2}>
                                <div className="col-sm-6">
                                </div>
                                <div className="col-sm-6">
                                   <center>
                                        <h1 className="ojos_unique_text landing_bold_text">What Makes OJOS21 unique?</h1>
                                        <h2 className="ojos_unique_text">We redefine your eyewear shopping experience!<br/> Just pick, click and bling it on!</h2>
                                    </center>
                                </div>
                            </div>
                            <div className={"col-sm-12 hidden-xs ultra_light_section landing_right_text "+this.state.homepage_page3}  onScroll={this.handleScrollMob3}>
                                <div className="col-sm-4">
                                </div>
                                <div className="col-sm-8">
                                    <center>
                                        <h1 className="landing_bold_text homepage_ultra_text">Beat the savings streak!</h1>
                                        <h4 className="homepage_ultra_text">Experience a new world of simplified eyewear shopping and all that your eyes will love, at a "one price fits all" deal! What's even better? A subscription plan of 3 pairs of designer eyeglasses with premium lenses for just Rs.<s>4500</s> 2100! The curated range of Eyeglasses at OJOS21, is all about the quality and getting the best deal for you! Another reason to get that cart page..loaded! </h4>
                                        <h4 className="homepage_ultra_text"><a href="/subscription-plan" className="homepage_subscription_plan_link">Know more about our subscription plan</a></h4>
                                        <a href="/explore-ojos"><button className="ultra_light_button">Explore OJOS21</button></a>
                                    </center>
                                </div>
                            </div>
                            <div className={"col-sm-12 hidden-xs anti_glare_section landing_right_text " +this.state.homepage_page4} onScroll={this.handleScrollMob4}>
                                <div className="col-sm-5">
                                    <center>
                                        <h1 className="landing_bold_text homepage_anti_text">Anti-Glare Premium Glasses</h1>
                                        <h4 className="homepage_anti_text">Get the clearest vision possible with anti- reflective coated premium lenses. Our No-Glare lenses are fitted to give you the best in clarity and precision, perfect for the nigh-time as well! </h4>
                                        <a href="/explore-ojos"><button className="anti_glare_button">Explore OJOS21</button></a>
                                    </center>
                                </div>
                                <div className="col-sm-7">
                                </div>
                            </div>
                            <div className={"col-sm-12 hidden-xs air_flex_section landing_right_text " +this.state.homepage_page5} onScroll={this.handleScrollMob5}>
                                <div className="col-sm-5">
                                </div>
                                <div className="col-sm-7">
                                    <center>
                                        <h1 className="landing_bold_text homepage_ultra_text">Flexi is the new in!</h1>
                                        <h4 className="homepage_ultra_text">Bend your trend and choose from a trendy range of eye frames. Flex it on with a pair of ultra-light, or dash of sporty colours and look your best with our expert selected, crafted and curated designs! Got the style? Now get the super chic feel, as well!</h4>
                                        <a href="/explore-ojos"><button className="ultra_light_button">Explore OJOS21</button></a>
                                    </center>
                                </div>
                            </div>
                            <div className={"col-sm-12 hidden-xs hand_picked_section landing_right_text "  +this.state.homepage_page6} onScroll={this.handleScrollMob6}>
                                <div className="col-sm-4">
                                </div>
                                <div className="col-sm-4">
                                    <center>
                                        <h1 className="landing_bold_text">A new you every month!</h1>
                                        <h4 className="hand_picked_sub_text">Stay in vogue with our irresistible eyewear range, revamped for you every month! Keep an eye out for our new premium collections and gear up for that quick pick and flick!</h4>
                                        <a href="/explore-ojos"><button className="hand_picked_button">Buy eyeglasses starting at Rs. 700</button></a>
                                    </center>
                                </div>
                                <div className="col-sm-4">
                                </div>
                            </div>*/}
              <div className="clearfix"></div>
              <div className="col-sm-12 homepage_banner_space_new hidden-xs">
                {/*<div className="col-sm-12">
                                    <h1 className="homepage_banner_new_text_header extra_bold_text">eyeglasses</h1>
                                    <h1 className="homepage_banner_new_subtext_header">FUN • BOLD • SLEEK</h1>
                                </div>
                                <div className="col-sm-1"></div>
                                <div className="col-sm-5 homepage_banner_section">
                                    <center><a href="/products/male?path_source=male_listing" className="homepage_banner_section_a"><img src="/images/Header/NewShopMenBannerDesktopNew.png" className="homepage_shop_glasses img-responsive"/><p className="homepage_banner_text_img">Shop Men <i class="homepage_banner_chevron fa fa-chevron-right"></i></p></a></center>
                                </div>
                                <div className="col-sm-5 homepage_banner_section">
                                    <center><a href="/products/female?path_source=female_listing" className="homepage_banner_section_a"><img src="/images/Header/ShopWomenBannerDesktopNew.png" className="homepage_shop_glasses img-responsive"/><p className="homepage_banner_text_img">Shop Women <i class="homepage_banner_chevron fa fa-chevron-right"></i></p></a></center>
                                </div>
                                <div className="col-sm-1"></div>
                                <div className="clearfix"></div>*/}
                {/* Valentine Banner*/}
                {/*<div className="col-sm-1"></div>
                                <div className="col-sm-10">
                                    <center><a href="/products/valentine?param=valentine"><img src="/images/Header/Desktop_ValentinesHomePageBanner.png" className="homepage_buy3_banner"/></a></center>
                                </div>
                                <div className="col-sm-1"></div>*/}
                {/* Valentine Banner Ends*/}
              </div>
              <div className="col-sm-12 homepage_banner_space_new hidden-xs">
                <div className="col-sm-12">
                  <h1 className="homepage_banner_new_text_header extra_bold_text">
                    eyeglasses
                  </h1>
                  <h1 className="homepage_banner_new_subtext_header">
                    FUN. BOLD. SLEEK
                  </h1>
                </div>
                <div className="col-sm-6">
                  <center>
                    <a
                      href="/products/male?path_source=male_listing"
                      className="homepage_banner_section_a"
                    >
                      <img
                        src="/images/Header/Desktop_ShopMen.jpg"
                        className="homepage_shop_glasses img-responsive"
                      />
                      <p className="homepage_banner_new_text_img">
                        Shop Men{" "}
                        <i class="homepage_banner_chevron fa fa-chevron-right"></i>
                      </p>
                    </a>
                  </center>
                  <br />
                  <center>
                    <a
                      href="/products/female?path_source=female_listing"
                      className="homepage_banner_section_a"
                    >
                      <img
                        src="/images/Header/Desktop_ShopWomen.jpg"
                        className="homepage_shop_glasses img-responsive"
                      />
                      <p className="homepage_banner_new_text_img">
                        Shop Women{" "}
                        <i class="homepage_banner_chevron fa fa-chevron-right"></i>
                      </p>
                    </a>
                  </center>
                </div>
                <div className="col-sm-6 homepage_banner_section">
                  <center>
                    <img
                      src="/images/Header/DesktopNewCollectionRegistrationBanner.png"
                      className="homepage_shop_glasses_launch_banner img-responsive"
                    />
                  </center>
                </div>
                <div className="col-sm-6 homepage_banner_section homepage_banner_launch_text_div">
                  <div className="homepage_banner_collection_launch_text">
                    <p className="homepage_banner_collection_launch_text_header">
                      OJOS New Collection Launching Soon!!!
                    </p>
                    <p className="homepage_banner_collection_launch_subtext_header">
                      Register Now and Win a Free Eyewear!
                    </p>
                    <p className="homepage_banner_collection_launch_subtext_header">
                      Get early access to the collection and grab your favourite
                      pair before it gets sold-out
                      <br />
                      <small>
                        *Winner selected at random. Will be notified through
                        email{" "}
                      </small>
                    </p>
                    <a href="/sale-registration">
                      <button className="homepage_banner_collection_launch_register_button">
                        Register Now and Win
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 homepage_3banner_space hidden-xs">
                <div className="col-sm-1"></div>
                <div className="col-sm-10">
                  <center>
                    <img
                      src="/images/Header/Buy3Banner.jpg"
                      className="homepage_buy3_banner"
                    />
                  </center>
                  <p className="homepage_3banner_text_shop1">Shop more</p>
                  <p className="homepage_3banner_text_shop2">Save more !!!</p>
                  <p className="homepage_3banner_text_buy1">Get 3 eyeglasses</p>
                  <p className="homepage_3banner_text_buy2">
                    for just Rs. 2100
                  </p>
                  <p className="homepage_3banner_text_button">
                    <a href="/subscription-plan">
                      <button className="homepage_learn_more_button">
                        Learn More
                      </button>
                    </a>
                    <a href="/explore-ojos">
                      <button className="homepage_buy_now_button">
                        Buy Now
                      </button>
                    </a>
                  </p>
                </div>
                <div className="col-sm-1"></div>
              </div>
              <div className="col-xs-12 homepage_instagram_feed_web">
                <InstagramFeed source="web" />
              </div>
              <div className="col-sm-12 homepage_download_app_web hidden-xs">
                <div className="col-sm-6">
                  <h1 className="homepage_download_app_header extra_bold_text">
                    download our
                  </h1>
                  <h1 className="homepage_download_app_subtext">MOBILE APP</h1>
                  <ul className="homepage_download_app_ul_web">
                    <li>Stay updated with trends and offers</li>
                    <li>Friendly UI</li>
                    <li>Faster and better in all aspects</li>
                    <li>
                      Access to exclusive app-only offers on your favourite
                      eyewear
                    </li>
                    <li>Early access to new collection every month</li>
                    <li>Enhanced OJ Experience</li>
                    <li>Fastest customer care experience</li>
                  </ul>
                  <div className="col-sm-6">
                    <a href="https://bit.ly/3Ivkivt" target="_blank">
                      <img
                        src="/images/Header/get-it-on-google-play-vector.png"
                        className="img-responsive homepage_playstore_image_web"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-sm-6">
                  <center>
                    <a href="https://bit.ly/3Ivkivt" target="_blank">
                      <img
                        src="/images/Header/AppMockups.png"
                        className="img-responsive homepage_app_img_web"
                      />
                    </a>
                  </center>
                </div>
              </div>
              <div className="col-sm-12 homepage_uncertain_div hidden-xs">
                <div className="col-sm-1"></div>
                <div className="col-sm-10">
                  <center>
                    <p className="homepage_uncertain_text extra_bold_text">
                      can’t wait for your new look?
                    </p>
                    <p className="homepage_uncertain_subtext">
                      get your superpower delivered to your doorstep at a
                      lightning speed
                    </p>
                  </center>
                  <div className="col-sm-12">
                    <div className="col-sm-3">
                      <center>
                        <img
                          src="/images/Header/14DaysReturn.svg"
                          className="homepage_delivery_icons"
                        />
                        <p className="homepage_delivery_subtext">
                          14 days easy return
                        </p>
                      </center>
                    </div>
                    <div className="col-sm-3">
                      <center>
                        <img
                          src="/images/Header/FreeDelivery.svg"
                          className="homepage_delivery_icons"
                        />
                        <p className="homepage_delivery_subtext">
                          Free delivery
                        </p>
                      </center>
                    </div>
                    <div className="col-sm-3">
                      <center>
                        <img
                          src="/images/Header/fastShipping.svg"
                          className="homepage_delivery_icons"
                        />
                        <p className="homepage_delivery_subtext">
                          Fast Shipping
                        </p>
                      </center>
                    </div>
                    <div className="col-sm-3">
                      <center>
                        <img
                          src="/images/Header/warranty.svg"
                          className="homepage_delivery_icons"
                        />
                        <p className="homepage_delivery_subtext">
                          1 Year warranty
                        </p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>

        <div class="footer_homepage">
          <div className="col-sm-12 col-xs-12 footer_link_div">
            <div className="col-sm-12 col-xs-12 footer_each_col">
              <p className="footer_header_text">Contact Us</p>
            </div>
            <div className="col-sm-1 col-xs-4 footer_each_col">
              <a href="mailto:contact@ojoswear.com" target="_blank">
                <center className="last_slide_contact_footer">
                  <img src="/images/Header/Icon material-mail.svg" />
                  <p className="last_slide_contact_footer_subtext">Mail Us</p>
                </center>
              </a>
            </div>
            <div className="col-sm-1 col-xs-4 footer_each_col">
              <a href="https://wa.me/+917669800711" target="_blank">
                <center className="last_slide_contact_footer">
                  <img src="/images/Header/Icon ionic-logo-whatsapp.svg" />
                  <p className="last_slide_contact_footer_subtext">
                    WhatsApp Us
                  </p>
                </center>
              </a>
            </div>
            <div className="clearfix"></div>
            {/*<div className="col-sm-3 col-xs-6 footer_each_col footer_contact_col">
                            <img src="/images/Header/Call_Filled.svg" className="footer_icon"/><p className="footer_subtext">+91-9876543210</p>
                        </div>*/}
          </div>
          <div className="col-sm-12 col-xs-12 footer_link_div">
            <div className="col-sm-4 col-xs-6 footer_each_col">
              <p className="footer_header_text">Download Our App</p>
            </div>
            <div className="col-sm-4 col-xs-6 footer_each_col align_right">
              <p className="footer_header_text">Let’s Connect On</p>
            </div>
            <div className="col-sm-4"></div>
            <div className="clearfix"></div>
            <div className="col-sm-4 col-xs-6 footer_each_col padding_left_0">
              <div className="col-sm-1 col-xs-2 footer_each_col footer_contact_col">
                <a href="https://bit.ly/3Ivkivt" target="_blank">
                  <img
                    src="/images/Header/icons8-google-play.svg"
                    className="footer_icon"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-4 col-xs-6 footer_each_col align_right">
              <a href="https://www.facebook.com/ojoswear" target="_blank">
                <img
                  src="/images/Header/FacebookIconFooter.svg"
                  className="footer_icon"
                />
              </a>
              <a href="https://www.instagram.com/ojoswear/" target="_blank">
                <img
                  src="/images/Header/InstagramIconFooter.svg"
                  className="footer_icon"
                />
              </a>
              <a href="https://twitter.com/OjosWear" target="_blank">
                <img
                  src="/images/Header/TwitterIconFooter.svg"
                  className="footer_icon"
                />
              </a>
            </div>
            <div className="col-sm-4"></div>
          </div>
          <div className="col-sm-12 col-xs-12 footer_link_div">
            <div className="col-sm-4 col-xs-6 footer_each_col">
              <p className="footer_header_text">Explore OJOS Wear</p>
              <p>
                <a
                  href="/products/male?path_source=male_listing"
                  target="_blank"
                  className="footer_subtext"
                >
                  Men's Eyeglasses
                </a>
              </p>
              <p>
                <a
                  href="/products/female?path_source=female_listing"
                  target="_blank"
                  className="footer_subtext"
                >
                  Women's Eyeglasses
                </a>
              </p>
              <p>
                <a
                  href="/subscription-plan"
                  target="_blank"
                  className="footer_subtext"
                >
                  OJ Super
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-xs-6 footer_each_col">
              <p className="footer_header_text">Our Services</p>
              <p>
                <a href="/account" target="_blank" className="footer_subtext">
                  My Account
                </a>
              </p>
              <p>
                <a href="/order" target="_blank" className="footer_subtext">
                  Orders & Shipping
                </a>
              </p>
              <p>
                <a
                  href="/contact-us"
                  target="_blank"
                  className="footer_subtext"
                >
                  Help
                </a>
              </p>
              <p>
                <a href="/faq" target="_blank" className="footer_subtext">
                  FAQs
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-xs-12 footer_each_col">
              <p className="footer_header_text">About OJOS Wear</p>
              <p>
                <a
                  href="https://forms.gle/XYbGDvGM2YBqyVFu6"
                  target="_blank"
                  className="footer_subtext"
                >
                  We are hiring!{" "}
                  <small className="apply_now_text"> Apply Now</small>
                </a>
              </p>
              <p>
                <a href="/about-us" target="_blank" className="footer_subtext">
                  Get to know us
                </a>
              </p>
              <p>
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  className="footer_subtext"
                >
                  Terms & Conditions
                </a>
              </p>
              <p>
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className="footer_subtext"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-xs-12 footer_link_div">
            <center>
              <img src="/images/Header/OJ_footer.svg" />
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default PageContent;
