import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProductTextAreaDesktop extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            show_desc: false,
            color_border: 'colour_border',
        };
        this.ShowDescription = this.ShowDescription.bind(this);
        this.HideDescription = this.HideDescription.bind(this);
        this.ChangeColor = this.ChangeColor.bind(this);
    }
    ShowDescription()
    {
        this.setState({show_desc: false});
    }
    HideDescription()
    {
        this.setState({show_desc: false});
    }
    ChangeColor(item_url,color_id,i)
    {
        this.props.change_color(item_url,color_id,i);
    }
    render() { 
            return(
                    
                    <div className="product_textarea_web" onMouseEnter={this.ShowDescription}>
                        {this.props.item.brand_name.length > 13 ?
                            <div className="col-xs-4 product_brand_text">
                                {this.props.item.brand_name.substr(0, 13)+"..."}
                            </div>
                            :
                            <div className="col-xs-4 product_brand_text">
                                {this.props.item.brand_name}
                            </div>
                        }
                       
                        <div className="col-xs-8 colour_section">
                            {this.props.colors.map((color, index) => (
                                <div>
                                    {this.props.item.specifications.color_prod_id === color.id?
                                    <div>
                                        {color.sold_out == 1 ?
                                            <div className="transparent_colour_border"><img src="/images/Header/red_striked_circle.svg" className="color_striked_img" onClick={() => this.ChangeColor(this.props.item.prod_url,color.id, this.props.i)}/><span className="colour_dot_product_details" id={color.basic_prod_id+"_"+color.id} key={index} style={{backgroundColor: color.hex_code}}></span></div>
                                            :
                                            <div className={this.state.color_border}><span className="colour_dot_product_details" id={color.basic_prod_id+"_"+color.id} key={index} style={{backgroundColor: color.hex_code}} onClick={() => this.ChangeColor(this.props.item.prod_url,color.id, this.props.i)}></span></div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        {color.sold_out == 1 ?
                                            <div className="transparent_colour_border"><img src="/images/Header/white_striked_circle.svg" className="color_striked_img" onClick={() => this.ChangeColor(this.props.item.prod_url,color.id, this.props.i)}/><span className="colour_dot_product_details" key={index} style={{backgroundColor: color.hex_code}}></span></div>
                                            :
                                            <div className="transparent_colour_border"><span className="colour_dot_product_details" key={index} style={{backgroundColor: color.hex_code}} onClick={() => this.ChangeColor(this.props.item.prod_url,color.id, this.props.i)}></span></div>
                                        }
                                    </div>
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="clearfix"></div>
                        {this.props.item.gender == "Unisex" ?
                            <div>
                                {this.props.path_source == ''?
                                    <Link to={"/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id}>
                                        <div className="col-xs-8 product_full_name">
                                            {this.props.item.short_name}
                                        </div>
                                        <div className="col-xs-4">
                                            {this.props.colors.map((item, index) => (
                                                <div>
                                                {this.props.item.specifications.color_prod_id === item.id && item.urjency_text ?
                                                    <p className="product_details_urgency_text">{item.urjency_text}</p>
                                                    :
                                                    null
                                                }
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-xs-12 product_size">
                                            {this.props.item.type == "Sunglasses" ?
                                                <div>
                                                    {this.props.item.gender}
                                                </div>
                                                :
                                                <div>
                                                    {this.props.item.specifications.frame_size} - {this.props.item.gender}
                                                </div>
                                            }
                                            
                                        </div>
                                    </Link>
                                :
                                    <Link to={"/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?gender="+this.props.item.gender+"&path_source="+this.props.path_source}>
                                        <div className="col-xs-8 product_full_name">
                                            {this.props.item.short_name}
                                        </div>
                                        <div className="col-xs-4">
                                            {this.props.colors.map((item, index) => (
                                                <div>
                                                {this.props.item.specifications.color_prod_id === item.id && item.urjency_text ?
                                                    <p className="product_details_urgency_text">{item.urjency_text}</p>
                                                    :
                                                    null
                                                }
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-xs-12 product_size">
                                            {this.props.item.specifications.frame_size} - {this.props.item.gender}
                                        </div>
                                    </Link>
                                }
                            </div>
                        :
                            <div>
                                {this.props.path_source == ''?
                                    <Link to={"/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id}>
                                        <div className="col-xs-8 product_full_name">
                                            {this.props.item.short_name}
                                        </div>
                                        <div className="col-xs-4">
                                            {this.props.colors.map((item, index) => (
                                                <div>
                                                {this.props.item.specifications.color_prod_id === item.id && item.urjency_text ?
                                                    <p className="product_details_urgency_text">{item.urjency_text}</p>
                                                    :
                                                    null
                                                }
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-xs-12 product_size">
                                            {this.props.item.specifications.frame_size} - {this.props.item.gender}
                                        </div>
                                    </Link>
                                :
                                    <Link to={"/product/"+this.props.item.prod_url+"/color/"+this.props.item.specifications.color_prod_id+"?path_source="+this.props.path_source}>
                                        <div className="col-xs-8 product_full_name">
                                            {this.props.item.short_name}
                                        </div>
                                        <div className="col-xs-4">
                                            {this.props.colors.map((item, index) => (
                                                <div>
                                                {this.props.item.specifications.color_prod_id === item.id && item.urjency_text ?
                                                    <p className="product_details_urgency_text">{item.urjency_text}</p>
                                                    :
                                                    null
                                                }
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-xs-12 product_size">
                                            {this.props.item.specifications.frame_size} - {this.props.item.gender}
                                        </div>
                                    </Link>
                                }
                            </div>
                        }
                    </div>
            );
    }
}

export default ProductTextAreaDesktop;