import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from '../Common/Footer';
import Loading from "../Common/Loading";
import OrderFilter from "./OrderFilter";
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

class OrderDetails extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            token : localStorage.getItem("token"),
            order_id: '',
            product_id: '',
            product_details: {delivery_address:{},products:{}, power: {}},
            reasons: [],
            primary_reason: '',
            secondary_reason: '',
            show_reason_section: true,
            show_order_refund_address: true,
            show_order_refund_mode: true,
            show_refund:true, 
        };
        this.SelectRefundReason = this.SelectRefundReason.bind(this);
        this.SelectSecondaryRefundReason = this.SelectSecondaryRefundReason.bind(this);
        this.RefundProceed = this.RefundProceed.bind(this);
        this.PreviousReasonDiv = this.PreviousReasonDiv.bind(this);
        this.RefundMode = this.RefundMode.bind(this);
        this.PreviousAddressDiv = this.PreviousAddressDiv.bind(this);
        this.RefundLastProceed = this.RefundLastProceed.bind(this);
        this.PreviousModeDiv = this.PreviousModeDiv.bind(this);
        this.RequestReturn = this.RequestReturn.bind(this);
    }
    componentDidMount()
    {
        if(this.state.token)
        {
            let order_id =  this.props.match.params.order_id;
            let product_id = this.props.match.params.product_id;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ this.state.token);

            let url = process.env.REACT_APP_API_URL+'/v1/orders/'+order_id+'/product/'+product_id;

            var requestOptions = {
                headers: myHeaders,
            };

            fetch(url, requestOptions)
            .then(response => response.json())
            
            .then(data => {
                this.setState({order_id: order_id, product_id: product_id, product_details: data.data, products: data.data.products}) 
            })

            if(this.state.show_reason_section == true)
            {
                let url1 = process.env.REACT_APP_API_URL+'/v1/return/reasons';

                var requestOptions = {
                    headers: myHeaders,
                };

                fetch(url1, requestOptions)
                .then(response => response.json())
                
                .then(data => {
                    this.setState({reasons: data.result.reasons}) 
                }) 
            }
        }
    }
    SelectRefundReason(id)
    {
        this.setState({primary_reason: id}) 
    }
    SelectSecondaryRefundReason(id)
    {
        this.setState({secondary_reason: id}) 
    }
    RefundProceed()
    {
        this.setState({show_reason_section: false}) 
    }
    PreviousReasonDiv()
    {
        this.setState({show_reason_section: true}) 
    }
    RefundMode()
    {
        this.setState({show_order_refund_address: false}) 
    }
    PreviousAddressDiv()
    {
        this.setState({show_order_refund_address: true}) 
    }
    RefundLastProceed()
    {
        this.setState({show_order_refund_mode: false}) 
    }
    PreviousModeDiv()
    {
        this.setState({show_order_refund_mode: true, show_refund: true}) 
    }
    RequestReturn()
    {
        var address_id = this.state.product_details.delivery_address.id;
        var pincode = parseInt(this.state.product_details.delivery_address.ship_code);
        var phone = this.state.product_details.delivery_address.phone;
        let order_id =  this.props.match.params.order_id;
        let product_id = this.props.match.params.product_id;

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.state.token);

        let url = process.env.REACT_APP_API_URL+'/v1/return/order/'+order_id+'/product/'+product_id;
        let data = JSON.stringify({'pincode' : pincode, 'phone': phone, 'address_id':address_id, primary_reason_id: this.state.primary_reason, secondary_reason_id: this.state.secondary_reason});
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };

        fetch(url, requestOptions)
        .then(response => response.json())
        
        .then(data => 
        {
            console.log(data);
            if(data.success == true)
            {
                this.setState({show_refund: false}) 
                //swal(data.error.message); 
            }
            else
            {
                swal(data.error.message);
                //this.setState({show_refund: false}) 
            }
        })

    }
    render() {
        console.log(this.state.product_details)
        console.log(this.state.reasons)
		return(
			<div>
                <Header color={"#160D08"}/>
                <div className="refund_order_details_body col-xs-12 padding_is_zero">
                    {this.state.show_refund ?
                        <div>
                            <div className="col-xs-12 col-sm-6 each_order_id_section">
                                <p className="refund_order_header">Return Order</p>
                            </div>
                            <div className="col-sm-12 ">
                                <div className="col-sm-3"></div>
                                <div className="col-xs-12 col-sm-6 refund_order_section">
                                    <div className="col-xs-12 order_section_header padding_is_zero">
                                        <div className="col-xs-6">
                                            <p className="order_total_cost">Rs. {this.state.product_details.total_cost}</p>
                                            <p className="order_subtext"></p>
                                        </div>
                                        <div className="col-xs-6 padding_is_zero">
                                            <p className="order_text_details">Order ID: </p>
                                            <p></p>
                                        </div>
                                        
                                        <div className="col-xs-6">
                                            <p className="order_status"></p>
                                            <p className="order_subtext">{this.state.product_details.payment_method}</p>
                                        </div>
                                        <div className="col-xs-6 padding_is_zero">
                                            <p className="order_status"></p>
                                            <p className="order_subtext">{this.state.product_details.hashed_id}</p>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xs-12 each_refund_order_product_section m_b_10 padding_is_zero">
                                        <div className="col-xs-6 padding_is_zero">
                                            <center><a href={"/product/"+this.state.product_details.products.product_url}><img src={this.state.product_details.products.image} className="order_details_product_image img-responsive"/></a></center>
                                        </div>
                                        <div className="col-xs-6 padding_is_zero">
                                            <p className="order_product_brand_text">{this.state.product_details.products.brand_name}</p>
                                            <p className="order_product_full_name">{this.state.product_details.products.name}</p>
                                            <p className="order_product_size">{this.state.product_details.products.size} - {this.state.product_details.products.gender}<span className="colour_dot order_product_color" style={{backgroundColor: this.state.product_details.products.color}}></span></p>
                                            <p className="order_product_qty">X {this.state.product_details.products.qty}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                            <div className="clearfix"></div>
                            {this.state.show_reason_section ?
                                <div>
                                    <div className="col-xs-12 padding_is_zero refund_order_reason_div">
                                        <div className="col-xs-12 col-sm-6 each_order_id_section">
                                            <p className="refund_order_subheader">Return Reason</p>
                                        </div>
                                        {this.state.reasons.length > 0 ?
                                            <div>
                                                {this.state.reasons.map((data, index) => (
                                                    <div className="col-xs-12 each_reason_div" >
                                                        <input type="radio"  name="active_reason" onClick={()=>this.SelectRefundReason(data.id)}/>
                                                        <label className="combo_label"> {data.reason} </label><br />
                                                        {this.state.primary_reason == data.id ?
                                                            <div>
                                                            {data.secondaryReasons.map((data, index) => (
                                                                <div className="col-xs-12 each_reason_div">
                                                                    <input type="radio"  name="active_subreason" onClick={()=>this.SelectSecondaryRefundReason(data.id)}/>
                                                                    <label className="combo_label"> {data.reason} </label><br />
                                                                </div>
                                                            ))}
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="col-xs-12 padding_is_zero order_refund_footer">
                                        <div className="col-xs-6">
                                            
                                        </div>
                                        <div className="col-xs-6">
                                            <button className="order_refund_button" onClick={this.RefundProceed}>Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            :
                                <div>
                                {this.state.show_order_refund_address ?
                                    <div>
                                        <div className="col-xs-12 padding_is_zero refund_order_reason_div">
                                            <div className="col-xs-12 col-sm-6 each_order_id_section">
                                                <p className="refund_order_subheader">Pickup Address</p>
                                            </div>
                                                <div className="col-xs-12 each_reason_div" >
                                                    <input type="radio" id={"address_"+this.state.product_details.delivery_address.id} name="active_address" checked/>
                                                    <label className="select_address_label return_selected_address">{this.state.product_details.delivery_address.name} | {this.state.product_details.delivery_address.phone}</label>
                                                    <br/>
                                                    <p className="select_address_subtext">{this.state.product_details.delivery_address.shipping_address+", "+this.state.product_details.delivery_address.ship_city+", "+this.state.product_details.delivery_address.ship_state+", "+this.state.product_details.delivery_address.ship_code}</p>
                                                </div>
                                                
                                        </div>
                                        <div className="col-xs-12 padding_is_zero order_refund_footer">
                                            <div className="col-xs-6">
                                                <img src="/images/Header/Quiz_previous.svg" className="refund_previous_button" onClick={this.PreviousReasonDiv}/>
                                            </div>
                                            <div className="col-xs-6">
                                                <button className="order_refund_button" onClick={this.RefundMode}>Proceed</button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                    {this.state.show_order_refund_mode ?
                                        <div>
                                            <div className="col-xs-12 padding_is_zero refund_order_reason_div">
                                                <div className="col-xs-12 col-sm-6 each_order_id_section">
                                                    <p className="refund_order_subheader">Select the refund mode</p>
                                                </div>
                                                    <div className="col-xs-12 each_reason_div" >
                                                        <input type="radio"  name="active_refund_mode" checked/>
                                                        <label className="select_refund_mode_label select_address_label"><img src="/images/Header/Coins_blue.svg" className="refund_mode_img"/>Get credits in OJOS wallet</label>
                                                        <br/>
                                                        <p className="select_refund_mode_subtext select_address_subtext">use those credits for buying any other product</p>
                                                    </div>
                                                    <div className="col-xs-12 each_reason_div" >
                                                        <input type="radio" name="active_refund_mode" checked/>
                                                        <label className="select_refund_mode_label select_address_label"><img src="/images/Header/Rupees_blue.svg" className="refund_mode_img"/>Refund to source</label>
                                                        <br/>
                                                        <p className="select_refund_mode_subtext select_address_subtext">you will get refund back to source payment method</p>
                                                    </div>
                                                    
                                            </div>
                                            <div className="col-xs-12 padding_is_zero order_refund_footer">
                                                <div className="col-xs-6">
                                                    <img src="/images/Header/Quiz_previous.svg" className="refund_previous_button" onClick={this.PreviousAddressDiv}/>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button className="order_refund_button" onClick={this.RefundLastProceed}>Proceed</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="col-xs-12 padding_is_zero refund_order_reason_div">
                                                <div className="col-xs-12 col-sm-6 each_order_id_section">
                                                    <p className="refund_order_subheader">Confirm your return details</p>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 padding_is_zero refund_order_reason_div">
                                                <div className="col-xs-12 col-sm-6 order_return_final_pickup_section">
                                                    <p className="refund_order_final_address">Pickup Address</p>
                                                    <br/>

                                                    <p className="select_address_label">{this.state.product_details.delivery_address.name} | {this.state.product_details.delivery_address.phone}</p>
                                                    <p className="refund_confirm_address select_address_subtext">{this.state.product_details.delivery_address.shipping_address+", "+this.state.product_details.delivery_address.ship_city+", "+this.state.product_details.delivery_address.ship_state+", "+this.state.product_details.delivery_address.ship_code}</p>
                                                </div>
                                                <div className="col-xs-12 col-sm-6 order_final_return_section">
                                                    <p className="refund_order_final_address">Refund Mode</p>
                                                    <br/>

                                                    <p className="select_refund_mode_label select_address_label"><img src="/images/Header/Rupees_blue.svg" className="refund_mode_img"/>Refund to source</p>
                                                    <p className="select_final_refund_mode_subtext select_address_subtext">you will get refund back to source payment method</p>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 padding_is_zero order_refund_footer">
                                                <div className="col-xs-4">
                                                    <img src="/images/Header/Quiz_previous.svg" className="refund_previous_button" onClick={this.PreviousModeDiv}/>
                                                </div>
                                                <div className="col-xs-8">
                                                    <button className="request_return_button" onClick={this.RequestReturn}>REQUEST RETURN</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                }

                                </div>
                            }
                        </div>
                        :
                        <div>
                            <div className="col-xs-12 col-sm-12 each_order_id_section">
                                <center>
                                    <img src="/images/Header/CheckMark.svg" />
                                    <p className="final_refund_text">Pickup scheduled & refund details recieved</p>
                                </center>
                            </div>
                            <div className="col-xs-12 col-sm-12 each_order_id_section">
                                <center>
                                    <p className="each_product_order_id">Order ID</p>
                                    <p>{this.state.product_details.hashed_id}</p>
                                </center>
                            </div>
                            <div className="col-sm-3"></div>

                            <div className="clearfix"></div>

                            <div className="col-sm-3"></div>
                            <div className="col-xs-12 col-sm-6  padding_is_zero">
                                <center>
                                    <a href={"/product/"+this.state.product_details.products.product_url}><img src={this.state.product_details.products.image} className="order_product_image img-responsive" /></a>
                                </center>
                            </div>
                            <div className="col-sm-3"></div>

                            <div className="col-xs-12 order_product_details">
                                <center>
                                    <p className="order_product_brand_text">{this.state.product_details.products.brand_name}</p>
                                    <p className="order_product_full_name">{this.state.product_details.products.name}</p>
                                    <p className="order_product_size">{this.state.product_details.products.size} - {this.state.product_details.products.gender}<span className="colour_dot order_product_color" style={{backgroundColor: this.state.product_details.products.color}} ></span></p>
                                    <p className="order_product_qty">X {this.state.product_details.products.qty}</p>
                                </center>
                            </div>
                            <div className="col-xs-12 padding_is_zero refund_order_reason_div">
                                <div className="col-xs-12 col-sm-6 each_order_id_section">
                                    <p className="refund_order_subheader">Your return details</p>
                                </div>
                            </div>
                            <div className="col-xs-12 padding_is_zero refund_order_reason_div">
                                <div className="col-xs-12 col-sm-6 order_return_final_pickup_section">
                                    <p className="refund_order_final_address">Pickup Address</p>
                                    <br/>

                                    <p className="select_address_label">{this.state.product_details.delivery_address.name} | {this.state.product_details.delivery_address.phone}</p>
                                    <p className="refund_confirm_address select_address_subtext">{this.state.product_details.delivery_address.shipping_address+", "+this.state.product_details.delivery_address.ship_city+", "+this.state.product_details.delivery_address.ship_state+", "+this.state.product_details.delivery_address.ship_code}</p>
                                </div>
                                <div className="col-xs-12 col-sm-6 order_final_return_section">
                                    <p className="refund_order_final_address">Refund Mode</p>
                                    <br/>

                                    <p className="select_refund_mode_label select_address_label"><img src="/images/Header/Rupees_blue.svg" className="refund_mode_img"/>Refund to source</p>
                                    <p className="select_final_refund_mode_subtext select_address_subtext">you will get refund back to source payment method</p>
                                </div>
                            </div>
                        </div>
                    }
                    <Footer />
                </div> 
            </div>
		);
		
	}
}

export default OrderDetails;