import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

class FAQ extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            exchange_show_more: false,
            cart_count: 0,
            app_id: 0,
        };
        this.ExchangeReadMore  = this.ExchangeReadMore.bind(this);
    }
    componentDidMount()
    {
        const query = new URLSearchParams(this.props.location.search);
		const app_id = query.get('app_id');
		if(app_id == 1)
		{
			this.setState({app_id: app_id});
		}
        let prod_count = localStorage.getItem("prod_counts");
        if(prod_count)
        {
            var total_qty = 0;
            let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
            for(var i = 0; i<= product_id_array.length-1; i++)
            {
                if(product_id_array[i] !== 0)
                {
                    total_qty = total_qty + JSON.parse(localStorage.getItem("prod_"+product_id_array[i])).qty;
                }
            }
            this.setState({
                cart_count: total_qty
            });
        }
    }
	ExchangeReadMore()
	{
		this.setState ({exchange_show_more: !this.state.exchange_show_more})
	}
	render() {
		return (
	    	<div>
	    		{this.state.app_id != 1 ?
	    			<Header color={"#160D08"} cart_count={this.state.cart_count}/>
	    			:
	    			null
	    		}
    			<div className={this.state.app_id != 1 ? "row grey_background" : "row grey_background_no_header"}>
					<div className="col-xs-12">
						<center className="font_20 font_w_700 header_text">
							<p className="contact_us_header">Frequently Asked Questions</p>
						</center>
					</div>
				</div>
				<div className=" container-fluid no_margin faq_content">
					<div className="col-sm-3"></div>
					<div className="col-xs-12 col-sm-6 padding_is_zero">
						<p className="faq_header">ABOUT OJOS</p>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title1" className="faq_input faq-drawer__trigger"/>
							<label for="title1" className="faq_question faq-drawer__title">
								<p>What is OJOS Wear?</p>
							</label>

							<div className="content">
								<p>At OJOS we're all about being young, fun, bold, and quirky, we are the latest verticle in alliance with Lenskart and we aim to bring premium quality super fresh eyewear to you who have a knack to stand out in the crowd, at prices that are pocket friendly and styles that are deadly! To know more about us check our <a href="/about-us">about us</a> page.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title24" className="faq_input faq-drawer__trigger"/>
							<label for="title24" className="faq_question faq-drawer__title">What is the OJOS promise?</label>

							<div className="content">
								<p>We at OJOS Promise to help our customers serve Lewks and not serve them huge bills! All of our frames use premium-quality metals and acetates which are carefully crafted by our designers to fit your style. Our lenses are anti-reflective, hydrophobic, dust and scratch resistant and are made using the latest technology! Don't believe us, well It also comes with a 1-year warranty and 14 days return exchange because we don't joke around with our customer's assurity!</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title2" className="faq_input faq-drawer__trigger"/>
							<label for="title2" className="faq_question faq-drawer__title">
								<p>Are your frames available in an offline store?</p>
							</label>

							<div className="content">
								<p>Right now we are an online brand. The offline shop is in the pipeline and will be made available to our OJOS tribe soon. wink wink! To check out our online store <a href="/">click here</a>.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title3" className="faq_input faq-drawer__trigger"/>
							<label for="title3" className="faq_question faq-drawer__title">
								<p> I have some credit points left, how can I use them? </p>
							</label>

							<div className="content">
								<p>You can have your pick from our lot! You can get eyeglasses and sunglasses using your credit points. One credit equals one eyewear. If you are getting a blue-cut single-vision lens for your glasses, you will need to make just the payment for the Blucut lens and the rest of it is free, even delivery.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title4" className="faq_input faq-drawer__trigger"/>
							<label for="title4" className="faq_question faq-drawer__title">
								<p> Is there a warranty available for OJOS products?</p>
							</label>

							<div className="content">
								<p>
									We don't sell you hollow promises or low-quality products. We assure you that only the best premium-quality materials are used for crafting your individual orders with extreme precision. We offer a 1-year warranty on all of our products. <br/>
									<b>Warranty includes: </b> <br/>                                                                                                                                                                                                                                                                                                                                                    
									1. Damages caused to the product due to corrosion, rusting, coating burst/peel off, peeling of color, and color fading.<br/>
									<b>Warranty excludes: </b> <br/>
									OjosWear warranty does not cover product breakage, scratches, damages caused by accident, mishandling, negligence, shock, tampering, and loss of components or accessories, improper use or storage of the product; unauthorized modifications or repairs; or normal wear & tear. <br/>
									Warranty conditions: <br/>
									1. This warranty will automatically terminate on the expiry of the warranty period. <br/>
									2. This warranty is valid only when purchased through Ojoswear.<br/>
									3. The decision of the company on the nature of the defects and applicability of the warranty will be final. <br/>
									4. The company will, in no event be liable or obligated for the payments/refund of any special incidental or consequential damages <br/>
									5. We have a separate Warranty policy for all the Branded products which includes Ray-Ban, Carrera, Vogue, etc.   <br/>                                                                                                                                                                                              
									Please email the pictures of the product with the defects to be duly validated to contact@ojoswear.com along with the order ID. <br/>
								</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title5" className="faq_input faq-drawer__trigger"/>
							<label for="title5" className="faq_question faq-drawer__title">
								<p>I have a different query. How can I reach out to OJOS?</p>
							</label>

							<div className="content">
								<p>We are your friendly next-door business and we love to hear from you whenever we can. You can simply reach out to us via our Contact Us section through Mail or WhatsApp or feel free to drop us a "Hi" over our social media linked below!</p>
							</div>
						</div>
						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title1" className="faq_input faq-drawer__trigger"/>
							<label for="title1" className="faq_question faq-drawer__title">
								<p>What’s new and different at OJOS?</p>
							</label>

							<div className="content">
								<p>Getting that specs appeal is an easy-peasy affair, with faster checkouts at OJOS! We aim to simplify your shopping experience and save your moolah with an array of 21 fashionable and affordable eye frames, you could lay your eyes on! To keep the sparkle of newness up and running, we revamp our range of 21 eye frame designs, monthly (yes, we are hard to forget!). Never miss a blink with our curated collection, carefully selected and styled by craft-led design makers, fitted with premium lenses at no additional cost! The frames with Anti-Reflective (AR) coated lenses, are Hydrophobic, Dust/Scratch resistant and are created using the finest quality and latest in technology. So stay tuned...we have a lot coming up.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title24" className="faq_input faq-drawer__trigger"/>
							<label for="title24" className="faq_question faq-drawer__title">How are you associated with Lenskart?</label>

							<div className="content">
								<p>We belong to Lenskart. We are a vertical powered by Lenskart, dealing with a specific range and collection of eyeglasses.</p>
							</div>
						</div>

						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title2" className="faq_input faq-drawer__trigger"/>
							<label for="title2" className="faq_question faq-drawer__title">
								<p>Faster checkouts and simplified eyewear shopping at OJOS? How is that possible?</p>
							</label>

							<div className="content">
								<p>Trust us, it is as quick as cooking up a pot of noodles! All of our 21 oomphful eye frames have already been refined, fitted with premium AR coated lenses, and come in designs that are tailored to suit your style. The delectable eye frame range has a fixed price tag, so all you need to do is pick and click on checkout! You don’t have to browse through a zillion options to make a choice...we hate the menu card as well!!</p>
							</div>
						</div>*/}
						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title3" className="faq_input faq-drawer__trigger"/>
							<label for="title3" className="faq_question faq-drawer__title">
								<p>How can I save on my purchase?</p>
							</label>

							<div className="content">
								<p>It’s a deal! You get to choose any eyeglass fitted with premium lenses at OJOS for just Rs.990 or, even better, get 3 eyeglasses fitted with premium lenses for Rs.700 each, which is a subscription plan.  The eyeglasses come fitted with premium lenses. You pay only Rs.700 for an eyeglass with 1 year validity on the purchase period through this plan. So subscribe now and come back anytime to pick your perfect pairs!  Aren’t we perfect?</p>
							</div>
						</div>*/}
						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title4" className="faq_input faq-drawer__trigger"/>
							<label for="title4" className="faq_question faq-drawer__title">
								<p>What’s exciting about the frames available at OJOS?</p>
							</label>

							<div className="content">
								<p>Trendy, high-quality and affordable is what we bet on when it comes to our frames. We take all of this seriously, hence all our frames are made using Cellulose Acetate and TR90 for the lightweight and super-flexible experience. Choose from full-rimmed, half-rimmed, and more to give you the perfect fit and the classy finish you’ve been looking for.</p>
							</div>
						</div>*/}
						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title5" className="faq_input faq-drawer__trigger"/>
							<label for="title5" className="faq_question faq-drawer__title">
								<p>Tell me more about OJOS credits.</p>
							</label>

							<div className="content">
								<p>When you choose the “3 eyeglasses for Rs.700 each” subscription plan at OJOS, you receive 3 Credits to purchase your eyewear. E.g. 1 Credit = 1 Eyeglass, redeemable at all times, with a 1 year validity. We keep replenishing and revamping our collection approximately every month, so you can pick your eyewear, at any time and from a collection that’s your favourite! We have your credits safe with us, our commitment to you.</p>
							</div>
						</div>*/}

						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title23" className="faq_input faq-drawer__trigger"/>
							<label for="title23" className="faq_question faq-drawer__title">
								<p>How do I use my OJOS wallet credits to make a payment?</p>
							</label>

							<div className="content">
								<p>Snap and it's done! Once you purchase the subscription plan of "3 eyeglasses for Rs.700 each", 3 credits will reflect in your wallet, that can be used at any time with 1 year validity. These credits will be automatically checked as a payment method on the cart page for the products added. In case you have added more than 3 products to your cart, you will be prompted to pick a new subscription plan of 3 credits, which can be used for the additional products. </p>
							</div>
						</div>*/}

						<p className="faq_header">MY ORDERS</p>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title6" className="faq_input faq-drawer__trigger"/>
							<label for="title6" className="faq_question faq-drawer__title">
								<p>How can I track my order?</p>
							</label>

							<div className="content">
								<p>We got you, babes! You can check your order(s) at any time. Click on the navigation bar (three horizontal lines) on the top left of the OJOS page. After clicking on it, scroll down to the Orders section. You will be taken to the Orders page where you can view and track the status of your current order(s). You can track your order <a href="/order"> HERE </a>.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title7" className="faq_input faq-drawer__trigger"/>
							<label for="title7" className="faq_question faq-drawer__title">
								<p>My money got deducted but my order didn't get placed. What should I do? </p>
							</label>

							<div className="content">
								<p>Don't Panic. And take a breath, In and out because we got you! All you gotta do is text or drop us a mail, mentioning your Order ID no. or your registered Phone no. along with your name and we will take care of it for you and your order will be placed shortly after that. To contact us click <a href="/contact-us">here</a>.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title25" className="faq_input faq-drawer__trigger"/>
							<label for="title25" className="faq_question faq-drawer__title">What are the payment methods available?</label>

							<div className="content">
								<p>Kaching! We like to keep it simple at OJOS. We have all sorts of payment options available just for you. Your payment can be made through Debit/credit cards, Netbanking, wallet, UPI transactions as well as Paytm!  To read more about  Pricing and payments click <a href="/terms-and-conditions"> HERE </a>.</p>
							</div>
						</div>
						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title6" className="faq_input faq-drawer__trigger"/>
							<label for="title6" className="faq_question faq-drawer__title">
								<p>What is the status of my order?</p>
							</label>

							<div className="content">
								<p>Ask, and you shall receive! You can check your order(s) anytime. Click on the Navigation Bar (3 horizontal lines, a hamburger bar!) at the top-left corner of the OJOS page. Once you click on it, scroll down to the “Orders” section. You will be redirected to the orders page, where you can view and track the status of your current order(s).</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title7" className="faq_input faq-drawer__trigger"/>
							<label for="title7" className="faq_question faq-drawer__title">
								<p>Can I order multiple eyeglasses with different prescriptions?</p>
							</label>

							<div className="content">
								<p>Of course, it’s all yours! Once you place your order in your cart - start completing the checkout and you will be prompted to enter the prescription for each of the frames. You can also enter your prescription up to 7 days after the order has been placed. In the subscription offer, as well, feel free to pick your eyeglass with different prescription for each, or choose from the dropdown of saved powers in case of a returning customer!</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title25" className="faq_input faq-drawer__trigger"/>
							<label for="title25" className="faq_question faq-drawer__title">Is COD available?</label>

							<div className="content">
								<p>Currently we do not have COD as a payment option. We are working on serving you the best and will be introducing it, shortly.</p>
							</div>
						</div>*/}
						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title28" className="faq_input faq-drawer__trigger"/>
							<label for="title28" className="faq_question faq-drawer__title">Can I get an eyewear for Rs. 700?</label>

							<div className="content">
								<p>An absolute yes! Sign up for our OJ Super subscription plan to avail this feature.</p>
							</div>
						</div>*/}


						<p className="faq_header">FRAMES AND PRESCRIPTIONS</p>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title8" className="faq_input faq-drawer__trigger"/>
							<label for="title8" className="faq_question faq-drawer__title">
								<p>Why is the frame I want out of stock?</p>
							</label>

							<div className="content">
								<p>We are a growing brand, and we love how you all have showered us with love and appreciation for our eyewear. we love to keep things trendy and exclusive here on OJOS, hence we keep our collections changing with limited stocks. Although we will be glad to bring some back if the customers are asking for it, dying for it, and getting on our backs!</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title9" className="faq_input faq-drawer__trigger"/>
							<label for="title9" className="faq_question faq-drawer__title">
								<p>How do I add power to my eye glasses?</p>
							</label>

							<div className="content">
								<p>To add power to your glasses, simply go to your Orders page after you have placed your order and completed your payment. You will see an option of "enter my prescription" and bam, just like that you can add your power! you"ll also receive an SMS and mail, reminding you to fill in your power details, cause we make sure you don't miss out on your best vision! To add a power prescription visit <a href="/order"> my order </a>. </p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title10" className="faq_input faq-drawer__trigger"/>
							<label for="title10" className="faq_question faq-drawer__title">
								<p>I don't know my prescription power, can I submit them later?</p>
							</label>

							<div className="content">
								<p>We all have been there,bestie! It's okay to provide prescription details after purchase. Until then, buy the frame you want. But make sure to provide prescription values within 7 days after checkout on <a href="/order"> my orders </a> page.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title11" className="faq_input faq-drawer__trigger"/>
							<label for="title11" className="faq_question faq-drawer__title">
								<p>Can I add multiple glasses with different prescriptions?</p>
							</label>

							<div className="content">
								<p>why stop at 1, when you can have 10! You can easily change your prescription power for every individual eyeglass you purchase because we do believe in diversity for all frames and lenses!</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title26" className="faq_input faq-drawer__trigger"/>
							<label for="title26" className="faq_question faq-drawer__title">Which Size will be the best fit for me?</label>

							<div className="content">
								<p>Most people require a size Medium(M), but some glasses do come in oversized trends which are supposed to be a bit oversized for you. In case you need a more specific size detail here's a hack just for you! Use a card – preferably a debit or credit card. Standing in front of a mirror, try placing the end of the card exactly on your nose bridge under your eyebrow. If the edge of the card is over the edge of your eye by a lot, you are an extra narrow size (EN), if by a little then you're a Narrow (N). If the edge of the card ends at the outer edge of your eye, you need a medium (M). For those whose card edge ends before meeting the outer edge of your eye by a little, you would need a Wide frame (W). And if it ends before meeting the edge of the eye by a lot then Extra wide(EW). And there you have your approximate frame size! For more details check the size guide <a href="/product-size">here</a>.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title27" className="faq_input faq-drawer__trigger"/>
							<label for="title27" className="faq_question faq-drawer__title">What are blu-cut lenses? and why should I get them?</label>

							<div className="content">
								<p>Blu-cut lenses are designed to absorb blue light that is usually emitted via digital screens. This type of lens is designed to allow some blue light to pass through to prevent color distortion. Blu-cut lenses reduce symptoms of digital eye strain, especially when working at night. Over time, wearing blue blockers while working on digital devices may help to normalize your circadian rhythm and risk of macular degeneration. </p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title29" className="faq_input faq-drawer__trigger"/>
							<label for="title29" className="faq_question faq-drawer__title">Does OJOS Wear have blu cut lenses? How can I upgrade to blu cut?</label>

							<div className="content">
								<p>You asked for it and we got it! We have Blu cut lenses available for your next glasses. the steps are quite simple: 1. Get overwhelmed by our amazing collection, and select a frame (or three, we don't judge!). 2. Select your lenses and add blue cut single vision. 3. Add it to your cart and head over to checkout for payment. And you just got yourself glasses that will change your view( quite literally) ! </p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title30" className="faq_input faq-drawer__trigger"/>
							<label for="title30" className="faq_question faq-drawer__title">Can we add power to the sunglasses? what materials are used for sunglasses?</label>

							<div className="content">
								<p>At this moment we can not add power to your shades as of yet. But we are working towards adding that feature soon. We use only premium-quality metals and acetate for sunglasses because you deserve nothing but the very best! So make sure to stay tuned for our upcoming launches. To check out our sunglasses collection click <a href="/products/sunglasses">here</a>. </p>
							</div>
						</div>
						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title8" className="faq_input faq-drawer__trigger"/>
							<label for="title8" className="faq_question faq-drawer__title">
								<p>I’m new to wearing glasses. How do I measure the right fit when buying online?</p>
							</label>

							<div className="content">
								<p>There’s a hack! Use any card – preferably a debit or credit card. Standing in front of the mirror, place one end of the card exactly on the bridge of your nose and under your eyebrow. If the edge of the card surpasses the edge of your eye, you need a narrow size (N). We also have an extra narrow (E) size for some frames, to give you that perfect sleek fit. If the edge of the card ends at the outer edge of your eye, you need a medium size (M). If the edge of the card ends before the outer edge of your eye, you need a wide size (W). Voilà! you have an approximate specification of your frame size.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title9" className="faq_input faq-drawer__trigger"/>
							<label for="title9" className="faq_question faq-drawer__title">
								<p>Where can I find the size of the frames?</p>
							</label>

							<div className="content">
								<p>To make it easier, we offer 4 frame sizes to choose from – Extra Narrow, Narrow, Medium and Wide. The sizes have been mentioned against every product you browse at OJOS. Size does matter...after all! What say?  </p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title10" className="faq_input faq-drawer__trigger"/>
							<label for="title10" className="faq_question faq-drawer__title">
								<p>Do the lenses at OJOS come in all prescriptions?</p>
							</label>

							<div className="content">
								<p>We aim to cover all prescriptions in due course, however complex, at OJOS. Each frame comes with different eye power availability ranges. Currently, we only cover for a specific eye power ranging from Spherical power being +4 to -6 and Cylindrical power being 0 to -2, Single Vision and Zero-Power lenses. We will be able to get all your requirements met, very soon.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title11" className="faq_input faq-drawer__trigger"/>
							<label for="title11" className="faq_question faq-drawer__title">
								<p>What if I do not have my prescription while placing an order? Can I provide the details later?</p>
							</label>

							<div className="content">
								<p>It’s completely fine to give us your prescription details after your purchase. Go ahead and buy your desired frames until then. You have 7 days to provide the prescription readings, post-checkout.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title26" className="faq_input faq-drawer__trigger"/>
							<label for="title26" className="faq_question faq-drawer__title">What kind of lenses do you provide?</label>

							<div className="content">
								<p>The lenses we use are of premium quality, with an anti reflective coating. The lenses are hydrophobic and dust resistant as well. We do include the BluCut feature as well.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title27" className="faq_input faq-drawer__trigger"/>
							<label for="title27" className="faq_question faq-drawer__title">Is your frames available on offline store?</label>

							<div className="content">
								<p>We are an online brand. Offline stores are in the pipeline and will be on it, shortly.</p>
							</div>
						</div>*/}


						<p className="faq_header">SHIPPING</p>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title12" className="faq_input faq-drawer__trigger"/>
							<label for="title12" className="faq_question faq-drawer__title">
								<p>How many days will it take for me to receive my order?</p>
							</label>

							<div className="content">
								<p>Knock Knock! looks like someone is at the door and It is your OJOS eyewear! Your order will take 7-10 working days to reach you. We don't like to keep our besties waiting to turn it up! You can read more about our delivery and cancellation policies <a href="/terms-and-conditions">here</a>.</p>
							</div>
						</div>
						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title12" className="faq_input faq-drawer__trigger"/>
							<label for="title12" className="faq_question faq-drawer__title">
								<p>How many days will it take for my order to be shipped?</p>
							</label>

							<div className="content">
								<p>You have our word to deliver your order as quick as possible. You can expect your order to be dispatched within approximately 5-12 days of order confirmation. It may take an additional 2-3 days to deliver, post-dispatch, based on the shipping location. However, given the current pandemic situation, your orders may be subject to unforeseeable delays.</p>
							</div>
						</div>*/}

						<p className="faq_header">EXCHANGE/RETURN</p>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title14" className="faq_input faq-drawer__trigger"/>
							<label for="title14" className="faq_question faq-drawer__title">
								<p>I don't like my eyewear. What can i do?</p>
							</label>

							<div className="content">
								<p>why fret when OJOS is here? Before you place your order you can always try out our latest feature AR try on for better understanding. In case the purchase has been made, you can cancel your first order with us and get your money refunded. In case it's not your first order and you still don't like the glasses, we can get it exchanged for a different one, absolutely free of cost. You can read more about our refund and exchange policies <a href="/terms-and-conditions">here</a>.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title15" className="faq_input faq-drawer__trigger"/>
							<label for="title15" className="faq_question faq-drawer__title">
								<p>How long will it take for my money to get refunded?</p>
							</label>

							<div className="content">
								<p>Refunds will be credited to you after approval by the OJOS team within 14 days of receipt of your order. Please note that the order to be refunded must be your first complete order from OJOS. If you have any questions, please contact our customer service team. we are always happy to help you.  You can read more about our refund and exchange policies <a href="/terms-and-conditions">here</a>.</p>
							</div>
						</div>
						{/*<div className="each_faq faq-drawer">
							<input type="checkbox" id="title14" className="faq_input faq-drawer__trigger"/>
							<label for="title14" className="faq_question faq-drawer__title">
								<p>Can I cancel an existing order?</p>
							</label>

							<div className="content">
								<p>Your lenses and frames are custom-made just for you - if you decide to cancel your order, we will not use them for anyone else.However, we want you to have a risk-free experience. If you want to cancel your order before it reaches you, for any reason, just contact us at the following:
You can contact us through the "Contact Us/Need Help" option on the Website or App.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title15" className="faq_input faq-drawer__trigger"/>
							<label for="title15" className="faq_question faq-drawer__title">
								<p>Can I Exchange/Return my Eyeglasses while in the subscription plan? Will there be a refund in case of a return?</p>
							</label>

							<div className="content">
								<p>Currently, we do not have an exchange policy for any of our products. But we do have a refund policy.  In case of a refund, we only initiate the process if it's your first order at OJOS and within 14 days of receiving the order. Please note that we run a stiff check on order refund eligibility and the request should be for your first complete purchase and transaction with us. We do not process refund requests for subsequent orders. Have a query on refund eligibility? Write back to us at contact@ojoswear.com, and we'll have it sorted.
								<br/>
								Eyewear with the following are not accepted for a refund:
								<br/>
								1. Scratches on frame or lens that were not present at the time of delivery. We do a precise 3-step quality check to ensure that the product reaches you in perfect condition.
								<br/>
								2. Product damaged due to mishandling post-delivery [changes in the product shape, with visible intentional damage to the product].
								<br/>
								3. Eyewear case or other standard packaging is damaged or torn or otherwise not in the same condition as it was received) Don’t worry we are your friendly neighbours!
								</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title16" className="faq_input faq-drawer__trigger"/>
							<label for="title16" className="faq_question faq-drawer__title">
								<p>What is your Exchange/Return/Refund policy?</p>
							</label>

							<div className="content">
								<p>Yes, in case you are unhappy with your product you can return and initiate a refund for the returned product. You are eligible to return your product within 14 days of receiving the order, and this is your first order/transaction at OJOS. The amount will be credited back to you. Once the product has reached our warehouse and is in its original condition. We run a stiff check on all products prior to scheduling a refund. Please update the accurate details for the refund source, in case asked while approval. We do not run a banking verification check, so ensure the right details for refund at all times.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title17" className="faq_input faq-drawer__trigger"/>
							<label for="title17" className="faq_question faq-drawer__title">
								<p>Where do I find the Refund option?</p>
							</label>

							<div className="content">
								<p>Right where you left it! Go to the Order's section in your accounts page of OJOS. Once you click on it, scroll down to the “Orders” section. You will be able to view all your upcoming and delivered orders.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title18" className="faq_input faq-drawer__trigger"/>
							<label for="title18" className="faq_question faq-drawer__title">
								<p>Will I be able to get a refund (if eligible) instantly?</p>
							</label>

							<div className="content">
								<p>Your refund will be initiated, once your returned product has reached us, in good condition, and is approved by the OJOS Team.</p>
							</div>
						</div>
						<div className="each_faq faq-drawer">
							<input type="checkbox" id="title19" className="faq_input faq-drawer__trigger" aria-expanded="false"/>
							<label for="title19" className="faq_question faq-drawer__title">
								<p>What refund methods are available?</p>
							</label>

							<div className="content">
								<p>Once approved by OJOS, your refund will be credited back to you, Please note that the order to be refunded, has to be your first complete order with OJOS. In case of any queries, please contact the customer care team, and we'll be glad to assist.</p>
							</div>
						</div>*/}
						
					</div>
					<div className="col-sm-3"></div>
					<div className="clearfix"></div>
				</div>
				{this.state.app_id != 1 ?
					<Footer />
					:
					null
				}
	    	</div>
	    );
	}
}

export default FAQ;
