import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HomePageDeliverySection extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
    render() {
        return (
            <div className="col-xs-12">
                {/*<div className="hidden-sm hidden-md hidden-lg">
                    <center>
                        <p className="homepage_uncertain_text_mob extra_bold_text">can’t wait for your new look?</p>
                        <p className="homepage_uncertain_subtext_mob">get your superpower delivered to your doorstep at a lightning speed</p>
                    </center>
                    <div className="col-xs-12 padding_is_zero">
                        
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/14DaysReturn.svg" className="homepage_delivery_icons_mob"/><p className="homepage_delivery_icons_mob_text">14 days easy return</p></center>
                        </div>
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/FreeDelivery.svg" className="homepage_delivery_icons_mob"/><p className="homepage_delivery_icons_mob_text">Free delivery</p></center>
                        </div>
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/fastShipping.svg" className="homepage_delivery_icons_mob"/><p className="homepage_delivery_icons_mob_text">Fast Shipping</p></center>
                        </div>
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/warranty.svg" className="homepage_delivery_icons_mob"/><p className="homepage_delivery_icons_mob_text">1 Year warranty</p></center>
                        </div>
                    </div>
                </div>
                <div className="hidden-xs">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <center>
                            <p className="homepage_uncertain_text extra_bold_text">can’t wait for your new look?</p>
                            <p className="homepage_uncertain_subtext">get your superpower delivered to your doorstep at a lightning speed</p>
                        </center>
                        <div className="col-sm-12">
                            
                            <div className="col-sm-3">
                                <center><img src="/images/Header/14DaysReturn.svg" className="homepage_delivery_icons"/><p className="homepage_delivery_subtext">14 days easy return</p></center>
                            </div>
                            <div className="col-sm-3">
                                <center><img src="/images/Header/FreeDelivery.svg" className="homepage_delivery_icons"/><p className="homepage_delivery_subtext">Free delivery</p></center>
                            </div>
                            <div className="col-sm-3">
                                <center><img src="/images/Header/fastShipping.svg" className="homepage_delivery_icons"/><p className="homepage_delivery_subtext">Fast Shipping</p></center>
                            </div>
                            <div className="col-sm-3">
                                <center><img src="/images/Header/warranty.svg" className="homepage_delivery_icons"/><p className="homepage_delivery_subtext">1 Year warranty</p></center>
                            </div>
                            
                        </div>
                    </div>
                </div>*/}
            </div>

            );
    }
}
export default HomePageDeliverySection;