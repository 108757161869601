import React, { Component } from "react";
import Header from "../Common/Header";

class Help extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            cart_count: 0,
        };
    }
	componentDidMount()
    {
        let prod_count = localStorage.getItem("prod_counts");
        if(prod_count)
        {
            var total_qty = 0;
            let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
            for(var i = 0; i<= product_id_array.length-1; i++)
            {
                if(product_id_array[i] !== 0)
                {
                    total_qty = total_qty + JSON.parse(localStorage.getItem("prod_"+product_id_array[i])).qty;
                }
            }
            this.setState({
                cart_count: total_qty,
            });
        }
    }
	render() {
		return (
	    	<div>
	    		<Header color={"#160D08"} cart_count={this.state.cart_count}/>
	    		<center>Help</center>
	    	</div>	
	    );
	}
}

export default Help;