import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProductCostSection from "./ProductCostSection";
import { Affix } from "react-bootstrap";

class ProductDetailsRightSectionWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color_border: "colour_border",
    };
    this.ChangeColor = this.ChangeColor.bind(this);
  }
  componentDidMount() {}
  ChangeColor(item_url, color_id) {
    this.props.change_color(item_url, color_id);
  }

  render() {
    return (
      <div className="col-xs-12">
        <div className="col-xs-8">
          {/* <p className="product_details_full_name_new">{this.props.product_details.short_name}</p> */}
          <p className="product_details_full_name_new">
            <small style={{ textTransform: "capitalize" }}>Rs.</small>{" "}
            {this.props.product_details.ojos_price}{" "}
            <strike className="product_details_new_striked_cost">
              <span style={{ textTransform: "capitalize" }}>Rs.</span>{" "}
              {this.props.product_details.market_price}
            </strike>
          </p>
          <p className="product_details_new_check_price">
            <b>Standard Pricing </b> *Offers available
          </p>
        </div>
        <div className="col-xs-4 footer_size_color footer_color_section">
          {this.props.product_details.colors.map((item, index) => (
            <div>
              {this.props.color_id === item.id ? (
                <div>
                  {item.sold_out == 1 ? (
                    <div className="transparent_colour_border">
                      <img
                        src="/images/Header/red_striked_circle.svg"
                        className="color_striked_img"
                        onClick={() =>
                          this.ChangeColor(
                            this.props.product_details.prod_url,
                            item.id
                          )
                        }
                      />
                      <span
                        className="colour_dot_product_details"
                        key={index}
                        style={{ backgroundColor: item.hex_code }}
                      ></span>
                    </div>
                  ) : (
                    <div className={this.state.color_border}>
                      <span
                        className="colour_dot_product_details"
                        key={index}
                        style={{ backgroundColor: item.hex_code }}
                        onClick={() =>
                          this.ChangeColor(
                            this.props.product_details.prod_url,
                            item.id
                          )
                        }
                      ></span>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {item.sold_out == 1 ? (
                    <div className="transparent_colour_border">
                      <img
                        src="/images/Header/white_striked_circle.svg"
                        className="color_striked_img"
                        onClick={() =>
                          this.ChangeColor(
                            this.props.product_details.prod_url,
                            item.id
                          )
                        }
                      />
                      <span
                        className="colour_dot_product_details"
                        key={index}
                        style={{ backgroundColor: item.hex_code }}
                      ></span>
                    </div>
                  ) : (
                    <div className="transparent_colour_border">
                      <span
                        className="colour_dot_product_details"
                        key={index}
                        style={{ backgroundColor: item.hex_code }}
                        onClick={() =>
                          this.ChangeColor(
                            this.props.product_details.prod_url,
                            item.id
                          )
                        }
                      ></span>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        <div>
          <div className="clearfix"></div>

          <div className="clearfix"></div>
          {this.props.product_details.latest == 1 ? (
            <div className="col-xs-12 product_details_new_collection_div">
              <center>
                <p className="product_details_new_collection_text">
                  New Collection - Fruity Frenzy
                  <img
                    src="/images/Header/star-svgrepo-com.svg"
                    className="product_details_new_coll_img"
                  />
                </p>
              </center>
              {/*<center><p className="product_details_new_collection_text"><img src="/images/Header/UrgencySandClockTimer.svg" className="product_details_new_coll_img"/> Going out of stock on friday. Buy your favourite now! </p></center>*/}
            </div>
          ) : null}

          <div className="col-sm-12 product_details_brand_features_section padding_is_zero">
            <div className="col-sm-3">
              <center>
                <img
                  src="/images/Header/PremiumQualityIcon.svg"
                  className="img-responsive"
                />
                <p className="homepage_why_ojoswear_subtext">
                  Premium Frame & Lens Quality
                </p>
              </center>
            </div>
            <div className="col-sm-3">
              <center>
                <img
                  src="/images/Header/PriceOffersIcon.svg"
                  className="img-responsive"
                />
                <p className="homepage_why_ojoswear_subtext">
                  Lowest Prices Guaranteed
                </p>
              </center>
            </div>
            <div className="col-sm-3">
              <center>
                <img
                  src="/images/Header/CustomerSupportIcon.svg"
                  className="img-responsive"
                />
                <p className="homepage_why_ojoswear_subtext">
                  5000+ Happy Customers
                </p>
              </center>
            </div>
            <div className="col-sm-3">
              <center>
                <img
                  src="/images/Header/SustainableEcofriendlyIcon.svg"
                  className="img-responsive"
                />
                <p className="homepage_why_ojoswear_subtext">
                  Sustainable & Eco-friendly Products
                </p>
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProductDetailsRightSectionWeb;
