import React, { Component } from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import DeliveryCheck from "../Common/DeliveryCheck/DeliveryCheck";
import ProductDescription from "./ProductDescription";
import ProductCostSection from "./ProductCostSection";
import MeasurementSection from "./MeasurementSection";
import ProductStaticFeature from "./ProductStaticFeature";
import ProductLenseFeature from "./ProductLenseFeature";
import ProductDynamicFeature from "./ProductDynamicFeature";
import Rating from "../RatingReview/Rating";

class ProductDetailsBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color_border: "colour_border",
    };
    this.ChangeColor = this.ChangeColor.bind(this);
  }
  componentDidMount() {}
  ChangeColor(item_url, color_id) {
    this.props.change_color(item_url, color_id);
  }
  render() {
    console.log(this.props.show_pdp_coupon_mob);
    return (
      <div>
        {this.props.source == "mob" ? (
          <div>
            <div className="col-xs-12 product_details_horizontal_line_up">
              <center>
                <img src="/images/Header/SlideUpLine.png" />
              </center>
            </div>
            <div className="col-xs-12">
              <div className="col-xs-8">
                <p className="product_details_full_name_new">
                  {this.props.product_details.short_name}
                  <Rating
                    reviewCount={this.props.reviews.total_reviews}
                    ratingCount={this.props.reviews.rating}
                  ></Rating>
                </p>
              </div>
              <div className="col-xs-4 colour_section">
                {this.props.product_details.colors.map((item, index) => (
                  <div>
                    {this.props.color_id === item.id ? (
                      <div>
                        {item.sold_out == 1 ? (
                          <div className="transparent_colour_border">
                            <img
                              src="/images/Header/red_striked_circle.svg"
                              className="color_striked_img"
                              onClick={() =>
                                this.ChangeColor(
                                  this.props.product_details.prod_url,
                                  item.id
                                )
                              }
                            />
                            <span
                              className="colour_dot_product_details"
                              key={index}
                              style={{ backgroundColor: item.hex_code }}
                            ></span>
                          </div>
                        ) : (
                          <div className={this.state.color_border}>
                            <span
                              className="colour_dot_product_details"
                              key={index}
                              style={{ backgroundColor: item.hex_code }}
                              onClick={() =>
                                this.ChangeColor(
                                  this.props.product_details.prod_url,
                                  item.id
                                )
                              }
                            ></span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {item.sold_out == 1 ? (
                          <div className="transparent_colour_border">
                            <img
                              src="/images/Header/white_striked_circle.svg"
                              className="color_striked_img"
                              onClick={() =>
                                this.ChangeColor(
                                  this.props.product_details.prod_url,
                                  item.id
                                )
                              }
                            />
                            <span
                              className="colour_dot_product_details"
                              key={index}
                              style={{ backgroundColor: item.hex_code }}
                            ></span>
                          </div>
                        ) : (
                          <div className="transparent_colour_border">
                            <span
                              className="colour_dot_product_details"
                              key={index}
                              style={{ backgroundColor: item.hex_code }}
                              onClick={() =>
                                this.ChangeColor(
                                  this.props.product_details.prod_url,
                                  item.id
                                )
                              }
                            ></span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="col-xs-12">
                <p
                  style={
                    this.props.source == "web"
                      ? null
                      : {
                          position: "relative",
                          top: "-11px",
                          marginBottom: "10px",
                        }
                  }
                  className="product_details_new_striked_cost"
                >
                  <s>Rs. {this.props.product_details.market_price}</s>
                </p>
              </div>
              <div className="col-xs-6">
                <p className="product_details_new_size">
                  {this.props.product_details.gender} |{" "}
                  {this.props.product_details.specifications.frame_size}
                </p>
              </div>
              <div className="col-xs-6">
                <p className="product_details_standard_text">
                  {this.props.product_details.ojos_price}
                </p>
              </div>
              <div className="clearfix"></div>
              <div className="col-xs-6">
                <Link to="/product-size">
                  <p className="product_details_new_size_guide">
                    <u>SIZE GUIDE</u>
                  </p>
                </Link>
              </div>
              <div className="col-xs-6">
                <p
                  style={
                    this.props.source === "web" ? null : { textAlign: "right" }
                  }
                  className="product_details_new_check_price"
                >
                  *Offers available
                </p>
              </div>

              <div
                className="col-md-12"
                style={{
                  marginLeft: "-3px",
                  marginTop: "15px",
                  marginBottom: "10px",
                }}
              >
                <br></br>
              </div>

              <div className="clearfix"></div>
              {this.props.product_details.latest == 1 ? (
                <div className="col-xs-12 product_details_new_collection_div">
                  <center>
                    <p className="product_details_new_collection_text">
                      New Collection - Fruity Frenzy
                      <img
                        src="/images/Header/star-svgrepo-com.svg"
                        className="product_details_new_coll_img"
                      />
                    </p>
                  </center>
                  {/*<center><p className="product_details_new_collection_text"><img src="/images/Header/UrgencySandClockTimer.svg" className="product_details_new_coll_img"/> Going out of stock on friday. Buy your favourite now! </p></center>*/}
                </div>
              ) : null}
              <div className="col-xs-12 product_details_brand_features_section">
                <div className="col-xs-3 padding_is_zero">
                  <center>
                    <img
                      src="/images/Header/PremiumQualityIcon.svg"
                      className="img-responsive"
                    />
                    <p className="homepage_why_ojoswear_subtext">
                      Premium Frame & Lens Quality
                    </p>
                  </center>
                </div>
                <div className="col-xs-3 padding_is_zero">
                  <center>
                    <img
                      src="/images/Header/PriceOffersIcon.svg"
                      className="img-responsive"
                    />
                    <p className="homepage_why_ojoswear_subtext">
                      Lowest Prices Guaranteed
                    </p>
                  </center>
                </div>
                <div className="col-xs-3 padding_is_zero">
                  <center>
                    <img
                      src="/images/Header/CustomerSupportIcon.svg"
                      className="img-responsive"
                    />
                    <p className="homepage_why_ojoswear_subtext">
                      5000+ Happy Customers
                    </p>
                  </center>
                </div>
                <div className="col-xs-3 padding_is_zero">
                  <center>
                    <img
                      src="/images/Header/SustainableEcofriendlyIcon.svg"
                      className="img-responsive"
                    />
                    <p className="homepage_why_ojoswear_subtext">
                      Sustainable & Eco-friendly Products
                    </p>
                  </center>
                </div>
              </div>
              <div className="clearfix"></div>
              <ProductCostSection
                pdp_coupon_mob={this.props.pdp_coupon_mob}
                show_pdp_coupon_mob={this.props.show_pdp_coupon_mob}
              />
              <div className="col-xs-12 product_details_feature_header">
                frame features
              </div>
              <ProductDynamicFeature
                features={this.props.product_details.features}
              />
              <ProductLenseFeature
                lense_feature={this.props.pres}
                lense_background={this.props.lense_background}
                available_power={this.props.available_power}
              />
              <div className="col-xs-12 product_details_feature_header">
                deliver in 7 - 10 working days
              </div>
              <ProductStaticFeature />
              <div className="clearfix"></div>
              <div className="delivery_section">
                <DeliveryCheck
                  product_id={this.props.product_details.product_id}
                />
              </div>
              
              <div className="col-xs-12 product_details_feature_header">
                description
              </div>
              <ProductDescription
                description={this.props.product_details.description}
              />
              <div className="col-xs-12 product_details_feature_header">
                product details
              </div>
              <MeasurementSection
                specifications={this.props.product_details.specifications}
              />
              <div className="col-xs-12 pdp_referral_div hidden-sm hidden-lg hidden-md">
                  <div className="col-xs-2" style={{paddingLeft: "5px", paddingRight: "5px"}}><img src="https://static.ojos21.com/icons/icons8-affiliate-96.png" className="img-responsive"/></div>
                  <div className="col-xs-10 product_details_cost_text_section">
                      <p className="product_details_cost_header">Refer a Friend & Get FREE OjosWear + Cashback!</p>
                      <p className="product_details_cost_subtext">Invite your friends to try our trendy eyewear using your unique referral link, and you'll both get rewarded with instant cashback & FREE eyewear. Don't miss out on this exclusive offer - <a href="/referral" target="_blank">Start Referring Now!</a></p>
                  </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* <div className="col-sm-12 product_details_new_feature_div_web">
              <div className="col-sm-3 product_details_new_feature_img">
                <img
                  src="/images/Header/FrameFeaturesImage.png"
                  className="img-responsive"
                />
              </div>
              <div className="col-sm-9 frame_feature_div_web">
                <div className="col-sm-2"></div>
                <div className="col-sm-8">
                  <p className="product_details_feature_header">
                    frame features
                  </p>
                  <ProductDynamicFeature
                    features={this.props.product_details.features}
                  />
                </div>
                <div className="col-sm-2"></div>
              </div>
            </div> */}
            <div className="clearfix"></div>
            {/* <div className="col-sm-12 product_details_description_div_web">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <p className="product_details_description_text_header_web">
                  {this.props.product_details.short_name}
                </p>
                <p className="product_details_description_text_web">
                  {this.props.product_details.description}
                </p>
              </div>
              <div className="col-sm-2"></div>
            </div> */}

            <div className="col-sm-12 product_details_new_image_div">
              {/* <div className="col-sm-5">
                <div className="delivery_section">
                  <DeliveryCheck
                    product_id={this.props.product_details.product_id}
                  />
                </div>
              </div> */}
              {/* <div className="col-sm-7">
                <div className="col-xs-12 product_details_feature_header">
                  deliver in 7 - 10 working days
                </div>
                <ProductStaticFeature />
              </div> */}
            </div>
            {/* <div className="col-sm-12 product_details_spec_div_web">
              <div className="col-sm-7">
                <div className="col-xs-12 product_details_feature_header">
                  product details
                </div>
                <MeasurementSection
                  specifications={this.props.product_details.specifications}
                />
              </div>
              <div className="col-sm-5"></div>
            </div> */}
          </div>
        )}
      </div>
    );
  }
}
export default ProductDetailsBody;
