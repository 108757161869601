import React, { Component } from "react";

class DeliveryCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product_id: this.props.product_id,
      show_delivery_msg: false,
      show_delivery_text: "",
    };
    this.CheckDelivery = this.CheckDelivery.bind(this);
  }
  CheckDelivery() {
    var pin = document.getElementById("delivery_input").value;
    let product_id = this.state.product_id;
    fetch(
      process.env.REACT_APP_API_URL +
        "/v1/delivery/product/" +
        product_id +
        "?pincode=" +
        pin
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success == true) {
          this.setState({
            show_delivery_msg: true,
            show_delivery_text: "Expected Delivery: " + data.data.delivery_date,
          });
        } else {
          this.setState({
            show_delivery_msg: true,
            show_delivery_text: data.error.message,
          });
        }
      });
  }
  render() {
    const show_delivery_msg = this.state.show_delivery_msg;
    return this.props.source === "web" ? (
      <div>
        <div className="col-xs-9 col-sm-4">
          <input
            type="text"
            placeholder="Enter Pincode"
            className="delivery_input"
            id="delivery_input"
            maxLength="6"
            autocomplete="off"
          />
        </div>
        <div className="col-xs-3 col-sm-2 check_button_section">
          <button className="check_button" onClick={() => this.CheckDelivery()}>
            Check
          </button>
        </div>
        <div className="col-xs-12">
          {show_delivery_msg ? (
            <p className="delivery_expect_text delivery_time_text">
              {this.state.show_delivery_text}
            </p>
          ) : (
            <p></p>
          )}
        </div>
        <div className="clearfix"></div>
      </div>
    ) : (
      <div>
        <div className="col-xs-9 col-sm-4">
          <input
            type="text"
            placeholder="Enter Pincode"
            className="delivery_input"
            id="delivery_input"
            maxLength="6"
            autocomplete="off"
            style={{ fontSize: "12px" }}
          />
        </div>
        <div className="col-xs-3 col-sm-2 check_button_section">
          <button
            className="check_button"
            style={{ fontSize: "12px" }}
            onClick={() => this.CheckDelivery()}
          >
            Check
          </button>
        </div>
        <div className="col-xs-12">
          {show_delivery_msg ? (
            <p className="delivery_expect_text delivery_time_text">
              {this.state.show_delivery_text}
            </p>
          ) : (
            <p></p>
          )}
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default DeliveryCheck;
/*
{delivery_date ? (
				        <p className="delivery_time_text">{this.state.delivery_date}</p>
				    ) : (
				        <p></p>
				    )}*/
