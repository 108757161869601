import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import swal from 'sweetalert';

class CartItems extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
        	products : this.props.products,
        	redirect: false,
        	redirectUrl: "/login",
        	gender: '',
        	path_source: this.props.path_source,
        	width: window.innerWidth,
        }
        this.ProceedPay = this.ProceedPay.bind(this);
    }
    componentDidMount()
	{
		var path_source = this.props.path_source;
		if(path_source == "female_listing")
		{
			var gender = path_source.substring(0,6);
			this.setState({ gender: gender});
		}
		if(path_source == "male_listing")
		{
			var gender = path_source.substring(0,4);
			this.setState({ gender: gender});
		}
	}
	componentWillMount() 
  	{
	  	window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount() {
	  	window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};
    NoProduct()
    {
    	swal("Add Products to Proceed");
    }
    RedirectBack()
	{
		window.history.back();
	}
	ProceedPay()
	{
		var token = localStorage.getItem("token");
		if(token)
		{
			let data = [];
			let sub_data = [];
			sub_data = JSON.parse(localStorage.getItem("sub_data")); //Passing Subscription details to backend
			
			const { width } = this.state;
	  		const isMobile = width <= 768;
	  		if(isMobile)
	  		{
	  			var device = "mobile";
	  		}
	  		else
	  		{
	  			var device = "desktop";
	  		}
			var myHeaders = new Headers();
		    myHeaders.append("Authorization", "Bearer "+ token);
		    myHeaders.append("User-Source", device);

	        if(this.props.cart_count > this.props.credits)
	        {
	        	data.push(sub_data);
	        	data = JSON.stringify({'cart_data' : data});
	        	let url = process.env.REACT_APP_API_URL+'/v1/cart';
	        	var requestOptions = {
		            method: 'POST',
		            headers: myHeaders,
		            body: data,
		            redirect: 'follow'
	        	};
	        	fetch(url, requestOptions)
		        .then(response => response.json())
				
		        .then(data => 
		        	this.setState({
	                redirect: true,
	                redirectUrl:'/checkout',
	            	})
		        )
	        }
	        else
	        {
	        	/*sub_data = JSON.parse(localStorage.getItem("sub_data")); //Passing Subscription details to backend
				sub_data.qty = 0;
				data.push(sub_data);
	        	data = JSON.stringify({'cart_data' : data});
	        	let url = process.env.REACT_APP_API_URL+'/v1/cart';
				var requestOptions = {
		            method: 'POST',
		            headers: myHeaders,
		            body: data,
		            redirect: 'follow'
		        };
	        	fetch(url, requestOptions)
		        .then(response => response.json())
				
		        .then(data => 
		        	
		        )*/
		        this.setState({
	                redirect: true,
	                redirectUrl:'/checkout',
	            	})
	        }
		}
		else
		{
			//this.setState({ redirectUrl: window.location.pathname + window.location.search});
			var redirectUrl = '/login?redirect_url='+window.location.pathname + window.location.search;
			this.setState({
        		redirect: true,
        		redirectUrl: redirectUrl,
    		})
		}
	}
    render() { 
    		
		    	
    		if(this.state.redirect){
            return <Redirect to={this.state.redirectUrl} />
        	}
			return(
				<div>
	            	<div className="col-sm-3"></div>
			    	<div className="col-xs-12 col-sm-6 cart_footer_web">
			    		<center><p className="cart_know_price_text">Know the final amount at the next step</p></center>
				    	<div className="col-xs-6 continue_footer">
			    			{this.state.gender == '' ?
			    				<a href="/"><center><u className="continue_text">Continue Shopping</u></center></a>
			    				:
			    				<a href={"/products/eyeglasses/"+this.state.gender+"?path_source="+this.props.path_source}><center><u className="continue_text">Continue Shopping</u></center></a>
							}
			    		</div>
			    		{this.props.cart_count  > 0 ?
				    		<div className="col-xs-6">
				    			<center><button className="proceed_to_pay_button" onClick={this.ProceedPay}>Unlock offer</button></center>
				    		</div>
				    		:
				    		<div className="col-xs-6">
				    			<center><button onClick={this.NoProduct} className="proceed_to_pay_button_disable">Proceed To Pay</button></center>
				    		</div>
				    	}
			    	</div>
			    	<div className="col-sm-3"></div>
		    	</div>
			);
    }
}

export default CartItems;
