import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class OrderTracker extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            
        };
        
    }
    render() { 
              return(
                   <div>
                        <div className="col-sm-3"></div>
                        <div className="col-xs-12 col-sm-6 order_product_power">
                            {this.props.tracker.map((each_item, index) => (
                                <div>
                                    {this.props.tracker.length-1 == index ?
                                        <div>
                                            <div className="col-xs-12 colored_track_div">
                                                <img src="/images/Header/Track_Order_Check_Mark_Inverted_Color.svg" className="order_track_icon"/><p className="order_track_text_active">{each_item.status}<p className="order_track_subtext_active"> on {each_item.date}</p></p>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="col-xs-12 noncolored_track_div">
                                                <img src="/images/Header/Track_Order_Check_Mark.svg" className="order_track_icon"/><p className="order_track_text">{each_item.status}<p className="order_track_subtext"></p><p className="order_track_subtext"> on {each_item.date}</p></p>
                                            </div>
                                            <div className="col-xs-12 track_vertical_line1"></div>
                                        </div>
                                    }
                                    
                                </div>
                            ))}
                        </div>
                        <div className="col-sm-3"></div>
                    </div> 
			);
    }
}

export default OrderTracker;
/*
{this.props.tracker.length == 1 ?
                            <div>
                                <div className="col-sm-3"></div>
                                <div className="col-xs-12 col-sm-6 order_product_power">
                                    <div className="col-xs-12 noncolored_track_div">
                                        <img src="/images/Header/Track_Order Check_Mark_Inactive.svg" className="order_track_icon"/><p className="order_track_text">Delivered<p className="order_track_subtext"></p></p>
                                    </div>
                                    <div className="col-xs-12 track_vertical_line1"></div>
                                    <div className="col-xs-12 noncolored_track_div ">
                                        <img src="/images/Header/Track_Order Check_Mark_Inactive.svg" className="order_track_icon"/><p className="order_track_in_transit_inactive">In Transit</p>
                                    </div>
                                    <div className="col-xs-12 track_vertical_line2"></div>
                                    <div className="col-xs-12 colored_track_div">
                                        <img src="/images/Header/Track_Order_Check_Mark_Inverted_Color.svg" className="order_track_icon"/><p className="order_track_text_active">Order Placed <p className="order_track_subtext_active"> on {this.state.product_details.ordered_on}</p></p>
                                    </div>
                                </div>
                            </div>
                            : 
                            <div>
                                {this.props.tracker.length == 2 ?
                                    <div>
                                        <div className="col-sm-3"></div>
                                        <div className="col-xs-12 col-sm-6 order_product_power">
                                            <div className="col-xs-12 noncolored_track_div">
                                                <img src="/images/Header/Track_Order Check_Mark_Inactive.svg" className="order_track_icon"/><p className="order_track_text">Delivered<p className="order_track_subtext"></p></p>
                                            </div>
                                            <div className="col-xs-12 track_vertical_line1"></div>
                                            <div className="col-xs-12 colored_track_div  ">
                                                <img src="/images/Header/Track_Order_Check_Mark_Inverted_Color.svg" className="order_track_icon"/><p className="order_track_in_transit">In Transit</p>
                                            </div>
                                            <div className="col-xs-12 track_vertical_line2"></div>
                                            <div className="col-xs-12 noncolored_track_div">
                                                <img src="/images/Header/Track_Order_Check_Mark.svg" className="order_track_icon"/><p className="order_track_text">Order Placed <p className="order_track_subtext"> on {this.state.product_details.ordered_on}</p></p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                    {this.props.tracker.length == 3 ?
                                        <div>
                                            <div className="col-sm-3"></div>
                                            <div className="col-xs-12 col-sm-6 order_product_power">
                                                <div className="col-xs-12 colored_track_div ">
                                                    <img src="/images/Header/Track_Order_Check_Mark_Inverted_Color.svg" className="order_track_icon"/><p className="order_track_text_active">Delivered<p className="order_track_subtext"></p></p>
                                                </div>
                                                <div className="col-xs-12 track_vertical_line1"></div>
                                                <div className="col-xs-12 noncolored_track_div  ">
                                                    <img src="/images/Header/Track_Order_Check_Mark.svg" className="order_track_icon"/><p className="order_track_in_transit_inactive">In Transit</p>
                                                </div>
                                                <div className="col-xs-12 track_vertical_line2"></div>
                                                <div className="col-xs-12 noncolored_track_div">
                                                    <img src="/images/Header/Track_Order_Check_Mark.svg" className="order_track_icon"/><p className="order_track_text">Order Placed <p className="order_track_subtext"> on {this.state.product_details.ordered_on}</p></p>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="col-sm-3"></div>
                                            <div className="col-xs-12 col-sm-6 order_product_power">
                                                <div className="col-xs-12 colored_track_div ">
                                                    <img src="/images/Header/Track_Order_Check_Mark_Inverted_Color.svg" className="order_track_icon"/><p className="order_track_text_active">Closed<p className="order_track_subtext"></p></p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    }
                                    </div>
                                }
                            </div>
                        }*/