import React, { Component } from "react";
import { Link } from "react-router-dom";
import Lottie from 'react-lottie';
import ARAnimation from '../Lotties/ar_try_on.json';

class DesktopHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: "MALE",
      url_path: "",
    };
    this.Filters = this.Filters.bind(this);
  }

  componentDidMount() {
    var url_path = window.location.pathname;
    var gender = url_path.substring(21);
    if (window.location.href.indexOf("sunglasses") > -1) {
      var gender = "sunglasses";
    }
    if (window.location.href.indexOf("collection") > -1) {
      var gender = "collection";
    }
    this.setState({
      gender: gender,
      url_path: url_path,
    });
  }
  Filters() {
    this.props.filters();
  }
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: ARAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const styles = {
        scrollArrowAnimation: {
            margin: '0px',
        },
    }

    return (
      <div>
          {this.state.gender == "sunglasses" ? 
            <div>
              {this.props.show_pl_header_sunglasses ? 
                <div className="col-sm-12 header_first_section padding_is_zero hidden-xs">
                  {this.props.pl_header_sunglasses.map((details, index) => (
                    <div>
                      <div className="col-sm-5 header_first_section_left">
                        <h1>{details.banner_title}</h1>
                        <h5>{details.banner_desc}</h5>
                      </div>
                      <div className="col-sm-2"></div>
                      <div className="col-sm-5 padding_is_zero desktop_product_header_img">
                        <img
                          src={details.banner_for_desktop}
                          className="img-responsive"
                          width="100%"
                        />
                      </div>
                    </div>
                    :
                    null
                    ))}
                </div>
                :
                null
              }
            </div>
            :
            [
              <div>
              {this.state.gender == "male" ? 
                <div>
                {this.props.show_pl_header_men ? 
                  <div className="col-sm-12 header_first_section padding_is_zero hidden-xs">
                    {this.props.pl_header_men.map((details, index) => (
                      <div>
                        <div className="col-sm-5 header_first_section_left">
                          <h1>{details.banner_title}</h1>
                          <h5>{details.banner_desc}</h5>
                        </div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-5 padding_is_zero desktop_product_header_img">
                          <img
                            src={details.banner_for_desktop}
                            className="img-responsive"
                            width="100%"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  :
                  null
                }
                </div>
                :
                [ 
                  <div>
                    {this.state.gender == "female" ? 
                      <div>
                        {this.props.show_pl_header_women ? 
                          <div className="col-sm-12 header_first_section padding_is_zero hidden-xs">
                            {this.props.pl_header_women.map((details, index) => (
                              <div>
                                <div className="col-sm-5 header_first_section_left">
                                  <h1>{details.banner_title}</h1>
                                  <h5>{details.banner_desc}</h5>
                                </div>
                                <div className="col-sm-2"></div>
                                <div className="col-sm-5 padding_is_zero desktop_product_header_img">
                                  <img
                                    src={details.banner_for_desktop}
                                    className="img-responsive"
                                    width="100%"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          :
                          null
                        }
                      </div>
                      :
                      [
                        <div>
                          {this.state.gender == "collection" ? 
                            <div>
                              {this.props.show_pl_collection_banner ? 
                                <div className="col-sm-12 header_first_section padding_is_zero hidden-xs">
                                  {this.props.pl_collection_banner.map((details, index) => (
                                    <div>
                                      <div className="col-sm-5 header_first_section_left">
                                        <h1>{details.banner_title}</h1>
                                        <h5>{details.banner_desc}</h5>
                                      </div>
                                      <div className="col-sm-2"></div>
                                      <div className="col-sm-5 padding_is_zero desktop_product_header_img">
                                        <img
                                          src={details.banner_for_desktop}
                                          className="img-responsive"
                                          width="100%"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                :
                                null
                              }
                            </div>
                            :
                            <div>
                              <div>
                                {this.props.show_pl_header_collection ? 
                                  <div className="col-sm-12 header_first_section padding_is_zero hidden-xs">
                                    {this.props.pl_header_collection.map((details, index) => (
                                      <div>
                                        <div className="col-sm-5 header_first_section_left">
                                          <h1>{details.banner_title}</h1>
                                          <h5>{details.banner_desc}</h5>
                                        </div>
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-5 padding_is_zero desktop_product_header_img">
                                          <img
                                            src={details.banner_for_desktop}
                                            className="img-responsive"
                                            width="100%"
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  :
                                  null
                                }
                              </div>

                          </div>
                          }
                        </div>
                      ]
                      
                    }
                  </div>
                ]
              }
              </div>
            ]
          }
        
        {/*{this.state.gender == "sunglasses" ? (
          <div className="col-sm-12 header_first_section padding_is_zero hidden-xs">
            <img
              src="/images/Header/Desktop_ListingHeader.jpg"
              alt="Shady Street"
              width="100%"
            />
          </div>
        ) : (
          <div className="col-sm-12 header_first_section padding_is_zero hidden-xs">
            <div className="col-sm-5 header_first_section_left">
              {this.state.gender == "male" ? (
                <div>
                  <h4>MEN'S EYEGLASSES</h4>
                  <h1>The New OJOS - "Candy Rush" Collection</h1>
                  <h5>
                    All you heroes out there, get set for a groovy ride. Taking
                    you down the silver screen, that bollywood for you, sit
                    tight. Choose from a range of freaky, stylish and quirky
                    collections, curated just for you! It's time to jazz up your
                    work and play days.
                  </h5>
                </div>
              ) : (
                [
                  <div>
                    {this.state.gender == "female" ? (
                      <div>
                        <h4>WOMEN'S EYEGLASSES</h4>
                        <h1>The New OJOS - "Candy Rush" Collection</h1>
                        <h5>
                          All you reigning queens of Bollywood, it's time to
                          unleash your stylish side. Let's take you to a world
                          of drama, sazz and all that filmy jazz. Play with
                          colors or wear it sleek, its time to look chic!
                        </h5>
                      </div>
                    ) : (
                      <div>
                        <h4>OJ Picks</h4>
                        <h1>
                          Candy Rush Collection: Where your sweet dreams come
                          true
                        </h1>
                        <h5>
                          And we make it so much more fun. Experience the taste
                          of heaven and a morsel of yum with Candy Rush.
                        </h5>
                      </div>
                    )}
                  </div>,
                ]
              )}
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-5 padding_is_zero desktop_product_header_img">
              {this.state.gender == "male" ? (
                <img
                  src="/images/Header/DesktopMenListing.jpg"
                  alt="men_image"
                  className="img-responsive"
                />
              ) : (
                [
                  <div>
                    {this.state.gender == "female" ? (
                      <img
                        src="/images/Header/DesktopWomenListing.jpg"
                        alt="women_image"
                        className="img-responsive"
                      />
                    ) : (
                      <img
                        src="/images/Header/Desktop_FeaturedListing.jpg"
                        alt="featured_image"
                        className="img-responsive"
                      />
                    )}
                  </div>,
                ]
              )}
            </div>
          </div>
        )}*/}

        {this.state.url_path != "/products/eyeglasses/featured" &&
        this.state.url_path != "/products/sunglasses" ? (
          <div>
          {this.props.show_pl_current_collection_banner_mob ? 
            <div>
              {this.props.pl_current_collection_banner_mob.map((details, index) => (
                <div className="col-sm-12 hidden-sm hidden-md hidden-lg padding_is_zero">
                  <a href="/products/eyeglasses/featured">
                    <img
                      src={details.banner_for_mobile_web}
                      className="homepage_buy3_banner_mob"
                    />
                  </a>
                </div>
              ))}
            </div>
            :
            null
          }
          </div>
        ) : null}

        <div className="clearfix"></div>

        {this.props.show_pl_coupon_banner_web ? 
          <div>
            {this.props.pl_coupon_banner_web.map((details, index) => (
              <div className="col-sm-12 hidden-xs padding_is_zero">
                <img
                  src={details.banner_for_desktop}
                  className="img-responsive"
                  width="100%"
                />
              </div>
            ))}
          </div>
          :
          null
        }

        {this.props.show_pl_coupon_banner_mob ? 
          <div>
            {this.props.pl_coupon_banner_mob.map((details, index) => (
              <div className="col-xs-12 padding_is_zero hidden-lg hidden-md hidden-sm">
                <br/>
                <img
                  src={details.banner_for_mobile_web}
                  className="img-responsive"
                  width="100%"
                />
              </div>
            ))}
          </div>
            :
            null
          }

        {this.state.url_path != "/products/eyeglasses/featured" &&
        this.state.url_path != "/products/sunglasses" ? (
          <div>
            {this.props.show_pl_current_collection_banner_web ? 
              <div>
                {this.props.pl_current_collection_banner_web.map((details, index) => (
                  <div className="col-sm-12 hidden-xs">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8 padding_is_zero product_listing_pride_collection_section">
                      <a href="/products/eyeglasses/featured">
                        <img
                          src={details.banner_for_desktop}
                          className="homepage_buy3_banner_mob"
                        />
                      </a>
                    </div>
                    <div className="col-sm-2"></div>
                  </div>
                ))}
              </div>
              :
              null
            }
          </div>
        ) : null}
        <div className="clearfix"></div>

        {this.state.gender == "sunglasses" ? (
          <div className="col-sm-12 web_breadcrumb hidden-xs">
            <Link to="/" className="web_breadcrumb_text">
              <p>HOME</p>
            </Link>
            <p className="web_breadcrumb_text"> | SUNGLASSES </p>
          </div>
        ) : (
          <div className="col-sm-12 web_breadcrumb hidden-xs">
            <Link to="/" className="web_breadcrumb_text">
              <p>HOME</p>
            </Link>
            <p className="web_breadcrumb_text"> | EYEGLASSES - </p>
            <Link
              to={"/products/eyeglasses/" + this.state.gender.toLowerCase()}
              className="web_breadcrumb_text"
            >
              <p>{this.state.gender}</p>
            </Link>
            {/*<p className="total_product_text">{this.props.count} Items</p>*/}
          </div>
        )}

        <div className="col-sm-12 web_breadcrumb web_breadcrumb_mob hidden-sm hidden-md hidden-lg padding_is_zero">
          <Link to="/" className="web_breadcrumb_text">
            <p className="p_margin_bottom_zero">HOME</p>
          </Link>
          <p className="web_breadcrumb_text"> | EYEGLASSES -</p>
          <Link
            to={"/products/eyeglasses/" + this.state.gender.toLowerCase()}
            className="web_breadcrumb_text"
          >
            <p className="p_margin_bottom_zero">{this.state.gender}</p>
          </Link>
          {/*<p className="total_product_text">{this.props.count} Items</p>*/}
            
          {!this.props.show_applied_filters ? (
            <img
              src="/images/Header/FilterIcon.jpg"
              className="products_filter_icon"
              onClick={this.Filters}
            />
          ) : (
            <img
              src="/images/Header/FilterAppliedIcon.jpg"
              className="products_filter_icon"
              onClick={this.Filters}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DesktopHead;
