import React, { Component } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { Link } from 'react-router-dom';
import store from '../../Redux/store';
import ProductTextArea from './ProductTextArea';
import ProductTextAreaDesktop from './ProductTextAreaDesktop'
import PageFooter from './PageFooter';
import Footer from '../Common/Footer';
import DesktopHead from './DesktopHead';
import ImageView from './ImageView';
import ImageViewDesktop from './ImageViewDesktop';
import ImageViewNew from './ImageViewNew';
import ProductTextAreaNew from './ProductTextAreaNew';
import ProductListingLastSlide from './ProductListingLastSlide';
import ProductListingFilters from './ProductListingFilters';
import InfiniteScroll from 'react-infinite-scroll-component';
import swal from 'sweetalert';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';
import Loading from "../Common/Loading";

class ProductPage extends Component 
{
 	constructor(props) {
    	super(props);
    	this.state = {
    		primary_image: [],
    		secondary_image: [],
    		theme_color: [],
    		lenskart_product_id: [],
    		product_count: '',
    		product_details: [{model_images:[], glass_images:[]}],
    		color: '',
    		width: window.innerWidth,
    		show_desc: false,
    		path_source: '',
    		page_index: 0,
    		show_loading_lottie: 1,
    		loading_lottie_web: 1,
    		choosen_index: 0,
    		items: Array.from({ length: 20 }),
    		current_page: '',
    		total_product: '',
    		total_pages: '',
    		per_page: '',
    		gender: '',
    		next_page_url: '',
    		last_page_url: '',
    		last_page: 1,
    		show_filters: false,
    		filters: [],
    		show_products: false,
    		filter_last_page: '',
    		show_loading_filter: false,
    		show_applied_filters: false,
    		show_no_product: false,
    		banners: [],
    		pl_coupon_banner_web: [],
    		show_pl_coupon_banner_web: false,
    		pl_coupon_banner_mob: [],
    		show_pl_coupon_banner_mob: false,
    		pl_header_men: [],
    		show_pl_header_men: false,
    		pl_header_women: [],
    		show_pl_header_women: false,
    		pl_header_collection: [],
    		show_pl_header_collection: false,
    		pl_header_sunglasses: [],
    		show_pl_header_sunglasses: false,
    		collection_value: '', 
    		collection_page: false,
    		pl_collection_banner: [],
    		show_pl_collection_banner: false,
    		pl_current_collection_banner_web: [],
    		show_pl_current_collection_banner_web: false,
    		pl_current_collection_banner_mob: [],
    		show_pl_current_collection_banner_mob: false,
    	};
    	this.ChangePicture = this.ChangePicture.bind(this);
    	this.ChangeColour = this.ChangeColour.bind(this);
    	this.ChangeColor = this.ChangeColor.bind(this);
    	this.onLeave = this.onLeave.bind(this);
    	this.afterLoad = this.afterLoad.bind(this);
    	this.AvailablePower = this.AvailablePower.bind(this);
    	this.Filters = this.Filters.bind(this);
    	this.ApplyFilters = this.ApplyFilters.bind(this);
    	this.OnlyFilters = this.OnlyFilters.bind(this);
    	this.UpdateOnlyFilters = this.UpdateOnlyFilters.bind(this);
    	this.AppliedFiltersIconChange = this.AppliedFiltersIconChange.bind(this);
  	}

/*
  	componentWillMount() 
  	{
	  window.addEventListener('resize', this.handleWindowSizeChange);
	}
*/
	componentWillUnmount() {
	  window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
	
	  this.setState({ width: window.innerWidth });
	};
	onLeave(origin, destination, direction) {
	    //console.log("Leaving section " + origin.index);
	}
	afterLoad(origin, destination, direction) {
	    //console.log("After load: " + destination.index);
	    this.setState({page_index: destination.index})
	}
	ChangeColour(item_id,color_id,i)
	{
		/*let url = process.env.REACT_APP_API_URL+'/v1/products/'+item_id+'?color_id='+color_id;
		fetch(url)
        .then(response => response.json())
        .then(products => {
        	let product_details_new = this.state.product_details;
        	product_details_new[i].model_images = products.result.products[i].model_images;
  			product_details_new[i].glass_images = products.result.products[i].glass_images;
        	//this.setState({product_details: product_details_new})
		}); */
	}
  	ChangePicture(i)
  	{
  		let product_details_new = this.state.product_details;
  		let glass_images_array = this.state.product_details[i].glass_images;
  		let model_images_array = this.state.product_details[i].model_images;
  		product_details_new[i].model_images = glass_images_array;
  		product_details_new[i].glass_images = model_images_array;

  		this.setState({product_details: product_details_new, show_loading_lottie: 0})
  	}
  	componentDidMount()
  	{
  		window.addEventListener('resize', this.handleWindowSizeChange);

  		if(window.location.pathname == "/products/eyeglasses/female")
        {
            var gender = window.location.pathname.substring(21, 27)
            var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname +'?path_source='+gender+'_listing'+ window.location.hash;    
            //window.history.pushState({ path: refresh }, '', refresh);
            this.setState({ path_source: gender+'_listing', gender: gender});
        }
        if(window.location.pathname == "/products/eyeglasses/male")
        {
            var gender = window.location.pathname.substring(21, 25)
            var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname +'?path_source='+gender+'_listing'+ window.location.hash;    
            //window.history.pushState({ path: refresh }, '', refresh);
            this.setState({ path_source: gender+'_listing', gender: gender});
        }

        var url_path = window.location.pathname;
	    if(url_path.indexOf("collection") > -1) 
	    {
	    	var collection_value = url_path.substring(32);
	    	var collection_page = true;
	    	this.setState({ collection_value: decodeURIComponent(collection_value) , collection_page: collection_page });
	    }

  		if (window.location.href.indexOf('frame_type') > -1 || window.location.href.indexOf('frame_shape') > -1 || window.location.href.indexOf('frame_size') > -1 || window.location.href.indexOf('frame_colour') > -1 || window.location.href.indexOf('frame_material') > -1)
		{ 
			//this.CallFiltersLoadMore(window.location.search);
			const state = store.getState();
			let product_details = state.products.items.products;
			let banners = state.products.items.banners;
		  	let total_pages = Math.ceil(state.products.items.total_product_count/state.products.items.per_page);
		  	var show_products = state.products.items.total_product_count > 0 ? true : false;
		  	

		  		this.setState({
		  			product_count: state.products.items.total_product_count, 
		  			product_details: product_details,
		  			banners: banners,
		  			current_page: state.products.items.current_page, 
		  			total_product: state.products.items.total_product_count, 
		  			per_page: state.products.items.per_page, 
		  			total_pages: total_pages,
		  			show_products: show_products,
		  			show_applied_filters: true,
		  		});
			
		}
		else
		{
			const state = store.getState();
	  		let product_details = state.products.items.products;
	  		let banners = state.products.items.banners;
	  		let total_pages = Math.ceil(state.products.items.total/state.products.items.per_page);
	  		var show_products = state.products.items.product_count > 0 ? true : false;

	  		this.setState({
	  			product_count: state.products.items.product_count, 
	  			product_details: product_details,
	  			banners: banners,
	  			current_page: state.products.items.current_page, 
	  			total_product: state.products.items.total, 
	  			per_page: state.products.items.per_page, 
	  			total_pages: total_pages,
	  			last_page_url: state.products.items.last_page_url,
	  			next_page_url: state.products.items.next_page_url,
	  			show_products: show_products,
	  			//primary_image: state.products.items.products[0].model_images, 
	  			//secondary_image: state.products.items.products[0].glass_images
	  		}, () => {
	  			if(show_products)
	  			{
	  				this.SetThemeColor(state);
		    		this.SetProductId(state);
		    		this.SetLastPage(state);
		    		this.FetchingBanner();
	  			}
		    	
			});
		}
		this.OnlyFilters();
  	}
  	SetThemeColor(state)
  	{
  		var theme_color = this.state.theme_color;
  		let product_details = this.state.product_details;
    	for(var i=0; i <= this.state.product_count-1; i++)
  		{
  			theme_color.push(product_details[i].theme_color);
  		}
  		this.setState({
  			theme_color: theme_color, loading_lottie_web: 0
  		});
  	}
  	SetProductId(state)
  	{
  		var lenskart_product_id = this.state.lenskart_product_id;
  		let product_details = this.state.product_details;
    	for(var i=0; i <= this.state.product_count-1; i++)
  		{
  			lenskart_product_id.push(String(product_details[i].lenskart_prod_id));
  		}
  		this.setState({
  			lenskart_product_id: lenskart_product_id
  		});
  	}
  	SetLastPage(state)
  	{

		let urlString = state.products.items.last_page_url;
        let paramString = urlString.split('?')[1];
        let params_arr = paramString.split('&');
        var last_page = '';
        for(let i = 0; i < params_arr.length; i++) {
            let pair = params_arr[i].split('=');
            if(pair[0] == 'page')
            {
            	last_page = pair[1];
            }
        }
        this.setState({
  			last_page: last_page
  		});
  	}
  	FetchingBanner()
  	{
	    var pl_header_men = this.state.pl_header_men;
	    var show_pl_header_men = this.state.show_pl_header_men;
	    var pl_header_women = this.state.pl_header_women;
	    var show_pl_header_women = this.state.show_pl_header_women;
	    var pl_header_collection = this.state.pl_header_collection;
	    var show_pl_header_collection = this.state.show_pl_header_collection;
	    var pl_header_sunglasses = this.state.pl_header_sunglasses;
	    var show_pl_header_sunglasses = this.state.show_pl_header_sunglasses;
	    var pl_coupon_banner_web = this.state.pl_coupon_banner_web;
	    var show_pl_coupon_banner_web = this.state.show_pl_coupon_banner_web;
	    var pl_coupon_banner_mob = this.state.pl_coupon_banner_mob;
	    var show_pl_coupon_banner_mob = this.state.show_pl_coupon_banner_mob;
	    var pl_collection_banner = this.state.pl_collection_banner;
	    var show_pl_collection_banner = this.state.show_pl_collection_banner;
	    var pl_current_collection_banner = this.state.pl_current_collection_banner;
	    var show_pl_current_collection_banner = this.state.show_pl_current_collection_banner;
	    var pl_current_collection_banner_web = this.state.pl_current_collection_banner_web;
	    var show_pl_current_collection_banner_web = this.state.show_pl_current_collection_banner_web;
	    var pl_current_collection_banner_mob = this.state.pl_current_collection_banner_mob;
	    var show_pl_current_collection_banner_mob = this.state.show_pl_current_collection_banner_mob;

	    for(var i = 0; i<this.state.banners.length; i++)
	    {
	        if(this.state.banners[i].banner_type == "Eyeglasses Listing page - header_men" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_header_men.push(this.state.banners[i]);
	            show_pl_header_men = true;
	        }
	        if(this.state.banners[i].banner_type == "Eyeglasses Listing page - header_female" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_header_women.push(this.state.banners[i]);
	            show_pl_header_women = true;
	        }
	        if(this.state.banners[i].banner_type == "Eyeglasses Listing Page - header_collection" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_header_collection.push(this.state.banners[i]);
	            show_pl_header_collection = true;
	        }
	        if(this.state.banners[i].banner_type == "Sunglasses Listing Page - header_collection" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_header_sunglasses.push(this.state.banners[i]);
	            show_pl_header_sunglasses = true;
	        }
	        if(this.state.banners[i].banner_type == "Coupon Banner - PL" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_coupon_banner_web.push(this.state.banners[i]);
	            show_pl_coupon_banner_web = true;
	        }
	        if(this.state.banners[i].banner_type == "Coupon Banner - PL" && this.state.banners[i].banner_for_mobile_web != "")
	        {
	            pl_coupon_banner_mob.push(this.state.banners[i]);
	            show_pl_coupon_banner_mob = true;
	        }
	        if(this.state.banners[i].banner_type == this.state.collection_value && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_collection_banner.push(this.state.banners[i]);
	            show_pl_collection_banner = true;
	        }
	        
	        if(this.state.banners[i].banner_type == "Current Collection Banner - PL" && this.state.banners[i].banner_for_desktop != "")
	        {
	            pl_current_collection_banner_web.push(this.state.banners[i]);
	            show_pl_current_collection_banner_web = true;
	        }
	        if(this.state.banners[i].banner_type == "Current Collection Banner - PL" && this.state.banners[i].banner_for_mobile_web != "")
	        {
	            pl_current_collection_banner_mob.push(this.state.banners[i]);
	            show_pl_current_collection_banner_mob = true;
	        }
	    }
	    this.setState({ pl_header_men: pl_header_men, show_pl_header_men: show_pl_header_men, 
	    	pl_header_women: pl_header_women, show_pl_header_women: show_pl_header_women,
	    	pl_header_collection: pl_header_collection, show_pl_header_collection: show_pl_header_collection,
	    	pl_header_sunglasses: pl_header_sunglasses, show_pl_header_sunglasses: show_pl_header_sunglasses,
	    	pl_coupon_banner_web: pl_coupon_banner_web, show_pl_coupon_banner_web: show_pl_coupon_banner_web, 
	        pl_coupon_banner_mob: pl_coupon_banner_mob, show_pl_coupon_banner_mob: show_pl_coupon_banner_mob,
	        pl_collection_banner: pl_collection_banner, show_pl_collection_banner: show_pl_collection_banner,
	        pl_current_collection_banner_web: pl_current_collection_banner_web, show_pl_current_collection_banner_web: show_pl_current_collection_banner_web,
	        pl_current_collection_banner_mob: pl_current_collection_banner_mob, show_pl_current_collection_banner_mob: show_pl_current_collection_banner_mob,
	    })
    }
  	ChangeColor(item_url,color_id,i)
	{	
		this.setState({ loading_lottie_web: 1, choosen_index: i});
		let product_details_new = this.state.product_details;
		//product_details_new[i].model_images = [];
        //product_details_new[i].glass_images = [];
        
		if(this.state.path_source != null)
		{
			if(this.state.path_source=="male_listing")
			{
				var gender = this.state.path_source.substring(0, 4);
			}
			else
			{
				var gender = this.state.path_source.substring(0, 6);
			}
			var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id+"?gender="+gender;
		}
		else
		{
			var gender = "";
			var url = process.env.REACT_APP_API_URL+'/v1/product/'+item_url+'/colorid/'+color_id;
		}

		fetch(url)
        .then(response => response.json())
        .then(products => {
        	let product_details_new = this.state.product_details;
        	product_details_new[i].model_images = products.result.products.model_images;
  			product_details_new[i].glass_images = products.result.products.glass_images;
  			product_details_new[i].specifications.color_prod_id = products.result.products.specifications.color_prod_id;
  			product_details_new[i].sold_out = products.result.products.sold_out;
        	this.setState({product_details: product_details_new,  color_border: 'colour_border'},()=> this.setState({loading_lottie_web: 0}))
		});	 
    }
	fetchMoreData = () => {
		if (window.location.href.indexOf('frame_type') > -1 || window.location.href.indexOf('frame_shape') > -1 || window.location.href.indexOf('frame_size') > -1 || window.location.href.indexOf('frame_colour') > -1 || window.location.href.indexOf('frame_material') > -1)
		{ 	
			//URL should have "frame_type,frame_shape,frame_size,frame_color,frame_material" in it
			console.log(window.location.search)
			this.CallFiltersLoadMore(window.location.search);
		}
		else
		{
			
			var current_page = this.state.current_page;
			var total_product = this.state.total_product;
			var total_pages = this.state.total_pages;
			var per_page = this.state.per_page;
			var gender = this.state.gender;
			var next_page = current_page+1;
			var last_page = this.state.last_page
			console.log("LAST PAGE "+last_page);
			if(next_page <= last_page)
			{
				var url = process.env.REACT_APP_API_URL+"/v2/products?type=old-listing&gender="+gender+"&page="+next_page+"&per_page="+per_page; 
				fetch(url)
		        .then(response => response.json())
		        .then(products => {
		        	console.log(products);
		        	this.setState({ product_details: this.state.product_details.concat(products.result.products), current_page: products.result.current_page });
		        });
			}
		}
		
    
  	};
  	AvailablePower(color_id)
	{
		var span = document.createElement("span");
        span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><center><div class='loader'></div></center></div>";
		swal({
		    content: span,
		    buttons: false
		});
		let url_power = process.env.REACT_APP_API_URL+'/v1/product/'+color_id+'/power';
		fetch(url_power)
        .then(response => response.json())
        
        .then(data => {
        	console.log(data);
        	if(data.success == true)
        	{
        		var cyl_values1 = data.data.cyl_values[0];
        		var cyl_values2 = data.data.cyl_values[data.data.cyl_values.length-1];
        		var sph_values1 = data.data.sph_values[0];
        		var sph_values2 = data.data.sph_values[data.data.sph_values.length-1];
        		this.setState({cyl_values1: cyl_values1, cyl_values2: cyl_values2, sph_values1: sph_values1, sph_values2: sph_values2}, () => {
					span.innerHTML = "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><b>This frame supports lenses of a specific power range.</b> <br/> Check your power availability below: <table style='margin-left: auto;margin-right: auto;margin-top: 20px;'><tr style='background-color: #EBEBEB;'><th style='padding: 10px 30px;'>SPH</th><th style='padding: 10px 30px;'>"+this.state.sph_values1+" to "+this.state.sph_values2+"</th></tr><tr style='background-color: #F6F6F6;'><th style='padding: 10px 30px;'>CYL</th><th style='padding: 10px 30px;'>"+this.state.cyl_values1+" to "+this.state.cyl_values2+"</th></tr></table><center><img src='/images/Header/LogoOnPower.svg' style='margin-top: 25px; width: 25px;' /></center></div>";
					swal({
					    content: span,
					});	
				});
        	}
        	else
        	{
        		//swal("Power details not fetched for this product")
        	}
		});
  	}
  	AppliedFiltersIconChange()
  	{
		this.setState({show_applied_filters: false});
  	}
  	OnlyFilters()
  	{
  		if(window.location.pathname == "/products/eyeglasses/female")
        {
            var gender = window.location.pathname.substring(21, 27)
        }
        if(window.location.pathname == "/products/eyeglasses/male")
        {
            var gender = window.location.pathname.substring(21, 25)
        }
  		var url = process.env.REACT_APP_API_URL+"/v3/products/filter?onlyFilter&gender="+gender+"&per_page=20"; 
		fetch(url)
        .then(response => response.json())
        .then(products => {
        	if(products.success)
        	{
        		this.setState({filters: products.data.filters});
        	}
        	
        });
  	}
  	Filters()
  	{
  		this.setState({ show_filters: !this.state.show_filters});
  	}
  	UpdateOnlyFilters(frame_type,frame_shape,frame_size,frame_color,frame_material)
  	{
  		var url_format = '';
  		if(frame_type.length > 0)
  		{
  			url_format = url_format.concat("&frame_type="+frame_type+"")
  		}
  		if(frame_shape.length > 0)
  		{
  			url_format = url_format.concat("&frame_shape="+frame_shape+"")
  		}
  		if(frame_size.length > 0)
  		{
  			url_format = url_format.concat("&frame_size="+frame_size+"")
  		}
  		if(frame_color.length > 0)
  		{
  			url_format = url_format.concat("&frame_colour="+frame_color+"")
  		}
  		if(frame_material.length > 0)
  		{
  			url_format = url_format.concat("&frame_material="+frame_material+"")
  		}
		var url = process.env.REACT_APP_API_URL+"/v3/products/filter?gender="+this.state.gender+url_format+"&per_page=20"; 
		fetch(url)
        .then(response => response.json())
        .then(products => {
        	if(products.success)
        	{
        		this.setState({filters: products.data.filters}); 
        	}
        });		
		  		
  	}
  	ApplyFilters(frame_type,frame_shape,frame_size,frame_color,frame_material)
  	{
  		this.setState({ show_loading_filter: true, show_filters: false },()=>
  			{
  				var url_format = '';
		  		if(frame_type.length > 0)
		  		{
		  			url_format = url_format.concat("&frame_type="+frame_type+"")
		  		}
		  		if(frame_shape.length > 0)
		  		{
		  			url_format = url_format.concat("&frame_shape="+frame_shape+"")
		  		}
		  		if(frame_size.length > 0)
		  		{
		  			url_format = url_format.concat("&frame_size="+frame_size+"")
		  		}
		  		if(frame_color.length > 0)
		  		{
		  			url_format = url_format.concat("&frame_colour="+frame_color+"")
		  		}
		  		if(frame_material.length > 0)
		  		{
		  			url_format = url_format.concat("&frame_material="+frame_material+"")
		  		}
		  		
		  		this.CallFiltersApi(url_format,frame_type,frame_shape,frame_size,frame_color,frame_material);
  			});
  		
  	}
  	CallFiltersApi(url_format,frame_type,frame_shape,frame_size,frame_color,frame_material)
  	{
  		var show_applied_filters = (url_format.length > 0) ? true : false;
  		var url = process.env.REACT_APP_API_URL+"/v3/products/filter?gender="+this.state.gender+url_format+"&per_page=20"; 
		fetch(url)
        .then(response => response.json())
        .then(products => {
	        	if(products.success)
	        	{
	        		this.AppendUrl(frame_type,frame_shape,frame_size,frame_color,frame_material);
	        		this.setState({filters: products.data.filters, 
	        			product_details: products.data.products, 
	        			show_filters: false, 
	        			current_page: products.data.current_page, 
	        			first_page: products.data.first_page, 
	        			filter_last_page: products.data.last_page, 
	        			per_page: products.data.per_page, 
	        			total_product: products.data.total_product_count,
	        			show_loading_filter: false, 
	        			show_applied_filters: show_applied_filters,
	        			show_no_product: false,
	        		});
        	}
        	else
        	{
        		//swal(products.message);
        		//var baseUrl = window.location.href.split("?")[0];
        		//window.history.pushState('name', '', baseUrl);
        		this.AppendUrl(frame_type,frame_shape,frame_size,frame_color,frame_material);
        		this.setState({show_loading_filter: false, show_no_product: true});
        	}
        });
  	}
  	AppendUrl(frame_type,frame_shape,frame_size,frame_color,frame_material)
  	{
  		let here = new URL(window.location.href);
		if(frame_type.length > 0)
  		{
  			here.searchParams.set('frame_type', frame_type);
  		}
  		else
  		{
  			here.searchParams.delete('frame_type');
  		}
  		if(frame_shape.length > 0)
  		{
  			here.searchParams.set('frame_shape', frame_shape);
  		}
  		else
  		{
  			here.searchParams.delete('frame_shape');
  		}
  		if(frame_size.length > 0)
  		{
  			here.searchParams.set('frame_size', frame_size);
  		}
  		else
  		{
  			here.searchParams.delete('frame_size');
  		}
  		if(frame_color.length > 0)
  		{
  			here.searchParams.set('frame_colour', frame_color);
  		}
  		else
  		{
  			here.searchParams.delete('frame_colour');
  		}
  		if(frame_material.length > 0)
  		{
  			here.searchParams.set('frame_material', frame_material);
  		}
  		else
  		{
  			here.searchParams.delete('frame_material');
  		}
  		window.history.pushState({}, '', here);
  	}
  	CallFiltersLoadMore(url_format)
  	{
  		if(this.state.current_page == '')
  		{
  			var next_page = 1;
  		}
  		else
  		{
  			var next_page = this.state.current_page+1;
  		}
  		console.log(this.state.current_page);
  		console.log(next_page);
  		if(next_page <= this.state.filter_last_page)
		{
	  		var url = process.env.REACT_APP_API_URL+"/v3/products/filter"+url_format+"&page="+next_page+"&per_page=20&gender="+this.state.gender; 
			fetch(url)
	        .then(response => response.json())
	        .then(products => {
	        	if(products.success)
	        	{
	        		this.setState({filters: products.data.filters, 
	        			product_details: this.state.product_details.concat(products.data.products), 
	        			current_page: products.data.current_page, 
	        			first_page: products.data.first_page, 
	        			filter_last_page: products.data.last_page, 
	        			per_page: products.data.per_page, 
	        			total_product: products.data.total_product_count, 
	        			show_products: true,
	        			show_no_product: false,
	        		});
	        	}
	        });
    	}
  	}
  	render() {
 		const { width } = this.state;
		const isMobile = width <= 768;
		const count = this.state.product_count;

		const settings = {
			draggable: true,
            dots: true,
          	arrows: true,
          	infinite: false,
          	speed: 500,
          	slidesToShow: 1,
          	slidesToScroll: 1,
          	swipe: true,
          	accessibility: true,
          	appendDots: (dots) => {
            	return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
          	}
        };

  		if(this.state.show_products)
 		{
 			if (isMobile) 
 			{
 				return (
 					<div>
	 				{!this.state.show_filters ?
	 					<div>
					    	<div className="col-sm-12 all_product_section">
					    		{!this.state.show_loading_filter ?
					    			<div>
					    				<DesktopHead count={count} filters={this.Filters} show_applied_filters={this.state.show_applied_filters} 
					    					pl_coupon_banner_mob={this.state.pl_coupon_banner_mob} show_pl_coupon_banner_mob={this.state.show_pl_coupon_banner_mob}
					    					pl_current_collection_banner_web={this.state.pl_current_collection_banner_web} show_pl_current_collection_banner_web={this.state.show_pl_current_collection_banner_web}
	        								pl_current_collection_banner_mob={this.state.pl_current_collection_banner_mob} show_pl_current_collection_banner_mob={this.state.show_pl_current_collection_banner_mob} />
					    				{!this.state.show_no_product ?
					    					<InfiniteScroll
									          dataLength={this.state.current_page * this.state.per_page}
									          next={this.fetchMoreData}
									          hasMore={true}
									        >
										        {this.state.product_details.map((item, i) => (
										    		<div className="col-sm-12 all_products_section_each_card padding_is_zero">
											    		<div className="section" key={i}>
															<ImageViewNew item={item} change_picture={this.ChangePicture} show_loading_lottie={this.state.show_loading_lottie} i={i} path_source={this.state.path_source} available_power={this.AvailablePower}/>
										    				<ProductTextAreaNew item={item} colors={this.state.product_details[i].colors} color_border={this.state.color_border} change_color={this.ChangeColor} path_source={this.state.path_source} i={i}/>
									    				</div>
											    	</div>
											    ))}
									        </InfiniteScroll>
									        :
									        <center><p className="no_products_text">No Products Found</p></center>
					    				}
							    		
							        </div>
							        :
							        <Loading />
							    }
					    	</div>
					    	<Footer />
				    	</div>
				    	:
				    	<div>
				    		<ProductListingFilters product_details={this.state.product_details} filters={this.state.filters} apply_filters={this.ApplyFilters} filters_action={this.Filters} only_filters={this.OnlyFilters} update_only_filters={this.UpdateOnlyFilters} applied_filter_icon_change={this.AppliedFiltersIconChange} device="mob"/>
				    	</div>
	 				}
 					</div>
	    		);
	    	}
	    	else
	    	{
	    		return (
	    			
		    		<div className=" hidden-xs">
			    		<DesktopHead count={count} pl_coupon_banner_web={this.state.pl_coupon_banner_web} show_pl_coupon_banner_web={this.state.show_pl_coupon_banner_web}
			    			pl_header_men={this.state.pl_header_men} show_pl_header_men={this.state.show_pl_header_men}
			    			pl_header_women={this.state.pl_header_women} show_pl_header_women={this.state.show_pl_header_women}
			    			pl_header_collection={this.state.pl_header_collection} show_pl_header_collection={this.state.show_pl_header_collection}
			    			pl_header_sunglasses={this.state.pl_header_sunglasses} show_pl_header_sunglasses={this.state.show_pl_header_sunglasses}
			    			pl_collection_banner={this.state.pl_collection_banner} show_pl_collection_banner={this.state.show_pl_collection_banner}
			    			pl_current_collection_banner_web={this.state.pl_current_collection_banner_web} show_pl_current_collection_banner_web={this.state.show_pl_current_collection_banner_web}
	        				pl_current_collection_banner_mob={this.state.pl_current_collection_banner_mob} show_pl_current_collection_banner_mob={this.state.show_pl_current_collection_banner_mob}/>
			    		<div className="clearfix"></div>
			    		{!this.state.show_loading_filter ?
				    		<div className="col-sm-12 padding_is_zero">
					    		<div className="col-sm-3 product_filters_section">
					    			<ProductListingFilters product_details={this.state.product_details} filters={this.state.filters} apply_filters={this.ApplyFilters} filters_action={this.Filters} only_filters={this.OnlyFilters} device="web"/>
					    		</div>
					    		<div className="col-sm-9" id="product_listing_filters">
					    			{!this.state.show_no_product ?
							    		<InfiniteScroll
								          dataLength={this.state.current_page * this.state.per_page}
								          next={this.fetchMoreData}
								          hasMore={true}
								          
								        >
									        {this.state.product_details.map((item, i) => (
									    		<div className="col-sm-4" style = {{ marginBottom : "50px"}}>
								    				<div className="col-sm-12">
										    			
										    			<div class="col-sm-12 all_products_section_each_card padding_is_zero" id="all_products_section_each_card">
											    		<div className="section" key={i}>
															<ImageViewNew item={item} change_picture={this.ChangePicture} show_loading_lottie={this.state.show_loading_lottie} i={i} path_source={this.state.path_source} available_power={this.AvailablePower}/>
										    				<ProductTextAreaNew item={item} colors={this.state.product_details[i].colors} color_border={this.state.color_border} change_color={this.ChangeColor} path_source={this.state.path_source} i={i}/>
									    				</div>
									    				</div>
									    				
								    				</div>
										    	</div>
										    ))}
								        </InfiniteScroll>
								        :
								        <center><p className="no_products_text">No Products Found</p></center>
								    }
						        </div>
				    		</div>
				    		:
				    		<Loading />
				    	}
			    		<Footer />
		    		</div>
	    		);
	    	}	
 		}
 		return 	<div className="col-sm-12 no_product_list">
 					<p>No Products Found !</p>
 				</div>;
		
 		
 		
  	}
  	
}


export default ProductPage;
/*

<Link to={"/product/"+item.product_id}></Link>
<a href={"#carousel__slide"+(index-1)}><i class="fa fa-angle-left product_details_corousel_left_web"></i></a>
<a href={"#carousel__slide"+(index+1)}><i class="fa fa-angle-right product_details_corousel_right_web"></i></a>
*/
