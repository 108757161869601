import React, { Component } from "react";
import Header from "../Common/Header";
import Loading from "../Common/Loading";
import PageFooter from "../ProductListing/PageFooter";
import ProductImagesNew from "./ProductImagesNew";
import ProductDetailsHeader from "./ProductDetailsHeader";
import ProductDetailsBreadCrumb from "./ProductDetailsBreadCrumb";
import ProductDetailsShareOptions from "./ProductDetailsShareOptions";
import ProductImagesShowCase from "./ProductImagesShowCase";
import ProductDetailsBody from "./ProductDetailsBody";
import ProductDetailsRightSectionWeb from "./ProductDetailsRightSectionWeb";
import ProductPackage from "./ProductPackage";
import ProductLenseFeature from "./ProductLenseFeature";
import ProductPackageDesktop from "./ProductPackageDesktop";
import ProductDetailsStaticImages from "./ProductDetailsStaticImages";
import ProductDetailsInstagramPost from "./ProductDetailsInstagramPost";
import ProductDetailsCollectionBanner from "./ProductDetailsCollectionBanner";
import Footer from "../Common/Footer";
import HomePageDeliverySection from "../Home/HomePageDeliverySection";
import store from "../../Redux/store";
import Lottie from "react-lottie";
import ARAnimation from "../Lotties/ar_try_on.json";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from "jquery";
import swal from "sweetalert";
import { RWebShare } from "react-web-share";
import Cookies from "js-cookie";
import ClevertapReact from "clevertap-react";
import ProductDetailsLeftSectionWeb from "./ProductDetailsLeftSectionWeb";
import HomePageTrendingSection from "../Home/HomePageTrendingSection";
import ProductCostSection from "./ProductCostSection";
import ReviewList from "../RatingReview/ReviewList";
import Rating from "../RatingReview/Rating";

class ProductDetailsLatest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: localStorage.getItem("token"),
      track_source: Cookies.get("track_source"),
      path_source: "",
      prevPath: "",
      redirect_page: false,
      cart_count: 0,
      sold_out: 0,
      show_lense_details: false,
      pres: "no",
      add_to_cart: true,
      cyl_values1: "-",
      cyl_values2: "-",
      sph_values1: "-",
      sph_values2: "-",
      lense_background: "lense_background_purple",
      lenskart_prod_id: "",
      show_product_image: false,
      web_images: [],
      mob_images: [],
      width: window.innerWidth,
      packages: [],
      showing_product_package: false,
      cross_package_image: false,
      user_data: [],
      trending_product_details: [],
      show_trending_product: false,
      static_eyeglasses_web: [],
      show_static_eyeglasses_web: false,
      static_eyeglasses_mob: [],
      show_static_eyeglasses_mob: false,
      static_sunglasses_web: [],
      show_static_sunglasses_web: false,
      static_sunglasses_mob: [],
      show_static_sunglasses_mob: false,
      collection_banner_web: [],
      show_collection_banner_web: false,
      collection_banner_mob: [],
      show_collection_banner_mob: false,
      banners: [],
      pdp_coupon_web: [],
      show_pdp_coupon_web: false,
      pdp_coupon_mob: [],
      show_pdp_coupon_mob: false,
    };
    this.RedirectBack = this.RedirectBack.bind(this);
    this.ChangeColor = this.ChangeColor.bind(this);
    this.ShowPowerRange = this.ShowPowerRange.bind(this);
    this.AvailablePower = this.AvailablePower.bind(this);
    this.SelectPackage = this.SelectPackage.bind(this);
    this.SelectPrescription = this.SelectPrescription.bind(this);
    this.ChangePackage = this.ChangePackage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.MoreImages = this.MoreImages.bind(this);
    this.GetUserDetails = this.GetUserDetails.bind(this);
    this.ChangeColorTrending = this.ChangeColorTrending.bind(this);
    this.FetchingBanner = this.FetchingBanner.bind(this);
  }
  componentDidMount() {
    const state = store.getState();
    var banners = state.each_products.items.banners;
    var product_details = state.each_products.items.products;
    var reviews = state.each_products.items.rating_reviews;
    let prod_count = localStorage.getItem("prod_counts");
    if (prod_count) {
      var total_qty = 0;
      let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
      for (var i = 0; i <= product_id_array.length - 1; i++) {
        if (product_id_array[i] !== 0) {
          total_qty =
            total_qty +
            JSON.parse(localStorage.getItem("prod_" + product_id_array[i])).qty;
        }
      }
      this.setState({
        cart_count: total_qty,
      });
    }
    this.setState(
      {
        product_count: state.each_products.items.product_count,
        product_details: product_details,
        banners: banners,
        reviews: reviews,
      },
      () => {
        this.setState({
          color_id: this.state.product_details.specifications.color_prod_id,
          gender: this.state.product_details.gender,
          lenskart_prod_id: this.state.product_details.lenskart_prod_id,
          sold_out: this.state.product_details.sold_out,
        });
      }
    );
    this.setState(
      { path_source: this.props.path_source, prevPath: this.props.prevPath },
      () => {
        if (this.state.product_count == 1) {
          this.ViewWebImages();
          this.FetchingBanner();
          var product_details_clevertap = {
            product_name: this.state.product_details.short_name,
            category: this.state.product_details.gender,
            product_id: this.state.product_details.specifications.color_prod_id,
          };

          ClevertapReact.event("ProductViewed", product_details_clevertap);
        }
      }
    );

    var url = process.env.REACT_APP_API_URL + "/v1_1/lenses";
    fetch(url)
      .then((response) => response.json())
      .then((packages) => {
        this.setState({ packages: packages });
      });
    var sub_data = {
      product_id: 0,
      qty: 1,
      subscription_id: 1,
    };
    localStorage.setItem("sub_data", JSON.stringify(sub_data));
    if (this.state.access_token) {
      this.GetUserDetails();
    }
    let trendingurl =
      process.env.REACT_APP_API_URL + "/v2/products?type=trending";
    fetch(trendingurl)
      .then((response) => response.json())
      .then((data) => {
        if (data.result) {
          this.setState({
            trending_product_details: data.result.products,
            show_trending_product: true,
          });
        } else {
          this.setState({ show_trending_product: false });
        }
      });
  }
  FetchingBanner() {
    var static_eyeglasses_web = this.state.static_eyeglasses_web;
    var show_static_eyeglasses_web = this.state.show_static_eyeglasses_web;
    var static_eyeglasses_mob = this.state.static_eyeglasses_mob;
    var show_static_eyeglasses_mob = this.state.show_static_eyeglasses_mob;
    var static_sunglasses_web = this.state.static_sunglasses_web;
    var show_static_sunglasses_web = this.state.show_static_sunglasses_web;
    var static_sunglasses_mob = this.state.static_sunglasses_mob;
    var show_static_sunglasses_mob = this.state.show_static_sunglasses_mob;
    var collection_banner_web = this.state.collection_banner_web;
    var show_collection_banner_web = this.state.show_collection_banner_web;
    var collection_banner_mob = this.state.collection_banner_mob;
    var show_collection_banner_mob = this.state.show_collection_banner_mob;
    var pdp_coupon_web = this.state.pdp_coupon_web;
    var show_pdp_coupon_web = this.state.show_pdp_coupon_web;
    var pdp_coupon_mob = this.state.pdp_coupon_mob;
    var show_pdp_coupon_mob = this.state.show_pdp_coupon_mob;
    console.log(this.state.banners);
    for (var i = 0; i < this.state.banners.length; i++) {
      if (
        this.state.banners[i].banner_type == "A Plus - Ojos" &&
        this.state.banners[i].banner_for_desktop != ""
      ) {
        static_eyeglasses_web.push(this.state.banners[i]);
        show_static_eyeglasses_web = true;
      }
      if (
        this.state.banners[i].banner_type == "A Plus - Ojos" &&
        this.state.banners[i].banner_for_mobile_web != ""
      ) {
        static_eyeglasses_mob.push(this.state.banners[i]);
        show_static_eyeglasses_mob = true;
      }
      if (
        this.state.banners[i].banner_type == "A Plus - Sunglasses" &&
        this.state.banners[i].banner_for_desktop != ""
      ) {
        static_sunglasses_web.push(this.state.banners[i]);
        show_static_sunglasses_web = true;
      }
      if (
        this.state.banners[i].banner_type == "A Plus - Sunglasses" &&
        this.state.banners[i].banner_for_mobile_web != ""
      ) {
        static_sunglasses_mob.push(this.state.banners[i]);
        show_static_sunglasses_mob = true;
      }
      if (
        this.state.banners[i].banner_type == "A Plus - Collection" &&
        this.state.banners[i].banner_for_desktop != ""
      ) {
        collection_banner_web.push(this.state.banners[i]);
        show_collection_banner_web = true;
      }
      if (
        this.state.banners[i].banner_type == "A Plus - Collection" &&
        this.state.banners[i].banner_for_mobile_web != ""
      ) {
        collection_banner_mob.push(this.state.banners[i]);
        show_collection_banner_mob = true;
      }
      if (
        this.state.banners[i].banner_type == "Coupon Banner - PDP" &&
        this.state.banners[i].banner_for_desktop != ""
      ) {
        pdp_coupon_web.push(this.state.banners[i]);
        show_pdp_coupon_web = true;
      }
      if (
        this.state.banners[i].banner_type == "Coupon Banner - PDP" &&
        this.state.banners[i].banner_for_mobile_web != ""
      ) {
        pdp_coupon_mob.push(this.state.banners[i]);
        show_pdp_coupon_mob = true;
      }
    }
    this.setState({
      static_eyeglasses_web: static_eyeglasses_web,
      show_static_eyeglasses_web: show_static_eyeglasses_web,
      static_eyeglasses_mob: static_eyeglasses_mob,
      show_static_eyeglasses_mob: show_static_eyeglasses_mob,
      static_sunglasses_web: static_sunglasses_web,
      show_static_sunglasses_web: show_static_sunglasses_web,
      static_sunglasses_mob: static_sunglasses_mob,
      show_static_sunglasses_mob: show_static_sunglasses_mob,
      collection_banner_web: collection_banner_web,
      show_collection_banner_web: show_collection_banner_web,
      collection_banner_mob: collection_banner_mob,
      show_collection_banner_mob: show_collection_banner_mob,
      pdp_coupon_web: pdp_coupon_web,
      show_pdp_coupon_web: show_pdp_coupon_web,
      pdp_coupon_mob: pdp_coupon_mob,
      show_pdp_coupon_mob: show_pdp_coupon_mob,
    });
  }
  GetUserDetails() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + this.state.access_token);
    var requestOptions = {
      headers: myHeaders,
    };
    fetch(process.env.REACT_APP_API_URL + "/v1/user", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ user_data: data.result });
      });
  }
  ViewWebImages() {
    let images = [];
    let model_images = [];
    let mobile_images = [];
    let image_data = {};
    var glass_images_data = this.state.product_details.glass_images;
    var model_images_data = this.state.product_details.model_images;
    for (var i = 0; i < glass_images_data.length; i++) {
      images.push({
        url: glass_images_data[i].url,
      });
    }
    if (model_images_data.length > 0) {
      for (var i = 0; i < model_images_data.length; i++) {
        model_images.push({
          url: model_images_data[i].url,
        });
      }
    }

    mobile_images = images.concat(model_images);
    this.setState({ mob_images: mobile_images });
    const { width } = this.state;
    const isMobile = width <= 768;
    $("product_details_main").each(function () {
      this.scrollTop = this.scrollHeight;
      if ($(this).scrollTop() > 0) {
        $("#product_details_scrolling").addClass("nav-hide");
      } else {
        $("#product_details_scrolling").addClass("nav-normal");
      }
    });
    if (!isMobile) {
      images = model_images.concat(images);
      this.setState({ web_images: images }, () => {
        var images_new = this.state.web_images;
        $(".main-image-section img").remove();
        $(".main-image-section").append(
          '<img src="theImg.png" id="main-view"/>'
        );
        const mainView = document.getElementById("main-view");
        const info = document.getElementById("info");

        const thumbnails = document.getElementById("thumbnails");
        $("#thumbnails img").remove();
        // for (let i = 0; i < images_new.length; i++) {
        //   let image = images_new[i];
        //   let img = document.createElement("img");
        //   img.src = images_new[i].url;
        //   img.setAttribute("width", 170);
        //   img.setAttribute("data-index", i);
        //   img.addEventListener("click", changeImage);
        //   thumbnails.appendChild(img);
        // }

        // function initGallery() {
        //   loadImage(0);
        // }

        function slideImage() {
          let currentIndex = parseInt(mainView.getAttribute("data-index"));
          currentIndex =
            currentIndex + 1 == images_new.length ? 1 : currentIndex + 1;
          loadImage(currentIndex);
          setTimeout(slideImage, 3000);
        }

        function changeImage(event) {
          let target = event.currentTarget;
          let index = target.getAttribute("data-index");
          loadImage(index);
        }

        function loadImage(index) {
          let image = images_new[index];
          mainView.src = image.url;
          mainView.setAttribute("data-index", index);
          mainView.setAttribute("id", "image-" + index);
          mainView.style.opacity = 1;
        }
        // initGallery();
        //setTimeout(slideImage, 3000);
      });
    }
  }
  handleChange() {
    var cart_count = this.state.cart_count;
    if (this.state.product_details.type == "Sunglasses") {
      //store a prod_id with product and increase each time
      // save products with diff key
      var prod_ids = [];
      var prod_data = {};
      var prod_id = this.state.product_details.specifications.color_prod_id;

      prod_data = {
        product_id: prod_id,
        qty: 1,
        prescription_type: "sunglasses",
      };

      //step 1
      prod_ids = JSON.parse(localStorage.getItem("prod_counts"));

      if (Array.isArray(prod_ids)) {
        prod_ids.indexOf(prod_id) === -1
          ? prod_ids.push(prod_id)
          : console.log("checking");
        this.CheckExistingItem(prod_id);
      } else {
        prod_ids = [];
        prod_ids.push(prod_id);
        this.setState({
          alert_msg: "Item Added to the Cart!",
          show_toast: "",
          add_to_cart: false,
        });
        setTimeout(() => {
          this.setState({ show_toast: "" });
        }, 1000);
        this.setState({
          cart_count: cart_count + 1,
        });
      }
      localStorage.setItem("prod_counts", JSON.stringify(prod_ids));

      // step 2
      localStorage.setItem("prod_" + prod_id, JSON.stringify(prod_data));

      // step 3
      if (this.state.access_token) {
        this.postCart();
      }
      var add_to_cart_clevertap = {
        product_name: this.state.product_details.short_name,
        product_id: this.state.product_details.specifications.color_prod_id,
        power_type: this.state.pres,
        category: this.state.product_details.gender,
        cart_value: this.state.product_details.ojos_price,
      };

      ClevertapReact.event("Added To cart", add_to_cart_clevertap);
    } else {
      if (this.state.pres == "no") {
        swal("Please select Prescription Type");
      } else {
        this.setState({ add_to_cart: false });

        //store a prod_id with product and increase each time
        // save products with diff key
        var prod_ids = [];
        var prod_data = {};
        var prod_id = this.state.product_details.specifications.color_prod_id;

        prod_data = {
          product_id: prod_id,
          qty: 1,
          prescription_type: this.state.pres,
        };

        //step 1
        prod_ids = JSON.parse(localStorage.getItem("prod_counts"));

        if (Array.isArray(prod_ids)) {
          prod_ids.indexOf(prod_id) === -1
            ? prod_ids.push(prod_id)
            : console.log("checking");
          this.CheckExistingItem(prod_id);
        } else {
          prod_ids = [];
          prod_ids.push(prod_id);
          this.setState({
            alert_msg: "Item Added to the Cart!",
            show_toast: "",
            add_to_cart: false,
          });
          setTimeout(() => {
            this.setState({ show_toast: "" });
          }, 1000);
          this.setState({
            cart_count: cart_count + 1,
          });
        }

        localStorage.setItem("prod_counts", JSON.stringify(prod_ids));

        // step 2
        localStorage.setItem("prod_" + prod_id, JSON.stringify(prod_data));

        // step 3
        if (this.state.access_token) {
          this.postCart();
        }
        var add_to_cart_clevertap = {
          product_name: this.state.product_details.short_name,
          product_id: this.state.product_details.specifications.color_prod_id,
          power_type: this.state.pres,
          category: this.state.product_details.gender,
          cart_value: this.state.product_details.ojos_price,
        };

        ClevertapReact.event("Added To cart", add_to_cart_clevertap);
      }
    }
    let local_prod_ids = JSON.parse(localStorage.getItem("prod_counts"));

    var local_prod_id = JSON.stringify({ product_ids: local_prod_ids });
    let url1 = process.env.REACT_APP_API_URL + "/v1/cart/list";

    var requestOptions = {
      method: "POST",
      body: local_prod_id,
      redirect: "follow",
    };

    fetch(url1, requestOptions)
      .then((response) => response.json())
      .then((products) => {
        // console.log(products);
      });
  }
  CheckExistingItem(prod_id) {
    /*Checking if item already exist in local storage when clicking add to bag*/
    var prod_ids = JSON.parse(localStorage.getItem("prod_counts"));
    var cart_count = this.state.cart_count;
    if (prod_ids.indexOf(prod_id) !== -1) {
      this.setState({
        alert_msg: "Item Updated with Prescription.",
        show_toast: "",
        add_to_cart: false,
      });
      setTimeout(() => {
        this.setState({ show_toast: "" });
      }, 1000);
    } else {
      this.setState({
        alert_msg: "Item Added to the Cart!",
        show_toast: "",
        add_to_cart: false,
      });
      setTimeout(() => {
        this.setState({ show_toast: "" });
      }, 1000);
      this.setState({
        cart_count: cart_count + 1,
      });
    }
  }
  postCart() {
    if (this.state.access_token) {
      let data = [];
      let key = "";
      let prod_data = [];
      let prod_ids = JSON.parse(localStorage.getItem("prod_counts"));
      let sub_data = JSON.parse(localStorage.getItem("sub_data")); //Passing Subscription details to backend

      prod_ids.forEach((item) => {
        key = "prod_" + item;

        prod_data = JSON.parse(localStorage.getItem(key));
        data.push(prod_data);
      });

      /*calculating cart_count again */
      let prod_count = localStorage.getItem("prod_counts");
      var cart_count = 0;
      if (prod_count) {
        let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
        for (var i = 0; i <= product_id_array.length - 1; i++) {
          if (product_id_array[i] !== 0) {
            cart_count =
              cart_count +
              JSON.parse(localStorage.getItem("prod_" + product_id_array[i]))
                .qty;
          }
        }
      }

      const { width } = this.state;
      const isMobile = width <= 768;
      if (isMobile) {
        var device = "mobile";
      } else {
        var device = "desktop";
      }
      if (cart_count > this.state.user_data.credits) {
        data.push(sub_data);
      }

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + this.state.access_token);
      myHeaders.append("User-Source", device);

      // prod_ids = JSON.stringify({'product_ids': prod_ids});
      // let url = process.env.REACT_APP_API_URL+'/v1/cart/list';

      data = JSON.stringify({
        cart_data: data,
        track_source: this.state.track_source,
      });
      let url = process.env.REACT_APP_API_URL + "/v1/cart";

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      };

      fetch(url, requestOptions).then((response) => response.json());
    }
  }
  ChangeColor(item_url, color_id) {
    if (this.state.path_source != null) {
      if (this.state.path_source == "male_listing") {
        var gender = this.state.path_source.substring(0, 4);
      } else {
        var gender = this.state.path_source.substring(0, 6);
      }
      var url =
        process.env.REACT_APP_API_URL +
        "/v1/product/" +
        item_url +
        "/colorid/" +
        color_id +
        "?gender=" +
        gender;
    } else {
      var gender = "";
      var url =
        process.env.REACT_APP_API_URL +
        "/v1/product/" +
        item_url +
        "/colorid/" +
        color_id;
    }

    fetch(url)
      .then((response) => response.json())
      .then((products) => {
        let product_details_new = this.state.product_details;
        product_details_new.model_images =
          products.result.products.model_images;
        product_details_new.glass_images =
          products.result.products.glass_images;
        product_details_new.specifications =
          products.result.products.specifications;
        this.setState(
          {
            product_details: product_details_new,
            color_id: products.result.products.specifications.color_prod_id,
            color_border: "colour_border",
            show_lense_details: false,
            add_bag: "add_to_bag",
            add_to_cart: true,
            lenskart_prod_id: products.result.products.lenskart_prod_id,
            sold_out: products.result.products.sold_out,
          },
          () => {
            if (this.state.pres != "no") {
              this.AvailablePower();
            }
            this.ViewWebImages();
          }
        );
      });
  }
  ChangeColorTrending(item_url, color_id, i) {
    var url =
      process.env.REACT_APP_API_URL +
      "/v1/product/" +
      item_url +
      "/colorid/" +
      color_id;
    let product_details_new = this.state.trending_product_details;
    fetch(url)
      .then((response) => response.json())
      .then((products) => {
        //console.log(products);
        let product_details_new = this.state.trending_product_details;
        product_details_new[i].model_images =
          products.result.products.model_images;
        product_details_new[i].glass_images =
          products.result.products.glass_images;
        product_details_new[i].specifications.color_prod_id =
          products.result.products.specifications.color_prod_id;
        product_details_new[i].sold_out = products.result.products.sold_out;
        this.setState({ trending_product_details: product_details_new });
      });
  }
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  RedirectBack() {
    if (this.state.prevPath.prevPath == undefined) {
      window.history.back();
    } else {
      this.setState({
        redirect_page: true,
      });
    }
  }
  ChangePackage() {
    this.setState({
      show_lense_details: !this.state.show_lense_details,
      showing_product_package: true,
      add_to_cart: true,
    });
  }
  AvailablePower() {
    this.ShowPowerRange();
  }
  SelectPackage() {
    this.setState({
      show_lense_details: !this.state.show_lense_details,
      showing_product_package: !this.state.showing_product_package,
    });
    setTimeout(() => {
      this.setState({ cross_package_image: true });
    }, 2000);
  }
  MoreImages() {
    this.setState({
      show_product_image: !this.state.show_product_image,
      image_type: "frame",
    });
  }
  MoreImagesModel() {
    this.setState({
      show_product_image: !this.state.show_product_image,
      image_type: "model",
    });
  }
  SelectPrescription(value) {
    this.setState({ show_lense_details: !this.state.show_lense_details });
    if (value == "Single Vision") {
      this.ShowPowerRange();
      this.setState({
        add_bag: "add_to_bag",
        pres: value,
      });
      this.setState({ lense_background: "lense_background_purple" });
      setTimeout(
        () => this.setState({ lense_background: "lense_background_white" }),
        5000
      );
    } else if (value == "Zero Power") {
      this.setState({
        add_bag: "add_to_bag",
        pres: value,
      });
      this.setState({ lense_background: "lense_background_purple" });
      setTimeout(
        () => this.setState({ lense_background: "lense_background_white" }),
        5000
      );
    } else if (value == "Blu Cut Single Vision") {
      this.ShowPowerRange();
      this.setState({
        add_bag: "add_to_bag",
        pres: value,
      });
      this.setState({ lense_background: "lense_background_purple" });
      setTimeout(
        () => this.setState({ lense_background: "lense_background_white" }),
        5000
      );
    } else {
      this.setState({
        add_bag: "add_to_bag_unclick",
        pres: "no",
      });
    }
  }
  ShowPowerRange() {
    var span = document.createElement("span");
    span.innerHTML =
      "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><center><div class='loader'></div></center></div>";
    swal({
      content: span,
      buttons: false,
    });
    let url_power =
      process.env.REACT_APP_API_URL +
      "/v1/product/" +
      this.state.color_id +
      "/power";
    fetch(url_power)
      .then((response) => response.json())

      .then((data) => {
        // console.log(data);
        if (data.success == true) {
          var cyl_values1 = data.data.cyl_values[0];
          var cyl_values2 =
            data.data.cyl_values[data.data.cyl_values.length - 1];
          var sph_values1 = data.data.sph_values[0];
          var sph_values2 =
            data.data.sph_values[data.data.sph_values.length - 1];
          this.setState(
            {
              cyl_values1: cyl_values1,
              cyl_values2: cyl_values2,
              sph_values1: sph_values1,
              sph_values2: sph_values2,
            },
            () => {
              span.innerHTML =
                "<div className='product_details_power_popup' style='font-size: 14px;margin: 10px 20px;'><b>This frame supports lenses of a specific power range.</b> <br/> Check your power availability below: <table style='margin-left: auto;margin-right: auto;margin-top: 20px;'><tr style='background-color: #EBEBEB;'><th style='padding: 10px 30px;'>SPH</th><th style='padding: 10px 30px;'>" +
                this.state.sph_values1 +
                " to " +
                this.state.sph_values2 +
                "</th></tr><tr style='background-color: #F6F6F6;'><th style='padding: 10px 30px;'>CYL</th><th style='padding: 10px 30px;'>" +
                this.state.cyl_values1 +
                " to " +
                this.state.cyl_values2 +
                "</th></tr></table><center><img src='/images/Header/LogoOnPower.svg' style='margin-top: 25px; width: 25px;' /></center></div>";
              swal({
                content: span,
              });
            }
          );
        } else {
          //swal("Power details not fetched for this product")
        }
      });
  }
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  render() {
    console.log(this.state.product_details);
    const { width } = this.state;
    const isMobile = width <= 768;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: ARAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const styles = {
      scrollArrowAnimation: {
        margin: "0px",
      },
    };
    var cart_count_var = this.state.cart_count > 1 ? "Items" : "Item";
    if (isMobile) {
      if (this.state.redirect_page) {
        return <Redirect to={this.state.prevPath.prevPath} />;
      }
      if (this.state.product_count === 1) {
        return (
          <div>
            {!this.state.show_product_image ? (
              <div>
                <ProductDetailsHeader
                  access_token={this.state.access_token}
                  cart_count={this.state.cart_count}
                  path_source={this.state.path_source}
                  redirect_back={this.RedirectBack}
                />
                <div id="product_details_scrolling"></div>
                <header className="product_details_body">
                  <ProductDetailsShareOptions
                    pres={this.state.pres}
                    available_power={this.AvailablePower}
                    product_details={this.state.product_details}
                    lenskart_prod_id={this.state.lenskart_prod_id}
                    color_id={this.state.color_id}
                    source="mob"
                  />
                  <ProductImagesNew
                    images={this.state.mob_images}
                    more_images={this.MoreImages}
                  />
                </header>

                <section className="product_details_main">
                  <ProductDetailsBody
                    reviews={this.state.reviews}
                    product_details={this.state.product_details}
                    color_id={this.state.color_id}
                    change_color={this.ChangeColor}
                    pres={this.state.pres}
                    lense_background={this.state.lense_background}
                    available_power={this.AvailablePower}
                    source="mob"
                    pdp_coupon_mob={this.state.pdp_coupon_mob}
                    show_pdp_coupon_mob={this.state.show_pdp_coupon_mob}
                  />
                  <ReviewList
                    productDetails={this.state.product_details}
                    reviewData={this.state.reviews}
                  ></ReviewList>
                  <ProductDetailsStaticImages
                    product_details={this.state.product_details}
                    static_eyeglasses_mob={this.state.static_eyeglasses_mob}
                    show_static_eyeglasses_mob={
                      this.state.show_static_eyeglasses_mob
                    }
                    static_sunglasses_mob={this.state.static_sunglasses_mob}
                    show_static_sunglasses_mob={
                      this.state.show_static_sunglasses_mob
                    }
                  />
                  <ProductDetailsCollectionBanner
                    device="mob"
                    product_details={this.state.product_details}
                    collection_banner_web={this.state.collection_banner_web}
                    show_collection_banner_web={
                      this.state.show_collection_banner_web
                    }
                    collection_banner_mob={this.state.collection_banner_mob}
                    show_collection_banner_mob={
                      this.state.show_collection_banner_mob
                    }
                  />
                  <ProductDetailsInstagramPost
                    device="desktop"
                    pdp_detail={this.state.product_details.pdp_detail}
                  />
                  <ProductDetailsInstagramPost
                    device="mob"
                    pdp_detail={this.state.product_details.pdp_detail}
                  />
                  <HomePageDeliverySection />
                  <Footer />
                </section>
                <div className="modal-footer-product-details-new">
                  <div className="col-xs-12">
                    {this.state.sold_out == 0 ? (
                      <div>
                        {this.state.product_details.type == "Sunglasses" ? (
                          <div>
                            {this.state.product_details.active == 1 ||
                            this.state.product_details.live_status == 1 ? (
                              <div>
                                {this.state.add_to_cart == true ? (
                                  <center>
                                    <button
                                      className="btn add_to_bag"
                                      onClick={this.handleChange}
                                    >
                                      ADD TO CART
                                    </button>
                                  </center>
                                ) : (
                                  <Link
                                    to={{
                                      pathname: "/checkout",
                                      state: {
                                        prevPath: window.location.pathname,
                                      },
                                    }}
                                  >
                                    <p className="go_to_bag_coupon_text">
                                      Use code <b>OJOS40</b> to get 40% off
                                    </p>
                                    <button className="btn go_to_bag view_cart_button">
                                      <div className="pdp_view_cart_button">
                                        <p className="pdp_view_cart_count">
                                          {this.state.cart_count}{" "}
                                          {cart_count_var}
                                          <div>
                                            <p className="pdp_view_cart_offer">
                                              *Offers available at next step
                                            </p>
                                          </div>
                                        </p>
                                        <p className="pdp_view_cart_text">
                                          View Cart
                                        </p>
                                      </div>
                                    </button>
                                  </Link>
                                )}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div>
                            {this.state.product_details.active == 1 ||
                            this.state.product_details.live_status == 1 ? (
                              <div>
                                {!this.state.show_lense_details ? (
                                  <div>
                                    {this.state.pres == "no" ? (
                                      <center>
                                        <button
                                          className="btn select_lense_button"
                                          onClick={this.SelectPackage}
                                        >
                                          SELECT LENSES
                                        </button>
                                      </center>
                                    ) : (
                                      <div>
                                        {this.state.add_to_cart == true ? (
                                          <div>
                                            <div
                                              className="col-xs-6 padding_is_zero"
                                              onClick={this.ChangePackage}
                                            >
                                              <p className="product_footer_text btn lense_package_button">
                                                LENS PACKAGE:{" "}
                                                <u
                                                  className="change_package_text"
                                                  onClick={this.ChangePackage}
                                                >
                                                  Change
                                                </u>
                                                <br />
                                                {this.state.pres.length > 12 ? (
                                                  <b className="choosen_pres_text">
                                                    {this.state.pres.substring(
                                                      0,
                                                      15
                                                    )}
                                                    ...
                                                  </b>
                                                ) : (
                                                  <b className="choosen_pres_text">
                                                    {this.state.pres}
                                                  </b>
                                                )}
                                              </p>
                                            </div>
                                            <div className="col-xs-6 padding_is_zero">
                                              <center>
                                                <button
                                                  className={
                                                    "btn " + this.state.add_bag
                                                  }
                                                  onClick={this.handleChange}
                                                >
                                                  ADD TO CART
                                                </button>
                                              </center>
                                            </div>
                                          </div>
                                        ) : (
                                          <div>
                                            <div className="col-xs-12 padding_is_zero">
                                              {this.state.path_source == "" ||
                                              this.state.path_source == null ? (
                                                <Link
                                                  to={{
                                                    pathname: "/checkout",
                                                    state: {
                                                      prevPath:
                                                        window.location
                                                          .pathname,
                                                    },
                                                  }}
                                                >
                                                  <p className="go_to_bag_coupon_text">
                                                    Use code <b>OJOS40</b> to
                                                    get 40% off
                                                  </p>
                                                  <button className="btn go_to_bag view_cart_button">
                                                    <div className="pdp_view_cart_button">
                                                      <p className="pdp_view_cart_count">
                                                        {this.state.cart_count}{" "}
                                                        {cart_count_var}
                                                        <div>
                                                          <p className="pdp_view_cart_offer">
                                                            *Offers available at
                                                            next step
                                                          </p>
                                                        </div>
                                                      </p>
                                                      <p className="pdp_view_cart_text">
                                                        View Cart
                                                      </p>
                                                    </div>
                                                  </button>
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={{
                                                    pathname: "/checkout",
                                                    search:
                                                      "?path_source=" +
                                                      this.state.path_source,
                                                    state: {
                                                      prevPath:
                                                        window.location
                                                          .pathname,
                                                    },
                                                  }}
                                                >
                                                  <p className="go_to_bag_coupon_text">
                                                    Use code <b>OJOS40</b> to
                                                    get 40% off
                                                  </p>
                                                  <button className="btn go_to_bag view_cart_button">
                                                    <div className="pdp_view_cart_button">
                                                      <p className="pdp_view_cart_count">
                                                        {this.state.cart_count}{" "}
                                                        {cart_count_var}
                                                        <div>
                                                          <p className="pdp_view_cart_offer">
                                                            *Offers available at
                                                            next step
                                                          </p>
                                                        </div>
                                                      </p>
                                                      <p className="pdp_view_cart_text">
                                                        View Cart
                                                      </p>
                                                    </div>
                                                  </button>
                                                </Link>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <ProductPackage
                                    select_package={this.SelectPackage}
                                    select_prescription={
                                      this.SelectPrescription
                                    }
                                    packages={this.state.packages}
                                  />
                                )}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    ) : (
                      <center>
                        <button className="btn sold_out_button">
                          SOLD OUT
                        </button>
                      </center>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-sm-12">
                <div className="col-sm-12">
                  <button
                    type="button"
                    className="close more_image_close_button"
                    onClick={this.MoreImages}
                  >
                    &times;
                  </button>
                </div>
                <div className="col-sm-12 more_image_section">
                  <center>
                    <div>
                      {this.props.gender_url == "Unisex" ? (
                        <div>
                          {this.state.product_details.glass_images.map(
                            (image_item, index) => (
                              <img
                                src={image_item.url}
                                className="img-responsive show_more_image_img"
                                id="frame_img"
                                name="frame_img"
                                alt="frame_img"
                              />
                            )
                          )}
                          {this.state.product_details.model_images.map(
                            (image_item, index) => (
                              <img
                                src={image_item.url}
                                className="img-responsive show_more_image_img"
                                id="frame_img"
                                name="frame_img"
                                alt="frame_img"
                              />
                            )
                          )}
                        </div>
                      ) : (
                        <div>
                          {this.state.product_details.glass_images.map(
                            (image_item, index) => (
                              <img
                                src={image_item.url}
                                className="img-responsive show_more_image_img"
                                id="frame_img"
                                name="frame_img"
                                alt="frame_img"
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </center>
                </div>
              </div>
            )}
          </div>
        );
      }
    } else {
      return (
        <div className="product_details">
          <Header color={"#160D08"} cart_count={this.state.cart_count} />
          {this.state.product_count === 1 ? (
            <ProductDetailsBreadCrumb
              path_source={this.state.path_source}
              product_details={this.state.product_details}
            />
          ) : null}
          <div className="col-sm-12 product_details_new_image_div">
            <div className="col-sm-12" style={{ marginBottom: "20px" }}>
              {this.state.product_count === 1 ? (
                <ProductImagesShowCase
                  pres={this.state.pres}
                  available_power={this.AvailablePower}
                  product_details={this.state.product_details}
                  lenskart_prod_id={this.state.lenskart_prod_id}
                  color_id={this.state.color_id}
                  source="web"
                />
              ) : null}
            </div>

            <div className="col-sm-7">
              {this.state.product_count === 1 ? (
                <div>
                  <ProductDetailsLeftSectionWeb
                    review={this.state.reviews}
                    product_details={this.state.product_details}
                    color_id={this.state.color_id}
                    change_color={this.ChangeColor}
                    pres={this.state.pres}
                    lense_background={this.state.lense_background}
                    available_power={this.AvailablePower}
                    sold_out={this.state.sold_out}
                    show_lense_details={this.state.show_lense_details}
                    source="web"
                  />
                </div>
              ) : null}

              <ReviewList
                productDetails={this.state.product_details}
                reviewData={this.state.reviews}
              ></ReviewList>
            </div>
            <div className="col-sm-5">
              <div className="col-sm-12 product_details_right_div_web">
                {this.state.product_count === 1 ? (
                  <div style={{ postion: "sticky", top: "50px" }}>
                    <ProductDetailsRightSectionWeb
                      product_details={this.state.product_details}
                      color_id={this.state.color_id}
                      change_color={this.ChangeColor}
                      pres={this.state.pres}
                      lense_background={this.state.lense_background}
                      available_power={this.AvailablePower}
                      sold_out={this.state.sold_out}
                      show_lense_details={this.state.show_lense_details}
                      source="web"
                    />
                    <div className="col-xs-12">
                      {this.state.product_details.colors.map((item, index) => (
                        <div className="col-sm-12">
                          {this.state.color_id === item.id && item.ar_status ? (
                            <center>
                              <Link
                                to={
                                  "/product/" +
                                  this.state.product_details.prod_url +
                                  "/" +
                                  this.state.lenskart_prod_id +
                                  "/color/" +
                                  this.state.product_details.specifications
                                    .color_prod_id
                                }
                              >
                                <button className="btn product_details_ar_button_web">
                                  <Lottie
                                    options={defaultOptions}
                                    width={50}
                                    style={styles.scrollArrowAnimation}
                                  />
                                  AR TRY ON
                                </button>
                              </Link>
                            </center>
                          ) : null}
                        </div>
                      ))}
                      {this.state.sold_out == 0 ? (
                        <div>
                          {this.state.product_details.active == 1 ||
                          this.state.product_details.live_status == 1 ? (
                            <div>
                              {this.state.product_details.type ==
                              "Sunglasses" ? (
                                <div>
                                  {this.state.product_details.active == 1 ||
                                  this.state.product_details.live_status ==
                                    1 ? (
                                    <div>
                                      {this.state.add_to_cart == true ? (
                                        <div className="col-xs-12 product_details_lense_package_selection">
                                          <center>
                                            {" "}
                                            <button
                                              className="btn add_to_bag"
                                              onClick={this.handleChange}
                                            >
                                              {" "}
                                              ADD TO CART{" "}
                                            </button>{" "}
                                          </center>
                                        </div>
                                      ) : (
                                        <div className="col-xs-12 product_details_lense_package_selection">
                                          <Link
                                            to={{
                                              pathname: "/checkout",
                                              state: {
                                                prevPath:
                                                  window.location.pathname,
                                              },
                                            }}
                                          >
                                            <p className="go_to_bag_coupon_text">
                                              {" "}
                                              Use code <b>OJOS40</b> to get 40%
                                              off{" "}
                                            </p>
                                            <button className="btn go_to_bag view_cart_button">
                                              <div className="pdp_view_cart_button">
                                                <p className="pdp_view_cart_count">
                                                  {this.state.cart_count}{" "}
                                                  {cart_count_var}
                                                  <div>
                                                    <p className="pdp_view_cart_offer">
                                                      {" "}
                                                      *Offers available at next
                                                      step{" "}
                                                    </p>
                                                  </div>
                                                </p>
                                                <p className="pdp_view_cart_text">
                                                  {" "}
                                                  View Cart{" "}
                                                </p>
                                              </div>
                                            </button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              ) : (
                                <div>
                                  {!this.state.show_lense_details ? (
                                    <div>
                                      {this.state.pres == "no" ? (
                                        <div className="col-xs-12">
                                          <center>
                                            <button
                                              className="btn select_lense_button"
                                              onClick={this.SelectPackage}
                                            >
                                              {" "}
                                              SELECT LENSES{" "}
                                            </button>{" "}
                                          </center>
                                        </div>
                                      ) : (
                                        <div>
                                          {this.state.add_to_cart == true ? (
                                            <div className="col-xs-12 product_details_lense_package_selection">
                                              <div
                                                className="col-xs-6 padding_is_zero"
                                                onClick={this.ChangePackage}
                                              >
                                                <p className="product_footer_text btn lense_package_button">
                                                  LENS PACKAGE:{" "}
                                                  <u
                                                    className="change_package_text"
                                                    onClick={this.ChangePackage}
                                                  >
                                                    {" "}
                                                    Change{" "}
                                                  </u>{" "}
                                                  <br />
                                                  <b className="choosen_pres_text">
                                                    {" "}
                                                    {this.state.pres}{" "}
                                                  </b>
                                                </p>
                                              </div>
                                              <div className="col-xs-6 padding_is_zero">
                                                <center>
                                                  {" "}
                                                  <button
                                                    className={
                                                      "btn " +
                                                      this.state.add_bag
                                                    }
                                                    onClick={this.handleChange}
                                                  >
                                                    {" "}
                                                    ADD TO CART{" "}
                                                  </button>{" "}
                                                </center>
                                              </div>
                                            </div>
                                          ) : (
                                            <div>
                                              <div className="col-xs-12 product_details_lense_package_selection">
                                                {this.state.path_source == "" ||
                                                this.state.path_source ==
                                                  null ? (
                                                  <Link
                                                    to={{
                                                      pathname: "/checkout",
                                                      state: {
                                                        prevPath:
                                                          window.location
                                                            .pathname,
                                                      },
                                                    }}
                                                  >
                                                    <p className="go_to_bag_coupon_text">
                                                      {" "}
                                                      Use code <b>OJOS40</b> to
                                                      get 40% off{" "}
                                                    </p>
                                                    <button className="btn go_to_bag view_cart_button">
                                                      <div className="pdp_view_cart_button">
                                                        <p className="pdp_view_cart_count">
                                                          {
                                                            this.state
                                                              .cart_count
                                                          }{" "}
                                                          {cart_count_var}
                                                          <div>
                                                            <p className="pdp_view_cart_offer">
                                                              {" "}
                                                              *Offers available
                                                              at next step{" "}
                                                            </p>
                                                          </div>
                                                        </p>
                                                        <p className="pdp_view_cart_text">
                                                          {" "}
                                                          View Cart{" "}
                                                        </p>
                                                      </div>
                                                    </button>
                                                    {/*<center><button className="btn go_to_bag">GO TO CART</button></center>*/}
                                                  </Link>
                                                ) : (
                                                  <Link
                                                    to={{
                                                      pathname: "/checkout",
                                                      search:
                                                        "?path_source=" +
                                                        this.state.path_source,
                                                      state: {
                                                        prevPath:
                                                          window.location
                                                            .pathname,
                                                      },
                                                    }}
                                                  >
                                                    <p className="go_to_bag_coupon_text">
                                                      {" "}
                                                      Use code <b>OJOS40</b> to
                                                      get 40% off{" "}
                                                    </p>
                                                    <button className="btn go_to_bag view_cart_button">
                                                      <div className="pdp_view_cart_button">
                                                        <p className="pdp_view_cart_count">
                                                          {
                                                            this.state
                                                              .cart_count
                                                          }{" "}
                                                          {cart_count_var}
                                                          <div>
                                                            <p className="pdp_view_cart_offer">
                                                              {" "}
                                                              *Offers available
                                                              at next step{" "}
                                                            </p>
                                                          </div>
                                                        </p>
                                                        <p className="pdp_view_cart_text">
                                                          {" "}
                                                          View Cart{" "}
                                                        </p>
                                                      </div>
                                                    </button>
                                                    {/*<center><button className="btn go_to_bag">GO TO CART</button></center>*/}
                                                  </Link>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="col-sm-12">
                                        <div className="col-sm-7 product_package_div_main">
                                          {this.state.cross_package_image ? (
                                            <img
                                              src="/images/Header/Minimise_ImageView.svg"
                                              onClick={this.SelectPackage}
                                              className="product_package_close_img"
                                            />
                                          ) : null}
                                        </div>
                                        <div
                                          className={
                                            "col-sm-5 product_package_desktop_overlay_" +
                                            this.state.showing_product_package
                                          }
                                        >
                                          <ProductPackageDesktop
                                            select_package={this.SelectPackage}
                                            select_prescription={
                                              this.SelectPrescription
                                            }
                                            packages={this.state.packages}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="col-xs-12">
                          <center>
                            {" "}
                            <button className="btn sold_out_button">
                              {" "}
                              SOLD OUT{" "}
                            </button>{" "}
                          </center>
                        </div>
                      )}
                    </div>
                    <ProductCostSection
                      pdp_coupon_web={this.state.pdp_coupon_web}
                      show_pdp_coupon_web={this.state.show_pdp_coupon_web}
                    />
                  </div>
                ) : null}
              </div>
              <div
                className="col-sm-12 "
                style={{ marginTop: "10px", height: "520px", overflow: "auto" }}
              ></div>
            </div>
          </div>
          {this.state.product_count === 1 ? (
            <div>
              <ProductDetailsBody
                product_details={this.state.product_details}
                color_id={this.state.color_id}
                change_color={this.ChangeColor}
                pres={this.state.pres}
                lense_background={this.state.lense_background}
                available_power={this.AvailablePower}
                source="web"
              />

              <ProductDetailsStaticImages
                product_details={this.state.product_details}
                static_eyeglasses_web={this.state.static_eyeglasses_web}
                show_static_eyeglasses_web={
                  this.state.show_static_eyeglasses_web
                }
                static_sunglasses_web={this.state.static_sunglasses_web}
                show_static_sunglasses_web={
                  this.state.show_static_sunglasses_web
                }
              />
              <ProductDetailsCollectionBanner
                device="desktop"
                product_details={this.state.product_details}
                collection_banner_web={this.state.collection_banner_web}
                show_collection_banner_web={
                  this.state.show_collection_banner_web
                }
                collection_banner_mob={this.state.collection_banner_mob}
                show_collection_banner_mob={
                  this.state.show_collection_banner_mob
                }
              />
              <ProductDetailsInstagramPost
                device="desktop"
                pdp_detail={this.state.product_details.pdp_detail}
              />
              {/* <HomePageDeliverySection /> */}
              <HomePageTrendingSection
                source="web"
                pageSource="pdp"
                trending_product_details={this.state.trending_product_details}
                change_color={this.ChangeColorTrending}
              />
              <Footer />
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div>
        <Header color={"#160D08"} />
        <Loading />
      </div>
    );
  }
}
export default ProductDetailsLatest;
{
  /*<ProductPackageDesktop select_package={this.SelectPackage} select_prescription={this.SelectPrescription} packages={this.state.packages}/>*/
}
