import React, { Component } from 'react';

class HomePageLaunchExtraSection extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
    render() {
        return (
                <div>
                    <div className="col-xs-12 homepage_extra_section hidden-sm hidden-lg hidden-md">
                    { /* Buy More Save More */}
                        {/*<div className="col-xs-12 homepage_banner_section padding_is_zero">
                            <center><img src="/images/Header/BuyMoreSaveMore_Image.png" className="homepage_shop_glasses_launch_banner_mob img-responsive"/></center>
                        </div>
                        <div className="col-xs-12 homepage_banner_section homepage_banner_launch_text_div padding_is_zero">
                            <div className="homepage_banner_collection_launch_text_mob">
                                <p className="homepage_buy_more_text_header">Buy More, Save More</p>
                                <p className="homepage_buy_more_text_subheader">Buy 1 OJ Wear and get 40% off</p>
                                <p className="homepage_buy_more_text_subheader">Buy 2 OJ Wears and get 50% off</p>
                                <p className="homepage_buy_more_text_subheader">Buy 3 OJ Wears and get 55% off</p>
                                <p className="homepage_buy_more_text_subheader">Apply coupon at checkout</p>
                                <a href="/explore-ojos"><button className="homepage_banner_save_more_button">Buy Now</button></a>
                                <img src="/images/Header/BuyMoreSaveMore_Icon.png" className="homepage_buy_more_icon"/>
                            </div>
                        </div>*/}
                    {/* Buy More Save More Ends*/}
                    </div>

                    <div className="col-xs-12 homepage_extra_section hidden-xs">
                    { /* Buy More Save More */}
                        {/*<div className="col-xs-4"></div>
                        <div className="col-xs-4 padding_is_zero">
                            <img src="/images/Header/BuyMoreSaveMore_Image.png" className="homepage_shop_glasses_launch_banner homepage_save_more_banner_img img-responsive"/>
                        </div>
                        <div className="col-xs-4"></div>
                        <div className="clearfix"></div>
                        <div className="col-xs-4"></div>
                        <div className="col-xs-4   homepage_banner_launch_text_div padding_is_zero">
                            <div className="homepage_banner_collection_launch_text">
                                <p className="homepage_buy_more_text_header">Buy More, Save More</p>
                                <p className="homepage_buy_more_text_subheader">Buy 1 OJ Wear and get 40% off</p>
                                <p className="homepage_buy_more_text_subheader">Buy 2 OJ Wears and get 50% off</p>
                                <p className="homepage_buy_more_text_subheader">Buy 3 OJ Wears and get 55% off</p>
                                <p className="homepage_buy_more_text_subheader">Apply coupon at checkout</p>
                                <a href="/explore-ojos"><button className="homepage_banner_save_more_button">Buy Now</button></a>
                                <img src="/images/Header/BuyMoreSaveMore_Icon.png" className="homepage_buy_more_icon"/>
                            </div>
                        </div>
                        <div className="col-xs-4"></div>*/}
                    {/* Buy More Save More Ends*/}
                    </div>
                </div>
            );
    }
}
export default HomePageLaunchExtraSection;
