import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HomePageWhyOjoswear extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
    render() {
        return (
            <div className="col-xs-12 padding_is_zero">
                <div className="hidden-sm hidden-md hidden-lg">
                    <div className="col-xs-12">
                        <p className="homepage_why_ojoswear_new_header">Trendy Streetwear meets Designer Frames</p>
                        <p className="homepage_why_ojoswear_new_subtext">When trendy streetwear meets designer frames you get the hippest eyewear brand in India with the assurance of Lenskart.
    OJOS Wear is here to redefine your Gen Z style at an affordable price. With a new handpicked collection every month,
    we deliver the best offers, free shipping, and fast delivery. So, whats not to love? 💜</p>
                    </div>
                    <div className="col-xs-12 homepage_feature_div_section padding_is_zero">
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/GroupIcon.png" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext">5000+ Happy Customers</p></center>
                        </div>
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/SustainableIcon.png" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext">Sustainable and Plastic Free products</p></center>
                        </div>
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/PremiumQualityIcon.png" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext">Premium Frame and Lens Quality</p></center>
                        </div>
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/LowestPricesIcon.png" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext">Lowest Prices Guaranteed</p></center>
                        </div>
                    </div>
                    <img src="/images/Header/seperation_banner.png" className="img-responsive"/>
                </div>

                {/*<div className="hidden-sm hidden-md hidden-lg">
                    <center>
                        <p className="homepage_why_oj_text_mob extra_bold_text">but the big question is</p>
                        <h1 className="homepage_why_ojoswear">who are we?</h1>
                    </center>
                    <div className="col-xs-12 homepage_lk_oj_img">
                        <div className="col-xs-6 homepage_oj_small_icon_div">
                            <img src="/images/Header/OJ_Monogram-01.png" className="homepage_oj_img img-responsive"/>
                        </div>
                        <div className="col-xs-6">
                            <img src="/images/Header/LK_Monogram.svg" className="img-responsive"/>
                        </div>
                    </div>
                    <div className="col-xs-12 homepage_oj_lk_desc_div">
                        <center><p className="homepage_oj_lk_desc">Welcome to the future of eyewear shopping. OJOS brings you an exclusive collection of curated designs in partnership with Lenskart, that redefines Gen Z style!</p></center>
                    </div>
                    <div className="col-xs-12 homepage_why_oj_div">
                        <center>
                            <h2 className="homepage_why_choose_oj extra_bold_text">why choose OJOS?</h2>
                            <p className="homepage_oj_lk_desc">We redefine your eyewear shopping experience. Just pick, click and bling it on!</p>
                            <img src="/images/Header/GirlLookingInMirror.svg" />
                        </center>
                    </div>
                    <div className="col-xs-12 homepage_feature_div_section padding_is_zero">
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/PremiumQualityIcon.svg" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext">Premium Frame & Lens Quality</p></center>
                        </div>
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/PriceOffersIcon.svg" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext">Lowest Prices Guaranteed</p></center>
                        </div>
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/CustomerSupportIcon.svg" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext">5000+ Happy Customers</p></center>
                        </div>
                        <div className="col-xs-3 padding_is_zero">
                            <center><img src="/images/Header/SustainableEcofriendlyIcon.svg" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext">Sustainable & Eco-friendly Products</p></center>
                        </div>
                    </div>
                    
                </div>*/}
                <div className="hidden-xs">
                    <div className="homepage_whyojos_new_section">
                        <div className="col-xs-12">
                            <p className="homepage_why_ojoswear_new_header_web">Trendy Streetwear meets Designer Frames</p>
                            <p className="homepage_why_ojoswear_new_subtext_web">When trendy streetwear meets designer frames you get the hippest eyewear brand in India with the assurance of Lenskart.
        OJOS Wear is here to redefine your Gen Z style at an affordable price. With a new handpicked collection every month,
        we deliver the best offers, free shipping, and fast delivery. So, whats not to love? 💜</p>
                        </div>
                        <div className="col-xs-12 homepage_feature_div_section padding_is_zero">
                            <div className="col-xs-2"></div>
                            <div className="col-xs-2 padding_is_zero">
                                <center><img src="/images/Header/GroupIcon.png" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext_web">5000+ Happy Customers</p></center>
                            </div>
                            <div className="col-xs-2 padding_is_zero">
                                <center><img src="/images/Header/SustainableIcon.png" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext_web">Sustainable and Plastic Free products</p></center>
                            </div>
                            <div className="col-xs-2 padding_is_zero">
                                <center><img src="/images/Header/PremiumQualityIcon.png" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext_web">Premium Frame and Lens Quality</p></center>
                            </div>
                            <div className="col-xs-2 padding_is_zero">
                                <center><img src="/images/Header/LowestPricesIcon.png" className="homepage_delivery_icons_mob"/><p className="homepage_why_ojoswear_subtext_web">Lowest Prices Guaranteed</p></center>
                            </div>
                            <div className="col-xs-2"></div>
                        </div>
                    
                    </div>
                    <img src="/images/Header/seperation_banner.png" className="img-responsive" width="100%"/>
                    {/*<div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <center>
                            <p className="homepage_why_oj_text extra_bold_text">but the big question is</p>
                            <h1 className="homepage_why_ojoswear">who are we?</h1>
                        </center>
                        <div className="col-xs-12 homepage_lk_oj_img">
                            <div className="col-xs-6 homepage_oj_small_icon_div">
                                <img src="/images/Header/OJ_Monogram-01.png" className="homepage_oj_img img-responsive"/>
                            </div>
                            <div className="col-xs-6">
                                <img src="/images/Header/LK_Monogram.svg" className="homepage_lk_img img-responsive"/>
                            </div>
                        </div>
                        <div className="col-xs-12 homepage_oj_lk_desc_div">
                            <center><p className="homepage_oj_lk_desc">Welcome to the future of eyewear shopping. OJOS brings you an exclusive collection of curated designs in partnership with Lenskart, that redefines Gen Z style!</p></center>
                        </div>
                        <div className="col-xs-12 homepage_why_oj_div">
                            <center>
                                <h2 className="homepage_why_choose_oj extra_bold_text">why choose OJOS?</h2>
                                <p className="homepage_oj_lk_desc">We redefine your eyewear shopping experience. Just pick, click and bling it on!</p>
                            </center>
                        </div>
                        <div className="col-sm-12 homepage_feature_div_section">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                                <center><img src="/images/Header/PremiumQualityIcon.svg" className="homepage_delivery_icons"/><p className="homepage_why_ojoswear_subtext">Premium Frame & Lens Quality</p></center>
                            </div>
                            <div className="col-sm-2">
                                <center><img src="/images/Header/PriceOffersIcon.svg" className="homepage_delivery_icons"/><p className="homepage_why_ojoswear_subtext">Lowest Prices Guaranteed</p></center>
                            </div>
                            <div className="col-sm-2">
                                <center><img src="/images/Header/CustomerSupportIcon.svg" className="homepage_delivery_icons"/><p className="homepage_why_ojoswear_subtext">5000+ Happy Customers</p></center>
                            </div>
                            <div className="col-sm-2">
                                <center><img src="/images/Header/SustainableEcofriendlyIcon.svg" className="homepage_delivery_icons"/><p className="homepage_why_ojoswear_subtext">Sustainable & Eco-friendly Products</p></center>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                    */}
                </div>
            </div>

            );
    }
}
export default HomePageWhyOjoswear;