import React, { Component } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

class AboutUs extends Component 
{
	constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            cart_count: 0,
            app_id: 0,
        };
    }
	componentDidMount()
    {
        const query = new URLSearchParams(this.props.location.search);
		const app_id = query.get('app_id');
		if(app_id == 1)
		{
			this.setState({app_id: app_id});
		}
        let prod_count = localStorage.getItem("prod_counts");
        if(prod_count)
        {
            var total_qty = 0;
            let product_id_array = JSON.parse(localStorage.getItem("prod_counts"));
            for(var i = 0; i<= product_id_array.length-1; i++)
            {
                if(product_id_array[i] !== 0)
                {
                    total_qty = total_qty + JSON.parse(localStorage.getItem("prod_"+product_id_array[i])).qty;
                }
            }
            this.setState({
                cart_count: total_qty
            });
        }
    }
	render() {
		return (
	    		<div>
	    			{this.state.app_id != 1 ?
		    			<Header color={"#160D08"} cart_count={this.state.cart_count}/>
		    			:
		    			null
		    		}
	    			<div className="static_background">
						<div className="col-xs-12 col-sm-12 padding_is_zero">
							<img src="../images/Static/about_us_banner_d.jpg" alt="About us image" className="img-responsive w_inherit"/>
						</div>
						
						<div className="col-xs-12 static_background about_us_content padding_is_zero">
							<div className="m_t_5">
								<div className="col-sm-1"></div>
								<div className="col-xs-12 col-sm-4 m_t_5">
									<p className="black_text font_14 font_w_600 line_h_17 our_story_text">Our Story</p>
									<p className="m_t_5 our_story_subtext">OJOS is all about eyes and 21 Frames to beautify them! We offer the finest in stylish eyewear and deals that help you get the best for your buck, thus setting ourselves apart in the eyewear market. How do we do this? Yes, we have our thinking hats on! The path to purchase is easier and simplified, with a fixed price. The 21 eye frames, pre-fitted with Anti-Glare lenses, are updated periodically, so all it needs is a pick and a click to get those pair of eye frames on you! We take the hassle, while you eye that dazzle! The editions are limited, wallet-friendly, hand-picked and curated by the connoisseurs of design and precision in the eyewear industry. We love all things fashion, and we love to pamper the fashionista in you!</p>
								</div>
								<div className="col-sm-1"></div>
								<div className="col-sm-6  m_t_5 hidden-xs">
									<center><img src="../images/Static/our_meet_up.jpg" alt="About us meet up" className="img-responsive about_us_image_web"/></center>
								</div>
							</div>

							<div className="col-sm-12 col-xs-12 about_us_second_section padding_is_zero">
									<div className="col-sm-6 m_t_12 hidden-xs">
										<center><img src="../images/Static/about_our_work.jpg" alt="About us meet up" className="img-responsive about_us_image_web"/></center>
									</div>

									<div className="col-xs-12 padding_is_zero corousel_section_about_us hidden-md hidden-sm hidden-lg white_background p_20 m_t_5 m_b_5">
										<section className="carousel corousel_about_us" aria-label="Gallery">
		                                    <ol className="carousel__viewport">
												<li className="carousel__slide corousel_about_us_half">
													<div className="carousel__snapper about_us_carousel_snapper">
														<img src="../images/Static/our_meet_up.jpg" alt="Meeting" className="img-responsive"/>
													</div>
												</li>
												<li className="carousel__slide corousel_about_us_half">
													<div className="carousel__snapper about_us_carousel_snapper">
														<img src="../images/Static/about_our_work.jpg" alt="Work" className="img-responsive"/>
													</div>
												</li>
												<li className="carousel__slide corousel_about_us_half">
													<div className="carousel__snapper about_us_carousel_snapper">
														<img src="../images/Static/about_tasks.jpg" alt="Tasks" className="img-responsive"/>
													</div>
												</li>
												<li className="carousel__slide corousel_about_us_half">
													<div className="carousel__snapper about_us_carousel_snapper">
														<img src="../images/Static/about_develop.jpg" alt="Development" className="img-responsive"/>
													</div>
												</li>
												<li className="carousel__slide corousel_about_us_half">
													<div className="carousel__snapper about_us_carousel_snapper">
														<img src="../images/Static/about_outdoor.jpg" alt="Outdoor" className="img-responsive"/>
													</div>
												</li>
											</ol>
		                                </section>
									</div>
									<div className="col-sm-1"></div>
									<div className="col-sm-4  m_b_5">
										<p className="black_text font_14 font_w_600 line_h_17 text_right our_story_text m_t_5 pull-right">Our Vision</p>
										<p className="text_right our_story_subtext m_b_5 pull-right">
											We're clear, we’re precise and aim to make it simplified. OJOS aspires to provide a “perfect vision” that never has to be compromised upon, in terms of style and at a price that fits all tags. With a mission to transform the old-fashioned way of traditional eyewear shopping, we look forward to creating value for customers through a standardized and personalized shopping experience! We've got all eyes on us...with style!
										</p>
									</div>
									<div className="col-sm-1"></div>
								</div>
						</div>

						{/*<div className="static_background m_t_5">
							<div className="col-md-offset-1 col-sm-offset-1 col-md-10 col-sm-10 col-xs-12">
								<div className="p_10 white_background">
									<center className="p_10 font_w_700 font_20 black_text">Get to know us more</center>
									<img src="../images/Static/play_button.svg" alt="Play" className="img-responsive play_button col-md-offset-5 col-sm-offset-5 col-xs-offset-5"></img>
									<img src="../images/Static/video_ad.jpg" alt="video" className="img-responsive border_radius_4 p_20"/>
								</div>
							</div>
						</div>*/}
		    		</div>
		    		{this.state.app_id != 1 ?
		    			<Footer />
		    			:
		    			null
		    		}
	    		</div>
	    );
	}
}

export default AboutUs;