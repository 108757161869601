import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';


class ProductListingFilters extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            selected_id:"",
            frame_shape:[],
            frame_type: [],
            frame_size: [],
            frame_material: [],
            frame_color: [],
        };
        this.ApplyFilter = this.ApplyFilter.bind(this);
        this.UncheckElements = this.UncheckElements.bind(this);
    }
    componentDidMount(){
        if(this.props.device == "mob")
        {
            this.filterSelection("FRAME_TYPE")
            // Add active class to the current button (highlight it)
            var btnContainer = document.getElementById("myfilter");
            var btns = btnContainer.getElementsByClassName("filter-item");
            for (var i = 0; i < btns.length; i++) {
                btns[i].addEventListener("click", function(){
                    var current = document.getElementsByClassName("active");
                    current[0].className = current[0].className.replace(" active", "");
                    this.className += " active";
                });
            }  
        }
        
        const urlParams = new URLSearchParams(window.location.search);
        if(window.location.href.indexOf('frame_type') > -1)
        {
            //this.state.frame_type.push(urlParams.get('frame_type'));  
            var array = urlParams.get('frame_type').split(',');
            this.setState({frame_type: this.state.frame_type.concat(array)}); 
        }
        if(window.location.href.indexOf('frame_shape') > -1)
        {
            //this.state.frame_shape.push(urlParams.get('frame_shape')); 
            var array = urlParams.get('frame_shape').split(',');
            this.setState({frame_shape: this.state.frame_shape.concat(array)});
        }
        if(window.location.href.indexOf('frame_size') > -1)
        {
            //this.state.frame_size.push(urlParams.get('frame_size')); 
            var array = urlParams.get('frame_size').split(',');
            this.setState({frame_size: this.state.frame_size.concat(array)});
        }
        if(window.location.href.indexOf('frame_colour') > -1)
        {
            //this.state.frame_color.push(urlParams.get('frame_colour'));
            var array = urlParams.get('frame_colour').split(',');
            this.setState({frame_color: this.state.frame_color.concat(array)}); 
        }
        if(window.location.href.indexOf('frame_material') > -1)
        {
            //this.state.frame_material.push(urlParams.get('frame_material')); 
            var array = urlParams.get('frame_material').split(',');
            this.setState({frame_material: this.state.frame_material.concat(array)}); 
        }
    }
    filterSelection(c) {
    if(c == "frame_colour")
    {
        c= "frame_color"
    }

    var x, i;
    var c = c.toUpperCase();
    x = document.getElementsByClassName("filterDiv"); 
    if (c == "FRAME TYPE") c = "";
    for (i = 0; i < x.length; i++) {
        this.w3RemoveClass(x[i], "show");
        if (x[i].className.indexOf(c) > -1) this.w3AddClass(x[i], "show");
    }
    }

    w3AddClass(element, name) {
        var i, arr1, arr2;
        arr1 = element.className.split(" ");
        arr2 = name.split(" ");
        for (i = 0; i < arr2.length; i++) {
            if (arr1.indexOf(arr2[i]) == -1) {element.className += " " + arr2[i];}
        }
    }
    w3RemoveClass(element, name) {
        var i, arr1, arr2;
        arr1 = element.className.split(" ");
        arr2 = name.split(" ");
        for (i = 0; i < arr2.length; i++) {
            while (arr1.indexOf(arr2[i]) > -1) {
                arr1.splice(arr1.indexOf(arr2[i]), 1);     
            }
        }
        element.className = arr1.join(" ");
    }
    SelectFrameType(e)
    {
        const target = e.target;
        var value = target.value;
        if(target.checked){
            //this.state.frame_type.push(value); 
            this.setState({frame_type: this.state.frame_type.concat(value)},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    } 
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    }
                });    
        }else{
            const index = this.state.frame_type.indexOf(value);
            if (index > -1) { // only splice array when item is found
                this.state.frame_type.splice(index, 1); // 2nd parameter means remove one item only
                this.setState({frame_type: this.state.frame_type},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    } 
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    }
                });    
            }
        }
        
    }
    SelectFrameShape(e) 
    {
        const target = e.target;
        var value = target.value;
        if(target.checked){
            //this.state.frame_shape.push(value); 
            this.setState({frame_shape: this.state.frame_shape.concat(value)},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    }
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    } 
                });    
        }else{
            const index = this.state.frame_shape.indexOf(value);
            if (index > -1) { // only splice array when item is found
              this.state.frame_shape.splice(index,1); // 2nd parameter means remove one item only
              this.setState({frame_shape: this.state.frame_shape},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    } 
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    }
                });   
            }
        }
    }
    SelectFrameSize(e) 
    {
        const target = e.target;
        var value = target.value;
        if(target.checked){
            //this.state.frame_size.push(value); 
            this.setState({frame_size: this.state.frame_size.concat(value)},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    }
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    } 
                });    
        }else{
            const index = this.state.frame_size.indexOf(value);
            if (index > -1) { // only splice array when item is found
              this.state.frame_size.splice(index, 1); // 2nd parameter means remove one item only
              this.setState({frame_size: this.state.frame_size},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    }
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    } 
                });   
            }
        }
    }
    SelectFrameColor(e) 
    {
        const target = e.target;
        var value = target.value;
        if(target.checked){
            //this.state.frame_color.push(value); 
            this.setState({frame_color: this.state.frame_color.concat(value)},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    }
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    } 
                });   
        }else{
            const index = this.state.frame_color.indexOf(value);
            if (index > -1) { // only splice array when item is found
              this.state.frame_color.splice(index, 1); // 2nd parameter means remove one item only
              this.setState({frame_color: this.state.frame_color},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    } 
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    }
                });   
            }
        }
    }
    SelectFrameMaterial(e) 
    {
        const target = e.target;
        var value = target.value;
        if(target.checked){
            //this.state.frame_material.push(value); 
            this.setState({frame_material: this.state.frame_material.concat(value)},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    } 
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    }
                });      
        }else{
            const index = this.state.frame_material.indexOf(value);
            if (index > -1) { // only splice array when item is found
              this.state.frame_material.splice(index, 1); // 2nd parameter means remove one item only
              this.setState({frame_material: this.state.frame_material},()=>
                {
                    if(this.props.device == "web")
                    {
                        this.ApplyFilter();
                        $('html, body').animate({scrollTop:$('#product_listing_filters').position().top}, 'slow');
                    } 
                    else
                    {
                        this.props.update_only_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
                    }
                });   
            }
        }
    }
    ApplyFilter()
    {
        this.props.apply_filters(this.state.frame_type, this.state.frame_shape, this.state.frame_size, this.state.frame_color, this.state.frame_material);
    }
    UncheckElements()
    {
        var uncheck=document.getElementsByTagName('input');
        for(var i=0;i<uncheck.length;i++)
        {
            if(uncheck[i].type=='checkbox')
            {
                uncheck[i].checked=false;
            }
        }
        var baseUrl = window.location.href.split("?")[0];
        window.history.pushState('name', '', baseUrl);
        this.setState({frame_shape:[], frame_type: [], frame_size: [], frame_material: [], frame_color: []},()=>
        {
            this.props.only_filters();
            this.props.applied_filter_icon_change();
        })

    }
    render() { 
            console.log(this.state.frame_type);
			return(
            <div>
                {this.props.device == "mob" ?
                    <div className="filters_section">
                        <div className="col-xs-12 filters_heading">
                            <p className="filters_heading_text1" onClick={this.props.filters_action}> &#60; &#160; FILTERS</p>
                            <p className="filters_heading_text2" onClick={this.UncheckElements}>CLEAR ALL</p>
                        </div>
                        <div className="col-xs-12 filters_body padding_is_zero">
                            <div id="myFilterContainer" className="col-xs-5 filter_menu padding_is_zero">
                                <ul className="list-group" id="myfilter" >
                                    {this.props.filters.map((item, i) => (
                                        <div>
                                            {item.id == 'frame_material' || item.id == 'frame_shape' || item.id == 'frame_size' || item.id == 'frame_type' || item.id == 'frame_colour' ?
                                               <div>
                                                {i == 0 ?
                                                    <li className="filter-item active"  aria-current="true" onClick={()=>this.filterSelection(item.id)}>{item.name} 
                                                    {this.state.frame_type.length > 0 ?
                                                        <span class="filter_dot"></span>
                                                        :
                                                        null
                                                    }
                                                    </li>
                                                    :
                                                    <li className="filter-item"  aria-current="true" onClick={()=>this.filterSelection(item.id)}>{item.name}
                                                    {item.id == 'frame_material' && this.state.frame_material.length > 0 || (item.id == 'frame_shape') && (this.state.frame_shape.length > 0) || (item.id == 'frame_size') && (this.state.frame_size.length > 0) || (item.id == 'frame_colour') && (this.state.frame_color.length > 0)?
                                                        <span class="filter_dot"></span>
                                                        :
                                                        null
                                                    }
                                                    </li>
                                                }
                                                </div> 
                                                :
                                                null
                                            }
                                        </div>
                                    ))}
                                    {/*<li className="filter-item active"  aria-current="true"onClick={()=>this.filterSelection('FRAME_TYPE')}>FRAME TYPE</li>
                                    <li className="filter-item"  aria-current="true"onClick={()=>this.filterSelection('FRAME_SHAPE')}>FRAME SHAPE</li> 
                                    <li className="filter-item"  aria-current="true"onClick={()=>this.filterSelection('FRAME_COLOR')}>FRAME COLOR</li> 
                                    <li className="filter-item"  aria-current="true"onClick={()=>this.filterSelection('FRAME_SIZE')}>FRAME SIZE</li> 
                                    <li className="filter-item"  aria-current="true"onClick={()=>this.filterSelection('POWER_RANGE')}>POWER RANGE</li> 
                                    <li className="filter-item"  aria-current="true"onClick={()=>this.filterSelection('MATERIAL')}>MATERIAL</li> 
                                    <li className="filter-item"  aria-current="true"onClick={()=>this.filterSelection('COLLECTION')}>COLLECTION</li> */}
                                </ul>
                            </div>
                            <div className="col-xs-7 filter_ojos">
                                {this.props.filters.map((item, i) => (
                                    <div>
                                        {item.id == "frame_type" ?
                                            <div>
                                                {item.options.map((option, index) => (
                                                    <div className="col-xs-12 filterDiv  FRAME_TYPE">
                                                        <div className="quiz_card_area" onChange={e => this.SelectFrameType(e)}>
                                                            {this.state.frame_type.includes(option.title) ?
                                                                <input className="quiz_checkbox" type="checkbox" id={option.title} value={option.title} checked/>
                                                                :
                                                                <input className="quiz_checkbox" type="checkbox" id={option.title} value={option.title} />
                                                            }
                                                            <div className="single_quiz_card">
                                                                <div className="quiz_card_content">
                                                                    <div className="quiz_card_title">
                                                                        <div>
                                                                            <center><img className="filter_div_img img-responsive" src={option.image_url} alt="Card image cap"/></center>
                                                                            <p>{option.title}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* -- end of quiz_card_content --> */}
                                                            </div>
                                                                {/* <!-- end of single_quiz_card --> */}
                                                        </div>
                                                        {/* <!-- end of quiz_card_area --> */}
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            
                                                <div>
                                                    {item.id == "frame_shape" ?
                                                        <div>
                                                            {item.options.map((option, index) => (
                                                                <div className="col-xs-6 filterDiv  FRAME_SHAPE">
                                                                    <div className="quiz_card_area1" onChange={e => this.SelectFrameShape(e)}>
                                                                        {this.state.frame_shape.includes(option.title) ?
                                                                            <input className="quiz_checkbox" type="checkbox" id={option.title} value={option.title} checked/>
                                                                            :
                                                                            <input className="quiz_checkbox" type="checkbox" id={option.title} value={option.title} />
                                                                        }
                                                                        <div className="single_quiz_card">
                                                                            <div className="quiz_card_content">
                                                                                <div className="quiz_card_title ">
                                                                                    <div>
                                                                                        <center><img  className="filter_div_img img-responsive" src={option.image_url} alt="Card image cap"/></center>
                                                                                        <p>{option.title}</p>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <!-- end of quiz_card_title --> */}
                                                                            </div>
                                                                            {/* -- end of quiz_card_content --> */}
                                                                        </div>
                                                                        {/* <!-- end of single_quiz_card --> */}
                                                                    </div>
                                                                    {/* <!-- end of quiz_card_area --> */}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div>
                                                            {item.id == "frame_size" ?
                                                                <div>
                                                                    {item.options.map((option, index) => (
                                                                        <div className="col-xs-12 filterDiv FRAME_SIZE card-img-top3" onChange={e => this.SelectFrameSize(e)}>
                                                                            {this.state.frame_size.includes(option.title) ?
                                                                                <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title} checked/>&#160;&#160;{option.title}</label>
                                                                                :
                                                                                <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title}/>&#160;&#160;{option.title}</label>
                                                                            }
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                :
                                                                <div>
                                                                    {item.id == "frame_colour" ?
                                                                        <div>
                                                                            {item.options.map((option, index) => (
                                                                                <div className="col-xs-12 filterDiv FRAME_COLOR card-img-top3" onChange={e => this.SelectFrameColor(e)}>
                                                                                    {this.state.frame_color.includes(option.title) ?
                                                                                        <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title} checked/>&#160;&#160;{option.title} ({option.no_of_products})</label>
                                                                                        :
                                                                                        <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title}/>&#160;&#160;{option.title} ({option.no_of_products})</label>
                                                                                    }
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            {item.id == "frame_material" ?
                                                                                <div>
                                                                                    {item.options.map((option, index) => (
                                                                                        <div className="col-xs-12 filterDiv FRAME_MATERIAL card-img-top3 filter_frame_color" onChange={e => this.SelectFrameMaterial(e)}>
                                                                                            {this.state.frame_material.includes(option.title) ?
                                                                                                <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title} checked/>&#160;&#160; {option.title} ({option.no_of_products})</label>
                                                                                                :
                                                                                                <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title}/>&#160;&#160; {option.title} ({option.no_of_products})</label>
                                                                                            }
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            
                                        }
                                    </div>
                                ))}
                                       

                                   
                                

                                {/* ***************************POWER RANGE********************************** */}

                                {/*<div className="col-xs-12 filterDiv POWER_RANGE  card-img-top3  ">
                                    <p className="filter_power_text">Spherical Power (SPH)</p><br/>
                                    <p className="filter_power_text_option">Right</p><br/>
                                    <label for="glass0"></label>
                                    <input type="number " id="glass0" className="card-img-top4" value="0.00"
                                    min="-2.5" max="0.00"></input>
                                    <p className="filter_power_text_option">Left</p><br/>
                                    <label for="glass"></label>
                                    <input type="number " id="glass" className="filter_power_num" value="0.00"
                                    min="-2.5" max="0.00"></input>
                                    <p className="filter_power_text">Cylindrical Power (CYL)</p><br/>
                                    <p className="filter_power_text_option">Right</p><br/>
                                    <label for="glass1"></label>
                                    <input type="number " id="glass1" className="filter_power_num" value="0.00"
                                    min="-2.5" max="0.00"></input>
                                    <p className="filter_power_text_option">Left</p><br/>
                                    <label for="glass2"></label>
                                    <input type="number " id="glass2" className="filter_power_num" value="0.00"
                                    min="-2.5" max="0.00"></input>
                                </div>*/}

                                
                            
                            </div>
                        </div>
                        <div className="filters-footer modal-footer-edited modal-footer">
                            <center><button className="btn select_apply_filters " onClick={this.ApplyFilter}>APPLY FILTERS</button></center>  
                        </div>
                        
                    </div>
                    :
                    <div className="filters_section_web" id="filters_section_web">
                        
                        <div className="col-xs-12 padding_is_zero">
                            <div id="" className="">
                                {this.props.filters.map((item, i) => (
                                    <div>
                                        {item.id == "frame_type" ?
                                            <div className="each_filter_div">
                                                <p>{item.name}</p>
                                                <div className="col-xs-12 padding_is_zero">
                                                    {item.options.map((option, index) => (
                                                        <div className="col-xs-4 filterDiv padding_is_zero">
                                                            <div className="quiz_card_area_web" onChange={e => this.SelectFrameType(e)}>
                                                                {this.state.frame_type.includes(option.title) ?
                                                                    <input className="quiz_checkbox" type="checkbox" id={option.title} value={option.title} checked/>
                                                                    :
                                                                    <input className="quiz_checkbox" type="checkbox" id={option.title} value={option.title} />
                                                                }
                                                                <div className="single_quiz_card">
                                                                    <div className="quiz_card_content">
                                                                        <div className="quiz_card_title">
                                                                            <div>
                                                                                <center><img className="filter_div_img img-responsive" src={option.image_url} alt="Card image cap"/></center>
                                                                                <p>{option.title}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* -- end of quiz_card_content --> */}
                                                                </div>
                                                                    {/* <!-- end of single_quiz_card --> */}
                                                            </div>
                                                            {/* <!-- end of quiz_card_area_web --> */}
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        :
                                            <div>
                                                {item.id == "frame_shape" ?
                                                    <div className="each_filter_div">
                                                        <p>{item.name}</p>
                                                        <div className="col-xs-12 padding_is_zero">
                                                            {item.options.map((option, index) => (
                                                                <div className="col-xs-4 filterDiv padding_is_zero">
                                                                    <div className="quiz_card_area_web" onChange={e => this.SelectFrameShape(e)}>
                                                                        {this.state.frame_shape.includes(option.title) ?
                                                                            <input className="quiz_checkbox" type="checkbox" id={option.title} value={option.title} checked/>
                                                                            :
                                                                            <input className="quiz_checkbox" type="checkbox" id={option.title} value={option.title} />
                                                                        }
                                                                        <div className="single_quiz_card">
                                                                            <div className="quiz_card_content">
                                                                                <div className="quiz_card_title">
                                                                                    <div>
                                                                                        <center><img className="filter_div_img img-responsive" src={option.image_url} alt="Card image cap"/></center>
                                                                                        <p>{option.title}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* -- end of quiz_card_content --> */}
                                                                        </div>
                                                                            {/* <!-- end of single_quiz_card --> */}
                                                                    </div>
                                                                    {/* <!-- end of quiz_card_area_web --> */}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        {item.id == "frame_size" ?
                                                            <div className="each_filter_div">
                                                                <p>{item.name}</p>
                                                                {item.options.map((option, index) => (
                                                                    <div className="col-xs-12 filterDiv  padding_is_zero card-img-top3" onChange={e => this.SelectFrameSize(e)}>
                                                                        {this.state.frame_size.includes(option.title) ?
                                                                            <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title} checked/>&#160;&#160;{option.title} ({option.no_of_products})</label>
                                                                            :
                                                                            <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title}/>&#160;&#160;{option.title} ({option.no_of_products})</label>
                                                                        }
                                                                    </div>
                                                                ))}
                                                                <div className="clearfix"></div>
                                                            </div>
                                                            :
                                                            <div>
                                                                {item.id == "frame_colour" ?
                                                                    <div className="each_filter_div">
                                                                        <p>{item.name}</p>
                                                                        {item.options.map((option, index) => (
                                                                            <div className="col-xs-12 filterDiv  padding_is_zero card-img-top3" onChange={e => this.SelectFrameColor(e)}>
                                                                                {this.state.frame_color.includes(option.title) ?
                                                                                    <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title} checked/>&#160;&#160;{option.title} ({option.no_of_products})</label>
                                                                                    :
                                                                                    <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title}/>&#160;&#160;{option.title} ({option.no_of_products})</label>
                                                                                }
                                                                            </div>
                                                                        ))}
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        {item.id == "frame_material" ?
                                                                            <div className="each_filter_div">
                                                                                <p>{item.name}</p>
                                                                                <div>
                                                                                    {item.options.map((option, index) => (
                                                                                        <div className="col-xs-12 filterDiv padding_is_zero card-img-top3 filter_frame_color" onChange={e => this.SelectFrameMaterial(e)}>
                                                                                            {this.state.frame_material.includes(option.title) ?
                                                                                                <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title} checked/>&#160;&#160; {option.title} ({option.no_of_products})</label>
                                                                                                :
                                                                                                <label><input type="checkbox" className="checkbox_framecolor" id={option.title} value={option.title}/>&#160;&#160; {option.title} ({option.no_of_products})</label>
                                                                                            }
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                ))}
                                {/*<ul className="list-group" id="myfilter" >
                                    {this.props.filters.map((item, i) => (
                                        <div>
                                            {item.id == 'frame_material' || item.id == 'frame_shape' || item.id == 'frame_size' || item.id == 'frame_type' || item.id == 'frame_colour' ?
                                               <div>
                                                {i == 0 ?
                                                    <li className="filter-item active"  aria-current="true" onClick={()=>this.filterSelection(item.id)}>{item.name}</li>
                                                    :
                                                    <li className="filter-item"  aria-current="true" onClick={()=>this.filterSelection(item.id)}>{item.name}</li>
                                                }
                                                </div> 
                                                :
                                                null
                                            }
                                        </div>
                                    ))}
                                </ul>*/}
                            </div>
                            
                        </div>
                        
                    </div>
                }
            </div>
		);
    }
}

export default ProductListingFilters;