import React from "react";

const Rating = ({ ratingCount, reviewCount, showStar }) => {
  const fullStars = Math.floor(ratingCount);
  const partialStar = ratingCount - fullStars;
  const starwidth = reviewCount ? 29.02 : 18.02;
  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${starwidth}`}
        height="27.721"
        viewBox="0 0 29.02 27.721"
      >
        <path
          id="Icon_feather-star"
          data-name="Icon feather-star"
          d="M16.26,3l4.1,8.3L29.52,12.64,22.89,19.1l1.565,9.123-8.195-4.31-8.195,4.31L9.63,19.1,3,12.64,12.163,11.3Z"
          transform="translate(-1.75 -1.75)"
          fill="#f29b24"
          stroke="#f29b24"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
        />
      </svg>
    );
  }

  if (partialStar > 0) {
    let percentage = 0;
    if (partialStar < 0.5) percentage = partialStar * 190;
    else percentage = partialStar * 78;
    stars.push(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${starwidth}`}
        height="27.721"
        viewBox="0 0 29.02 27.721"
      >
        <defs>
          <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset={`${percentage}%`}
              style={{ stopColor: "#F29B24", stopOpacity: 1 }}
            />
            <stop
              offset={`${percentage}%`}
              style={{ stopColor: "transparent", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <path
          id="Icon_feather-star"
          data-name="Icon feather-star"
          d="M16.26,3l4.1,8.3L29.52,12.64,22.89,19.1l1.565,9.123-8.195-4.31-8.195,4.31L9.63,19.1,3,12.64,12.163,11.3Z"
          transform="translate(-1.75 -1.75)"
          fill="url(#grad)"
          stroke="#F29B24"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
        />
      </svg>
    );
  }
  let remainingStar = 5 - fullStars - Math.ceil(partialStar);

  for (let i = 0; i < remainingStar; i++) {
    stars.push(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${starwidth}`}
        height="27.721"
        viewBox="0 0 29.02 27.721"
      >
        <path
          id="Icon_feather-star"
          data-name="Icon feather-star"
          d="M16.26,3l4.1,8.3L29.52,12.64,22.89,19.1l1.565,9.123-8.195-4.31-8.195,4.31L9.63,19.1,3,12.64,12.163,11.3Z"
          transform="translate(-1.75 -1.75)"
          fill="none"
          stroke="#F29B24"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
        />
      </svg>
    );
  }

  return (
    <>
      {showStar && showStar == "all" ? (
        <>
          {stars}{" "}
          {reviewCount ? (
            <a href="#reviewsection">
              <b
                style={{
                  fontSize: "16px",
                }}
              >
                {reviewCount} reviews
              </b>
            </a>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {/* {stars}{" "} */}
          {ratingCount ? (
            <div>
              <b
                style={{
                  fontSize: "14px",

                  color: "#F29B24",
                }}
              >
                {" "}
                {ratingCount}{" "}
                <span
                  style={{
                    paddingRight: "3px",
                    borderRight: " 1px #E6E6E6 solid",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="11"
                    viewBox="0 0 29.02 27.721"
                  >
                    <path
                      id="Icon_feather-star"
                      data-name="Icon feather-star"
                      d="M16.26,3l4.1,8.3L29.52,12.64,22.89,19.1l1.565,9.123-8.195-4.31-8.195,4.31L9.63,19.1,3,12.64,12.163,11.3Z"
                      transform="translate(-1.75 -1.75)"
                      fill="#f29b24"
                      stroke="#f29b24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                    />
                  </svg>{" "}
                </span>
              </b>

              {reviewCount ? (
                <a href="#reviewsection">
                  <b
                    style={{
                      fontSize: "13px",
                      marginLeft: "7px",
                      color: "#b4b1b1",
                    }}
                  >
                    {reviewCount}{" "}
                    <span style={{ textTransform: "lowercase" }}>reviews</span>
                  </b>
                </a>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default Rating;
