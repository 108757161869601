import React, { Component } from 'react';
import { Redirect } from 'react-router';

class Logout extends Component 
{    
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
    }

    componentDidMount(){
        //will be an api call here to deactivate this token from database, and after that promise is resolved then following code will run
        this.setState({
            redirect : true
        });
        localStorage.removeItem('token');
    }
    
    render() { 

        if(this.state.redirect){
            return <Redirect to="/" />
        }
			return(
                        <div>Logging Out...</div>
			);
    }
}

export default Logout;