import React, { Component } from "react";

class CheckoutBillDetails extends Component 
{
	constructor(props) {
	    super(props);
	    this.state = {
	    	cart_details: this.props.cart_details,
	    	show_tax: false,
	    	show_tax_chevron: 'fa-chevron-down',
	    	credits_in_wallet: this.props.credits_in_wallet,
	    }
	    this.ShowTax = this.ShowTax.bind(this);
	}
	ShowTax()
	{
		if(this.state.show_tax == true)
		{
			var show_tax_chevron = "fa-chevron-down"
		}
		else
		{
			var show_tax_chevron = "fa-chevron-up"
		}
		this.setState({show_tax: !this.state.show_tax, show_tax_chevron: show_tax_chevron});
	}
	componentDidMount()
	{
		
	}
	componentWillUnmount() {
    	
	}
	render() {
		return (
					
						<div>
							<div className={this.props.device == "mob" ? "col-xs-12 bill_details padding_is_zero" : "col-xs-12 bill_details_web padding_is_zero"}>
								{this.props.device == "web" ?
									<div className="col-xs-12 cart_prescription_text">
		    							Enter power for eyeglasses after placing your order
		    						</div>
		    						:
		    						null
								}
								<div className="col-xs-12 bill_heading">
									<p className="bill_details_text">Bill Details</p><p className="bill_details_text_sub">(Total Including GST)</p>
								</div>
								<div className="col-xs-12 item_total_section1 padding_is_zero">
									<div className="col-xs-6 item_total_text_area"><p>Total Price</p><p>({this.props.cart_details.billing.total_items} items)</p></div>
									<div className="col-xs-6 item_total_cost"><span className="billing_cost_text">₹ {this.props.cart_details.billing.total_price}</span></div>
									{/*{this.props.cart_details.billing.oj_super > 0 ?
										<div>
											<div className="col-xs-9 item_total_text_area item_total_oj_super"><p>OJ SUPER</p>{this.props.cart_details.billing.oj_super_qty > 1 ? <p className="item_total_super_oj_qty"> X {this.props.cart_details.billing.oj_super_qty}</p> : null } <p className="item_total_valid_text">valid for 1 year</p></div>
											<div className="col-xs-3 item_total_cost">₹ {this.props.cart_details.billing.oj_super}</div>
										</div>
										: 
										null
									}*/}
								</div>
								<div className="col-xs-12 item_total_section padding_is_zero">
									<div>
										<div>
											<div className="col-xs-9 item_total_text_area"><p>Delivery</p></div>
											<div className="col-xs-3 item_total_cost"><span className="billing_cost_text"><p className="bill_details_delivery_charge">FREE</p></span></div>
											
											{this.props.cart_details.billing.coupon_price > 0 ?
												<div>
													<div className="col-xs-9 item_total_text_area"><p>Discount <small className="discount_text_caps">"{this.props.cart_details.billing.title}"</small> <small className="discount_text">applied</small></p></div> 
													<div className="col-xs-3 item_total_cost discount_text"> - ₹ {(this.props.cart_details.billing.coupon_price)}</div>
												</div>
												:
												null
											}
											
										</div>
		 											
		 										
	 									
	 									
								</div>
									{/*<div className="col-xs-9 item_total_text_area "><p><b>Subtotal</b></p></div>
									<div className="col-xs-3 item_total_cost"><p><b>₹ {this.props.cart_details.billing.subtotal}</b></p></div>*/}
								</div>
								<div className="col-xs-12 padding_is_zero">
									<div className="col-xs-9 item_total_text_area tax_hover" onClick={this.ShowTax}><p>Taxes <i className={"fa tax_chevron "+this.state.show_tax_chevron} aria-hidden="true" ></i></p></div>
									<div className="col-xs-3 item_total_cost">₹ {this.props.cart_details.billing.tax}</div>
									{this.state.show_tax ?
										<div>
											<div className="col-xs-9 tax_subtext"><p>CGST ({this.props.cart_details.billing.tax_percent})</p></div>
											<div className="col-xs-3 tax_subtext_cost">₹ {this.props.cart_details.billing.tax}</div>
										</div>
										:
										null
									}
								</div>
								<div className="col-xs-12 item_total_section padding_is_zero">
									<div>
										{this.props.cart_details.billing.oj_referral_discount > 0 ?
											<div>
												<div className="col-xs-9 item_total_text_area discount_text"><p>OJ Cash Applied</p></div> 
												<div className="col-xs-3 item_total_cost discount_text"> - ₹ {(this.props.cart_details.billing.oj_referral_discount)}</div>
											</div>
											:
											null
										}
		 							</div>
								</div>
								<div className="col-xs-12 padding_is_zero">
									<div className="col-xs-9 total_text item_total_text_area"><p className="total_text">TOTAL</p></div> 
									<div className="col-xs-3 total_text item_total_cost">₹ {(this.props.cart_details.billing.payable_price).toFixed(2)}</div>
								</div>
							</div>
						</div>
    				
		    	
	    );
		
		
	}
}

export default CheckoutBillDetails;